import { getAppErrorCode } from '../../../utils/helpres';

const unitialUserState = {
  patients: [],
  patientRecords: [],
  isLoadingPatientRecord: false,
  isLoadingPatientRecords: false,
  isLoadingPatients: false,
  isLoadingPatient: false,
  isLoadingActionPatient: false,
  newPatient: {},
  deletePatient: false,
  addPatient: false,
  patientRecord: {
    id: '',
    consultations: [],
    patient: {
      id: '',
      first_name: '',
      last_name: '',
      email: '',
      status: '',
      cnam: '',
      phone: '',
      address_patient: {
        city: '',
        country: '',
        governerate: '',
        street: '',
        street_number: '',
        zip_code: '',
      },
    },
  },
  params: {
    search: '',
    first_name: '',
    last_name: '',
    city: '',
    birthday: '',
    cnam: '',
    phone: '',
    currentPage: 1,
    limit: 10,
  },
  paramsBilan: {
    search: '',
    currentPage: 1,
    limit: 2,
  },
  patient: {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    cnam: '',
    phone: '',
    profession: '',
    birthday: '',
    address_patient: {
      country: '',
      city: '',
      street: '',
      zipCode: '',
    },
  },
  message: '',
  bilanPatientRecord: [],
  isLoadingBilanPatientRecord: false,
  updatePatientRecord: false,
  protocolePatientRecord: [],
  isLoadingProtocolePatientRecord: false,
  billPatientRecord: [],
  isLoadingBillPatientRecord: false,
  receiptPatientRecord: [],
  isLoadingReceiptPatientRecord: false,
  patientStatistical: null,
  isLoadingPatientStatistical: false,
  lastPatientRecord: [],
  isLoadingLastPatientRecord: false,
};

const PatientReducer = (state = unitialUserState, action) => {
  switch (action.type) {
    case 'GET_LAST_PATIENT_RECORD_REQUESTING':
      return {
        ...state,
        isLoadingLastPatientRecord: true,
      };
    case 'GET_LAST_PATIENT_RECORD_SUCCESS':
      return {
        ...state,
        isLoadingLastPatientRecord: false,
        lastPatientRecord: action.data,
      };
    case 'GET_LAST_PATIENT_RECORD_FAILURE':
      return {
        ...state,
        isLoadingLastPatientRecord: false,
        lastPatientRecord: [],
        // error: getAppErrorCode(action.status),
      };

    case 'GET_RECEIPT_BY_PATIENT_RECORD_REQUESTING':
      return {
        ...state,
        isLoadingReceiptPatientRecord: true,
      };
    case 'GET_RECEIPT_BY_PATIENT_RECORD_SUCCESS':
      return {
        ...state,
        isLoadingReceiptPatientRecord: false,
        receiptPatientRecord: action.data.data,
      };
    case 'GET_RECEIPT_BY_PATIENT_RECORD_FAILURE':
      return {
        ...state,
        isLoadingReceiptPatientRecord: false,
        receiptPatientRecord: [],
        // error: getAppErrorCode(action.status),
      };

    case 'GET_PATIENT_STATISTICAL_REQUESTING':
      return {
        ...state,
        isLoadingPatientStatistical: true,
      };
    case 'GET_PATIENT_STATISTICAL_SUCCESS':
      return {
        ...state,
        isLoadingPatientStatistical: false,
        patientStatistical: action.data,
      };
    case 'GET_PATIENT_STATISTICAL_FAILURE':
      return {
        ...state,
        isLoadingPatientStatistical: false,
        patientStatistical: null,
        // error: getAppErrorCode(action.status),
      };

    case 'GET_BILL_BY_PATIENT_RECORD_REQUESTING':
      return {
        ...state,
        isLoadingBillPatientRecord: true,
      };
    case 'GET_BILL_BY_PATIENT_RECORD_SUCCESS':
      return {
        ...state,
        isLoadingBillPatientRecord: false,
        billPatientRecord: action.data.data,
      };
    case 'GET_BILL_BY_PATIENT_RECORD_FAILURE':
      return {
        ...state,
        isLoadingBillPatientRecord: false,
        billPatientRecord: [],
        // error: getAppErrorCode(action.status),
      };

    case 'GET_PROTOCOLE_BY_PATIENT_RECORD_REQUESTING':
      return {
        ...state,
        isLoadingProtocolePatientRecord: true,
      };
    case 'GET_PROTOCOLE_BY_PATIENT_RECORD_SUCCESS':
      return {
        ...state,
        isLoadingProtocolePatientRecord: false,
        protocolePatientRecord: action.data.data,
      };
    case 'GET_PROTOCOLE_BY_PATIENT_RECORD_FAILURE':
      return {
        ...state,
        isLoadingProtocolePatientRecord: false,
        protocolePatientRecord: [],
        // error: getAppErrorCode(action.status),
      };

    case 'UPDATE_PATIENT_RECORD_SUCCESS':
      return {
        ...state,
        updatePatientRecord: true,
      };
    case 'UPDATE_PATIENT_RECORD_FAILURE':
      return {
        ...state,
        updatePatientRecord: false,
      };
    case 'INITIAL_STATE_PATIENT':
      return {
        ...state,
        patient: {},
        updatePatientRecord: false,
      };
    /**Delete Patient*/
    case 'GET_BILAN_BY_PATIENT_RECORD_REQUESTING':
      return {
        ...state,
        isLoadingBilanPatientRecord: true,
      };
    case 'GET_BILAN_BY_PATIENT_RECORD_SUCCESS':
      return {
        ...state,
        isLoadingBilanPatientRecord: false,
        bilanPatientRecord: action.data,
      };
    case 'GET_BILAN_BY_PATIENT_RECORD_FAILURE':
      return {
        ...state,
        isLoadingBilanPatientRecord: false,
        bilanPatientRecord: [],
        // error: getAppErrorCode(action.status),
      };
    /**Delete Patient*/
    case 'ACTION_DELETE_PATIENT_SUCCESS':
      return {
        ...state,
        deletePatient: true,
        message: action.data.message,
      };
    case 'ACTION_DELETE_PATIENT_FAILURE':
      return {
        ...state,
        message: action.err,
        // error: getAppErrorCode(action.status),
      };
    /**Add Patient*/
    case 'UPDATE_PATIENT_REQUESTING': {
      return {
        ...state,
        isLoadingActionPatient: true,
        addPatient: false,
      };
    }
    case 'UPDATE_PATIENT_SUCCESS': {
      return {
        ...state,
        isLoadingActionPatient: false,
        newPatient: action.data.data,
        addPatient: true,
      };
    }
    case 'UPDATE_PATIENT_FAILURE': {
      return {
        ...state,
        isLoadingActionPatient: false,
        message: action.err,
        addPatient: false,
        ///    error: getAppErrorCode(action.status),
      };
    }
    /**Get All Patient*/
    case 'GET_PATIENTS_REQUESTING': {
      return {
        ...state,
        isLoadingPatient: true,
      };
    }
    case 'GET_PATIENTS_SUCCESS': {
      return {
        ...state,
        isLoadingPatient: false,
        patients: action.data,
        patient: {},
      };
    }
    case 'GET_PATIENTS_FAILURE': {
      return {
        ...state,
        isLoadingPatient: false,
        message: action.err,
        error: getAppErrorCode(action.status),
        patient: {},
      };
    }
    /**Get Patient By Id*/
    case 'GET_PATIENT_REQUESTING': {
      return {
        ...state,
        isLoadingPatient: true,
        addPatient: false,
      };
    }
    case 'GET_PATIENT_SUCCESS': {
      return {
        ...state,
        isLoadingPatient: false,
        patient: action.data,
        addPatient: false,
      };
    }
    case 'GET_PATIENT_FAILURE': {
      return {
        ...state,
        isLoadingPatient: false,
        addPatient: false,
        message: action.err,
        error: getAppErrorCode(action.status),
      };
    }
    /**Get Fiche Patient*/
    case 'GET_PATIENT_RECORD_REQUESTING': {
      return {
        ...state,
        isLoadingPatientRecord: true,
        addPatient: false,
      };
    }
    case 'GET_PATIENT_RECORD_SUCCESS': {
      return {
        ...state,
        isLoadingPatientRecord: false,
        patientRecord: action.data,
      };
    }
    case 'GET_PATIENT_RECORD_FAILURE': {
      return {
        ...state,
        isLoadingPatientRecord: false,
        message: action.err,
        error: getAppErrorCode(action.status),
      };
    }
    case 'GET_PATIENTS_RECORDS_BY_DOCTOR_REQUESTING': {
      return {
        ...state,
        isLoadingPatientRecords: true,
      };
    }
    case 'GET_PATIENTS_RECORDS_BY_DOCTOR_SUCCESS': {
      return {
        ...state,
        isLoadingPatientRecords: false,
        patientRecords: action.data,
        patients: [],
      };
    }
    case 'GET_PATIENTS_RECORDS_BY_DOCTOR_FAILURE': {
      return {
        ...state,
        isLoadingPatientRecords: false,
        message: action.err,
        error: getAppErrorCode(action.status),
      };
    }
    case 'ACTION_PATIENT_FAILURE': {
      return {
        ...state,
        newPatient: false,
      };
    }
    case 'ACTION_PATIENT_SUCCESS': {
      return {
        ...state,
        newPatient: true,
      };
    }
    /**Update Params*/
    case 'UPDATE_SEARCH_PATIENT_PARAMS': {
      return {
        ...state,
        params: action.params,
      };
    }
    /**Update Params Bilan*/
    case 'UPDATE_SEARCH_BILAN_PARAMS': {
      return {
        ...state,
        paramsBilan: action.paramsBilan,
      };
    }
    default: {
      return state;
    }
  }
};

export default PatientReducer;
