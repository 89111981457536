import React from 'react';
import { apiCall } from '../../../js/actions';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { getStatusError } from '../../../utils/helpres';
import ToastAction from '../../../components/ToastAction';

const getAllRdvByDay = (params, id) => async (dispatch) => {
  dispatch({ type: 'GET_RDV_ALL_DOCTOR_REQUESTING' });
  try {
    const { date } = params || {
      date: '',
    };
    const { status } = params || {
      status: [],
    };
    const { type } = params || {
      type: '',
    };
    let url = `/api/get/all/appointment?date=${date}&id[]=${id}&type=${type}`;
    if (status && Array.isArray(status) && status.length > 0) {
      status.map((item) => {
        url += `&status[]=${item}`;
      });
    }
    const { data } = await apiCall({
      method: 'get',
      url: url,
    });
    dispatch({
      type: 'GET_RDV_ALL_DOCTOR_SUCCESS',
      data: data,
    });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: 'GET_RDV_ALL_DOCTOR_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: response.type || response.message,
    });
  }
};

const getAllUnavailability = (params) => async (dispatch) => {
  dispatch({ type: 'GET_ALL_UNAVAILABILITY_DOCTOR_REQUESTING' });
  try {
    const { date } = params || {
      date: '',
    };
    const { data } = await apiCall({
      method: 'get',
      url: `/api/get/all/unavailability?date=${moment(date).format(
        'YYYY-MM-DD',
      )}&hours08=${moment(`${moment().format('YYYY/MM/DD')} 08:00:00`)
        .tz('UTC')
        .utc()
        .format('HH')}&hours20=${moment(`${moment().format('YYYY/MM/DD')} 20:00:00`)
        .tz('UTC')
        .utc()
        .format('HH')}&hours8=${moment(`${moment().format('YYYY/MM/DD')} 08:00:00`)
        .tz('UTC')
        .utc()
        .format('HH')}`,
    });
    dispatch({
      type: 'GET_ALL_UNAVAILABILITY_DOCTOR_SUCCESS',
      data: data,
    });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: 'GET_ALL_UNAVAILABILITY_DOCTOR_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: response.type || response.message,
    });
  }
};

const submitRDVForm = (values) => async (dispatch) => {
  dispatch({ type: 'CREATE_NEW_CONSULTATION_REQUESTING' });
  const {
    first_name,
    last_name,
    phone,
    gender,
    display_name,
    patient_id,
    diagnostic,
    city,
    startHours,
    endHours,
    note,
    date,
    status,
    motif_id,
    phone_number,
    doctor_id,
  } = values;
  let valNew = {
    first_name,
    last_name,
    phone,
    gender,
    display_name,
    doctor_id,
    note,
    patient_id,
    diagnostic,
    /*date_from: date
      ? moment(`${date} ${startHours}`).tz('UTC').utc().format('YYYY-MM-DD HH:mm:ss')
      : null,
    date_to: date
      ? moment(`${date} ${endHours}`).tz('UTC').utc().format('YYYY-MM-DD HH:mm:ss')
      : null,*/
    motif_id,
    status,
    city: city || '',
    phone_number,
  };
  if (date) {
    valNew.date_from = moment(`${date} ${startHours}`).tz('UTC').utc();
    valNew.date_to = moment(`${date} ${endHours}`).tz('UTC').utc();
  }
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/appointment/${values.id || ''}`,
      data:
        values.status === 'canceled'
          ? { status: 'canceled' }
          : values.status === 'completed'
          ? { status: 'completed' }
          : valNew,
    });
    const response = data;
    if (response.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={`${
              values.id
                ? `Rendez-vous modifié avec succès`
                : `Rendez-vous créé avec succès`
            }`}
          />
        ),
        { hideProgressBar: true },
      );
      if (values.status !== 'canceled')
        dispatch({
          type: 'CREATE_NEW_CONSULTATION_SUCCESS',
          msg: response.message,
          data: response.data,
        });
      else
        dispatch({
          type: 'UPDATE_STATUS_RDV_SUCCESS',
          msg: response.message,
          data: response.data,
        });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message={`${
              values.id
                ? `Échec de modification de rendez-vous`
                : `Échec de création de rendez-vous`
            }`}
          />
        ),
        { hideProgressBar: true },
      );
      //toast.error(`${values.id ? `Rdv n'est pas modifiéé` : `Rdv n'est pas ajouté`}`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'CREATE_NEW_CONSULTATION_FAILURE',
        msg: response.data,
        ErrorMessage: response.message,
        site: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={`${
            data.type === 'error'
              ? data.message
              : values.id
              ? `Échec de modification de rendez-vous`
              : `Échec de création de rendez-vous`
          }`}
        />
      ),
      { hideProgressBar: true },
    );
    //toast.error(`${data.type === 'error' ? data.message : values.id ? `Rdv n'est pas modifiéé` : `Rdv n'est pas ajouté`}`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'CREATE_NEW_CONSULTATION_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.type || data.message,
      data: values,
    });
  }
};
const submitUnavailabilityForm = (values) => async (dispatch) => {
  const { startDate, endDate, startHours, endHours, comment } = values;
  let val = {
    startDate: moment(`${moment(startDate).format('YYYY/MM/DD')} ${startHours}`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    startDate08: moment(`${moment(startDate).format('YYYY/MM/DD')} 08:00:00`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    startDate2359: moment(`${moment(startDate).format('YYYY/MM/DD')} 23:59:00`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    startDate00: moment(`${moment(startDate).format('YYYY/MM/DD')} 00:00:00`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    startDate1930: moment(`${moment(startDate).format('YYYY/MM/DD')} 19:30:00`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    hours08: moment(`${moment(startDate).format('YYYY/MM/DD')} 08:00:00`)
      .tz('UTC')
      .utc()
      .format('HH'),
    hours20: moment(`${moment(startDate).format('YYYY/MM/DD')} 20:00:00`)
      .tz('UTC')
      .utc()
      .format('HH'),
    startDate20: moment(`${moment(startDate).format('YYYY/MM/DD')} 20:00:00`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    endDate: moment(`${moment(endDate).format('YYYY/MM/DD')} ${endHours}`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    endDate20: moment(`${moment(endDate).format('YYYY/MM/DD')} 20:00:00`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    endDate08: moment(`${moment(endDate).format('YYYY/MM/DD')} 08:00:00`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    note: comment ? comment : 'Indisponible',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/unavailability/${values.id ? values.id : ''}`,
      data: val,
    });
    const response = data;
    if (response.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={
              values.id
                ? 'Indisponibilité modifiée avec succès'
                : 'Indisponibilité enregistrée avec succès'
            }
          />
        ),
        { hideProgressBar: true },
      );
      // toast.success(`Date a été ajouté`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'CREATE_NEW_DISPO_SUCCESS',
        msg: response.message,
        data: response.data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="error" message={response.message} />, {
        hideProgressBar: true,
      });
      // toast.error(response.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'CREATE_NEW_DISPO_FAILURE',
        msg: response.data,
        ErrorMessage: response.message,
        site: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
      hideProgressBar: true,
    });
    //  toast.error(data.message, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'CREATE_NEW_DISPO_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.type || data.message,
      data: values,
    });
  }
};

const getMotif = () => async (dispatch) => {
  dispatch({ type: 'GET_MOTIFS_AGENDA_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'get',
      url: `/api/motifs`,
    });
    dispatch({
      type: 'GET_MOTIFS_AGENDA_SUCCESS',
      data: data,
    });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: 'GET_MOTIFS_AGENDA_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: response ? response.type || response.message : '',
    });
  }
};

const getDipoDoctorByMotif = (values) => async (dispatch) => {
  dispatch({ type: 'GET_DISPONIBILITY_DOCTOR_BY_MOTIF_REQUESTING' });
  try {
    const body = {
      hours20: moment(`${moment(values.date).format('YYYY/MM/DD')} 20:00:00`)
        .tz('UTC')
        .utc()
        .format('HH'),
      hours08: moment(`${moment(values.date).format('YYYY/MM/DD')} 08:00:00`)
        .tz('UTC')
        .utc()
        .format('HH'),
      start_date: moment(moment(values.date).format('YYYY-MM-DD 08:00:00'))
        .tz('UTC')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(moment(values.date).format('YYYY-MM-DD 20:00:00'))
        .tz('UTC')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      motif_id: values.motif_id,
      rdv_id: values.rdv_id,
    };
    const { data } = await apiCall({
      method: 'post',
      url: `/api/getdipsodoctor`,
      data: body,
    });
    dispatch({
      type: 'GET_DISPONIBILITY_DOCTOR_BY_MOTIF_SUCCESS',
      data: data,
    });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: 'GET_DISPONIBILITY_DOCTOR_BY_MOTIF_FAILURE',
      // msg: response.data.message,
      // err: response.data.type,
    });
  }
};

const getLastAvailability = (id) => async (dispatch) => {
  dispatch({ type: 'GET_LAST_AVAILABILITY_REQUESTING' });
  try {
    const body = {
      hours20: moment(`${moment().format('YYYY/MM/DD')} 20:00:00`)
        .tz('UTC')
        .utc()
        .format('HH'),
      hoursNow: moment(moment().format('YYYY-MM-DD HH:mm:ss'))
        .tz('UTC')
        .utc()
        .format('HH'),
      hours08: moment(`${moment().format('YYYY/MM/DD')} 08:00:00`)
        .tz('UTC')
        .utc()
        .format('HH'),
      start_date: moment(moment().format('YYYY-MM-DD 08:00:00'))
        .tz('UTC')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(moment().format('YYYY-MM-DD 20:00:00'))
        .tz('UTC')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
    };
    const { data } = await apiCall({
      method: 'post',
      url: `/api/get/last/availability/doctor/${id}`,
      data: body,
    });
    dispatch({
      type: 'GET_LAST_AVAILABILITY_SUCCESS',
      data: data,
    });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: 'GET_LAST_AVAILABILITY_FAILURE',
      // msg: response.data.message,
      //err: response.data.type,
    });
  }
};

const deleteRdv = (id) => async (dispatch) => {
  dispatch({ type: 'GET_DELETE_RDV_REQUESTING' });
  try {
    const url = `/api/appointment/${id}`;
    const { data } = await apiCall({
      method: 'delete',
      url,
    });
    if (data.type === 'success')
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
    else
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    dispatch({ type: 'GET_DELETE_RDV_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec de suppression de rendez-vous" />
      ),
      {
        hideProgressBar: true,
      },
    );
    dispatch({
      type: 'GET_DELETE_RDV_FAILURE',
      err: err.message,
      status: getStatusError(err),
    });
  }
};

const changeParams = (params) => ({
  type: 'UPDATE_DATE_UNAVAILABILITY_PARAMS',
  params: params,
});

export default {
  deleteRdv,
  getLastAvailability,
  getDipoDoctorByMotif,
  getMotif,
  changeParams,
  getAllUnavailability,
  getAllRdvByDay,
  submitUnavailabilityForm,
  submitRDVForm,
};
