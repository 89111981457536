import React, { Component } from 'react';
import moment from 'moment';
import Modals from '../../../components/modal';
import { isHasPermission } from '../../../utils/helpres';
class Item_rdv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setIsOpenDeleteModal: false,
      setIsOpenCancelModal: false,
    };
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.openCancelModal = this.openCancelModal.bind(this);
  }
  openDeleteModal() {
    this.setState({
      setIsOpenDeleteModal: !this.state.setIsOpenDeleteModal,
    });
  }
  openCancelModal() {
    this.setState({
      setIsOpenCancelModal: !this.state.setIsOpenCancelModal,
    });
  }
  render() {
    const { item, params, user, userList } = this.props;
    const { setIsOpenDeleteModal, setIsOpenCancelModal } = this.state;
    const date_from = moment(item.date_from).format('HH:mm');
    const date_to = moment(item.date_to).format('HH:mm');
    return (
      <div
        style={{
          marginBottom: '10px',
          borderLeft: `5px solid ${item.practitioner.color}`,
        }}
        key={item.id}
        className="rendez_vous_list_body_item_container"
      >
        <div className="rendez_vous_list_body_item">
          <div
            style={{ display: 'contents' }}
            onClick={async () => {
              if (
                isHasPermission(user.login_access.permissions, 'SHOW_APPOINTMENT_DETAILS')
              ) {
                await this.props.getPayment(item.id);
                await this.props.getItemRdv(item.id);
                await this.props.openModal();
              }
            }}
          >
            <div className="rv_list_body_sub_item" id="time_container_indis">
              <i className="far fa-clock"></i>
              <span id="start_time"> {date_from}</span>
              <span id="end_time"> {date_to}</span>
            </div>
            <div
              className={
                item.status === 'actif'
                  ? 'rv_list_body_sub_item btn_status'
                  : 'rv_list_body_sub_item btn_status actif_status'
              }
            >
              <span>
                {item.status === 'actif'
                  ? 'Planifié'
                  : item.status === 'canceled'
                  ? 'Annulé'
                  : 'Terminé'}
              </span>
            </div>
            <div className="rv_list_body_sub_item rv_details">
              <div className="rv_text_details">
                <div className="patient_consultation_text">
                  <span id="type_consultation">
                    {item.patient
                      ? `${item.patient.first_name} ${item.patient.first_name}`
                      : item.display_name}
                  </span>
                  <span style={{ color: item.motif.color }} id="patient_name">
                    {item.motif && item.motif.label === 'Autre'
                      ? item.diagnostic
                      : item.motif.label}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="rv_more_details">
            <div className="expanded_more_details">
              {/*{item.status === 'actif' &&
              isHasPermission(
                user.login_access.permissions,
                'SHOW_APPOINTMENT_DETAILS',
              ) ? (
                <i
                  title="Voir detail"
                  onClick={() => {
                    this.props.getItemRdv(item.id);
                    this.props.openModal();
                  }}
                  id="expanded_more_details_view"
                  className="fas fa-arrow-right"
                ></i>
              ) : (
                ''
              )}*/}
              {isHasPermission(user.login_access.permissions, 'DELETE_APPOINTMENT') ? (
                <i
                  title="Supprimer rdv"
                  onClick={() => this.openDeleteModal()}
                  id="expanded_more_details_delete"
                  className="fas fa-trash"
                ></i>
              ) : (
                ''
              )}
              {item.status === 'canceled' || item.status === 'completed' ? (
                ''
              ) : isHasPermission(user.login_access.permissions, 'CANCEL_APPOINTMENT') ? (
                <i
                  title="Annuler rdv"
                  onClick={() => this.openCancelModal()}
                  className="far fa-window-close"
                ></i>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <Modals
          setIsOpen={setIsOpenDeleteModal}
          openModal={this.openDeleteModal}
          data={
            <>
              <div className="modal-header" style={{ marginTop: '-30px' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openDeleteModal()}
                  style={{ marginTop: '1%' }}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '5px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Suppression!</h2>
                <span>Etes-vous sûr de vouloir supprimer ce rendez-vous ?</span>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => this.openDeleteModal()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                  style={{ backgroundColor: '#f6f6f6' }}
                >
                  Non
                </button>
                <button
                  onClick={() => {
                    this.props.deleteRdv(
                      params,
                      item.id,
                      userList.length > 1 ? userList : item.practitioner_id,
                    );
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
        <Modals
          setIsOpen={setIsOpenCancelModal}
          openModal={this.openCancelModal}
          data={
            <>
              <div className="modal-header" style={{ marginTop: '-30px' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openCancelModal()}
                  style={{ marginTop: '1%' }}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '5px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Annuler!</h2>
                <span>Etes-vous sûr de vouloir annuler ce rendez-vous ?</span>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => this.openCancelModal()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                  style={{ backgroundColor: '#f6f6f6' }}
                >
                  Non
                </button>
                <button
                  onClick={async () => {
                    await this.props.submitRDVForm(
                      params,
                      {
                        status: 'canceled',
                        id: item.id,
                      },
                      userList.length > 1 ? userList : item.practitioner_id,
                    );
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
      </div>
    );
  }
}
export default Item_rdv;
