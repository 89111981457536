import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Illustration from '../../../assets/img/cuida_illustration.png';

class Formation extends Component {
  render() {
    return (
      <div style={{ width: '100%', margin: '1% 2%' }}>
        <div className="contact_us" style={{ height: '100%' }}>
          <div className="contact_us_text">
            <span id="contact_us_title">Serie vidéos</span>
            <span id="contact_us_subtext">Une serie des vidéo de demonstration</span>
          </div>
          <div className="faq-sect-col-elem" style={{ padding: '8px' }}>
            <Box sx={{ width: '100%' }}>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={4} style={{marginBottom:'20px'}}>
                  <a target="_blank" href="https://www.youtube.com/">
                    <img
                      style={{ width: '100%' }}
                      src={Illustration}
                    />
                  </a>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    Demonstration Inscription sur CUIDA
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    12 déc 2021 à 10:00
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{marginBottom:'20px'}}>
                  <a target="_blank" href="https://www.youtube.com/">
                    <img
                      style={{ width: '100%' }}
                      src={Illustration}
                    />
                  </a>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    Demonstration Inscription sur CUIDA
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    12 déc 2021 à 10:00
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{marginBottom:'20px'}}>
                  <a target="_blank" href="https://www.youtube.com/">
                    <img
                      style={{ width: '100%' }}
                      src={Illustration}
                    />
                  </a>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    Demonstration Inscription sur CUIDA
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    12 déc 2021 à 10:00
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{marginBottom:'20px'}}>
                  <a target="_blank" href="https://www.youtube.com/">
                    <img
                      style={{ width: '100%' }}
                      src={Illustration}
                    />
                  </a>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    Demonstration Inscription sur CUIDA
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    12 déc 2021 à 10:00
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{marginBottom:'20px'}}>
                  <a target="_blank" href="https://www.youtube.com/">
                    <img
                      style={{ width: '100%' }}
                      src={Illustration}
                    />
                  </a>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    Demonstration Inscription sur CUIDA
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    12 déc 2021 à 10:00
                  </Typography>
                </Grid>

                <Grid item xs={4} style={{marginBottom:'20px'}}>
                  <a target="_blank" href="https://www.youtube.com/">
                    <img
                      style={{ width: '100%' }}
                      src={Illustration}
                    />
                  </a>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    Demonstration Inscription sur CUIDA
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    12 déc 2021 à 10:00
                  </Typography>
                </Grid>

                <Grid item xs={4} style={{marginBottom:'20px'}}>
                  <a target="_blank" href="https://www.youtube.com/">
                    <img
                      style={{ width: '100%' }}
                      src={Illustration}
                    />
                  </a>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    Demonstration Inscription sur CUIDA
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    12 déc 2021 à 10:00
                  </Typography>
                </Grid>
                
              </Grid>
            </Box>

            {/* <div className="carousel-inner">

              <div className="carousel-item-content">
                <a target="_blank" href="https://www.youtube.com/">
                  <img
                      style={{ width: '100%' }}
                      className="d-block w-100"
                      src="https://m.media-amazon.com/images/M/MV5BMDE0ZjMzOTAtMzU2OS00ZDVmLWFlMjYtMThlOWYwMDBiN2E3XkEyXkFqcGdeQWFsZWxvZw@@._V1_.jpg"
                  />
                </a>
                <a target="_blank" href="https://www.youtube.com/">
                  <img
                      style={{ width: '100%' }}
                      className="d-block w-100"
                      src="https://m.media-amazon.com/images/M/MV5BMDE0ZjMzOTAtMzU2OS00ZDVmLWFlMjYtMThlOWYwMDBiN2E3XkEyXkFqcGdeQWFsZWxvZw@@._V1_.jpg"
                  />
                </a>
                <a target="_blank" href="https://www.youtube.com/">
                  <img
                      style={{ width: '100%' }}
                      className="d-block w-100"
                      src="https://m.media-amazon.com/images/M/MV5BMDE0ZjMzOTAtMzU2OS00ZDVmLWFlMjYtMThlOWYwMDBiN2E3XkEyXkFqcGdeQWFsZWxvZw@@._V1_.jpg"
                  />
                </a>
                <a target="_blank" href="https://www.youtube.com/">
                  <img
                      style={{ width: '100%' }}
                      className="d-block w-100"
                      src="https://m.media-amazon.com/images/M/MV5BMDE0ZjMzOTAtMzU2OS00ZDVmLWFlMjYtMThlOWYwMDBiN2E3XkEyXkFqcGdeQWFsZWxvZw@@._V1_.jpg"
                  />
                </a>
                <a target="_blank" href="https://www.youtube.com/">
                  <img
                      style={{ width: '100%' }}
                      className="d-block w-100"
                      src="https://m.media-amazon.com/images/M/MV5BMDE0ZjMzOTAtMzU2OS00ZDVmLWFlMjYtMThlOWYwMDBiN2E3XkEyXkFqcGdeQWFsZWxvZw@@._V1_.jpg"
                  />
                </a>
                <a target="_blank" href="https://www.youtube.com/">
                  <img
                      style={{ width: '100%' }}
                      className="d-block w-100"
                      src="https://m.media-amazon.com/images/M/MV5BMDE0ZjMzOTAtMzU2OS00ZDVmLWFlMjYtMThlOWYwMDBiN2E3XkEyXkFqcGdeQWFsZWxvZw@@._V1_.jpg"
                  />
                </a>
              </div>
            </div>
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-ride="carousel"
            >
              <a
                className="carousel-control-prev"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="prev"
                style={{
                  backgroundColor: 'gray',
                  borderRadius: '50%',
                  padding: '1%',
                }}
              >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>


              <a
                className="carousel-control-next"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="next"
                style={{
                  backgroundColor: 'gray',
                  borderRadius: '50%',
                  padding: '1%',
                }}
              >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>*/}
          </div>
        </div>
      </div>
    );
  }
}

export default Formation;
