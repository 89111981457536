import React, { Component } from 'react';
import Form from '../containers/form';
import Modals from '../../../components/modal';
import actionsJs from '../../../js/actions';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import HeaderList from '../../../components/headerList';

const mapStateToProps = (state) => ({
  isLoadingSecretary: state.AppReducer.isLoadingSecretary,
  secretary: state.AppReducer.secretary,
  actionSecretary: state.AppReducer.actionSecretary,
  switchList: state.AppReducer.switchList,
  activeItemSideBarSETTINGS: state.AppReducer.activeItemSideBarSETTINGS,
  create: state.AppReducer.create,
});

const mapDispatchToProps = (dispatch) => ({
  getSecretaries: () => dispatch(actionsJs.getSecretaries()),
  getSecretary: (id) => dispatch(actionsJs.getSecretary(id)),
});

const headers = [
  { name: 'Nom & prénom', width: '30%', type: 'text' },
  { name: 'Email', width: '23%', type: 'string' },
  { name: 'Téléphone', width: '23%', type: 'string' },
  { name: 'Status', width: '20%', type: 'string' },
];
class SecretarialManagement extends Component {
  constructor(props) {
    super(props);
    props.changeNamePage('Gestion des secrétariat');
    document.title = 'Gestion des secrétariat';
    this.state = {
      setIsOpen: false,
      create: false,
      switchList: false,
      employee_selected: null,
    };
    this.openModal = this.openModal.bind(this);
    this.handleSelectItem = this.handleSelectItem.bind(this);
  }

  openModal() {
    this.setState({
      setIsOpen: !this.state.setIsOpen,
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { actionSecretary, activeItemSideBarSETTINGS } = this.props;
    if (
      actionSecretary &&
      actionSecretary !== prevProps.actionSecretary &&
      actionSecretary.id
    ) {
      this.props.changeActiveItemSideBarSettings(activeItemSideBarSETTINGS, false);
    }
  }
  handleSelectItem() {
    this.setState({
      switchList: false,
      create: false,
    });
  }
  render() {
    const { setIsOpen } = this.state;
    const {
      sandwich_menu_btn,
      secretary,
      isLoadingSecretary,
      secretaries,
      switchList,
      activeItemSideBarSETTINGS,
      create,
    } = this.props;
    return (
      <div
        className="general_info"
        style={{ marginTop: 5, width: '87%', height: 'auto' }}
      >
        <Modals
          setIsOpen={setIsOpen}
          openModal={this.openModal}
          data={
            <>
              {' '}
              <div className="modal-header" style={{ marginTop: '0' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModal()}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '10px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                {secretaries && secretaries.length > 0
                  ? secretaries.map((item) => {
                      if (item.login_access.access_status === 'disabled')
                        return (
                          <div
                            key={item.id}
                            style={{ padding: '2%', marginBottom: '15px' }}
                            //className="contact_patients_body_item"
                          >
                            <div
                              className="patient_contact_info_container"
                              style={{ height: '60px' }}
                            >
                              <div
                                className="patient_contact_img_container"
                                style={{
                                  width: '60px',
                                  height: '60px',
                                }}
                              >
                                {item.photo ? (
                                  <img
                                    src={`${process.env.REACT_APP_PUBLIC_URL}${item.photo}`}
                                  />
                                ) : (
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 33 34"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5.73958 23.2812C9.42708 21.7812 12.9583 21.0312 16.3333 21.0312C19.7083 21.0312 23.2083 21.7812 26.8333 23.2812C30.5208 24.7188 32.3646 26.625 32.3646 29V33.0312H0.302084V29C0.302084 26.625 2.11458 24.7188 5.73958 23.2812ZM21.9583 14.6562C20.3958 16.2188 18.5208 17 16.3333 17C14.1458 17 12.2708 16.2188 10.7083 14.6562C9.14583 13.0938 8.36458 11.2188 8.36458 9.03125C8.36458 6.84375 9.14583 4.96875 10.7083 3.40625C12.2708 1.78125 14.1458 0.96875 16.3333 0.96875C18.5208 0.96875 20.3958 1.78125 21.9583 3.40625C23.5208 4.96875 24.3021 6.84375 24.3021 9.03125C24.3021 11.2188 23.5208 13.0938 21.9583 14.6562Z"
                                      fill="#8083A3"
                                    ></path>
                                  </svg>
                                )}
                              </div>
                              <div
                                className=""
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: '30%',
                                }}
                              >
                                <span id="patient_contact_name">{`${item.first_name} ${item.last_name}`}</span>
                                <span
                                  className="sub_title"
                                  id="patient_contact_city"
                                  style={{ color: 'grey' }}
                                >
                                  {item.phone}
                                </span>
                              </div>
                              <div
                                className={
                                  item.login_access.access_status === 'active'
                                    ? 'rv_list_body_sub_item btn_status'
                                    : 'rv_list_body_sub_item btn_status actif_status'
                                }
                              >
                                <span>
                                  {item.login_access.access_status === 'active'
                                    ? 'Actif'
                                    : 'Inactif'}
                                </span>
                              </div>
                            </div>

                            <div className="call_icon_patient">
                              <i className="fas fa-angle-right"></i>
                            </div>
                          </div>
                        );
                    })
                  : 'Pas de secretaires'}
              </div>
              <div className="modal-footer">
                <button
                  style={{ backgroundColor: '#f6f6f6' }}
                  onClick={() => this.openModal()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                >
                  Annuler
                </button>
              </div>
            </>
          }
        />
        {switchList ? (
          isLoadingSecretary ? (
            <CircularProgress
              disableShrink
              style={{ marginLeft: '33%', marginTop: '20%' }}
            />
          ) : (
            <div className="" style={{ width: '100%' }}>
              <div className="container_changeable_content" style={{ height: '100%' }}>
                <div className="patient_detailed_info_section">
                  <svg
                    onClick={async () => {
                      this.props.changeActiveItemSideBarSettings(
                        activeItemSideBarSETTINGS,
                        false,
                      );
                    }}
                    width="46"
                    height="46"
                    viewBox="0 0 46 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ cursor: 'pointer' }}
                  >
                    <rect
                      x="0.5"
                      y="45.5"
                      width="45"
                      height="45"
                      rx="9.5"
                      transform="rotate(-90 0.5 45.5)"
                      fill="white"
                      stroke="#ECEEF5"
                    ></rect>
                    <path
                      d="M20.0117 23L24.5117 18.5L25.5664 19.5547L22.1211 23L25.5664 26.4453L24.5117 27.5L20.0117 23Z"
                      fill="#8083A3"
                    ></path>
                  </svg>
                  <Form
                    goBack={async () => {
                      this.props.changeActiveItemSideBarSettings(
                        activeItemSideBarSETTINGS,
                        false,
                      );
                    }}
                    secretary={create ? null : secretary}
                    type="secretary"
                    isLoadingSecretary={isLoadingSecretary}
                    sandwich_menu_btn={sandwich_menu_btn}
                    handleSelectItem={this.handleSelectItem}
                  />
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="contact_patients_body" style={{ height: '100%' }}>
            <div style={{ display: 'flex', gap: 10 }}>
              <svg
                onClick={async () => {
                  this.props.goBackItem('showSecretarialManagement');
                }}
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
              >
                <rect
                  x="0.5"
                  y="45.5"
                  width="45"
                  height="45"
                  rx="9.5"
                  transform="rotate(-90 0.5 45.5)"
                  fill="white"
                  stroke="#ECEEF5"
                ></rect>
                <path
                  d="M20.0117 23L24.5117 18.5L25.5664 19.5547L22.1211 23L25.5664 26.4453L24.5117 27.5L20.0117 23Z"
                  fill="#8083A3"
                ></path>
              </svg>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 10,
                }}
              >
                <span className="patients_consultations_record_label">
                  Liste des secrétaires
                </span>{' '}
                {/*<span
                onClick={async () => {
                  this.props.changeActiveItemSideBarSettings(
                    activeItemSideBarSETTINGS,
                    true,
                  );
                  this.setState({ create: true });
                }}
                style={{ float: 'right' }}
                className="patients_consultations_record_label"
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="39"
                    height="39"
                    rx="9.5"
                    fill="white"
                    stroke="#ECEEF5"
                  ></rect>
                  <path
                    d="M25.2383 20.7383H20.7383V25.2383H19.2617V20.7383H14.7617V19.2617H19.2617V14.7617H20.7383V19.2617H25.2383V20.7383Z"
                    fill="#8083A3"
                  ></path>
                </svg>
              </span>*/}
              </div>
            </div>
            <br />
            <HeaderList headers={headers} />
            {/*{secretaries &&
            secretaries.length > 0 &&
            secretaries.find((item) => item.login_access.access_status === 'disabled') ? (
              <span
                onClick={() => this.openModal()}
                style={{ marginLeft: '50%', color: '#5446db', cursor: 'pointer' }}
                className="patients_consultations_record_label"
              >
                Afficher l’archive
              </span>
            ) : (
              ''
            )}*/}
            {secretaries && secretaries.length > 0 ? (
              secretaries.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="contact_patients_body_item"
                    onClick={async () => {
                      await this.props.getSecretary(item.id);
                      await this.handleSelectItem(item.id);
                      this.props.changeActiveItemSideBarSettings(
                        activeItemSideBarSETTINGS,
                        true,
                      );
                    }}
                  >
                    <div
                      className="patient_contact_info_container"
                      style={{ width: '95%' }}
                    >
                      <div
                        className="patient_contact_img_container"
                        style={{ width: '60px', height: '60px' }}
                      >
                        {item.photo ? (
                          <img src={`${process.env.REACT_APP_PUBLIC_URL}${item.photo}`} />
                        ) : (
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 33 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.73958 23.2812C9.42708 21.7812 12.9583 21.0312 16.3333 21.0312C19.7083 21.0312 23.2083 21.7812 26.8333 23.2812C30.5208 24.7188 32.3646 26.625 32.3646 29V33.0312H0.302084V29C0.302084 26.625 2.11458 24.7188 5.73958 23.2812ZM21.9583 14.6562C20.3958 16.2188 18.5208 17 16.3333 17C14.1458 17 12.2708 16.2188 10.7083 14.6562C9.14583 13.0938 8.36458 11.2188 8.36458 9.03125C8.36458 6.84375 9.14583 4.96875 10.7083 3.40625C12.2708 1.78125 14.1458 0.96875 16.3333 0.96875C18.5208 0.96875 20.3958 1.78125 21.9583 3.40625C23.5208 4.96875 24.3021 6.84375 24.3021 9.03125C24.3021 11.2188 23.5208 13.0938 21.9583 14.6562Z"
                              fill="#8083A3"
                            ></path>
                          </svg>
                        )}
                      </div>
                      <div
                        style={{ width: '30%', display: 'flex', flexDirection: 'column' }}
                      >
                        <span id="patient_contact_name">{`${item.first_name} ${item.last_name}`}</span>
                        <span id="patient_contact_city">{item.gsm}</span>
                      </div>
                      <div style={{ width: '25%' }} className="patient_contact_info">
                        <span id="patient_contact_city">{item.email || '-'}</span>
                      </div>
                      <div style={{ width: '22%' }} className="patient_contact_info">
                        <span id="patient_contact_city">{item.phone || '-'}</span>
                      </div>
                      <div style={{ width: '10%' }} className="rv_list_body_sub_item">
                        <span>
                          {item.login_access.access_status === 'active' ? (
                            <i style={{ color: '#20c997' }} className="fas fa-check"></i>
                          ) : (
                            <i style={{ color: '#dc3545' }} className="fas fa-lock"></i>
                          )}
                        </span>
                      </div>
                    </div>

                    <div style={{ width: '5%' }} className="call_icon_patient">
                      <i className="fas fa-angle-right"></i>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="consultation_detailed_info_section">
                <div className="empty_consultation_result_container">
                  <div className="empty_consultation_result">
                    <img
                      src={require('../../../assets/img/undraw_design_team_af2y.svg')}
                    />
                    <span id="no_consultation">Liste vide !</span>
                    <span id="no_consultation_desc">
                      Veuillez créer des nouvelles secrétaires dans votre centre.
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SecretarialManagement);
