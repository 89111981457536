import React, { Component } from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modals from '../../../components/modal';
import actions from '../../../js/actions';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  return {
    isLoadingPermissionUser: state.AppReducer.isLoadingPermissionUser,
    permissionsUser: state.AppReducer.permissionsUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  submitUserForm: async (values, id) => {
    await dispatch(actions.submitUserForm(values));
    dispatch(actions.getUserById(id));
  },
  addPermissionsUser: async (values, id) => {
    await dispatch(actions.addPermissionsUser(values));
    dispatch(actions.getPermissionsByUser(id));
  },
  addGroupPermissionsUser: async (values, id) => {
    await dispatch(actions.addGroupPermissionsUser(values));
    setTimeout(() => {
      dispatch(actions.getPermissionsByUser(id));
    }, 500);
  },
  getPermissionsByUser: (id) => dispatch(actions.getPermissionsByUser(id)),
});

class Permission extends Component {
  constructor(props) {
    super(props);
    props.changeNamePage('Gestion des permissions');
    document.title = 'Gestion des permissions';
    this.state = {
      account_type: false,
      switchList: false,
      employee_selected: null,
      value: 0,
      item: null,
      setIsOpen: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({
      setIsOpen: !this.state.setIsOpen,
    });
  }

  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  handleChange = (event, value) => {
    this.setState({
      value,
    });
  };

  handleSelectItem(item) {
    // const data = dataUser.find((item) => item.id === id);
    this.setState({
      //employee_selected: data,
      item: item,
    });
  }

  render() {
    const { setIsOpen, account_type, value, item } = this.state;
    const { doctors, secretaries, permissionsUser, assistants, group_permissions } =
      this.props;
    let allDoctors = doctors;
    if (doctors && doctors.length > 0)
      allDoctors = doctors.filter((item) => item.login_access.offices.length === 0);
    return (
      <div
        className="main_content"
        style={{
          overflow: 'hidden',
          width: '100%',
          padding: '5%',
          height: '100%',
        }}
      >
        <svg
          onClick={async () => {
            this.props.goBackItem('showPermission');
          }}
          width="46"
          height="46"
          viewBox="0 0 46 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ cursor: 'pointer' }}
        >
          <rect
            x="0.5"
            y="45.5"
            width="45"
            height="45"
            rx="9.5"
            transform="rotate(-90 0.5 45.5)"
            fill="white"
            stroke="#ECEEF5"
          ></rect>
          <path
            d="M20.0117 23L24.5117 18.5L25.5664 19.5547L22.1211 23L25.5664 26.4453L24.5117 27.5L20.0117 23Z"
            fill="#8083A3"
          ></path>
        </svg>
        <Modals
          setIsOpen={setIsOpen}
          openModal={this.openModal}
          data={
            <>
              {' '}
              <div className="modal-header" style={{ marginTop: '0' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModal()}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '10px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div
                style={{ display: 'block', height: '500px', overflow: 'auto' }}
                className="modal-body"
              >
                {group_permissions && group_permissions.length > 0
                  ? group_permissions.map((group_permission) => {
                      if (account_type) {
                        ///if (group_permission.name !== "L'agenda")
                        return (
                          <div
                            style={{ display: 'block', width: '90%' }}
                            className="notification_info_form_item"
                          >
                            <div
                              style={{ display: 'flex' }}
                              className="options_container_item option1"
                            >
                              <div className="seance_number" style={{ width: '70%' }}>
                                <span>{group_permission.name}</span>
                              </div>
                              <div style={{ width: '10%' }} className="seance-status">
                                <div
                                  style={{ cursor: 'pointer' }}
                                  //className="active_btn"
                                  onClick={(e) => {
                                    this.props.addGroupPermissionsUser(
                                      {
                                        group_permission_id: group_permission.id,
                                        user_id: item.login_access.id,
                                        checked:
                                          permissionsUser.length > 0 &&
                                          permissionsUser.find(
                                            (item) =>
                                              item.permission.group_permission_id ===
                                              group_permission.id,
                                          )
                                            ? false
                                            : true,
                                      },
                                      item.login_access.id,
                                    );
                                  }}
                                  className={
                                    permissionsUser.length > 0 &&
                                    permissionsUser.find(
                                      (item) =>
                                        item.permission.group_permission_id ===
                                        group_permission.id,
                                    )
                                      ? 'active_btn group-btn'
                                      : 'deactive_btn'
                                  }
                                >
                                  <div
                                    className={
                                      permissionsUser.length > 0 &&
                                      permissionsUser.find(
                                        (item) =>
                                          item.permission.group_permission_id ===
                                          group_permission.id,
                                      )
                                        ? 'deactive_btn_circle active_btn_circle'
                                        : 'deactive_btn_circle'
                                    }
                                    // className="deactive_btn_circle active_btn_circle"
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <br />
                            {group_permission.permissions.length > 0 &&
                              group_permission.permissions.map((permission) => {
                                return (
                                  <div style={{ margin: '1%', display: 'flex' }}>
                                    <div
                                      style={{ width: '70%' }}
                                      className="options_container_item option1"
                                    >
                                      <div
                                        className="seance_number"
                                        style={{ width: '80%' }}
                                      >
                                        <span>{permission.label}</span>
                                      </div>
                                    </div>
                                    <div
                                      style={{ width: '10%' }}
                                      className="seance-status"
                                    >
                                      <div
                                        style={{ cursor: 'pointer' }}
                                        //className="active_btn"
                                        onClick={(e) => {
                                          /*if (
                                              permissionsUser.length > 0 &&
                                              permissionsUser.find(
                                                (item) =>
                                                  item.permission.group_permission_id ===
                                                  group_permission.id,
                                              )
                                            )*/
                                          this.props.addPermissionsUser(
                                            {
                                              user_id: item.login_access.id,
                                              permission_id: permission.id,
                                              checked:
                                                permissionsUser.length > 0 &&
                                                permissionsUser.find(
                                                  (item) =>
                                                    item.permission_id === permission.id,
                                                )
                                                  ? false
                                                  : true,
                                            },
                                            item.login_access.id,
                                          );
                                        }}
                                        className={
                                          permissionsUser.length > 0 &&
                                          permissionsUser.find(
                                            (item) =>
                                              item.permission_id === permission.id &&
                                              item.permission.group_permission_id ===
                                                group_permission.id,
                                          )
                                            ? 'active_btn'
                                            : 'deactive_btn'
                                        }
                                      >
                                        <div
                                          className={
                                            permissionsUser.length > 0 &&
                                            permissionsUser.find(
                                              (item) =>
                                                item.permission_id === permission.id &&
                                                item.permission.group_permission_id ===
                                                  group_permission.id,
                                            )
                                              ? 'deactive_btn_circle active_btn_circle'
                                              : 'deactive_btn_circle'
                                          }
                                          // className="deactive_btn_circle active_btn_circle"
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        );
                      } else
                        return (
                          <div
                            style={{ display: 'block', width: '90%' }}
                            className="notification_info_form_item"
                          >
                            <div
                              style={{ display: 'flex' }}
                              className="options_container_item option1"
                            >
                              <div className="seance_number" style={{ width: '70%' }}>
                                <span>{group_permission.name}</span>
                              </div>
                              <div style={{ width: '10%' }} className="seance-status">
                                <div
                                  style={{ cursor: 'pointer' }}
                                  //className="active_btn"
                                  onClick={(e) => {
                                    this.props.addGroupPermissionsUser(
                                      {
                                        group_permission_id: group_permission.id,
                                        user_id: item.login_access.id,
                                        checked:
                                          permissionsUser.length > 0 &&
                                          permissionsUser.find(
                                            (item) =>
                                              item.permission.group_permission_id ===
                                              group_permission.id,
                                          )
                                            ? false
                                            : true,
                                      },
                                      item.login_access.id,
                                    );
                                  }}
                                  className={
                                    permissionsUser.length > 0 &&
                                    permissionsUser.find(
                                      (item) =>
                                        item.permission.group_permission_id ===
                                        group_permission.id,
                                    )
                                      ? 'active_btn group-btn'
                                      : 'deactive_btn'
                                  }
                                >
                                  <div
                                    className={
                                      permissionsUser.length > 0 &&
                                      permissionsUser.find(
                                        (item) =>
                                          item.permission.group_permission_id ===
                                          group_permission.id,
                                      )
                                        ? 'deactive_btn_circle active_btn_circle'
                                        : 'deactive_btn_circle'
                                    }
                                    // className="deactive_btn_circle active_btn_circle"
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <br />
                            {group_permission.permissions.length > 0 &&
                              group_permission.permissions.map((permission) => {
                                return (
                                  <div style={{ margin: '1%', display: 'flex' }}>
                                    <div
                                      style={{ width: '70%' }}
                                      className="options_container_item option1"
                                    >
                                      <div
                                        className="seance_number"
                                        style={{ width: '80%' }}
                                      >
                                        <span>{permission.label}</span>
                                      </div>
                                    </div>
                                    <div
                                      style={{ width: '10%' }}
                                      className="seance-status"
                                    >
                                      <div
                                        style={{ cursor: 'pointer' }}
                                        //className="active_btn"
                                        onClick={(e) => {
                                          /*if (
                                            permissionsUser.length > 0 &&
                                            permissionsUser.find(
                                              (item) =>
                                                item.permission.group_permission_id ===
                                                group_permission.id,
                                            )
                                          )*/
                                          this.props.addPermissionsUser(
                                            {
                                              user_id: item.login_access.id,
                                              permission_id: permission.id,
                                              checked:
                                                permissionsUser.length > 0 &&
                                                permissionsUser.find(
                                                  (item) =>
                                                    item.permission_id === permission.id,
                                                )
                                                  ? false
                                                  : true,
                                            },
                                            item.login_access.id,
                                          );
                                        }}
                                        className={
                                          permissionsUser.length > 0 &&
                                          permissionsUser.find(
                                            (item) =>
                                              item.permission_id === permission.id &&
                                              item.permission.group_permission_id ===
                                                group_permission.id,
                                          )
                                            ? 'active_btn'
                                            : 'deactive_btn'
                                        }
                                      >
                                        <div
                                          className={
                                            permissionsUser.length > 0 &&
                                            permissionsUser.find(
                                              (item) =>
                                                item.permission_id === permission.id &&
                                                item.permission.group_permission_id ===
                                                  group_permission.id,
                                            )
                                              ? 'deactive_btn_circle active_btn_circle'
                                              : 'deactive_btn_circle'
                                          }
                                          // className="deactive_btn_circle active_btn_circle"
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        );
                    })
                  : ''}
              </div>
            </>
          }
        />
        <div
          className="general_info"
          style={{ display: 'contents', width: '87%', height: '100%' }}
        >
          <div className="diploma_info" style={{ overflow: 'auto', display: 'block' }}>
            <AppBar position="static" style={{ backgroundColor: 'mediumaquamarine' }}>
              <Tabs
                value={value}
                onChange={this.handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Collaborateur " {...this.a11yProps(0)} />
                <Tab label="Secrétaire " {...this.a11yProps(1)} />
                <Tab label="Assistant" {...this.a11yProps(2)} />
              </Tabs>
            </AppBar>
            <br />
            <br />
            <TabPanel value={value} index={0}>
              <div className="modal_body_recap_details">
                {allDoctors && allDoctors.length > 0 ? (
                  allDoctors.map((doctor) => {
                    return (
                      <div
                        key={doctor.id}
                        className="contact_patients_body_item"
                        onClick={async () => {
                          await this.props.getPermissionsByUser(doctor.login_access.id);
                          await this.handleSelectItem(doctor);
                          this.openModal();
                          this.setState({ switchList: true, account_type: false });
                        }}
                      >
                        <div
                          style={{
                            alignItems: 'center',
                          }}
                          className="patient_contact_info_container"
                        >
                          <div
                            className="patient_contact_img_container"
                            style={{ marginLeft: '40px', width: '60px', height: '60px' }}
                          >
                            {doctor.image_practitioners.length > 0 ? (
                              <img
                                src={`${process.env.REACT_APP_PUBLIC_URL}${doctor.image_practitioners[0].url}`}
                              />
                            ) : (
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 33 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.73958 23.2812C9.42708 21.7812 12.9583 21.0312 16.3333 21.0312C19.7083 21.0312 23.2083 21.7812 26.8333 23.2812C30.5208 24.7188 32.3646 26.625 32.3646 29V33.0312H0.302084V29C0.302084 26.625 2.11458 24.7188 5.73958 23.2812ZM21.9583 14.6562C20.3958 16.2188 18.5208 17 16.3333 17C14.1458 17 12.2708 16.2188 10.7083 14.6562C9.14583 13.0938 8.36458 11.2188 8.36458 9.03125C8.36458 6.84375 9.14583 4.96875 10.7083 3.40625C12.2708 1.78125 14.1458 0.96875 16.3333 0.96875C18.5208 0.96875 20.3958 1.78125 21.9583 3.40625C23.5208 4.96875 24.3021 6.84375 24.3021 9.03125C24.3021 11.2188 23.5208 13.0938 21.9583 14.6562Z"
                                  fill="#8083A3"
                                ></path>
                              </svg>
                            )}
                          </div>
                          <div
                            style={{ width: '200px' }}
                            className="patient_contact_info"
                          >
                            <span id="patient_contact_name">{`${doctor.first_name} ${doctor.last_name}`}</span>
                            <span id="patient_contact_city">{doctor.speciality}</span>
                          </div>
                        </div>

                        <div className="call_icon_patient">
                          <i className="fas fa-angle-right"></i>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="consultation_detailed_info_section">
                    <div className="empty_consultation_result_container">
                      <div className="empty_consultation_result">
                        <img
                          src={require('../../../assets/img/undraw_design_team_af2y.svg')}
                        />
                        <span id="no_consultation">Liste vide !</span>
                        <span id="no_consultation_desc">
                          Essayez de créer de nouveaux collaborateurs
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="modal_body_recap_details">
                {secretaries && secretaries.length > 0 ? (
                  secretaries.map((secretary) => {
                    if (secretary.login_access.access_status === 'active')
                      return (
                        <div
                          key={secretary.id}
                          className="contact_patients_body_item"
                          onClick={async () => {
                            await this.props.getPermissionsByUser(
                              secretary.login_access.id,
                            );
                            await this.handleSelectItem(secretary);
                            this.openModal();
                            this.setState({ switchList: true, account_type: false });
                          }}
                        >
                          <div
                            style={{
                              alignItems: 'center',
                            }}
                            className="patient_contact_info_container"
                          >
                            <div
                              className="patient_contact_img_container"
                              style={{
                                marginLeft: '40px',
                                width: '60px',
                                height: '60px',
                              }}
                            >
                              {secretary.photo ? (
                                <img
                                  src={`${process.env.REACT_APP_PUBLIC_URL}${secretary.photo}`}
                                />
                              ) : (
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 33 34"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.73958 23.2812C9.42708 21.7812 12.9583 21.0312 16.3333 21.0312C19.7083 21.0312 23.2083 21.7812 26.8333 23.2812C30.5208 24.7188 32.3646 26.625 32.3646 29V33.0312H0.302084V29C0.302084 26.625 2.11458 24.7188 5.73958 23.2812ZM21.9583 14.6562C20.3958 16.2188 18.5208 17 16.3333 17C14.1458 17 12.2708 16.2188 10.7083 14.6562C9.14583 13.0938 8.36458 11.2188 8.36458 9.03125C8.36458 6.84375 9.14583 4.96875 10.7083 3.40625C12.2708 1.78125 14.1458 0.96875 16.3333 0.96875C18.5208 0.96875 20.3958 1.78125 21.9583 3.40625C23.5208 4.96875 24.3021 6.84375 24.3021 9.03125C24.3021 11.2188 23.5208 13.0938 21.9583 14.6562Z"
                                    fill="#8083A3"
                                  ></path>
                                </svg>
                              )}
                            </div>
                            <div
                              style={{ width: '200px' }}
                              className="patient_contact_info"
                            >
                              <span id="patient_contact_name">{`${secretary.first_name} ${secretary.last_name}`}</span>
                              <span id="patient_contact_city">
                                {secretary.speciality}
                              </span>
                            </div>
                          </div>

                          <div className="call_icon_patient">
                            <i className="fas fa-angle-right"></i>
                          </div>
                        </div>
                      );
                  })
                ) : (
                  <div
                    style={{ height: 'calc(60vh - 64px)' }}
                    className="consultation_detailed_info_section"
                  >
                    <div className="empty_consultation_result_container">
                      <div className="empty_consultation_result">
                        <img
                          src={require('../../../assets/img/undraw_design_team_af2y.svg')}
                        />
                        <span id="no_consultation"> Liste vide !</span>
                        <span id="no_consultation_desc">
                          Veuillez créer des nouvelles secrétaires dans la rubrique
                          gestion des secrétaires.
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="modal_body_recap_details">
                {assistants && assistants.length > 0 ? (
                  assistants.map((assistant) => {
                    if (assistant.login_access.access_status === 'active')
                      return (
                        <div
                          key={assistant.id}
                          className="contact_patients_body_item"
                          onClick={async () => {
                            await this.props.getPermissionsByUser(
                              assistant.login_access.id,
                            );
                            await this.handleSelectItem(assistant);
                            this.openModal();
                            this.setState({ switchList: true, account_type: true });
                          }}
                        >
                          <div
                            style={{
                              alignItems: 'center',
                            }}
                            className="patient_contact_info_container"
                          >
                            <div
                              className="patient_contact_img_container"
                              style={{
                                marginLeft: '40px',
                                width: '60px',
                                height: '60px',
                              }}
                            >
                              {assistant.photo ? (
                                <img
                                  src={`${process.env.REACT_APP_PUBLIC_URL}${assistant.photo}`}
                                />
                              ) : (
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 33 34"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.73958 23.2812C9.42708 21.7812 12.9583 21.0312 16.3333 21.0312C19.7083 21.0312 23.2083 21.7812 26.8333 23.2812C30.5208 24.7188 32.3646 26.625 32.3646 29V33.0312H0.302084V29C0.302084 26.625 2.11458 24.7188 5.73958 23.2812ZM21.9583 14.6562C20.3958 16.2188 18.5208 17 16.3333 17C14.1458 17 12.2708 16.2188 10.7083 14.6562C9.14583 13.0938 8.36458 11.2188 8.36458 9.03125C8.36458 6.84375 9.14583 4.96875 10.7083 3.40625C12.2708 1.78125 14.1458 0.96875 16.3333 0.96875C18.5208 0.96875 20.3958 1.78125 21.9583 3.40625C23.5208 4.96875 24.3021 6.84375 24.3021 9.03125C24.3021 11.2188 23.5208 13.0938 21.9583 14.6562Z"
                                    fill="#8083A3"
                                  ></path>
                                </svg>
                              )}
                            </div>
                            <div
                              style={{ width: '200px' }}
                              className="patient_contact_info"
                            >
                              <span id="patient_contact_name">{`${assistant.first_name} ${assistant.last_name}`}</span>
                              <span id="patient_contact_city">
                                {assistant.speciality}
                              </span>
                            </div>
                          </div>

                          <div className="call_icon_patient">
                            <i className="fas fa-angle-right"></i>
                          </div>
                        </div>
                      );
                  })
                ) : (
                  <div
                    style={{ height: 'calc(60vh - 64px)' }}
                    className="consultation_detailed_info_section"
                  >
                    <div className="empty_consultation_result_container">
                      <div className="empty_consultation_result">
                        <img
                          src={require('../../../assets/img/undraw_design_team_af2y.svg')}
                        />
                        <span id="no_consultation"> Liste vide !</span>
                        <span id="no_consultation_desc">
                          Veuillez créer des nouveaux assistants dans la rubrique gestion
                          des assistants.
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </TabPanel>
          </div>
        </div>
      </div>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Permission);
