import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import React from 'react';
import ToastAction from '../../../../components/ToastAction';
import { getStatusError } from '../../../../utils/helpres';
const initialState = () => async (dispatch) => {
  dispatch({ type: 'INITIAL_STATE_EMAIL_USER' });
};
const getEmail = (email) => async (dispatch) => {
  dispatch({ type: 'GET_EMAIL_USER_REQUESTING' });
  try {
    const url = `${process.env.REACT_APP_PUBLIC_URL}/api/get-email`;
    const { data } = await axios.post(url, { email });
    dispatch({ type: 'GET_EMAIL_USER_SUCCESS', data: data });
  } catch (err) {
    const { response } = err;
    const { data } = response;
    toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
      hideProgressBar: true,
    });
    dispatch({
      type: 'GET_EMAIL_USER_FAILURE',
      err: data.message,
      status: getStatusError(err),
    });
  }
};

const submitResetPasswordForm = (values) => async (dispatch) => {
  dispatch({
    type: 'RESET_PASSWORD_REQUESTING',
  });
  const { email, password } = values;
  let request = {
    email,
    password,
  };
  try {
    const url = `${process.env.REACT_APP_PUBLIC_URL}/api/reset-password`;
    const { data } = await axios.post(url, request);
    //const response = data.data;
    if (!data.error) {
      dispatch({
        type: 'RESET_PASSWORD_SUCCESS',
        data: data,
      });
    } else {
      dispatch({
        type: 'RESET_PASSWORD_FAILURE',
        ErrorMessage: data.message,
        data: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    dispatch({
      type: 'RESET_PASSWORD_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: values,
    });
  }
};

export default {
  submitResetPasswordForm,
  getEmail,
  initialState,
};
