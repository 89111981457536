import React, { Component } from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import actions from '../store/actions';
import update from 'immutability-helper';
import './index.css';

const mapStateToProps = (state) => ({
  motifs: state.ProfileReducer.motifs,
  user: state.AppReducer.user,
  newMotif: state.ProfileReducer.newMotif,
  isLoadingMotifs: state.ProfileReducer.isLoadingMotifs,
});

const mapDispatchToProps = (dispatch) => ({
  getMotifs: () => dispatch(actions.getMotifs()),
  submitMotifForm: (values) => dispatch(actions.submitMotifForm(values)),
  deleteMotif: async (values, id) => {
    await dispatch(actions.deleteMotif(values));
    dispatch(actions.getMotifs());
  },
});

class Motifs extends Component {
  constructor(props) {
    super(props);
    props.getMotifs();
    document.title = 'Liste des diagnostiques';
    this.state = {
      initialValues: {
        motifs: [],
      },
    };
    this.handleChangeMotif = this.handleChangeMotif.bind(this);
  }
  handleChangeMotif(id) {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      const data = [...prevState.initialValues.motifs];
      var commentIndex = data.findIndex(function (c) {
        return c.id === id;
      });
      const updatedComment = update(data[commentIndex], {
        actif: { $set: !data[commentIndex].actif },
      });
      data[commentIndex] = updatedComment;
      this.setState({
        initialValues: {
          ...initialValues,
          motifs: data,
        },
      });
    });
  }
  componentWillReceiveProps(nextProps, nextContext) {
    const { motifs, newMotif } = nextProps;
    const { initialValues } = this.state;
    if (!newMotif && motifs && motifs.length > 0) {
      this.setState({
        initialValues: {
          ...initialValues,
          motifs: motifs,
        },
      });
    }
  }

  render() {
    const { isLoadingMotifs } = this.props;
    const { initialValues } = this.state;
    const { motifs } = initialValues;
    if (isLoadingMotifs)
      return (
        <CircularProgress
          disableShrink
          style={{ marginLeft: '500px', marginTop: '25%' }}
        />
      );
    return (
      <div className="notification_info">
        <div className="notification_info_form">
          <div className="title_and_button">
            <span>diagnostiques </span>
          </div>
          <div className="notification_info_actual_form">
            {motifs &&
              motifs.length > 0 &&
              motifs.map((motif) => {
                return (
                  <div className="notification_info_form_item">
                    <div
                      style={{ width: '600px' }}
                      className="options_container_item option1"
                    >
                      <div className="seance_number" style={{ width: '80%' }}>
                        <span>{motif.label}</span>
                      </div>
                      <div className="seance_date">
                        <span>{motif.duration}{' '}mn</span>
                      </div>
                    </div>
                    <div style={{ width: '5%' }} className="seance-status">
                      <div
                        onClick={() => {
                          this.handleChangeMotif(motif.id);
                          this.props.submitMotifForm({
                            id: motif.id,
                            actif: motif.actif ?  false: true,
                          });
                        }}
                        style={{ cursor: 'pointer' }}
                        className={motif.actif ? 'active_btn' : 'deactive_btn'}
                      >
                        <div
                          className={
                            motif.actif
                              ? 'deactive_btn_circle active_btn_circle'
                              : 'deactive_btn_circle'
                          }
                        ></div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Motifs);
