import React from 'react';
import { apiCall } from '../../../js/actions';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { getStatusError } from '../../../utils/helpres';
import ToastAction from '../../../components/ToastAction';

const getAllRdvByDay = (params, id) => async (dispatch) => {
  dispatch({ type: 'GET_RDV_ALL_DOCTOR_REQUESTING' });
  try {
    const { date } = params || {
      date: '',
    };
    const { status } = params || {
      status: '',
    };
    let url = `/api/get/all/appointment?date=${date}`;
    if (Array.isArray(id) && id.length > 0) {
      id.map((item) => {
        url += `&id[]=${parseInt(item)}`;
      });
    } else url = `/api/get/all/appointment?date=${date}&id[]=${id}`;
    if (status) {
      url += `&status=${status}`;
    }
    const { data } = await apiCall({
      method: 'get',
      url, //`/api/get/all/appointment?id[]=${id}&date=${date}&status=${status}`,
    });
    dispatch({
      type: 'GET_RDV_ALL_DOCTOR_SUCCESS',
      data: data,
    });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: 'GET_RDV_ALL_DOCTOR_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: (response && response.type) || (response && response.message),
    });
  }
};

const getAllRdv = (params, id) => async (dispatch) => {
  dispatch({ type: 'GET_RDV_ALL_DOCTOR_REQUESTING' });
  try {
    const { date } = params || {
      date: '',
    };
    const { status } = params || {
      status: [],
    };
    const { type } = params || {
      type: '',
    };
    let url = `/api/get/all/appointment/calender?date=${date}`;
    if (Array.isArray(id) && id.length > 0) {
      id.map((item) => {
        url += `&id[]=${parseInt(item)}`;
      });
    } else url = `/api/get/all/appointment/calender?date=${date}`;
    if (status && Array.isArray(status) && status.length > 0) {
      status.map((item) => {
        url += `&status[]=${item}`;
      });
    }
    if (type) {
      url += `&type=${type}`;
    }
    const { data } = await apiCall({
      method: 'get',
      url, //`/api/get/all/appointment?id[]=${id}&date=${date}&status=${status}`,
    });
    dispatch({
      type: 'GET_RDV_ALL_DOCTOR_SUCCESS',
      data: data,
    });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: 'GET_RDV_ALL_DOCTOR_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: (response && response.type) || (response && response.message),
    });
  }
};

const getAllUnavailability = (params) => async (dispatch) => {
  dispatch({ type: 'GET_ALL_UNAVAILABILITY_DOCTOR_REQUESTING' });
  try {
    const { date } = params || {
      date: '',
    };
    const { data } = await apiCall({
      method: 'get',
      url: `/api/get/all/unavailability?date=${moment(date).format(
        'YYYY-MM-DD',
      )}&hours08=${moment(`${moment().format('YYYY/MM/DD')} 08:00:00`)
        .tz('UTC')
        .utc()
        .format('HH')}&hours20=${moment(`${moment().format('YYYY/MM/DD')} 20:00:00`)
        .tz('UTC')
        .utc()
        .format('HH')}&hours8=${moment(`${moment().format('YYYY/MM/DD')} 08:00:00`)
        .tz('UTC')
        .utc()
        .format('HH')}`,
    });
    dispatch({
      type: 'GET_ALL_UNAVAILABILITY_DOCTOR_SUCCESS',
      data: data,
    });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: 'GET_ALL_UNAVAILABILITY_DOCTOR_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: response.type || response.message,
    });
  }
};

const submitRDVForm = (values) => async (dispatch) => {
  dispatch({ type: 'CREATE_NEW_CONSULTATION_REQUESTING' });
  const {
    first_name,
    last_name,
    phone,
    gender,
    display_name,
    diagnostic,
    city,
    startHours,
    endHours,
    note,
    date,
    status,
    motif_id,
    phone_number,
    patient_id,
    doctor_id,
  } = values;
  let valNew = {
    display_name,
    doctor_id,
    note,
    diagnostic,
    patient_id,
    /*date_from: date
      ? moment(`${date} ${startHours}`).tz('UTC').utc().format('YYYY-MM-DD HH:mm:ss')
      : null,
    date_to: date
      ? moment(`${date} ${endHours}`).tz('UTC').utc().format('YYYY-MM-DD HH:mm:ss')
      : null,*/
    motif_id,
    status,
    city: city || '',
    phone_number,
    first_name,
    last_name,
    phone,
    gender,
  };
  if (date) {
    valNew.date_from = moment(`${date} ${startHours}`).tz('UTC').utc();
    valNew.date_to = moment(`${date} ${endHours}`).tz('UTC').utc();
  }
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/appointment/${values.id || ''}`,
      data:
        values.status === 'canceled'
          ? { status: 'canceled' }
          : values.status === 'completed'
          ? { status: 'completed' }
          : valNew,
    });
    const response = data;
    if (response.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={`${
              values.id
                ? `Rendez-vous modifié avec succès`
                : `Rendez-vous créé avec succès`
            }`}
          />
        ),
        { hideProgressBar: true },
      );
      if (values.status !== 'canceled')
        dispatch({
          type: 'CREATE_NEW_CONSULTATION_SUCCESS',
          msg: response.message,
          data: response.data,
        });
      else
        dispatch({
          type: 'UPDATE_STATUS_RDV_SUCCESS',
          msg: response.message,
          data: response.data,
        });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message={`${
              values.id
                ? `Échec de modification de rendez-vous`
                : `Échec de création de rendez-vous`
            }`}
          />
        ),
        { hideProgressBar: true },
      );
      //toast.error(`${values.id ? `Rdv n'est pas modifiéé` : `Rdv n'est pas ajouté`}`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'CREATE_NEW_CONSULTATION_FAILURE',
        msg: response.data,
        ErrorMessage: response.message,
        site: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={`${
            data.type === 'error'
              ? data.message
              : values.id
              ? `Échec de modification de rendez-vous`
              : `Échec de création de rendez-vous`
          }`}
        />
      ),
      { hideProgressBar: true },
    );
    //toast.error(`${data.type === 'error' ? data.message : values.id ? `Rdv n'est pas modifiéé` : `Rdv n'est pas ajouté`}`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'CREATE_NEW_CONSULTATION_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.type || data.message,
      data: values,
    });
  }
};

const duplicateRDVForm = (values) => async (dispatch) => {
  dispatch({ type: 'DUPLICATE_RDV_REQUESTING' });
  const { startHours, type, endHours, date, doctor_id } = values;
  let valNew = {
    doctor_id,
    type,
  };
  if (date) {
    valNew.date_from = moment(`${date} ${startHours}`).tz('UTC').utc();
    valNew.date_to = moment(`${date} ${endHours}`).tz('UTC').utc();
  }
  try {
    const { data } = await apiCall({
      method: 'patch',
      url: `/api/duplicate/appointment/${values.id}`,
      data: valNew,
    });
    const response = data;
    if (response.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={
              type === 'reprogrammer'
                ? 'Rendez-vous reprogrammer avec succès'
                : 'Rendez-vous dupliqué avec succès'
            }
          />
        ),
        { hideProgressBar: true },
      );
      if (values.status !== 'canceled')
        dispatch({
          type: 'DUPLICATE_RDV_SUCCESS',
          msg: response.message,
          data: response.data,
        });
      else
        dispatch({
          type: 'DUPLICATE_RDV_SUCCESS',
          msg: response.message,
          data: response.data,
        });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message={
              type === 'reprogrammer'
                ? 'Échec de reprogrammer de rendez-vous'
                : 'Échec de duplication de rendez-vous'
            }
          />
        ),
        { hideProgressBar: true },
      );
      //toast.error(`${values.id ? `Rdv n'est pas modifiéé` : `Rdv n'est pas ajouté`}`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'DUPLICATE_RDV_FAILURE',
        msg: response.data,
        ErrorMessage: response.message,
        site: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={
            type === 'reprogrammer'
              ? data.message
                ? data.message
                : 'Échec de reprogrammer de rendez-vous'
              : data.message
              ? data.message
              : 'Échec de duplication de rendez-vous'
          }
        />
      ),
      { hideProgressBar: true },
    );
    //toast.error(`${data.type === 'error' ? data.message : values.id ? `Rdv n'est pas modifiéé` : `Rdv n'est pas ajouté`}`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'DUPLICATE_RDV_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.type || data.message,
      data: values,
    });
  }
};

const AffectRdv = (values) => async (dispatch) => {
  dispatch({ type: 'AFFECT_RDV_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/affect/appointment/${values.id || ''}`,
      data: values,
    });
    const response = data;
    if (response.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Rendez-vous affecté avec succès" />
        ),
        { hideProgressBar: true },
      );
      dispatch({
        type: 'AFFECT_RDV_SUCCESS',
        msg: response.message,
        data: response.data,
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction type="error" message="Échec d'affectation de rendez-vous" />
        ),
        { hideProgressBar: true },
      );
      //toast.error(`${values.id ? `Rdv n'est pas modifiéé` : `Rdv n'est pas ajouté`}`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'AFFECT_RDV_FAILURE',
        msg: response.data,
        ErrorMessage: response.message,
        site: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec d'affectation de rendez-vous" />
      ),
      { hideProgressBar: true },
    );
    //toast.error(`${data.type === 'error' ? data.message : values.id ? `Rdv n'est pas modifiéé` : `Rdv n'est pas ajouté`}`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'AFFECT_RDV_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.type || data.message,
      data: values,
    });
  }
};

const deleteUnavailability = (item) => async (dispatch) => {
  dispatch({ type: 'GET_DELETE_UNAVAILABILITY_REQUESTING' });
  try {
    const url = `/api/unavailability/${item.id}`;
    const { data } = await apiCall({
      method: 'delete',
      url,
      data: {
        date_from: moment(item.date_from).tz('UTC').utc().format('YYYY-MM-DD HH:mm:ss'),
        hours08: moment(`${moment().format('YYYY/MM/DD')} 08:00:00`)
          .tz('UTC')
          .utc()
          .format('HH'),
        hours20: moment(`${moment().format('YYYY/MM/DD')} 20:00:00`)
          .tz('UTC')
          .utc()
          .format('HH'),
      },
    });
    if (data.type === 'success')
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
    else
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    dispatch({ type: 'GET_DELETE_UNAVAILABILITY_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec de suppression d’indisponibilité" />
      ),
      {
        hideProgressBar: true,
      },
    );
    dispatch({
      type: 'GET_DELETE_UNAVAILABILITY_FAILURE',
      err: err.message,
      status: getStatusError(err),
    });
  }
};

const submitUnavailabilityForm = (values) => async (dispatch) => {
  const { startDate, endDate, startHours, endHours, comment } = values;
  let val = {
    startDate: moment(`${moment(startDate).format('YYYY/MM/DD')} ${startHours}`)
      /*.tz('UTC')
      .utc()*/
      .format('YYYY-MM-DD HH:mm:ss'),
    startDate08: moment(`${moment(startDate).format('YYYY/MM/DD')} 08:00:00`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    startDate2359: moment(`${moment(startDate).format('YYYY/MM/DD')} 23:59:00`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    startDate00: moment(`${moment(startDate).format('YYYY/MM/DD')} 00:00:00`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    startDate1930: moment(`${moment(startDate).format('YYYY/MM/DD')} 19:30:00`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    hours08: moment(`${moment(startDate).format('YYYY/MM/DD')} 08:00:00`)
      .tz('UTC')
      .utc()
      .format('HH'),
    hours20: moment(`${moment(startDate).format('YYYY/MM/DD')} 20:00:00`)
      .tz('UTC')
      .utc()
      .format('HH'),
    startDate20: moment(`${moment(startDate).format('YYYY/MM/DD')} 20:00:00`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    endDate: moment(`${moment(endDate).format('YYYY/MM/DD')} ${endHours}`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    endDate20: moment(`${moment(endDate).format('YYYY/MM/DD')} 20:00:00`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    endDate08: moment(`${moment(endDate).format('YYYY/MM/DD')} 08:00:00`)
      .tz('UTC')
      .utc()
      .format('YYYY-MM-DD HH:mm:ss'),
    note: comment ? comment : 'Indisponible',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/unavailability/${values.id ? values.id : ''}`,
      data: val,
    });
    const response = data;
    if (response.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={
              values.id
                ? 'Indisponibilité modifiée avec succès'
                : 'Indisponibilité enregistrée avec succès'
            }
          />
        ),
        { hideProgressBar: true },
      );
      // toast.success(`Date a été ajouté`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'CREATE_NEW_DISPO_SUCCESS',
        msg: response.message,
        data: response.data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="error" message={response.message} />, {
        hideProgressBar: true,
      });
      // toast.error(response.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'CREATE_NEW_DISPO_FAILURE',
        msg: response.data,
        ErrorMessage: response.message,
        site: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
      hideProgressBar: true,
    });
    //  toast.error(data.message, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'CREATE_NEW_DISPO_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.type || data.message,
      data: values,
    });
  }
};

const getDetailtPaymentAppointment = (id) => async (dispatch) => {
  dispatch({ type: 'GET_PAYMENT_APPOINTMENT_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'get',
      url: `/api/payment/appointment/${id}`,
    });
    dispatch({
      type: 'GET_PAYMENT_APPOINTMENT_SUCCESS',
      data: data,
    });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: 'GET_PAYMENT_APPOINTMENT_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: response ? response.type || response.message : '',
    });
  }
};

const getMotif = (type) => async (dispatch) => {
  dispatch({ type: 'GET_MOTIFS_AGENDA_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'get',
      url: `/api/motifs?type=${type}`,
    });
    dispatch({
      type: 'GET_MOTIFS_AGENDA_SUCCESS',
      data: data,
    });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: 'GET_MOTIFS_AGENDA_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: response ? response.type || response.message : '',
    });
  }
};

const getDipoDoctorByMotif = (values) => async (dispatch) => {
  dispatch({ type: 'GET_DISPONIBILITY_DOCTOR_BY_MOTIF_REQUESTING' });
  try {
    const body = {
      hours20: moment(`${moment(values.date).format('YYYY/MM/DD')} 20:00:00`)
        .tz('UTC')
        .utc()
        .format('HH'),
      hours08: moment(`${moment(values.date).format('YYYY/MM/DD')} 08:00:00`)
        .tz('UTC')
        .utc()
        .format('HH'),
      start_date: moment(moment(values.date).format('YYYY-MM-DD 08:00:00'))
        .tz('UTC')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(moment(values.date).format('YYYY-MM-DD 20:00:00'))
        .tz('UTC')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      motif_id: values.motif_id,
      doctor_id: values.doctor_id,
      //rdv_id: values.rdv_id,
    };
    const { data } = await apiCall({
      method: 'post',
      url: `/api/getdipsodoctor`,
      data: body,
    });
    dispatch({
      type: 'GET_DISPONIBILITY_DOCTOR_BY_MOTIF_SUCCESS',
      data: data,
    });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: 'GET_DISPONIBILITY_DOCTOR_BY_MOTIF_FAILURE',
      // msg: response.data.message,
      // err: response.data.type,
    });
  }
};

const getLastAvailability = (id, doctor_id) => async (dispatch) => {
  dispatch({ type: 'GET_LAST_AVAILABILITY_REQUESTING' });
  try {
    const body = {
      hours20: moment(`${moment().format('YYYY/MM/DD')} 20:00:00`)
        .tz('UTC')
        .utc()
        .format('HH'),
      hoursNow: moment(moment().format('YYYY-MM-DD HH:mm:ss'))
        .tz('UTC')
        .utc()
        .format('HH'),
      hours08: moment(`${moment().format('YYYY/MM/DD')} 08:00:00`)
        .tz('UTC')
        .utc()
        .format('HH'),
      start_date: moment(moment().format('YYYY-MM-DD 08:00:00'))
        .tz('UTC')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(moment().format('YYYY-MM-DD 20:00:00'))
        .tz('UTC')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      doctor_id: doctor_id,
    };
    const { data } = await apiCall({
      method: 'post',
      url: `/api/get/last/availability/practitioner/${id}`,
      data: body,
    });
    dispatch({
      type: 'GET_LAST_AVAILABILITY_SUCCESS',
      data: data,
    });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: 'GET_LAST_AVAILABILITY_FAILURE',
      //msg: response.data.message,
      //err: response.data.type,
    });
  }
};

const deleteRdv = (id) => async (dispatch) => {
  dispatch({ type: 'GET_DELETE_RDV_REQUESTING' });
  try {
    const url = `/api/appointment/${id}`;
    const { data } = await apiCall({
      method: 'delete',
      url,
    });
    if (data.type === 'success')
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
    else
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    dispatch({ type: 'GET_DELETE_RDV_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec de suppression de rendez-vous" />
      ),
      {
        hideProgressBar: true,
      },
    );
    dispatch({
      type: 'GET_DELETE_RDV_FAILURE',
      err: err.message,
      status: getStatusError(err),
    });
  }
};

const changeParams = (params) => ({
  type: 'UPDATE_DATE_UNAVAILABILITY_PARAMS',
  params: params,
});

const submitPaymentForm = (values) => async (dispatch) => {
  dispatch({ type: 'CREATE_PAYMENT_REQUESTING' });
  const {
    patient_id,
    date_cheque,
    orde_virement,
    num_cheque,
    appointment_id,
    total_achieved,
    already_paid,
    left_to_pay,
    money_pot_amount,
    session_price,
    payment_method,
    settled_in_session,
    date,
  } = values;
  let valNew = {
    appointment_id,
    patient_id,
    total_achieved,
    already_paid,
    left_to_pay,
    money_pot_amount,
    session_price,
    payment_method,
    settled_in_session,
    orde_virement,
    num_cheque,
    date_cheque,
    date,
  };
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/payment/${values.id ? 'update/' : 'create'}${values.id || ''}`,
      data: valNew,
    });
    const response = data;
    if (response.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={`${
              values.id
                ? `Rendez-vous modifié avec succès`
                : `Rendez-vous créé avec succès`
            }`}
          />
        ),
        { hideProgressBar: true },
      );
      dispatch({
        type: 'CREATE_PAYMENT_SUCCESS',
        msg: response.message,
        data: response.data,
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message={`${
              values.id
                ? `Échec de modification de rendez-vous`
                : `Échec de création de rendez-vous`
            }`}
          />
        ),
        { hideProgressBar: true },
      );
      //toast.error(`${values.id ? `Rdv n'est pas modifiéé` : `Rdv n'est pas ajouté`}`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'CREATE_PAYMENT_FAILURE',
        msg: response.data,
        ErrorMessage: response.message,
        site: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={`${
            data.type === 'error'
              ? data.message
              : values.id
              ? `Échec de modification de rendez-vous`
              : `Échec de création de rendez-vous`
          }`}
        />
      ),
      { hideProgressBar: true },
    );
    //toast.error(`${data.type === 'error' ? data.message : values.id ? `Rdv n'est pas modifiéé` : `Rdv n'est pas ajouté`}`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'CREATE_PAYMENT_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.type || data.message,
      data: values,
    });
  }
};

const getPayment = (id) => async (dispatch) => {
  dispatch({ type: 'GET_PAYMENT_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'get',
      url: `/api/payment/get-one/${id}`,
    });
    dispatch({
      type: 'GET_PAYMENT_SUCCESS',
      data: data,
    });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: 'GET_PAYMENT_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: response ? response.type || response.message : '',
    });
  }
};

const getDoctorIsDispo = (values) => async (dispatch) => {
  dispatch({ type: 'GET_GET_DOCTOR_IS_DISPO_REQUESTING' });
  try {
    let url = `/api/appointment/verified/${values.id}`;
    const { data } = await apiCall({
      method: 'post',
      url,
      data: values,
    });
    dispatch({ type: 'GET_GET_DOCTOR_IS_DISPO_SUCCESS', data: data });
  } catch (err) {
    const { response } = err;
    const { data } = response;
    toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
      hideProgressBar: true,
    });
    dispatch({
      type: 'GET_GET_DOCTOR_IS_DISPO_FAILURE',
      err: err.message,
      status: getStatusError(err),
    });
  }
};

export default {
  getDoctorIsDispo,
  submitPaymentForm,
  deleteRdv,
  getLastAvailability,
  getDipoDoctorByMotif,
  getMotif,
  changeParams,
  getAllUnavailability,
  getAllRdvByDay,
  submitUnavailabilityForm,
  submitRDVForm,
  deleteUnavailability,
  getPayment,
  AffectRdv,
  duplicateRDVForm,
  getAllRdv,
  getDetailtPaymentAppointment,
};
