import React, { Component } from 'react';
export default class ItemComponents extends Component {
  render() {
    const { item } = this.props;
    return (
      <div
        key={item.id}
        onClick={() => this.props.HandelChangePage(item.id)}
        className="block-bloc"
      >
        <div className="icon-bloc">
          <i className={item.icon}></i>
        </div>
        <div className="texts" style={{ display: 'grid' }}>
          <span className="p-bloc" style={{ fontWeight: 'bold' }}>
            {item.title}
          </span>
          <span className="p-bloc desc1">{item.description}</span>
        </div>
      </div>
    );
  }
}
