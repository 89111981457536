import { getStatusError } from '../../../utils/helpres';
import { apiCall } from '../../../js/actions';
import 'react-toastify/dist/ReactToastify.css';

const getCountRdvByDoctor = () => async (dispatch) => {
  dispatch({ type: 'GET_RDV_DOCTOR_REQUESTING' });
  try {
    const url = `/api/count/appointment`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_RDV_DOCTOR_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'GET_RDV_DOCTOR_FAILURE',
      err: response && response.data && response.data.error,
      status: getStatusError(err),
    });
  }
};
const getTotalTTcBill = () => async (dispatch) => {
  dispatch({ type: 'GET_TOTAL_TTC_BILL_REQUESTING' });
  try {
    const url = `/api/get/sum/total/bill`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_TOTAL_TTC_BILL_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'GET_TOTAL_TTC_BILL_FAILURE',
      err: response && response.data && response.data.error,
      status: getStatusError(err),
    });
  }
};
const getCountConsultationByDoctor = () => async (dispatch) => {
  dispatch({ type: 'GET_CONSULTATION_DOCTOR_REQUESTING' });
  try {
    const url = `/api/count/consultation`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_CONSULTATION_DOCTOR_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'GET_CONSULTATION_DOCTOR_FAILURE',
      err: response && response.data && response.data.error,
      status: getStatusError(err),
    });
  }
};
const getCountPatientByDoctor = () => async (dispatch) => {
  dispatch({ type: 'GET_PATIENT_DOCTOR_REQUESTING' });
  try {
    const url = `/api/count/patient`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_PATIENT_DOCTOR_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'GET_PATIENT_DOCTOR_FAILURE',
      err: response && response.data && response.data.error,
      status: getStatusError(err),
    });
  }
};
const getLast10PatientByDoctor = () => async (dispatch) => {
  dispatch({ type: 'GET_LAST_10_PATIENT_DOCTOR_REQUESTING' });
  try {
    const url = `/api/last/10/patient`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_LAST_10_PATIENT_DOCTOR_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'GET_LAST_10_PATIENT_DOCTOR_FAILURE',
      err: response && response.data && response.data.error,
      status: getStatusError(err),
    });
  }
};
const getLast10RdvByDay = () => async (dispatch) => {
  dispatch({ type: 'GET_LAST_10_RDV_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'get',
      url: `/api/get/last/appointment/practitioner`,
    });
    dispatch({
      type: 'GET_LAST_10_RDV_SUCCESS',
      data: data,
    });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: 'GET_LAST_10_RDV_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: response ? response.type : '' || response ? response.message : '',
    });
  }
};

const getLast10ConsultationsByDoctor = () => async (dispatch) => {
  dispatch({ type: 'GET_LAST_10_CONSULTATION_DOCTOR_REQUESTING' });
  try {
    const url = `/api/last/10/consultations`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_LAST_10_CONSULTATION_DOCTOR_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'GET_LAST_10_CONSULTATION_DOCTOR_FAILURE',
      err: response && response.data && response.data.error,
      status: getStatusError(err),
    });
  }
};

export default {
  getCountRdvByDoctor,
  getTotalTTcBill,
  getCountPatientByDoctor,
  getCountConsultationByDoctor,
  getLast10PatientByDoctor,
  getLast10ConsultationsByDoctor,
  getLast10RdvByDay,
};
