import React, { useState, useEffect, Fragment } from 'react';
//import React, { Component } from 'react';
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from '@react-pdf/renderer';
import { NumberToLetter } from 'convertir-nombre-lettre';

import { codeBureauCNAM } from '../../utils/constants';
import moment from 'moment';
import Icon from '../../assets/img/arrow1.png';
import IconKine from '../../assets/img/logo-kine.png';
// Create styles
const newstyles = StyleSheet.create({
  page: {
    display: 'inline-grid',
    flexDirection: 'row',
    marginTop: 10,
  },
  PDFViewer: {
    width: '100%',
    height: '550px',
  },
  section: {
    marginLeft: 10,
    padding: 10,
  },
  sectionNameDoctor: {
    marginLeft: 10,
    marginRight: 90,
    padding: 10,
  },
  sectionLogo: {
    marginLeft: 10,
    marginRight: 80,
    padding: 10,
  },
  section2: {
    padding: 10,
    marginLeft: -10,
  },
  hr: {
    BorderTop: '1px bold red',
  },
  cabinet: {
    display: 'flex',
    marginTop: 30,
    flexDirection: 'row',
    marginLeft: 30,
    marginRight: 60,
  },
  sectionBill: {
    margin: 10,
    padding: 10,
    flexDirection: 'row',
  },
  detailBill: {
    margin: 10,
    padding: 10,
    flexDirection: 'row',
  },
  detailBillText: {
    color: '#070707',
    fontWeight: '1000',
    fontSize: 12,
    fontStyle: 'gras',
    //fontFamily: 'Georgia, serif',
    margin: 3,
  },
  detailBillText2: {
    color: '#070707',
    fontWeight: 'bolder',
    marginLeft: 10,
    padding: 10,
  },
  text: {
    color: '#5446db',
    fontWeight: 'bolder',
  },
  bill: {
    color: '#5446db',
    fontWeight: '500',
    marginLeft: '1%',
  },
  text1: {
    color: '#49494c',
    fontSize: 12,
    fontWeight: 'bolder',
    display: 'block',
    margin: 3,
    width: 120,
  },
  text2: {
    color: '#49494c',
    fontSize: 12,
    fontWeight: 'bolder',
    display: 'block',
    margin: 3,
    width: 150,
  },
  images: {
    marginTop: 150,
    marginLeft: 20,
    // padding: 10,
    width: 50,
    height: 20,
  },
});
function FacturePdf(props) {
  const bill = props.location.state.facture;
  const tag = props.location.state.tag;
  const office = props.location.state.office;
  document.title = `Récu n°: ${bill.number}`;
  useEffect(() => {
    props.changeActiveItemSideBar(5);
    props.changeNamePage(`Récu n°: ${bill.number}`);
  });

  return (
    <>
      <div className="bilan_details_header">
        <div
          onClick={() =>
            props.history.push({
              pathname: '/compta',
              state: {
                receipt: true,
              },
            })
          }
          className="back_icon"
        >
          <img src={Icon} />
        </div>
        <span>Retour</span>
      </div>
      <Fragment>
        <PDFViewer style={newstyles.PDFViewer}>
          <Document>
            {/*orientation="landscape"*/}
            <Page size="A4">
              <View style={newstyles.cabinet}>
                <View style={newstyles.section}>
                  <Text style={newstyles.detailBillText}>TVA</Text>
                  <Text style={newstyles.detailBillText}>R.I.B</Text>
                  <Text style={newstyles.detailBillText}>BANQUE</Text>
                  <Text style={newstyles.detailBillText}>N° Employeur</Text>
                  <Text style={newstyles.detailBillText}>Code CNAM </Text>
                </View>
                <View style={newstyles.section2}>
                  <Text style={newstyles.text1}>
                    {' '}
                    {office ? office.tax_registration_number : '-'}
                  </Text>
                  <Text style={newstyles.text1}>{office ? office.rib : '-'}</Text>
                  <Text style={newstyles.text1}>{office ? office.bank : '-'}</Text>
                  <Text style={newstyles.text1}>
                    {office ? office.number_employer : '-'}
                  </Text>
                  <Text style={newstyles.text1}>
                    {office ? office.conventional_code : '-'}
                  </Text>
                </View>
                <View style={newstyles.sectionLogo}>
                  <Image style={{ width: 60, height: 60 }} src={IconKine} />
                </View>
                <View style={newstyles.sectionNameDoctor}>
                  <Text style={newstyles.detailBillText}>
                    {bill && bill.practitioner ? bill.practitioner.name_cabinet : '-'}
                  </Text>
                  <Text style={newstyles.detailBillText}>Kinésithérapie</Text>
                  <Text style={newstyles.text1}>
                    {office && office.address_office
                      ? `${office.address_office.street}, ${office.address_office.zip_code} ${office.address_office.city} ${office.address_office.country}`
                      : '-'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  borderBottomColor: 'black',
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                }}
              />
              <View
                style={{
                  marginLeft: 50,
                  marginRight: 60,
                  marginTop: 5,
                }}
              >
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 10,
                    fontWeight: '500',
                    display: 'block',
                    margin: 3,
                  }}
                >
                  Les honoraires de {bill.line_bill.sessions_nbr} séances de
                  physiothérapie et rééducation fonctionnelle selon la convention du
                </Text>
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 10,
                    fontWeight: 'bolder',
                    display: 'block',
                    margin: 3,
                    textAlign: 'center',
                  }}
                >
                  06/09/2020 (JORT : 16-10-2007 )
                </Text>
              </View>
              <View
                style={{
                  borderBottomColor: 'black',
                  marginTop: 5,
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                  borderWidth: 1,
                  borderRadius: 5,
                  height: 50,
                }}
              >
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    padding: 12,
                    fontStyle: 'gras',
                    textAlign: 'center',
                    //fontFamily: 'Georgia, serif',
                    margin: 3,
                  }}
                >
                  Récu n° : {bill.number}
                  {bill.consultation &&
                  bill.consultation.prise_en_charges[0] &&
                  bill.consultation.prise_en_charges[0].code_bureau
                    ? `( ${
                        codeBureauCNAM[bill.consultation.prise_en_charges[0].code_bureau]
                      } )`
                    : ''}
                </Text>
              </View>
              <View
                style={{
                  marginLeft: 80,
                  marginTop: 20,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 12,
                    fontWeight: 'bolder',
                  }}
                >
                  Date Reçu :
                </Text>
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    marginRight: 30,
                    marginLeft: 10,
                  }}
                >
                  {moment(bill.created_at).format('DD/MM/YYYY')}
                </Text>
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 12,
                    fontWeight: 'bolder',
                  }}
                >
                  Séances du :
                </Text>
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    marginRight: 30,
                    marginLeft: 10,
                  }}
                >
                  {moment(bill.start_date).format('DD/MM/YYYY')}
                </Text>
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 12,
                    fontWeight: 'bolder',
                  }}
                >
                  Au :
                </Text>
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    marginRight: 30,
                    marginLeft: 10,
                  }}
                >
                  {moment(bill.end_date).format('DD/MM/YYYY')}
                </Text>
              </View>
              <View
                style={{
                  borderBottomColor: 'black',
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                  borderWidth: 1,
                  borderRadius: 5,
                  height: 150,
                  marginTop: 30,
                }}
              >
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    padding: 12,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    marginLeft: 20,
                    textDecoration: 'underline',
                  }}
                >
                  Note d'honoraire
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 10,
                    marginRight: 60,
                  }}
                >
                  <View style={newstyles.section2}>
                    <Text style={newstyles.text1}>Effectué à</Text>
                    {bill.consultation ? (
                      <View>
                        {bill.patient_record &&
                        bill.patient_record.patient &&
                        bill.patient_record.patient.cnam_root ? (
                          <Text style={newstyles.text1}>Assuré n°</Text>
                        ) : bill &&
                          bill.consultation &&
                          bill.consultation.patient_record &&
                          bill.consultation.patient_record.patient &&
                          bill.consultation.patient_record.patient.cnam_root ? (
                          <Text style={newstyles.text1}>Assuré n°</Text>
                        ) : (
                          <Text></Text>
                        )}
                        {bill.consultation &&
                        bill.consultation.prise_en_charges &&
                        bill.consultation.prise_en_charges.length > 0 &&
                        bill.consultation.prise_en_charges[0].code_bureau ? (
                          <Text style={newstyles.text1}>N° décision</Text>
                        ) : (
                          <Text></Text>
                        )}
                      </View>
                    ) : (
                      <Text></Text>
                    )}

                    <Text style={newstyles.text1}>Édité le</Text>
                  </View>
                  <View style={newstyles.section}>
                    <Text style={newstyles.detailBillText}>
                      :
                      {bill.patient_record && bill.patient_record.patient
                        ? ` ${bill.patient_record.patient.last_name} ${bill.patient_record.patient.first_name}`
                        : bill &&
                          bill.consultation &&
                          bill.consultation.patient_record &&
                          bill.consultation.patient_record.patient
                        ? `${bill.consultation.patient_record.patient.first_name} ${bill.consultation.patient_record.patient.last_name}`
                        : '-'}
                    </Text>

                    {bill.patient_record &&
                    bill.patient_record.patient &&
                    bill.patient_record.patient.cnam_root ? (
                      <Text style={newstyles.detailBillText}>
                        {`: ${bill.patient_record.patient.cnam_root}/${bill.patient_record.patient.cnam_key} `}
                      </Text>
                    ) : bill &&
                      bill.consultation &&
                      bill.consultation.patient_record &&
                      bill.consultation.patient_record.patient &&
                      bill.consultation.patient_record.patient.cnam_root ? (
                      `: ${bill.consultation.patient_record.patient.cnam_root}/${bill.consultation.patient_record.patient.cnam_key}`
                    ) : (
                      <Text></Text>
                    )}

                    {bill.consultation &&
                    bill.consultation.prise_en_charges &&
                    bill.consultation.prise_en_charges.length > 0 &&
                    bill.consultation.prise_en_charges[0].code_bureau ? (
                      <Text style={newstyles.detailBillText}>
                        {`: ${bill.consultation.prise_en_charges[0].code_bureau}/${bill.consultation.prise_en_charges[0].year}/${bill.consultation.prise_en_charges[0].num_order}`}
                      </Text>
                    ) : (
                      <Text></Text>
                    )}

                    <Text style={newstyles.detailBillText}>
                      : {moment().format('DD/MM/YYYY')}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 130,
                  marginTop: 20,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    marginRight: 30,
                    marginLeft: 10,
                  }}
                >
                  Les honoraires TTC : ( {bill.line_bill.unit_price} *{' '}
                  {bill.line_bill.sessions_nbr} séances ={' '}
                  {parseFloat(
                    bill.line_bill.sessions_nbr * bill.line_bill.unit_price,
                  ).toFixed(3)}{' '}
                  )
                </Text>
              </View>
              <View
                style={{
                  borderBottomColor: 'black',
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                  borderWidth: 1,
                  borderRadius: 5,
                  height: 170,
                  marginTop: 30,
                }}
              >
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    padding: 12,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    marginLeft: 20,
                    textDecoration: 'underline',
                  }}
                >
                  Détails
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 80,
                    marginRight: 60,
                  }}
                >
                  <View style={newstyles.section2}>
                    <Text style={newstyles.text1}>Honoraires HT par séance</Text>
                    <Text style={newstyles.text1}>Nombre de séances</Text>
                    <Text style={newstyles.text1}>Total honoraires HT</Text>
                    <Text style={newstyles.text1}>TVA ( 7% )</Text>
                    <Text style={newstyles.detailBillText}>Total honoraires TTC</Text>
                  </View>
                  <View style={newstyles.section2}>
                    <Text style={newstyles.text1}>:</Text>
                    <Text style={newstyles.text1}>:</Text>
                    <Text style={newstyles.text1}>:</Text>
                    <Text style={newstyles.text1}>:</Text>
                    <Text style={newstyles.text1}>:</Text>
                  </View>
                  <View style={newstyles.section}>
                    <Text
                      style={{
                        color: '#070707',
                        fontWeight: '1000',
                        fontSize: 12,
                        fontStyle: 'gras',
                        //fontFamily: 'Georgia, serif',
                        margin: 3,
                        marginRight: 250,
                        textAlign: 'right',
                        //textDecoration: 'underline',
                      }}
                    >
                      {parseFloat((bill.line_bill.unit_price * 93) / 100).toFixed(3)}
                    </Text>
                    <Text
                      style={{
                        color: '#070707',
                        fontWeight: '1000',
                        fontSize: 12,
                        fontStyle: 'gras',
                        //fontFamily: 'Georgia, serif',
                        margin: 3,
                        marginRight: 250,
                        textAlign: 'right',
                        textDecoration: 'underline',
                      }}
                    >
                      {bill.line_bill.sessions_nbr}
                    </Text>
                    <Text
                      style={{
                        color: '#070707',
                        fontWeight: '1000',
                        fontSize: 12,
                        fontStyle: 'gras',
                        //fontFamily: 'Georgia, serif',
                        margin: 3,
                        marginRight: 250,
                        textAlign: 'right',
                        //textDecoration: 'underline',
                      }}
                    >
                      {parseFloat(bill.line_bill.ht_amount).toFixed(3)}
                    </Text>
                    <Text
                      style={{
                        color: '#070707',
                        fontWeight: '1000',
                        fontSize: 12,
                        fontStyle: 'gras',
                        //fontFamily: 'Georgia, serif',
                        margin: 3,
                        marginRight: 250,
                        textAlign: 'right',
                        textDecoration: 'underline',
                      }}
                    >
                      {parseFloat(
                        (bill.line_bill.unit_price * bill.line_bill.sessions_nbr * 7) /
                          100,
                      ).toFixed(3)}
                    </Text>
                    <Text
                      style={{
                        color: '#070707',
                        fontWeight: '1000',
                        fontSize: 12,
                        fontStyle: 'gras',
                        //fontFamily: 'Georgia, serif',
                        margin: 3,
                        marginRight: 250,
                        textAlign: 'right',
                        // textDecoration: 'underline',
                      }}
                    >
                      {parseFloat(bill.total_ttc).toFixed(3)}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 50,
                  marginRight: 60,
                  marginTop: 3,
                }}
              >
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 12,
                    fontWeight: 'bolder',
                    display: 'block',
                    margin: 3,
                    textAlign: 'center',
                  }}
                >
                  Arretée la présente facture à la somme de :
                </Text>
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 12,
                    fontWeight: 'bolder',
                    display: 'block',
                    margin: 3,
                    textAlign: 'center',
                  }}
                >
                  #{' '}
                  {NumberToLetter(
                    parseFloat(bill.total_ttc).toFixed(3).toString().replace('.', ''),
                  )
                    .replace('mille', 'Dinars')
                    .replace('millions', 'mille')}{' '}
                  #
                </Text>
              </View>
              <View
                style={{
                  height: '40px',
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  width: '100%',
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                <View
                  style={{
                    borderBottomColor: 'black',
                    marginLeft: 50,
                    marginRight: 60,
                    //marginTop: 350,
                    borderBottomWidth: 1,
                  }}
                />
                <View
                  style={
                    {
                      // bottom:0,
                    }
                  }
                >
                  <Text
                    style={{
                      top: 10,
                      color: '#070707',
                      fontWeight: '1000',
                      fontSize: 10,
                      fontStyle: 'gras',
                      //fontFamily: 'Georgia, serif',
                      margin: 3,
                    }}
                  >
                    {bill.practitioner &&
                    bill.practitioner.address_practitioner &&
                    bill.practitioner.address_practitioner.street
                      ? `${bill.practitioner.address_practitioner.street} ${bill.practitioner.address_practitioner.city} ${bill.practitioner.address_practitioner.zip_code}`
                      : '-'}{' '}
                    {office && office.phone ? `Tél/Fax: +216 ${office.phone} ` : '-'}
                    {bill.practitioner && bill.practitioner.gsm
                      ? `GSM: +216 ${bill.practitioner.gsm} `
                      : '-'}
                  </Text>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </Fragment>
    </>
  );
}

export default FacturePdf;
