import React, { Component } from 'react';
import moment from 'moment';
import actionsJs from '../../../js/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import actionsA from '../../agenda-practitioner/store/actions';
import InputField from '../../../components/inputField';
import { SwipeableDrawer } from '@material-ui/core';
import Modals from '../../../components/modal';
import HeaderList from '../../../components/headerList';

const mapStateToProps = (state) => ({
  holidays: state.AppReducer.holidays,
  absences: state.AppReducer.absences,
  holidaysUser: state.AppReducer.holidaysUser,
  isLoadingAbsenses: state.AppReducer.isLoadingAbsenses,
  isLoadingActionAbsences: state.AppReducer.isLoadingActionAbsences,
  actionAbsences: state.AppReducer.actionAbsences,
  switchList: state.AppReducer.switchList,
  params: state.AgendaReducer.params,
});

const mapDispatchToProps = (dispatch) => ({
  changeParams: (params) => dispatch(actionsA.changeParams(params)),
  getAbsenses: (params, id) => dispatch(actionsJs.getAbsenses(params, id)),
  submitAbsenceForm: async (values, id, params) => {
    await dispatch(actionsJs.submitAbsenceForm(values));
    dispatch(actionsJs.getAbsenses(params, id));
  },
  deleteAbsence: async (id, params) => {
    await dispatch(actionsJs.deleteAbsence(id));
    dispatch(actionsJs.getAbsenses(params, null));
  },
  changeDate: async (params, id) => {
    await dispatch(actionsA.changeParams(params));
    dispatch(actionsJs.getAbsenses(params, id));
  },
});

const headers = [
  { name: 'Date début', width: '15%', type: 'date' },
  { name: 'Date fin', width: '17%', type: 'date' },
  { name: 'Collaborateur', width: '27%', type: 'string' },
  { name: 'Note', width: '30%', type: 'text' },
  { name: 'Actions', width: '15%', type: 'action' },
];

class AbsenceManagement extends Component {
  constructor(props) {
    super(props);
    props.changeNamePage('Gestion des absences');
    document.title = 'Gestion des absences';
    props.getAbsenses(
      {
        date: '',
        date_to: '',
      },
      null,
    );
    this.state = {
      setIsOpenDeleteModal: false,
      hasErrorDate: false,
      hasError: false,
      switchList: false,
      employee_selected: null,
      selected_id: null,
      value: 0,
      login_access_id: props.employeeManagement && props.employeeManagement[0],
      initialValues: {},
    };
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  handleChange = (event, value) => {
    this.setState({
      value,
    });
  };

  handleSelectItem(id) {
    const { doctors } = this.props;
    const data = doctors.find((item) => item.id === id);
    this.setState({
      employee_selected: data,
    });
  }

  submitForm(initialValues, params, doctor) {
    if (
      !initialValues.date_from ||
      !initialValues.date_to ||
      !initialValues.hour_from ||
      !initialValues.login_access_id ||
      !initialValues.hour_to ||
      !initialValues.note
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else if (moment(initialValues.date_from).isAfter(moment(initialValues.date_to))) {
      this.setState({
        hasErrorDate: true,
      });
    } else {
      this.setState({
        hasErrorDate: false,
        hasError: false,
      });
      this.props.submitAbsenceForm(
        initialValues,
        doctor ? doctor.login_access.id : null,
        params,
      );
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { actionAbsences } = this.props;
    const { initialValues } = this.state;
    if (actionAbsences !== prevProps.actionAbsences) {
      this.props.changeActiveItemSideBarSettings(5, false, false);
      this.setState({
        initialValues: {
          ...initialValues,
          anchor: false,
          left: false,
          id: null,
          login_access_id: null,
          date_to: null,
          hour_from: null,
          hour_to: null,
          note: '',
        },
      });
    }
  }
  toggleDrawer = (anchor, open, id) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      left: open,
      anchor: 'left',
      selected_id: id,
      hasError: false,
    });
  };
  componentDidMount() {
    this.props.changeDate(
      {
        ...this.props.params,
        date: '',
        date_to: '',
      },
      null,
    );
  }
  openDeleteModal() {
    this.setState({
      setIsOpenDeleteModal: !this.state.setIsOpenDeleteModal,
    });
  }
  render() {
    const {
      absences,
      params,
      isLoadingAbsenses,
      doctors,
      isLoadingDoctors,
      firstDoctor,
      switchList,
      secretaries,
      assistants,
    } = this.props;
    const {
      hasErrorDate,
      setIsOpenDeleteModal,
      hasError,
      initialValues,
      motif_selected,
      doctor,
      anchor,
      selected_id,
    } = this.state;
    console.log(absences);
    if (isLoadingDoctors)
      return (
        <CircularProgress disableShrink style={{ marginLeft: '33%', marginTop: '20%' }} />
      );
    const allUsers = [...doctors, ...secretaries, ...assistants];
    return (
      <div
        className="main_content"
        style={{
          overflow: 'hidden',
          width: '100%',
          height: '100%',
        }}
      >
        <Modals
          setIsOpen={setIsOpenDeleteModal}
          openModal={this.openDeleteModal}
          data={
            <>
              <div className="modal-header" style={{ marginTop: '-9px' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openDeleteModal()}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '13px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Suppression!</h2>
                <span>Etes-vous sûr de vouloir supprimer cette absence ?</span>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => this.openDeleteModal()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                  style={{ backgroundColor: '#f6f6f6' }}
                >
                  Non
                </button>
                <button
                  onClick={async () => {
                    await this.props.deleteAbsence(selected_id, params);
                    this.openDeleteModal();
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
        <svg
          onClick={async () => {
            this.props.goBackItem('showAbsenceManagement');
          }}
          width="46"
          height="46"
          viewBox="0 0 46 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ cursor: 'pointer', margin: 5 }}
        >
          <rect
            x="0.5"
            y="45.5"
            width="45"
            height="45"
            rx="9.5"
            transform="rotate(-90 0.5 45.5)"
            fill="white"
            stroke="#ECEEF5"
          ></rect>
          <path
            d="M20.0117 23L24.5117 18.5L25.5664 19.5547L22.1211 23L25.5664 26.4453L24.5117 27.5L20.0117 23Z"
            fill="#8083A3"
          ></path>
        </svg>
        {doctors.length > 0 ? (
          <div
            className="general_info"
            style={{ marginTop: 5, display: 'contents', width: '100%', height: '100%' }}
          >
            <div className="diploma_info" style={{ overflow: 'auto', display: 'block' }}>
              <div
                style={{ marginLeft: '0', marginTop: '70px' }}
                className="contact_patients_header"
              >
                <p style={{ display: 'black' }}>
                  Choisir un collaborateur pour gérer ses absences
                </p>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <div style={{ width: '30%' }} className="search_contact_patients_name">
                    <select
                      style={{ width: '95%' }}
                      onChange={(e) => {
                        if (e.target.value === 'Choisir un collaborateur') {
                          this.props.getAbsenses(params, null);
                          this.setState({
                            doctor: null,
                          });
                        } else {
                          this.props.getAbsenses(params, e.target.value);
                          this.setState({
                            doctor: allUsers.find(
                              (item) => item.login_access.id === parseInt(e.target.value),
                            ),
                          });
                        }
                      }}
                    >
                      <option style={{ color: '#ccc' }} value={null}>
                        Choisir un collaborateur
                      </option>
                      {allUsers.map((doctor) => {
                        return (
                          <option
                            value={doctor.login_access.id}
                          >{`${doctor.first_name} ${doctor.last_name}`}</option>
                        );
                      })}
                    </select>
                  </div>
                  <div style={{ display: 'flex' }} className="field_row">
                    <InputField
                      width="100%"
                      errorDate={hasError}
                      className="add_patient_form_item"
                      type="date"
                      placeholder="Date début"
                      label="Date début"
                      validInput=""
                      name="hiring_date"
                      onChange={(e) => {
                        if (
                          params.date_to &&
                          moment(params.date_to).isBefore(moment(e.target.value))
                        ) {
                          this.setState({
                            hasError: true,
                          });
                          this.props.changeParams({
                            ...params,
                            date: moment(e.target.value),
                          });
                        } else {
                          this.setState({
                            hasError: false,
                          });
                          this.props.changeDate(
                            {
                              ...params,
                              date: moment(e.target.value),
                            },
                            (doctor && doctor.id) || null,
                          );
                        }
                      }}
                    />
                    <InputField
                      width="100%"
                      className="add_patient_form_item"
                      type="date"
                      placeholder="Date fin"
                      label="Date fin"
                      validInput=""
                      errorDate={hasError}
                      onChange={(e) => {
                        if (
                          params.date &&
                          moment(moment(e.target.value).format('YYYY-MM-DD')).isBefore(
                            moment(params.date).format('YYYY-MM-DD'),
                          )
                        ) {
                          this.setState({
                            hasError: true,
                          });
                          this.props.changeParams({
                            ...params,
                            date_to: moment(e.target.value),
                          });
                        } else {
                          this.setState({
                            hasError: false,
                          });
                          this.props.changeDate(
                            {
                              ...params,
                              date_to: moment(e.target.value),
                            },
                            (doctor && doctor.id) || null,
                          );
                        }
                      }}
                      name="departure_date"
                      //value={moment(params.date_to).format('YYYY-MM-DD')}
                    />
                  </div>
                </div>
              </div>
              <br />
              <br />
              {isLoadingAbsenses ? (
                <CircularProgress
                  disableShrink
                  style={{ marginLeft: '33%', marginTop: '20%' }}
                />
              ) : firstDoctor ? (
                <>
                  <div className="contact_patients_body">
                    <HeaderList headers={headers} />
                    {absences && absences.length > 0 ? (
                      absences.map((item) => {
                        let doctorD = allUsers.find(
                          (itemD) => itemD.login_access.id === item.login_access_id,
                        );
                        if (doctorD && doctorD.login_access.access_status === 'active')
                          return (
                            <>
                              <div
                                key={item.id}
                                className="rendez_vous_list_body_item_container"
                              >
                                <div className="rendez_vous_list_body_item">
                                  <div
                                    style={{ width: '15%' }}
                                    className="rv_list_body_sub_item"
                                    id="time_container_indis"
                                  >
                                    <i className="far fa-clock"></i>
                                    <span id="start_time">
                                      {' '}
                                      {moment(item.date_from).format('DD/MM/YYYY')}{' '}
                                      {item.hour_from}
                                    </span>
                                  </div>
                                  <div
                                    style={{ width: '15%' }}
                                    className="rv_list_body_sub_item"
                                    id="time_container_indis"
                                  >
                                    <i className="far fa-clock"></i>
                                    <span id="end_time">
                                      {' '}
                                      {moment(item.date_to).format('DD/MM/YYYY')}{' '}
                                      {item.hour_to}
                                    </span>
                                  </div>
                                  <div
                                    style={{ width: '25%' }}
                                    className="rv_list_body_sub_item rv_details"
                                  >
                                    <div className="rv_text_details">
                                      <span
                                        style={{ paddingLeft: 70 }}
                                      >{`${doctorD.first_name} ${doctorD.last_name}`}</span>
                                    </div>
                                  </div>
                                  <div
                                    style={{ width: '30%' }}
                                    className="rv_text_details"
                                  >
                                    <span style={{ paddingLeft: 50 }}>{item.note}</span>
                                  </div>
                                  <div
                                    style={{ width: '15%' }}
                                    className="rv_text_details"
                                  >
                                    <div
                                      onClick={() => {
                                        this.props.changeActiveItemSideBarSettings(
                                          5,
                                          true,
                                          false,
                                        );
                                        this.setState({
                                          motif_selected: true,
                                          initialValues: {
                                            ...initialValues,
                                            id: item.id,
                                            account_type:
                                              doctorD.login_access.account_type,
                                            login_access_id: item.login_access_id,
                                            hour_from: item.hour_from,
                                            hour_to: item.hour_to,
                                            date_from: item.date_from,
                                            date_to: item.date_to,
                                            note: item.note,
                                          },
                                        });
                                      }}
                                      className="more_icon_container"
                                      style={{ marginRight: '20px' }}
                                    >
                                      <i
                                        style={{
                                          padding: '0',
                                          border: '',
                                          color: '',
                                          borderRadius: '',
                                          backgroundColor: 'white',
                                        }}
                                        className="fas fa-edit"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                    <div
                                      onClick={() => {
                                        this.setState({
                                          selected_id: item.id,
                                        });
                                        this.openDeleteModal();
                                      }}
                                      className="more_icon_container"
                                      style={{ marginRight: '20px' }}
                                    >
                                      <i
                                        style={{
                                          padding: '0',
                                          border: '',
                                          color: '',
                                          borderRadius: '',
                                          backgroundColor: 'white',
                                        }}
                                        className="fas fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                      })
                    ) : (
                      <div
                        style={{ height: 'calc(60vh - 64px)' }}
                        className="consultation_detailed_info_section"
                      >
                        <div className="empty_consultation_result_container">
                          <div className="empty_consultation_result">
                            <img
                              src={require('../../../assets/img/undraw_design_team_af2y.svg')}
                            />
                            <span id="no_consultation">Liste vide !</span>
                            <span id="no_consultation_desc">
                              Veuillez créer des nouvelles absences pour vos
                              collaborateurs.
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                /* <TabPanel
                  value={(doctor && doctor.id) || firstDoctor.id}
                  index={(doctor && doctor.id) || firstDoctor.id}
                >

                </TabPanel>*/
                ''
              )}
            </div>
            <SwipeableDrawer
              style={{ width: '50%' }}
              anchor="right"
              open={switchList}
              onClose={this.toggleDrawer(anchor, false)}
              onOpen={this.toggleDrawer(anchor, true)}
            >
              <div className="right_side_bar_body">
                <span style={{ color: 'black' }}>
                  {motif_selected ? 'Modifier une absence' : 'Nouvelle absence'}
                </span>
                <span
                  onClick={async () => {
                    await this.setState({
                      initialValues: {
                        date_from: null,
                        date_to: null,
                        hour_from: null,
                        hour_to: null,
                        note: '',
                      },
                      //switchList: false,
                      motif_selected: null,
                    });
                    this.props.changeActiveItemSideBarSettings(5, false, false);
                  }}
                  style={{
                    cursor: 'pointer',
                    float: 'right',
                    fontSize: '18px',
                    fontWeight: '700',
                    color: 'black',
                  }}
                >
                  X
                </span>
                <div className="right_sidebar_body" id="Patient">
                  <div
                    className={
                      hasError && !initialValues.login_access_id
                        ? 'right_sidebar_body_item type3'
                        : 'right_sidebar_body_item'
                    }
                  >
                    <span id="date_de_debut_title">Collaborateur </span>
                    <div className="date_de_debut_icon input_and_icon">
                      <select
                        onChange={(e) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              login_access_id: e.target.value,
                            },
                          });
                        }}
                        value={initialValues.login_access_id}
                      >
                        <option value={null}>Choisir un collaborateur</option>
                        {allUsers.map((doctor) => {
                          return (
                            <option
                              value={doctor.login_access.id}
                            >{`${doctor.first_name} ${doctor.last_name}`}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div
                    className={
                      hasError && !initialValues.note
                        ? 'right_sidebar_body_item type3'
                        : 'right_sidebar_body_item'
                    }
                  >
                    <span id="date_de_debut_title">Motif </span>
                    <div className="date_de_debut_icon input_and_icon">
                      <input
                        name="note"
                        maxLength={15}
                        className="sub_text_container"
                        id="date_de_debut_text"
                        placeholder="Motif"
                        value={initialValues.note}
                        onChange={(e) =>
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              note: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={
                      (hasError && !initialValues.date_from) || hasErrorDate
                        ? 'right_sidebar_body_item type3'
                        : 'right_sidebar_body_item'
                    }
                  >
                    <span id="date_de_debut_title">Date de début</span>
                    <div className="date_de_debut_icon input_and_icon">
                      <input
                        name="startDate"
                        type="date"
                        value={
                          initialValues.date_from
                            ? moment(initialValues.date_from).format('YYYY-MM-DD')
                            : null
                        }
                        onChange={(e) =>
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              date_from: moment(e.target.value).format('YYYY-MM-DD'),
                            },
                          })
                        }
                        style={{ width: '-webkit-fill-available' }}
                        className="sub_text_container"
                        id="date_de_debut_text"
                        placeholder="Monday, September 7"
                      />
                    </div>
                  </div>

                  <div
                    className={
                      hasError && !initialValues.hour_from
                        ? 'right_sidebar_body_item type3'
                        : 'right_sidebar_body_item'
                    }
                  >
                    <span id="date_de_debut_title">Heure de debut</span>
                    <div className="date_de_debut_icon input_and_icon">
                      <input
                        name="startHours"
                        type="time"
                        className="sub_text_container"
                        id="date_de_debut_text"
                        placeholder="4:00"
                        value={initialValues.hour_from}
                        onChange={(e) =>
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              hour_from: e.target.value,
                            },
                          })
                        }
                        style={{ width: '-webkit-fill-available' }}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      (hasError && !initialValues.date_to) || hasErrorDate
                        ? 'right_sidebar_body_item type3'
                        : 'right_sidebar_body_item'
                    }
                  >
                    <span id="date_de_debut_title">Date de fin</span>
                    <div className="date_de_debut_icon input_and_icon">
                      <input
                        name="endDate"
                        type="date"
                        className="sub_text_container"
                        id="date_de_debut_text"
                        placeholder="Monday, September 7"
                        value={
                          initialValues.date_to
                            ? moment(initialValues.date_to).format('YYYY-MM-DD')
                            : null
                        }
                        onChange={(e) =>
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              date_to: moment(e.target.value).format('YYYY-MM-DD'),
                            },
                          })
                        }
                        style={{ width: '-webkit-fill-available' }}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      hasError && !initialValues.hour_to
                        ? 'right_sidebar_body_item type3'
                        : 'right_sidebar_body_item'
                    }
                  >
                    <span id="date_de_debut_title">Heure de fin</span>
                    <div className="date_de_debut_icon input_and_icon">
                      <input
                        name="endHours"
                        type="time"
                        value={initialValues.hour_to}
                        onChange={(e) =>
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              hour_to: e.target.value,
                            },
                          })
                        }
                        style={{ width: '-webkit-fill-available' }}
                        className="sub_text_container"
                        id="date_de_debut_text"
                        placeholder="5:00"
                      />
                    </div>
                  </div>
                </div>
                <div className="right_sidebar_footer confirm_btn_container">
                  <button
                    onClick={
                      () => this.submitForm(initialValues, params, doctor)
                      /* this.setState({
                        switchList: false,
                      })*/
                    }
                    className="confirm_recap_info_btn"
                  >
                    Valider
                  </button>
                </div>
              </div>
            </SwipeableDrawer>
            <div
              className="right_side_bar"
              style={{
                width: '42%',
                height: '100%',
                marginRight: '4%',
                display: switchList ? '' : 'none',
                borderLeft: '1px solid #ECEEF5',
                overflowY: 'auto',
              }}
            ></div>
          </div>
        ) : (
          <div className="consultation_detailed_info_section">
            <div className="empty_consultation_result_container">
              <div className="empty_consultation_result">
                <img src={require('../../../assets/img/undraw_design_team_af2y.svg')} />
                <span id="no_consultation">Pas de collaborateurs !</span>
                <span id="no_consultation_desc">
                  Essayez de créer des nouveaux collaborateurs.
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AbsenceManagement);
