import React, { Component } from 'react';
import { SelectorBilanItemSection, SelectorColorInputCheckbox } from '../../../../utils';
import UploadFile from '../../upload_file';import { Slider, RangeSlider } from 'rsuite';

class Traitement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEntretien: true,
      showDouleur: false,
      showBilanArticulaire: false,
      showScore: false,
    };
    this.handleChangeSubSectionBtn = this.handleChangeSubSectionBtn.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    SelectorColorInputCheckbox();
    SelectorBilanItemSection();
  }
  handleChangeSubSectionBtn(name) {
    this.setState({
      [name]: !this.state[name],
    });
  }
  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }
  render() {
    const { sandwich_menu_btn, initialValues } = this.props;
    return (
      <div className="actual_bilan_settings">
        <div className="actual_bilan_settings_item head_shaking_test" id="headShaking1">
          <div
            style={{ backgroundColor: '#6c89bc' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}> Traitements</span>
          </div>
          <br />
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Techniques (base)
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Antalgique"
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_1"
                              checked={initialValues.check_traitement_1}
                              value="Massage"
                            />
                            <label className="form-check-label" htmlFor={`Antalgique`}>
                              Massage
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_2"
                              checked={initialValues.check_traitement_2}
                              value="Physiothérapie"
                              className="form-check-input"
                              type="checkbox"
                              id={`Physiothérapie`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`Physiothérapie`}
                            >
                              Physiothérapie
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_3"
                              checked={initialValues.check_traitement_3}
                              value="Techniques mobilisatrice"
                              className="form-check-input"
                              type="checkbox"
                              id={`mobilisatrice`}
                            />
                            <label className="form-check-label" htmlFor={`mobilisatrice`}>
                              Techniques mobilisatrice
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_4"
                              checked={initialValues.check_traitement_4}
                              value="Renforcement musculaire"
                              className="form-check-input"
                              type="checkbox"
                              id={`Renforcement`}
                            />
                            <label className="form-check-label" htmlFor={`Renforcement`}>
                              Renforcement musculaire
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_5"
                              checked={initialValues.check_traitement_5}
                              value="Extensibilité musculaire"
                              className="form-check-input"
                              type="checkbox"
                              id={`Extensibilité`}
                            />
                            <label className="form-check-label" htmlFor={`Extensibilité`}>
                              Extensibilité musculaire
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_6"
                              checked={initialValues.check_traitement_6}
                              value="Travail fonctionnel"
                              id={`souplesse`}
                            />
                            <label className="form-check-label" htmlFor={`souplesse`}>
                              Travail fonctionnel
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_7"
                              checked={initialValues.check_traitement_7}
                              value="Rééd. Neuro-musculaire"
                              className="form-check-input"
                              type="checkbox"
                              id={`Neuro-musculaire`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`Neuro-musculaire`}
                            >
                              Rééd. Neuro-musculaire
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_8"
                              checked={initialValues.check_traitement_8}
                              value="Rééd. proprioceptive"
                              className="form-check-input"
                              type="checkbox"
                              id={`proprioceptive`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`proprioceptive`}
                            >
                              Rééd. proprioceptive
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_9"
                              checked={initialValues.check_traitement_9}
                              value="Education et conseils"
                              className="form-check-input"
                              type="checkbox"
                              id={`conseils`}
                            />
                            <label className="form-check-label" htmlFor={`conseils`}>
                              Education et conseils
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Gain de mobilité
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="check_traitement_1_1"
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_1_1"
                              checked={initialValues.check_traitement_1_1}
                              value="Mobilisation active et passive"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_traitement_1_1`}
                            >
                              Mobilisation active et passive
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_1_2"
                              checked={initialValues.check_traitement_1_2}
                              value="Mobilisations spécifiques"
                              className="form-check-input"
                              type="checkbox"
                              id={`check_traitement_1_2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_traitement_1_2`}
                            >
                              Mobilisations spécifiques
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_1_3"
                              checked={initialValues.check_traitement_1_3}
                              value="Contracté-relaché"
                              className="form-check-input"
                              type="checkbox"
                              id={`check_traitement_1_3`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_traitement_1_3`}
                            >
                              Contracté-relaché
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_1_4"
                              checked={initialValues.check_traitement_1_4}
                              value="Stretching"
                              className="form-check-input"
                              type="checkbox"
                              id={`check_traitement_1_4`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_traitement_1_4`}
                            >
                              Stretching
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '100%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Massages
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Antalgique"
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_10"
                              checked={initialValues.check_traitement_10}
                              value="Antalgique"
                            />
                            <label className="form-check-label" htmlFor={`Antalgique`}>
                              Antalgique
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_11"
                              checked={initialValues.check_traitement_11}
                              value="Décontracturant"
                              id={`Décontracturant`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`Décontracturant`}
                            >
                              Décontracturant
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_12"
                              checked={initialValues.check_traitement_12}
                              value="Circulaire"
                              className="form-check-input"
                              type="checkbox"
                              id={`Circulaire`}
                            />
                            <label className="form-check-label" htmlFor={`Circulaire`}>
                              Circulaire
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_13"
                              checked={initialValues.check_traitement_13}
                              value="Défibrosant"
                              className="form-check-input"
                              type="checkbox"
                              id={`Défibrosant`}
                            />
                            <label className="form-check-label" htmlFor={`Défibrosant`}>
                              Défibrosant
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_14"
                              checked={initialValues.check_traitement_14}
                              value="Transverse profont"
                              className="form-check-input"
                              type="checkbox"
                              id={`Transverse`}
                            />
                            <label className="form-check-label" htmlFor={`Transverse`}>
                              Transverse profont
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_15"
                              checked={initialValues.check_traitement_15}
                              value="Palper-rouler"
                              className="form-check-input"
                              type="checkbox"
                              id={`Palper-rouler`}
                            />
                            <label className="form-check-label" htmlFor={`Palper-rouler`}>
                              Palper-rouler
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_16"
                              checked={initialValues.check_traitement_16}
                              value="DLM"
                              className="form-check-input"
                              type="checkbox"
                              id={`DLM`}
                            />
                            <label className="form-check-label" htmlFor={`DLM`}>
                              DLM
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Contre la douleur et l'oedèmz
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="habiller"
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_17"
                              checked={initialValues.check_traitement_17}
                              value="Bandage compressif"
                            />
                            <label className="form-check-label" htmlFor={`habiller`}>
                              Bandage compressif
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_18"
                              checked={initialValues.check_traitement_18}
                              value="Contension adhésive"
                              id={`Shirt`}
                            />
                            <label className="form-check-label" htmlFor={`Shirt`}>
                              Contension adhésive
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_19"
                              checked={initialValues.check_traitement_19}
                              value="Orthése semi rigide"
                              className="form-check-input"
                              type="checkbox"
                              id={`chaussenttes`}
                            />
                            <label className="form-check-label" htmlFor={`chaussenttes`}>
                              Orthése semi rigide
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_20"
                              checked={initialValues.check_traitement_20}
                              value="Cryothérapie"
                              className="form-check-input"
                              type="checkbox"
                              id={`veste`}
                            />
                            <label className="form-check-label" htmlFor={`veste`}>
                              Cryothérapie
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_21"
                              checked={initialValues.check_traitement_21}
                              value="Massage antalgique"
                              className="form-check-input"
                              type="checkbox"
                              id={`meuble`}
                            />
                            <label className="form-check-label" htmlFor={`meuble`}>
                              Massage antalgique
                            </label>
                          </div>
                        </div>

                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_22"
                              checked={initialValues.check_traitement_22}
                              value="Pressothérapie"
                              className="form-check-input"
                              type="checkbox"
                              id={`couteau`}
                            />
                            <label className="form-check-label" htmlFor={`couteau`}>
                              Pressothérapie
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_23"
                              checked={initialValues.check_traitement_23}
                              value="DLM"
                              className="form-check-input"
                              type="checkbox"
                              id={`carafe`}
                            />
                            <label className="form-check-label" htmlFor={`carafe`}>
                              DLM
                            </label>
                          </div>
                        </div>

                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_24"
                              checked={initialValues.check_traitement_24}
                              value="Basses fréquences"
                              className="form-check-input"
                              type="checkbox"
                              id={`couteauc`}
                            />
                            <label className="form-check-label" htmlFor={`couteauc`}>
                              Basses fréquences
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_25"
                              checked={initialValues.check_traitement_25}
                              value="Autre techniques"
                              className="form-check-input"
                              type="checkbox"
                              id={`carafec`}
                            />
                            <label className="form-check-label" htmlFor={`carafec`}>
                              Autre techniques
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Mobilisations
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="passives"
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_26"
                              checked={initialValues.check_traitement_26}
                              value="Mobilisations passives"
                            />
                            <label className="form-check-label" htmlFor={`passives`}>
                              Mobilisations passives
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_27"
                              checked={initialValues.check_traitement_27}
                              value="Mobilisations actives"
                              className="form-check-input"
                              type="checkbox"
                              id={`passives`}
                            />
                            <label className="form-check-label" htmlFor={`passives`}>
                              Mobilisations actives
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_28"
                              checked={initialValues.check_traitement_28}
                              value="Mobilisations activo-passives"
                              className="form-check-input"
                              type="checkbox"
                              id={`passives`}
                            />
                            <label className="form-check-label" htmlFor={`passives`}>
                              Mobilisations activo-passives
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_29"
                              checked={initialValues.check_traitement_29}
                              value="Mobilisations spécifiques"
                              className="form-check-input"
                              type="checkbox"
                              id={`spécifiques`}
                            />
                            <label className="form-check-label" htmlFor={`spécifiques`}>
                              Mobilisations spécifiques
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_30"
                              checked={initialValues.check_traitement_30}
                              value="postures"
                              className="form-check-input"
                              type="checkbox"
                              id={`postures`}
                            />
                            <label className="form-check-label" htmlFor={`postures`}>
                              postures
                            </label>
                          </div>
                        </div>

                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_31"
                              checked={initialValues.check_traitement_31}
                              value="Tractions axiales"
                              className="form-check-input"
                              type="checkbox"
                              id={`Tractions`}
                            />
                            <label className="form-check-label" htmlFor={`Tractions`}>
                              Tractions axiales
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_32"
                              checked={initialValues.check_traitement_32}
                              value="Contracté-relaché"
                              className="form-check-input"
                              type="checkbox"
                              id={`Contracté`}
                            />
                            <label className="form-check-label" htmlFor={`Contracté`}>
                              Contracté-relaché
                            </label>
                          </div>
                        </div>

                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_33"
                              checked={initialValues.check_traitement_33}
                              value="Entirements musculaire"
                              className="form-check-input"
                              type="checkbox"
                              id={`Entirements`}
                            />
                            <label className="form-check-label" htmlFor={`Entirements`}>
                              Entirements musculaire
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_34"
                              checked={initialValues.check_traitement_34}
                              value="Stretching"
                              className="form-check-input"
                              type="checkbox"
                              id={`Stretching`}
                            />
                            <label className="form-check-label" htmlFor={`Stretching`}>
                              Stretching
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_35"
                              checked={initialValues.check_traitement_35}
                              value="Auto-étirements"
                              className="form-check-input"
                              type="checkbox"
                              id={`étirements`}
                            />
                            <label className="form-check-label" htmlFor={`étirements`}>
                              Auto-étirements
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Physiothérapie
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="antalgique"
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_36"
                              checked={initialValues.check_traitement_36}
                              value="Electro-antalgique"
                            />
                            <label className="form-check-label" htmlFor={`antalgique`}>
                              Electro-antalgique
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_37"
                              checked={initialValues.check_traitement_37}
                              value="Electrostimulation musculaire"
                              className="form-check-input"
                              type="checkbox"
                              id={`musculaire`}
                            />
                            <label className="form-check-label" htmlFor={`musculaire`}>
                              Electrostimulation musculaire
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_38"
                              checked={initialValues.check_traitement_38}
                              value="Electrostimulation musculaire"
                              className="form-check-input"
                              type="checkbox"
                              id={`Cryothérapie`}
                            />
                            <label className="form-check-label" htmlFor={`Cryothérapie`}>
                              Cryothérapie
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_39"
                              checked={initialValues.check_traitement_39}
                              value="Thermothérapie"
                              className="form-check-input"
                              type="checkbox"
                              id={`Thermothérapie`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`Thermothérapie`}
                            >
                              Thermothérapie
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_40"
                              checked={initialValues.check_traitement_40}
                              value="Ultrasons"
                              className="form-check-input"
                              type="checkbox"
                              id={`Ultrasons`}
                            />
                            <label className="form-check-label" htmlFor={`Ultrasons`}>
                              Ultrasons
                            </label>
                          </div>
                        </div>

                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_41"
                              checked={initialValues.check_traitement_41}
                              value="Ondes de choc"
                              className="form-check-input"
                              type="checkbox"
                              id={`choc`}
                            />
                            <label className="form-check-label" htmlFor={`choc`}>
                              Ondes de choc
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_42"
                              checked={initialValues.check_traitement_42}
                              value="Pressothérapie"
                              className="form-check-input"
                              type="checkbox"
                              id={`Pressothérapie`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`Pressothérapie`}
                            >
                              Pressothérapie
                            </label>
                          </div>
                        </div>

                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_43"
                              checked={initialValues.check_traitement_43}
                              value="Balnèothérapie"
                              className="form-check-input"
                              type="checkbox"
                              id={`Balnèothérapie`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`Balnèothérapie`}
                            >
                              Balnèothérapie
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '100%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Appareillage
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_44"
                              checked={initialValues.check_traitement_44}
                              value="Bandage compressif"
                              className="form-check-input"
                              type="checkbox"
                              id="compressif"
                            />
                            <label className="form-check-label" htmlFor={`compressif`}>
                              Bandage compressif
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_45"
                              checked={initialValues.check_traitement_45}
                              value="Orthèse"
                              className="form-check-input"
                              type="checkbox"
                              id={`Orthèse`}
                            />
                            <label className="form-check-label" htmlFor={`Orthèse`}>
                              Orthèse
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_46"
                              checked={initialValues.check_traitement_46}
                              value="Contension"
                              className="form-check-input"
                              type="checkbox"
                              id={`Contension`}
                            />
                            <label className="form-check-label" htmlFor={`Contension`}>
                              Contension
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_traitement_47"
                              checked={initialValues.check_traitement_47}
                              value="Strapping"
                              className="form-check-input"
                              type="checkbox"
                              id={`Strapping`}
                            />
                            <label className="form-check-label" htmlFor={`Strapping`}>
                              Strapping
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
              className="actual_bilan_settings_item_body "
              id="headShaking1_sub_section"
              style={{ display: 'flex', width: '100%' }}
          >
            <div
                className="bilan_item"
                style={{backgroundColor: '#f3f4f9',width:"100%"
                }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                      style={{

                        alignItems: 'start',
                      }}
                      className="bilan_contact_info"
                  >
                    <span
                        style={{
                          marginLeft: '3%',
                        }}
                        id="name"
                    >
                      Autres traitements
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="additional_container_item_field">
                        <textarea
                            onChange={this.props.handleChangeInput}
                            name="textarea_traitements"
                            value={initialValues.textarea_traitements}
                            maxLength="500"
                            rows="10"
                            style={{ width: '100%' }}
                            placeholder="Autres traitements"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Traitement;
