const initialUserState = {
  bordereaux: [],
  bordereau: null,
  isLoadingBordereau: false,
  isLoadingBordereaux: false,
  isLoadingBillsByIds: true,
  billsByIds: [],
  isLoadingAddBordereau: false,
  addBordereau: null,
  lastBordereau: null,
  isLoadingLastBordereau: true,
  bordereauDeleted: false,
  params: {
    limit: 3,
    currentPage: 1,
    search: '',
    status: '',
  },
};

const BordereauxReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case 'GET_LAST_BORDEREAU_REQUESTING': {
      return {
        ...state,
        isLoadingLastBordereau: true,
      };
    }
    case 'GET_LAST_BORDEREAU_SUCCESS': {
      return {
        ...state,
        isLoadingLastBordereau: false,
        lastBordereau: action.data,
      };
    }
    case 'GET_LAST_BORDEREAU_FAILURE': {
      return {
        ...state,
        isLoadingLastBordereau: false,
        lastBordereau: null,
      };
    }

    case 'SUBMIT_BORDEREAU_REQUESTING': {
      return {
        ...state,
        isLoadingAddBordereau: true,
      };
    }
    case 'SUBMIT_BORDEREAU_SUCCESS': {
      return {
        ...state,
        isLoadingAddBordereau: false,
        addBordereau: action.data,
      };
    }
    case 'SUBMIT_BORDEREAU_FAILURE': {
      return {
        ...state,
        isLoadingAddBordereau: false,
        addBordereau: null,
      };
    }

    case 'GET_BILLS_BY_IDS_REQUESTING': {
      return {
        ...state,
        isLoadingBillsByIds: true,
      };
    }
    case 'GET_BILLS_BY_IDS_SUCCESS': {
      return {
        ...state,
        isLoadingBillsByIds: false,
        billsByIds: action.data,
      };
    }
    case 'GET_BILLS_BY_IDS_FAILURE': {
      return {
        ...state,
        isLoadingBillsByIds: false,
        billsByIds: [],
      };
    }

    case 'GET_BORDEREAU_REQUESTING': {
      return {
        ...state,
        addBordereau: null,
        isLoadingBordereau: true,
      };
    }
    case 'GET_BORDEREAU_SUCCESS': {
      return {
        ...state,
        isLoadingBordereau: false,
        bordereau: action.data,
      };
    }
    case 'GET_BORDEREAU_FAILURE': {
      return {
        ...state,
        isLoadingBordereau: false,
        bordereau: null,
      };
    }

    case 'GET_BORDEREAUX_REQUESTING': {
      return {
        ...state,
        isLoadingBordereaux: true,
      };
    }
    case 'GET_BORDEREAUX_SUCCESS': {
      return {
        ...state,
        isLoadingBordereaux: false,
        bordereaux: action.data,
      };
    }
    case 'GET_BORDEREAUX_FAILURE': {
      return {
        ...state,
        isLoadingBordereaux: false,
        bordereaux: [],
      };
    }

    case 'UPDATE_SEARCH_BORDEREAU_PARAMS': {
      return {
        ...state,
        params: action.params,
      };
    }

    case 'ACTION_DELETE_BORDEREAU_SUCCESS': {
      return {
        ...state,
        bordereauDeleted: true,
      };
    }

    case 'ACTION_DELETE_BORDEREAU_FAILURE': {
      return {
        ...state,
        bordereauDeleted: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default BordereauxReducer;
