import { da } from 'date-fns/locale';
import moment from 'moment-timezone';
import React from 'react';
import axios from 'axios';

const PHONE_FR_FORMAT_REGEXP = new RegExp(/^\+216\d{9}$/, 'i');
const momentLocale = 'YYYY-MM-DD HH:MM:SS';
export const ROLE_DOCTOR = 'DOC';
export const ROLE_CLIENT = 'CLT';
export const ROLE_ADMIN = 'ADM';
export const ERROR_JS = 0;
export const ERROR_NETWORK = 600;
export const ERROR_AUTH = 401;
export const ERROR_REQ = 400;
export const ERROR_APP = 700;
export const ERROR_SERVER = 500;
const PHONE_INTL_FORMAT_REGEXP = new RegExp(/^\+\d+$/, 'i');
export const TOAST_CLOSE_DELAY = 4000;
const codeCNAM = [
  71, 0, 74, 73, 72, 70, 59, 69, 58, 61, 64, 81, 80, 85, 83, 82, 86, 84, 11, 10, 88, 87,
  12, 27, 13, 14, 15, 22, 23, 26, 19, 75, 16, 17, 18, 30, 25, 20, 28, 29, 31, 32, 34, 46,
  36, 47, 48, 37, 39, 38, 49, 50, 44, 52, 63, 45, 55, 53, 62, 56, 65, 60, 51, 57, 54, 40,
  41, 43, 67, 76, 68, 66,
];
export const isCodeCNAM = (code) => {
  if (code && code.toString().length === 2) {
    return codeCNAM.includes(parseInt(code));
  }
};

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const lengthPhone = (n) => {
  return n.toString().length === 8;
};

export const firstNumberPhone = (n) => {
  if (
    parseInt(n[0]) === 2 ||
    parseInt(n[0]) === 4 ||
    parseInt(n[0]) === 5 ||
    parseInt(n[0]) === 7 ||
    parseInt(n[0]) === 3 ||
    parseInt(n[0]) === 0 ||
    parseInt(n[0]) === 1 ||
    parseInt(n[0]) === 6 ||
    parseInt(n[0]) === 9
  )
    return true;
  return false;
};

export const validate = (value) => {
  const re = /^[A-Za-zô'éèêçàáâòóý\s]+$/;
  if (re.test(value)) return true;
  else return false;
};
export const getStatusError = (error) => {
  if (error.response) return error.response.status;
  if (error.message === 'Network Error') return ERROR_NETWORK;
  return ERROR_JS;
};

export const getAppErrorCode = (status) => {
  if (!status) return ERROR_NETWORK;
  else if (status === ERROR_AUTH) return ERROR_AUTH;
  else if (status === ERROR_REQ) return ERROR_REQ;
  else if (status === ERROR_APP) return undefined;
  return ERROR_SERVER;
};

export const isAmount = (string, allowEmptyString = false, allowThreeDecimal = false) => {
  const r = allowThreeDecimal ? /^-?[\d]*[,.]?[\d]{0,3}$/ : /^-?[\d]*[,.]?[\d]{0,2}$/;
  if (allowEmptyString && string === '') return true;
  const regex = new RegExp(r, 'i');
  return !!string.match(regex);
};

export const tryGetAmount = (value, allowThreeDecimal = false) => {
  if (typeof value === 'number') return value;
  if (!value || value.length === 0) return Number.NaN;
  // Remove space and euro characters
  const processed = value.replace(/\s/g, '').replace(/€/g, '');
  if (!isAmount(processed, false, allowThreeDecimal)) return Number.NaN;
  // Parse float with comma authorized in addition to dot
  return parseFloat(processed.replace(/,/g, '.'));
};

export const getAmount = (value, allowThreeDecimal = false) => {
  const parsed = tryGetAmount(value, allowThreeDecimal);
  if (Number.isNaN(parsed)) return 0.0;
  return parsed;
};

export const formatAmount = (
  value,
  ensureFractionDigits = true,
  emptyIfZero = false,
  allowThreeDecimal = false,
) => {
  const amount = getAmount(value, allowThreeDecimal);
  return amount || !emptyIfZero
    ? amount.toLocaleString('fr-FR', {
        minimumFractionDigits: ensureFractionDigits ? 2 : undefined,
        maximumFractionDigits: allowThreeDecimal ? 3 : 2,
      })
    : '';
};

export const isDate = (string) => {
  if (!string || string === '') return false;
  const parsedDate = moment(string, momentLocale);
  const regex = new RegExp(/^[\d]{1,2}\/[\d]{1,2}(\/[\d]{1,4})?$/, 'i');
  return parsedDate.isValid() && !!string.match(regex);
};

export const isFloat = (a) => {
  return a - a === 0 && a.toString(32).indexOf('.') !== -1;
};

export const isAuthorized = (user, roles) => {
  const userRoles = user.Roles;
  if (!userRoles) {
    return false;
  }
  const userRolesCodes = userRoles.map((role) => role.code);
  return roles.some((role) => userRolesCodes.includes(role));
};

export const setFormRoles = (userRoles, roles) => {
  const formRoles = {
    Own: [],
    Other: [],
  };
  roles.forEach((role) => {
    if (userRoles.some((_role) => _role.code === role.code)) {
      formRoles.Own.push(role);
    } else {
      formRoles.Other.push(role);
    }
  });
  return formRoles;
};

export const phoneToPrettyFormat = (number) => {
  if (!number) return '';
  if (!number.match(PHONE_FR_FORMAT_REGEXP)) return number;
  const _num = number.replace(/^\+216/, '0');
  return `${_num.substr(0, 2)} ${_num.substr(2, 2)} ${_num.substr(4, 2)} ${_num.substr(
    6,
    2,
  )} ${_num.substr(8, 2)}`;
};
/**
 * Format french phone number to be international (starts with '+33')
 * Note: already international numbers are returned untouched
 * @param {string} number
 */
export const phoneNumberToIntlFormat = (number) => {
  if (!number) return '';
  //if (number.match(PHONE_INTL_FORMAT_REGEXP)) return number;
  return number.substr(3, 10);
  // .replace(/^0/, '+216');
};

export const diffDate = (dateMessage) => {
  const dateNow = moment(moment().format('DD-MM-YYYY HH:mm:dd'), 'DD-MM-YYYY HH:mm:dd');
  dateMessage = moment(
    moment(dateMessage).format('DD-MM-YYYY HH:mm:dd'),
    'DD-MM-YYYY HH:mm:dd',
  );
  var duration = moment.duration(dateMessage.diff(dateNow));
  let time;
  const days = duration.asDays() * -1;
  const hours = duration.asHours() * -1;
  const minutes = duration.asMinutes() * -1;
  time = moment(dateMessage).format('HH:mm');
  //if (minutes > 5) {
  if (minutes === 0) {
    time = '';
  }
  if (minutes < 59 && minutes > 1) {
    time = minutes + ' mn';
  } else if (hours > 24 && (days < 30 || days < 31)) {
    switch (moment(dateMessage).locale('en').format('ddd')) {
      case 'Mon':
        time = 'Lundi ' + moment(dateMessage).format('HH:mm');
        break;
      case 'Tue':
        time = 'Mardi ' + moment(dateMessage).format('HH:mm');
        break;
      case 'Wed':
        time = 'Mercredi ' + moment(dateMessage).format('HH:mm');
        break;
      case 'Thu':
        time = 'Jeudi ' + moment(dateMessage).format('HH:mm');
        break;
      case 'Fri':
        time = 'Vendredi ' + moment(dateMessage).format('HH:mm');
        break;
      case 'Sat':
        time = 'Samedi ' + moment(dateMessage).format('HH:mm');
        break;
      case 'Sun':
        time = 'Dimanche ' + moment(dateMessage).format('HH:mm');
        break;
      default:
        time = 'Dimanche';
        break;
    }
  } else if (days > 30 || days > 31) {
    time = `${moment(dateMessage).format('MM/DD')} ${moment(dateMessage).format(
      'HH:mm',
    )}`;
  }
  return time;
  // } else return null
};

export const generateBillNumber = (num, year) => {
  const currentYear = moment().format('YYYY');
  if (year === currentYear) {
    if (num === 0) num++;
    return `${num}/${year}`;
  } else return `1/${currentYear}`;
};

export const getPermissionSelected = (group, code) => {
  let permissions = [];
  group.map((item) => {
    item.permissions.map((permission) => {
      if (permission.code.includes(code)) {
        permissions.push(permission.id);
      }
    });
  });
  return permissions;
};

export const getPermissionFormated = (permissions) => {
  let new_permissions = [];
  permissions.map((permission) => {
    new_permissions.push(permission.id);
  });
  return new_permissions;
};

export const isHasPermission = (permissions, perm) => {
  let isPermission = false;
  permissions.map((permission) => {
    if (permission.code === perm) isPermission = true;
  });
  return isPermission;
};

export const deformedData = (data) => {
  let options = [];
  if (data.length > 0)
    data.map((item) => {
      options.push({ value: item.id, label: `${item.first_name} ${item.last_name}` });
    });
  return options;
};
export const newDataEmployeeManagement = (employeeManagement, userListName) => {
  let data = [];
  if (employeeManagement.length > 0)
    employeeManagement.map((item) => {
      if (userListName.indexOf(`${item.first_name} ${item.last_name}`) > -1)
        data.push({ id: item.id, title: `${item.first_name} ${item.last_name}` });
    });
  return data;
};

export const newDataEmployeeManagementDraft = (employeeManagement, userListName) => {
  let data = [];
  if (employeeManagement.length > 0)
    employeeManagement.map((item) => {
      data.push({ id: item.id, title: `${item.first_name} ${item.last_name}` });
    });
  return data;
};

export const calculateMoneyPotAmount = (
  old_money_pot_amount,
  old_already_paid,
  settled_in_session,
  session_price,
) => {
  let money_pot_amount = 0;
  if (parseFloat(session_price) >= parseFloat(settled_in_session)) {
    // S'il ne paye rien ou moins que le tarif séance
    //Dans ce cas
    if (
      parseFloat(old_money_pot_amount) >=
      parseFloat(session_price) - parseFloat(settled_in_session)
    ) {
      //C'est à dire le montant cagnotte est suffisant
      // Reste à Payer ne change pas
      // Et la cagnotte diminue
      money_pot_amount =
        parseFloat(old_money_pot_amount) -
        (parseFloat(session_price) - parseFloat(settled_in_session));
    }
    //cagnotte insuffisante
    else {
      money_pot_amount = 0;
    }
  }
  //Il a payé plus que le tarif séance
  else {
    //Reste à Payer ne change pas et cagnotte augmente
    money_pot_amount =
      parseFloat(old_money_pot_amount) +
      parseFloat(settled_in_session) -
      parseFloat(old_already_paid) -
      parseFloat(session_price);
  }
  return parseFloat(money_pot_amount).toFixed(2);
};

export const calculateAlreadyPaid = (
  old_money_pot_amount,
  old_left_to_pay,
  settled_in_session,
  session_price,
) => {
  let left_to_pay = 0;
  if (parseFloat(session_price) >= parseFloat(settled_in_session)) {
    // S'il ne paye rien ou moins que le tarif séance
    //Dans ce cas
    if (
      parseFloat(old_money_pot_amount) >=
      parseFloat(session_price) - parseFloat(settled_in_session)
    ) {
      //C'est à dire le montant cagnotte est suffisant
      // Reste à Payer ne change pas
      // Et la cagnotte diminue
    }
    //cagnotte insuffisante
    else {
      left_to_pay =
        parseFloat(old_left_to_pay) +
        (parseFloat(session_price) -
          parseFloat(settled_in_session) -
          parseFloat(old_money_pot_amount));
    }
  }
  return parseFloat(left_to_pay).toFixed(2);
};

export const generateSlotsAuto = (initialValues, daysIndex) => {
  let Slots = []; //le tableau des rdv à remplir par les séances générées avant de les insérer dans la
  let startDate = initialValues.start_date;
  var generatedSlotsCount = 0;
  while (generatedSlotsCount < initialValues.sessions_count) {
    if (daysIndex.includes(moment(startDate).day())) {
      let day = initialValues.getDispo.filter(
        (day) => parseInt(day.indexVal) === parseInt(moment(startDate).day()),
      );
      const time = moment(day[0].hour_start, 'HH:mm').tz('UTC').utc();
      const timeEnd = moment(day[0].hour_end, 'HH:mm').tz('UTC').utc();
      const time1 = moment(day[0].hour_start, 'HH:mm');
      const timeEnd1 = moment(day[0].hour_end, 'HH:mm');
      Slots.push({
        color: 'yellow',
        date: moment(startDate)
          .tz('UTC')
          .utc()
          .set({ hour: time.get('hour'), minute: time.get('minute') })
          .format('YYYY-MM-DD HH:mm:ss'),
        end: moment(startDate)
          .set({ hour: timeEnd1.get('hour'), minute: timeEnd1.get('minute') })
          .format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(startDate)
          .set({ hour: timeEnd.get('hour'), minute: timeEnd.get('minute') })
          .format('YYYY-MM-DD HH:mm:ss'),
        resourceIds: [initialValues.practitioner_id],
        sort: 0,
        start: moment(startDate)
          .set({ hour: time1.get('hour'), minute: time1.get('minute') })
          .format('YYYY-MM-DD HH:mm:ss'),
        start_date: moment(startDate)
          .set({ hour: time.get('hour'), minute: time.get('minute') })
          .format('YYYY-MM-DD HH:mm:ss'),
        title: initialValues.title,
        type: 'session',
        week: moment(startDate).format('DD'),
      });
      generatedSlotsCount++;
    }
    startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD HH:mm:ss');
  }
  return Slots;
};

export const validateImage = async (url) => {
  return await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `JWT ${window.localStorage.getItem('token')}`,
    },
    responseType: 'blob', //Force to receive data in a Blob Format
  })
    .then((response) => {
      console.log(URL.createObjectURL(new Blob([response.data])));
      return URL.createObjectURL(new Blob([response.data]));
    })
    .catch((error) => {
      return false;
    });
};
