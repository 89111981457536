import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Empty_container from '../../../components/empty_container';
import { isHasPermission } from '../../../utils/helpres';
import moment from 'moment';

class Receipt extends Component {
  render() {
    const { receipts, patientItem, patient, user, consultationID } = this.props;
    if (receipts && receipts.length === 0)
      return (
        <Empty_container
          user={user}
          permission="ADD_NEW_RECEIPT"
          title="Pas de reçus !"
          body="Dès qu'une consultation est créée, vous pourrez y ajouter des reçus."
          btn_name="Ajouter reçu"
          url={{
            pathname: patient ? '/patient/receipt' : `/add-receipt/${consultationID}`,
            state: {
              patient_id: patientItem,
            },
          }}
        />
      );
    return (
      <div className="add_bilan_container" style={{ marginTop: '15px' }}>
        <div className="add_bilan_container_header">
          <div className="left">
            <span>Reçus</span>
          </div>
          {isHasPermission(user.login_access.permissions, 'ADD_NEW_RECEIPT') ? (
            <Link
              to={{
                pathname: patient ? '/patient/receipt' : `/add-receipt/${consultationID}`,
                state: {
                  patient_id: patientItem,
                },
              }}
            >
              <div className="right">
                <svg
                  width="12"
                  height="11"
                  viewBox="0 0 12 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.2381 6.23828H6.73805V10.7383H5.26149V6.23828H0.76149V4.76172H5.26149V0.261719H6.73805V4.76172H11.2381V6.23828Z"
                    fill="#5446DB"
                  />
                </svg>
                <span>Ajouter reçu</span>
              </div>
            </Link>
          ) : (
            ''
          )}
        </div>
        <div className="add_document_container_body">
          {receipts && receipts.length > 0
            ? receipts.map((receipt) => {
                return (
                  <div key={receipt.id} className="add_document_content_preview">
                    <div className="add_document_content_preview_item">
                      <div
                        style={{ width: '80%' }}
                        className="bilan_preview_sub_item"
                        id="patient_consulation_timing"
                      >
                        <svg
                          width="38"
                          height="38"
                          viewBox="0 0 38 38"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="37"
                            height="37"
                            rx="9.5"
                            fill="white"
                            stroke="#ECEEF5"
                          />
                          <path
                            d="M19.7383 16.75H23.8867L19.7383 12.6367V16.75ZM21.9883 20.5117V19H16.0117V20.5117H21.9883ZM21.9883 23.5V21.9883H16.0117V23.5H21.9883ZM20.5117 11.5117L25.0117 16.0117V25.0117C25.0117 25.4102 24.8594 25.7617 24.5547 26.0664C24.25 26.3477 23.8984 26.4883 23.5 26.4883H14.5C14.1016 26.4883 13.75 26.3477 13.4453 26.0664C13.1406 25.7617 12.9883 25.4102 12.9883 25.0117L13.0234 12.9883C13.0234 12.5898 13.1641 12.25 13.4453 11.9688C13.75 11.6641 14.1016 11.5117 14.5 11.5117H20.5117Z"
                            fill="#8083A3"
                          />
                        </svg>
                        <div className="prise_en_charge_text">
                          <span id="prise_en_charge_text_title">{receipt.number}</span>
                          <span id="prise_en_charge_text_desc">
                            {' '}
                            {moment(receipt.date).format('DD/MM/YYYY')}
                          </span>
                        </div>
                      </div>
                      {isHasPermission(
                        user.login_access.permissions,
                        'RECEIPT_ACCESS',
                      ) ? (
                        <Link key={receipt.id} to={`/show-receipt/${receipt.id}`}>
                          <div
                            style={{ marginRight: '1%' }}
                            className="more_icon_container"
                          >
                            <i
                              style={{
                                padding: '0',
                                border: '',
                                color: '',
                                borderRadius: '',
                                backgroundColor: 'white',
                              }}
                              className="fas fa-eye"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </Link>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                );
              })
            : ''}
        </div>
      </div>
    );
  }
}
export default Receipt;
