import React, { Component } from 'react';
import './add_form.css';
import actions from './store/actions';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SwipeableDrawer } from '@material-ui/core';
import Form from './containers/form';

const mapStateToProps = (state, ownProps) => {
  return {
    isLoadingDepenseById: state.DepenseReducer.isLoadingDepenseById,
    depense: state.DepenseReducer.depense,
    actionDepense: state.DepenseReducer.actionDepense,
    isLoadingActionDepense: state.DepenseReducer.isLoadingActionDepense,
    sandwich_menu_btn: state.AppReducer.sandwich_menu_btn,
    user: state.AppReducer.user,
    params: state.DepenseReducer.params,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDepenseById: (id) => dispatch(actions.getDepenseById(id)),
});
class FormDepense extends Component {
  constructor(props) {
    super(props);
    props.getDepenseById(this.props.selected_id ? this.props.selected_id : null);
  }
  render() {
    const { depense,params, activeItemSideBarRDV, isLoadingDepenseById } = this.props;
    return (
      <SwipeableDrawer
        style={{ width: '50%' }}
        anchor="right"
        open={activeItemSideBarRDV}
        onClose={this.props.toggleDrawer('right', false)}
        onOpen={this.props.toggleDrawer('right', true)}
      >
        {isLoadingDepenseById ? (
          <CircularProgress
            disableShrink
            style={{ marginLeft: '50%', marginTop: '22%' }}
          />
        ) : (
          <div className="right_side_bar">
            <div className="right_side_bar_header">
              <span id="add_app_sidebar_title">
                {depense ? 'Modifier la depense' : 'Ajouter une nouvelle depense'}
              </span>
              <svg
                onClick={() => {
                  this.props.changeActiveItemSideBarRDV(activeItemSideBarRDV);
                  this.props.getDepenseById(null);
                }}
                style={{ cursor: 'pointer' }}
                id="close-right-sidebar"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="9.5"
                  fill="white"
                  stroke="#ECEEF5"
                />
                <path
                  d="M25.2383 15.8164L21.0547 20L25.2383 24.1836L24.1836 25.2383L20 21.0547L15.8164 25.2383L14.7617 24.1836L18.9453 20L14.7617 15.8164L15.8164 14.7617L20 18.9453L24.1836 14.7617L25.2383 15.8164Z"
                  fill="#8083A3"
                />
              </svg>
            </div>
            <div className="right_side_bar_body">
              <Form
                changeActiveItemSideBarRDV={this.props.changeActiveItemSideBarRDV}
                activeItemSideBarRDV={activeItemSideBarRDV}
                getDepenseById={this.props.getDepenseById}
                depense={depense}
                params={params}
              />
            </div>
          </div>
        )}
      </SwipeableDrawer>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormDepense);
