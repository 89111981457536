export const SelectorSandwichMenuBtn = () => {
  window.$('.menu_icon_container').click(function () {
    if (window.$('.sidenav').hasClass('activated')) {
      if (window.$(window).width() <= 1366) {
        window.$('.control_buttons_items').css('padding', '1.5vw 2vw');
        window.$('.control_buttons_items').css('font-size', '80%');
        window.$('.general_info_form_item').css('width', '30vw');
        window.$('.security_info_form_item').css('width', '30vw');
        window.$('.diploma_info_form_item').css('width', '19vw');
      }
      //CONTROL BUTTONS ITEMS ==> resize when the navbar closes
      if (window.$(window).width() >= 1400) {
        window.$('.control_buttons_items').css('padding', '1.5vw 2vw');
        window.$('.control_buttons_items').css('font-size', '80%');
        window.$('.general_info_form_item').css('width', '30vw');
        window.$('.security_info_form_item').css('width', '30vw');
        window.$('.diploma_info_form_item').css('width', '19vw');
      }
    } else {
      //CONTROL BUTTONS ITEMS ==> resize when the navbar opens
      if (window.$(window).width() <= 1366) {
        window.$('.control_buttons_items').css('padding', '1.5vw 1.85vw');
        window.$('.control_buttons_items').css('font-size', '70%');
        window.$('.general_info_form_item').css('width', '26vw');
        window.$('.security_info_form_item').css('width', '26vw');
        window.$('.diploma_info_form_item').css('width', '16vw');
      }
      //CONTROL BUTTONS ITEMS ==> resize when the navbar opens
      if (window.$(window).width() >= 1400) {
        window.$('.control_buttons_items').css('padding', '1.5vw 1.85vw');
        window.$('.control_buttons_items').css('font-size', '60%');
        window.$('.general_info_form_item').css('width', '27vw');
        window.$('.security_info_form_item').css('width', '27vw');
        window.$('.diploma_info_form_item').css('width', '17vw');
      }
    }
  });
};

export const SelectorWidthInput = () => {
  if (window.$('.sidenav').hasClass('activated')) {
    if (window.$(window).width() <= 1366) {
      window.$('.control_buttons_items').css('padding', '1.5vw 1.85vw');
      window.$('.control_buttons_items').css('font-size', '70%');
      window.$('.general_info_form_item').css('width', '26vw');
      window.$('.security_info_form_item').css('width', '26vw');
      window.$('.diploma_info_form_item').css('width', '16vw');
      window.$('.add_patient_form_item').css('width', '75%');
    }
    //CONTROL BUTTONS ITEMS ==> resize when the navbar opens
    if (window.$(window).width() >= 1400) {
      window.$('.control_buttons_items').css('padding', '1.5vw 1.85vw');
      window.$('.control_buttons_items').css('font-size', '60%');
      window.$('.general_info_form_item').css('width', '27vw');
      window.$('.security_info_form_item').css('width', '27vw');
      window.$('.diploma_info_form_item').css('width', '17vw');
      window.$('.add_patient_form_item').css('width', '75%');
    }
  } else {
    //CONTROL BUTTONS ITEMS ==> resize when the navbar opens
    if (window.$(window).width() <= 1366) {
      window.$('.control_buttons_items').css('padding', '1.5vw 2vw');
      window.$('.control_buttons_items').css('font-size', '80%');
      window.$('.general_info_form_item').css('width', '30vw');
      window.$('.security_info_form_item').css('width', '30vw');
      window.$('.diploma_info_form_item').css('width', '19vw');
      window.$('.add_patient_form_item').css('width', '100%');
    }
    //CONTROL BUTTONS ITEMS ==> resize when the navbar closes
    if (window.$(window).width() >= 1400) {
      window.$('.control_buttons_items').css('padding', '1.5vw 2vw');
      window.$('.control_buttons_items').css('font-size', '80%');
      window.$('.general_info_form_item').css('width', '30vw');
      window.$('.security_info_form_item').css('width', '30vw');
      window.$('.diploma_info_form_item').css('width', '19vw');
      window.$('.add_patient_form_item').css('width', '100%');
    }
  }
};
function init_gray_sidebar_icons() {
  let img_tags = Object.values(window.$('.sidenav_container_item img'));
  img_tags.shift();

  window
    .$('.sidenav_container_item #dashboard img')
    .attr('src', require('../assets/img/dashboard.png'));
  window
    .$('.sidenav_container_item #agenda img')
    .attr('src', require('../assets/img/agenda.png'));
  window
    .$('.sidenav_container_item #consultation img')
    .attr('src', require('../assets/img/consultation.png'));
  window
    .$('.sidenav_container_item #patient img')
    .attr('src', require('../assets/img/patient.png'));
  window
    .$('.sidenav_container_item #messenger img')
    .attr('src', require('../assets/img/messenger.png'));
  window
    .$('.sidenav_container_item #facture img')
    .attr('src', require('../assets/img/facture.png'));
  window
    .$('.sidenav_container_item #support img')
    .attr('src', require('../assets/img/support.png'));
}
export const SelectorSidenavContainerItem = () => {
  window.$('.sidenav_container_item').click(function () {
    if (window.$(this).hasClass('main_logo')) {
    } else {
      let target = window.$('.active_container');
      window.$(target).removeClass('active_container');
      let target_icon = window.$(target).children();
      window.$(target_icon).removeClass('active_icon');
      window.$(this).children().addClass('active_container');
      window.$(this).children().children().addClass('active_icon');
      if (window.$('.sidenav ').hasClass('activated')) {
        window.$('.cont').removeClass('open_navbar_bg_color');
        window.$(this).parent().addClass('open_navbar_bg_color');
      }
      let target_id = window.$(this).children().attr('id');
      let actual_target_icon = window.$(this).children().children();
      init_gray_sidebar_icons();
      if (target_id == 'dashboard') {
        window
          .$(actual_target_icon)
          .attr('src', require('../assets/img/dashboard_blue.png'));
      } else if (target_id == 'agenda') {
        window
          .$(actual_target_icon)
          .attr('src', require('../assets/img/agenda_blue.png'));
      } else if (target_id == 'consultation') {
        window
          .$(actual_target_icon)
          .attr('src', require('../assets/img/consultation_blue.png'));
      } else if (target_id == 'patient') {
        window
          .$(actual_target_icon)
          .attr('src', require('../assets/img/patient_blue.png'));
      } else if (target_id == 'messenger') {
        window
          .$(actual_target_icon)
          .attr('src', require('../assets/img/messenger_blue.png'));
      } else if (target_id == 'facture') {
        window
          .$(actual_target_icon)
          .attr('src', require('../assets/img/facture_blue.png'));
      } else {
        window
          .$(actual_target_icon)
          .attr('src', require('../assets/img/support_blue.png'));
      }
    }
  });
};
export const SelectorInputAndIcon = () => {
  window.$('.input_and_icon input').focus(function () {
    window.$(this).siblings().css('color', '#1D1D24');
  });
  window.$('.input_and_icon input').focusout(function () {
    const text_check = window.$(this).val();
    if (text_check === '') {
      window.$(this).siblings().css('color', '#8083A3');
    }
  });
};
export const SelectorSearchbarIcon = () => {
  window.$('#searchbar-icon').click(function () {
    window.$('#searchbar-input').animate({ width: 'toggle' });
    window.$('#searchbar-icon').toggle();
    window.$('#searchbar-cross').toggle(500);
    window.$('.icon_search').css('border', 'none');
    //$(".icon_search").css("padding","0")
    window.$('.searchbar').css('background', '#F3F4F9');
  });
};
export const SelectorSearchbarCross = () => {
  window.$('#searchbar-cross').click(function () {
    window.$('#searchbar-input').animate({ width: 'toggle' });
    window.$('#searchbar-cross').toggle();
    window.$('#searchbar-icon').toggle(500);
    window.$('.searchbar').css('background', 'transparent');
  });
};
export const SelectorSwitchInput = () => {
  window.$('.switch_input').on('change', function () {
    let ok = window.$(this).attr('id');
    ok = '.' + ok;
    let traget_span = window.$(ok).children()[0];
    //$(traget_span).css("color", "#1A1C1D")
    if (window.$(traget_span).hasClass('active_toggle_btn')) {
      window.$(traget_span).removeClass('active_toggle_btn');
    } else {
      window.$(traget_span).addClass('active_toggle_btn');
    }
  });
};
export const SelectorItemContainer = () => {
  window.$('.item_container').click(function () {
    window.$('.item_container').removeClass('active_item_container');
    window.$(this).addClass('active_item_container');
  });
};
export const SelectorColorInputCheckbox = () => {
  window.$('input[type=checkbox]').change(function () {
    if (window.$(this).prop('checked')) {
      window.$(this).siblings().css('color', '#1A1C1D');
    } else {
      window.$(this).siblings().css('color', '#8181A5');
    }
  });
};
export const SelectorBilanItemSection = () => {
  window.$('.bilan_details_item').click(function () {
    window.$('.bilan_details_item').removeClass('active_details_nav');
    window.$(this).addClass('active_details_nav');
  });
};
export const SelectorSubSectionBtn = () => {
  window.$('.sub_section_btn').click(function () {
    let sub_section_target = window.$(this).siblings().attr('id');

    if (window.$('#' + sub_section_target).is(':visible')) {
      window.$('#' + sub_section_target).slideUp(200);
    } else {
      window.$('#' + sub_section_target).slideDown(200);
    }
  });
};
export const SelectorContactPatientsBodyItem = () => {
  window.$('.contact_patients_body_item ').click(function () {
    window.$('.contact_patients_body_item').removeClass('selected_patient');
    window.$(this).addClass('selected_patient');
  });
};
export const SelectorContactFacturesBodyItem = () => {
  window.$('.facture_patient_body_item ').click(function () {
    window.$('.facture_patient_body_item').removeClass('selected_patient');
    window.$(this).addClass('selected_patient');
  });
};
export const SelectorContactConsultationBodyItem = () => {
  window.$('.consultation_schedule_item ').click(function () {
    window.$('.consultation_schedule_item').removeClass('selected_patient');
    window.$(this).addClass('selected_patient');
  });
};
export const SelectorTopSectionItem = () => {
  window.$('.top_section_item ').click(function () {
    let target_section = window.$(this).attr('id');
    if (target_section === 'top_section_indisponibilite_item') {
      window.$('#top_section_rendez_vous_item').removeClass('activated_item');
      window
        .$('#top_section_rendez_vous_item img')
        .attr('src', require('../assets/img/agenda.png'));
      window.$('#top_section_indisponibilite_item').addClass('activated_item');
      window
        .$('#top_section_indisponibilite_item img')
        .attr('src', require('../assets/img/indisponibilite_blue.png'));
      window.$('#appointment_details_indispo').text('Pas de disponibilité !');
      window
        .$('#appointment_details_sub')
        .text(
          'Essayez de créer de nouveaux disponibilité ou synchroniser votre service de calendrier',
        );
      window.$('.appointment_details button').text('Ajouter une indisponibilité');
      window.$('.appointment_details button').attr('id', 'add_rv');
    } else {
      window.$('#top_section_indisponibilite_item').removeClass('activated_item');
      window
        .$('#top_section_indisponibilite_item img')
        .attr('src', require('../assets/img/indisponibilite.png'));
      window.$('#top_section_rendez_vous_item').addClass('activated_item');
      window
        .$('#top_section_rendez_vous_item img')
        .attr('src', require('../assets/img/agenda_blue.png'));
      window.$('#appointment_details_indispo').text('Pas de rendez-vous !');
      window
        .$('#appointment_details_sub')
        .text(
          'Essayez de créer de nouveaux rendez-vous ou synchroniser votre service de calendrier',
        );
      window.$('.appointment_details button').text('Ajouter un rendez-vous');
      window.$('.appointment_details button').attr('id', 'add_dis');
    }
  });
};
export const SelectorMessengerItem = () => {
  window.$(document).on("click", ".patients_messenger_contact_list_item", function () {
    window.$(".patients_messenger_contact_list_item").removeClass("messenger_selected_patient")
    window.$(this).addClass("messenger_selected_patient")
  })
};
export const SelectorControlButtonsItemsDashboard = () => {
  window.$(".control_buttons_items").mouseover(function () {
    let id = window.$(this).attr("id")
    if (id == "add_rd_btn") {
      window.$(this).children().attr("src", "actions_rapides/add_rd_blue.svg")
    } else if (id == "add_patient_btn") {
      window.$(this).children().attr("src", "actions_rapides/add_patient_blue.svg")
    } else if (id == "call_patient_btn") {
      window.$(this).children().attr("src", "actions_rapides/call_patient_blue.svg")
    } else {
      window.$(this).children().attr("src", "actions_rapides/facture_blue.svg")
    }
  });
  window.$(".control_buttons_items").mouseout(function () {
    let id = window.$(this).attr("id")
    if (id == "add_rd_btn") {
      window.$(this).children().attr("src", "actions_rapides/add_rd.svg")
    } else if (id == "add_patient_btn") {
      window.$(this).children().attr("src", "actions_rapides/add_patient.svg")
    } else if (id == "call_patient_btn") {
      window.$(this).children().attr("src", "actions_rapides/call_patient.svg")
    } else {
      window.$(this).children().attr("src", "actions_rapides/facture.svg")
    }
  });
  window.$(".control_buttons_items").click(function () {
    window.$(".control_buttons_items").removeClass("selected_action_rapides_btn")
    window.$(this).addClass("selected_action_rapides_btn")
  })
};
