const initialUserState = {
  isLoadingAddReceipt: false,
  isLoadingLastReceipt: false,
  lastReceipt: null,
  updateReceipt: false,
  receipts: [],
  receipt: null,
  isLoadingReceipts: false,
  sendReceiptByEmail: true,
  isLoadingSendReceiptByEmail: true,
  isLoadingReceiptsByIds: true,
  receiptsByIds: [],
  isLoadingReceipt: true,
  params: {
    limit: 6,
    currentPage: 1,
    start_date: '',
    end_date: '',
    search: '',
    status: '',
    type: '',
  },
};

const ReceiptReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case 'GET_RECEIPTS_BY_IDS_REQUESTING': {
      return {
        ...state,
        isLoadingReceiptsByIds: true,
      };
    }
    case 'GET_RECEIPTS_BY_IDS_SUCCESS': {
      return {
        ...state,
        isLoadingReceiptsByIds: false,
        receiptsByIds: action.data,
      };
    }
    case 'GET_RECEIPTS_BY_IDS_FAILURE': {
      return {
        ...state,
        isLoadingReceiptsByIds: false,
        receiptsByIds: [],
      };
    }

    case 'GET_RECEIPT_REQUESTING': {
      return {
        ...state,
        isLoadingReceipt: true,
        updateReceipt: false,
      };
    }
    case 'GET_RECEIPT_SUCCESS': {
      return {
        ...state,
        isLoadingReceipt: false,
        updateReceipt: false,
        receipt: action.data,
      };
    }
    case 'GET_RECEIPT_FAILURE': {
      return {
        ...state,
        isLoadingReceipt: false,
        updateReceipt: false,
        receipt: null,
      };
    }

    case 'GET_RECEIPTS_REQUESTING': {
      return {
        ...state,
        isLoadingReceipts: true,
        updateReceipt: false,
      };
    }
    case 'GET_RECEIPTS_SUCCESS': {
      return {
        ...state,
        isLoadingReceipts: false,
        receipts: action.data,
      };
    }
    case 'GET_RECEIPTS_FAILURE': {
      return {
        ...state,
        isLoadingReceipts: false,
        receipts: [],
      };
    }

    case 'GET_LAST_RECEIPT_REQUESTING': {
      return {
        ...state,
        isLoadingLastReceipt: true,
        lastReceipt: null,
      };
    }
    case 'GET_LAST_RECEIPT_SUCCESS': {
      return {
        ...state,
        isLoadingLastReceipt: false,
        lastReceipt: action.data,
      };
    }
    case 'GET_LAST_RECEIPT_FAILURE': {
      return {
        ...state,
        isLoadingLastReceipt: false,
        lastReceipt: null,
      };
    }

    case 'UPDATE_RECEIPT_REQUESTING': {
      return {
        ...state,
        isLoadingAddReceipt: true,
        updateReceipt: false,
      };
    }
    case 'UPDATE_RECEIPT_SUCCESS': {
      return {
        ...state,
        isLoadingAddReceipt: false,
        updateReceipt: true,
      };
    }
    case 'UPDATE_RECEIPT_FAILURE': {
      return {
        ...state,
        isLoadingAddReceipt: false,
        updateReceipt: false,
      };
    }

    case 'UPDATE_SEARCH_RECEIPT_PARAMS': {
      return {
        ...state,
        params: action.params,
      };
    }
    default: {
      return state;
    }
  }
};

export default ReceiptReducer;
