import * as React from 'react';
import { TableHeader } from './TableHeader';
import { TableBody } from './TableBody';
import { View } from '@react-pdf/renderer';

export class Table extends React.PureComponent {
  render() {
    let tableHeader = null;
    let tableBody = null;

    React.Children.forEach(this.props.children, (c) => {
      if (c.type === TableHeader) {
        tableHeader = c;
      } else if (c.type === TableBody) {
        tableBody = React.cloneElement(c, {
          data: c.props.data ?? this.props.data ?? [],
        });
      }
    });

    return (
      <View
        style={{
          //width: '90%',
          marginLeft: 10,
          padding: 10,
        }}
      >
        {tableHeader}
        {tableBody}
      </View>
    );
  }
}
