import React, { Component } from 'react';
import { isCodeCNAM, isNumeric, lengthPhone, validate } from '../utils/helpres';
import moment from 'moment';
const iconError = (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.71094 0.289062C10.0022 0.580307 10.0022 1.05251 9.71094 1.34375L6.05469 5L9.71094 8.65625C10.0022 8.94749 10.0022 9.41969 9.71094 9.71094C9.41969 10.0022 8.94749 10.0022 8.65625 9.71094L5 6.05469L1.34375 9.71094C1.05251 10.0022 0.580307 10.0022 0.289062 9.71094C-0.0021815 9.41969 -0.00218129 8.94749 0.289063 8.65625L3.94531 5L0.289063 1.34375C-0.00218055 1.05251 -0.0021815 0.580307 0.289062 0.289062C0.580307 -0.0021815 1.05251 -0.00218129 1.34375 0.289063L5 3.94531L8.65625 0.289063C8.94749 -0.00218055 9.41969 -0.0021815 9.71094 0.289062Z"
      fill="#F6583E"
    />
  </svg>
);
class InputField extends Component {
  render() {
    const {
      hasError,
      value,
      onChange,
      required,
      icon,
      validInput,
      type,
      name,
      placeholder,
      label,
      errorDate,
      className,
      width,
      onKeyPress,
      disabled,
      maxLength,
      minLength,
    } = this.props;
    return (
      <div
        style={{ width: width }}
        className={
          (hasError && !value) ||
          (hasError &&
            name !== 'num_order' &&
            !validate(value) &&
            validInput === 'string') ||
          //(hasError && name === 'num_order' && validInput === 'string') ||
          // (hasError && validInput === 'TRN') ||
          (value && !isNumeric(value) && validInput === 'integer') ||
          (value && !isNumeric(value) && validInput === 'integerCnamKey' && hasError) ||
          (value && !isNumeric(value) && validInput === 'integerYear') ||
          /*(value &&
            isNumeric(value) &&
            validInput === 'integerCnamKey' &&
            value.toString().length !== 2 &&
            hasError) ||*/
          (value && !isNumeric(value) && validInput === 'integerCnamRoot') /*&&
            value.toString().length !== 10*/ ||
          (value && isNumeric(value) && validInput === 'integerNbrS' && value > 6) ||
          (value &&
            isNumeric(value) &&
            validInput === 'integerDuration' &&
            value > 300) ||
          (value && !isNumeric(value) && validInput === 'integerNbrS') ||
          (value && !isNumeric(value) && validInput === 'integerDuration') ||
          (value &&
            isNumeric(value) &&
            validInput === 'integerYear' &&
            value.toString().length !== 4) ||
          (value &&
            isNumeric(value) &&
            validInput === 'integerYear' &&
            value > moment().format('YYYY')) ||
          /*(value &&
            //isNumeric(value) &&
            validInput === 'integerNumOrder' &&
            value.toString().length <= 4) ||*/
          (value && isNumeric(value) && validInput === 'integerSessionC' && value > 50) ||
          // (value && isNumeric(value) && validInput === 'integerNumOrder') ||
          (value && !isNumeric(value) && validInput === 'integerSessionC') ||
          (value && required && required.type) ||
          (value && !lengthPhone(value) && validInput === 'tel') ||
          (value && !isNumeric(value) && validInput === 'tel') ||
          //(validInput === 'integerCnamKey' && value && !isCodeCNAM(value) && hasError) ||
          errorDate /*||
          (hasError && !validate(value) && validInput === 'string')*/
            ? `${className} type3`
            : className
        }
      >
        <label>{label}</label>
        <div className="input_and_icon">
          <input
            maxLength={maxLength}
            minLength={minLength}
            disabled={disabled}
            style={{ width: '100%' }}
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onKeyPress={onKeyPress}
            name={name}
          />
          {(hasError && !value) ||
          //(validInput === 'integerCnamKey' && value && !isCodeCNAM(value) && hasError) ||
          (value && !lengthPhone(value) && validInput === 'tel') ||
          (value && !isNumeric(value) && validInput === 'tel') ||
          (value && !isNumeric(value) && validInput === 'integer') ||
          (value && !isNumeric(value) && validInput === 'integerSessionC') ||
          (hasError &&
            name !== 'num_order' &&
            !validate(value) &&
            validInput === 'string') ||
          //(hasError && name === 'num_order' && validInput === 'string') ||
          //(hasError && validInput === 'TRN') ||
          (value && required && required.type) ||
          errorDate/* ||
          (hasError && !validate(value) && validInput === 'string')*/
            ? iconError
            : icon}
        </div>
        {validInput === 'integerNbrS' && value && value > 6 ? (
          <span style={{ fontSize: '13px', color: '#FF5B35' }}>6 séances maximum</span>
        ) : (
          ''
        )}
        {validInput === 'integerDuration' && value && value > 300 ? (
          <span style={{ fontSize: '13px', color: '#FF5B35' }}>300 minutes maximum</span>
        ) : (
          ''
        )}
        {/* {validInput === 'integerCnamKey' && value && !isCodeCNAM(value) ? (
          <span style={{ fontSize: '13px', color: '#FF5B35' }}>
            code cnam n'est pas valide
          </span>
        ) : (
          ''
        )}*/}
        {validInput === 'integerSessionC' && value && value > 50 ? (
          <span style={{ fontSize: '13px', color: '#FF5B35' }}>50 séances maximum</span>
        ) : (
          ''
        )}
      </div>
    );
  }
}
export default InputField;
