import React, { Component } from 'react';
import moment from 'moment';
import Empty_container from '../../../components/empty_container';
import Modals from '../../../components/modal';
import { isHasPermission, isNumeric, validate } from '../../../utils/helpres';
import SimpleReactValidator from 'simple-react-validator';
import actions from '../store/actions';
import actionsPatient from '../../patient/store/actions';
import actionsJs from '../../../js/actions';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import Pec from '../composent/pec';
import PrescriptionMedicale from '../composent/prescription-medicale';
import Session from '../composent/session';
import Document from '../composent/document';
import Bilan from '../composent/bilan';
import Bill from '../composent/bill';
import Receipt from '../composent/receipt';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Protocol from '../protocol';
import SwipeableDrawerCreate from './drawer-new-cure';
import actionsAgenda from '../../agenda/store/actions';
import Tooltip from '@mui/material/Tooltip';

const mapStateToProps = (state) => ({
  loadingGetDispoDoctor: state.ConsultationReducer.loadingGetDispoDoctor,
  user: state.AppReducer.user,
  getDispoDate: state.ConsultationReducer.getDispoDate,
  getDispoDatePEC: state.ConsultationReducer.getDispoDate,
  sessions: state.ConsultationReducer.sessions,
  updateConsultation: state.ConsultationReducer.updateConsultation,
  loadingSession: state.ConsultationReducer.loadingSession,
  paramsBilan: state.ConsultationReducer.paramsBilan,
  isLoadingBilanConsultation: state.ConsultationReducer.isLoadingBilanConsultation,
  bilanConsultation: state.ConsultationReducer.bilanConsultation,
  error: state.ConsultationReducer.error,
  loadingAddFile: state.ConsultationReducer.loadingAddFile,
  addFiles: state.ConsultationReducer.addFiles,
  isLoadingProtocoleConsultation:
    state.ConsultationReducer.isLoadingProtocoleConsultation,
  protocoleConsultation: state.ConsultationReducer.protocoleConsultation,
  paramsProtocole: state.ConsultationReducer.paramsProtocole,
  office: state.AppReducer.office,
  billConsultation: state.ConsultationReducer.billConsultation,
  isLoadingBillConsultation: state.ConsultationReducer.isLoadingBillConsultation,
  isLoadingReceiptConsultation: state.ConsultationReducer.isLoadingReceiptConsultation,
  receiptConsultation: state.ConsultationReducer.receiptConsultation,
  closeSession: state.ConsultationReducer.closeSession,
  motifList: state.ConsultationReducer.motifList,
  allSessionsPatient: state.ConsultationReducer.allSessionsPatient,
  isLoadingAllSessionsPatient: state.ConsultationReducer.isLoadingAllSessionsPatient,
  medecins: state.AppReducer.medecins,
  employeeManagement: state.AppReducer.employeeManagement,
  patientStatistical: state.PatientReducer.patientStatistical,
});

const mapDispatchToProps = (dispatch) => ({
  deleteRdv: async (consultationId, id) => {
    await dispatch(actionsAgenda.deleteRdv(id));
    dispatch(actions.getSessionByConsultation(consultationId));
  },
  getMedecins: () => dispatch(actionsJs.getMedecins()),
  displaylistMotifs: () => dispatch(actions.getMotifs()),
  getOfficeByIdDoctor: () => {
    dispatch(actionsJs.getOfficeByIdDoctor());
  },
  submitRDVForm: async (values, id) => {
    await dispatch(actionsAgenda.submitRDVForm(values));
    dispatch(actions.getSessionByConsultation(id));
  },
  getPatientStatistical: (id) => dispatch(actionsPatient.getPatientStatistical(id)),
  closeTheSessions: async (id) => {
    await dispatch(actions.closeTheSessions(id));
    dispatch(actions.getSessionByConsultation(id));
  },

  submitFormPEC: async (values, type) => {
    await dispatch(actions.submitFormPEC(values));
    if (!type) dispatch(actions.getConsultationById(values.consultation_id));
  },
  submitFormCURE: async (values, type) => {
    await dispatch(actions.submitFormCure(values));
    dispatch(actions.getConsultationById(values.consultation_id));
  },
  deletePEC: async (id, consultation_id) => {
    await dispatch(actions.deletePEC(id));
    dispatch(actions.getConsultationById(consultation_id));
  },
  submitFormUpdateSession: async (values, id) => {
    await dispatch(actions.submitFormUpdateSession(values));
    dispatch(actions.getSessionByConsultation(id));
  },
  uploadFIleConsultation: async (values) => {
    await dispatch(actions.uploadFIleConsultation(values));
    dispatch(actions.getConsultationById(values.id));
  },
  deleteFile: async (fileId, consultationId) => {
    await dispatch(actions.deleteFile(fileId));
    dispatch(actions.getConsultationById(consultationId));
  },
  changeSearchedStringBilan: async (params, id) => {
    await dispatch(actions.changeSearchedStringBilan(params));
    dispatch(actions.getBilanByConsultationId(params, id));
  },
  changeSearchedStringBill: async (params, id) => {
    await dispatch(actions.changeSearchedStringBill(params));
    dispatch(actions.getBillByConsultationId(params, id));
  },
  changeSearchedStringReceipt: async (params, id) => {
    await dispatch(actions.changeSearchedStringReceipt(params));
    dispatch(actions.getReceiptByConsultationId(params, id));
  },
  changeSearchedStringProtocole: async (params, id) => {
    await dispatch(actions.changeSearchedStringProtocole(params));
    dispatch(actions.getProtocoleByConsultationId(params, id));
  },
  getSessionByConsultation: (id) => dispatch(actions.getSessionByConsultation(id)),
  createConsultation: async (values, id) => {
    await dispatch(actions.addConsultation(values));
    dispatch(actions.getConsultationById(id));
  },
  getDispoDoctor: async (values, id) => {
    await dispatch(actions.getDispoDoctor(values, id));
  },
  getPatientSessions: async (id) => {
    await dispatch(actions.getPatientSessions(id));
  },
  getEmployeeManagement: (type) => dispatch(actionsJs.getEmployeeManagement(type)),
});

class Consultation extends Component {
  constructor(props) {
    super(props);
    props.getOfficeByIdDoctor();
    props.displaylistMotifs();
    props.getMedecins();
    props.getEmployeeManagement(true);
    this.state = {
      item: 0,
      switchItem: 0,
      setIsOpen: false,
      setIsOpenPec: false,
      setIsOpenPM: false,
      setIsOpenSeance: false,
      Section1: true,
      hasError: false,
      dateError: false,
      errorCountSeance: false,
      activeDrawer: false,
      left: false,
      anchor: 'right',
      consultationValue: {
        motif_id: null,
        date: null,
        note: '',
        doctor: '',
      },
      initialValues: {
        //consultation_id: props.consultation.id,
        filed_on: '',
        sessions_count: null,
        nextItem: 0,
        id: null,
        sessions_per_week: null,
        start_date: '',
        quality: '',
        num_pec: '',
        end_date: '',
        date: '',
        unit_price: 11.5,
        hours: '',
        disponibility: [],
        getDispo: [],
      },
    };
    this.openModal = this.openModal.bind(this);
    this.openModalPec = this.openModalPec.bind(this);
    this.openModalSeance = this.openModalSeance.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.getItemPec = this.getItemPec.bind(this);
    this.handleChangeInputItem = this.handleChangeInputItem.bind(this);
    this.openModalPM = this.openModalPM.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.submitFormConsultation = this.submitFormConsultation.bind(this);
    this.handleChangeInputCon = this.handleChangeInputCon.bind(this);
    this.handelSwitchItem = this.handelSwitchItem.bind(this);

    this.validator = new SimpleReactValidator({
      messages: {
        required: 'Champ requis.',
        integer: 'Champ ne doit contenir que des chiffres',
        string: 'Champ ne doit contenir que des lettres',
        // OR
        default: 'Cette information est requise.', // will override all messages
      },
    });
  }
  handleChangeInputCon(event) {
    const { consultationValue } = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (target.options && target.options[target.selectedIndex].dataset.label) {
      this.setState({
        consultationValue: {
          ...consultationValue,
          labelMotif: target.options[target.selectedIndex].dataset.label,
          [name]: value,
        },
      });
    } else
      this.setState({
        consultationValue: {
          ...consultationValue,
          [name]: value,
        },
      });
  }
  submitFormConsultation(initialValues) {
    if (
      !initialValues.date ||
      !initialValues.motif_id ||
      // (initialValues.note && !validate(initialValues.note)) ||
      (initialValues.doctor && !validate(initialValues.doctor)) ||
      (initialValues.motif_id && !isNumeric(initialValues.motif_id)) ||
      (initialValues.motif_id &&
        initialValues.labelMotif === 'Autre' &&
        !initialValues.diagnostic) //|| (initialValues.date && moment(initialValues.date).isBefore(moment()))
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else if (this.validator.allValid()) {
      this.props.createConsultation(initialValues, initialValues.id);
    } else {
      this.validator.showMessages();
      this.setState({
        ...initialValues,
        hasError: true,
      });
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }
  toggleDrawer = (anchor, open, id) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      left: open,
      anchor: anchor,
      hasError: false,
    });
  };
  openModal() {
    this.setState({
      setIsOpen: !this.state.setIsOpen,
    });
  }
  handelSwitchItem(switchItem) {
    this.setState({
      switchItem,
    });
  }

  openModalSeance() {
    this.setState({
      setIsOpenSeance: !this.state.setIsOpenSeance,
    });
  }

  openModalPec() {
    const { initialValues } = this.state;
    this.setState({
      setIsOpenPec: !this.state.setIsOpenPec,
    });
    this.setState({
      initialValues: {
        ...initialValues,
        sessions_count: null,
        nextItem: 0,
        id: null,
        sessions_per_week: null,
        start_date: '',
        end_date: '',
        unit_price: '',
      },
    });
  }
  openModalPM() {
    const { initialValues } = this.state;
    this.setState({
      setIsOpenPM: !this.state.setIsOpenPM,
    });
    this.setState({
      initialValues: {
        ...initialValues,
        sessions_count: null,
        nextItem: 0,
        id: null,
        sessions_per_week: null,
        start_date: '',
        end_date: '',
        unit_price: '',
      },
    });
  }

  handleChangeInput(event) {
    const { initialValues } = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      initialValues: {
        ...initialValues,
        [name]: value,
      },
    });
  }

  handleChangeInputItem(event, id, item) {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      const data = [...prevState.initialValues.getDispo];
      var commentIndex = data.findIndex(function (c) {
        return c.week === id;
      });
      const date =
        initialValues.getDispo[commentIndex].values[
          (initialValues[item] ? initialValues[item] + 1 : 1) %
            initialValues.getDispo[commentIndex].values.length
        ].date;
      const updatedComment = update(data[commentIndex], { date: { $set: date } });
      data[commentIndex] = updatedComment;

      this.setState({
        initialValues: {
          ...initialValues,
          [item]: initialValues[item] ? initialValues[item] + 1 : 1,
          getDispo: data,
        },
      });
    });
  }

  componentDidMount() {
    if (this.props.consultation && this.props.consultation.id) {
      this.props.getSessionByConsultation(this.props.consultation.id);
      this.props.changeSearchedStringProtocole(
        {
          ...this.props.paramsProtocole,
          limit: 10,
        },
        this.props.consultation.id,
      );
      this.props.changeSearchedStringBilan(
        {
          ...this.props.paramsBilan,
          limit: 10,
        },
        this.props.consultation.id,
      );
      this.props.changeSearchedStringBill(
        {
          ...this.props.paramsBilan,
          limit: 10,
        },
        this.props.consultation.id,
      );
      this.props.changeSearchedStringReceipt(
        {
          ...this.props.paramsBilan,
          limit: 10,
        },
        this.props.consultation.id,
      );
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { getDispoDate, consultation } = nextProps;
    const { initialValues } = this.state;
    if (getDispoDate && getDispoDate.length > 0) {
      this.setState({
        initialValues: {
          ...initialValues,
          getDispo: getDispoDate,
        },
      });
    }
    if (consultation) {
      this.setState({
        initialValues: {
          ...initialValues,
          consultation_id: consultation.id,
        },
      });
    }
  }

  getItemPec(id) {
    const { consultation } = this.props;
    const { prise_en_charges } = consultation;
    const pec = prise_en_charges.find((element) => element.id === parseInt(id));
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        id: pec.id,
        start_date: pec.start_date,
        end_date: pec.end_date,
        sessions_per_week: pec.sessions_per_week,
        sessions_count: pec.sessions_count,
        number: pec.number,
      },
    });
  }
  /*
  submitPEC(initialValues) {
    if (
      !initialValues.quality ||
      (initialValues.num_pec && !isNumeric(initialValues.num_pec))
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else if (this.validator.allValid()) {
      this.props.submitFormPEC(initialValues);
    } else {
      this.validator.showMessages();
      this.setState({
        ...initialValues,
        hasError: true,
      });
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }*/

  render() {
    const {
      params,
      consultation,
      addFiles,
      loadingSession,
      sessions,
      bilanConsultation,
      user,
      closeSession,
      protocoleConsultation,
      office,
      billConsultation,
      receiptConsultation,
      motifList,
      medecins,
      loadingGetDispoDoctor,
      getDispoDate,
      employeeManagement,
      admin,
      isLoadingAllSessionsPatient,
      allSessionsPatient,
      patientStatistical,
    } = this.props;
    const {
      setIsOpenPM,
      setIsOpenPec,
      setIsOpenSeance,
      activeDrawer,
      anchor,
      consultationValue,
      switchItem,
      setIsOpen,
    } = this.state;
    let i = 0;
    if (!consultation)
      return (
        <CircularProgress disableShrink style={{ marginLeft: '33%', marginTop: '20%' }} />
      );
    return (
      <div className="container_changeable_content">
        <Modals
          setIsOpen={setIsOpen}
          openModal={this.openModal}
          data={
            <>
              <div className="modal-header" style={{ marginTop: '-30px' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModal()}
                  style={{ marginTop: '1%' }}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '5px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Cure</h2>
                {consultation.prise_en_charges &&
                consultation.prise_en_charges.length > 0 &&
                consultation.prise_en_charges[0].code_bureau &&
                consultation.prise_en_charges[0].year &&
                consultation.prise_en_charges[0].num_order ? (
                  <span>
                    Cette cure contient une prise en charge. Voulez-vous quand-même la
                    supprimer ?
                  </span>
                ) : (
                  <span>Voulez-vous vraiment supprimer cette cure ?</span>
                )}
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => this.openModal()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                  style={{ backgroundColor: '#f6f6f6' }}
                >
                  Non
                </button>
                <button
                  onClick={() => {
                    this.props.deleteCure(params, consultation.id);
                    this.props.goBack();
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
        <SwipeableDrawerCreate
          handleCloseDrawer={() => this.setState({ activeDrawer: false })}
          onChangeDoctor={(option) =>
            this.setState({
              initialValues: {
                ...consultationValue,
                doctor: option != null ? option.title : '',
              },
            })
          }
          submitFormConsultation={this.submitFormConsultation}
          initialValues={consultationValue}
          motifList={motifList}
          medecins={medecins}
          handleChangeInput={this.handleChangeInputCon}
          toggleDrawer={this.toggleDrawer}
          anchor={anchor}
          activeDrawer={activeDrawer}
        />
        <div className="consultation_detailed_info_section">
          <div
            className="consultation_schedule_info_section"
            style={{ width: '100%', height: '100%', display: 'flex' }}
          >
            <div className="patient_detailed_info_section" style={{ flex: '50%' }}>
              <div
                style={{
                  marginLeft: '30px',
                }}
                className="patient_detailed_info_section_header"
              >
                <div className="patient_avatar">
                  <svg
                    width="33"
                    height="34"
                    viewBox="0 0 33 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.73958 23.2812C9.42708 21.7812 12.9583 21.0312 16.3333 21.0312C19.7083 21.0312 23.2083 21.7812 26.8333 23.2812C30.5208 24.7188 32.3646 26.625 32.3646 29V33.0312H0.302084V29C0.302084 26.625 2.11458 24.7188 5.73958 23.2812ZM21.9583 14.6562C20.3958 16.2188 18.5208 17 16.3333 17C14.1458 17 12.2708 16.2188 10.7083 14.6562C9.14583 13.0938 8.36458 11.2188 8.36458 9.03125C8.36458 6.84375 9.14583 4.96875 10.7083 3.40625C12.2708 1.78125 14.1458 0.96875 16.3333 0.96875C18.5208 0.96875 20.3958 1.78125 21.9583 3.40625C23.5208 4.96875 24.3021 6.84375 24.3021 9.03125C24.3021 11.2188 23.5208 13.0938 21.9583 14.6562Z"
                      fill="#8083A3"
                    />
                  </svg>
                </div>
                <div className="patient_info">
                  <div className="patient_general_info">
                    <span id="patient_general_info_name">
                      {consultation.patient_record && consultation.patient_record.patient
                        ? `${consultation.patient_record.patient.last_name.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase(),
                          )} ${consultation.patient_record.patient.first_name.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase(),
                          )}`
                        : '-'}
                    </span>
                    <span id="patient_general_info_city">
                      {consultation.patient_record &&
                      consultation.patient_record.patient &&
                      consultation.patient_record.patient.address_patient
                        ? consultation.patient_record.patient.address_patient.city
                        : '-'}
                    </span>
                  </div>
                  <div className="patient_general_info_btns">
                    <Link
                      to={{
                        pathname: '/patients',
                        state: {
                          patient_id:
                            consultation.patient_record &&
                            consultation.patient_record.patient
                              ? consultation.patient_record.patient.id
                              : '',
                          fromPatient: true,
                        },
                      }}
                    >
                      <div className="np_btn">
                        <svg
                          width="13"
                          height="16"
                          viewBox="0 0 13 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.23828 5.83691H11.3867L7.23828 1.72363V5.83691ZM9.48828 9.59863V8.08691H3.51172V9.59863H9.48828ZM9.48828 12.5869V11.0752H3.51172V12.5869H9.48828ZM8.01172 0.598633L12.5117 5.09863V14.0986C12.5117 14.4971 12.3594 14.8486 12.0547 15.1533C11.75 15.4346 11.3984 15.5752 11 15.5752H2C1.60156 15.5752 1.25 15.4346 0.945312 15.1533C0.640625 14.8486 0.488281 14.4971 0.488281 14.0986L0.523438 2.0752C0.523438 1.67676 0.664062 1.33691 0.945312 1.05566C1.25 0.750977 1.60156 0.598633 2 0.598633H8.01172Z"
                            fill="#FAFBFF"
                          />
                        </svg>

                        <span>Fiche patient</span>
                      </div>
                    </Link>
                    {isHasPermission(user.login_access.permissions, 'UPDATE_PATIENT') ? (
                      <div
                        style={{
                          cursor: 'pointer',
                        }}
                        className="more_icon"
                      >
                        <Tooltip title="Modifier cure">
                          <div
                            onClick={() =>
                              this.setState({
                                activeDrawer: true,
                                consultationValue: {
                                  ...consultationValue,
                                  id: consultation.id,
                                  motif_id: consultation.motif.id,
                                  date: moment(consultation.createdAt).format(
                                    'YYYY-MM-DD',
                                  ),
                                  note: consultation.note || '',
                                  doctor: consultation.doctor || '',
                                },
                              })
                            }
                            className="op_item_bilan op_view"
                            data-toggle="modal"
                            data-target="#view_details"
                          >
                            <i className="fas fa-edit" aria-hidden="true"></i>
                          </div>
                        </Tooltip>
                      </div>
                    ) : (
                      ''
                    )}
                    <Tooltip title="Supprimer cure">
                      <div
                        onClick={() => {
                          this.openModal();
                        }}
                        className="more_icon_container"
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <i
                          style={{
                            padding: '0',
                            border: '',
                            color: '',
                            borderRadius: '',
                            backgroundColor: 'white',
                          }}
                          className="fas fa-trash"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="patient_detailed_info_section_body">
                <hr
                  style={{
                    marginLeft: '150px',
                    marginRight: '22px',
                    marginBottom: '20px',
                    width: '70%',
                  }}
                />
                <div className="flex-container">
                  <div className="left-section" style={{ height: '100%', width: '100%' }}>
                    <div className="items_container" style={{ marginBottom: '10%' }}>
                      <div className="patient_detailed_info_section_body_item patient_info_email">
                        <div className="patient_detail_props">
                          <div
                            style={{ textAlign: 'left' }}
                            className="email_label_container"
                            id="patient_email"
                          >
                            <span id="patient_detail_props_label">Diagnostique</span>
                          </div>
                          <span id="patient_detail_props_text">
                            {consultation.motif && consultation.motif.label !== 'Autre'
                              ? consultation.motif.label
                              : consultation.diagnostic}
                          </span>
                        </div>
                      </div>
                      <div className="patient_detailed_info_section_body_item patient_info_email">
                        <div className="patient_detail_props">
                          <div
                            style={{ textAlign: 'left' }}
                            className="email_label_container"
                            id="patient_email"
                          >
                            <span id="patient_detail_props_label">Date</span>
                          </div>
                          <span id="patient_detail_props_text">
                            {consultation.createdAt ? (
                              moment(consultation.createdAt).format('DD/MM/YYYY')
                            ) : (
                              <strong style={{ color: '#8080803d' }}>
                                Date non renseignée
                              </strong>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="patient_detailed_info_section_body_item patient_info_birthday">
                        <div className="patient_detail_props">
                          <div className="patient_detail_props">
                            <div
                              className="birthday_label_container"
                              id="patient_birthday"
                              style={{ textAlign: 'left' }}
                            >
                              <span id="patient_detail_props_label">Créneau</span>
                            </div>

                            <span id="patient_detail_props_text">
                              {consultation.createdAt ? (
                                moment(consultation.createdAt).format('HH:mm')
                              ) : (
                                <strong style={{ color: '#8080803d' }}>
                                  Créneau non renseigné
                                </strong>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="patient_detailed_info_section_body_item patient_info_cnam">
                        <div className="patient_detail_props">
                          <div className="patient_detail_props">
                            <div
                              style={{ textAlign: 'left' }}
                              className="cnam_label_container"
                              id="patient_cnam"
                            >
                              <span id="patient_detail_props_label">Téléphone</span>
                            </div>

                            <span id="patient_detail_props_text">
                              {consultation.patient_record &&
                              consultation.patient_record.patient &&
                              consultation.patient_record.patient.phone ? (
                                consultation.patient_record.patient.phone
                              ) : (
                                <strong style={{ color: '#8080803d' }}>
                                  Téléphone non renseignée
                                </strong>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="patient_detailed_info_section_body_item patient_info_address">
                        <div className="patient_detail_props">
                          <div className="patient_detail_props">
                            <div
                              style={{ textAlign: 'left' }}
                              className="address_label_container"
                              id="patient_address"
                            >
                              <span id="patient_detail_props_label">Ville</span>
                            </div>

                            <span id="patient_detail_props_text">
                              {consultation.patient_record &&
                              consultation.patient_record.patient &&
                              consultation.patient_record.patient.address_patient &&
                              consultation.patient_record.patient.address_patient.city ? (
                                consultation.patient_record.patient.address_patient.city
                              ) : (
                                <strong style={{ color: 'rgba(101,101,101,0.24)' }}>
                                  Ville non renseignée
                                </strong>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="patient_detailed_info_section_body_item patient_info_address">
                        <div className="patient_detail_props">
                          <div className="patient_detail_props">
                            <div
                              style={{ textAlign: 'left' }}
                              className="address_label_container"
                              id="patient_address"
                            >
                              <span id="patient_detail_props_label">
                                Médecin traitant
                              </span>
                            </div>

                            <span id="patient_detail_props_text">
                              {consultation.doctor ? (
                                consultation.doctor
                              ) : (
                                <strong style={{ color: '#8080803d' }}>
                                  Champ non renseigné
                                </strong>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="patient_detailed_info_section_body_item patient_info_address">
                        <div className="patient_detail_props">
                          <div className="patient_detail_props">
                            <div
                              style={{ textAlign: 'left' }}
                              className="address_label_container"
                              id="patient_address"
                            >
                              <span id="patient_detail_props_label">Note</span>
                            </div>

                            <span id="patient_detail_props_text">
                              {consultation.note ? (
                                consultation.note
                              ) : (
                                <strong style={{ color: '#8080803d' }}>
                                  Champ non renseigné
                                </strong>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ flex: '50%', paddingTop: '1%', marginRight: '15px' }}>
              {isHasPermission(user.login_access.permissions, 'VIEW_DOCUMENTS') ? (
                <Document
                  consultationId={consultation.id}
                  addFiles={addFiles}
                  user={user}
                  patientName={
                    consultation.patient_record && consultation.patient_record.patient ? (
                      `${consultation.patient_record.patient.last_name} ${consultation.patient_record.patient.first_name}`
                    ) : (
                      <strong style={{ color: '#8080803d' }}>
                        Patient non renseignée
                      </strong>
                    )
                  }
                  files={consultation.files}
                  uploadFIleConsultation={this.props.uploadFIleConsultation}
                  deleteFile={this.props.deleteFile}
                />
              ) : (
                ''
              )}
              {consultation.prise_en_charges &&
              consultation.prise_en_charges.length > 0 ? (
                consultation.prise_en_charges.map((pec) => {
                  i++;
                  return (
                    <>
                      <Session
                        //key={consultation.prise_en_charges[0].id}
                        closeSession={closeSession}
                        employeeManagement={employeeManagement}
                        user={user}
                        office={office}
                        admin={admin}
                        isLoadingAllSessionsPatient={isLoadingAllSessionsPatient}
                        allSessionsPatient={allSessionsPatient}
                        sessions={sessions}
                        setIsOpenSeance={setIsOpenSeance}
                        loadingGetDispoDoctor={loadingGetDispoDoctor}
                        getDispoDate={getDispoDate}
                        consultationID={consultation.id}
                        consultation={consultation}
                        loadingSession={loadingSession}
                        submitRDVForm={this.props.submitRDVForm}
                        deleteRdv={this.props.deleteRdv}
                        gotToAgendaPrescription={this.props.gotToAgendaPrescription}
                        gotToAgendaManuel={this.props.gotToAgendaManuel}
                        getSessionByConsultation={this.props.getSessionByConsultation}
                        submitFormUpdateSession={this.props.submitFormUpdateSession}
                        getDispoDoctor={this.props.getDispoDoctor}
                        closeTheSessions={this.props.closeTheSessions}
                        getPatientSessions={this.props.getPatientSessions}
                        getPatientStatistical={this.props.getPatientStatistical}
                        onClickSession={this.onClickSession}
                        handleChangeSessions={this.handleChangeSessions}
                        patientStatistical={patientStatistical}
                        switchItem={switchItem}
                        openModalSeance={this.openModalSeance}
                        handelSwitchItem={this.handelSwitchItem}
                      />
                      <PrescriptionMedicale
                        openModal={this.openModalPM}
                        submitFormCURE={this.props.submitFormCURE}
                        pec={pec}
                        setIsOpenPM={setIsOpenPM}
                        sessions={sessions}
                        consultation={consultation}
                        doctor={consultation.doctor}
                        setIsOpenSeance={setIsOpenSeance}
                        sessions_per_week={5}
                        patientName={
                          consultation.patient_record &&
                          consultation.patient_record.patient
                            ? `${consultation.patient_record.patient.last_name} ${consultation.patient_record.patient.first_name}`
                            : '-'
                        }
                        city={
                          consultation.patient_record &&
                          consultation.patient_record.patient &&
                          consultation.patient_record.patient.address_patient
                            ? consultation.patient_record.patient.address_patient.city
                            : '-'
                        }
                        consultation_id={consultation.id}
                        cnam={
                          consultation.patient_record &&
                          consultation.patient_record.patient &&
                          consultation.patient_record.patient.cnam_root &&
                          consultation.patient_record.patient.cnam_key
                            ? `${consultation.patient_record.patient.cnam_root}/${consultation.patient_record.patient.cnam_key}`
                            : '-'
                        }
                      />
                      {pec.regime === 'autre' ? (
                        ''
                      ) : isHasPermission(user.login_access.permissions, 'PEC_MANAG') ? (
                        <Pec
                          getDispoDate={getDispoDate}
                          switchItem={switchItem}
                          openModal={this.openModalPec}
                          handelSwitchItem={this.handelSwitchItem}
                          goTo={this.props.goTo}
                          submitFormPEC={this.props.submitFormPEC}
                          getDispoDoctor={this.props.getDispoDoctor}
                          deletePEC={this.props.deletePEC}
                          user={user}
                          pec={pec}
                          setIsOpenPec={setIsOpenPec}
                          doctor={consultation.doctor}
                          consultation_id={consultation.id}
                          consultation={consultation}
                          office={office}
                          sessions={sessions}
                          setIsOpenSeance={setIsOpenSeance}
                          patientName={
                            consultation.patient_record &&
                            consultation.patient_record.patient
                              ? `${consultation.patient_record.patient.last_name} ${consultation.patient_record.patient.first_name}`
                              : '-'
                          }
                          num_cnam={
                            consultation.patient_record &&
                            consultation.patient_record.patient
                              ? `${consultation.patient_record.patient.cnam_root}/${consultation.patient_record.patient.cnam_key}`
                              : '-'
                          }
                          cit
                          y={
                            consultation.patient_record &&
                            consultation.patient_record.patient &&
                            consultation.patient_record.patient.address_patient
                              ? consultation.patient_record.patient.address_patient.city
                              : '-'
                          }
                        />
                      ) : (
                        ''
                      )}
                    </>
                  );
                })
              ) : consultation.patient_record /* &&
                consultation.patient_record.patient.cnam_key*/ ? (
                <Empty_container
                  user={user}
                  permission="GENERATE_SESSIONS_MEMORY"
                  //onClick={this.openModal}
                  title="Pas de Prescription médicale !"
                  body="Essayez de créer une nouvelle prescription médicale."
                  btn_name="Ajouter prescription médicale"
                  url={`/form/pec/${consultation.id}`}
                  type="pec"
                />
              ) : (
                ''
              )}

              <Bilan
                consultationID={consultation.id}
                patientName={
                  consultation.patient_record && consultation.patient_record.patient
                    ? `${consultation.patient_record.patient.last_name} ${consultation.patient_record.patient.first_name}`
                    : '-'
                }
                bilans={bilanConsultation}
                user={user}
              />
              {protocoleConsultation && protocoleConsultation.length > 0 ? (
                <Protocol
                  user={user}
                  protocoleConsultation={protocoleConsultation}
                  consultationID={consultation.id}
                />
              ) : (
                <Empty_container
                  user={user}
                  permission="CREATE_NEW_PROTOCOLE"
                  title="Pas de protocoles !"
                  body="Dès qu'une consultation est créée, vous pourrez y ajouter des protocoles."
                  btn_name="Ajouter un protocole"
                  url={`/form-plan-reeducation/${consultation.id}`}
                />
              )}
              <Bill
                prise_en_charges={consultation.prise_en_charges}
                pec={
                  consultation.prise_en_charges &&
                  consultation.prise_en_charges.length > 0 &&
                  consultation.prise_en_charges[0].status === 'billable' /*||
                  (consultation.prise_en_charges &&
                    consultation.prise_en_charges.length > 0 &&
                    moment(consultation.prise_en_charges[0].end_date).isBefore(moment()))*/
                    ? false
                    : true
                }
                consultationID={consultation.id}
                patientName={
                  consultation.patient_record && consultation.patient_record.patient
                    ? `${consultation.patient_record.patient.last_name} ${consultation.patient_record.patient.first_name}`
                    : '-'
                }
                bills={billConsultation}
                regime={
                  consultation.prise_en_charges &&
                  consultation.prise_en_charges.length > 0 &&
                  consultation.prise_en_charges[0].regime
                }
                user={user}
              />
              <Receipt
                pec={
                  consultation.prise_en_charges &&
                  consultation.prise_en_charges.length > 0
                }
                consultationID={consultation.id}
                patientName={
                  consultation.patient_record && consultation.patient_record.patient
                    ? `${consultation.patient_record.patient.last_name} ${consultation.patient_record.patient.first_name}`
                    : '-'
                }
                receipts={receiptConsultation}
                user={user}
              />
            </div>
          </div>
        </div>
        {/*<Modals
          setIsOpen={setIsOpen}
          openModal={this.openModal}
          data={
            <>
              <div
                className="modal-header"
                style={{
                  borderBottom: '1px solid #dee2e6',
                  padding: '1.5rem 0rem',
                  margin: '0px 20px',
                  alignItems: 'baseline',
                  marginLeft: '3%',
                  marginTop: '-30px',
                  width: '100%',
                }}
              >
                <span>Creation prescription médicale</span>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModal()}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '30px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <hr style={{ width: '95%' }} />
              <div className="modal-body">
                 <span>
                  Voulez-vous vraiment créer une prise en charge pour cette consultation ?
                </span>
                <form style={{ padding: '2px 0px' }}>
                  <div className="form-row">
                    <div
                      style={{ width: '100%' }}
                      className={
                        hasError && !initialValues.num_pec
                          ? 'form-group type3'
                          : 'form-group'
                      }
                    >
                      <label htmlFor="">Numéro PEC</label>
                      <div className="input_and_icon">
                        <input
                          value={initialValues.num_pec}
                          name="num_pec"
                          onChange={this.handleChangeInput}
                          type="text"
                          placeholder="N° PEC"
                        />
                        <i className="fas fa-pen"></i>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div
                      style={{ width: '100%' }}
                      className={
                        hasError && !initialValues.quality
                          ? 'right_sidebar_body_item type3'
                          : 'right_sidebar_body_item'
                      }
                    >
                      <label htmlFor="">Qualité</label>
                      <div className="input_and_icon">
                        <select
                          value={initialValues.quality}
                          name="quality"
                          onChange={this.handleChangeInput}
                          className="form-control"
                          id="exampleFormControlSelect1"
                        >
                          <option value="" selected>
                            Qualité
                          </option>
                          <option value="Assuré">Assuré</option>
                          <option value="Conjoint">Conjoint</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <hr style={{ width: '95%' }} />
              <div
                className="modal-footer create_prise_en_charge_1_footer"
                style={{
                  borderBottom: '1px solid #dee2e6',
                  padding: '1.5rem 0rem',
                  margin: '0px 20px',
                }}
              >
                <button
                  onClick={() => this.openModal()}
                  type="button"
                  className="modal_close_btn"
                  id="retour"
                  style={{ backgroundColor: '#f6f6f6' }}
                >
                  Non
                </button>
                <button
                  onClick={() =>
                    this.submitPEC({
                      consultation_id: consultation.id,
                      quality: initialValues.quality,
                      num_pec: initialValues.num_pec,
                    })
                  }
                  id="valider"
                  type="button"
                  className="btn_valid"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />*/}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Consultation);
