import { getAppErrorCode } from '../../../utils/helpres';
import moment from 'moment';

const initialAppState = {
  error: undefined,
  ErrorMessage: undefined,
  isLoadingGetAllUnavailability: false,
  isLoadingGetAllDayDispo: false,
  isLoadingGetMotifs: false,
  isLoadingGetAllRDV: false,
  isLoadingDeleteUnavailability: false,
  deleteUnavailability: false,
  isLoadingAddRdv: false,
  isLoadingAddDispo: false,
  isLoadingDeleteRDV: false,
  getAllUnavailability: [],
  getAllRDV: [],
  getAllDayDispo: [],
  getLastAvailabilityDate: null,
  isLoadingGetLastAvailability: false,
  canceledRdv: false,
  motifsAgenda: [],
  message: '',
  rdv: null,
  created: false,
  createdNewDispo: false,
  deleteRDV: false,
  params: {
    date: new Date(),
    date_to: null,
    status: [],
    type: 'all',
  },
  isLoadingActionPayment: false,
  paymentAction: null,
  isLoadingGetIsDispo: false,
  getIsDispo: null,
  isLoadingUserMotif: false,
  userMotifs: [],
  duplicateRdv: false,
  isLoadingDuplicateRdv: false,
  detailtPyamentRdv: null,
  isLoadingDetailtPyamentRdv: true,
  agendaManuel: false,
  isLoadingAgendaManuel: false,
};
const AgendaReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'UPDATE_AGENDA_MANUEL_REQUESTING': {
      return {
        ...state,
        isLoadingAgendaManuel: true,
      };
    }
    case 'UPDATE_AGENDA_MANUEL_SUCCESS': {
      return {
        ...state,
        isLoadingAgendaManuel: false,
        agendaManuel: true,
      };
    }
    case 'UPDATE_AGENDA_MANUEL_FAILURE': {
      return {
        ...state,
        isLoadingAgendaManuel: false,
        agendaManuel: false,
      };
    }

    case 'GET_PAYMENT_APPOINTMENT_REQUESTING': {
      return {
        ...state,
        isLoadingDetailtPyamentRdv: true,
      };
    }
    case 'GET_PAYMENT_APPOINTMENT_SUCCESS': {
      return {
        ...state,
        isLoadingDetailtPyamentRdv: false,
        detailtPyamentRdv: action.data.data,
      };
    }
    case 'GET_PAYMENT_APPOINTMENT_FAILURE': {
      return {
        ...state,
        isLoadingDetailtPyamentRdv: false,
        detailtPyamentRdv: null,
      };
    }

    case 'DUPLICATE_RDV_REQUESTING': {
      return {
        ...state,
        isLoadingDuplicateRdv: true,
      };
    }
    case 'DUPLICATE_RDV_SUCCESS': {
      return {
        ...state,
        isLoadingDuplicateRdv: false,
        duplicateRdv: true,
      };
    }
    case 'DUPLICATE_RDV_FAILURE': {
      return {
        ...state,
        isLoadingDuplicateRdv: false,
        duplicateRdv: false,
      };
    }

    case 'GET_USER_MOTIFS_REQUESTING': {
      return {
        ...state,
        isLoadingUserMotif: true,
      };
    }
    case 'GET_USER_MOTIFS_SUCCESS': {
      return {
        ...state,
        isLoadingUserMotif: false,
        userMotifs: action.data,
      };
    }
    case 'GET_USER_MOTIFS_FAILURE': {
      return {
        ...state,
        isLoadingUserMotif: false,
        userMotifs: [],
      };
    }

    case 'GET_GET_DOCTOR_IS_DISPO_REQUESTING': {
      return {
        ...state,
        isLoadingGetIsDispo: true,
      };
    }
    case 'GET_GET_DOCTOR_IS_DISPO_SUCCESS': {
      return {
        ...state,
        isLoadingGetIsDispo: false,
        getIsDispo: true,
      };
    }
    case 'GET_GET_DOCTOR_IS_DISPO_FAILURE': {
      return {
        ...state,
        isLoadingGetIsDispo: false,
        getIsDispo: false,
      };
    }

    case 'CREATE_PAYMENT_REQUESTING': {
      return {
        ...state,
        isLoadingActionPayment: true,
        paymentAction: false,
      };
    }
    case 'CREATE_PAYMENT_SUCCESS': {
      return {
        ...state,
        isLoadingActionPayment: false,
        paymentAction: true,
      };
    }
    case 'CREATE_PAYMENT_FAILURE': {
      return {
        ...state,
        isLoadingActionPayment: false,
        paymentAction: false,
      };
    }
    case 'CREATE_NEW_DISPO_SUCCESS': {
      return {
        ...state,
        isLoadingAddDispo: false,
        createdNewDispo: true,
      };
    }
    case 'CREATE_NEW_DISPO_FAILURE': {
      return {
        ...state,
        isLoadingAddDispo: false,
        createdNewDispo: false,
      };
    }
    case 'CREATE_NEW_CONSULTATION_REQUESTING': {
      return {
        ...state,
        isLoadingAddRdv: false,
        created: false,
      };
    }
    case 'CREATE_NEW_CONSULTATION_SUCCESS': {
      return {
        ...state,
        isLoadingAddRdv: false,
        created: true,
        rdv: {
          ...action.data,
        },
      };
    }
    case 'UPDATE_STATUS_RDV_SUCCESS': {
      return {
        ...state,
        isLoadingAddRdv: false,
        canceledRdv: true,
        rdv: {
          ...action.data,
        },
      };
    }
    case 'CREATE_NEW_CONSULTATION_FAILURE': {
      return {
        ...state,
        isLoadingAddRdv: false,
        created: false,
        rdv: {
          ...action.data,
        },
        message: action.err,
        // error: getAppErrorCode(action.status),
        ErrorMessage: action.ErrorMessage,
      };
    }
    case 'GET_LAST_AVAILABILITY_REQUESTING':
      return {
        ...state,
        isLoadingGetLastAvailability: true,
      };
    case 'GET_LAST_AVAILABILITY_SUCCESS': {
      return {
        ...state,
        isLoadingGetLastAvailability: false,
        getLastAvailabilityDate: action.data,
      };
    }
    case 'GET_LAST_AVAILABILITY_FAILURE': {
      return {
        ...state,
        isLoadingGetLastAvailability: false,
        error: getAppErrorCode(action.status),
        message: action.msg,
        getLastAvailabilityDate: null,
      };
    }

    case 'GET_DELETE_RDV_REQUESTING':
      return {
        ...state,
        isLoadingDeleteRDV: true,
      };
    case 'GET_DELETE_RDV_SUCCESS': {
      return {
        ...state,
        isLoadingDeleteRDV: false,
        deleteRDV: action.data,
      };
    }
    case 'GET_DELETE_RDV_FAILURE': {
      return {
        ...state,
        isLoadingDeleteRDV: false,
        error: getAppErrorCode(action.status),
        message: action.msg,
        deleteRDV: false,
      };
    }

    case 'GET_DISPONIBILITY_DOCTOR_BY_MOTIF_REQUESTING':
      return {
        ...state,
        isLoadingGetAllDayDispo: true,
        createdNewDispo: false,
      };
    case 'GET_DISPONIBILITY_DOCTOR_BY_MOTIF_SUCCESS': {
      return {
        ...state,
        isLoadingGetAllDayDispo: false,
        createdNewDispo: false,
        getAllDayDispo: action.data,
      };
    }
    case 'GET_DISPONIBILITY_DOCTOR_BY_MOTIF_FAILURE': {
      return {
        ...state,
        isLoadingGetAllDayDispo: false,
        error: getAppErrorCode(action.status),
        message: action.msg,
        getAllDayDispo: [],
      };
    }
    case 'GET_MOTIFS_AGENDA_REQUESTING':
      return {
        ...state,
        isLoadingGetMotifs: true,
      };
    case 'GET_MOTIFS_AGENDA_SUCCESS': {
      return {
        ...state,
        isLoadingGetMotifs: false,
        motifsAgenda: action.data.data,
      };
    }
    case 'GET_MOTIFS_AGENDA_FAILURE': {
      return {
        ...state,
        isLoadingGetMotifs: false,
        error: getAppErrorCode(action.status),
        message: action.msg,
        motifsAgenda: [],
      };
    }

    case 'GET_ALL_UNAVAILABILITY_DOCTOR_REQUESTING':
      return {
        ...state,
        isLoadingGetAllUnavailability: true,
        createdNewDispo: false,
        deleteUnavailability: false,
      };
    case 'GET_ALL_UNAVAILABILITY_DOCTOR_SUCCESS': {
      return {
        ...state,
        isLoadingGetAllUnavailability: false,
        createdNewDispo: false,
        deleteUnavailability: false,
        getAllUnavailability: action.data.data,
      };
    }
    case 'GET_ALL_UNAVAILABILITY_DOCTOR_FAILURE': {
      return {
        ...state,
        isLoadingGetAllUnavailability: false,
        error: getAppErrorCode(action.status),
        message: action.msg,
        createdNewDispo: false,
        deleteUnavailability: false,
        getAllUnavailability: [],
      };
    }

    case 'GET_DELETE_UNAVAILABILITY_REQUESTING':
      return {
        ...state,
        isLoadingDeleteUnavailability: true,
        createdNewDispo: false,
      };
    case 'GET_DELETE_UNAVAILABILITY_SUCCESS': {
      return {
        ...state,
        isLoadingDeleteUnavailability: false,
        createdNewDispo: false,
        deleteUnavailability: true,
      };
    }
    case 'GET_DELETE_UNAVAILABILITY_FAILURE': {
      return {
        ...state,
        isLoadingDeleteUnavailability: false,
        error: getAppErrorCode(action.status),
        message: action.msg,
        createdNewDispo: false,
        deleteUnavailability: false,
      };
    }

    case 'GET_RDV_ALL_DOCTOR_REQUESTING':
      return {
        ...state,
        isLoadingGetAllRDV: true,
        getLastAvailabilityDate: null,
        created: false,
        paymentAction: false,
        canceledRdv: false,
        agendaManuel: false,
      };
    case 'GET_RDV_ALL_DOCTOR_SUCCESS': {
      return {
        ...state,
        isLoadingGetAllRDV: false,
        created: false,
        paymentAction: false,
        getLastAvailabilityDate: null,
        canceledRdv: false,
        agendaManuel: false,
        getAllRDV: action.data.data,
      };
    }
    case 'GET_RDV_ALL_DOCTOR_FAILURE': {
      return {
        ...state,
        isLoadingGetAllRDV: false,
        created: false,
        paymentAction: false,
        error: getAppErrorCode(action.status),
        message: action.msg,
        getAllRDV: [],
      };
    }
    case 'UPDATE_DATE_UNAVAILABILITY_PARAMS': {
      return {
        ...state,
        params: action.params,
      };
    }
    default: {
      return state;
    }
  }
};

export default AgendaReducer;
