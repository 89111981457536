import { apiCall } from '../../../js/actions';
import { toast } from 'react-toastify';
import ToastAction from '../../../components/ToastAction';
import React from 'react';
import { getStatusError } from '../../../utils/helpres';
import moment from 'moment-timezone';

const getReceiptById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_RECEIPT_REQUESTING' });
  try {
    let url = `/api/bill/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_RECEIPT_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_RECEIPT_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getAllReceipt = (params, tag) => async (dispatch) => {
  dispatch({ type: 'GET_RECEIPTS_REQUESTING' });
  try {
    const { start_date } = params || {
      start_date: '',
    };
    const { end_date } = params || {
      end_date: '',
    };
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    const { search } = params || {
      search: '',
    };
    const { status } = params || {
      status: '',
    };
    const { type } = params || {
      type: '',
    };
    let url = `/api/bills?tag=${tag}&type=${type}&limit=${limit}&current_page=${currentPage}`;
    if (search && search !== '') url += `&search=${search}`;
    if (start_date && start_date !== '') url += `&start_date=${start_date}`;
    if (end_date && end_date !== '')
      url += `&end_date=${moment(moment(end_date).format('YYYY-MM-DD 23:59:00'))
        .tz('UTC')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')}`;
    if (status && status !== '') url += `&status=${status}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_RECEIPTS_SUCCESS',
      data: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_RECEIPTS_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const submitReceiptForm = (values, tag) => async (dispatch) => {
  dispatch({ type: 'UPDATE_RECEIPT_REQUESTING' });
  const { date, number, patient_record_id, type, type_id, submitted_in, line_bill } =
    values;
  let request = {
    number,
    type_id,
    type,
    submitted_in,
    line_bill,
    patient_record_id,
    start_date: line_bill.start_date,
    end_date: line_bill.end_date,
    date,
    tag,
  };
  try {
    const { data } = await apiCall({
      method: 'post',
      url: `/api/bill`,
      data: request,
    });
    const incompletePatient = !!(
      data.type_id === '' ||
      data.type === '' ||
      data.submitted_in === ''
    );
    if (!data.error) {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={`${tag === 'bill' ? 'Facture' : 'Récu'} créée avec succès`}
          />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`Patient a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      if (incompletePatient)
        toast(
          ({ closeToast }) => (
            <ToastAction type="warning" message="Facture est incomplète !" />
          ),
          { hideProgressBar: true },
        );
      dispatch({
        type: 'UPDATE_RECEIPT_SUCCESS',
        data: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_RECEIPT_FAILURE',
        ErrorMessage: data.message,
        data: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={
            data
              ? data.message
              : `Échec de création ${tag === 'bill' ? 'de facture' : 'de récu'}`
          }
        />
      ),
      { hideProgressBar: true },
    );
    //  toast.error(`Patient non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'UPDATE_RECEIPT_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: values,
    });
  }
};

const submitUpdateReceiptForm = (values) => async (dispatch) => {
  dispatch({ type: 'SUBMIT_RECEIPT_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'patch',
      url: `/api/bill/${values.id}`,
      data: values,
    });
    if (!data.error) {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Facture modifiée avec succès" />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`Patient a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'SUBMIT_RECEIPT_SUCCESS',
        data: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'SUBMIT_RECEIPT_FAILURE',
        ErrorMessage: data.message,
        data: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={data ? data.message : `Échec de modification de facture`}
        />
      ),
      { hideProgressBar: true },
    );
    //  toast.error(`Patient non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'SUBMIT_RECEIPT_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: values,
    });
  }
};

const submitSendReceipt = (values) => async (dispatch) => {
  dispatch({ type: 'SUBMIT_SEND_RECEIPT_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'post',
      url: `/api/send/bill/${values.id}`,
      data: { email: values.email },
    });
    if (data) {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Bordereau CNAM créé avec succès" />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`Patient a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'SUBMIT_SEND_RECEIPT_SUCCESS',
        data: data,
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction type="error" message="Echec d'envoi du bordereau" />
        ),
        {
          hideProgressBar: true,
        },
      );
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'SUBMIT_SEND_RECEIPT_FAILURE',
        //ErrorMessage: data,
        data: null,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={data ? data.message : `Facture n'est pas envoyée `}
        />
      ),
      { hideProgressBar: true },
    );
    //  toast.error(`Patient non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'SUBMIT_SEND_RECEIPT_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: null,
    });
  }
};
const getReceiptByIds = (ids) => async (dispatch) => {
  dispatch({ type: 'GET_RECEIPTS_BY_IDS_REQUESTING' });
  try {
    let url = `/api/bills/practitioner/bordereau/ids`;
    const { data } = await apiCall({
      method: 'get',
      url,
      params: { ids },
    });
    dispatch({
      type: 'GET_RECEIPTS_BY_IDS_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_RECEIPTS_BY_IDS_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const getLastReceipt = (tag) => async (dispatch) => {
  dispatch({ type: 'GET_LAST_RECEIPT_REQUESTING' });
  try {
    let url = `/api/get/last/bill/${tag}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_LAST_RECEIPT_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_LAST_RECEIPT_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const changeSearchedStringReceipt = (params) => ({
  type: 'UPDATE_SEARCH_RECEIPT_PARAMS',
  params: params,
});

export default {
  changeSearchedStringReceipt,
  getAllReceipt,
  submitReceiptForm,
  getLastReceipt,
  getReceiptByIds,
  submitUpdateReceiptForm,
  submitSendReceipt,
  getReceiptById,
};
