import { apiCall } from '../../../js/actions';
import { toast } from 'react-toastify';
import ToastAction from '../../../components/ToastAction';
import React from 'react';
import { getStatusError } from '../../../utils/helpres';

const getAllBill = (params) => async (dispatch) => {
  dispatch({ type: 'GET_BILLS_REQUESTING' });
  try {
    const { start_date } = params || {
      start_date: '',
    };
    const { end_date } = params || {
      end_date: '',
    };
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    const { search } = params || {
      search: '',
    };
    const { status } = params || {
      status: '',
    };
    let url = `/api/bills?type=bordereau&limit=${limit}&current_page=${currentPage}`;
    if (search && search !== '') url += `&search=${search}`;
    if (start_date && start_date !== '') url += `&start_date=${start_date}`;
    if (end_date && end_date !== '') url += `&end_date=${end_date}`;
    if (status && status !== '') url += `&status=${status}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_BILLS_SUCCESS',
      data: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_BILLS_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getBordereauById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_BORDEREAU_REQUESTING' });
  try {
    let url = `/api/bordereau/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_BORDEREAU_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_BORDEREAU_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getAllBordereaux = (params, regime) => async (dispatch) => {
  dispatch({ type: 'GET_BORDEREAUX_REQUESTING' });
  try {
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { status } = params || {
      status: '',
    };
    const { limit } = params || {
      limit: '',
    };
    const { search } = params || {
      search: '',
    };

    let url = `/api/bordereaux?limit=${limit}&current_page=${currentPage}&regime=${regime}`;
    if (search && search !== '') url += `&search=${search}`;
    if (status && status !== '') url += `&status=${status}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_BORDEREAUX_SUCCESS',
      data: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_BORDEREAUX_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getBillByIds = (ids) => async (dispatch) => {
  dispatch({ type: 'GET_BILLS_BY_IDS_REQUESTING' });
  try {
    let url = `/api/bills/practitioner/bordereau/ids`;
    const { data } = await apiCall({
      method: 'get',
      url,
      params: { ids },
    });
    dispatch({
      type: 'GET_BILLS_BY_IDS_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_BILLS_BY_IDS_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getLastBordereau = () => async (dispatch) => {
  dispatch({ type: 'GET_LAST_BORDEREAU_REQUESTING' });
  try {
    let url = `/api/last/bordereau`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_LAST_BORDEREAU_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_LAST_BORDEREAU_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const submitBordereau = (values) => async (dispatch) => {
  dispatch({ type: 'SUBMIT_BORDEREAU_REQUESTING' });
  try {
    let url = values.id ? `/api/bordereau/${values.id}` : `/api/bordereau`;
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url,
      data: values,
    });
    toast(
      ({ closeToast }) => (
        <ToastAction type="success" message="Bordereau CNAM créé avec succès" />
      ),
      { hideProgressBar: true },
    );
    dispatch({
      type: 'SUBMIT_BORDEREAU_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'SUBMIT_BORDEREAU_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const changeSearchedStringBordereau = (params) => ({
  type: 'UPDATE_SEARCH_BORDEREAU_PARAMS',
  params: params,
});

const deleteBordereauBill = (values) => async (dispatch) => {
  const { bordereau_id, bill_id } = values;
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/bordereau/bill/${bordereau_id}/${bill_id}`,
    });
    if (!data.error) {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Facture supprimée avec succès" />
        ),
        {
          hideProgressBar: true,
        },
      );
      // toast.success(`Motif ${values.id ? 'modifié' : 'ajouté'} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_BORDEREAU_SUCCESS',
        data: data,
      });
    } else {
      toast(
        ({ closeToast }) => <ToastAction type="warning" message={data.data.message} />,
        { hideProgressBar: true },
      );
      //toast.warn(data.data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_BORDEREAU_FAILURE',
        ErrorMessage: data.message,
        data: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec de suppression de facture" />
      ),
      { hideProgressBar: true },
    );
    //toast.error(`Motif n'est pas ${values.id ? 'modifié' : 'ajouté'} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'ACTION_MOTIF_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: values,
    });
  }
};

const submitDeleteBordereau = (id) => async (dispatch) => {
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/bordereau/delete/${id}`,
    });
    if (!data.error) {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Bordereau supprimée avec succès" />
        ),
        {
          hideProgressBar: true,
        },
      );
      dispatch({
        type: 'ACTION_DELETE_BORDEREAU_SUCCESS',
        data: data,
      });
    } else {
      toast(
        ({ closeToast }) => <ToastAction type="warning" message={data.data.message} />,
        { hideProgressBar: true },
      );
      dispatch({
        type: 'ACTION_DELETE_BORDEREAU_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec de suppression de bordereau" />
      ),
      { hideProgressBar: true },
    );
    dispatch({
      type: 'ACTION_DELETE_BORDEREAU_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: false,
    });
  }
};




export default {
  changeSearchedStringBordereau,
  getAllBill,
  getBordereauById,
  getBillByIds,
  getLastBordereau,
  submitBordereau,
  getAllBordereaux,
  deleteBordereauBill,
  submitDeleteBordereau
};
