import React, { Component } from 'react';
import InputField from '../../../components/inputField';
import moment from 'moment';
import actionsJs from '../../../js/actions';
import actions from '../../profile/store/actions';
import { connect } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Select from 'react-select';
import { deformedData } from '../../../utils/helpres';

const mapStateToProps = (state, ownProps) => {
  const { mission } = ownProps;
  let libel;
  let date;
  let duration;
  let id;
  let motif;
  let practitioner_id;
  if (mission) {
    practitioner_id =
      mission && mission.practitioner_id ? JSON.parse(mission.practitioner_id) : null;
    id = mission && mission.id ? mission.id : null;
    libel = mission && mission.libel ? mission.libel : null;
    motif = mission && mission.motif ? mission.motif : '';
    duration = mission && mission.duration ? mission.duration : '';
    date = mission && mission.date ? moment(mission.date).format('YYYY-MM-DD') : '';
  }
  return {
    mission: state.AppReducer.mission,
    motifs: state.ProfileReducer.motifs,
    initialValues: {
      id,
      motif,
      practitioner_id,
      libel,
      date,
      duration,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMotifs: () => dispatch(actions.getMotifs()),
  submitMissionForm: async (values) => {
    await dispatch(actionsJs.submitMissionForm(values));
    dispatch(actionsJs.getMissions());
  },
});
const styles = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
};

const orderOptions = (values) => {
  return values.filter((v) => v.isFixed).concat(values.filter((v) => !v.isFixed));
};

class FormMission extends Component {
  constructor(props) {
    super(props);
    props.getMotifs();
    this.state = {
      hasError: false,
      setIsOpen: false,
      active: false,
      initialValues: {
        ...props.initialValues,
      },
    };
    this.openModal = this.openModal.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  openModal() {
    this.setState({
      setIsOpen: !this.state.setIsOpen,
    });
  }
  submitForm(initialValues, id) {
    if (
      !initialValues.libel ||
      !initialValues.date ||
      !initialValues.practitioner_id ||
      !initialValues.duration ||
      !initialValues.motif
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else this.props.submitMissionForm(initialValues, this.props.type);
  }
  onChangeSelect(value, { action, removedValue }, options) {
    const { initialValues } = this.state;
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        value = options.filter((v) => v.isFixed);
        break;
    }

    value = orderOptions(value || []);
    this.setState({
      initialValues: {
        ...initialValues,
        practitioner_id: value,
      },
    });
  }
  render() {
    const { sandwich_menu_btn, motifs, doctors } = this.props;
    const { initialValues, hasError } = this.state;
    let data = [];
    if (doctors) data = deformedData(doctors);
    return (
      <div className="" style={{ width: '100%' }}>
        <div className="container_changeable_content" style={{ height: '100%' }}>
          <div className="patient_detailed_info_section">
            <div className="general_info" style={{ width: '97%' }}>
              <form className="general_info_form">
                <div className="title_and_button">
                  <span>Informations</span>
                </div>
                <div className="general_info_actual_form">
                  <InputField
                    width={sandwich_menu_btn ? '26vw' : '30vw'}
                    className="general_info_form_item"
                    type="text"
                    placeholder="Libellé"
                    label="Libellé"
                    validInput=""
                    value={initialValues.libel}
                    maxLength={40}
                    hasError={hasError}
                    name="libel"
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          libel: e.target.value,
                        },
                      });
                    }}
                  />
                  <InputField
                    width={sandwich_menu_btn ? '26vw' : '30vw'}
                    type="date"
                    placeholder="Date "
                    className="general_info_form_item"
                    validInput=""
                    label="Date "
                    // value={user.first_name}
                    value={initialValues.date}
                    hasError={hasError}
                    name="date"
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          date: e.target.value,
                        },
                      });
                    }}
                  />
                  <InputField
                    width={sandwich_menu_btn ? '26vw' : '30vw'}
                    className="general_info_form_item"
                    type="text"
                    placeholder="Durée de la formation"
                    label="Durée "
                    validInput=""
                    hasError={hasError}
                    maxLength={20}
                    icon={<i className="fas fa-phone-alt"></i>}
                    value={initialValues.duration}
                    name="duration"
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          duration: e.target.value,
                        },
                      });
                    }}
                  />
                  <InputField
                    width={sandwich_menu_btn ? '26vw' : '30vw'}
                    className="general_info_form_item"
                    type="text"
                    placeholder="Libellé"
                    label="Motif"
                    validInput="string"
                    value={initialValues.motif}
                    hasError={hasError}
                    name="motif"
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          motif: e.target.value,
                        },
                      });
                    }}
                  />
                  <div style={{ width: '50%' }} className="add_patient_form_item">
                    <label> Assignée à</label>
                    <Select
                      value={initialValues.practitioner_id}
                      isMulti
                      name="practitioner_id"
                      placeholder="Choisissez une optien dans liste"
                      options={data}
                      styles={styles}
                      onChange={(value, { action, removedValue }) => {
                        this.onChangeSelect(value, { action, removedValue }, data);
                      }}
                      className="basic-multi-select"
                    />
                  </div>
                </div>
              </form>
              <div className="footer_buttons" style={{ paddingBottom: '4%' }}>
                <button onClick={this.props.goBack} className="footer_btn" id="cancel">
                  Annuler
                </button>
                <button
                  style={{
                    marginRight: sandwich_menu_btn ? '6%' : '6%',
                  }}
                  id="update"
                  className="footer_btn"
                  onClick={() => this.submitForm(initialValues)}
                  //disabled={isLoadingUpdateUser}
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormMission);
