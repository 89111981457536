import { getAppErrorCode } from '../../../utils/helpres';
const unitialUserState = {
  patientConsultationList: [],
  doctorConsultationList: [],
  motifList: [],
  getDispoDate: [],
  sessions: [],
  consultationAdded: {},
  consultation: {},
  loadingMotifs: false,
  loadingConsultationById: false,
  loadingSession: false,
  loadingAddSessions: false,
  addSessions: false,
  loadingGetDispoDoctor: false,
  updateConsultation: false,
  isLoadingUpdateConsultation: false,
  loadingConsultationPatientRecord: false,
  loadingConsultation: true,
  loadingAddPec: false,
  addPec: false,
  params: {
    search: '',
    currentPage: 1,
    limit: 10,
    startDate: '',
    endDate: '',
  },
  paramsBilan: {
    search: '',
    currentPage: 1,
    limit: 5,
  },
  loadingAddFile: false,
  addFiles: false,
  paramsProtocole: {
    search: '',
    currentPage: 1,
    limit: 5,
  },
  isLoadingProtocoleConsultation: false,
  protocoleConsultation: [],
  isLoadingAddProtocole: false,
  addProtocole: false,
  section_protocole: null,
  isLoadingBillConsultation: false,
  billConsultation: [],
  receiptConsultation: [],
  isLoadingReceiptConsultation: false,
  sessionsVirtualPec: [],
  isLoadingSessionVirtualPec: false,
  listPec: [],
  isLoadingListPec: false,
  paramsPec: {
    status: '',
    search: '',
    currentPage: 1,
    limit: 10,
    start_date: '',
    end_date: '',
  },
  closeSession: false,
  isLoadingCloseSession: false,
  allSessionsPatient: [],
  isLoadingAllSessionsPatient: false,
};

const consultationReducer = (state = unitialUserState, action) => {
  switch (action.type) {
    case 'GET_PATIENT_SESSIONS_REQUESTING': {
      return {
        ...state,
        isLoadingAllSessionsPatient: true,
      };
    }
    case 'GET_PATIENT_SESSIONS_SUCCESS': {
      return {
        ...state,
        isLoadingAllSessionsPatient: false,
        allSessionsPatient: action.data,
      };
    }
    case 'GET_PATIENT_SESSIONS_FAILURE': {
      return {
        ...state,
        isLoadingAllSessionsPatient: false,
        allSessionsPatient: [],
      };
    }

    case 'CLOSE_SESSION_REQUESTING': {
      return {
        ...state,
        isLoadingCloseSession: true,
      };
    }
    case 'CLOSE_SESSION_SUCCESS': {
      return {
        ...state,
        isLoadingCloseSession: false,
        closeSession: action.data,
      };
    }
    case 'CLOSE_SESSION_FAILURE': {
      return {
        ...state,
        isLoadingCloseSession: false,
        closeSession: false,
      };
    }

    case 'GET_ALL_PEC_REQUESTING': {
      return {
        ...state,
        isLoadingListPec: true,
      };
    }
    case 'GET_ALL_PEC_SUCCESS': {
      return {
        ...state,
        isLoadingListPec: false,
        listPec: action.data,
      };
    }
    case 'GET_ALL_PEC_FAILURE': {
      return {
        ...state,
        isLoadingListPec: false,
        listPec: action.data,
      };
    }
    case 'UPDATE_SEARCH_ALL_PEC_PARAMS': {
      return {
        ...state,
        paramsPec: action.paramsPec,
      };
    }

    case 'GET_VIRTUAL_PEC_REQUESTING': {
      return {
        ...state,
        isLoadingSessionVirtualPec: true,
      };
    }
    case 'GET_VIRTUAL_PEC_SUCCESS': {
      return {
        ...state,
        isLoadingSessionVirtualPec: false,
        sessionsVirtualPec: action.data,
      };
    }
    case 'GET_VIRTUAL_PEC_FAILURE': {
      return {
        ...state,
        isLoadingSessionVirtualPec: false,
        sessionsVirtualPec: action.data,
      };
    }

    case 'UPDATE_SEARCH_RECEIPT_CONSULTATION_PARAMS': {
      return {
        ...state,
        isLoadingReceiptConsultation: true,
      };
    }
    case 'GET_RECEIPT_BY_CONSULTATION_REQUESTING': {
      return {
        ...state,
        isLoadingReceiptConsultation: true,
      };
    }
    case 'GET_RECEIPT_BY_CONSULTATION_SUCCESS': {
      return {
        ...state,
        isLoadingReceiptConsultation: false,
        receiptConsultation: action.data.data,
      };
    }
    case 'GET_RECEIPT_BY_CONSULTATION_FAILURE': {
      return {
        ...state,
        isLoadingBillConsultation: false,
        receiptConsultation: [],
        //  error: getAppErrorCode(action.status),
      };
    }

    case 'GET_BILL_BY_CONSULTATION_REQUESTING': {
      return {
        ...state,
        isLoadingBillConsultation: true,
      };
    }
    case 'UPDATE_SEARCH_BILL_CONSULTATION_PARAMS': {
      return {
        ...state,
        isLoadingBillConsultation: true,
      };
    }
    case 'GET_BILL_BY_CONSULTATION_SUCCESS': {
      return {
        ...state,
        isLoadingBillConsultation: false,
        billConsultation: action.data.data,
      };
    }
    case 'GET_BILL_BY_CONSULTATION_FAILURE': {
      return {
        ...state,
        isLoadingBillConsultation: false,
        billConsultation: [],
        //  error: getAppErrorCode(action.status),
      };
    }

    case 'GET_SECTION_PROTOCOLE_REQUESTING': {
      return {
        ...state,
        loadingSectionBilan: true,
      };
    }

    case 'GET_SECTION_PROTOCOLE_LIST_SUCCESS': {
      return {
        ...state,
        loadingSectionBilan: false,
        section_protocole: action.data,
      };
    }

    case 'GET_SECTION_PROTOCOLE_LIST_FAILURE': {
      return {
        ...state,
        // section_bilan: {},
        loadingSectionBilan: false,
      };
    }
    case 'UPDATE_SEARCH_PROTOCOLE_CONSULTATION_PARAMS': {
      return {
        ...state,
        paramsProtocole: action.paramsProtocole,
      };
    }
    case 'SUBMIT_FORM_PROTOCOLE_REQUESTING': {
      return {
        ...state,
        isLoadingAddProtocole: true,
        addProtocole: false,
      };
    }
    case 'SUBMIT_FORM_PROTOCOLE_SUCCESS': {
      return {
        ...state,
        isLoadingAddProtocole: false,
        addProtocole: true,
      };
    }
    case 'SUBMIT_FORM_PROTOCOLE_FAILURE': {
      return {
        ...state,
        isLoadingAddProtocole: false,
        addProtocole: false,
      };
    }
    case 'GET_PROTOCOLE_BY_CONSULTATION_REQUESTING': {
      return {
        ...state,
        isLoadingProtocoleConsultation: true,
      };
    }
    case 'GET_PROTOCOLE_BY_CONSULTATION_SUCCESS': {
      return {
        ...state,
        isLoadingProtocoleConsultation: false,
        protocoleConsultation: action.data.data,
      };
    }
    case 'GET_PROTOCOLE_BY_CONSULTATION_FAILURE': {
      return {
        ...state,
        isLoadingProtocoleConsultation: false,
        protocoleConsultation: [],
        //  error: getAppErrorCode(action.status),
      };
    }
    case 'INITIAL_STATE_CONSULTATION': {
      return {
        ...state,
        updateConsultation: false,
        addSessions: false,
        getDispoDate: false,
      };
    }
    case 'UPLOAD_FILES_CONSULTATION_REQUESTING': {
      return {
        ...state,
        loadingAddFile: true,
      };
    }
    case 'UPLOAD_FILES_CONSULTATION_SUCCESS': {
      return {
        ...state,
        loadingAddFile: false,
        addFiles: true,
      };
    }
    case 'UPLOAD_FILES_CONSULTATION_FAILURE': {
      return {
        ...state,
        loadingAddFile: false,
        addFiles: false,
        //  error: getAppErrorCode(action.status),
      };
    }

    case 'UPDATE_PEC_REQUESTING': {
      return {
        ...state,
        loadingAddPec: true,
      };
    }
    case 'UPDATE_PEC_SUCCESS': {
      return {
        ...state,
        loadingAddPec: false,
        addPec: true,
      };
    }
    case 'UPDATE_PEC_FAILURE': {
      return {
        ...state,
        loadingAddPec: false,
        addPec: false,
        //  error: getAppErrorCode(action.status),
      };
    }

    case 'UPDATE_CURE_REQUESTING': {
      return {
        ...state,
        loadingAddCure: true,
      };
    }
    case 'UPDATE_CURE_SUCCESS': {
      return {
        ...state,
        loadingAddCure: false,
        addCure: true,
      };
    }
    case 'UPDATE_CURE_FAILURE': {
      return {
        ...state,
        loadingAddCure: false,
        addCure: false,
      };
    }

    case 'UPDATE_SESSION_REQUESTING': {
      return {
        ...state,
        loadingAddSessions: true,
      };
    }
    case 'UPDATE_SESSION_SUCCESS': {
      return {
        ...state,
        loadingAddSessions: false,
        addSessions: true,
      };
    }
    case 'UPDATE_SESSION_FAILURE': {
      return {
        ...state,
        loadingAddSessions: false,
        addSessions: false,
        //  error: getAppErrorCode(action.status),
      };
    }

    case 'GET_BILAN_BY_CONSULTATION_REQUESTING': {
      return {
        ...state,
        isLoadingBilanConsultation: true,
      };
    }
    case 'GET_BILAN_BY_CONSULTATION_SUCCESS': {
      return {
        ...state,
        isLoadingBilanConsultation: false,
        bilanConsultation: action.data.data,
      };
    }
    case 'GET_BILAN_BY_CONSULTATION_FAILURE': {
      return {
        ...state,
        isLoadingBilanConsultation: false,
        bilanConsultation: [],
        //  error: getAppErrorCode(action.status),
      };
    }

    case 'UPDATE_CONSULTATION_REQUESTING': {
      return {
        ...state,
        isLoadingUpdateConsultation: true,
        updateConsultation: false,
      };
    }
    case 'UPDATE_CONSULTATION_SUCCESS': {
      return {
        ...state,
        isLoadingUpdateConsultation: false,
        updateConsultation: action.data,
      };
    }
    case 'UPDATE_CONSULTATION_FAILURE': {
      return {
        ...state,
        isLoadingUpdateConsultation: false,
        updateConsultation: false,
        //  error: getAppErrorCode(action.status),
      };
    }
    case 'GET_SESSION_CONSULTATION_REQUESTING': {
      return {
        ...state,
        loadingSession: true,
      };
    }
    case 'GET_SESSION_CONSULTATION_SUCCESS': {
      return {
        ...state,
        loadingSession: false,
        sessions: action.data,
      };
    }
    case 'GET_SESSION_CONSULTATION_FAILURE': {
      return {
        ...state,
        loadingSession: false,
        sessions: [],
      };
    }

    case 'GET_CONSULTATION_REQUESTING': {
      return {
        ...state,
        loadingConsultationById: true,
        addFiles: false,
        addProtocole: false,
        loadingGetDispoDoctor: false,
        getDispoDate: [],
      };
    }
    case 'GET_CONSULTATION_SUCCESS': {
      return {
        ...state,
        loadingConsultationById: false,
        updateConsultation: false,
        addFiles: false,
        consultation: action.data,
      };
    }
    case 'GET_CONSULTATION_FAILURE': {
      return {
        ...state,
        loadingConsultationById: false,
        updateConsultation: false,
        error: getAppErrorCode(action.status),
      };
    }
    case 'DELETE_CURE_SUCCESS': {
      return {
        ...state,
        loadingConsultation: true,
        updateConsultation: false,
        doctorConsultationList: [],
      };
    }
    case 'GET_CONSULTATION_DOCTOR_LIST_REQUESTING': {
      return {
        ...state,
        loadingConsultation: true,
        updateConsultation: false,
      };
    }
    case 'GET_CONSULTATION_DOCTOR_LIST_SUCCESS': {
      return {
        ...state,
        loadingConsultation: false,
        doctorConsultationList: action.data,
        updateConsultation: false,
      };
    }
    case 'GET_CONSULTATION_DOCTOR_LIST_FAILURE': {
      return {
        ...state,
        loadingConsultation: false,
        doctorConsultationList: [],
        error: getAppErrorCode(action.status),
        updateConsultation: false,
      };
    }
    case 'GET_DISPONIBILITY_DOCTOR_REQUESTING': {
      return {
        ...state,
        loadingGetDispoDoctor: true,
      };
    }
    case 'GET_DISPONIBILITY_DOCTOR__SUCCESS': {
      return {
        ...state,
        loadingGetDispoDoctor: false,
        addSessions: false,
        getDispoDate: action.data,
      };
    }
    case 'GET_DISPONIBILITY_DOCTOR__FAILURE': {
      return {
        ...state,
        loadingGetDispoDoctor: false,
        getDispoDate: action.data,
      };
    }

    case 'GET_CONSULTATION_PATIENT_RECORD_REQUESTING': {
      return {
        ...state,
        loadingConsultationPatientRecord: true,
        updateConsultation: false,
      };
    }
    case 'GET_CONSULTATION_PATIENT_RECORD_SUCCESS': {
      return {
        ...state,
        loadingConsultationPatientRecord: false,
        updateConsultation: false,
        patientConsultationList: action.data,
      };
    }
    case 'GET_CONSULTATION_PATIENT_RECORD_FAILURE': {
      return {
        ...state,
        loadingConsultationPatientRecord: false,
        updateConsultation: false,
        patientConsultationList: action.data,
        error: getAppErrorCode(action.status),
      };
    }
    case 'GET_MOTIF_List_SUCCESS': {
      return {
        ...state,
        loadingMotifs: false,
        motifList: action.data,
      };
    }
    case 'GET_MOTIFS_REQUESTING': {
      return {
        ...state,
        loadingMotifs: true,
      };
    }
    /**Update Params*/
    case 'UPDATE_SEARCH_CONSULTATION_PARAMS': {
      return {
        ...state,
        params: action.params,
      };
    }
    case 'UPDATE_SEARCH_BILAN_CONSULTATION_PARAMS': {
      return {
        ...state,
        paramsBilan: action.paramsBilan,
      };
    }

    default: {
      return state;
    }
  }
};

export default consultationReducer;
