const initialState = {
  sendMail: false,
  isLoadingSendMail: false,
  isLoadingAddContact: false,
  addContact: false,
};

const SupportReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_CONTACT_SUCCESS': {
      return {
        ...state,
        isLoadingAddContact: false,
        addContact: true,
        sendMail: false,
      };
    }
    case 'ADD_CONTACT_FAILURE': {
      return {
        ...state,
        isLoadingAddContact: false,
        addContact: false,
        sendMail: false,
      };
    }
    case 'ADD_CONTACT_REQUESTING': {
      return {
        ...state,
        isLoadingAddContact: true,
        sendMail: false,
      };
    }

    case 'SEND_MAIL_SUCCESS': {
      return {
        ...state,
        isLoadingSendMail: false,
        sendMail: true,
      };
    }
    case 'SEND_MAIL_FAILURE': {
      return {
        ...state,
        isLoadingSendMail: false,
        sendMail: false,
      };
    }
    case 'SEND_MAIL_REQUESTING': {
      return {
        ...state,
        isLoadingSendMail: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default SupportReducer;
