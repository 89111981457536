import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../../js/actions';
import './index.css';

import CircularProgress from '@material-ui/core/CircularProgress';
import { SelectorInputAndIcon } from '../../../utils';
import { isNumeric, lengthPhone, validate } from '../../../utils/helpres';
import InputField from '../../../components/inputField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { sortCities, country } from '../../../utils/constants';
import TextField from '@material-ui/core/TextField';

const mapStateToProps = (state, ownProps) => {
  const { getUser } = ownProps;
  let first_name;
  let last_name;
  let email;
  let country;
  let city;
  let street;
  let zip_code;
  let phone;
  let speciality_id;
  let conventional_code;
  let tax_identification;
  let cnss;
  let rib;
  let iban;
  let bank;
  let gsm;
  let name_cabinet;
  let id;
  if (getUser && getUser.id) {
    id = getUser && getUser.id ? getUser.id : null;
    speciality_id = getUser && getUser.speciality ? getUser.speciality.id : null;
    bank = getUser && getUser.bank ? getUser.bank : '';
    gsm = getUser && getUser.gsm ? getUser.gsm : '';
    cnss = getUser && getUser.cnss ? getUser.cnss : '';
    name_cabinet = getUser && getUser.name_cabinet ? getUser.name_cabinet : '';
    rib = getUser && getUser.rib ? getUser.rib : '';
    iban = getUser && getUser.iban ? getUser.iban : '';
    tax_identification =
      getUser && getUser.tax_identification ? getUser.tax_identification : '';
    conventional_code =
      getUser && getUser.conventional_code ? getUser.conventional_code : '';
    first_name = getUser && getUser.first_name ? getUser.first_name : '';
    last_name = getUser && getUser.last_name ? getUser.last_name : '';
    email = getUser && getUser.email ? getUser.email : '';
    phone = getUser && getUser.phone ? getUser.phone : '';
    country =
      getUser.login_access.account_type === 'user'
        ? getUser.address_practitioner && getUser.address_practitioner.country
          ? getUser.address_practitioner.country
          : ''
        : getUser.country
        ? getUser.country
        : '';
    city =
      getUser.login_access.account_type === 'user'
        ? getUser.address_practitioner && getUser.address_practitioner.city
          ? getUser.address_practitioner.city
          : ''
        : getUser.city
        ? getUser.city
        : '';
    street =
      getUser.login_access.account_type === 'user'
        ? getUser.address_practitioner && getUser.address_practitioner.street
          ? getUser.address_practitioner.street
          : ''
        : getUser.street
        ? getUser.street
        : '';
    zip_code =
      getUser.login_access.account_type === 'user'
        ? getUser.address_practitioner && getUser.address_practitioner.zip_code
          ? getUser.address_practitioner.zip_code
          : ''
        : getUser.zip_code
        ? getUser.zip_code
        : '';
  }
  return {
    sandwich_menu_btn: state.AppReducer.sandwich_menu_btn,
    specialities: state.AppReducer.specialities,
    isLoadingUpdateUser: state.AppReducer.isLoadingUpdateUser,
    message: state.AppReducer.message,
    error: state.AppReducer.error,
    updateUser: state.AppReducer.updateUser,
    roles: state.AppReducer.roles,
    user: state.AppReducer.user,
    getUser: getUser,
    initialValues: {
      ...getUser,
      tax_identification: tax_identification,
      conventional_code: conventional_code,
      id,
      cnss: cnss,
      iban: iban,
      bank: bank,
      name_cabinet: name_cabinet,
      rib: rib,
      first_name: first_name,
      speciality_id: speciality_id,
      last_name: last_name,
      email: email,
      phone: phone,
      gsm: gsm,
      address_practitioner: {
        country: country,
        city: city,
        street: street,
        zip_code: zip_code,
      },
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  submitUserForm: async (values, id) => {
    await dispatch(actions.submitUserForm(values));
    dispatch(actions.getUserById(id));
    dispatch(actions.getMe());
  },
  submitSecretaryForm: async (values, type, id) => {
    await dispatch(actions.submitSecretaryForm(values, type));
    dispatch(actions.getUserById(id));
    dispatch(actions.getMe());
  },
  getSpecialities: () => dispatch(actions.getSpecialities()),
});

class InfoGenerale extends Component {
  constructor(props) {
    super(props);
    document.title = 'Info générale';
    this.state = {
      initialValues: {
        ...props.initialValues,
      },
      hasError: false,
    };
    this.handleInitialValues = this.handleInitialValues.bind(this);
  }
  handleInitialValues() {
    this.setState({
      hasError: false,
      initialValues: {
        ...this.props.initialValues,
      },
    });
  }
  componentDidMount() {
    SelectorInputAndIcon();
  }

  submitForm(initialValues, id) {
    const { getUser } = this.props;
    if (this.props.file) {
      initialValues.file = this.props.file;
    }
    if (
      //   !initialValues.email ||
      !initialValues.last_name ||
      !initialValues.first_name ||
      (initialValues.first_name && !validate(initialValues.first_name)) ||
      (initialValues.address_practitioner.country &&
        !validate(initialValues.address_practitioner.country)) ||
      (initialValues.address_practitioner.city &&
        !validate(initialValues.address_practitioner.city)) ||
      (initialValues.address_practitioner.zip_code &&
        !isNumeric(initialValues.address_practitioner.zip_code)) ||
      (initialValues.last_name && !validate(initialValues.last_name)) ||
      //!firstNumberPhone(initialValues.phone) ||
      (initialValues.gsm && !lengthPhone(initialValues.gsm)) ||
      (initialValues.gsm && !isNumeric(initialValues.gsm)) ||
      (initialValues.phone && !lengthPhone(initialValues.phone)) ||
      (initialValues.phone && !isNumeric(initialValues.phone))
    )
      this.setState({
        ...initialValues,
        hasError: true,
      });
    else {
      if (getUser.login_access.account_type === 'user')
        this.props.submitUserForm(initialValues, id);
      if (getUser.login_access.account_type === 'secretary') {
        initialValues.city = initialValues.address_practitioner.city;
        initialValues.street = initialValues.address_practitioner.street;
        initialValues.zip_code = initialValues.address_practitioner.zip_code;
        initialValues.country = initialValues.address_practitioner.country;
        this.props.submitSecretaryForm(initialValues, 'secretary', id);
      }
      if (getUser.login_access.account_type === 'assistant') {
        initialValues.city = initialValues.address_practitioner.city;
        initialValues.street = initialValues.address_practitioner.street;
        initialValues.zip_code = initialValues.address_practitioner.zip_code;
        initialValues.country = initialValues.address_practitioner.country;
        this.props.submitSecretaryForm(initialValues, 'assistant', id);
      }
    }
  }

  render() {
    const { isLoadingUpdateUser, admin, sandwich_menu_btn, getUser } = this.props;
    const { initialValues, hasError } = this.state;
    return (
      <div className="general_info" style={{ width: '97%' }}>
        <form className="general_info_form">
          <div className="title_and_button">
            <span>Informations générales</span>
          </div>
          <div className="general_info_actual_form">
            <InputField
              width={sandwich_menu_btn ? '26vw' : '30vw'}
              className="general_info_form_item"
              type="text"
              placeholder="Prénom"
              label="Prénom"
              hasError={hasError}
              validInput="string"
              value={initialValues.last_name}
              name="last_name"
              icon={<i className="fas fa-user"></i>}
              onChange={(e) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    last_name: e.target.value,
                    //  name_cabinet:  e.target.value,
                  },
                });
              }}
            />

            <InputField
              width={sandwich_menu_btn ? '26vw' : '30vw'}
              type="text"
              placeholder="Nom"
              className="general_info_form_item"
              validInput="string"
              label="Nom"
              hasError={hasError}
              value={initialValues.first_name}
              name="first_name"
              icon={<i className="fas fa-user"></i>}
              onChange={(e) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    first_name: e.target.value,
                    // name_cabinet: e.target.value,
                  },
                });
              }}
            />
            <InputField
              width={sandwich_menu_btn ? '26vw' : '30vw'}
              className="general_info_form_item"
              type="text"
              placeholder="Adresse e-mail"
              label="Adresse e-mail"
              // hasError={hasError}
              //disabled={true}
              value={initialValues.email}
              name="email"
              icon={<i className="fas fa-envelope"></i>}
              onChange={(e) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    email: e.target.value,
                  },
                });
              }}
            />
            <InputField
              width={sandwich_menu_btn ? '26vw' : '30vw'}
              className="general_info_form_item"
              type="text"
              placeholder="GSM"
              label="GSM"
              minLength={8}
              maxLength={20}
              //  hasError={hasError}
              validInput="tel"
              value={initialValues.phone}
              name="phone"
              icon={<i className="fas fa-phone-alt"></i>}
              onChange={(e) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    phone: e.target.value,
                  },
                });
              }}
            />
            {admin ? (
              <>
                <InputField
                  width={sandwich_menu_btn ? '26vw' : '30vw'}
                  className="general_info_form_item"
                  type="text"
                  placeholder="Tél/Fax"
                  label="Tél/Fax"
                  minLength={8}
                  maxLength={20}
                  ///  hasError={hasError}
                  validInput="tel"
                  value={initialValues.gsm}
                  name="gsm"
                  icon={<i className="fas fa-phone-alt"></i>}
                  onChange={(e) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        gsm: e.target.value,
                      },
                    });
                  }}
                />
                <InputField
                  width={sandwich_menu_btn ? '26vw' : '30vw'}
                  className="general_info_form_item"
                  type="text"
                  placeholder="Nom du cabinet"
                  label="Nom du cabinet"
                  //hasError={hasError}
                  validInput="string"
                  value={initialValues.name_cabinet}
                  name="name_cabinet"
                  icon={<i className="fas fa-user"></i>}
                  onChange={(e) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        name_cabinet: e.target.value,
                      },
                    });
                  }}
                />
              </>
            ) : (
              ''
            )}
          </div>
          {admin ? (
            ''
          ) : (
            <>
              <div className="title_and_button">
                <span>Informations personnelle</span>
              </div>
              <div className="general_info_actual_form">
                {getUser.login_access &&
                getUser.login_access.account_type === 'assistant' ? (
                  ''
                ) : (
                  <InputField
                    width={sandwich_menu_btn ? '26vw' : '30vw'}
                    className="general_info_form_item"
                    type="text"
                    placeholder="C.N.S.S"
                    label="C.N.S.S"
                    // hasError={hasError}
                    //disabled={true}
                    value={initialValues.cnss}
                    name="cnss"
                    icon={<i className="fab fa-accessible-icon"></i>}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          cnss: e.target.value,
                        },
                      });
                    }}
                  />
                )}

                <InputField
                  width={sandwich_menu_btn ? '26vw' : '30vw'}
                  className="general_info_form_item"
                  type="text"
                  placeholder="IBAN"
                  label="IBAN"
                  //hasError={hasError}
                  //validInput="integer"
                  value={initialValues.iban}
                  name="iban"
                  icon={<i className="fab fa-scribd"></i>}
                  onChange={(e) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        iban: e.target.value,
                      },
                    });
                  }}
                />
                <InputField
                  width={sandwich_menu_btn ? '26vw' : '30vw'}
                  className="general_info_form_item"
                  type="text"
                  placeholder="BANQUE"
                  label="BANQUE"
                  //hasError={hasError}
                  validInput="string"
                  value={initialValues.bank}
                  name="bank"
                  icon={<i className="fas fa-money-check-alt"></i>}
                  onChange={(e) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        bank: e.target.value,
                      },
                    });
                  }}
                />
              </div>
            </>
          )}

          <div className="address_form">
            <div className="title_and_button">
              <span id="">Adresse</span>
            </div>

            <div className="add_patient_actual_form">
              <div className="field_row">
                <InputField
                  width="100%"
                  className="add_patient_form_item"
                  type="text"
                  placeholder="Adresse"
                  label="Adresse"
                  //hasError={hasError}
                  validInput=""
                  value={initialValues.address_practitioner.street}
                  name="street"
                  icon={<i className="fas fa-city"></i>}
                  onChange={(e) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        address_practitioner: {
                          ...initialValues.address_practitioner,
                          street: e.target.value,
                        },
                      },
                    });
                  }}
                />
                <div style={{ width: '100%' }} className="add_patient_form_item">
                  <label>Ville</label>
                  <Autocomplete
                    id="size-small-standard"
                    size="small"
                    options={sortCities}
                    onChange={(event, option) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          address_practitioner: {
                            ...initialValues.address_practitioner,
                            city: option != null ? option.title : '',
                          },
                        },
                      });
                    }}
                    defaultValue={{
                      title:
                        initialValues.address_practitioner &&
                        initialValues.address_practitioner.city,
                    }}
                    getOptionLabel={(option) => option.title}
                    renderInput={(cities) => (
                      <TextField
                        style={{ width: '100%' }}
                        placeholder="Ville"
                        {...cities}
                        variant="standard"
                        //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                        //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                        // error={hasError && !initialValues.address_practitioner.city}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="field_row">
                <InputField
                  width="100%"
                  className="add_patient_form_item"
                  type="text"
                  placeholder="Code Postal"
                  label="Code Postal"
                  // hasError={hasError}
                  validInput="integer"
                  value={initialValues.address_practitioner.zip_code}
                  name="zip_code"
                  icon={<i className="fas fa-globe-africa"></i>}
                  onChange={(e) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        address_practitioner: {
                          ...initialValues.address_practitioner,
                          zip_code: e.target.value,
                        },
                      },
                    });
                  }}
                />
                <div style={{ width: '100%' }} className="add_patient_form_item">
                  <label>Pays</label>
                  <Autocomplete
                    id="size-small-standard"
                    size="small"
                    options={country}
                    onChange={(event, option) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          address_practitioner: {
                            ...initialValues.address_practitioner,
                            country: option != null ? option.title : '',
                          },
                        },
                      });
                    }}
                    defaultValue={{
                      title:
                        initialValues.address_practitioner &&
                        initialValues.address_practitioner.country,
                    }}
                    getOptionLabel={(option) => option.title}
                    renderInput={(country) => (
                      <TextField
                        style={{ width: '100%' }}
                        placeholder="Pays"
                        {...country}
                        variant="standard"
                        //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                        //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                        //    error={hasError && !initialValues.address_practitioner.country}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="footer_buttons" style={{ paddingBottom: '4%' }}>
          <button onClick={this.handleInitialValues} className="footer_btn" id="cancel">
            Annuler
          </button>
          <button
            style={{
              marginRight: sandwich_menu_btn ? '6%' : '6%',
            }}
            id="update"
            className="footer_btn"
            //disabled={isLoadingUpdateUser}
            onClick={() => {
              this.submitForm(initialValues, getUser.id);
            }}
          >
            {isLoadingUpdateUser ? (
              <CircularProgress
                style={{
                  marginLeft: '4%',
                  width: '25px',
                  height: '25px',
                  color: 'white',
                  marginTop: '5%',
                }}
              />
            ) : (
              'Valider'
            )}
          </button>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InfoGenerale);
