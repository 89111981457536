import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import actions from './store/actions';
import SimpleReactValidator from 'simple-react-validator';
import InputField from '../../../components/inputFieldV2';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

const mapStateToProps = (state) => ({
  emailExist: state.ResetPasswordReducer.emailExist,
  isLoadingGetEmail: state.ResetPasswordReducer.isLoadingGetEmail,
  isLoading: state.ResetPasswordReducer.isLoading,
  message: state.ResetPasswordReducer.message,
  error: state.ResetPasswordReducer.error,
  isLoadingRestPassword: state.ResetPasswordReducer.isLoadingRestPassword,
  updated: state.ResetPasswordReducer.updated,
});

const mapDispatchToProps = (dispatch) => ({
  getEmail: (email) => dispatch(actions.getEmail(email)),
  submitResetPasswordForm: (values) => dispatch(actions.submitResetPasswordForm(values)),
});
const iconError = (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.71094 0.289062C10.0022 0.580307 10.0022 1.05251 9.71094 1.34375L6.05469 5L9.71094 8.65625C10.0022 8.94749 10.0022 9.41969 9.71094 9.71094C9.41969 10.0022 8.94749 10.0022 8.65625 9.71094L5 6.05469L1.34375 9.71094C1.05251 10.0022 0.580307 10.0022 0.289062 9.71094C-0.0021815 9.41969 -0.00218129 8.94749 0.289063 8.65625L3.94531 5L0.289063 1.34375C-0.00218055 1.05251 -0.0021815 0.580307 0.289062 0.289062C0.580307 -0.0021815 1.05251 -0.00218129 1.34375 0.289063L5 3.94531L8.65625 0.289063C8.94749 -0.00218055 9.41969 -0.0021815 9.71094 0.289062Z"
      fill="#F6583E"
    />
  </svg>
);
const iconSuccess = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.67572 10.548C7.0657 10.9416 7.70143 10.9431 8.09325 10.5513L12.0273 6.61726C12.3199 6.32459 12.3238 5.85128 12.036 5.55385C11.7414 5.24945 11.2545 5.24546 10.955 5.54499L7.38281 9.11719L5.23952 6.97389C4.94759 6.68197 4.47428 6.68197 4.18236 6.97389C3.8914 7.26485 3.89029 7.73625 4.17988 8.02857L6.67572 10.548ZM2.69141 2.72656C4.16797 1.25 5.9375 0.511719 8 0.511719C10.0625 0.511719 11.8203 1.25 13.2734 2.72656C14.75 4.17969 15.4883 5.9375 15.4883 8C15.4883 10.0625 14.75 11.832 13.2734 13.3086C11.8203 14.7617 10.0625 15.4883 8 15.4883C5.9375 15.4883 4.16797 14.7617 2.69141 13.3086C1.23828 11.832 0.511719 10.0625 0.511719 8C0.511719 5.9375 1.23828 4.17969 2.69141 2.72656Z"
      fill="#1DD2C1"
    />
  </svg>
);
class ResetPassword extends PureComponent {
  constructor(props) {
    super(props);
    document.title = 'Verification adresse e-mail';
    this.state = {
      showEmail: true,
      hiddenEmail: false,
      hiddenPassword: true,
      showPassword: false,
      showRepeatPassword: false,
      hasErrorNewPassword: false,
      hasError: false,
      initialState: {
        confirmPassword: '',
        code: '',
        email: '',
        password: '',
        showEmail: '',
        showPassword: '',
      },
    };
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        min: 'Saisissez un mot de passe comprenant au moins 6 caractères.',
        max: 'Le mot de passe doit comporter de 6 à 20 caractères. ',
        // OR
        default: 'Champ requise.', // will override all messages
      },
    });
    this.handleShowPassword = this.handleShowPassword.bind(this);
    this.handleShowRepeatPassword = this.handleShowRepeatPassword.bind(this);
  }
  handleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  handleShowRepeatPassword() {
    this.setState({
      showRepeatPassword: !this.state.showRepeatPassword,
    });
  }
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  keyPressed(event) {
    if (event.key === 'Enter') {
      const { initialState, hasError } = this.state;
      if (!initialState.email)
        this.setState({
          hasError: !hasError,
        });
      if (initialState.email) {
        this.submitFormEmail(initialState.email);
      }
    }
  }
  submitFormResetPassword(initialState) {
    if (this.validator.allValid()) {
      this.props.submitResetPasswordForm(initialState);
    } else if (initialState.password.localeCompare(initialState.confirmPassword)) {
      this.setState({
        ...initialState,
        hasErrorNewPassword: true,
      });
    } else {
      this.setState({
        hasError: true,
      });
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { emailExist, updated } = nextProps;
    const { hiddenPassword } = this.state;
    if (emailExist.code && hiddenPassword) {
      this.setState({
        hasError: false,
        showEmail: false,
        showResetPassword: true,
      });
    }
    if (updated && hiddenPassword) {
      nextProps.history.push('/login');
    }
  }

  submitFormEmail(email) {
    if (this.validator.allValid()) {
      this.props.getEmail(email);
    } else {
      this.setState({
        hasError: true,
      });
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }
  render() {
    const {
      showPassword,
      hasErrorNewPassword,
      showRepeatPassword,
      initialState,
      hasError,
      showEmail,
      hiddenPassword,
      showResetPassword,
    } = this.state;
    //const {  } = initialState;
    const { emailExist, isLoadingGetEmail, isLoadingRestPassword } = this.props;
    return (
      <div className="authentication_view">
        {showEmail ? (
          <>
            <img
              src={require('../../../assets/img/recover_img.svg')}
              width="200px"
              height="156.66px"
              alt=""
            />
            <div className="view_header" style={{ marginTop: '35px' }}>
              <span>Mot de passe perdu?</span>
              <span>Entrer vos coordonnées pour</span>
              <span>récupérer.</span>
            </div>
            <p id="header_subtext">Entrer vos coordonnées pour continuer</p>
            <form style={{ width: '45%' }} className="sign_in_form">
              <InputField
                width={'60%'}
                className="view_item"
                type="text"
                placeholder="Adresse e-mail"
                label=""
                hasError={hasError}
                value={initialState.email}
                name="email"
                icon={<i className="fas fa-envelope"></i>}
                required={this.validator.message(
                  'email',
                  initialState.email,
                  'required|email',
                )}
                onChange={(e) => {
                  this.setState({
                    initialState: {
                      ...initialState,
                      email: e.target.value,
                    },
                  });
                }}
              />
              <br />
              <div className="view_item">
                <button
                  id="connect_btn"
                  type="button"
                  onClick={() => this.submitFormEmail(initialState.email)}
                >
                  {isLoadingGetEmail ? (
                    <CircularProgress
                      style={{
                        marginRight: '45%',
                        width: '30px',
                        height: '30px',
                        color: 'white',
                      }}
                    />
                  ) : (
                    'Récupérer'
                  )}
                </button>
                <Link to="/login">
                  <button type="button" id="create_account_btn">
                    Se connecter
                  </button>
                </Link>
              </div>
            </form>
          </>
        ) : (
          ''
        )}
        {showResetPassword ? (
          <>
            <img
              src={require('../../../assets/img/recover_img.svg')}
              width="200px"
              height="126.66px"
              alt=""
            />
            <div className="view_header" style={{ marginTop: '35px' }}>
              <span>Modification du mot de passe</span>
            </div>
            <p id="header_subtext">Saisir les nouveaux accès.</p>
            <form style={{ width: '45%' }} className="sign_in_form">
              <InputField
                width={'60%'}
                className="view_item"
                type="text"
                placeholder="Code"
                label=""
                hasError={hasError}
                value={initialState.code}
                name="code"
                icon={<i className="fas fa-key"></i>}
                required={this.validator.message(
                  'code',
                  initialState.code,
                  `${hiddenPassword && emailExist ? `in:${emailExist.code}` : ''}`,
                  { messages: { in: 'Code incorrect.' } },
                )}
                onChange={(e) => {
                  this.setState({
                    initialState: {
                      ...initialState,
                      code: e.target.value,
                    },
                  });
                }}
              />
              <div
                style={{ width: '60%', display: 'flex' }}
                className={
                  hasError && !initialState.password
                    ? `view_item type3`
                    : initialState.password &&
                      initialState.confirmPassword &&
                      !initialState.password.localeCompare(initialState.confirmPassword)
                    ? 'view_item type4'
                    : 'view_item'
                }
                id="new-input"
              >
                <span className="input-group-text" id="basic-addon1">
                  {hasError && !initialState.password ? (
                    iconError
                  ) : initialState.password &&
                    initialState.confirmPassword &&
                    !initialState.password.localeCompare(initialState.confirmPassword) ? (
                    iconSuccess
                  ) : (
                    <i onClick={this.handleShowPassword} className="fas fa-lock"></i>
                  )}
                </span>
                <input
                  id="standard-adornment-password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={initialState.password}
                  onChange={(data) => {
                    this.setState({
                      initialState: {
                        ...initialState,
                        password: data.target.value,
                      },
                    });
                  }}
                  placeholder="Nouveau mot de passe . . . "
                  className="form-control"
                />
              </div>
              <div style={{ width: '60%', display: 'block' }}>
                <div
                  style={{ width: '100%', marginBottom: '10px', display: 'flex' }}
                  className={
                    hasError && !initialState.confirmPassword
                      ? `view_item type3`
                      : initialState.password &&
                        initialState.confirmPassword &&
                        !initialState.password.localeCompare(initialState.confirmPassword)
                      ? 'view_item type4'
                      : 'view_item'
                  }
                  id="new-input"
                >
                  <span className="input-group-text" id="basic-addon1">
                    {(hasError && !initialState.confirmPassword) ||
                    (initialState.confirmPassword && hasErrorNewPassword) ? (
                      iconError
                    ) : initialState.password &&
                      initialState.confirmPassword &&
                      !initialState.password.localeCompare(
                        initialState.confirmPassword,
                      ) ? (
                      iconSuccess
                    ) : (
                      <i
                        onClick={this.handleShowRepeatPassword}
                        className="fas fa-lock"
                      ></i>
                    )}
                  </span>
                  <input
                    id="standard-adornment-password"
                    name="password"
                    type={showRepeatPassword ? 'text' : 'password'}
                    value={initialState.confirmPassword}
                    onChange={(data) => {
                      if (initialState.password.localeCompare(data.target.value)) {
                        this.setState({
                          ...initialState,
                          hasError: true,
                        });
                      }
                      this.setState({
                        initialState: {
                          ...initialState,
                          confirmPassword: data.target.value,
                        },
                      });
                    }}
                    placeholder="Repeter mot de passe . . . "
                    className="form-control"
                  />
                  <div style={{ color: 'red', marginBottom: '2%', fontSize: '13px' }}>
                    {this.validator.message(
                      'confirmPassword',
                      initialState.confirmPassword,
                      'string|min:6|max:20',
                    )}
                  </div>
                </div>
                {initialState.confirmPassword && hasErrorNewPassword ? (
                  <div style={{ color: 'red', marginBottom: '2%', fontSize: '13px' }}>
                    Merci de vérifier que vos mots de passe correspondent
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div style={{ marginTop: '30px' }} className="view_item">
                <button
                  onClick={() => {
                    if (
                      !initialState.confirmPassword ||
                      !initialState.password ||
                      !initialState.code
                    )
                      this.setState({
                        ...this.state,
                        hasError: true,
                      });
                    if (
                      initialState.confirmPassword &&
                      initialState.password &&
                      initialState.code
                    ) {
                      this.submitFormResetPassword(initialState);
                    }
                  }}
                  id="connect_btn"
                  type="button"
                >
                  {isLoadingRestPassword ? (
                    <CircularProgress
                      style={{
                        marginRight: '45%',
                        width: '30px',
                        height: '30px',
                        color: 'white',
                      }}
                    />
                  ) : (
                    'Valider'
                  )}
                </button>
                <Link to="/login">
                  <button type="button" id="create_account_btn">
                    Se connecter
                  </button>
                </Link>
              </div>
            </form>
          </>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
