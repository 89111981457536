import React, { useState, useEffect, Fragment } from 'react';
//import React, { Component } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';
import moment from 'moment';
import Icon from '../../assets/img/arrow1.png';
import IconKine from '../../assets/img/logo-kine.png';
import { CircularProgress } from '@material-ui/core';

// Create styles
const newstyles = StyleSheet.create({
  page: {
    display: 'inline-grid',
    flexDirection: 'row',
    marginTop: 10,
  },
  PDFViewer: {
    width: '100%',
    height: '550px',
  },
  body: {
    paddingTop: 10,
    paddingBottom: 65,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  tableTOP: {
    display: 'table',
    width: 'auto',
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginHorizontal: '10',
    marginTop: '10px',
    paddingBottom: '10px',
  },
  tableTOTAL: {
    display: 'table',
    width: '80%',
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginHorizontal: '20',
    alignItems: 'right',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginHorizontal: '20',
    marginTop: '10px',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol30: {
    width: '30%',
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol35TOP: {
    width: '35%',
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol35: {
    width: '35%',
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol15: {
    width: '15%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol10: {
    width: '10%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol5: {
    width: '5%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol40: {
    width: '40%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
    textAlign: 'left',
    paddingLeft: 0,
  },
});
function Bordereau(props) {
  const bills = props.location.state.billsByIds;
  const office = props.location.state.office;
  const bordereau = props.location.state.addBordereau;
  const type = props.location.state.type;
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    props.changeActiveItemSideBar(6);
    props.changeNamePage(`Bordereau n°: ${bordereau.number}`);
    let dataTemp = [];
    let totalTTC = 0;
    bills &&
      bills.length > 0 &&
      bills
        .sort((a, b) => (a.id > b.id ? 1 : -1))
        .map((bill, index) => {
          if (!dataTemp[parseInt(index / 35)]) {
            dataTemp[parseInt(index / 35)] = [];
          }
          totalTTC += bill.total_ttc;
          dataTemp[parseInt(index / 35)].push({
            id: bill.id,
            num: index,
            number_bill: bill.number,
            date: moment(bill.date).format('DD/MM/YYYY'),
            patient: `${bill.consultation.patient_record.patient.last_name} ${bill.consultation.patient_record.patient.first_name}`,
            cnam:
              bill.consultation.patient_record.patient.cnam_root &&
              bill.consultation.patient_record.patient.cnam_key
                ? `${bill.consultation.patient_record.patient.cnam_root}/${bill.consultation.patient_record.patient.cnam_key}`
                : '-',
            number_pec:
              bill.consultation.prise_en_charges.length > 0 &&
              bill.consultation.prise_en_charges[0].code_bureau
                ? `${bill.consultation.prise_en_charges[0].code_bureau}/${bill.consultation.prise_en_charges[0].year}/${bill.consultation.prise_en_charges[0].num_order}`
                : '',
            year_pec:
              bill.consultation.prise_en_charges.length > 0
                ? bill.consultation.prise_en_charges[0].year
                : '',
            total: bill.total_ttc,
          });
        });
    setData(dataTemp);
    setTotal(totalTTC);
    console.log(dataTemp);
  }, []);

  return (
    <>
      <div className="bilan_details_header">
        <div
          onClick={() =>
            props.history.push({
              pathname:
                type === 'cnam'
                  ? `/module-cnam`
                  : type === 'act'
                  ? '/module-cnam'
                  : '/compta',
              state: {
                bordereau_id: bordereau.id,
                bCnam: type === 'cnam' ? true : false,
                bACT: type === 'act' ? true : false,
                bAutre: type === 'autre' ? true : false,
              },
            })
          }
          className="back_icon"
        >
          <img src={Icon} />
        </div>
        <span>Retour</span>
      </div>
      {data && data.length > 0 ? (
        <Fragment>
          <PDFViewer style={newstyles.PDFViewer}>
            <Document>
              {data &&
                data.length > 0 &&
                Object.keys(data)
                  //.sort((a, b) => (a.id > b.id ? 1 : 1))
                  .map((key, currentBlock) => {
                    const dataBloc = data[key];
                    return (
                      <Page style={newstyles.body} size="A4">
                        <View style={newstyles.tableTOP}>
                          <View style={newstyles.tableRow}>
                            <View style={newstyles.tableCol35TOP}>
                              <Text style={newstyles.tableCell}>
                                NOM :{' '}
                                {office && office.name
                                  ? office.name
                                  : `${office.first_name} ${office.last_name}`}
                              </Text>
                              <Text style={newstyles.tableCell}>
                                N° Employeur : {office ? office.number_employer : '-'}{' '}
                              </Text>
                              <Text style={newstyles.tableCell}>
                                CNAM : {office ? office.conventional_code : '-'}{' '}
                              </Text>
                            </View>

                            <View style={newstyles.tableCol30}>
                              <Text style={newstyles.tableCell}>
                                <Image style={{ width: 50, height: 40 }} src={IconKine} />
                              </Text>
                            </View>

                            <View style={newstyles.tableCol35}>
                              <Text style={newstyles.tableCell}>
                                Identification fiscale:{' '}
                                {office ? office.tax_registration_number : '-'}
                              </Text>
                              <Text style={newstyles.tableCell}>
                                R.I.B: {office ? office.rib : '-'}
                              </Text>
                              <Text style={newstyles.tableCell}>
                                BANQUE: {office ? office.bank : '-'}
                              </Text>
                            </View>
                          </View>
                        </View>

                        {currentBlock === 0 ? (
                          <>
                            <View
                              style={{
                                borderBottomColor: 'black',
                                marginLeft: 50,
                                marginRight: 60,
                                marginTop: 20,
                                borderBottomWidth: 1,
                              }}
                            />
                            <View
                              style={{
                                justifyContent: 'center',
                                //marginLeft: 50,
                                marginTop: 10,
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              <Text
                                style={{
                                  //color: '#49494c',
                                  fontSize: 10,
                                  fontWeight: 'bolder',
                                }}
                              >
                                BORDEREAU DE TRANSMISSION N° {bordereau.number}
                              </Text>
                            </View>

                            <View
                              style={{
                                justifyContent: 'center',
                                marginTop: 10,
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 10,
                                  fontWeight: 'bolder',
                                  marginBottom: 10,
                                }}
                              >
                                {moment(bordereau.created_at).format('DD/MM/YYYY')}
                              </Text>
                            </View>
                          </>
                        ) : null}

                        <View style={newstyles.table}>
                          <View style={newstyles.tableRow}>
                            <View style={newstyles.tableCol5}>
                              <Text style={newstyles.tableCell}>N°</Text>
                            </View>
                            <View style={newstyles.tableCol40}>
                              <Text style={newstyles.tableCell}>Le patient</Text>
                            </View>
                            <View style={newstyles.tableCol15}>
                              <Text style={newstyles.tableCell}>N° Assuré</Text>
                            </View>
                            <View style={newstyles.tableCol15}>
                              <Text style={newstyles.tableCell}>N° Décision</Text>
                            </View>
                            <View style={newstyles.tableCol15}>
                              <Text style={newstyles.tableCell}>N° Facture</Text>
                            </View>
                            <View style={newstyles.tableCol10}>
                              <Text style={newstyles.tableCell}>TTC</Text>
                            </View>
                          </View>

                          {dataBloc.map((bill) => (
                            <View style={newstyles.tableRow}>
                              <View style={newstyles.tableCol5}>
                                <Text style={newstyles.tableCell}>{bill.num + 1}</Text>
                              </View>
                              <View style={newstyles.tableCol40}>
                                <Text style={newstyles.tableCell}>{bill.patient}</Text>
                              </View>
                              <View style={newstyles.tableCol15}>
                                <Text style={newstyles.tableCell}>{bill.cnam}</Text>
                              </View>
                              <View style={newstyles.tableCol15}>
                                <Text style={newstyles.tableCell}>{bill.number_pec}</Text>
                              </View>
                              <View style={newstyles.tableCol15}>
                                <Text style={newstyles.tableCell}>
                                  {bill.number_bill}
                                </Text>
                              </View>
                              <View style={newstyles.tableCol10}>
                                <Text style={newstyles.tableCell}>
                                  {parseFloat(bill.total).toFixed(3)}
                                </Text>
                              </View>
                            </View>
                          ))}
                        </View>

                        {data && data.length !== 0 && currentBlock + 1 == data.length ? (
                          <View style={newstyles.tableTOTAL}>
                            <View
                              style={{
                                width: '20%',
                                borderStyle: 'solid',
                                borderWidth: 1,
                                paddingLeft: '1%',
                                paddingRight: '1%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginTop: '20px',
                              }}
                            >
                              <View
                                style={{
                                  borderLeftWidth: 0,
                                  borderTopWidth: 0,
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                }}
                              >
                                <Text
                                  style={{
                                    margin: 'auto',
                                    marginTop: 5,
                                    fontSize: 8,
                                    textAlign: 'left',
                                    paddingLeft: 0,
                                  }}
                                >
                                  TOT. HT
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderLeftWidth: 0,
                                  borderTopWidth: 0,
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                }}
                              >
                                <Text
                                  style={{
                                    margin: 'auto',
                                    marginTop: 5,
                                    fontSize: 8,
                                    // textAlign: "",
                                    paddingLeft: 0,
                                  }}
                                >
                                  {(parseFloat(total) / 1.07).toFixed(3)}
                                </Text>
                              </View>
                            </View>

                            <View
                              style={{
                                width: '20%',
                                borderStyle: 'solid',
                                borderWidth: 1,
                                borderTopWidth: 0,
                                paddingLeft: '1%',
                                paddingRight: '1%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                            >
                              <View
                                style={{
                                  borderLeftWidth: 0,
                                  borderTopWidth: 0,
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                }}
                              >
                                <Text
                                  style={{
                                    margin: 'auto',
                                    marginTop: 5,
                                    fontSize: 8,
                                    textAlign: 'left',
                                    paddingLeft: 0,
                                  }}
                                >
                                  MNT. TVA
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderLeftWidth: 0,
                                  borderTopWidth: 0,
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                }}
                              >
                                <Text
                                  style={{
                                    margin: 'auto',
                                    marginTop: 5,
                                    fontSize: 8,
                                    // textAlign: "",
                                    paddingLeft: 0,
                                  }}
                                >
                                  {(parseFloat(total) - parseFloat(total) / 1.07).toFixed(
                                    3,
                                  )}
                                </Text>
                              </View>
                            </View>

                            <View
                              style={{
                                width: '20%',
                                borderStyle: 'solid',
                                borderWidth: 1,
                                borderTopWidth: 0,
                                paddingLeft: '1%',
                                paddingRight: '1%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                            >
                              <View
                                style={{
                                  borderLeftWidth: 0,
                                  borderTopWidth: 0,
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                }}
                              >
                                <Text
                                  style={{
                                    margin: 'auto',
                                    marginTop: 5,
                                    fontSize: 8,
                                    textAlign: 'left',
                                    paddingLeft: 0,
                                  }}
                                >
                                  TOT. TTC
                                </Text>
                              </View>
                              <View
                                style={{
                                  borderLeftWidth: 0,
                                  borderTopWidth: 0,
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                }}
                              >
                                <Text
                                  style={{
                                    margin: 'auto',
                                    marginTop: 5,
                                    fontSize: 8,
                                    // textAlign: "",
                                    paddingLeft: 0,
                                  }}
                                >
                                  {parseFloat(total).toFixed(3)}
                                </Text>
                              </View>
                            </View>
                          </View>
                        ) : (
                          <Text></Text>
                        )}
                        <View
                          style={{
                            height: '40px',
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                            width: '100%',
                            color: 'white',
                            textAlign: 'center',
                          }}
                        >
                          <View
                            style={{
                              borderBottomColor: 'black',
                              marginLeft: 50,
                              marginRight: 60,
                              //marginTop: 350,
                              borderBottomWidth: 1,
                            }}
                          />
                          <View>
                            <Text
                              style={{
                                top: 10,
                                color: '#070707',
                                fontWeight: '1000',
                                fontSize: 10,
                                fontStyle: 'gras',
                                //fontFamily: 'Georgia, serif',
                                margin: 3,
                              }}
                            >
                              {office && office.address_office
                                ? `Adresse: ${office.address_office.street}, ${office.address_office.zip_code} ${office.address_office.city} ${office.address_office.country}`
                                : '-'}
                              {' - '}
                              {office.phone ? `Tél/Fax: +216 ${office.phone} ` : '-'}
                            </Text>
                            <Text
                              style={newstyles.pageNumber}
                              render={({ pageNumber, totalPages }) =>
                                `${pageNumber} / ${totalPages}`
                              }
                              fixed
                            />
                          </View>
                        </View>
                      </Page>
                    );
                  })}
            </Document>
          </PDFViewer>
        </Fragment>
      ) : (
        <CircularProgress />
      )}
    </>
  );
}

export default Bordereau;
