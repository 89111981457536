import React, { Component } from 'react';
import { SwipeableDrawer } from '@material-ui/core';
import moment from 'moment';
import { CircularProgress } from '@mui/material';

class SwipeableDrawerCreateDuplicate extends Component {
  render() {
    const {
      initialValues,
      params,
      hasError,
      isLoadingDuplicateRdv,
      user,
      absenceHours,
      switchDuplicateRDV,
    } = this.props;
    return (
      <SwipeableDrawer
        style={{ width: '50%' }}
        anchor="right"
        open={switchDuplicateRDV}
        onClose={this.props.toggleDrawer('right', false)}
        onOpen={this.props.toggleDrawer('right', true)}
      >
        <div className="right_side_bar">
          <div className="right_side_bar_header">
            <span id="add_app_sidebar_title">Ajouter un nouveau rendez-vous</span>
            <svg
              onClick={() => {
                this.props.handleInitialStateRDV();
                this.props.handleSwitchList();
              }}
              style={{ cursor: 'pointer' }}
              id="close-right-sidebar"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="39"
                height="39"
                rx="9.5"
                fill="white"
                stroke="#ECEEF5"
              />
              <path
                d="M25.2383 15.8164L21.0547 20L25.2383 24.1836L24.1836 25.2383L20 21.0547L15.8164 25.2383L14.7617 24.1836L18.9453 20L14.7617 15.8164L15.8164 14.7617L20 18.9453L24.1836 14.7617L25.2383 15.8164Z"
                fill="#8083A3"
              />
            </svg>
          </div>
          <div className="right_side_bar_body">
            <div className="right_sidebar_body" id="Motif">
              <div className="right_sidebar_body_item">
                <label>Date</label>
                <div className="input_and_icon">
                  <input
                    style={{ width: '100%' }}
                    value={initialValues.date}
                    name="date"
                    onChange={this.props.handleChangeInput}
                    className="form-control"
                    type="date"
                    placeholder="John Doe"
                  />
                </div>
              </div>
              <div className="right_sidebar_body_item">
                <span className="creneau_container_title">Heure début</span>
                <div
                  className={
                    hasError &&
                    (!initialValues.startHours ||
                      (initialValues.startHours &&
                        initialValues.endHours &&
                        moment(
                          moment(
                            `${initialValues.date} ${initialValues.startHours}`,
                          ).format('YYYY-MM-DD HH:mm:ss'),
                        ).isSameOrAfter(
                          moment(
                            `${initialValues.date} ${initialValues.endHours}`,
                          ).format('YYYY-MM-DD HH:mm:ss'),
                        )))
                      ? 'creneau_container type3'
                      : 'creneau_container'
                  }
                  style={{ maxHeight: '300px', overflow: 'auto' }}
                >
                  <div className="input_and_icon" style={{ width: '100%' }}>
                    <input
                      style={{ width: '100%' }}
                      value={initialValues.startHours}
                      name="startHours"
                      onChange={this.props.handleChangeInput}
                      className="form-control"
                      type="time"
                      placeholder="John Doe"
                    />
                  </div>
                </div>
              </div>
              <div className="right_sidebar_body_item">
                <span className="creneau_container_title">Heure fin</span>
                <div
                  className={
                    hasError &&
                    initialValues.endHours &&
                    moment(
                      moment(`${initialValues.date} ${initialValues.startHours}`).format(
                        'YYYY-MM-DD HH:mm:ss',
                      ),
                    ).isAfter(
                      moment(`${initialValues.date} ${initialValues.endHours}`).format(
                        'YYYY-MM-DD HH:mm:ss',
                      ),
                    )
                      ? 'creneau_container type3'
                      : 'creneau_container'
                  }
                  style={{ maxHeight: '300px', overflow: 'auto' }}
                >
                  <div style={{ width: '100%' }} className="input_and_icon">
                    <input
                      style={{ width: '100%' }}
                      value={initialValues.endHours}
                      name="endHours"
                      onChange={this.props.handleChangeInput}
                      className="form-control"
                      type="time"
                      placeholder="John Doe"
                    />
                  </div>
                </div>
              </div>
              {/*{absenceHours.length > 0 ? (
                <div className="right_sidebar_body_item">
                  <span style={{ color: 'red' }} className="creneau_container_title">
                    Collaborateur est absent
                  </span>
                </div>
              ) : (
                ''
              )}*/}
            </div>
            <div className="right_sidebar_footer confirm_btn_container">
              <button
                onClick={async () => {
                  await this.props.duplicateRDVForm(params, initialValues, user.id);
                  //this.props.changeActiveItemSideBarRDV(true);
                }}
                className="confirm_recap_info_btn"
              >
                {isLoadingDuplicateRdv ? (
                  <CircularProgress
                    style={{
                      marginLeft: '4%',
                      width: '35px',
                      height: '25px',
                      color: 'white',
                      animation: 'infinite',
                    }}
                  />
                ) : (
                  'Confirmer'
                )}
              </button>
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    );
  }
}

export default SwipeableDrawerCreateDuplicate;
