const unitialUserState = {
  listBilan: [],
  loadingSectionBilan: true,
  section_bilan: {},
  loadingBilan: false,
  addBilan: false,
  loadingAddBilan: false,
  params: {
    search: '',
    currentPage: 1,
    limit: 100,
  },
};

const BilanReducer = (state = unitialUserState, action) => {
  switch (action.type) {
    case 'INITIAL_STATE': {
      return {
        ...state,
        addBilan: false,
      };
    }

    case 'GET_SECTION_BILAN_LIST_SUCCESS': {
      return {
        ...state,
        section_bilan: action.data,
        loadingSectionBilan: false,
      };
    }

    case 'GET_SECTION_BILAN_REQUESTING': {
      return {
        ...state,
        loadingSectionBilan: true,
      };
    }

    case 'GET_SECTION_BILAN_LIST_FAILURE': {
      return {
        ...state,
        // section_bilan: {},
        loadingSectionBilan: false,
      };
    }
    case 'SUBMIT_FORM_BILAN_REQUESTING': {
      return {
        ...state,
        loadingAddBilan: true,
        addBilan: false,
        //section_bilan: {},
      };
    }
    case 'SUBMIT_FORM_BILAN_SUCCESS': {
      return {
        ...state,
        addBilan: true,
        loadingAddBilan: false,
        //section_bilan: {},
      };
    }
    case 'SUBMIT_FORM_BILAN_FAILURE': {
      return {
        ...state,
        addBilan: false,
        loadingAddBilan: false,
        // section_bilan: null,
      };
    }

    case 'GET_BILAN_LIST_SUCCESS': {
      return {
        ...state,
        listBilan: action.data.data,
        loadingBilan: false,
      };
    }
    case 'GET_BILAN_LIST_FAILURE': {
      return {
        ...state,
        listBilan: [],
        loadingBilan: false,
      };
    }
    case 'GET_BILAN_REQUESTING': {
      return {
        ...state,
        loadingBilan: true,
        section_bilan: {},
      };
    }
    case 'UPDATE_SEARCH_BILAN_PARAMS': {
      return {
        ...state,
        params: action.params,
      };
    }

    default: {
      return state;
    }
  }
};

export default BilanReducer;
