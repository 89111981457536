import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import '../index.css';
import { connect } from 'react-redux';
const mapStateToProps = (state) => ({
  addAccount: state.RegisterReducer.addAccount,
});
class SuccessSignUp extends PureComponent {
  render() {
    const { addAccount } = this.props;
    return (
      <div className="container_changeable_content">
        <div className="fatcure_detailed_info_section">
          <div
            style={{ marginTop: '7%' }}
            className="empty_consultation_result_container"
          >
            <div className="patient_detailed_info_section_body">
              <div className="empty_consultation_result">
                <img src={require('../../../assets/img/merci.svg')} />
                <span id="no_consultation">Merci</span>
                <span id="no_consultation_desc">
                  Un e-mail de validation de compte a été envoyé.
                </span>
                <Link to="/login">
                  <button id="create_account_btn">Se connecter</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(SuccessSignUp);
