import * as React from 'react';
import { TableBorder, TableCell } from './TableCell';
import { DataTableCell } from './DataTableCell';
import { View } from '@react-pdf/renderer';
import { getDefaultBorderIncludes } from './Utils';
import { TableBodyProps } from './TableBody';

/**
 * This component describes how to display a row.
 */
export class TableRow extends React.PureComponent {
  render() {
    const rowCells = React.Children.toArray(this.props.children);
    const {
      includeLeftBorder,
      includeBottomBorder,
      includeRightBorder,
      includeTopBorder,
    } = getDefaultBorderIncludes(this.props);

    let remainingWeighting = 1;
    let numberOfWeightingsDefined = 0;
    rowCells.forEach((i) => {
      if (i.props.weighting !== undefined) {
        remainingWeighting -= i.props.weighting;
        numberOfWeightingsDefined++;
      }
    });

    const weightingsPerNotSpecified = Math.ceil(
      remainingWeighting / (rowCells.length - numberOfWeightingsDefined),
    );

    return (
      <View
        // @ts-ignore
        style={{
          borderBottom: includeBottomBorder && '1pt solid #222222',
          borderRight: includeRightBorder && '1pt solid black',
          borderTop: includeTopBorder && '1pt solid black',
          borderLeft: includeLeftBorder && '1pt solid black',
          width: '100%',
          display: 'flex',
          textAlign: 'left',
          flexDirection: 'row',
          justifyContent: 'stretch',
        }}
      >
        {rowCells.map((rc, columnIndex) =>
          React.cloneElement(rc, {
            weighting: rc.props.weighting ?? weightingsPerNotSpecified,
            data: this.props.data,
            key: columnIndex,
            fontSize: this.props.fontSize,
            textAlign: this.props.textAlign,
            includeLeftBorder: columnIndex === 0,
            includeRightBorder: columnIndex !== rowCells.length - 1,
          }),
        )}
      </View>
    );
  }
}
