import React, { Component } from 'react';
import FormMission from '../containers/form-mission';
import actionsJs from '../../../js/actions';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

const mapStateToProps = (state) => ({
  isLoadingMission: state.AppReducer.isLoadingMission,
  mission: state.AppReducer.mission,
  actionMission: state.AppReducer.actionMission,
  switchList: state.AppReducer.switchList,
  activeItemSideBarSETTINGS: state.AppReducer.activeItemSideBarSETTINGS,
  create: state.AppReducer.create,
});

const mapDispatchToProps = (dispatch) => ({
  getMission: (id) => dispatch(actionsJs.getMission(id)),
});
class MissionsManagement extends Component {
  constructor(props) {
    super(props);
    props.changeNamePage('Gestion des missions');
    document.title = 'Gestion des missions';
    this.state = {
      create: false,
      switchList: false,
      employee_selected: null,
    };
    this.handleSelectItem = this.handleSelectItem.bind(this);
  }
  handleSelectItem() {
    this.setState({
      switchList: false,
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { actionMission, activeItemSideBarSETTINGS } = this.props;
    if (actionMission && actionMission !== prevProps.actionMission && actionMission) {
      this.props.changeActiveItemSideBarSettings(activeItemSideBarSETTINGS, false);
      this.setState({
        create: false,
      });
    }
  }
  render() {
    const {
      switchList,
      activeItemSideBarSETTINGS,
      sandwich_menu_btn,
      isLoadingMissions,
      isLoadingMission,
      missions,
      mission,
      create,
      doctors,
    } = this.props;
    console.log(missions);
    return (
      <div
        className="general_info"
        style={{ marginTop: 5, width: '87%', height: '100%' }}
      >
        {switchList ? (
          isLoadingMission ? (
            <CircularProgress
              disableShrink
              style={{ marginLeft: '33%', marginTop: '20%' }}
            />
          ) : (
            <div className="" style={{ width: '100%' }}>
              <div className="container_changeable_content" style={{ height: '100%' }}>
                <div className="patient_detailed_info_section">
                  <svg
                    onClick={async () => {
                      this.props.changeActiveItemSideBarSettings(
                        activeItemSideBarSETTINGS,
                        false,
                      );
                    }}
                    width="46"
                    height="46"
                    viewBox="0 0 46 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ cursor: 'pointer' }}
                  >
                    <rect
                      x="0.5"
                      y="45.5"
                      width="45"
                      height="45"
                      rx="9.5"
                      transform="rotate(-90 0.5 45.5)"
                      fill="white"
                      stroke="#ECEEF5"
                    ></rect>
                    <path
                      d="M20.0117 23L24.5117 18.5L25.5664 19.5547L22.1211 23L25.5664 26.4453L24.5117 27.5L20.0117 23Z"
                      fill="#8083A3"
                    ></path>
                  </svg>
                  <FormMission
                    goBack={async () => {
                      this.props.changeActiveItemSideBarSettings(
                        activeItemSideBarSETTINGS,
                        false,
                      );
                    }}
                    mission={create ? null : mission}
                    handleSelectItem={this.handleSelectItem}
                    sandwich_menu_btn={sandwich_menu_btn}
                    doctors={doctors}
                  />
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="contact_patients_body" style={{ height: '100%' }}>
            <div style={{ display: 'flex', gap: 10 }}>
              <svg
                onClick={async () => {
                  this.props.goBackItem('showMissionManagement');
                }}
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
              >
                <rect
                  x="0.5"
                  y="45.5"
                  width="45"
                  height="45"
                  rx="9.5"
                  transform="rotate(-90 0.5 45.5)"
                  fill="white"
                  stroke="#ECEEF5"
                ></rect>
                <path
                  d="M20.0117 23L24.5117 18.5L25.5664 19.5547L22.1211 23L25.5664 26.4453L24.5117 27.5L20.0117 23Z"
                  fill="#8083A3"
                ></path>
              </svg>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 10,
                }}
              >
                <span className="patients_consultations_record_label">
                  Liste des missions
                </span>
              </div>
            </div>
            <div className="contact_patients_body_item">
              <div style={{ width: '100%' }} className="patient_contact_info_container">
                {' '}
                <div className="rv_text_details" style={{ width: '29%' }}>
                  <span
                    className=""
                    id="informations_generales_title"
                    style={{ paddingLeft: 20, color: 'black' }}
                  >
                    Libel
                  </span>
                </div>
                <div style={{ width: '7%' }} className="rv_text_details">
                  <span style={{ paddingLeft: 0 }}>Duration</span>
                </div>
                <div style={{ width: '20%' }} className="rv_text_details">
                  <span style={{ paddingLeft: 0 }}>Motif</span>
                </div>
                <div style={{ width: '15%' }} className="rv_text_details">
                  <span style={{ paddingLeft: 0 }}>Actions</span>
                </div>
              </div>
            </div>
            {isLoadingMissions ? (
              <CircularProgress
                disableShrink
                style={{ marginLeft: '33%', marginTop: '20%' }}
              />
            ) : missions && missions.length > 0 ? (
              missions.map((item) => {
                return (
                  <div key={item.id} className="contact_patients_body_item">
                    <div
                      style={{ borderLeft: `3px solid ${item.color}`, width: '100%' }}
                      className="patient_contact_info_container"
                    >
                      <div
                        className="patient_contact_img_container"
                        style={{ width: '60px', height: '60px' }}
                      >
                        <i className="fa fa-laptop-house"></i>
                      </div>
                      <div className="text" style={{ width: '25%' }}>
                        <span
                          className="title"
                          id="informations_generales_title"
                          style={{ color: 'black' }}
                        >
                          {item.libel}
                        </span>
                        <span className="desc">
                          À partir de {moment(item.date).format('DD/MM/YYYY')}
                        </span>
                      </div>
                      <div
                        style={{ width: '5%', justifyContent: 'flex-start' }}
                        className={
                          item.status
                            ? 'rv_text_details rv_list_body_sub_item btn_status'
                            : 'rv_text_details rv_list_body_sub_item btn_status actif_status'
                        }
                      >
                        <span style={{ paddingLeft: 0 }}>{item.duration}</span>
                      </div>
                      <div style={{ width: '20%' }} className="rv_text_details">
                        <span style={{ paddingLeft: 0 }}>{item.motif}</span>
                      </div>
                      <div style={{ width: '15%' }} className="rv_text_details">
                        <div
                          onClick={async () => {
                            await this.props.getMission(item.id);
                            this.props.changeActiveItemSideBarSettings(
                              activeItemSideBarSETTINGS,
                              true,
                            );
                          }}
                          className="more_icon_container"
                          style={{ marginRight: '20px' }}
                        >
                          <i
                            style={{
                              padding: '0',
                              border: '',
                              color: '',
                              borderRadius: '',
                              backgroundColor: 'white',
                            }}
                            className="fas fa-edit"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                className="consultation_detailed_info_section"
                style={{
                  height: 'auto',
                  marginTop: '10%',
                }}
              >
                <div className="empty_consultation_result_container">
                  <div className="empty_consultation_result">
                    <img
                      src={require('../../../assets/img/undraw_design_team_af2y.svg')}
                    />
                    <span id="no_consultation">Liste vide !</span>
                    <span id="no_consultation_desc">
                      Veuillez ajouter des nouvelles missions à faire ou déjà faites.
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MissionsManagement);
