import React, { Component } from 'react';
import { SelectorItemContainer } from '../utils';
export default class SettingMenuItemsProfil extends Component {
  componentDidMount() {
    SelectorItemContainer();
  }

  render() {
    const { imgColor, img, icon, activate, name, description, HandelChangePage, item } =
      this.props;
    return (
      <div className="setting_menu_items">
        <div
          className={activate ? 'item_container active_item_container' : 'item_container'}
          //id="informations_generales"
          onClick={() => HandelChangePage(item)}
        >
          <div className="icon">
            {icon ? (
              <i className={icon}></i>
            ) : (
              <img
                className={activate ? 'active_icon' : ''}
                src={activate ? imgColor : img}
                width="20px"
                height="20px"
                alt=""
              />
            )}
          </div>
          {/* <div className="icon">
            <i  className={icon}></i>
          </div> */}
          <div className="text">
            <span className="title" id="informations_generales_title">
              <a href="#">{name}</a>
            </span>
            <span className="desc">{description}</span>
          </div>
        </div>
      </div>
    );
  }
}
