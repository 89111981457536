/* @flow */
import { getAppErrorCode } from '../../../utils/helpres';

const initialAppState = {
  error: undefined,
  deleteDiplome: false,
  deleteFormation: false,
  changePassword: false,
  password: {},
  diplome: false,
  formation: false,
  message: '',
  motifs: [],
  isLoadingMotifs: false,
  isLoadingActionDiploma: false,
  isLoadingVerifyPassword: false,
  verifyPassword: false,
  newMotif: false,
  deleteMotif: false,
};

const ProfileReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'VERIFY_PASSWORD_REQUESTING':
      return {
        ...state,
        isLoadingVerifyPassword: true,
        verifyPassword: false,
      };
    case 'VERIFY_PASSWORD_SUCCESS':
      return {
        ...state,
        isLoadingVerifyPassword: false,
        verifyPassword: true,
      };
    case 'VERIFY_PASSWORD_FAILURE':
      return {
        ...state,
        isLoadingVerifyPassword: false,
        verifyPassword: false,
      };

    case 'UPDATE_PASSWORD_REQUESTING':
      return {
        ...state,
        isLoadingChangePassword: true,
        changePassword: false,
      };
    case 'UPDATE_PASSWORD_SUCCESS':
      return {
        ...state,
        isLoadingChangePassword: false,
        isAuthorized: true,
        changePassword: true,
        password: action.data.data,
        message: action.data.message,
      };
    case 'UPDATE_PASSWORD_FAILURE':
      return {
        ...state,
        isAuthorized: false,
        changePassword: false,
        isLoadingChangePassword: false,
        message: action.err,
        //error: getAppErrorCode(action.status),
      };

    case 'ACTION_DIPLOME_REQUESTING':
      return {
        ...state,
        isLoadingActionDiploma: true,
        isLoadingSpecialities: false,
        // isAuthorized: true,
      };
    case 'ACTION_DIPLOME_SUCCESS':
      return {
        ...state,
        isLoadingSpecialities: false,
        isLoadingActionDiploma: false,
        //  isAuthorized: true,
        diplome: true,
      };
    case 'ACTION_DIPLOME_FAILURE':
      return {
        ...state,
        isLoadingActionDiploma: false,
        diplome: false,
        // isAuthorized: false,
        // error: getAppErrorCode(action.status),
      };
    case 'ACTION_FORMATION_SUCCESS':
      return {
        ...state,
        isLoadingSpecialities: false,
        // isAuthorized: true,
        formation: true,
      };
    case 'ACTION_FORMATION_FAILURE':
      return {
        ...state,
        formation: false,
        // isAuthorized: false,
        message: action.err,
        //error: getAppErrorCode(action.status),
      };
    case 'ACTION_DELETE_DIPLOME_SUCCESS':
      return {
        ...state,
        isLoadingSpecialities: false,
        //isAuthorized: true,
        deleteDiplome: true,
        message: action.data.message,
      };
    case 'ACTION_DELETE_DIPLOME_FAILURE':
      return {
        ...state,
        isAuthorized: false,
        message: action.err,
        error: true,
      };
    case 'ACTION_DELETE_FORMATION_SUCCESS':
      return {
        ...state,
        isLoadingSpecialities: false,
        isAuthorized: true,
        deleteFormation: true,
        message: action.data.message,
      };
    case 'ACTION_DELETE_FORMATION_FAILURE':
      return {
        ...state,
        isAuthorized: false,
        message: action.err,
        //   error: getAppErrorCode(action.status),
      };
    case 'ACTION_DELETE_MOTIF_SUCCESS':
      return {
        ...state,
        deleteMotif: true,
        message: action.data.message,
      };
    case 'ACTION_DELETE_MOTIF_FAILURE':
      return {
        ...state,
        message: action.err,
        // error: getAppErrorCode(action.status),
      };
    case 'GET_MOTIFS_REQUESTING': {
      return {
        ...state,
        isLoadingMotifs: true,
        newMotif: false,
      };
    }
    case 'GET_MOTIFS_SUCCESS': {
      return {
        ...state,
        isLoadingMotifs: false,
        motifs: action.data,
        newMotif: false,
      };
    }
    case 'GET_MOTIFS_FAILURE': {
      return {
        ...state,
        isLoadingMotifs: false,
        message: action.err,
        newMotif: false,
        error: getAppErrorCode(action.status),
      };
    }
    case 'ACTION_MOTIF_FAILURE': {
      return {
        ...state,
        newMotif: false,
      };
    }
    case 'ACTION_MOTIF_SUCCESS': {
      return {
        ...state,
        newMotif: true,
      };
    }
    default:
      return state;
  }
};

export default ProfileReducer;
