import React from 'react';
//import {Trans} from '@lingui/macro';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import ToastAction from '../../../../components/ToastAction';
import { getStatusError } from '../../../../utils/helpres';

const InitialState = () => async (dispatch) => {
  dispatch({
    type: 'INITIAL_STATE_SIGN_UP',
  });
};

const submitUserForm = (values) => async (dispatch) => {
  dispatch({
    type: 'CREATE_NEW_USER_REQUESTING',
  });
  const { first_name, last_name, email, password } = values;
  let val = {
    first_name,
    last_name,
    email,
    password,
  };
  if (!values.id) val.roleId = 3;
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_PUBLIC_URL}/api/practitioner/`,
      val,
    );
    const response = data;
    const incompleteUser = !!(
      response.data.firstName === '' ||
      response.data.lastName === '' ||
      response.data.email === '' ||
      response.data.password === ''
    );
    if (response.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Compte créé avec succès" />
        ),
        { hideProgressBar: true },
      );
      // toast.success(`User a été ajouté`, {position: toast.POSITION.TOP_CENTER});
      if (incompleteUser)
        toast(
          ({ closeToast }) => <ToastAction type="warning" message="Profil incomplet !" />,
          { hideProgressBar: true },
        );
      //toast.warn(`User est incomplet !`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'CREATE_NEW_USER_SUCCESS',
        msg: response.message,
        data: response.data,
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction type="error" message="Échec de création de compte" />
        ),
        { hideProgressBar: true },
      );
      //toast.error(`User n'est pas ajouté`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'CREATE_NEW_USER_FAILURE',
        msg: response.data,
        ErrorMessage: response.message,
        site: values,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'CREATE_NEW_USER_FAILURE',
        msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
        err: data.data || data.type,
        data: values,
      });
    }
  }
};

const submitAccountRequestForm = (values) => async (dispatch) => {
  dispatch({
    type: 'CREATE_NEW_ACCOUNT_REQUEST_REQUESTING',
  });
  const { first_name, last_name, email, city, code_promo, phone } = values;
  let val = {
    first_name,
    last_name,
    email,
    city,
    speciality: 'kinésithérapeute',
    phone,
    code_promo,
  };
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_PUBLIC_URL}/api/account_requests/`,
      val,
    );
    const response = data;
    if (response.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Compte enregistré avec succès" />
        ),
        { hideProgressBar: true },
      );
      dispatch({
        type: 'CREATE_NEW_ACCOUNT_REQUEST_SUCCESS',
        msg: response.message,
        data: response.data,
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction type="error" message="Échec de création de compte" />
        ),
        { hideProgressBar: true },
      );
      //toast.error(`User n'est pas ajouté`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'CREATE_NEW_ACCOUNT_REQUEST_FAILURE',
        msg: response.data,
        ErrorMessage: response.message,
        site: values,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response) {
      const { data } = response;
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'CREATE_NEW_ACCOUNT_REQUEST_FAILURE',
        msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
        err: data.data || data.type,
        data: values,
      });
    }
  }
};

const getDoctorByEmail = (email) => async (dispatch) => {
  dispatch({
    type: 'GET_DOCTOR_BY_EMAIL_USER_REQUESTING',
  });
  try {
    const url = `${process.env.REACT_APP_PUBLIC_URL}/api/get/practitioner/email`;
    const { data } = await axios.post(url, { email });
    dispatch({ type: 'GET_DOCTOR_BY_EMAIL_USER_SUCCESS', data: data });
  } catch (err) {
    const { response } = err;
    const { data } = response;
    toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
      hideProgressBar: true,
    });
    dispatch({
      type: 'GET_DOCTOR_BY_EMAIL_USER_FAILURE',
      err: data.message,
      status: getStatusError(err),
    });
  }
};
export default {
  submitUserForm,
  submitAccountRequestForm,
  getDoctorByEmail,
  InitialState,
};
