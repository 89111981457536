import axios from 'axios';
import { getStatusError } from '../utils/helpres';
import decode from 'jwt-decode';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastAction from '../components/ToastAction';
import React from 'react';
import moment from 'moment-timezone';

const ERROR_MAINTENANCE = 503;

const submitUserForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_USER_REQUESTING' });
  const {
    payment_method,
    street_number,
    address_practitioner,
    first_name,
    color,
    last_name,
    email,
    password,
    phone,
    speciality_id,
    date_of_birth,
    hiring_date,
    departure_date,
    type_of_contract,
    contract_length,
    salary,
    hour_from,
    payment_date,
    iban,
    hour_to,
    cv,
    note,
    statut,
    bank,
    rib,
    cnss,
    gsm,
    tax_identification,
    conventional_code,
    name_cabinet,
    login,
  } = values;
  let request = {
    date_of_birth,
    hiring_date,
    departure_date,
    type_of_contract,
    contract_length,
    salary,
    hour_from,
    payment_date,
    iban,
    hour_to,
    cv,
    payment_method,
    street_number,
    first_name,
    address_practitioner: address_practitioner || {},
    last_name,
    note,
    email,
    password,
    speciality_id: parseInt(speciality_id),
    statut,
    phone: phone,
    bank,
    color,
    login,
    gsm,
    rib,
    cnss,
    tax_identification,
    conventional_code,
    name_cabinet,
  };
  if (request.password === '') delete request.password;
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/practitioner/${values.id || ''}`,
      data: request,
    });
    const incompleteUser = !!(
      data.first_name === '' ||
      data.last_name === '' ||
      data.email === ''
    );
    if (!data.error) {
      if (values.file) {
        const formData = new FormData();
        formData.append('file', values.file);
        formData.append('type', 'profile');
        formData.append('practitioner_id', data.data.id);
        formData.append('name_action', 'doctors');
        await apiCall({
          method: 'post',
          url: `/api/upload`,
          data: formData,
        });
      }
      if (values.fileCover) {
        const formData = new FormData();
        formData.append('file', values.fileCover);
        formData.append('name_action', 'doctors');
        formData.append('type', 'cover');
        formData.append('practitioner_id', data.data.id);
        await apiCall({
          method: 'post',
          url: `/api/upload`,
          data: formData,
        });
      }
      if (values.cv && values.cv.name) {
        const formData = new FormData();
        formData.append('file', values.cv);
        formData.append('type', 'cv');
        formData.append('name_action', 'doctors');
        formData.append('practitioner_id', data.data.id);
        await apiCall({
          method: 'post',
          url: `/api/upload`,
          data: formData,
        });
      }
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={`Profil ${
              !values.type ? (values.id ? 'modifié avec succès' : 'créé avec succès') : ''
            } `}
          />
        ),
        { hideProgressBar: true },
      );
      //  toast.success(`Profil a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      if (incompleteUser)
        toast(
          ({ closeToast }) => (
            <ToastAction type="warning" message="Profil est incomplet !" />
          ),
          { hideProgressBar: true },
        );
      //toast.warn(`Profil est incomplet !`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_USER_SUCCESS',
        data: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_USER_FAILURE',
        ErrorMessage: data.message,
        data: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    if (data.message) {
      toast(
        ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
        { hideProgressBar: true },
      );
    } else
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message={`Profil non ${
              !values.type ? (values.id ? 'modifié' : 'ajouté') : ''
            } `}
          />
        ),
        { hideProgressBar: true },
      );
    // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'UPDATE_USER_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: values,
      status: getStatusError(e),
    });
  }
};
const submitEmployeeManagementForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_Employee_Management_REQUESTING' });
  const {
    payment_method,
    street_number,
    address_practitioner,
    first_name,
    color,
    last_name,
    email,
    password,
    phone,
    speciality_id,
    date_of_birth,
    hiring_date,
    departure_date,
    type_of_contract,
    contract_length,
    salary,
    hour_from,
    payment_date,
    iban,
    hour_to,
    cv,
    note,
    statut,
    bank,
    rib,
    cnss,
    gsm,
    tax_identification,
    conventional_code,
    name_cabinet,
    login,
  } = values;
  let request = {
    date_of_birth,
    hiring_date,
    departure_date,
    type_of_contract,
    contract_length,
    salary,
    hour_from,
    payment_date,
    iban,
    hour_to,
    cv,
    payment_method,
    street_number,
    first_name,
    address_practitioner: address_practitioner || {},
    last_name,
    note,
    email,
    password,
    speciality_id: parseInt(speciality_id),
    statut,
    phone: phone,
    bank,
    color,
    login,
    gsm,
    rib,
    cnss,
    tax_identification,
    conventional_code,
    name_cabinet,
  };
  if (request.password === '') delete request.password;
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/employee-management/${values.id ? 'update' : 'create'}/${
        values.id || ''
      }`,
      data: request,
    });
    const incompleteUser = !!(
      data.first_name === '' ||
      data.last_name === '' ||
      data.email === ''
    );
    if (!data.error) {
      if (values.file) {
        const formData = new FormData();
        formData.append('file', values.file);
        formData.append('type', 'profile');
        formData.append('practitioner_id', data.data.id);
        formData.append('name_action', 'doctors');
        await apiCall({
          method: 'post',
          url: `/api/upload`,
          data: formData,
        });
      }
      if (values.fileCover) {
        const formData = new FormData();
        formData.append('file', values.fileCover);
        formData.append('name_action', 'doctors');
        formData.append('type', 'cover');
        formData.append('practitioner_id', data.data.id);
        await apiCall({
          method: 'post',
          url: `/api/upload`,
          data: formData,
        });
      }
      if (values.cv && values.cv.name) {
        const formData = new FormData();
        formData.append('file', values.cv);
        formData.append('type', 'cv');
        formData.append('name_action', 'doctors');
        formData.append('practitioner_id', data.data.id);
        await apiCall({
          method: 'post',
          url: `/api/upload`,
          data: formData,
        });
      }
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={`Profil ${
              !values.type ? (values.id ? 'modifié avec succès' : 'créé avec succès') : ''
            } `}
          />
        ),
        { hideProgressBar: true },
      );
      //  toast.success(`Profil a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      if (incompleteUser)
        toast(
          ({ closeToast }) => (
            <ToastAction type="warning" message="Profil est incomplet !" />
          ),
          { hideProgressBar: true },
        );
      //toast.warn(`Profil est incomplet !`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_Employee_Management_SUCCESS',
        data: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_Employee_Management_FAILURE',
        ErrorMessage: data.message,
        data: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    if (data.message) {
      toast(
        ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
        { hideProgressBar: true },
      );
    } else
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message={`Profil non ${
              !values.type ? (values.id ? 'modifié' : 'ajouté') : ''
            } `}
          />
        ),
        { hideProgressBar: true },
      );
    // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'UPDATE_Employee_Management_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: values,
      status: getStatusError(e),
    });
  }
};

const login = (valuse) => async (dispatch) => {
  dispatch({ type: 'LOGIN_REQUESTING' });
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_PUBLIC_URL}/api/login`,
      valuse,
    );
    const response = data.data;
    if (response.type === 'error')
      toast(({ closeToast }) => <ToastAction type="error" message={response.message} />, {
        hideProgressBar: true,
      });
    else {
      window.localStorage.setItem('token', response.token);
      window.localStorage.setItem('registrationTimestamp', Date.now());
      window.localStorage.setItem('email', response.user.email);
      window.localStorage.setItem('expiryMilliseconds', decode(response.token).exp);
      dispatch({ type: 'LOGIN_SUCCESS', data: response });
    }
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={response && response.data && response.data.message}
        />
      ),
      { hideProgressBar: true },
    );
    dispatch({
      type: 'LOGIN_FAILURE',
      err:
        (response && response.data && response.data.error) || (response && response.data),
      status: getStatusError(err),
    });
  }
};

const getRoles = () => async (dispatch) => {
  dispatch({ type: 'GET_ROLES_REQUESTING' });
  try {
    const url = `/api/roles`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_ROLES_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'GET_ROLES_FAILURE',
      err: response && response.data && response.data.error,
      status: getStatusError(err),
    });
  }
};
const getSecretary = (id) => async (dispatch) => {
  dispatch({ type: 'GET_SECRETARY_REQUESTING' });
  try {
    const url = `/api/secretary/get-one/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_SECRETARY_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_SECRETARY_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const getSecretaries = () => async (dispatch) => {
  dispatch({ type: 'GET_SECRETARIES_REQUESTING' });
  try {
    const url = `/api/secretary`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_SECRETARIES_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_SECRETARIES_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const getSpecialities = () => async (dispatch) => {
  dispatch({ type: 'GET_SPECIALITIES_REQUESTING' });
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_PUBLIC_URL}/api/specialities`,
    );
    dispatch({ type: 'GET_SPECIALITIES_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_SPECIALITIES_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};

const getUserById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_USER_REQUESTING' });
  try {
    const url = `/api/practitioner/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_USER_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_USER_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const getEmployerById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_Employer_REQUESTING' });
  try {
    const url = `/api/employee-management/get-one/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_Employer_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response)
      dispatch({
        type: 'GET_Employer_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};

const verifyAccount = (id) => async (dispatch) => {
  dispatch({ type: 'GET_VERIFY_ACCOUNT_REQUESTING' });
  try {
    const url = `/api/verifyAccount/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_VERIFY_ACCOUNT_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'GET_VERIFY_ACCOUNT_FAILURE',
      err: response && response.data && response.data.error,
      status: getStatusError(err),
    });
  }
};

const getMe = () => async (dispatch) => {
  dispatch({ type: 'GET_ME_REQUESTING' });
  try {
    const url = `/api/getMe`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_ME_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'GET_ME_FAILURE',
      err: response && response.data && response.data.error,
      status: getStatusError(err),
    });
  }
};

const notificationEnabled = (values) => async (dispatch) => {
  dispatch({ type: 'ENABLE_NOTIFICATION_REQUESTING' });
  try {
    const url = `/api/notification-enabled/${values.id}`;
    const { data } = await apiCall({
      method: 'patch',
      url,
      data: values,
    });
    dispatch({ type: 'ENABLE_NOTIFICATION_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    dispatch({
      type: 'ENABLE_NOTIFICATION_FAILURE',
      err: response && response.data && response.data.error,
      status: getStatusError(err),
    });
  }
};

const isTokenExpired = (token) => {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const apiCall = async (params) => {
  const token = window.localStorage.getItem('token');
  // Clear Authentication cache for IE
  // </AxiosResponse>document.execCommand('ClearAuthenticationCache', false);
  if (!token || isTokenExpired(token)) {
    // try for a new token
    return axios
      .post(
        `${process.env.REACT_APP_PUBLIC_URL}/api/refreshToken`,
        { token },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        },
      )
      .then(({ data }) => {
        const response = data.data;
        window.localStorage.setItem('user', JSON.stringify(response.user));
        window.localStorage.setItem('token', response.token);
        window.localStorage.setItem('email', response.user.email);
        window.localStorage.setItem('registrationTimestamp', Date.now());
        window.localStorage.setItem('expiryMilliseconds', decode(response.token).exp);
        const newExtendedParams = {
          ...params,
          url: `${process.env.REACT_APP_PUBLIC_URL + params.url}`,
          headers: {
            ...params.headers,
            Authorization: `JWT ${response.token}`,
          },
        };
        return newExtendedParams;
      })
      .then((newExtendedParams) => axios(newExtendedParams))
      .catch((apiError) => {
        if (apiError.response && apiError.response.status === ERROR_MAINTENANCE) {
          document.location.reload();
        }
        return Promise.reject(apiError);
      });
  }
  const extendedParams = {
    ...params,
    url: `${process.env.REACT_APP_PUBLIC_URL + params.url}`,
    headers: {
      ...params.headers,
      Authorization: `JWT ${token}`,
    },
  };
  return axios(extendedParams).catch((apiError) => {
    if (apiError.response && apiError.response.status === ERROR_MAINTENANCE) {
      document.location.reload();
    }
    return Promise.reject(apiError);
  });
};

const getMessage = () => async (dispatch) => {
  dispatch({ type: 'GET_MESSAGES_REQUESTING' });
  try {
    let url = `/api/messages`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_MESSAGES_SUCCESS',
      data: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_MESSAGES_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getEmail = (email) => async (dispatch) => {
  dispatch({ type: 'GET_EMAIL_USER_REQUESTING' });
  try {
    const url = `${process.env.REACT_APP_PUBLIC_URL}/api/get-email`;
    const { data } = await axios.post(url, { email });
    dispatch({ type: 'GET_EMAIL_USER_SUCCESS', data: data });
  } catch (err) {
    const { response } = err;
    const { data } = response;
    toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
      hideProgressBar: true,
    });
    dispatch({
      type: 'GET_EMAIL_USER_FAILURE',
      err: data.message,
      status: getStatusError(err),
    });
  }
};

const changeSandwichMenuBtn = () => ({
  type: 'UPDATE_SandwichMenuBtn',
});
const changeCountMessage = (data) => ({
  type: 'UPDATE_COUNT_MESSAGE_SEEN',
  countMessageIsSeen: data,
});
const changeNamePage = (data) => ({
  type: 'UPDATE_NAME_PAGE',
  namePage: data,
});

const changeTag = (tag) => ({
  type: 'UPDATE_TAG',
  tag,
});

const changeActiveItemSideBar = (data) => ({
  type: 'UPDATE_ACTIVE_ITEM_SIDE_BAR',
  activeItemSideBar: data,
});
const changeActiveItemSideBarSettings = (data, item, create) => ({
  type: 'UPDATE_ACTIVE_ITEM_SIDE_BAR_SETTINGS',
  activeItemSideBarSETTINGS: data,
  switchList: item,
  create: create,
});
const changeActiveItemSideBarRDV = (activeItemSideBarRDV) => ({
  type: 'ACTIVE_ITEM_SIDE_BAR_RDV',
  activeItemSideBarRDV,
});

const keepMySessionOpen = (value) => async (dispatch) => {
  dispatch({
    type: 'keep_my_session_open',
    keep_my_session_open: value,
  });
};

const submitSecretaryForm = (values, type) => async (dispatch) => {
  dispatch({ type: 'UPDATE_SECRETARY_REQUESTING' });
  const {
    first_name,
    last_name,
    email,
    password,
    phone,
    note,
    statut,
    bank,
    rib,
    cnss,
    gsm,
    name_cabinet,
    payment_method,
    street_number,
    country,
    zip_code,
    date_of_birth,
    hiring_date,
    departure_date,
    type_of_contract,
    contract_length,
    salary,
    hour_from,
    payment_date,
    iban,
    hour_to,
    login,
    cv,
    city,
    street,
    file,
    function_,
  } = values;
  let request = {
    payment_method: payment_method || '',
    function: function_ || '',
    street_number,
    country,
    login,
    zip_code,
    date_of_birth: date_of_birth ? date_of_birth : null,
    hiring_date: hiring_date || '',
    file,
    departure_date: departure_date || '',
    type_of_contract: type_of_contract || '',
    contract_length: contract_length || '',
    salary: salary || '',
    hour_from: hour_from || '',
    payment_date: payment_date || '',
    iban: iban || '',
    hour_to: hour_to || '',
    cv,
    city,
    street,
    first_name,
    last_name,
    note,
    email,
    password,
    statut,
    phone: phone,
    bank: bank || '',
    gsm,
    rib: rib || '',
    cnss,
    name_cabinet,
  };

  //if (request.password === '') delete request.password;
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/${type === 'secretary' ? 'secretary' : 'assistant'}/${
        values.id ? 'update' : 'create'
      }/${values.id || ''}`,
      data: request,
    });
    const incompleteUser = !!(
      data.first_name === '' ||
      data.last_name === '' ||
      data.email === ''
    );
    if (data.type === 'success') {
      if (values.file) {
        const formData = new FormData();
        formData.append('file', values.file);
        formData.append('type', 'profile');
        formData.append('name_action', type);
        formData.append('practitioner_id', data.data.id);
        await apiCall({
          method: 'post',
          url: `/api/upload`,
          data: formData,
        });
      }
      if (values.cv && values.cv.name) {
        const formData = new FormData();
        formData.append('file', values.cv);
        formData.append('type', 'cover');
        formData.append('name_action', type);
        formData.append('practitioner_id', data.data.id);
        await apiCall({
          method: 'post',
          url: `/api/upload`,
          data: formData,
        });
      }
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={`Profil ${
              !values.type ? (values.id ? 'modifié avec succès' : 'créé avec succès') : ''
            } `}
          />
        ),
        { hideProgressBar: true },
      );
      //  toast.success(`Profil a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      if (incompleteUser)
        toast(
          ({ closeToast }) => (
            <ToastAction type="warning" message="Profil secretary est incomplet !" />
          ),
          { hideProgressBar: true },
        );
      //toast.warn(`Profil est incomplet !`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_SECRETARY_SUCCESS',
        data: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_SECRETARY_FAILURE',
        ErrorMessage: data.message,
        data: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    if (data.message) {
      toast(
        ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
        { hideProgressBar: true },
      );
    } else
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message={`Profil non ${
              !values.type ? (values.id ? 'modifié' : 'ajouté') : ''
            } `}
          />
        ),
        { hideProgressBar: true },
      );
    // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'UPDATE_SECRETARY_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: values,
      status: getStatusError(e),
    });
  }
};

const getAssistant = (id) => async (dispatch) => {
  dispatch({ type: 'GET_ASSISTANT_REQUESTING' });
  try {
    const url = `/api/assistant/get-one/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_ASSISTANT_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_ASSISTANT_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const getAssistants = () => async (dispatch) => {
  dispatch({ type: 'GET_ASSISTANTS_REQUESTING' });
  try {
    const url = `/api/assistant`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_ASSISTANTS_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_ASSISTANTS_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};

const getMissions = () => async (dispatch) => {
  dispatch({ type: 'GET_MISSIONS_REQUESTING' });
  try {
    const url = `/api/mission`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_MISSIONS_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_MISSIONS_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const getPermissions = () => async (dispatch) => {
  dispatch({ type: 'GET_PERMISSIONS_REQUESTING' });
  try {
    const url = `/api/permission`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_PERMISSIONS_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_PERMISSIONS_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const getPermissionsByUser = (id) => async (dispatch) => {
  dispatch({ type: 'GET_PERMISSIONS_BY_USER_REQUESTING' });
  try {
    const url = `/api/permission/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_PERMISSIONS_BY_USER_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_PERMISSIONS_BY_USER_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const getMotifsReference = () => async (dispatch) => {
  dispatch({ type: 'GET_MOTIF_REFERENCE_REQUESTING' });
  try {
    const url = `/api/motifs-reference`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_MOTIF_REFERENCE_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_MOTIF_REFERENCE_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const getMission = (id) => async (dispatch) => {
  dispatch({ type: 'GET_MISSION_REQUESTING' });
  try {
    const url = `/api/mission/get-one/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_MISSION_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_MISSION_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};

const submitMissionForm = (values, type) => async (dispatch) => {
  dispatch({ type: 'UPDATE_MISSION_REQUESTING' });
  const { libel, practitioner_id, date, duration, motif } = values;
  let request = {
    libel,
    date,
    practitioner_id: JSON.stringify(practitioner_id),
    duration,
    motif,
  };
  if (request.password === '') delete request.password;
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/mission/${values.id ? 'update' : 'create'}/${values.id || ''}`,
      data: request,
    });
    if (!data.error) {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={`Mission ${
              !values.type ? (values.id ? 'modifié avec succès' : 'créé avec succès') : ''
            } `}
          />
        ),
        { hideProgressBar: true },
      );
      dispatch({ type: 'UPDATE_MISSION_SUCCESS', data: data.data });
      //  toast.success(`Profil a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_MISSION_FAILURE',
        ErrorMessage: data.message,
        data: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    if (data.message) {
      toast(
        ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
        { hideProgressBar: true },
      );
    } else
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message={`Mission non ${
              !values.type ? (values.id ? 'modifié' : 'ajouté') : ''
            } `}
          />
        ),
        { hideProgressBar: true },
      );
    // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'UPDATE_MISSION_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: values,
      status: getStatusError(e),
    });
  }
};
const addHolidayUser = (values) => async (dispatch) => {
  dispatch({ type: 'ADD_HOLIDAY_USER_REQUESTING' });
  try {
    const url = `/api/holiday/create/holiday-user`;
    const { data } = await apiCall({
      method: 'post',
      url,
      data: values,
    });
    toast(
      ({ closeToast }) => (
        <ToastAction type="success" message="Modification effectuée avec succès" />
      ),
      {
        hideProgressBar: true,
      },
    );
    dispatch({ type: 'ADD_HOLIDAY_BY_USER_SUCCESS', data: data.data });
  } catch (err) {
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Modification non effectuée" />
      ),
      { hideProgressBar: true },
    );
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'ADD_HOLIDAY_BY_USER_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const addPermissionsUser = (values) => async (dispatch) => {
  dispatch({ type: 'ADD_PERMISSIONS_BY_USER_REQUESTING' });
  try {
    const url = `/api/permission/create/permission-user`;
    const { data } = await apiCall({
      method: 'post',
      url,
      data: values,
    });
    toast(
      ({ closeToast }) => (
        <ToastAction type="success" message="Permission modifiée avec succès" />
      ),
      { hideProgressBar: true },
    );
    dispatch({ type: 'ADD_PERMISSIONS_BY_USER_SUCCESS', data: data.data });
  } catch (err) {
    toast(
      ({ closeToast }) => <ToastAction type="error" message="Permission non modifiée" />,
      { hideProgressBar: true },
    );
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'ADD_PERMISSIONS_BY_USER_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const addGroupPermissionsUser = (values) => async (dispatch) => {
  dispatch({ type: 'ADD_GROUP_PERMISSIONS_BY_USER_REQUESTING' });
  try {
    const url = `/api/permission/create/permission-user/group`;
    const { data } = await apiCall({
      method: 'post',
      url,
      data: values,
    });
    toast(
      ({ closeToast }) => (
        <ToastAction type="success" message="Permission modifiée avec succès" />
      ),
      { hideProgressBar: true },
    );
    dispatch({ type: 'ADD_GROUP_PERMISSIONS_BY_USER_SUCCESS', data: data.data });
  } catch (err) {
    toast(
      ({ closeToast }) => <ToastAction type="error" message="Permission non modifiée" />,
      { hideProgressBar: true },
    );
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'ADD_GROUP_PERMISSIONS_BY_USER_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const ActivitedDeactivetedAccount = (values) => async (dispatch) => {
  dispatch({ type: 'ADD_ACTIVE_USER_REQUESTING' });
  try {
    const url = `/api/update/status/${values.id}`;
    const { data } = await apiCall({
      method: 'post',
      url,
      data: values,
    });
    toast(({ closeToast }) => <ToastAction type="success" message="Acces ajouter" />, {
      hideProgressBar: true,
    });
    dispatch({ type: 'ADD_ACTIVE_USER_SUCCESS', data: data.data });
  } catch (err) {
    toast(({ closeToast }) => <ToastAction type="error" message="Acces non ajouter" />, {
      hideProgressBar: true,
    });
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'ADD_ACTIVE_USER_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};

const getMedecin = (id) => async (dispatch) => {
  dispatch({ type: 'GET_MEDECIN_REQUESTING' });
  try {
    const url = `/api/prescribing-practitioner/get-one/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_MEDECIN_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_MEDECIN_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const getMedecins = () => async (dispatch) => {
  dispatch({ type: 'GET_MEDECINS_REQUESTING' });
  try {
    const url = `/api/prescribing-practitioner`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_MEDECINS_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_MEDECINS_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};

const submitMedecinForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_MEDECIN_REQUESTING' });
  const {
    consultation_id,
    first_name,
    street,
    zip_code,
    last_name,
    phone,
    speciality,
    country,
    city,
  } = values;
  let request = {
    consultation_id,
    first_name,
    street,
    zip_code,
    last_name,
    phone,
    speciality,
    country,
    city,
  };
  if (request.password === '') delete request.password;
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/prescribing-practitioner/${values.id ? 'update' : 'create'}/${
        values.id || ''
      }`,
      data: request,
    });
    if (!data.error) {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={`Prescribing doctor ${
              !values.type ? (values.id ? 'modifié avec succès' : 'créé avec succès') : ''
            } `}
          />
        ),
        { hideProgressBar: true },
      );
      dispatch({
        type: 'UPDATE_MEDECIN_SUCCESS',
        data: data,
      });
      //  toast.success(`Profil a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_MEDECIN_FAILURE',
        ErrorMessage: data.message,
        data: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    if (data.message) {
      toast(
        ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
        { hideProgressBar: true },
      );
    } else
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message={`Prescribing doctor non ${
              !values.type ? (values.id ? 'modifié' : 'ajouté') : ''
            } `}
          />
        ),
        { hideProgressBar: true },
      );
    // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'UPDATE_MEDECIN_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: values,
      status: getStatusError(e),
    });
  }
};

const getAbsensesByHours = (values) => async (dispatch) => {
  dispatch({ type: 'GET_ABSENCES_HOURS_REQUESTING' });
  try {
    let url = `/api/absence/hours/${values.id}`;

    const { data } = await apiCall({
      method: 'post',
      url,
      data: {
        date_end: values.date_end,
        date_start: values.date_start,
      },
    });

    dispatch({ type: 'GET_ABSENCES_HOURS_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_ABSENCES_HOURS_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};

const getAbsenses = (params, id) => async (dispatch) => {
  dispatch({ type: 'GET_ABSENCES_REQUESTING' });
  try {
    const { date } = params || {
      date: '',
    };
    const { date_to } = params || {
      date_to: '',
    };
    let url = `/api/absence/${id}`;
    if (date && !date_to) {
      url = `/api/absence/${id}?date=${moment(date).format(
        'YYYY-MM-DD',
      )}&hours08=${moment(`${moment().format('YYYY/MM/DD')} 08:00:00`)
        .tz('UTC')
        .utc()
        .format('HH')}&hours20=${moment(`${moment().format('YYYY/MM/DD')} 20:00:00`)
        .tz('UTC')
        .utc()
        .format('HH')}&hours8=${moment(`${moment().format('YYYY/MM/DD')} 08:00:00`)
        .tz('UTC')
        .utc()
        .format('HH')}`;
    }
    if (date_to && !date) {
      url = `/api/absence/${id}?date_end=${moment(date_to).format(
        'YYYY-MM-DD',
      )}&hours08=${moment(`${moment().format('YYYY/MM/DD')} 08:00:00`)
        .tz('UTC')
        .utc()
        .format('HH')}&hours20=${moment(`${moment().format('YYYY/MM/DD')} 20:00:00`)
        .tz('UTC')
        .utc()
        .format('HH')}&hours8=${moment(`${moment().format('YYYY/MM/DD')} 08:00:00`)
        .tz('UTC')
        .utc()
        .format('HH')}`;
    }
    if (date_to && date) {
      url = `/api/absence/${id}?date=${moment(date).format(
        'YYYY-MM-DD',
      )}&date_end=${moment(date_to).format('YYYY-MM-DD')}&hours08=${moment(
        `${moment().format('YYYY/MM/DD')} 08:00:00`,
      )
        .tz('UTC')
        .utc()
        .format('HH')}&hours20=${moment(`${moment().format('YYYY/MM/DD')} 20:00:00`)
        .tz('UTC')
        .utc()
        .format('HH')}&hours8=${moment(`${moment().format('YYYY/MM/DD')} 08:00:00`)
        .tz('UTC')
        .utc()
        .format('HH')}`;
    }
    const { data } = await apiCall({
      method: 'get',
      url,
    });

    dispatch({ type: 'GET_ABSENCES_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_ABSENCES_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const submitAbsenceForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_ABSENCE_REQUESTING' });
  const { date_from, hour_from, hour_to, date_to, note, login_access_id } = values;
  let request = {
    date_from: moment(`${moment(date_from).format('YYYY-MM-DD')} ${hour_from}`)
      .tz('UTC')
      .utc(),
    date_to: moment(`${moment(date_to).format('YYYY-MM-DD')} ${hour_to}`)
      .tz('UTC')
      .utc(),
    note,
    login_access_id,
    hour_from,
    hour_to,
  };
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/absence/${values.id ? 'update' : 'create'}/${values.id || ''}`,
      data: request,
    });
    if (!data.error) {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={`Absence ${
              !values.type ? (values.id ? 'modifié avec succès' : 'créé avec succès') : ''
            } `}
          />
        ),
        { hideProgressBar: true },
      );
      dispatch({
        type: 'UPDATE_ABSENCE_SUCCESS',
        data: data,
      });
      //  toast.success(`Profil a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_ABSENCE_FAILURE',
        ErrorMessage: data.message,
        data: values,
        status: true,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    if (data.message) {
      toast(
        ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
        { hideProgressBar: true },
      );
    } else
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message={`Absence non ${
              !values.type ? (values.id ? 'modifié' : 'ajouté') : ''
            } `}
          />
        ),
        { hideProgressBar: true },
      );
    // toast.error(`Profil non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'UPDATE_ABSENCE_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: values,
      status: getStatusError(e),
    });
  }
};
const getHolidayUser = (id) => async (dispatch) => {
  dispatch({ type: 'GET_USER_HOLIDAY_REQUESTING' });
  try {
    const url = `/api/holiday/user/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_USER_HOLIDAY_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_USER_HOLIDAY_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const getHoliday = () => async (dispatch) => {
  dispatch({ type: 'GET_HOLIDAY_REQUESTING' });
  try {
    const url = `/api/holiday`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_HOLIDAY_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_HOLIDAY_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const getEmployeeManagement = (type) => async (dispatch) => {
  dispatch({ type: 'GET_EmployeeManagement_REQUESTING' });
  try {
    const url = `/api/employee-management?type=${type}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_EmployeeManagement_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_EmployeeManagement_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};

const AddOffice = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_OFFICE_REQUESTING' });
  const {
    name,
    phone,
    conventional_code,
    tax_registration_number,
    office_code,
    cnam,
    cnss,
    rib,
    bank,
    number_employer,
    first_name,
    last_name,
    email,
    address_office,
  } = values;
  let request = {
    name,
    phone,
    office_code,
    cnam,
    cnss,
    rib,
    number_employer,
    bank,
    tax_registration_number,
    first_name,
    conventional_code,
    last_name,
    email,
    address_office: address_office || {},
  };
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/office/${values.id || ''}`,
      data: request,
    });
    const incompleteOffice = !!(
      data.name === '' ||
      data.phone === '' ||
      data.iban === '' ||
      data.office_code === '' ||
      data.speciality_id === '' ||
      data.cnam === '' ||
      data.cnss === '' ||
      data.bank === '' ||
      data.tax_registration_number === '' ||
      data.first_name === '' ||
      data.last_name === '' ||
      data.email === '' ||
      data.address_office === ''
    );
    if (!data.error) {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Centre modifié avec succès" />
        ),
        { hideProgressBar: true },
      );
      if (incompleteOffice)
        toast(
          ({ closeToast }) => (
            <ToastAction type="warning" message="office est incomplet !" />
          ),
          { hideProgressBar: true },
        );
      dispatch({
        type: 'UPDATE_OFFICE_SUCCESS',
        data: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_OFFICE_FAILURE',
        ErrorMessage: data.message,
        data: values,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response && response.data) {
      toast(
        ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
        { hideProgressBar: true },
      );
    }
    dispatch({
      type: 'UPDATE_OFFICE_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      ///err: data.errors || data.message,
      data: values,
    });
  }
};

const getOfficeByIdDoctor = () => async (dispatch) => {
  dispatch({ type: 'GET_OFFICE_REQUESTING' });
  try {
    let url = `/api/offices`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_OFFICE_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_OFFICE_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getAllArchive = () => async (dispatch) => {
  dispatch({ type: 'GET_ARCHIVE_REQUESTING' });
  try {
    const url = `/api/archive`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_ARCHIVE_SUCCESS', data: data.data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_ARCHIVE_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};

const deleteAbsence = (id) => async (dispatch) => {
  dispatch({ type: 'GET_DELETE_ABSENCE_REQUESTING' });
  try {
    const url = `/api/absence/delete/${id}`;
    const { data } = await apiCall({
      method: 'delete',
      url,
    });
    if (data.type === 'success')
      toast(({ closeToast }) => <ToastAction type="success" message={data.message} />, {
        hideProgressBar: true,
      });
    else
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
    dispatch({ type: 'GET_DELETE_ABSENCE_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Echec de suppression de l'absence" />
      ),
      {
        hideProgressBar: true,
      },
    );
    dispatch({
      type: 'GET_DELETE_ABSENCE_FAILURE',
      err: err.message,
      status: getStatusError(err),
    });
  }
};

const getStatistical = (params) => async (dispatch) => {
  dispatch({ type: 'GET_STATISTICAL_REQUESTING' });
  try {
    const { end_date } = params || {
      end_date: '',
    };
    const { start_date } = params || {
      start_date: '',
    };
    let url = `/api/payment?`;
    if (start_date && start_date !== '')
      url += `&start_date=${moment(start_date).format('YYYY-MM-DD')}`;
    if (end_date && end_date !== '')
      url += `&end_date=${moment(end_date).format('YYYY-MM-DD')}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({ type: 'GET_STATISTICAL_SUCCESS', data });
  } catch (err) {
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'GET_STATISTICAL_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const changeRegime = (regime) => ({
  type: 'UPDATE_REGIME',
  regime,
});
const changeActiveItemSideBarCompta = (data) => ({
  type: 'UPDATE_ACTIVE_ITEM_SIDE_BAR_COMPTA',
  activeItemSideBarCompta: data,
});
const changeSearchedPayment = (params) => ({
  type: 'UPDATE_CHANGE_PAYMENT_PARAMS',
  paramsPayment: params,
});
export default {
  changeSearchedPayment,
  changeActiveItemSideBarCompta,
  changeRegime,
  deleteAbsence,
  getAllArchive,
  submitAbsenceForm,
  addHolidayUser,
  getEmployeeManagement,
  getHoliday,
  getHolidayUser,
  getStatistical,
  getAbsenses,
  addPermissionsUser,
  getMedecin,
  submitMedecinForm,
  getMedecins,
  getPermissionsByUser,
  submitMissionForm,
  getPermissions,
  getMissions,
  getMotifsReference,
  getMission,
  changeActiveItemSideBarRDV,
  changeCountMessage,
  changeNamePage,
  getEmail,
  changeSandwichMenuBtn,
  keepMySessionOpen,
  login,
  getUserById,
  getMessage,
  getMe,
  getSpecialities,
  getRoles,
  submitUserForm,
  notificationEnabled,
  verifyAccount,
  changeActiveItemSideBar,
  getSecretaries,
  getSecretary,
  submitSecretaryForm,
  getAssistant,
  getAssistants,
  ActivitedDeactivetedAccount,
  submitEmployeeManagementForm,
  AddOffice,
  getOfficeByIdDoctor,
  getEmployerById,
  changeTag,
  changeActiveItemSideBarSettings,
  getAbsensesByHours,
  addGroupPermissionsUser,
};
