import React, { Component } from 'react';
import './index.css';
import actions from './store/actions';
import { connect } from 'react-redux';
import RDV from './composent/rdv';
import Patient from './composent/patient';
import { Link } from 'react-router-dom';
import { SelectorControlButtonsItemsDashboard } from '../../utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import Modals from '../../components/modal';
import { isHasPermission } from '../../utils/helpres';
import actionsJs from '../../js/actions';
import ToastAction from '../../components/ToastAction';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  isLoadingCountConsultation: state.DashboardReducer.isLoadingCountConsultation,
  isLoadingCountPatient: state.DashboardReducer.isLoadingCountPatient,
  isLoadingCountRdv: state.DashboardReducer.isLoadingCountRdv,
  countRdv: state.DashboardReducer.countRdv,
  countConsultation: state.DashboardReducer.countConsultation,
  countPatient: state.DashboardReducer.countPatient,
  isLoadingTotalTtcBill: state.DashboardReducer.isLoadingTotalTtcBill,
  last10Patient: state.DashboardReducer.last10Patient,
  isLoadingLast10Patients: state.DashboardReducer.isLoadingLast10Patients,
  last10RDV: state.DashboardReducer.last10RDV,
  isLoadingLast10RDV: state.DashboardReducer.isLoadingLast10RDV,
});

const mapDispatchToProps = (dispatch) => ({
  getCountRdvByDoctor: () => dispatch(actions.getCountRdvByDoctor()),
  getLast10RdvByDay: () => dispatch(actions.getLast10RdvByDay()),
  getCountConsultationByDoctor: () => dispatch(actions.getCountConsultationByDoctor()),
  getCountPatientByDoctor: () => dispatch(actions.getCountPatientByDoctor()),
  getLast10PatientByDoctor: () => dispatch(actions.getLast10PatientByDoctor()),
  changeActiveItemSideBarSettings: (item, data, create) =>
    dispatch(actionsJs.changeActiveItemSideBarSettings(item, data, create)),
  changeActiveItemSideBarCompta: (data) =>
    dispatch(actionsJs.changeActiveItemSideBarCompta(data)),
});

class Dashboard extends Component {
  constructor(props) {
    super(props);
    props.getCountRdvByDoctor();
    props.getCountConsultationByDoctor();
    props.changeActiveItemSideBarCompta(null);
    props.getCountPatientByDoctor();
    props.getLast10PatientByDoctor();
    props.getLast10RdvByDay();
    props.changeNamePage('Tableau de bord');
    props.changeActiveItemSideBar(0);
    props.changeActiveItemSideBarSettings(1000, false, true);
    document.title = 'Tableau de bord';
    this.state = {
      initialValues: {
        labelMotif: '',
        diagnostic: '',
        date: '',
        display_name: '',
        city: '',
        phone_number: '',
        motif_id: null,
        startDate: '',
        endDate: '',
        startHours: '',
        endHours: '',
        time: '',
      },
    };
    this.openModal = this.openModal.bind(this);
    this.getItemRdv = this.getItemRdv.bind(this);
  }

  openModal() {
    this.setState({
      setIsOpen: !this.state.setIsOpen,
    });
  }

  componentDidMount() {
    SelectorControlButtonsItemsDashboard();
  }

  getItemRdv(id) {
    const { last10RDV } = this.props;
    const rdv = last10RDV.find((element) => element.id === parseInt(id));
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        id: rdv.id,
        city: rdv.city
          ? rdv.city
          : rdv.patient.address_patient
          ? rdv.patient.address_patient.city
          : 'Ville non renseignée',
        status: rdv.status,
        display_name: rdv.display_name
          ? rdv.display_name
          : `${rdv.patient.first_name} ${rdv.last_name}`,
        labelMotif: rdv.motif.label === 'Autre' ? rdv.diagnostic : rdv.motif.label,
        phone_number: rdv.phone_number ? rdv.phone_number : rdv.patient.phone,
        motif_id: rdv.motif.id,
        startDate: rdv.date_from,
        endDate: rdv.date_to,
        time: moment(rdv.date_from).format('HH:mm'),
        startHours: moment(rdv.date_from).format('HH:mm'),
        endHours: moment(rdv.date_to).format('HH:mm'),
      },
    });
  }

  render() {
    const {
      countRdv,
      countConsultation,
      last10Patient,
      countPatient,
      isLoadingCountConsultation,
      last10RDV,
      isLoadingLast10RDV,
      user,
      admin,
      account_type,
    } = this.props;
    const { initialValues, setIsOpen } = this.state;
    if (isLoadingCountConsultation)
      return <CircularProgress disableShrink style={{ marginTop: '25%' }} />;
    if (isLoadingLast10RDV)
      return <CircularProgress disableShrink style={{ marginTop: '25%' }} />;
    return (
      <div className="main_content_dashboard">
        <Modals
          setIsOpen={setIsOpen}
          openModal={this.openModal}
          data={
            <>
              <div
                className="modal-header"
                style={{ marginTop: '-22px', marginBottom: '1%' }}
              >
                <span style={{ marginLeft: '1%' }}>Détails rendez-vous</span>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModal()}
                  style={{ margin: '0' }}
                >
                  <span aria-hidden="true" style={{ color: '#1A1C1D' }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="modal_body_section_1">
                  <div className="modal_body_section_1_left">
                    <div
                      style={{
                        width: '65px',
                        height: '65px',
                      }}
                      className="patient_contact_img_container"
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                          fill="#8083A3"
                        />
                      </svg>
                    </div>
                    <div className="user_gen_info">
                      <h4>{initialValues.display_name}</h4>
                      <span id="recap_username">{initialValues.city}</span>
                    </div>
                  </div>
                </div>
                <div className="modal_body_recap_details">
                  <div className="modal_body_recap_details_item">
                    <div className="modal_body_recap_details_item_left">
                      <span>Motif</span>
                    </div>
                    <div className="modal_body_recap_details_item_right">
                      <div className="conto">
                        <span style={{ lineHeight: '42px' }}>
                          {initialValues.labelMotif}
                        </span>
                        <button
                          style={{ cursor: 'auto' }}
                          className={
                            initialValues.status === 'actif'
                              ? 'status_rdv btn_status actif_status'
                              : 'status_rdv btn_status span_completed'
                          }
                          //  className="status_rdv span_completed"
                        >
                          {initialValues.status === 'actif'
                            ? 'Actif'
                            : initialValues.status === 'canceled'
                            ? 'Annuler'
                            : 'Terminer'}
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr id="top_section_seperator" />
                  <div className="modal_body_recap_details_item">
                    <div className="modal_body_recap_details_item_left">
                      <span>Date</span>
                    </div>
                    <div className="modal_body_recap_details_item_right">
                      <span>{moment(initialValues.startDate).format('DD/MM/YYYY')}</span>
                    </div>
                  </div>

                  <div className="modal_body_recap_details_item">
                    <div className="modal_body_recap_details_item_left">
                      <span>Créneau</span>
                    </div>
                    <div className="modal_body_recap_details_item_right">
                      <span>{initialValues.time}</span>
                    </div>
                  </div>
                  <div className="modal_body_recap_details_item">
                    <div className="modal_body_recap_details_item_left">
                      <span>Téléphone</span>
                    </div>
                    <div className="modal_body_recap_details_item_right">
                      <div className="conto">
                        <span>{initialValues.phone_number}</span>
                      </div>
                    </div>
                  </div>
                  <div className="modal_body_recap_details_item">
                    <div className="modal_body_recap_details_item_left">
                      <span>Ville</span>
                    </div>
                    <div className="modal_body_recap_details_item_right">
                      <div className="conto">
                        <span>{initialValues.city}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        />
        <section className="section_dashboard">
          <div className="dashboard_data">
            <div className="banner">
              <div className="banner_text">
                <span id="main_title">Votre centre digital de kinésithérapie</span>
                <span id="banner_desc">
                  Chers kinésithérapeutes, Cuida a été conçue en partant d’une
                  identification précieuse des problématiques que vous vivez chaque jour.
                  Elle a pour vocation de vous offrir une nouvelle expérience fluide et
                  économique. Aidez-nous à l'améliorer en nous partageant vos propositions
                  et nouvelles demandes.
                </span>
              </div>
              <div className="banner_img">
                <img src={require('../../assets/img/Illustration.svg')} />
              </div>
            </div>
            <div className="data">
              <div className="section_consultation" style={{ width: '80%' }}>
                <div className="section_consultation_header">
                  <div className="header_text">
                    <span id="consultation_title">Cures à venir</span>
                    <span id="consultation_desc">Cures et seances planifiées</span>
                  </div>
                  {isHasPermission(user.login_access.permissions, 'CALENDAR_ACCESS') ? (
                    <div className="icon_text">
                      <Link
                        to={
                          admin || user.login_access.account_type === 'secretary'
                            ? '/agenda'
                            : '/agenda-practitioner'
                        }
                      >
                        <i className="far fa-calendar-minus"></i>
                        <span>Voir l'agenda</span>
                      </Link>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="consultations_items">
                  {last10RDV && last10RDV.length > 0 ? (
                    last10RDV.map((item) => {
                      return (
                        <RDV
                          getItemRdv={this.getItemRdv}
                          openModal={this.openModal}
                          key={item.id}
                          item={item}
                        />
                      );
                    })
                  ) : (
                    <div
                      style={{ marginTop: '1%', height: '95%' }}
                      className="consultation_detailed_info_section"
                    >
                      <div className="empty_consultation_result_container">
                        <div className="empty_consultation_result">
                          <img
                            width="50%"
                            src={require('../../assets/img/undraw_design_team_af2y.svg')}
                          />
                          <span style={{ fontSize: '18px' }} id="no_consultation">
                            Vous n’avez pas de rendez-vous pour le moment
                          </span>
                          <span style={{ fontSize: '13px' }} id="no_consultation_desc">
                            Essayez de créer des nouveaux rendez-vous ou synchronisez
                            votre service de calendrier.
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="section_action_stats">
                <div style={{ display: 'flex', gap: '10px' }}>
                  <div style={{ width: '33%' }} className="control_buttons_items-home">
                    <div
                      style={{ width: '73%', textAlign: 'initial' }}
                      className="stats_item_text"
                    >
                      <span id="item_title">RDV terminés</span>
                    </div>
                    <div
                      style={{ margin: 'auto' }}
                      className="item_stats actual_item_stats1"
                    >
                      <div className="stat_text">
                        {countRdv.count ? countRdv.count : 0}
                      </div>
                      <div className="stat_bar">
                        <div id="full_length_bar">
                          <div id="progress"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: '33%' }} className="control_buttons_items-home">
                    <div
                      style={{ width: '73%', textAlign: 'initial' }}
                      className="stats_item_text"
                    >
                      <span id="item_title">Patients</span>
                    </div>
                    <div
                      style={{ margin: 'auto' }}
                      className="item_stats actual_item_stats3"
                    >
                      <div className="stat_text">
                        {countPatient.count ? countPatient.count : 0}
                      </div>
                      <div className="stat_bar">
                        <div id="full_length_bar">
                          <div id="progress"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: '33%' }} className="control_buttons_items-home">
                    <div
                      style={{ width: '73%', textAlign: 'initial' }}
                      className="stats_item_text"
                    >
                      <span id="item_title">Cures</span>
                    </div>
                    <div
                      style={{ margin: 'auto' }}
                      className="item_stats actual_item_stats2"
                    >
                      <div className="stat_text">
                        {countConsultation.count ? countConsultation.count : 0}
                      </div>
                      <div className="stat_bar">
                        <div id="full_length_bar">
                          <div id="progress"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*<div className="stats" style={{ gap: '30px', height: '35%' }}>
                   <div className="stats_container_dashboard">
                    <div style={{ width: '90%' }} className="stats_items">
                      <div className="stats_item_text">
                        <span id="item_title">Rendez-vous terminés</span>
                        <span id="item_desc">Nombre total des rendez-vous</span>
                      </div>
                      <div className="item_stats actual_item_stats1">
                        <div className="stat_text">
                          {countRdv.count ? countRdv.count : 0}
                        </div>
                        <div className="stat_bar">
                          <div id="full_length_bar">
                            <div id="progress"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="stats_container_dashboard">
                    <div style={{ width: '90%' }} className="stats_items">
                      <div className="stats_item_text">
                        <span id="item_title">Patients</span>
                        <span id="item_desc">Nombre total des patients</span>
                      </div>
                      <div className="item_stats actual_item_stats3">
                        <div className="stat_text">
                          {countPatient.count ? countPatient.count : 0}
                        </div>
                        <div className="stat_bar">
                          <div id="full_length_bar">
                            <div id="progress"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="stats_container_dashboard">
                    <div style={{ width: '90%' }} className="stats_items">
                      <div className="stats_item_text">
                        <span id="item_title">Cures</span>
                        <span id="item_desc">Nombre total des cures</span>
                      </div>
                      <div className="item_stats actual_item_stats2">
                        <div className="stat_text">
                          {countConsultation.count ? countConsultation.count : 0}
                        </div>
                        <div className="stat_bar">
                          <div id="full_length_bar">
                            <div id="progress"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>*/}
                <br />
                <div className="action_rapides">
                  <div className="action_rapides_text">
                    <span id="action_rapides_text_bold">Actions rapides</span>
                    <span id="action_rapides_text_light">
                      Personnalisez vos actions rapides en sélectionnant les missions qui
                      vous sont utiles.
                    </span>
                  </div>
                  {account_type === 'admin' ? (
                    <div className="action_rapides_buttons_container">
                      <Link
                        to={{
                          pathname: '/settings',
                          state: {
                            item: 1,
                          },
                        }}
                        onClick={() =>
                          this.props.changeActiveItemSideBarSettings(1, true, true)
                        }
                        id="add_rd_btn"
                        className="control_buttons_items"
                      >
                        <svg
                          width="18"
                          height="15"
                          viewBox="0 0 18 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 5.65234L12.6523 9.34766H9.90234V14.8477H8.09766V9.34766H5.34766L9 5.65234ZM15.4023 0.152344C15.918 0.152344 16.3477 0.338542 16.6914 0.710938C17.0638 1.08333 17.25 1.51302 17.25 2V13C17.25 13.487 17.0638 13.9167 16.6914 14.2891C16.319 14.6615 15.8893 14.8477 15.4023 14.8477H11.75V13H15.4023V3.84766H2.59766V13H6.25V14.8477H2.59766C2.08203 14.8477 1.63802 14.6615 1.26562 14.2891C0.921875 13.9167 0.75 13.487 0.75 13V2C0.75 1.51302 0.921875 1.08333 1.26562 0.710938C1.63802 0.338542 2.08203 0.152344 2.59766 0.152344H15.4023Z"
                            fill="#5446DB"
                          />
                        </svg>

                        <span>Ajouter un kiné</span>
                      </Link>

                      <div
                        onClick={() => {
                          this.props.history.push({
                            pathname: '/settings',
                            state: {
                              item: 8,
                            },
                          });
                          this.props.changeActiveItemSideBarSettings(8, true, true);
                        }}
                        id="add_patient_btn"
                        className="control_buttons_items"
                      >
                        <svg
                          width="16"
                          height="11"
                          viewBox="0 0 16 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.75 6.23828C6.26562 6.23828 6.86328 6.30859 7.54297 6.44922C6.34766 7.10547 5.75 7.97266 5.75 9.05078V10.7383H0.511719V8.875C0.511719 8.33594 0.828125 7.85547 1.46094 7.43359C2.11719 7.01172 2.83203 6.70703 3.60547 6.51953C4.37891 6.33203 5.09375 6.23828 5.75 6.23828ZM8.66797 7.57422C9.60547 7.19922 10.5078 7.01172 11.375 7.01172C12.2422 7.01172 13.1445 7.19922 14.082 7.57422C15.0195 7.94922 15.4883 8.44141 15.4883 9.05078V10.7383H7.26172V9.05078C7.26172 8.44141 7.73047 7.94922 8.66797 7.57422ZM7.33203 4.09375C6.88672 4.53906 6.35938 4.76172 5.75 4.76172C5.14062 4.76172 4.61328 4.53906 4.16797 4.09375C3.72266 3.64844 3.5 3.12109 3.5 2.51172C3.5 1.90234 3.72266 1.375 4.16797 0.929688C4.61328 0.484375 5.14062 0.261719 5.75 0.261719C6.35938 0.261719 6.88672 0.484375 7.33203 0.929688C7.77734 1.375 8 1.90234 8 2.51172C8 3.12109 7.77734 3.64844 7.33203 4.09375ZM12.6758 4.97266C12.3242 5.32422 11.8906 5.5 11.375 5.5C10.8594 5.5 10.4141 5.32422 10.0391 4.97266C9.6875 4.59766 9.51172 4.15234 9.51172 3.63672C9.51172 3.12109 9.6875 2.67578 10.0391 2.30078C10.4141 1.92578 10.8594 1.73828 11.375 1.73828C11.8906 1.73828 12.3242 1.92578 12.6758 2.30078C13.0508 2.67578 13.2383 3.12109 13.2383 3.63672C13.2383 4.15234 13.0508 4.59766 12.6758 4.97266Z"
                            fill="#8083A3"
                          />
                        </svg>
                        {'   '}
                        <span>Gérer les permissions</span>
                      </div>
                      <div
                        onClick={() => {
                          this.props.history.push({
                            pathname: '/settings',
                            state: {
                              item: 6,
                            },
                          });
                          this.props.changeActiveItemSideBarSettings(6, true, true);
                        }}
                        id="call_patient_btn"
                        className="control_buttons_items"
                      >
                        <i className="fa fa-plus"></i>

                        <span>Ajouter une mission</span>
                      </div>

                      <Link
                        to={{
                          pathname: '/compta',
                        }}
                        id="add_rd_btn"
                        className="control_buttons_items"
                      >
                        <svg
                          width="18"
                          height="13"
                          viewBox="0 0 18 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.4883 10.2617V4.98828H13.5V10.2617H16.4883ZM17.2617 3.51172C17.4727 3.51172 17.6484 3.58203 17.7891 3.72266C17.9297 3.86328 18 4.03906 18 4.25V11.7383C18 11.9492 17.9297 12.1367 17.7891 12.3008C17.6484 12.4414 17.4727 12.5117 17.2617 12.5117H12.7617C12.5508 12.5117 12.3633 12.4414 12.1992 12.3008C12.0586 12.1367 11.9883 11.9492 11.9883 11.7383V4.25C11.9883 4.03906 12.0586 3.86328 12.1992 3.72266C12.3633 3.58203 12.5508 3.51172 12.7617 3.51172H17.2617ZM2.98828 2V10.2617H10.5117V12.5117H0V10.2617H1.51172V2C1.51172 1.60156 1.65234 1.25 1.93359 0.945312C2.23828 0.640625 2.58984 0.488281 2.98828 0.488281H16.4883V2H2.98828Z"
                            fill="#8083A3"
                          />
                        </svg>

                        <span>Gérer la comptabilité </span>
                      </Link>
                    </div>
                  ) : account_type === 'user' ? (
                    <div className="action_rapides_buttons_container">
                      <div
                        onClick={() => {
                          if (
                            isHasPermission(
                              user.login_access.permissions,
                              'ADD_NEW_PATIENT',
                            )
                          )
                            this.props.history.push('/form-patient');
                          else
                            toast(
                              ({ closeToast }) => (
                                <ToastAction
                                  type="warning"
                                  message="Vous n'avez pas la permission d'ajouter des patients."
                                />
                              ),
                              { hideProgressBar: true },
                            );
                        }}
                        id="add_patient_btn"
                        className="control_buttons_items"
                      >
                        <svg
                          width="16"
                          height="11"
                          viewBox="0 0 16 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.75 6.23828C6.26562 6.23828 6.86328 6.30859 7.54297 6.44922C6.34766 7.10547 5.75 7.97266 5.75 9.05078V10.7383H0.511719V8.875C0.511719 8.33594 0.828125 7.85547 1.46094 7.43359C2.11719 7.01172 2.83203 6.70703 3.60547 6.51953C4.37891 6.33203 5.09375 6.23828 5.75 6.23828ZM8.66797 7.57422C9.60547 7.19922 10.5078 7.01172 11.375 7.01172C12.2422 7.01172 13.1445 7.19922 14.082 7.57422C15.0195 7.94922 15.4883 8.44141 15.4883 9.05078V10.7383H7.26172V9.05078C7.26172 8.44141 7.73047 7.94922 8.66797 7.57422ZM7.33203 4.09375C6.88672 4.53906 6.35938 4.76172 5.75 4.76172C5.14062 4.76172 4.61328 4.53906 4.16797 4.09375C3.72266 3.64844 3.5 3.12109 3.5 2.51172C3.5 1.90234 3.72266 1.375 4.16797 0.929688C4.61328 0.484375 5.14062 0.261719 5.75 0.261719C6.35938 0.261719 6.88672 0.484375 7.33203 0.929688C7.77734 1.375 8 1.90234 8 2.51172C8 3.12109 7.77734 3.64844 7.33203 4.09375ZM12.6758 4.97266C12.3242 5.32422 11.8906 5.5 11.375 5.5C10.8594 5.5 10.4141 5.32422 10.0391 4.97266C9.6875 4.59766 9.51172 4.15234 9.51172 3.63672C9.51172 3.12109 9.6875 2.67578 10.0391 2.30078C10.4141 1.92578 10.8594 1.73828 11.375 1.73828C11.8906 1.73828 12.3242 1.92578 12.6758 2.30078C13.0508 2.67578 13.2383 3.12109 13.2383 3.63672C13.2383 4.15234 13.0508 4.59766 12.6758 4.97266Z"
                            fill="#8083A3"
                          />
                        </svg>
                        {'   '}
                        <span>Ajouter un patient</span>
                      </div>
                      <div
                        onClick={() => {
                          if (
                            isHasPermission(
                              user.login_access.permissions,
                              'ADD_NEW_APPOINTMENT',
                            )
                          )
                            this.props.history.push({
                              pathname: '/agenda-practitioner',
                              state: {
                                showAddRdv: true,
                              },
                            });
                          else
                            toast(
                              ({ closeToast }) => (
                                <ToastAction
                                  type="warning"
                                  message="Vous n'avez pas la permission d'ajouter des patients."
                                />
                              ),
                              { hideProgressBar: true },
                            );
                        }}
                        id="add_rd_btn"
                        className="control_buttons_items"
                      >
                        <svg
                          width="18"
                          height="15"
                          viewBox="0 0 18 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 5.65234L12.6523 9.34766H9.90234V14.8477H8.09766V9.34766H5.34766L9 5.65234ZM15.4023 0.152344C15.918 0.152344 16.3477 0.338542 16.6914 0.710938C17.0638 1.08333 17.25 1.51302 17.25 2V13C17.25 13.487 17.0638 13.9167 16.6914 14.2891C16.319 14.6615 15.8893 14.8477 15.4023 14.8477H11.75V13H15.4023V3.84766H2.59766V13H6.25V14.8477H2.59766C2.08203 14.8477 1.63802 14.6615 1.26562 14.2891C0.921875 13.9167 0.75 13.487 0.75 13V2C0.75 1.51302 0.921875 1.08333 1.26562 0.710938C1.63802 0.338542 2.08203 0.152344 2.59766 0.152344H15.4023Z"
                            fill="#5446DB"
                          />
                        </svg>

                        <span>Planifier un rendez-vous</span>
                      </div>
                      <div
                        onClick={() => {
                          if (
                            isHasPermission(
                              user.login_access.permissions,
                              'ADD_NEW_BALANCE_SHEET',
                            )
                          )
                            this.props.history.push('/consultations');
                          else
                            toast(
                              ({ closeToast }) => (
                                <ToastAction
                                  type="warning"
                                  message="Vous n'avez pas la permission de créer des bilans."
                                />
                              ),
                              { hideProgressBar: true },
                            );
                        }}
                        id="call_patient_btn"
                        className="control_buttons_items"
                      >
                        <i className="fa fa-plus"></i>

                        <span>Créer un bilan</span>
                      </div>
                      <div
                        onClick={() => {
                          if (
                            isHasPermission(
                              user.login_access.permissions,
                              'CREATE_NEW_PROTOCOLE',
                            )
                          )
                            this.props.history.push('/consultations');
                          else
                            toast(
                              ({ closeToast }) => (
                                <ToastAction
                                  type="warning"
                                  message="Vous n'avez pas la permission de créer des protocoles."
                                />
                              ),
                              { hideProgressBar: true },
                            );
                        }}
                        id="add_rd_btn"
                        className="control_buttons_items"
                      >
                        <svg
                          width="18"
                          height="13"
                          viewBox="0 0 18 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.4883 10.2617V4.98828H13.5V10.2617H16.4883ZM17.2617 3.51172C17.4727 3.51172 17.6484 3.58203 17.7891 3.72266C17.9297 3.86328 18 4.03906 18 4.25V11.7383C18 11.9492 17.9297 12.1367 17.7891 12.3008C17.6484 12.4414 17.4727 12.5117 17.2617 12.5117H12.7617C12.5508 12.5117 12.3633 12.4414 12.1992 12.3008C12.0586 12.1367 11.9883 11.9492 11.9883 11.7383V4.25C11.9883 4.03906 12.0586 3.86328 12.1992 3.72266C12.3633 3.58203 12.5508 3.51172 12.7617 3.51172H17.2617ZM2.98828 2V10.2617H10.5117V12.5117H0V10.2617H1.51172V2C1.51172 1.60156 1.65234 1.25 1.93359 0.945312C2.23828 0.640625 2.58984 0.488281 2.98828 0.488281H16.4883V2H2.98828Z"
                            fill="#8083A3"
                          />
                        </svg>

                        <span>Créer un protocole </span>
                      </div>
                    </div>
                  ) : account_type === 'assistant' ? (
                    <div className="action_rapides_buttons_container">
                      <div
                        onClick={() => {
                          if (
                            isHasPermission(
                              user.login_access.permissions,
                              'ADD_NEW_PATIENT',
                            )
                          )
                            this.props.history.push('/form-patient');
                          else
                            toast(
                              ({ closeToast }) => (
                                <ToastAction
                                  type="warning"
                                  message="Vous n'avez pas la permission d'ajouter des patients."
                                />
                              ),
                              { hideProgressBar: true },
                            );
                        }}
                        id="add_patient_btn"
                        className="control_buttons_items"
                      >
                        <svg
                          width="16"
                          height="11"
                          viewBox="0 0 16 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.75 6.23828C6.26562 6.23828 6.86328 6.30859 7.54297 6.44922C6.34766 7.10547 5.75 7.97266 5.75 9.05078V10.7383H0.511719V8.875C0.511719 8.33594 0.828125 7.85547 1.46094 7.43359C2.11719 7.01172 2.83203 6.70703 3.60547 6.51953C4.37891 6.33203 5.09375 6.23828 5.75 6.23828ZM8.66797 7.57422C9.60547 7.19922 10.5078 7.01172 11.375 7.01172C12.2422 7.01172 13.1445 7.19922 14.082 7.57422C15.0195 7.94922 15.4883 8.44141 15.4883 9.05078V10.7383H7.26172V9.05078C7.26172 8.44141 7.73047 7.94922 8.66797 7.57422ZM7.33203 4.09375C6.88672 4.53906 6.35938 4.76172 5.75 4.76172C5.14062 4.76172 4.61328 4.53906 4.16797 4.09375C3.72266 3.64844 3.5 3.12109 3.5 2.51172C3.5 1.90234 3.72266 1.375 4.16797 0.929688C4.61328 0.484375 5.14062 0.261719 5.75 0.261719C6.35938 0.261719 6.88672 0.484375 7.33203 0.929688C7.77734 1.375 8 1.90234 8 2.51172C8 3.12109 7.77734 3.64844 7.33203 4.09375ZM12.6758 4.97266C12.3242 5.32422 11.8906 5.5 11.375 5.5C10.8594 5.5 10.4141 5.32422 10.0391 4.97266C9.6875 4.59766 9.51172 4.15234 9.51172 3.63672C9.51172 3.12109 9.6875 2.67578 10.0391 2.30078C10.4141 1.92578 10.8594 1.73828 11.375 1.73828C11.8906 1.73828 12.3242 1.92578 12.6758 2.30078C13.0508 2.67578 13.2383 3.12109 13.2383 3.63672C13.2383 4.15234 13.0508 4.59766 12.6758 4.97266Z"
                            fill="#8083A3"
                          />
                        </svg>
                        {'   '}
                        <span>Ajouter un patient</span>
                      </div>
                      <div
                        onClick={() => {
                          if (
                            isHasPermission(
                              user.login_access.permissions,
                              'ADD_NEW_APPOINTMENT',
                            )
                          )
                            this.props.history.push({
                              pathname: '/agenda-practitioner',
                              state: {
                                showAddRdv: true,
                              },
                            });
                          else
                            toast(
                              ({ closeToast }) => (
                                <ToastAction
                                  type="warning"
                                  message="Vous n'avez pas la permission de planifier des rendez-vous."
                                />
                              ),
                              { hideProgressBar: true },
                            );
                        }}
                        id="add_rd_btn"
                        className="control_buttons_items"
                      >
                        <svg
                          width="18"
                          height="15"
                          viewBox="0 0 18 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 5.65234L12.6523 9.34766H9.90234V14.8477H8.09766V9.34766H5.34766L9 5.65234ZM15.4023 0.152344C15.918 0.152344 16.3477 0.338542 16.6914 0.710938C17.0638 1.08333 17.25 1.51302 17.25 2V13C17.25 13.487 17.0638 13.9167 16.6914 14.2891C16.319 14.6615 15.8893 14.8477 15.4023 14.8477H11.75V13H15.4023V3.84766H2.59766V13H6.25V14.8477H2.59766C2.08203 14.8477 1.63802 14.6615 1.26562 14.2891C0.921875 13.9167 0.75 13.487 0.75 13V2C0.75 1.51302 0.921875 1.08333 1.26562 0.710938C1.63802 0.338542 2.08203 0.152344 2.59766 0.152344H15.4023Z"
                            fill="#5446DB"
                          />
                        </svg>

                        <span>Planifier un rendez-vous</span>
                      </div>
                    </div>
                  ) : (
                    <div className="action_rapides_buttons_container">
                      <div
                        onClick={() => {
                          if (
                            isHasPermission(
                              user.login_access.permissions,
                              'ADD_NEW_PATIENT',
                            )
                          )
                            this.props.history.push('/form-patient');
                          else
                            toast(
                              ({ closeToast }) => (
                                <ToastAction
                                  type="warning"
                                  message="Vous n'avez pas la permission d'ajouter des patients."
                                />
                              ),
                              { hideProgressBar: true },
                            );
                        }}
                        id="add_patient_btn"
                        className="control_buttons_items"
                      >
                        <svg
                          width="16"
                          height="11"
                          viewBox="0 0 16 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.75 6.23828C6.26562 6.23828 6.86328 6.30859 7.54297 6.44922C6.34766 7.10547 5.75 7.97266 5.75 9.05078V10.7383H0.511719V8.875C0.511719 8.33594 0.828125 7.85547 1.46094 7.43359C2.11719 7.01172 2.83203 6.70703 3.60547 6.51953C4.37891 6.33203 5.09375 6.23828 5.75 6.23828ZM8.66797 7.57422C9.60547 7.19922 10.5078 7.01172 11.375 7.01172C12.2422 7.01172 13.1445 7.19922 14.082 7.57422C15.0195 7.94922 15.4883 8.44141 15.4883 9.05078V10.7383H7.26172V9.05078C7.26172 8.44141 7.73047 7.94922 8.66797 7.57422ZM7.33203 4.09375C6.88672 4.53906 6.35938 4.76172 5.75 4.76172C5.14062 4.76172 4.61328 4.53906 4.16797 4.09375C3.72266 3.64844 3.5 3.12109 3.5 2.51172C3.5 1.90234 3.72266 1.375 4.16797 0.929688C4.61328 0.484375 5.14062 0.261719 5.75 0.261719C6.35938 0.261719 6.88672 0.484375 7.33203 0.929688C7.77734 1.375 8 1.90234 8 2.51172C8 3.12109 7.77734 3.64844 7.33203 4.09375ZM12.6758 4.97266C12.3242 5.32422 11.8906 5.5 11.375 5.5C10.8594 5.5 10.4141 5.32422 10.0391 4.97266C9.6875 4.59766 9.51172 4.15234 9.51172 3.63672C9.51172 3.12109 9.6875 2.67578 10.0391 2.30078C10.4141 1.92578 10.8594 1.73828 11.375 1.73828C11.8906 1.73828 12.3242 1.92578 12.6758 2.30078C13.0508 2.67578 13.2383 3.12109 13.2383 3.63672C13.2383 4.15234 13.0508 4.59766 12.6758 4.97266Z"
                            fill="#8083A3"
                          />
                        </svg>
                        {'   '}
                        <span>Ajouter un patient</span>
                      </div>
                      <div
                        onClick={() => {
                          if (
                            isHasPermission(
                              user.login_access.permissions,
                              'ADD_NEW_APPOINTMENT',
                            )
                          )
                            this.props.history.push({
                              pathname: '/agenda',
                              state: {
                                showAddRdv: true,
                              },
                            });
                          else
                            toast(
                              ({ closeToast }) => (
                                <ToastAction
                                  type="warning"
                                  message="Vous n'avez pas la permission de planifier des rendez-vous."
                                />
                              ),
                              { hideProgressBar: true },
                            );
                        }}
                        id="add_rd_btn"
                        className="control_buttons_items"
                      >
                        <svg
                          width="18"
                          height="15"
                          viewBox="0 0 18 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 5.65234L12.6523 9.34766H9.90234V14.8477H8.09766V9.34766H5.34766L9 5.65234ZM15.4023 0.152344C15.918 0.152344 16.3477 0.338542 16.6914 0.710938C17.0638 1.08333 17.25 1.51302 17.25 2V13C17.25 13.487 17.0638 13.9167 16.6914 14.2891C16.319 14.6615 15.8893 14.8477 15.4023 14.8477H11.75V13H15.4023V3.84766H2.59766V13H6.25V14.8477H2.59766C2.08203 14.8477 1.63802 14.6615 1.26562 14.2891C0.921875 13.9167 0.75 13.487 0.75 13V2C0.75 1.51302 0.921875 1.08333 1.26562 0.710938C1.63802 0.338542 2.08203 0.152344 2.59766 0.152344H15.4023Z"
                            fill="#5446DB"
                          />
                        </svg>

                        <span>Planifier un rendez-vous</span>
                      </div>
                      <div
                        onClick={() => {
                          if (
                            isHasPermission(user.login_access.permissions, 'ADD_NEW_BILL')
                          )
                            this.props.history.push({
                              pathname: '/patient/facture',
                              state: {
                                showAddRdv: true,
                              },
                            });
                          else
                            toast(
                              ({ closeToast }) => (
                                <ToastAction
                                  type="warning"
                                  message="Vous n'avez pas la permission de créer des factures."
                                />
                              ),
                              { hideProgressBar: true },
                            );
                        }}
                        id="add_rd_btn"
                        className="control_buttons_items"
                      >
                        <svg
                          width="18"
                          height="13"
                          viewBox="0 0 18 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.4883 10.2617V4.98828H13.5V10.2617H16.4883ZM17.2617 3.51172C17.4727 3.51172 17.6484 3.58203 17.7891 3.72266C17.9297 3.86328 18 4.03906 18 4.25V11.7383C18 11.9492 17.9297 12.1367 17.7891 12.3008C17.6484 12.4414 17.4727 12.5117 17.2617 12.5117H12.7617C12.5508 12.5117 12.3633 12.4414 12.1992 12.3008C12.0586 12.1367 11.9883 11.9492 11.9883 11.7383V4.25C11.9883 4.03906 12.0586 3.86328 12.1992 3.72266C12.3633 3.58203 12.5508 3.51172 12.7617 3.51172H17.2617ZM2.98828 2V10.2617H10.5117V12.5117H0V10.2617H1.51172V2C1.51172 1.60156 1.65234 1.25 1.93359 0.945312C2.23828 0.640625 2.58984 0.488281 2.98828 0.488281H16.4883V2H2.98828Z"
                            fill="#8083A3"
                          />
                        </svg>

                        <span>Créer une facture </span>
                      </div>
                      <div
                        onClick={() => {
                          if (
                            isHasPermission(
                              user.login_access.permissions,
                              'ADD_NEW_RECEIPT',
                            )
                          )
                            this.props.history.push({
                              pathname: '/patient/receipt',
                              state: {
                                showAddRdv: true,
                              },
                            });
                          else
                            toast(
                              ({ closeToast }) => (
                                <ToastAction
                                  type="warning"
                                  message="Vous n'avez pas la permission de créer des reçus."
                                />
                              ),
                              { hideProgressBar: true },
                            );
                        }}
                        id="add_rd_btn"
                        className="control_buttons_items"
                      >
                        <svg
                          width="18"
                          height="13"
                          viewBox="0 0 18 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.4883 10.2617V4.98828H13.5V10.2617H16.4883ZM17.2617 3.51172C17.4727 3.51172 17.6484 3.58203 17.7891 3.72266C17.9297 3.86328 18 4.03906 18 4.25V11.7383C18 11.9492 17.9297 12.1367 17.7891 12.3008C17.6484 12.4414 17.4727 12.5117 17.2617 12.5117H12.7617C12.5508 12.5117 12.3633 12.4414 12.1992 12.3008C12.0586 12.1367 11.9883 11.9492 11.9883 11.7383V4.25C11.9883 4.03906 12.0586 3.86328 12.1992 3.72266C12.3633 3.58203 12.5508 3.51172 12.7617 3.51172H17.2617ZM2.98828 2V10.2617H10.5117V12.5117H0V10.2617H1.51172V2C1.51172 1.60156 1.65234 1.25 1.93359 0.945312C2.23828 0.640625 2.58984 0.488281 2.98828 0.488281H16.4883V2H2.98828Z"
                            fill="#8083A3"
                          />
                        </svg>

                        <span>Créer un reçu </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="section_patients" style={{ height: '100%' }}>
          <div className="section_consultation_header">
            <div className="header_text">
              <span id="recent_patient_title_bold">Patients récents</span>
              <span id="recent_patient_title_light">Vos patients</span>
            </div>
            <div className="icon_text">
              <Link to="/patients">
                <span>Voir tous</span>
              </Link>
            </div>
          </div>
          <div
            className="recent_patients_items_container"
            style={{ overflowY: 'auto', height: 'calc(101% - 60px)' }}
          >
            {' '}
            {last10Patient && last10Patient.length > 0 ? (
              last10Patient.map((item) => {
                return (
                  <Patient
                    key={item.id}
                    onClickShowPhone={this.onClickShowPhone}
                    item={item}
                  />
                );
              })
            ) : (
              <div
                style={{ marginTop: '1%', height: '70%' }}
                className="consultation_detailed_info_section"
              >
                <div className="empty_consultation_result_container">
                  <div className="empty_consultation_result">
                    <img
                      width="50%"
                      src={require('../../assets/img/undraw_design_team_af2y.svg')}
                    />
                    <span style={{ fontSize: '18px' }} id="no_consultation">
                      Pas de Patients !
                    </span>
                    <span id="no_consultation_desc" style={{ fontSize: '13px' }}>
                      Essayez d'ajouter des nouveaux patients.
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>*/}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
