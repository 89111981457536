const initialUserState = {
  isLoadingAddBill: false,
  isLoadingLastBill: false,
  isLoadingGetPec: true,
  pec: null,
  lastBill: null,
  updateBill: false,
  bills: [],
  bill: null,
  isLoadingBills: false,
  sendBillByEmail: true,
  isLoadingSendBillByEmail: true,
  isLoadingBillsByIds: true,
  billsByIds: [],
  isLoadingBill: true,
  isLoadingAddBordereau: true,
  addBordereau: null,
  billData: null,
  params: {
    limit: 6,
    currentPage: 1,
    start_date: '',
    end_date: '',
    search: '',
    num_decision: '',
    status: '',
    type: '',
  },
};

const FactureReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case 'SUBMIT_BORDEREAU_REQUESTING': {
      return {
        ...state,
        isLoadingAddBordereau: true,
      };
    }
    case 'SUBMIT_BORDEREAU_SUCCESS': {
      return {
        ...state,
        isLoadingAddBordereau: false,
        addBordereau: action.data,
      };
    }
    case 'SUBMIT_BORDEREAU_FAILURE': {
      return {
        ...state,
        isLoadingAddBordereau: false,
        addBordereau: null,
      };
    }

    case 'GET_BILLS_BY_IDS_REQUESTING': {
      return {
        ...state,
        isLoadingBillsByIds: true,
      };
    }
    case 'GET_BILLS_BY_IDS_SUCCESS': {
      return {
        ...state,
        isLoadingBillsByIds: false,
        billsByIds: action.data,
      };
    }
    case 'GET_BILLS_BY_IDS_FAILURE': {
      return {
        ...state,
        isLoadingBillsByIds: false,
        billsByIds: [],
      };
    }

    case 'SUBMIT_SEND_BILL_REQUESTING': {
      return {
        ...state,
        isLoadingSendBillByEmail: true,
      };
    }
    case 'SUBMIT_SEND_BILL_SUCCESS': {
      return {
        ...state,
        isLoadingSendBillByEmail: false,
        sendBillByEmail: true,
      };
    }
    case 'SUBMIT_SEND_BILL_FAILURE': {
      return {
        ...state,
        isLoadingSendBillByEmail: false,
        sendBillByEmail: false,
      };
    }

    case 'GET_BILL_REQUESTING': {
      return {
        ...state,
        isLoadingBill: true,
        updateBill: false,
      };
    }
    case 'GET_BILL_SUCCESS': {
      return {
        ...state,
        isLoadingBill: false,
        // updateBill: false,
        bill: action.data,
      };
    }
    case 'GET_BILL_FAILURE': {
      return {
        ...state,
        isLoadingBill: false,
        //updateBill: false,
        bill: null,
      };
    }

    case 'GET_BILLS_REQUESTING': {
      return {
        ...state,
        isLoadingBills: true,
        updateBill: false,
      };
    }
    case 'GET_BILLS_SUCCESS': {
      return {
        ...state,
        isLoadingBills: false,
        bills: action.data,
      };
    }
    case 'GET_BILLS_FAILURE': {
      return {
        ...state,
        isLoadingBills: false,
        bills: [],
      };
    }

    case 'GET_PEC_REQUESTING': {
      return {
        ...state,
        isLoadingGetPec: true,
        // updateBill: false,
        pec: null,
      };
    }
    case 'GET_PEC_SUCCESS': {
      return {
        ...state,
        isLoadingGetPec: false,
        /// updateBill: false,
        pec: action.data.data,
      };
    }
    case 'GET_PEC_FAILURE': {
      return {
        ...state,
        isLoadingGetPec: false,
        // updateBill: false,
        pec: null,
      };
    }

    case 'GET_LAST_BILL_REQUESTING': {
      return {
        ...state,
        isLoadingLastBill: true,
        lastBill: null,
      };
    }
    case 'GET_LAST_BILL_SUCCESS': {
      return {
        ...state,
        isLoadingLastBill: false,
        lastBill: action.data,
      };
    }
    case 'GET_LAST_BILL_FAILURE': {
      return {
        ...state,
        isLoadingLastBill: false,
        lastBill: null,
      };
    }

    case 'UPDATE_BILL_REQUESTING': {
      return {
        ...state,
        isLoadingAddBill: true,
        updateBill: false,
      };
    }
    case 'UPDATE_BILL_SUCCESS': {
      return {
        ...state,
        isLoadingAddBill: false,
        updateBill: true,
        billData:action.data
      };
    }
    case 'UPDATE_BILL_FAILURE': {
      return {
        ...state,
        isLoadingAddBill: false,
        updateBill: false,
      };
    }
    case 'UPDATE_SEARCH_BILL_PARAMS': {
      return {
        ...state,
        params: action.params,
      };
    }
    default: {
      return state;
    }
  }
};

export default FactureReducer;
