import React, { Component } from 'react';
import { SelectorInputAndIcon } from '../../../utils';
import moment from 'moment';
import Modals from '../../../components/modal';

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setIsOpenDeleteModal: false,
    };
    this.openDeleteModal = this.openDeleteModal.bind(this);
  }
  componentDidMount() {
    SelectorInputAndIcon();
  }
  openDeleteModal() {
    this.setState({
      setIsOpenDeleteModal: !this.state.setIsOpenDeleteModal,
    });
  }
  render() {
    const {
      element,
      handleChangeInputItem,
      item,
      userId,
      countItem,
      sandwich_menu_btn,
      hasError,
    } = this.props;
    const { setIsOpenDeleteModal } = this.state;
    return (
      <div className="wrapper" key={item.id}>
        <div
          className={
            hasError && !item.title
              ? 'diploma_info_form_item type3'
              : 'diploma_info_form_item'
          }
          style={{ width: sandwich_menu_btn ? '16vw' : '19vw' }}
        >
          <label>
            Nom du {element === 'diplomas' ? 'diplôme' : 'trainings'} n°{countItem}
          </label>
          <div className="input_and_icon">
            <input
              required
              value={item.title}
              onChange={(event) => {
                handleChangeInputItem(event, item.id, element);
              }}
              type="text"
              name="title"
              placeholder="Ecrire ici..."
            />
            <i className="fas fa-book"></i>
          </div>
        </div>
        <div
          className={
            (hasError && element === 'diplomas' && !item.university) ||
            (hasError && element === 'trainings' && !item.place)
              ? 'diploma_info_form_item type3'
              : 'diploma_info_form_item'
          }
          style={{ width: sandwich_menu_btn ? '16vw' : '19vw' }}
        >
          <label>{element === 'diplomas' ? 'Université' : 'Lieu'}</label>

          <div className="input_and_icon">
            <input
              required
              value={element === 'diplomas' ? item.university : item.place}
              onChange={(event) => {
                handleChangeInputItem(event, item.id, element);
              }}
              type="text"
              name={element === 'diplomas' ? 'university' : 'place'}
              placeholder="Ecrire ici..."
            />
            <i className="fas fa-building"></i>
          </div>
        </div>
        <div
          className={
            hasError && !item.date
              ? 'diploma_info_form_item type3'
              : 'diploma_info_form_item'
          }
          style={{ width: sandwich_menu_btn ? '16vw' : '19vw' }}
        >
          <label>Date</label>

          <div className="input_and_icon">
            <input
              required
              value={moment(item.date).format('YYYY-MM-DD')}
              onChange={(event) => {
                handleChangeInputItem(event, item.id, element);
              }}
              type="date"
              name="date"
              placeholder="Ecrire ici..."
            />
          </div>
        </div>
        {item.title ? (
          <div
            onClick={() => this.openDeleteModal()}
            className="delete_diploma_main_field_btn"
          >
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="35"
                height="35"
                rx="9.5"
                fill="white"
                stroke="#ECEEF5"
              />
              <path
                d="M23.2383 11.9883V13.5H12.7617V11.9883H15.3633L16.1367 11.25H19.8633L20.6367 11.9883H23.2383ZM13.5 23.2383V14.2383H22.5V23.2383C22.5 23.6367 22.3477 23.9883 22.043 24.293C21.7383 24.5977 21.3867 24.75 20.9883 24.75H15.0117C14.6133 24.75 14.2617 24.5977 13.957 24.293C13.6523 23.9883 13.5 23.6367 13.5 23.2383Z"
                fill="#8083A3"
              />
            </svg>
          </div>
        ) : (
          <div
            onClick={() => this.props.handleDeleteInputItem(item.id, element)}
            className="delete_diploma_fields_btn"
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="39"
                height="39"
                rx="9.5"
                fill="white"
                stroke="#ECEEF5"
              />
              <path
                d="M25.2383 15.8164L21.0547 20L25.2383 24.1836L24.1836 25.2383L20 21.0547L15.8164 25.2383L14.7617 24.1836L18.9453 20L14.7617 15.8164L15.8164 14.7617L20 18.9453L24.1836 14.7617L25.2383 15.8164Z"
                fill="#8083A3"
              />
            </svg>
          </div>
        )}
        <Modals
          setIsOpen={setIsOpenDeleteModal}
          openModal={this.openDeleteModal}
          data={
            <>
              <div className="modal-header" style={{ marginTop: '-14px' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openDeleteModal()}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '5px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Suppression!</h2>
                <span>Etes-vous sûr de vouloir supprimer</span>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => this.openDeleteModal()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                  style={{ backgroundColor: '#f6f6f6' }}
                >
                  Non
                </button>
                <button
                  onClick={() => {
                    if (element === 'diplomas') this.props.deleteDiploma(item.id, userId);
                    else this.props.deleteTrainings(item.id, userId);
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
      </div>
    );
  }
}
export default Form;
