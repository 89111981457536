import React, { Component } from 'react';
import { SelectorBilanItemSection, SelectorColorInputCheckbox } from '../../../../utils';
import UploadFile from '../../upload_file';

class ANoter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEntretien: true,
      showDouleur: false,
      showBilanArticulaire: false,
      showScore: false,
    };
    this.handleChangeSubSectionBtn = this.handleChangeSubSectionBtn.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    SelectorColorInputCheckbox();
    SelectorBilanItemSection();
  }
  handleChangeSubSectionBtn(name) {
    this.setState({
      [name]: !this.state[name],
    });
  }
  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }
  render() {
    const { sandwich_menu_btn, initialValues } = this.props;
    return (
      <div className="actual_bilan_settings">
        <div className="actual_bilan_settings_item head_shaking_test" id="headShaking1">
          <div
            style={{ backgroundColor: '#6c89bc' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}>Notes libres</span>
          </div>
          <br />
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '100%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Observations
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="additional_container_item_field">
                        <textarea
                          onChange={this.props.handleChangeInput}
                          maxLength="500"
                          style={{ width: '100%' }}
                          name="note_bilan"
                          value={initialValues.note_bilan}
                          rows={8}
                          placeholder="Taper les observations ici pour les sauvegarder..."
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  

<UploadFile
handleChangeDeleteFile={this.props.handleChangeDeleteFile}
                  
ChangeDeleteFile={this.props.ChangeDeleteFile}
                    handleChangeFile={this.props.handleChangeFile}
                    initialValues={initialValues}
                  />
                  

<UploadFile
handleChangeDeleteFile={this.props.handleChangeDeleteFile}
                  
ChangeDeleteFile={this.props.ChangeDeleteFile}
                    handleChangeFile={this.props.handleChangeFile}
                    initialValues={initialValues}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ANoter;
