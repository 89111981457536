import React, { Component } from 'react';
import InfoGenerale from './InfoGenerale';
import Securities from './Securities';
import DipolmeFormation from './DiplomeFormation';
import SettingMenuItemsProfil from '../../components/settingMenuItemsProfil';
import { connect } from 'react-redux';
import actions from '../../js/actions';
import SimpleReactValidator from 'simple-react-validator';

import './Abonnement/index.css';
import './DiplomeFormation/index.css';
import './InfoGenerale/index.css';
import './Notifications/index.css';
import './Securities/index.css';
import './index.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Motifs from './motifs';
import { isHasPermission } from '../../utils/helpres';

const mapStateToProps = (state) => ({
  deleteDiplome: state.ProfileReducer.deleteDiplome,
  deleteFormation: state.ProfileReducer.deleteFormation,
  diplome: state.ProfileReducer.diplome,
  formation: state.ProfileReducer.formation,
  changePassword: state.ProfileReducer.changePassword,
  isLoadingGetUser: state.AppReducer.isLoadingGetUser,
  isLoading: state.AppReducer.isLoading,
  getUser: state.AppReducer.getUser,
  countConsultation: state.AppReducer.countConsultation,
  countPatient: state.AppReducer.countPatient,
  user: state.AppReducer.user,
  error: state.AppReducer.error,
  verifyAccountUser: state.AppReducer.verifyAccount,
  updateUser: state.AppReducer.updateUser,
});

const mapDispatchToProps = (dispatch) => ({
  getUserById: (id) => dispatch(actions.getUserById(id)),
  verifyAccount: (id) => dispatch(actions.verifyAccount(id)),
  changeActiveItemSideBarSettings: (item, data, create) =>
    dispatch(actions.changeActiveItemSideBarSettings(item, data, create)),
});

class Profil extends Component {
  constructor(props) {
    super(props);
    props.getUserById(props.user.id);
    props.verifyAccount(props.user.id);
    props.changeNamePage('Profil');
    props.changeActiveItemSideBar(0);
    props.changeActiveItemSideBarSettings(111, false, true);
    this.state = {
      item: 0,
      initialValues: {
        ...props.initialValues,
        alert: false,
      },
      activeTab: '1',
      showInfo: true,
      showSecurite: false,
      showDiplome: false,
      showNotif: false,
      showAbonnement: false,
      showMotif: false,
      file: null,
      isOpenToggleDiplome: false,
      isOpenToggleFormation: false,
      hasError: false,
    };

    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        // OR
        default: 'Cette information est requise.', // will override all messages
      },
    });
    this.HandelChangePage = this.HandelChangePage.bind(this);
    this.handleChangeFiles = this.handleChangeFiles.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  HandelChangePage(item) {
    switch (item) {
      case 0:
        this.setState({
          showInfo: true,
          showMotif: false,
          showSecurite: false,
          showDiplome: false,
          showNotif: false,
          showAbonnement: false,
        });
        break;
      case 1:
        this.setState({
          showInfo: false,
          showSecurite: true,
          showMotif: false,
          showDiplome: false,
          showNotif: false,
          showAbonnement: false,
        });
        break;
      case 2:
        this.setState({
          showInfo: false,
          showSecurite: false,
          showDiplome: true,
          showNotif: false,
          showMotif: false,
          showAbonnement: false,
        });
        break;
      case 3:
        this.setState({
          showInfo: false,
          showSecurite: false,
          showDiplome: false,
          showMotif: true,
          showNotif: false,
          showAbonnement: false,
        });
        break;
      case 4:
        this.setState({
          showInfo: false,
          showSecurite: false,
          showDiplome: false,
          showNotif: true,
          showMotif: false,
          showAbonnement: false,
        });
        break;
      case 5:
        this.setState({
          showInfo: false,
          showSecurite: false,
          showMotif: false,
          showDiplome: false,
          showNotif: false,
          showAbonnement: true,
        });
        break;
      default:
        this.setState({
          showInfo: true,
          showSecurite: false,
          showDiplome: false,
          showNotif: false,
          showMotif: false,
          showAbonnement: false,
        });
    }
  }

  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }

  handleChangeFiles = (event, name) => {
    const fileUploaded = event.target.files[0];
    this.setState({
      [name]: fileUploaded,
    });
  };

  render() {
    const { getUser, admin, user, isLoadingGetUser } = this.props;
    const { file, showInfo, showSecurite, showDiplome, showMotif } = this.state;
    /*if (isLoadingGetUser) {
      return (
        <CircularProgress disableShrink style={{ marginLeft: '33%', marginTop: '20%' }} />
      );
    }*/
    return (
      <div className="main_content" style={{ paddingLeft: 0 }}>
        <div className="user_info">
          <div className="user_img_name">
            <div className="user_img">
              {isLoadingGetUser ? (
                <div className="patient_avatar">
                  <svg
                    width="33"
                    height="34"
                    viewBox="0 0 33 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.73958 23.2812C9.42708 21.7812 12.9583 21.0312 16.3333 21.0312C19.7083 21.0312 23.2083 21.7812 26.8333 23.2812C30.5208 24.7188 32.3646 26.625 32.3646 29V33.0312H0.302084V29C0.302084 26.625 2.11458 24.7188 5.73958 23.2812ZM21.9583 14.6562C20.3958 16.2188 18.5208 17 16.3333 17C14.1458 17 12.2708 16.2188 10.7083 14.6562C9.14583 13.0938 8.36458 11.2188 8.36458 9.03125C8.36458 6.84375 9.14583 4.96875 10.7083 3.40625C12.2708 1.78125 14.1458 0.96875 16.3333 0.96875C18.5208 0.96875 20.3958 1.78125 21.9583 3.40625C23.5208 4.96875 24.3021 6.84375 24.3021 9.03125C24.3021 11.2188 23.5208 13.0938 21.9583 14.6562Z"
                      fill="#8083A3"
                    />
                  </svg>
                </div>
              ) : !this.state.file &&
                getUser.image_practitioners &&
                getUser.image_practitioners.length === 0 ? (
                <div className="patient_avatar">
                  <svg
                    width="33"
                    height="34"
                    viewBox="0 0 33 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.73958 23.2812C9.42708 21.7812 12.9583 21.0312 16.3333 21.0312C19.7083 21.0312 23.2083 21.7812 26.8333 23.2812C30.5208 24.7188 32.3646 26.625 32.3646 29V33.0312H0.302084V29C0.302084 26.625 2.11458 24.7188 5.73958 23.2812ZM21.9583 14.6562C20.3958 16.2188 18.5208 17 16.3333 17C14.1458 17 12.2708 16.2188 10.7083 14.6562C9.14583 13.0938 8.36458 11.2188 8.36458 9.03125C8.36458 6.84375 9.14583 4.96875 10.7083 3.40625C12.2708 1.78125 14.1458 0.96875 16.3333 0.96875C18.5208 0.96875 20.3958 1.78125 21.9583 3.40625C23.5208 4.96875 24.3021 6.84375 24.3021 9.03125C24.3021 11.2188 23.5208 13.0938 21.9583 14.6562Z"
                      fill="#8083A3"
                    />
                  </svg>
                </div>
              ) : (
                <img
                  src={
                    this.state.file
                      ? URL.createObjectURL(this.state.file)
                      : getUser.image_practitioners &&
                        getUser.image_practitioners.length > 0
                      ? `${process.env.REACT_APP_PUBLIC_URL}${getUser.image_practitioners[0].url}`
                      : getUser.photo
                      ? `${process.env.REACT_APP_PUBLIC_URL}${getUser.photo}`
                      : require('../../assets/img/kar.jpg')
                  }
                  alt=""
                />
              )}

              <input
                onChange={(e) => this.handleChangeFiles(e, 'file')}
                type="file"
                ref="fileUploaderDoctor"
                name="file"
                hidden
                accept=".png, .jpg,.jpeg"
              />
              {showInfo ? (
                <i
                  onClick={() => this.handleClick('fileUploaderDoctor')}
                  className="fa fa-camera"
                ></i>
              ) : (
                ''
              )}
            </div>
            <div className="user_name">
              <span className="name">{`${getUser.first_name} ${getUser.last_name}`}</span>
              <span className="job">{getUser.speciality && getUser.speciality.name}</span>
            </div>
          </div>
          <div className="settings_menu">
            <SettingMenuItemsProfil
              activate={true}
              item={0}
              HandelChangePage={this.HandelChangePage}
              icon="fas fa-user-circle"
              name="Informations générales"
              description="Nom et langue"
            />
            <hr />
            <SettingMenuItemsProfil
              item={1}
              HandelChangePage={this.HandelChangePage}
              icon="fas fa-shield-alt"
              name="Sécurité"
              description="Mot de passe & questions de sécurité"
            />
            <hr />
            {user.login_access.account_type === 'user' ? (
              <>
                <SettingMenuItemsProfil
                  item={2}
                  HandelChangePage={this.HandelChangePage}
                  icon="fa fa-briefcase"
                  name="Diplome & Formation"
                  description="Diplome, Attestations"
                />
                <hr />
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="user_settings">
          {isLoadingGetUser ? (
            <CircularProgress
              disableShrink
              style={{ marginLeft: '45%', marginTop: '23%' }}
            />
          ) : (
            <div className="container_changeable_content">
              {showInfo ? (
                <InfoGenerale admin={admin} file={file} getUser={getUser} />
              ) : showSecurite ? (
                <Securities file={file} getUser={getUser} />
              ) : showDiplome && user.login_access.account_type === 'user' ? (
                <DipolmeFormation goBack={this.goBack} file={file} getUser={getUser} />
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profil);
