import { apiCall } from '../../../js/actions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastAction from '../../../components/ToastAction';
import React from 'react';

export const ADD_CONTACT_SUCCESS = 'ADD_CONTACT_SUCCESS';

const submitContact = (values) => async (dispatch) => {
  dispatch({ type: 'ADD_CONTACT_REQUESTING' });
  try {
    let url = `/api/contact`;
    const { data } = await apiCall({
      method: 'post',
      url,
      data: values,
    });
    if (data.type === 'success') {
      dispatch({
        type: 'ADD_CONTACT_SUCCESS',
        data: data.data,
      });
    }
  } catch (e) {
   /* toast(({ closeToast }) => <ToastAction type="error" message="Problème d'envoie" />, {
      hideProgressBar: true,
    });*/
    //toast.error(`Problème d'envoie`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'ADD_CONTACT_FAILURE',
      err: e.message,
    });
  }
};

const sendMail = (values) => async (dispatch) => {
  dispatch({ type: 'SEND_MAIL_REQUESTING' });
  try {
    let url = `/api/send-mail`;
    const { data } = await apiCall({
      method: 'post',
      url,
      data: values,
    });
    if (data.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message="Votre message a été envoyé avec succès, nous reviendrons vers vous dans les plus courts délais"
          />
        ),
        { hideProgressBar: true },
      );
      // toast.success(`Envoyé avec succès`, {position: toast.POSITION.TOP_CENTER});
    }
    dispatch({
      type: 'SEND_MAIL_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    toast(({ closeToast }) => <ToastAction type="error" message="Echec d'envoi de mail" />, {
      hideProgressBar: true,
    });
    //toast.error(`Problème d'envoie`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'SEND_MAIL_FAILURE',
      err: e.message,
    });
  }
};

export default {
  sendMail,
  submitContact,
};
