import React, { Component } from 'react';
import './index.css';
import actions from './store/actions';
import actionsJs from '../../js/actions';
import { connect } from 'react-redux';
import Item_bill from './containers/item_bill';
import moment from 'moment';
import { SelectorContactFacturesBodyItem } from '../../utils';
import ReceiptForm from './containers/facture';
import CircularProgress from '@material-ui/core/CircularProgress';

const mapStateToProps = (state) => ({
  receiptsByIds: state.ReceiptReducer.receiptsByIds,
  user: state.AppReducer.user,
  receipt: state.ReceiptReducer.receipt,
  receipts: state.ReceiptReducer.receipts,
  isLoadingReceipts: state.ReceiptReducer.isLoadingReceipts,
  isLoadingReceipt: state.ReceiptReducer.isLoadingReceipt,
  params: state.ReceiptReducer.params,
  office: state.AppReducer.office,
});

const mapDispatchToProps = (dispatch) => ({
  getOfficeByIdDoctor: () => dispatch(actionsJs.getOfficeByIdDoctor()),
  getReceiptById: (id) => dispatch(actions.getReceiptById(id)),
  submitSendBill: (id) => dispatch(actions.submitSendBill(id)),
  getAllReceipt: (params, type) => dispatch(actions.getAllReceipt(params, type)),

  getReceiptByIds: (ids) => dispatch(actions.getReceiptByIds(ids)),

  changeTag: (tag) => dispatch(actionsJs.changeTag(tag)),

  changeSearched: async (params, type) => {
    await dispatch(actions.changeSearchedStringReceipt(params));
    dispatch(actions.getAllReceipt(params, type));
  },
  submitUpdateReceiptForm: async (values) => {
    await dispatch(actions.submitUpdateReceiptForm(values));
    dispatch(actions.getReceiptById(values.id));
  },
  changeSearchedStatus: async (params, type) => {
    await dispatch(actions.changeSearchedStringReceipt(params));
    dispatch(actions.getAllReceipt(params, type));
  },
  changeSearchedEndDate: async (params, type) => {
    await dispatch(actions.changeSearchedStringReceipt(params));
    dispatch(actions.getAllReceipt(params, type));
  },
  changeSearchedStartDate: async (params, type) => {
    await dispatch(actions.changeSearchedStringReceipt(params));
    dispatch(actions.getAllReceipt(params, type));
  },
  changeSearchedStringLimit: async (params, type) => {
    await dispatch(actions.changeSearchedStringReceipt(params));
    dispatch(actions.getAllReceipt(params, type));
  },
  changeActiveItemSideBarSettings: (item, data, create) =>
    dispatch(actionsJs.changeActiveItemSideBarSettings(item, data, create)),
});

class Receipts extends Component {
  constructor(props) {
    super(props);
    props.changeTag('receipt');
    props.getAllReceipt(props.params, 'receipt');
    props.changeNamePage('Reçu');
    props.getOfficeByIdDoctor();
    props.changeActiveItemSideBarCompta(3023);
    document.title = 'Reçu';
    this.state = {
      errorDate: false,
    };
  }
  componentDidMount() {
    SelectorContactFacturesBodyItem();
    this.props.changeSearchedStartDate(
      {
        limit: 6,
        currentPage: 1,
        start_date: '',
        end_date: '',
        search: '',
        status: '',
        type: '',
      },
      'receipt',
    );
  }
  handleSelectedBill(selectedBill) {
    this.setState({
      selectedBill: !selectedBill,
    });
  }

  render() {
    const {
      params,
      sendBillByEmail,
      receipts,
      receipt,
      isLoadingReceipts,
      isLoadingReceipt,
      selectedId,
      office,
    } = this.props;
    const { errorDate } = this.state;
    const { data } = receipts;
    return (
      <div>
        <div className="main_content">
          <div className="facture_patient">
            <div className="facture_patient_header">
              <p>Rechercher un reçu</p>
              <div className="facture_patient_controls">
                <input
                  style={{ width: '100%', marginLeft: '-7%' }}
                  onChange={(event) => {
                    this.props.changeSearched(
                      {
                        ...params,
                        search: event.target.value ? event.target.value : '',
                      },
                      'receipt',
                    );
                  }}
                  type="text"
                  placeholder="Nom,  ou numéro de reçu"
                />
                {/*<select
                  style={{ width: '50%' }}
                  id="inputState"
                  className="form-control"
                  name="status"
                  value={params.status}
                  onChange={(event, option) => {
                    this.props.changeSearchedStatus(
                      {
                        ...params,
                        status: event.target.value,
                      },
                      'receipt',
                    );
                  }}
                >
                  <option selected value="">
                    Statut
                  </option>
                  <option value={0}>Non payé</option>
                  <option value={1}>Payé</option>
                </select>*/}
              </div>
            </div>
            <div className="facture_patient_controls">
              <input
                style={{
                  width: '50%',
                  borderBottom: errorDate ? '1px solid #ff808b' : '',
                }}
                id="date"
                type="date"
                value={
                  params.start_date ? moment(params.start_date).format('YYYY-MM-DD') : ''
                }
                onChange={(e) => {
                  this.props.changeSearchedStartDate(
                    {
                      ...params,
                      start_date: e.target.value
                        ? moment(e.target.value).format('YYYY-MM-DD')
                        : '',
                    },
                    'receipt',
                  );
                }}
              />
              <input
                style={{
                  width: '50%',
                  borderBottom: errorDate ? '1px solid #ff808b' : '',
                }}
                id="date"
                type="date"
                className="type3"
                value={
                  params.end_date ? moment(params.end_date).format('YYYY-MM-DD') : ''
                }
                onChange={(e) => {
                  if (moment(params.start_date).isAfter(moment(e.target.value))) {
                    this.setState({
                      errorDate: true,
                    });
                    this.props.changeSearchedEndDate(
                      {
                        ...params,
                        end_date: e.target.value
                          ? moment(e.target.value).format('YYYY-MM-DD')
                          : '',
                      },
                      'receipt',
                    );
                  } else {
                    this.setState({
                      errorDate: false,
                    });
                    this.props.changeSearchedEndDate(
                      {
                        ...params,
                        end_date: e.target.value
                          ? moment(e.target.value).format('YYYY-MM-DD')
                          : '',
                      },
                      'receipt',
                    );
                  }
                }}
              />
            </div>
            {isLoadingReceipts ? (
              <CircularProgress
                disableShrink
                style={{ marginLeft: '47%', marginTop: '30%' }}
              />
            ) : data && data.length > 0 ? (
              <div className="facture_patients_body">
                {data.map((item) => {
                  return (
                    <Item_bill
                      key={item.id}
                      getReceiptById={this.props.getReceiptById}
                      tag="receipt"
                      item={item}
                      id={
                        selectedId
                          ? parseInt(selectedId)
                          : data && data.length > 0 && data[0].id
                      }
                    />
                  );
                })}
              </div>
            ) : (
              ''
            )}
          </div>

          <div className="facture_info_section">
            {data && data.length === 0 ? (
              <div className="container_changeable_content">
                <div className="fatcure_detailed_info_section">
                  <div
                    style={{ marginTop: '10%' }}
                    className="empty_consultation_result_container"
                  >
                    <div className="patient_detailed_info_section_body">
                      <div className="empty_consultation_result">
                        <img
                          src={require('../../assets/img/undraw_design_team_af2y.svg')}
                        />
                        <span id="no_consultation">Pas de Reçus !</span>
                        <span id="no_consultation_desc">
                          Essayez de créer des nouveaux reçus. Si vous n'avez pas des
                          cures, commencez déja par en créer des nouvelles.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : isLoadingReceipt ? (
              <CircularProgress
                disableShrink
                style={{ marginLeft: '47%', marginTop: '30%' }}
              />
            ) : data && data.length === 0 && !isLoadingReceipt ? (
              <div className="container_changeable_content">
                <div className="fatcure_detailed_info_section">
                  <div
                    style={{ marginTop: '10%' }}
                    className="empty_consultation_result_container"
                  >
                    <div className="patient_detailed_info_section_body">
                      <div className="empty_consultation_result">
                        <img
                          src={require('../../assets/img/undraw_design_team_af2y.svg')}
                        />
                        <span id="no_consultation">Pas de Reçus !</span>
                        <span id="no_consultation_desc">
                          Essayez de créer des nouveaux récus. Si vous n'avez pas des
                          cures, commencez déja par en créer des nouvelles.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <ReceiptForm
                submitUpdateReceiptForm={this.props.submitUpdateReceiptForm}
                submitSendBill={this.props.submitSendBill}
                sendBillByEmail={sendBillByEmail}
                receipt={receipt}
                office={office}
                tag="receipt"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Receipts);
