import React, { Component } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import InputField from '../../../components/inputField';
import CircularProgress from '@material-ui/core/CircularProgress';

class Faq extends Component {
  render() {
    const { user, initialValues, isLoadingAddContact, hasError } = this.props;
    return (
      <div className="offers">
        <div className="offre_details" style={{ width: '100%' }}>
          <div className="offre_details_container">
            <div className="offre_details_content">
              <div className="contact_us">
                <div className="contact_us_text">
                  <span id="contact_us_title">Besoin d'aide ?</span>
                  <span id="contact_us_subtext">
                    Nous ne vous laissons jamais seul ! Nos conseillers se mobilisent pour
                    votre support 24/7.
                  </span>
                </div>
                <div className="contact_us_buttons">
                  <div className="send_mail_btn_container">
                    <i className="fas fa-envelope"></i>
                    <span
                      style={{
                        color: '#8181a5',
                        inlineSize: 'max-content',
                      }}
                    >
                      support@cuida.tn
                    </span>
                  </div>
                  <div className="send_mail_btn_container">
                    <i className="fas fa-phone-square-alt"></i>
                    <span
                      style={{
                        color: '#8181a5',
                        inlineSize: 'max-content',
                      }}
                    >
                      75 733 834
                    </span>
                  </div>
                  <div className="send_mail_btn_container">
                    <i className="fab fa-whatsapp-square"></i>
                    <span
                      style={{
                        color: '#8181a5',
                        inlineSize: 'max-content',
                      }}
                    >
                      50 733 834
                    </span>
                  </div>
                </div>
              </div>
              <div className="offre_list">
                <div
                  style={{
                    marginBottom: '-6%',
                  }}
                  className="offre_list_header"
                >
                  <div className="offre_list_header_text">
                    <span id="offre_list_header_text_title"> Contacter le support</span>
                  </div>
                </div>
                <div className="offer_list_body">
                  <div className="offer_cards">
                    <div className="offer_cards_container">
                      <form>
                        <div
                          className="form-row"
                          style={{
                            display: 'flex',
                            gap: '30px',
                            margin: '20px 0px',
                          }}
                        >
                          <InputField
                            width="46.5%"
                            type="text"
                            placeholder="Entrer votre nom"
                            className=""
                            validInput="string"
                            label="Nom"
                            hasError={hasError}
                            value={user.first_name}
                            name="first_name"
                            icon={<i className="fas fa-user"></i>}
                            onChange={this.props.handleChangeInput}
                          />
                          <InputField
                            width="46.5%"
                            type="text"
                            placeholder="Entrer votre prÃ©nom"
                            className=""
                            validInput="string"
                            label="Prénom"
                            hasError={hasError}
                            value={user.last_name}
                            name="last_name"
                            icon={<i className="fas fa-user"></i>}
                            onChange={this.props.handleChangeInput}
                          />
                        </div>
                        <div
                          className="form-row"
                          style={{
                            display: 'flex',
                            gap: '30px',
                            margin: '20px 0px',
                          }}
                        >
                          <InputField
                            width="46.5%"
                            className=""
                            type="text"
                            placeholder="Votre e-mail"
                            label="Adresse mail"
                            hasError={hasError}
                            value={user.email || initialValues.email}
                            name="email"
                            icon={<i className="fas fa-envelope"></i>}
                            onChange={this.props.handleChangeInput}
                          />
                          <InputField
                            width="46.5%"
                            className=""
                            type="text"
                            placeholder="Votre tÃ©lÃ©phone"
                            label="Téléphone"
                            hasError={hasError}
                            validInput="tel"
                            value={user.gsm || initialValues.gsm}
                            name="gsm"
                            icon={<i className="fas fa-phone-alt"></i>}
                            onChange={this.props.handleChangeInput}
                          />
                        </div>
                        <div
                          className="form-row"
                          style={{
                            width: '98%',
                          }}
                        >
                          <div
                            style={{ width: '100%' }}
                            className={
                              hasError && !initialValues.message
                                ? 'col-md-12 mb-3 add_dispo_item commentaire type3'
                                : 'col-md-12 mb-3 commentaire add_dispo_item commentaire'
                            }
                          >
                            <span id="date_de_debut_title">
                              Message ({initialValues.message.length}/500)
                            </span>
                            <div className="date_de_debut_icon input_and_icon">
                              <textarea
                                maxLength="500"
                                rows={5}
                                style={{ width: '100%' }}
                                onChange={this.props.handleChangeInput}
                                value={initialValues.message}
                                name="message"
                                className="sub_text_container"
                                id="date_de_debut_text"
                                placeholder="Votre message"
                              ></textarea>
                            </div>
                            <span></span>
                          </div>
                        </div>
                        <div className="footer_buttons">
                          <button
                            onClick={() => {
                              this.props.submitForm(initialValues);
                            }}
                            type="button"
                            className="footer_btn"
                            id="update"
                            style={{
                              marginLeft: '85%',
                            }}
                          >
                            {isLoadingAddContact ? (
                              <CircularProgress
                                style={{
                                  marginLeft: '4%',
                                  width: '25px',
                                  height: '25px',
                                  color: 'white',
                                  marginTop: '5%',
                                }}
                              />
                            ) : (
                              'Envoyer'
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Faq;
