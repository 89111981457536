import React, { Component } from 'react';
import { diffDate } from '../../../utils/helpres';

class Messages extends Component {
  constructor(props) {
    super(props);
    this.boxRef = React.createRef();
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    this.boxRef.current.scrollTop = this.boxRef.current.scrollHeight;
  };

  componentDidUpdate = () => {
    this.scrollToBottom();
  };

  render() {
    const { messages, user, conversation } = this.props;
    return (
      <div ref={this.boxRef} className="chat_container" style={{ marginTop: '2%' }}>
        <div className="chat_content">
          <div className="date_based_conversation_container">
            <div className="date_based_conversation_container_body">
              {!conversation.office_id
                ? messages &&
                  messages.length > 0 &&
                  messages.map((message) => {
                    if (conversation.id === message.conversation_id)
                      if (
                        message.sender_id === user.login_access.id &&
                        message.deleted_from !== user.login_access.id
                      )
                        return (
                          <div className="date_based_conversation_container_item current_user_item">
                            <div className="current_user">
                              <div className="current_user_chat_container">
                                <div className="current_user_chat_content">
                                  <div className="actual_chat_container">
                                    <div className="actual_chat_item">
                                      <div className="sent_message">
                                        <span
                                          style={{
                                            fontWeight:
                                              message.seen === 1 ? 'normal' : 'bold',
                                          }}
                                        >
                                          {message.content}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="current_user_img">
                                    {message.user ? (
                                      message.user.practitioner.image_practitioners
                                        .length > 0 ? (
                                        <img
                                          src={`${process.env.REACT_APP_PUBLIC_URL}${message.user.practitioner.image_practitioners[0].url}`}
                                          alt=""
                                        />
                                      ) : (
                                        <div className="patient_contact_img_container">
                                          <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                                              fill="#8083A3"
                                            />
                                          </svg>
                                        </div>
                                      )
                                    ) : message.assistant ? (
                                      message.assistant.assistant.photo ? (
                                        <img
                                          src={`${process.env.REACT_APP_PUBLIC_URL}${message.assistant.assistant.photo}`}
                                          alt=""
                                        />
                                      ) : (
                                        <div className="patient_contact_img_container">
                                          <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                                              fill="#8083A3"
                                            />
                                          </svg>
                                        </div>
                                      )
                                    ) : user.secretary &&
                                      user.secretary.secretary.photo ? (
                                      <img
                                        src={`${process.env.REACT_APP_PUBLIC_URL}${message.secretary.secretary.photo}`}
                                        alt=""
                                      />
                                    ) : (
                                      <div className="patient_contact_img_container">
                                        <svg
                                          width="14"
                                          height="14"
                                          viewBox="0 0 14 14"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                                            fill="#8083A3"
                                          />
                                        </svg>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="message_time">
                                  <span>{diffDate(message.created_at)}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      else if (message.deleted_from !== user.login_access.id)
                        return (
                          <div className="date_based_conversation_container_item other_user_item">
                            <div className="other_user">
                              <div className="other_user_chat_container">
                                <div className="other_user_chat_content">
                                  <div className="other_user_img">
                                    {message.user ? (
                                      message.user.practitioner.image_practitioners
                                        .length > 0 ? (
                                        <img
                                          src={`${process.env.REACT_APP_PUBLIC_URL}${message.user.practitioner.image_practitioners[0].url}`}
                                          alt=""
                                        />
                                      ) : (
                                        <div className="patient_contact_img_container">
                                          <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                                              fill="#8083A3"
                                            />
                                          </svg>
                                        </div>
                                      )
                                    ) : message.assistant ? (
                                      message.assistant.assistant.photo ? (
                                        <img
                                          src={`${process.env.REACT_APP_PUBLIC_URL}${message.assistant.assistant.photo}`}
                                          alt=""
                                        />
                                      ) : (
                                        <div className="patient_contact_img_container">
                                          <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                                              fill="#8083A3"
                                            />
                                          </svg>
                                        </div>
                                      )
                                    ) : user.secretary &&
                                      user.secretary.secretary.photo ? (
                                      <img
                                        src={`${process.env.REACT_APP_PUBLIC_URL}${message.secretary.secretary.photo}`}
                                        alt=""
                                      />
                                    ) : (
                                      <div className="patient_contact_img_container">
                                        <svg
                                          width="14"
                                          height="14"
                                          viewBox="0 0 14 14"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                                            fill="#8083A3"
                                          />
                                        </svg>
                                      </div>
                                    )}
                                  </div>

                                  <div className="actual_chat_container">
                                    <div className="actual_chat_item">
                                      <div className="sent_message">
                                        <span
                                          style={{
                                            fontWeight:
                                              message.seen === 1 ? 'normal' : 'bold',
                                          }}
                                        >
                                          {message.content}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="message_time">
                                  <span>{diffDate(message.created_at)}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                  })
                : messages &&
                  messages.length > 0 &&
                  messages.map((message) => {
                    if (conversation.id === message.conversation_id)
                      if (
                        message.sender_id === user.login_access.id &&
                        message.deleted_from !== user.login_access.id
                      )
                        return (
                          <div className="date_based_conversation_container_item current_user_item">
                            <div className="current_user">
                              <div className="current_user_chat_container">
                                <div className="current_user_chat_content">
                                  <div className="actual_chat_container">
                                    <div className="actual_chat_item">
                                      <div className="sent_message">
                                        <span
                                          style={{
                                            fontWeight:
                                              message.seen === 1 ? 'normal' : 'bold',
                                          }}
                                        >
                                          {message.content}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="current_user_img">
                                    {/*{message.user*/}
                                    {/*  ? `${message.user.practitioner.first_name} ${message.user.practitioner.last_name}`*/}
                                    {/*  : message.assistant*/}
                                    {/*  ? `${message.assistant.assistant.first_name} ${message.assistant.assistant.last_name}`*/}
                                    {/*  : `${message.secretary.secretary.first_name} ${message.secretary.secretary.last_name}`}*/}
                                    {message.user ? (
                                      message.user.practitioner.image_practitioners
                                        .length > 0 ? (
                                        <img
                                          src={`${process.env.REACT_APP_PUBLIC_URL}${message.user.practitioner.image_practitioners[0].url}`}
                                          alt=""
                                        />
                                      ) : (
                                        <div className="patient_contact_img_container">
                                          <span
                                            style={{
                                              verticalAlign: 'sub',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            {`${message.user.practitioner.first_name.charAt(
                                              0,
                                            )}${message.user.practitioner.last_name.charAt(
                                              0,
                                            )}`}
                                          </span>
                                        </div>
                                      )
                                    ) : message.assistant ? (
                                      message.assistant.assistant.photo ? (
                                        <img
                                          src={`${process.env.REACT_APP_PUBLIC_URL}${message.assistant.assistant.photo}`}
                                          alt=""
                                        />
                                      ) : (
                                        <div className="patient_contact_img_container">
                                          <span
                                            style={{
                                              verticalAlign: 'sub',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            {`${message.assistant.assistant.first_name.charAt(
                                              0,
                                            )}${message.assistant.assistant.last_name.charAt(
                                              0,
                                            )}`}
                                          </span>
                                        </div>
                                      )
                                    ) : user.secretary &&
                                      user.secretary.secretary.photo ? (
                                      <img
                                        src={`${process.env.REACT_APP_PUBLIC_URL}${message.secretary.secretary.photo}`}
                                        alt=""
                                      />
                                    ) : (
                                      <div className="patient_contact_img_container">
                                        <span
                                          style={{
                                            verticalAlign: 'sub',
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {`${message.secretary.secretary.first_name.charAt(
                                            0,
                                          )}${message.secretary.secretary.last_name.charAt(
                                            0,
                                          )}`}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="message_time">
                                  <span>{diffDate(message.created_at)}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      else if (message.deleted_from !== user.login_access.id)
                        return (
                          <div className="date_based_conversation_container_item other_user_item">
                            <div className="other_user">
                              <div className="other_user_chat_container">
                                <div className="other_user_chat_content">
                                  <div className="other_user_img">
                                    {/*{message.user
                                      ? `${message.user.practitioner.first_name} ${message.user.practitioner.last_name}`
                                      : message.assistant
                                      ? `${message.assistant.assistant.first_name} ${message.assistant.assistant.last_name}`
                                      : `${message.secretary.secretary.first_name} ${message.secretary.secretary.last_name}`}*/}
                                    {message.user ? (
                                      message.user.practitioner.image_practitioners
                                        .length > 0 ? (
                                        <img
                                          src={`${process.env.REACT_APP_PUBLIC_URL}${message.user.practitioner.image_practitioners[0].url}`}
                                          alt=""
                                        />
                                      ) : (
                                        <div className="patient_contact_img_container">
                                          <span
                                            style={{
                                              verticalAlign: 'sub',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            {`${message.user.practitioner.first_name.charAt(
                                              0,
                                            )}${message.user.practitioner.last_name.charAt(
                                              0,
                                            )}`}
                                          </span>
                                          {/*<svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                                              fill="#8083A3"
                                            />
                                          </svg>*/}
                                        </div>
                                      )
                                    ) : message.assistant ? (
                                      message.assistant.assistant.photo ? (
                                        <img
                                          src={`${process.env.REACT_APP_PUBLIC_URL}${message.assistant.assistant.photo}`}
                                          alt=""
                                        />
                                      ) : (
                                        <div className="patient_contact_img_container">
                                          <span
                                            style={{
                                              verticalAlign: 'sub',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            {`${message.assistant.assistant.first_name.charAt(
                                              0,
                                            )}${message.assistant.assistant.last_name.charAt(
                                              0,
                                            )}`}
                                          </span>
                                        </div>
                                      )
                                    ) : user.secretary &&
                                      user.secretary.secretary.photo ? (
                                      <img
                                        src={`${process.env.REACT_APP_PUBLIC_URL}${message.secretary.secretary.photo}`}
                                        alt=""
                                      />
                                    ) : (
                                      <div className="patient_contact_img_container">
                                        <span
                                          style={{
                                            verticalAlign: 'sub',
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {`${message.secretary.secretary.first_name.charAt(
                                            0,
                                          )}${message.secretary.secretary.last_name.charAt(
                                            0,
                                          )}`}
                                        </span>
                                      </div>
                                    )}
                                  </div>

                                  <div className="actual_chat_container">
                                    <div className="actual_chat_item">
                                      <div className="sent_message">
                                        <span
                                          style={{
                                            fontWeight:
                                              message.seen === 1 ? 'normal' : 'bold',
                                          }}
                                        >
                                          {message.content}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="message_time">
                                  <span>{diffDate(message.created_at)}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                  })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Messages;
