import React, { Component } from 'react';
import Modals from '../../../components/modal';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import update from 'immutability-helper';
import { SwipeableDrawer } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { generateSlotsAuto, isHasPermission, isNumeric } from '../../../utils/helpres';
import { Link } from 'react-router-dom';
import InputField from '../../../components/inputField';
import { toast } from 'react-toastify';
import ToastAction from '../../../components/ToastAction';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';

class Session extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      left: false,
      switchListSessions: false,
      setIsOpenCloseSession: false,
      setIsOpenDeleteRDV: false,
      isOpenAddSession: false,
      anchor: 'left',
      rdv: null,
      rdvId: null,
      initialValues: {
        getDispo: [],
        days: [],
        sessions: props.sessions,
        sessions_per_week: null,
        sessions_count: null,
        patient_id:
          props.consultation.patient_record &&
          props.consultation.patient_record.patient.id,
        patient:
          props.consultation.patient_record && props.consultation.patient_record.patient,
        duration: null,
        type: 1,
        start_date: moment().format('YYYY-MM-DD'),
        end_date: '',
        hourStart: '08:00',
        practitioner_id: props.user.id,
        consultation_id: props.consultation.id,
        unit_price: props.consultation.unit_price,
      },
      hasError: false,
    };
    this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
    this.onChangeHours = this.onChangeHours.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.submitFormConsultation = this.submitFormConsultation.bind(this);
    this.handleCheckedPec = this.handleCheckedPec.bind(this);
    this.submitGenerateSlotsAuto = this.submitGenerateSlotsAuto.bind(this);
    this.openDeleteModalRDV = this.openDeleteModalRDV.bind(this);
  }

  handleChangeInput(event) {
    const { initialValues } = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      initialValues: {
        ...initialValues,
        [name]: value,
      },
    });
  }

  onChangeCheckBox(value) {
    const { initialValues } = this.state;
    let days = [...initialValues.days];
    if (initialValues.sessions_per_week) {
      let countDays = initialValues.days.find((item) => item.checked === true);
      if (initialValues.sessions_per_week <= countDays.length) {
        if (initialValues.days.find((item) => item.checked === value)) {
          days = days.filter((item) => item.day !== value);
        } else
          toast(
            ({ closeToast }) => (
              <ToastAction
                type="info"
                message="Attention ! Le nbr des séances pas semaine saisi est inferieur au nbr jours selectionnés."
              />
            ),
            { hideProgressBar: true },
          );
      } else {
        if (days.length > 0) {
          if (days.find((item) => item.day === value))
            days = days.filter((item) => item.day !== value);
          else days.push({ day: value });
        } else days.push({ day: value });
      }
      this.setState({
        initialValues: {
          ...initialValues,
          days,
        },
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="info"
            message="Attention ! Vous n'avez pas saisi le nbr des séances pas semaine."
          />
        ),
        { hideProgressBar: true },
      );
    }
  }

  onChangeHours(week, value, name) {
    const { initialValues } = this.state;
    if (initialValues.sessions_per_week) {
      this.setState((prevState) => {
        const data = [...prevState.initialValues.getDispo];
        let days = [];
        var commentIndex = data.findIndex(function (c) {
          return c.id === week;
        });
        let updatedComment = data[commentIndex];
        if (name === 'day') {
          let existDay = data.filter(function (c) {
            return parseInt(c.indexVal) === parseInt(value);
          });
          if (existDay.length > 0) {
            toast(
              ({ closeToast }) => (
                <ToastAction
                  type="info"
                  message="Attention ! Vous avez séléctionné ce jour."
                />
              ),
              { hideProgressBar: true },
            );
          } else {
            updatedComment = update(data[commentIndex], {
              indexVal: {
                $set: parseInt(value),
              },
            });
          }
          //data.map((day) => days.push(day.indexVal));
          /*days = days.filter((item) => item === parseInt(index));
          days.push(index);*/
        }

        if (name === 'hour_start') {
          let startDate = moment(value, 'h:mma');
          let endDate = moment(data[commentIndex].hour_end, 'h:mma').add(
            initialValues.duration || 60,
            'minutes',
          );
          updatedComment = update(data[commentIndex], {
            hour_start: {
              $set: value,
            },
            hour_end: {
              $set: moment(`12-12-2022 ${value}`)
                .add(initialValues.duration || 60, 'minutes')
                .format('HH:mm'),
            },
          });
        }
        if (name === 'hour_end') {
          let startDate = moment(data[commentIndex].hour_start, 'h:mma');
          let endDate = moment(value, 'h:mma');
          if (startDate.isAfter(endDate))
            toast(
              ({ closeToast }) => (
                <ToastAction
                  type="info"
                  message="Attention ! Veuillez saisir une heure de fin supérieur à l'heure de début."
                />
              ),
              { hideProgressBar: true },
            );

          if (startDate.isBefore(endDate))
            updatedComment = update(data[commentIndex], {
              hour_end: {
                $set: value,
              },
            });
        }
        //if (value && name)

        data[commentIndex] = updatedComment;
        this.setState({
          initialValues: {
            ...initialValues,
            getDispo: data,
            // days,
          },
        });
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="info"
            message="Attention ! Vous n'avez pas saisi le nbr des séances pas semaine."
          />
        ),
        { hideProgressBar: true },
      );
    }
  }

  submitFormConsultation(initialValues) {
    const { sessions, consultation } = this.props;

    if (
      !initialValues.start_date ||
      (initialValues.type === 1 && !initialValues.practitioner_id) ||
      !initialValues.hourStart ||
      !initialValues.sessions_count ||
      !initialValues.sessions_per_week ||
      (initialValues.sessions_per_week &&
        isNumeric(initialValues.sessions_per_week) &&
        initialValues.sessions_per_week > 6) ||
      (initialValues.sessions_per_week && !isNumeric(initialValues.sessions_per_week)) ||
      (initialValues.sessions_count &&
        isNumeric(initialValues.sessions_count) &&
        initialValues.sessions_count > 36) ||
      (initialValues.sessions_count && !isNumeric(initialValues.sessions_count))
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else {
      if (initialValues.type === 1) {
        this.setState({ isOpenAddSession: true });
        //initialValues.sessions_count = initialValues.sessions_per_week;
        this.props.getDispoDoctor(initialValues, this.props.consultation.id);
      } else {
        this.props.gotToAgendaManuel(initialValues, consultation);
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { closeSession } = this.props;
    if (closeSession !== prevProps.closeSession)
      this.setState({
        setIsOpenCloseSession: !this.state.setIsOpenCloseSession,
      });
  }
  componentWillReceiveProps(nextProps, nextContext) {
    const { getDispoDate } = nextProps;
    const { initialValues } = this.state;
    if (getDispoDate && getDispoDate.length > 0) {
      let days = [];
      let daysIndex = [];
      getDispoDate.map((item) => {
        const timeEnd = moment(initialValues.hourStart, 'HH:mm');
        days.push({
          id: item.week,
          hour_start: initialValues.hourStart,
          hour_end: moment()
            .set({ hour: timeEnd.get('hour'), minute: timeEnd.get('minute') })
            .add(initialValues.duration || 60, 'minutes')
            .format('HH:mm'),
          indexVal: moment(item.start_date).day(),
        });
        daysIndex.push(moment(item.start_date).day());
      });
      this.setState({
        initialValues: {
          ...initialValues,
          getDispo: days,
          days: daysIndex,
        },
      });
    }
  }
  async submitGenerateSlotsAuto(initialValues) {
    const { consultation, sessions } = this.props;
    const { patient_record } = consultation;
    const { patient } = patient_record;
    let days = [];
    if (
      parseInt(initialValues.sessions_per_week) > parseInt(initialValues.sessions_count)
    ) {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="info"
            message="Attention ! Vous avez dépassé le nombre total des séances mentionné."
          />
        ),
        { hideProgressBar: true },
      );
    } else {
      initialValues.title = `${patient.last_name} ${patient.first_name}`;
      initialValues.getDispo.map((item) => days.push(item.indexVal));
      const slots = await generateSlotsAuto(initialValues, days);
      if (slots.length > 0) {
        this.props.gotToAgendaPrescription(initialValues, slots);
      }
    }
  }
  toggleDrawer = (anchor, open, id) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      isOpen: false,
      left: open,
      anchor: 'left',
    });
  };

  toggleDrawerD = (anchor, open, id) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      isOpenAddSession: false,
      left: open,
      anchor: 'left',
    });
  };

  openDeleteModal() {
    this.setState({
      setIsOpenCloseSession: !this.state.setIsOpenCloseSession,
    });
  }
  openDeleteModalRDV() {
    this.setState({
      setIsOpenDeleteRDV: !this.state.setIsOpenDeleteRDV,
    });
  }

  handleCheckedPec(type) {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        type,
        getDispo: [],
        sessions_count: null,
        sessions_per_week: null,
        start_date: moment().format('YYYY-MM-DD'),
      },
    });
  }
  getWeekOfDay(start) {
    let i = 1;
    let options = [];
    while (i < 7) {
      if (moment(start).add(i, 'day').format('dddd') === 'dimanche') i++;
      options.push(
        <option value={i}>
          {moment().startOf('weeks').add(i, 'day').format('dddd')}
        </option>,
      );
      i++;
    }

    return options;
  }
  render() {
    const {
      key,
      user,
      loadingGetDispoDoctor,
      getDispoDate,
      office,
      sessions,
      consultationID,
      loadingSession,
      consultation,
      employeeManagement,
      admin,
      allSessionsPatient,
      isLoadingAllSessionsPatient,
      patientStatistical,
    } = this.props;
    let pathologie = '';
    let pathologieColor = '#000000';
    const {
      rdv,
      setIsOpenCloseSession,
      isOpen,
      isOpenAddSession,
      hasError,
      switchListSessions,
      setIsOpenDeleteRDV,
      rdvId,
      initialValues,
    } = this.state;
    let i = 0;
    const countIsDone =
      sessions &&
      sessions.length > 0 &&
      sessions.filter((ele) => ele.status === 'completed');
    const countIsCanceled =
      sessions &&
      sessions.length > 0 &&
      sessions.filter((ele) => ele.status === 'actif' || ele.status === 'not_confirmed');
    let countIsNotCanceled = [];
    countIsNotCanceled =
      allSessionsPatient &&
      allSessionsPatient.length > 0 &&
      allSessionsPatient.filter((ele) => ele.status !== 'canceled');
    let countIsCompleted = [];
    countIsCompleted =
      allSessionsPatient &&
      allSessionsPatient.length > 0 &&
      allSessionsPatient.filter((ele) => ele.status === 'completed');
    let width;
    const same = countIsDone.length / sessions.length;
    if (countIsDone.length === sessions.length) width = '50px';
    else if (same >= 0.2 && same <= 0.4) width = '20px';
    else if (same <= 0.2 && same >= 0.1) width = '10px';
    else if (same >= 0.4 && same <= 0.7) width = '30px';
    else if (same >= 0.7 && same <= 0.9) width = '45px';
    else width = '0';
    const { patient_record } = consultation;
    const { patient } = patient_record;
    return (
      <div
        key={key}
        className="seance_prise_charge_container"
        style={{ paddingTop: '15px' }}
      >
        {sessions && sessions.length > 0 ? (
          <div className="seance_tags">
            <div className="seance_tag_text">
              <span id="seance_tag_title">Calendrier réel</span>
              <span id="seance_tag_desc">La mémoire des séances</span>
            </div>
            <div className="stats_container">
              <div className="progress_bar_container">
                <div className="progress_in_numbers">
                  <span>
                    {countIsDone.length}/{sessions.length}
                  </span>
                </div>
                <div className="actual_progress_bar">
                  <div
                    style={{
                      width: width,
                    }}
                    className="actual_progress"
                  ></div>
                </div>
              </div>
              {isHasPermission(
                user.login_access.permissions,
                'GENERATE_SESSIONS_MEMORY',
              ) ? (
                <Link
                  to={{
                    pathname: '/seance-pdf',
                    state: {
                      sessions: sessions,
                      office: office,
                      //pec: pec,
                      consultation: consultation,
                    },
                  }}
                >
                  <Tooltip title="Mémoire des séances de physiothérapie">
                    <div
                      style={{
                        cursor: 'pointer',
                      }}
                      className="more_icon"
                    >
                      <i className="fas fa-download" aria-hidden="true"></i>
                    </div>
                  </Tooltip>
                </Link>
              ) : (
                ''
              )}
              <Tooltip title="Journal des séances">
                <div
                  onClick={async () => {
                    await this.props.getSessionByConsultation(consultationID);
                    this.props.handelSwitchItem(0);
                    this.setState({
                      isOpen: true,
                    });
                    //this.props.onClickSession();
                  }}
                  style={{
                    /*marginRight:
                    consultation.sessions_count > sessions.length ? '-50px' : '',*/
                    cursor: 'pointer',
                  }}
                  className="more_icon_container"
                >
                  <i
                    style={{
                      padding: '0',
                      border: '',
                      color: '',
                      borderRadius: '',
                      backgroundColor: 'white',
                    }}
                    className="fas fa-eye"
                    aria-hidden="true"
                  ></i>
                </div>
              </Tooltip>
              {consultation.sessions_count === sessions.length ? (
                ''
              ) : (
                <Tooltip title="Calendrier réel">
                  <div
                    onClick={async () => {
                      this.setState({
                        isOpenAddSession: true,
                      });
                      //this.props.onClickSession();
                    }}
                    style={{ cursor: 'pointer' }}
                    className="more_icon_container"
                  >
                    <i
                      style={{
                        padding: '0',
                        border: '',
                        color: '',
                        borderRadius: '',
                        backgroundColor: 'white',
                      }}
                      className="fas fa-plus"
                      aria-hidden="true"
                    ></i>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        ) : (
          <div className="seance_tags">
            <div className="seance_tag_text">
              <span id="seance_tag_title">Calendrier réel</span>
              <span id="seance_tag_desc">La mémoire des séances</span>
            </div>
            <div className="stats_container">
              <Tooltip title="Ajouter des séances">
                <div
                  onClick={async () => {
                    this.setState({
                      isOpenAddSession: true,
                    });
                    //this.props.onClickSession();
                  }}
                  style={{ cursor: 'pointer' }}
                  className="more_icon_container"
                >
                  <i
                    style={{
                      padding: '0',
                      border: '',
                      color: '',
                      borderRadius: '',
                      backgroundColor: 'white',
                    }}
                    className="fas fa-plus"
                    aria-hidden="true"
                  ></i>
                </div>
              </Tooltip>
            </div>
          </div>
        )}
        <SwipeableDrawer
          style={{ width: '50%' }}
          anchor="right"
          open={isOpen}
          onClose={this.toggleDrawer('right', false)}
          onOpen={this.toggleDrawer('right', true)}
        >
          <div className="right_side_bar">
            <div className="right_side_bar_header">
              {rdv ? (
                <div onClick={() => this.setState({ rdv: null })}>
                  <img
                    src={require('../../../assets/img/back_btn.svg')}
                    alt="Retour"
                    style={{ border: 'unset' }}
                  />
                </div>
              ) : (
                <span id="add_app_sidebar_title">Calendrier réel</span>
              )}
              <FormControlLabel
                control={
                  <Switch
                    onChange={async (e) => {
                      this.setState({
                        switchListSessions: e.target.checked,
                      });
                      if (e.target.checked) {
                        await this.props.getPatientSessions(patient.id);
                        await this.props.getPatientStatistical(patient.id);
                      }
                    }}
                    defaultChecked={false}
                  />
                }
                label="Journal des séances"
              />

              <svg
                onClick={() => {
                  this.setState({
                    isOpen: false,
                    switchListSessions: false,
                    rdv: null,
                  });
                }}
                style={{ cursor: 'pointer' }}
                id="close-right-sidebar"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="9.5"
                  fill="white"
                  stroke="#ECEEF5"
                />
                <path
                  d="M25.2383 15.8164L21.0547 20L25.2383 24.1836L24.1836 25.2383L20 21.0547L15.8164 25.2383L14.7617 24.1836L18.9453 20L14.7617 15.8164L15.8164 14.7617L20 18.9453L24.1836 14.7617L25.2383 15.8164Z"
                  fill="#8083A3"
                />
              </svg>
            </div>
            {rdv ? (
              <>
                <div style={{ display: 'block' }} className="seances_panel_container">
                  <div
                    className="modal_body_recap_details"
                    style={{
                      width: rdv.payment ? '50%' : '80%',
                      height: '340px',
                      float: 'left',
                      paddingRight: '5px',
                    }}
                  >
                    <div className="modal_body_recap_details_item">
                      <div className="modal_body_recap_details_item_left">
                        <span>Collaborateur</span>
                      </div>
                      <div className="modal_body_recap_details_item_right">
                        <div className="conto">
                          <span style={{ alignSelf: 'center' }}>
                            {rdv.practitioner
                              ? `${rdv.practitioner.last_name} ${rdv.practitioner.first_name}`
                              : ''}
                          </span>
                          <button
                            style={{ cursor: 'auto' }}
                            className={
                              rdv.status === 'actif'
                                ? 'status_rdv btn_status actif_status'
                                : rdv.status === 'not_confirmed'
                                ? 'status_rdv btn_status actif_not_confirmed'
                                : rdv.status === 'completed'
                                ? 'status_rdv btn_status span_completed'
                                : 'status_rdv btn_status actif_canceled'
                            }
                            //  className="status_rdv span_completed"
                          >
                            {rdv.status === 'actif'
                              ? 'Planifié'
                              : rdv.status === 'canceled'
                              ? 'Annulé'
                              : rdv.status === 'not_confirmed'
                              ? 'Non confirmé'
                              : 'Terminé'}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="modal_body_recap_details_item">
                      <div className="modal_body_recap_details_item_left">
                        <span>Pathologie </span>
                      </div>
                      <div className="modal_body_recap_details_item_right">
                        <div className="conto">
                          <span>{rdv.motif.label}</span>
                        </div>
                      </div>
                    </div>
                    <div className="modal_body_recap_details_item">
                      <div className="modal_body_recap_details_item_left">
                        <span>Date</span>
                      </div>
                      <div className="modal_body_recap_details_item_right">
                        <span>{moment(rdv.date_from).format('DD/MM/YYYY')}</span>
                      </div>
                    </div>

                    <div className="modal_body_recap_details_item">
                      <div className="modal_body_recap_details_item_left">
                        <span>Heure</span>
                      </div>
                      <div className="modal_body_recap_details_item_right">
                        <span>{moment(rdv.date_from).format('HH:mm')}</span>
                      </div>
                    </div>
                    <div className="modal_body_recap_details_item">
                      <div className="modal_body_recap_details_item_left">
                        <span>Note</span>
                      </div>
                      <div
                        className="modal_body_recap_details_item_right"
                        style={{ height: 'inherit' }}
                      >
                        <textarea
                          disabled={true}
                          style={{ width: '100%' }}
                          value={rdv.note}
                          name="note"
                          rows={4}
                          className="form-control"
                        >
                          {rdv.note}
                        </textarea>
                      </div>
                    </div>
                  </div>
                  {rdv.payment ? (
                    <div
                      className="modal_body_recap_details"
                      style={{
                        width: '50%',
                        float: 'right',
                        borderLeft: '1px solid gray',
                      }}
                    >
                      <div className="modal_body_recap_details_item">
                        <div className="modal_body_recap_details_item_left">
                          <span>Total réalisé </span>
                        </div>
                        <div className="modal_body_recap_details_item_right">
                          <div className="conto">
                            <input
                              style={{
                                width: '79%',
                              }}
                              disabled={true}
                              defaultValue={rdv.payment.total_achieved || 0}
                              name="num_cheque"
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                      <hr id="top_section_seperator" />
                      <div className="modal_body_recap_details_item">
                        <div className="modal_body_recap_details_item_left">
                          <span>Déjà payé</span>
                        </div>
                        <div className="modal_body_recap_details_item_right">
                          <input
                            style={{
                              width: '79%',
                            }}
                            disabled={true}
                            defaultValue={rdv.payment.already_paid || 0}
                            name="num_cheque"
                            className="form-control"
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="modal_body_recap_details_item">
                        <div className="modal_body_recap_details_item_left">
                          <span>Reste à payer</span>
                        </div>
                        <div className="modal_body_recap_details_item_right">
                          <input
                            style={{
                              width: '79%',
                            }}
                            disabled={true}
                            defaultValue={rdv.payment.left_to_pay || 0}
                            name="num_cheque"
                            className="form-control"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="modal_body_recap_details_item">
                        <div className="modal_body_recap_details_item_left">
                          <span>Montant cagnotte</span>
                        </div>
                        <div className="modal_body_recap_details_item_right">
                          <input
                            style={{
                              width: '79%',
                            }}
                            disabled={true}
                            defaultValue={rdv.payment.money_pot_amount || 0}
                            name="num_cheque"
                            className="form-control"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="modal_body_recap_details_item">
                        <div className="modal_body_recap_details_item_left">
                          <span>Tarif Séance</span>
                        </div>
                        <div className="modal_body_recap_details_item_right">
                          <span>{rdv.payment.session_price}</span>
                        </div>
                      </div>
                      <div className="modal_body_recap_details_item">
                        <div className="modal_body_recap_details_item_left">
                          <span>Mode paiement</span>
                        </div>
                        <div className="modal_body_recap_details_item_right">
                          <span>{rdv.payment.payment_method}</span>
                        </div>
                      </div>
                      {rdv.payment.payment_method === 'Chèque' ? (
                        <>
                          <div className="modal_body_recap_details_item">
                            <div className="modal_body_recap_details_item_left">
                              <span>Numéro du chèque</span>
                            </div>
                            <div className="modal_body_recap_details_item_right">
                              <span>{rdv.payment.num_cheque}</span>
                            </div>
                          </div>
                          <div className="modal_body_recap_details_item">
                            <div className="modal_body_recap_details_item_left">
                              <span>Date d'échéance</span>
                            </div>
                            <div className="modal_body_recap_details_item_right">
                              <span>
                                {moment(rdv.payment.date_cheque).format('DD/MM/YYYY')}
                              </span>
                            </div>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                      {rdv.payment.payment_method === 'Virement' ? (
                        <div className="modal_body_recap_details_item">
                          <div className="modal_body_recap_details_item_left">
                            <span>Ordre de virement</span>
                          </div>
                          <div className="modal_body_recap_details_item_right">
                            <span>{rdv.payment.orde_virement}</span>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="modal_body_recap_details_item">
                        <div className="modal_body_recap_details_item_left">
                          <span>Payé en séance</span>
                        </div>
                        <div className="modal_body_recap_details_item_right">
                          <span>{rdv.payment.settled_in_session}</span>
                        </div>
                      </div>
                      <div className="modal_body_recap_details_item">
                        <div className="modal_body_recap_details_item_left">
                          <span>Date de réglement</span>
                        </div>
                        <div className="modal_body_recap_details_item_right">
                          <span>{moment(rdv.payment.date).format('DD/MM/YYYY')}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="footer_buttons">
                  {rdv.status === 'not_confirmed' &&
                  isHasPermission(user.login_access.permissions, 'UPDATE_APPOINTMENT') ? (
                    <button
                      onClick={async () => {
                        await this.props.submitRDVForm(
                          {
                            status: 'actif',
                            id: rdv.id,
                          },
                          rdv.consultation_id,
                        );
                        this.setState({
                          isOpen: false,
                          rdv: null,
                        });
                      }}
                      style={{ width: '34%', marginRight: '1px' }}
                      className="btn_valid"
                    >
                      Confirmer le rendez-vous
                    </button>
                  ) : (
                    ''
                  )}
                  {rdv.status === 'canceled' || rdv.status === 'completed' ? (
                    ''
                  ) : isHasPermission(
                      user.login_access.permissions,
                      'CANCEL_APPOINTMENT',
                    ) ? (
                    <button
                      onClick={async () => {
                        await this.props.submitRDVForm(
                          {
                            status: 'canceled',
                            id: rdv.id,
                          },
                          rdv.consultation_id,
                        );
                        this.setState({
                          isOpen: false,
                          rdv: null,
                        });
                      }}
                      id="cancel"
                      style={{ width: '34%', marginRight: '1px' }}
                      className="footer_btn"
                    >
                      Annuler le rendez-vous
                    </button>
                  ) : (
                    ''
                  )}{' '}
                </div>
              </>
            ) : switchListSessions ? (
              <div className="right_side_bar_body">
                <div className="selected_facture_details" style={{ width: 'inherit' }}>
                  <div
                    style={{
                      width: '100%',
                    }}
                    className="selected_facture_info_container"
                  >
                    <div
                      style={{ width: 'inherit' }}
                      className="selected_facture_patient_info"
                    >
                      <span
                        style={{
                          width: 'auto',
                          textAlign: 'left',
                        }}
                        id="selected_facture_patient_name"
                      >
                        {`${patient.last_name} ${patient.first_name}`}{' '}
                        {patient_record.pr_number
                          ? `(FN° ${patient_record.pr_number})`
                          : ''}{' '}
                      </span>
                      {patient.phone &&
                      patient.address_patient &&
                      patient.address_patient.city ? (
                        <span
                          id="selected_facture_patient_city"
                          style={{ textAlign: 'left' }}
                        >
                          <strong>Ville :</strong> {patient.address_patient.city} -{' '}
                          <strong>Tél :</strong> {patient.phone}
                        </span>
                      ) : patient.address_patient && patient.address_patient.city ? (
                        <span
                          id="selected_facture_patient_city"
                          style={{ textAlign: 'left' }}
                        >
                          <strong>Ville :</strong> {patient.address_patient.city}
                        </span>
                      ) : (
                        <span
                          id="selected_facture_patient_city"
                          style={{ textAlign: 'left' }}
                        >
                          <strong>Tél :</strong> {patient.phone}
                        </span>
                      )}
                    </div>
                    <div
                      style={{ width: '160px' }}
                      className="selected_facture_patient_info"
                    >
                      <span
                        id="selected_facture_patient_city"
                        style={{ textAlign: 'left' }}
                      >
                        <strong>Régime :</strong>{' '}
                        {patient.regime === 1
                          ? 'CNAM'
                          : patient.regime === 2
                          ? 'ACT'
                          : 'Autre'}
                      </span>
                      <span
                        id="selected_facture_patient_name"
                        style={{ textAlign: 'left' }}
                      >
                        <strong>Nbr S :</strong> {allSessionsPatient.length}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="selected_facture_details" style={{ width: 'inherit' }}>
                  <div
                    style={{
                      width: '100%',
                    }}
                    className="selected_facture_info_container"
                  >
                    <div
                      style={{ width: '25%' }}
                      className="selected_facture_patient_info"
                    >
                      <span
                        id="selected_facture_patient_name"
                        style={{ textAlign: 'left' }}
                      >
                        <strong>Réalisé / Total séances</strong>
                      </span>
                      <span
                        id="selected_facture_patient_city"
                        style={{ textAlign: 'left' }}
                      >
                        {countIsCompleted.length} / {countIsNotCanceled.length}
                      </span>
                    </div>
                    <div
                      style={{ width: '25%' }}
                      className="selected_facture_patient_info"
                    >
                      <span
                        id="selected_facture_patient_name"
                        style={{ textAlign: 'left' }}
                      >
                        <strong>Montant séances réalisées</strong>
                      </span>
                      <span
                        id="selected_facture_patient_city"
                        style={{ textAlign: 'left' }}
                      >
                        {patientStatistical && patientStatistical.session_price
                          ? patientStatistical.session_price
                          : 0}
                      </span>
                    </div>
                    <div
                      style={{ width: '25%' }}
                      className="selected_facture_patient_info"
                    >
                      <span
                        id="selected_facture_patient_name"
                        style={{ textAlign: 'left' }}
                      >
                        <strong>Total Payé</strong>
                      </span>
                      <span
                        id="selected_facture_patient_city"
                        style={{ textAlign: 'left' }}
                      >
                        {patientStatistical && patientStatistical.sumCreditPayed}
                      </span>
                    </div>
                    <div
                      style={{ width: '25%' }}
                      className="selected_facture_patient_info"
                    >
                      <span
                        id="selected_facture_patient_name"
                        style={{ textAlign: 'left' }}
                      >
                        <strong>Reste</strong>
                      </span>
                      <span
                        id="selected_facture_patient_city"
                        style={{ textAlign: 'left' }}
                      >
                        {patientStatistical && patientStatistical.sumCreditInPayed}
                      </span>
                    </div>
                  </div>
                </div>
                {isLoadingAllSessionsPatient ? (
                  <div className="seances_panel_container">
                    <CircularProgress disableShrink />
                  </div>
                ) : (
                  <div
                    style={{ padding: '0px', gap: '6px' }}
                    className="seances_panel_container"
                  >
                    {allSessionsPatient &&
                      allSessionsPatient.length > 0 &&
                      allSessionsPatient.map((item) => {
                        if (item.status !== 'not_confirmed') {
                          pathologie =
                            item.motif && item.motif.label === 'Autre'
                              ? item.diagnostic
                              : item.motif.label;
                          pathologieColor = item.motif && item.motif.color;
                          i++;
                          return (
                            <div
                              /*onClick={() =>
                                    this.setState({
                                      rdv: item,
                                    })
                                  }*/
                              key={item.id}
                              className="seances_panel_item"
                              style={{ height: '55px' }}
                            >
                              <div
                                style={{ width: '0%' }}
                                className="selected_facture_patient_info"
                              >
                                <button
                                  style={{ cursor: 'auto' }}
                                  className={
                                    item.status === 'actif'
                                      ? 'status_rdv btn_status actif_status-'
                                      : item.status === 'not_confirmed'
                                      ? 'status_rdv btn_status actif_not_confirmed'
                                      : item.status === 'completed'
                                      ? 'status_rdv btn_status actif_completed'
                                      : 'status_rdv btn_status actif_canceled'
                                  }
                                >
                                  <span>{i}</span>
                                </button>
                              </div>
                              <div
                                style={{ width: '20%' }}
                                className="selected_facture_patient_info"
                              >
                                <span id="selected_facture_patient_name">
                                  {moment(item.date_from).format('dddd')}{' '}
                                  {moment(item.date_from).format('DD/MM/YYYY')}
                                </span>
                                <span id="selected_facture_patient_city">
                                  {moment(item.date_from).format('HH:mm')} -{' '}
                                  {moment(item.date_to).format('HH:mm')}
                                </span>
                              </div>
                              <div
                                style={{ width: '27%' }}
                                className="selected_facture_patient_info"
                              >
                                <span id="selected_facture_patient_name">
                                  {`${item.practitioner.last_name} ${item.practitioner.first_name}`}
                                </span>
                                <span
                                  style={{ color: pathologieColor }}
                                  id="selected_facture_patient_city"
                                >
                                  {pathologie}
                                </span>
                              </div>

                              <div
                                style={{ width: '10%' }}
                                className="selected_facture_patient_info"
                              >
                                <span id="selected_facture_patient_name">
                                  Payé:{' '}
                                  {item.payment ? item.payment.settled_in_session : 0} /{' '}
                                  {item.payment ? item.payment.session_price : 0}
                                </span>
                                <span id="selected_facture_patient_city">
                                  {item.payment ? item.payment.payment_method : ''}
                                </span>
                              </div>
                              <div
                                style={{ width: '10%' }}
                                className="selected_facture_patient_info"
                              >
                                <span id="selected_facture_patient_name">
                                  Reste: {item.payment ? item.payment.left_to_pay : 0}
                                </span>
                              </div>
                            </div>
                          );
                        }
                      })}
                    {!switchListSessions &&
                    countIsCanceled &&
                    countIsCanceled.length > 0 ? (
                      <div className="footer_buttons">
                        <button
                          onClick={async () => {
                            this.openDeleteModal();
                          }}
                          style={{ width: '34%', marginRight: '1px' }}
                          className="btn_valid"
                        >
                          Clôturer les séances
                        </button>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="right_side_bar_body">
                {loadingSession ? (
                  <div className="seances_panel_container">
                    <CircularProgress disableShrink />
                  </div>
                ) : (
                  <div
                    style={{ padding: '0px', gap: '6px' }}
                    className="seances_panel_container"
                  >
                    {sessions &&
                      sessions.length > 0 &&
                      sessions.map((item) => {
                        i++;
                        return (
                          <div
                            style={{ height: '55px' }}
                            key={item.id}
                            className="seances_panel_item"
                          >
                            <div className="seance_number">
                              <span>Séance N°{i}</span>
                            </div>
                            <div className="seance_date">
                              <span>
                                {moment(item.date_from).format('dddd')}{' '}
                                {moment(item.date_from).format('DD/MM/YYYY HH:mm')}
                              </span>
                            </div>
                            <div style={{ width: '25%' }} className="conto">
                              <button
                                style={{ cursor: 'auto' }}
                                className={
                                  item.status === 'actif'
                                    ? 'status_rdv btn_status actif_status-'
                                    : item.status === 'not_confirmed'
                                    ? 'status_rdv btn_status actif_not_confirmed'
                                    : item.status === 'completed'
                                    ? 'status_rdv btn_status actif_completed'
                                    : 'status_rdv btn_status actif_canceled'
                                }
                              >
                                <span>
                                  {item.status === 'actif'
                                    ? 'Planifié'
                                    : item.status === 'canceled'
                                    ? 'Annulé'
                                    : item.status === 'not_confirmed'
                                    ? 'Non confirmé'
                                    : 'Terminé'}
                                </span>
                              </button>
                            </div>
                            <div style={{ width: '7%' }} className="conto">
                              <Tooltip title="Modifier rdv">
                                <div
                                  className="more_icon_container"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    this.setState({
                                      rdv: item,
                                    })
                                  }
                                >
                                  <i
                                    className="fas fa-eye"
                                    aria-hidden="true"
                                    style={{ padding: '0px', backgroundColor: 'white' }}
                                  ></i>
                                </div>
                              </Tooltip>
                              <Tooltip title="Supprimer rdv">
                                <div
                                  className="more_icon_container"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    this.setState({
                                      rdvId: item.id,
                                    });
                                    this.openDeleteModalRDV();
                                  }}
                                >
                                  <i
                                    className="fas fa-trash"
                                    aria-hidden="true"
                                    style={{ padding: '0px', backgroundColor: 'white' }}
                                  ></i>
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                        );
                      })}
                    {countIsCanceled && countIsCanceled.length > 0 ? (
                      <div className="footer_buttons">
                        <button
                          onClick={async () => {
                            this.openDeleteModal();
                          }}
                          style={{ width: '34%', marginRight: '1px' }}
                          className="btn_valid"
                        >
                          Clôturer les séances
                        </button>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </SwipeableDrawer>
        <Modals
          setIsOpen={setIsOpenCloseSession}
          openModal={this.openDeleteModal}
          data={
            <>
              <div className="modal-header" style={{ marginTop: '-30px' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openDeleteModal()}
                  style={{ marginTop: '1%' }}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '5px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Clôture des séances !</h2>
                <span>Êtes-vous sûr de vouloir clôturer les séances ? </span>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => this.openDeleteModal()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                  style={{ backgroundColor: '#f6f6f6' }}
                >
                  Non
                </button>
                <button
                  onClick={async () => {
                    await this.props.closeTheSessions(consultationID);
                    this.setState({
                      isOpen: false,
                      rdv: null,
                    });
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
        <SwipeableDrawer
          style={{ width: '50%' }}
          anchor="right"
          open={isOpenAddSession}
          onClose={this.toggleDrawerD('right', false)}
          onOpen={this.toggleDrawerD('right', true)}
        >
          <div className="right_side_bar">
            <div className="right_side_bar_header">
              {rdv ? (
                <div onClick={() => this.setState({ rdv: null })}>
                  <img
                    src={require('../../../assets/img/back_btn.svg')}
                    alt="Retour"
                    style={{ border: 'unset' }}
                  />
                </div>
              ) : (
                <span id="add_app_sidebar_title">Calendrier réel</span>
              )}
              <svg
                onClick={() => {
                  this.setState({
                    isOpenAddSession: false,
                    rdv: null,
                    initialValues: {
                      ...initialValues,
                      getDispo: [],
                      days: [],
                      sessions_per_week: null,
                      sessions_count: null,
                      duration: null,
                      type: 0,
                      start_date: moment().format('YYYY-MM-DD'),
                      end_date: '',
                      hourStart: '08:00',
                      practitioner_id: null,
                    },
                  });
                }}
                style={{ cursor: 'pointer' }}
                id="close-right-sidebar"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="9.5"
                  fill="white"
                  stroke="#ECEEF5"
                />
                <path
                  d="M25.2383 15.8164L21.0547 20L25.2383 24.1836L24.1836 25.2383L20 21.0547L15.8164 25.2383L14.7617 24.1836L18.9453 20L14.7617 15.8164L15.8164 14.7617L20 18.9453L24.1836 14.7617L25.2383 15.8164Z"
                  fill="#8083A3"
                />
              </svg>
            </div>
            <div className="right_side_bar_body">
              <div className="modal-body">
                <form style={{ padding: '2px 0px' }}>
                  <div className="form-row">
                    <div style={{ display: 'flex' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() =>
                              this.handleCheckedPec(initialValues.type === 1 ? 0 : 1)
                            }
                            checked={initialValues.type}
                          />
                        }
                        label={
                          <div>
                            <span
                              style={{
                                color: initialValues.type === 0 ? 'black' : '#57575c',
                                fontWeight: initialValues.type === 0 ? '700' : '500',
                              }}
                            >
                              Manuel
                            </span>
                            /
                            <span
                              style={{
                                color: initialValues.type === 1 ? 'black' : '#57575c',
                                fontWeight: initialValues.type === 1 ? '700' : '500',
                              }}
                            >
                              Automatique
                            </span>
                          </div>
                        }
                      />
                    </div>
                    <div style={{ width: '100%', display: 'flex' }}>
                      {initialValues.type === 1 &&
                      (user.login_access.account_type === 'assistant' ||
                        user.login_access.account_type === 'secretary' ||
                        admin) ? (
                        <>
                          <div className="form-row">
                            <div
                              style={{ width: '100%' }}
                              className={
                                hasError && !initialValues.practitioner_id
                                  ? 'right_sidebar_body_item type3'
                                  : 'right_sidebar_body_item'
                              }
                            >
                              <label>Collaborateurs</label>
                              <div className="input_and_icon">
                                <select
                                  style={{ width: '100%' }}
                                  value={initialValues.practitioner_id}
                                  onChange={(e) => {
                                    this.handleChangeInput(e);
                                    if (
                                      initialValues.type === 1 &&
                                      initialValues.sessions_count &&
                                      initialValues.start_date &&
                                      initialValues.sessions_per_week &&
                                      initialValues.sessions_count >=
                                        initialValues.sessions_per_week
                                    ) {
                                      let practitioner_id = e.target.value;
                                      setTimeout(() => {
                                        this.props.getDispoDoctor(
                                          {
                                            ...initialValues,
                                            practitioner_id,
                                          },
                                          this.props.consultation.id,
                                        );
                                      }, 1000);
                                    }
                                  }}
                                  name="practitioner_id"
                                >
                                  <option value="">Collaborateurs</option>
                                  {employeeManagement.map((item) => {
                                    return (
                                      <option
                                        value={item.id}
                                      >{`${item.last_name} ${item.first_name}`}</option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                          <br />
                        </>
                      ) : (
                        ''
                      )}
                      <InputField
                        width="30%"
                        className="add_patient_form_item"
                        type="text"
                        placeholder="10"
                        label="Nbr de séances"
                        hasError={
                          hasError ||
                          sessions.length + initialValues.sessions_count >
                            consultation.sessions_count
                        }
                        value={initialValues.sessions_count || ''}
                        name="sessions_count"
                        icon={<i className="fas fa-pen"></i>}
                        validInput="integerSessionC"
                        onChange={(e) => {
                          this.handleChangeInput(e);
                          if (
                            initialValues.type === 1 &&
                            initialValues.practitioner_id &&
                            initialValues.start_date &&
                            initialValues.sessions_per_week &&
                            parseInt(e.target.value) >=
                              parseInt(initialValues.sessions_per_week)
                          ) {
                            let sessions_count = e.target.value;
                            setTimeout(() => {
                              this.props.getDispoDoctor(
                                {
                                  ...initialValues,
                                  sessions_count,
                                },
                                this.props.consultation.id,
                              );
                            }, 1000);
                          }
                        }}
                      />
                      <InputField
                        maxLength={1}
                        width="30%"
                        className="add_patient_form_item"
                        type="text"
                        label="S/semaine"
                        placeholder="S/semaine"
                        hasError={
                          hasError ||
                          parseInt(initialValues.sessions_count) <
                            parseInt(initialValues.sessions_per_week)
                        }
                        value={initialValues.sessions_per_week || ''}
                        name="sessions_per_week"
                        icon={<i className="fas fa-pen"></i>}
                        validInput="integerNbrS"
                        onChange={(e) => {
                          this.handleChangeInput(e);
                          let sessions_per_week = e.target.value;

                          if (
                            initialValues.type === 1 &&
                            initialValues.sessions_count &&
                            initialValues.start_date &&
                            initialValues.practitioner_id &&
                            parseInt(e.target.value) <=
                              parseInt(initialValues.sessions_count)
                          ) {
                            setTimeout(() => {
                              this.props.getDispoDoctor(
                                {
                                  ...initialValues,
                                  sessions_per_week,
                                },
                                this.props.consultation.id,
                              );
                            }, 1000);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div style={{ width: '100%', display: 'flex' }}>
                      {initialValues.type === 1 ? (
                        <InputField
                          width="100%"
                          className="add_patient_form_item"
                          type="date"
                          placeholder="Date de début"
                          label="Date de début"
                          hasError={hasError}
                          value={initialValues.start_date}
                          name="start_date"
                          icon=""
                          onChange={(e) => {
                            this.handleChangeInput(e);
                            if (
                              initialValues.type === 1 &&
                              initialValues.sessions_count &&
                              initialValues.sessions_per_week &&
                              initialValues.practitioner_id &&
                              parseInt(initialValues.sessions_count) >=
                                parseInt(initialValues.sessions_per_week)
                            ) {
                              let start_date = e.target.value;
                              setTimeout(() => {
                                this.props.getDispoDoctor(
                                  {
                                    ...initialValues,
                                    start_date,
                                  },
                                  this.props.consultation.id,
                                );
                              }, 1000);
                            }
                          }}
                        />
                      ) : (
                        ''
                      )}

                      <InputField
                        width="30%"
                        className="add_patient_form_item"
                        type="time"
                        placeholder="10"
                        label="Heur de début"
                        //hasError={hasError}
                        value={initialValues.hourStart || ''}
                        name="hourStart"
                        //icon={<i className="fas fa-pen"></i>}
                        //validInput="integerSessionC"
                        onChange={(e) => {
                          this.handleChangeInput(e);
                          if (
                            initialValues.type === 1 &&
                            initialValues.sessions_count &&
                            initialValues.sessions_per_week &&
                            initialValues.practitioner_id &&
                            parseInt(initialValues.sessions_count) >=
                              parseInt(initialValues.sessions_per_week)
                          ) {
                            let hourStart = e.target.value;
                            setTimeout(() => {
                              this.props.getDispoDoctor(
                                {
                                  ...initialValues,
                                  hourStart,
                                },
                                this.props.consultation.id,
                              );
                            }, 1000);
                          }
                        }}
                      />
                      <InputField
                        maxLength={3}
                        width="30%"
                        className="add_patient_form_item"
                        type="text"
                        label="durée (en minutes)"
                        placeholder="durée (en minutes)"
                        //hasError={hasError}
                        value={initialValues.duration || ''}
                        name="duration"
                        icon={<i className="fas fa-pen"></i>}
                        validInput="integerDuration"
                        onChange={(e) => {
                          this.handleChangeInput(e);
                          if (
                            initialValues.type === 1 &&
                            initialValues.sessions_count &&
                            initialValues.sessions_per_week &&
                            initialValues.practitioner_id &&
                            parseInt(initialValues.sessions_count) >=
                              parseInt(initialValues.sessions_per_week)
                          ) {
                            let duration = e.target.value;
                            setTimeout(() => {
                              this.props.getDispoDoctor(
                                {
                                  ...initialValues,
                                  duration,
                                },
                                this.props.consultation.id,
                              );
                            }, 1000);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <br />
                  {initialValues.type === 0 &&
                  (initialValues.getDispo.length === 0 ||
                    initialValues.getDispo === false) ? (
                    <div className="footer_buttons">
                      <button
                        style={{ marginRight: '3%', width: '285px' }}
                        onClick={() => {
                          this.submitFormConsultation(initialValues);
                        }}
                        type="button"
                        className="footer_btn"
                        id="update"
                      >
                        Passer à l'agenda
                      </button>
                    </div>
                  ) : (
                    ''
                  )}
                  {initialValues.type === 1 && initialValues.getDispo.length > 0 ? (
                    <>
                      <div className="form-row">
                        <div style={{ display: 'contents' }}>
                          {initialValues.getDispo &&
                            initialValues.getDispo.length > 0 &&
                            initialValues.getDispo.map((item) => {
                              if (item)
                                return (
                                  <div style={{ display: 'flex' }}>
                                    <div
                                      style={{ padding: '15px', width: '30%' }}
                                      className={
                                        hasError && !initialValues.practitioner_id
                                          ? 'right_sidebar_body_item type3'
                                          : 'right_sidebar_body_item'
                                      }
                                    >
                                      <div className="input_and_icon">
                                        <select
                                          style={{ width: '100%' }}
                                          value={parseInt(item.indexVal)}
                                          onChange={(e) =>
                                            this.onChangeHours(
                                              item.id,
                                              e.target.value,
                                              'day',
                                            )
                                          }
                                          name="day"
                                        >
                                          {/*{this.getWeekOfDay(item.start_date)}*/}
                                          <option value={1}>Lundi </option>
                                          <option value={2}>Mardi </option>
                                          <option value={3}>Mercredi </option>
                                          <option value={4}>Jeudi </option>
                                          <option value={5}>Vendredi </option>
                                          <option value={6}>Samedi </option>
                                        </select>
                                      </div>
                                    </div>
                                    <InputField
                                      width="10%"
                                      className="add_patient_form_item"
                                      type="time"
                                      placeholder="10"
                                      label="du"
                                      //hasError={hasError}
                                      value={item.hour_start}
                                      name="hour_start"
                                      //icon={<i className="fas fa-pen"></i>}
                                      //validInput="integerSessionC"
                                      onChange={(e) =>
                                        this.onChangeHours(
                                          item.id,
                                          e.target.value,
                                          'hour_start',
                                        )
                                      }
                                    />
                                    <InputField
                                      width="10%"
                                      className="add_patient_form_item"
                                      type="time"
                                      placeholder="10"
                                      label="à"
                                      //hasError={hasError}
                                      value={item.hour_end}
                                      name="hour_end"
                                      //icon={<i className="fas fa-pen"></i>}
                                      //validInput="integerSessionC"

                                      onChange={(e) =>
                                        this.onChangeHours(
                                          item.id,
                                          e.target.value,
                                          'hour_end',
                                        )
                                      }
                                    />
                                  </div>
                                );
                            })}
                        </div>
                      </div>
                      <div className="footer_buttons">
                        <button
                          style={{ marginRight: '3%', width: '285px' }}
                          onClick={() => {
                            this.submitGenerateSlotsAuto(initialValues);
                          }}
                          type="button"
                          className="footer_btn"
                          id="update"
                        >
                          {loadingGetDispoDoctor ? (
                            <CircularProgress
                              style={{
                                marginLeft: '4%',
                                width: '25px',
                                height: '25px',
                                color: 'white',
                                marginTop: '5%',
                              }}
                            />
                          ) : (
                            "Passer à l'agenda"
                          )}
                        </button>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </form>
              </div>
            </div>
            <br />
          </div>
        </SwipeableDrawer>
        <Modals
          setIsOpen={setIsOpenDeleteRDV}
          openModal={this.openDeleteModalRDV}
          data={
            <>
              <div className="modal-header" style={{ marginTop: '-30px' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openDeleteModalRDV()}
                  style={{ marginTop: '1%' }}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '5px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Suppression rendez-vous!</h2>
                <span>Voulez-vous vraiment supprimer ce rendez-vous ?</span>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => this.openDeleteModalRDV()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                  style={{ backgroundColor: '#f6f6f6' }}
                >
                  Non
                </button>
                <button
                  onClick={async () => {
                    await this.props.deleteRdv(consultationID, rdvId);
                    this.openDeleteModalRDV();
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
      </div>
    );
  }
}
export default Session;
