import React, { Component } from 'react';
import './index.css';
import { SelectorMessengerItem } from '../../utils';
import actions from './store/actions';
import actionsJs from '../../js/actions';
import { connect } from 'react-redux';
import Pusher from 'pusher-js';
import moment from 'moment';
import Messages from './containers/messages';
import Item_conversation from './composents/item_conversation';
import _ from 'lodash';
import Alert from '@mui/material/Alert';

const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  allUsers: state.MessengerReducer.allUsers,
  isLoadingAllUsers: state.MessengerReducer.isLoadingAllUsers,
  params: state.MessengerReducer.params,
  error: state.MessengerReducer.error,
  messages: state.MessengerReducer.messages,
  isLoadingMessages: state.MessengerReducer.isLoadingMessages,
  conversationAccount: state.MessengerReducer.conversationAccount,
  isLoadingConversationAccount: state.MessengerReducer.isLoadingconversationAccount,
  paramsMessages: state.MessengerReducer.paramsMessages,
  createdMessage: state.MessengerReducer.createdMessage,
  newConversation: state.MessengerReducer.newConversation,
  destroyConversation: state.MessengerReducer.deleteConversation,
  conversationTo: state.MessengerReducer.conversationTo,
});
//DeleteConversation
const mapDispatchToProps = (dispatch) => ({
  getAllUSERS: async (params) => await dispatch(actions.getAllUSERS(params)),
  initialState: async () => await dispatch(actions.initialState()),
  getConversationAccount: async (paramsMessages) =>
    await dispatch(actions.getConversationAccount(paramsMessages)),
  countMessagesIsSeen: () => dispatch(actions.countMessagesIsSeen()),
  countMessagesIsSeenByConversation: async (conversationId) => {
    await dispatch(actions.countMessagesIsSeenByConversation(conversationId));
    dispatch(actions.countMessagesIsSeen());
  },
  submitMessageForm: async (values, paramsMessages) => {
    await dispatch(actions.submitMessageForm(values));
    dispatch(actions.getMessages(values.conversation_id, paramsMessages));
    dispatch(actions.getConversationAccount(paramsMessages));

    //dispatch(actions.countMessagesIsSeen());
    //dispatch(actions.countMessagesIsSeenTo(id));
    // dispatch(actions.getMessages(values.conversation_id));
  },
  deleteConversation: async (id, paramsMessages) => {
    await dispatch(actions.deleteConversation(id));
    dispatch(actions.getConversationAccount(paramsMessages));
  },
  submitConversationForm: async (values) => {
    await dispatch(actions.submitConversationForm(values));
    // dispatch(actions.getConversationAccount(paramsMessages));
  },

  countMessagesIsSeenTo: (id) => dispatch(actions.countMessagesIsSeenTo(id)),
  changeParamsMessage: async (id, params) => {
    await dispatch(actions.changeParamsMessage(params));
    dispatch(actions.getMessages(id, params));
  },
  changeCountMessage: (countMessage) =>
    dispatch(actionsJs.changeCountMessage(countMessage)),
  changeActiveItemSideBarCompta: (data) =>
    dispatch(actionsJs.changeActiveItemSideBarCompta(data)),
  getMessages: (id, accountType) => dispatch(actions.getMessages(id, accountType)),
});

const pusher = new Pusher('9c5bef3c6c40e92e26cb', {
  cluster: 'eu',
  encrypted: true,
});

const channel = pusher.subscribe('conversations');

class Messenger extends Component {
  constructor(props) {
    super(props);
    props.countMessagesIsSeen();
    props.changeActiveItemSideBarCompta(null);
    props.getAllUSERS();
    this.boxRef = React.createRef();
    document.title = 'Messenger';
    props.changeNamePage('Messenger');
    props.changeActiveItemSideBar(4);
    this.state = {
      countMessageIsSeenByConversation: 0,
      conversationIdSwitch: true,
      conversationId: null,
      messages: [],
      doctors: [],
      allConversation: [],
      conversation: false,
      user_checked: null,
      userSelected: props.allUsers.length > 0 ? props.allUsers[0] : null,
      initialValues: {
        content: '',
        sender_id: '',
        conversation_id: '',
        to: '',
        seen: '',
        created_at: '',
      },
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangeIdConsultation = this.handleChangeIdConsultation.bind(this);
    this.keyPressed = this.keyPressed.bind(this);
  }

  componentDidMount() {
    const { initialValues, userSelected } = this.state;
    channel.bind(`messages-${this.props.user.login_access.id}`, (data) => {
      if (data && data.conversation) {
        this.setState({
          messages: data.conversation.messages,
          conversation: data.conversation,
        });
      }
    });
    channel.bind(`add-new-message`, (data) => {
      if (data.conversation_id === this.state.conversation.id) {
        this.setState({
          ...this.state,
          messages: data.conversation.messages,
          conversation: data.conversation,
          initialValues: {
            ...initialValues,
            conversation_id: data.conversation.id,
          },
        });
      }
    });

    SelectorMessengerItem();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { destroyConversation } = this.props;
    if (destroyConversation !== prevProps.destroyConversation) {
      this.setState({
        messages: [],
        conversation: false,
        conversationIdSwitch: false,
        conversationSelected: null,
        //allConversation: [],
      });
    }
  }

  handleChangeIdConsultation(id, type) {
    const { allUsers } = this.props;
    this.props.initialState();
    const userSelected = allUsers.find((item) => item && item.id === id);
    this.setState({
      user_checked: id,
      userSelected: userSelected,
      //messages: [],
    });
    const { conversation } = this.state;
    /*channel.bind(
      `add-new-conversation-${this.props.user.login_access.id}-${parseInt(id)}`,
      (data) => {
        if (data) {
          this.setState({
            messages: data.messages,
            conversationId: id,
            conversationIdSwitch: false,
            conversation: data,
            conversationSelected: data.id,
            /!*initialValues: {
              ...initialValues,
              conversation_id: data.id,
              to:
                data.ToDoctor && data.ToDoctor.id === this.props.user.id
                  ? data.FromDoctor && data.FromDoctor.id
                  : data.ToDoctor && data.ToDoctor.id,
            },*!/
            doctors: {},
          });
        }
      },
    );*/
    this.props.countMessagesIsSeenByConversation(conversation.id);
  }

  keyPressed(event) {
    if (event.key === 'Enter') {
      const { initialValues, conversationId } = this.state;
      if (initialValues.content) {
        this.props.submitMessageForm(initialValues, conversationId);
        this.setState({
          initialValues: {
            ...initialValues,
            content: '',
          },
        });
      }
    }
  }

  handleInputChange(event) {
    const { initialValues } = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      initialValues: {
        ...initialValues,
        [name]: value,
      },
    });
  }

  render() {
    const { allUsers, user, isLoadingMessages, paramsMessages } = this.props;
    const { conversation, initialValues, user_checked, messages, userSelected } =
      this.state;
    let newMessages = _.orderBy(messages, 'id');
    return (
      <div className="main_content">
        <div className="patients_messenger_contact_list">
          <div className="patients_messenger_contact_list_container">
            {allUsers && allUsers.length > 0
              ? allUsers.map((doctor) => {
                  if (doctor)
                    return (
                      <Item_conversation
                        type="doc"
                        office={doctor && doctor.office_id ? doctor.office_id : null}
                        handleChangeIdConsultation={this.handleChangeIdConsultation}
                        submitConversationForm={this.props.submitConversationForm}
                        paramsMessages={paramsMessages}
                        doctor={doctor}
                        accountType={doctor && doctor.office_id ? 'conv' : ''}
                        user={user}
                        key={doctor.id}
                        id={doctor.id}
                        user_checked={
                          user_checked
                            ? user_checked
                            : allUsers.length > 0
                            ? allUsers[0] && allUsers[0].id
                            : null
                        }
                        img={
                          doctor.image_practitioner &&
                          doctor.image_practitioner.length > 0
                            ? `${process.env.REACT_APP_PUBLIC_URL}${doctor.image_practitioner[0].url}`
                            : doctor.photo
                        }
                        date=""
                        getMessages={this.props.getMessages}
                        short_message={
                          doctor.practitioner
                            ? 'kinésithérapeute'
                            : doctor.secretary
                            ? 'Secrétaire'
                            : 'Assistant'
                        }
                        displayName={
                          doctor.practitioner
                            ? `${doctor.practitioner.last_name} ${doctor.practitioner.first_name}`
                            : doctor.secretary
                            ? `${doctor.secretary.last_name} ${doctor.secretary.first_name}`
                            : doctor.assistant
                            ? `${doctor.assistant.last_name} ${doctor.assistant.first_name}`
                            : ''
                        }
                      />
                    );
                })
              : ''}
          </div>
        </div>
        <div className="chat_section">
          {conversation ? (
            <div className="message_input_field" style={{ height: '57px' }}>
              <div
                className="patient_contact_details_container"
                style={{ marginTop: '1%', marginLeft: '1%' }}
              >
                <div className="patient_contact_img_container">
                  {userSelected.practitioner ? (
                    userSelected.practitioner.image_practitioners.length > 0 ? (
                      <img
                        style={{ width: '40px', height: '40px' }}
                        src={`${process.env.REACT_APP_PUBLIC_URL}${userSelected.practitioner.image_practitioners[0].url}`}
                        alt=""
                      />
                    ) : (
                      <div className="patient_contact_img_container">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                            fill="#8083A3"
                          />
                        </svg>
                      </div>
                    )
                  ) : userSelected.assistant ? (
                    userSelected.assistant.photo ? (
                      <img
                        style={{ width: '40px', height: '40px' }}
                        src={`${process.env.REACT_APP_PUBLIC_URL}${userSelected.assistant.photo}`}
                        alt=""
                      />
                    ) : (
                      <div className="patient_contact_img_container">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                            fill="#8083A3"
                          />
                        </svg>
                      </div>
                    )
                  ) : userSelected.secretary && userSelected.secretary.photo ? (
                    <img
                      style={{ width: '40px', height: '40px' }}
                      src={`${process.env.REACT_APP_PUBLIC_URL}${userSelected.secretary.photo}`}
                      alt=""
                    />
                  ) : userSelected.office_id ? (
                    <div className="patient_contact_img_container">
                      <i className="fas fa-users" />{' '}
                    </div>
                  ) : (
                    <div className="patient_contact_img_container">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                          fill="#8083A3"
                        />
                      </svg>
                    </div>
                  )}
                </div>
                <div className="patient_contact_text_container">
                  <div
                    style={{
                      marginTop: conversation.office_id ? '18%' : '',
                      marginBottom: '-5%',
                    }}
                    className="first_row"
                  >
                    <span id="patient_username">
                      {conversation && conversation.to_ === user.login_access.id
                        ? `${user.last_name} ${user.first_name}`
                        : userSelected.practitioner
                        ? `${userSelected.practitioner.last_name} ${userSelected.practitioner.first_name}`
                        : userSelected.assistant
                        ? `${userSelected.assistant.last_name} ${userSelected.assistant.first_name}`
                        : userSelected.secretary
                        ? `${userSelected.secretary.last_name} ${userSelected.secretary.first_name}`
                        : 'Groupe'}{' '}
                    </span>
                  </div>
                  <div style={{ marginBottom: '-5%' }} className="first_row">
                    <span id="patient_general_info_city">
                      {conversation && conversation.to_ === user.login_access.id
                        ? user.gsm
                        : userSelected.practitioner
                        ? userSelected.practitioner.phone
                        : userSelected.assistant
                        ? userSelected.assistant.phone
                        : userSelected.secretary
                        ? userSelected.secretary.phone
                        : ''}{' '}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          {conversation ? (
            <>
              <Messages
                user={user}
                to={initialValues.to}
                messages={newMessages}
                isLoadingMessages={isLoadingMessages}
                conversation={conversation}
              />
              <div className="message_input_field">
                {!conversation.office_id && userSelected.access_status !== 'active' ? (
                  <Alert style={{ width: '100%' }} severity="info">
                    Ce compte est désactivé ! Vous ne pouvez plus lui envoyer des messages
                    mais vous pouvez quand-même consulter l'historique de la discussion.
                  </Alert>
                ) : (
                  <>
                    <input
                      value={initialValues.content}
                      name="content"
                      onKeyPress={this.keyPressed}
                      onChange={(e) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            content: e.target.value,
                            sender_id: user.login_access.id,
                            to: userSelected.id,
                            conversation_id: conversation ? conversation.id : '',
                            seen: 0,
                            created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                          },
                        });
                      }}
                      type="text"
                      placeholder="Tapez pour ajouter votre message"
                    />
                    <div className="input_field_icons">
                      <div
                        onClick={async () => {
                          if (initialValues.content && initialValues.sender_id) {
                            /*if (
                                      !initialValues.conversation_id &&
                                      conversationAccount &&
                                      conversationAccount.length === 0
                                    ) {
                                      await this.props.submitConversationForm({
                                        to: initialValues.to,
                                      });
                                    }*/
                            this.props.submitMessageForm(initialValues, paramsMessages);
                            this.props.countMessagesIsSeenByConversation(
                              initialValues.conversation_id,
                            );
                            this.setState({
                              initialValues: {
                                ...initialValues,
                                content: '',
                                conversation_id: conversation ? conversation.id : '',
                              },
                            });
                          }
                        }}
                        className="send_icon"
                        style={{ cursor: 'pointer' }}
                      >
                        <svg
                          width="44"
                          height="45"
                          viewBox="0 0 44 45"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            y="0.4375"
                            width="44"
                            height="44"
                            rx="10"
                            fill="#5446DB"
                          />
                          <path
                            d="M13.9031 29.1875V23.9492L25.1531 22.4375L13.9031 20.9258V15.6875L29.6531 22.4375L13.9031 29.1875Z"
                            fill="#FAFBFF"
                          />
                        </svg>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <div className="consultation_detailed_info_section">
              <div className="empty_consultation_result_container">
                <div className="empty_consultation_result">
                  <img src={require('../../assets/img/main_pic_empty.svg')} />
                  <span id="no_consultation_desc">
                    Veuillez sélectionner une discussion ou chercher un nouveau
                    kinésithérapeute pour discuter
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Messenger);
