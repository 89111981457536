import React, { Component } from 'react';
import { SwipeableDrawer } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { newDataEmployeeManagementDraft } from '../../../utils/helpres';
class SwipeableDrawerCreate extends Component {
  render() {
    const {
      activeDrawer,
      anchor,
      loadingAddSessions,
      initialValues,
      hasError,
      motifList,
      medecins,
    } = this.props;
    return (
      <SwipeableDrawer
        style={{ width: '50%' }}
        anchor={anchor}
        open={activeDrawer}
        onClose={this.props.toggleDrawer(anchor, false)}
        onOpen={this.props.toggleDrawer(anchor, true)}
      >
        <div className="right_side_bar">
          <div className="right_side_bar_header">
            <span id="add_app_sidebar_title">Modifier cure</span>
            <svg
              onClick={() => {
                this.props.handleCloseDrawer();
              }}
              style={{ cursor: 'pointer' }}
              id="close-right-sidebar"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="39"
                height="39"
                rx="9.5"
                fill="white"
                stroke="#ECEEF5"
              />
              <path
                d="M25.2383 15.8164L21.0547 20L25.2383 24.1836L24.1836 25.2383L20 21.0547L15.8164 25.2383L14.7617 24.1836L18.9453 20L14.7617 15.8164L15.8164 14.7617L20 18.9453L24.1836 14.7617L25.2383 15.8164Z"
                fill="#8083A3"
              />
            </svg>
          </div>
          <div className="right_side_bar_body">
            <div className="modal-body">
              <form style={{ padding: '2px 0px' }}>
                <div className="form-row">
                  <div
                    style={{ width: '100%' }}
                    className={
                      hasError && !initialValues.motif_id
                        ? 'right_sidebar_body_item type3'
                        : 'right_sidebar_body_item'
                    }
                  >
                    <label htmlFor="">Diagnostique</label>
                    <div className="input_and_icon">
                      <select
                        value={initialValues.motif_id}
                        name="motif_id"
                        onChange={this.props.handleChangeInput}
                        className="form-control"
                        id="exampleFormControlSelect1"
                      >
                        <option value="" selected>
                          Diagnostique
                        </option>
                        {motifList &&
                          motifList.length > 0 &&
                          motifList.map((motif) => {
                            if (motif.actif)
                              return (
                                <option
                                  key={motif.id}
                                  data-label={motif.label}
                                  value={parseInt(motif.id)}
                                >
                                  {motif.label}
                                </option>
                              );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                {initialValues.labelMotif === 'Autre' ? (
                  <div className="form-row">
                    <div
                      style={{ width: '100%' }}
                      className={
                        hasError && !initialValues.display_name
                          ? 'form-group type3'
                          : 'form-group'
                      }
                    >
                      <label></label>
                      <div className="input_and_icon">
                        <input
                          maxLength={100}
                          value={initialValues.diagnostic}
                          name="diagnostic"
                          onChange={this.props.handleChangeInput}
                          type="text"
                          placeholder="Nouveau diagnostique"
                        />
                        <i className="fas fa-pen"></i>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="form-row">
                  <div
                    style={{ width: '100%' }}
                    className={
                      (hasError && !initialValues.date) ||
                      (hasError && initialValues.date) //&& moment(initialValues.date, 'YYYY-MM-DD').isBefore(moment()))
                        ? 'form-group type3'
                        : 'form-group'
                    }
                  >
                    <label>Date </label>
                    <div className="input_and_icon">
                      <input
                        style={{ width: '100%' }}
                        value={initialValues.date}
                        name="date"
                        onChange={this.props.handleChangeInput}
                        type="date"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div style={{ width: '100%' }} className="form-group">
                    <label>Note</label>
                    <div className="input_and_icon">
                      <textarea
                        maxLength={200}
                        style={{
                          border: '0px',
                          borderBottom: '1px #ECEEF5 solid',
                          width: '100%',
                        }}
                        value={initialValues.note}
                        name="note"
                        onChange={this.props.handleChangeInput}
                        placeholder="Vos notes ici."
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div style={{ width: '100%' }} className="form-group">
                    <label>Médecin prescripteur </label>
                    <Autocomplete
                      id="size-small-standard"
                      size="small"
                      options={newDataEmployeeManagementDraft(medecins)}
                      onChange={(event, option) => {
                        this.props.onChangeDoctor(option)
                      }}
                      defaultValue={{
                        title: initialValues.doctor,
                      }}
                      getOptionLabel={(option) => option.title}
                      renderInput={(cities) => (
                        <TextField
                          style={{ width: '100%' }}
                          placeholder="Choisissez un médecin prescripteur"
                          {...cities}
                          variant="standard"
                          //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                          //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                          // error={hasError && !initialValues.address_practitioner.city}
                        />
                      )}
                    />
                  </div>
                </div>
              </form>
            </div>
            <hr style={{ width: '95%' }} />
            <div
              className="modal-footer create_prise_en_charge_1_footer"
              style={{
                borderBottom: '1px solid #dee2e6',
                padding: '1.5rem 0rem',
                margin: '0px 20px',
              }}
            >
              <button
                onClick={() => this.props.handleCloseDrawer()}
                type="button"
                className="modal_close_btn"
                id="retour"
                style={{ backgroundColor: '#f6f6f6' }}
              >
                Annuler
              </button>
              <button
                onClick={() => this.props.submitFormConsultation(initialValues)}
                id="valider"
                style={{ marginLeft: '0' }}
                type="button"
                className="btn_valid"
              >
                {loadingAddSessions ? (
                  <CircularProgress
                    style={{
                      marginLeft: '4%',
                      width: '35px',
                      height: '25px',
                      color: 'white',
                      animation: 'infinite',
                    }}
                  />
                ) : (
                  'Valider'
                )}
              </button>
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    );
  }
}

export default SwipeableDrawerCreate;
