import React, { Component } from 'react';
import './index.css';
import { connect } from 'react-redux';
import actionsJs from '../../js/actions';
import actions from './store/actions';
import { firstNumberPhone, isNumeric, lengthPhone, validate } from '../../utils/helpres';
import 'react-accessible-accordion/dist/fancy-example.css';
import Formation from './composents/formation';
import Faq from './composents/faq';
import Contact from './composents/contact';
import VersionsCuida from './composents/versions';

const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  sendMail: state.SupportReducer.sendMail,
  addContact: state.SupportReducer.addContact,
  isLoadingAddContact: state.SupportReducer.isLoadingAddContact,
});

const mapDispatchToProps = (dispatch) => ({
  sendMail: (values) => dispatch(actions.sendMail(values)),
  submitContact: (values) => dispatch(actions.submitContact(values)),
  changeActiveItemSideBarSettings: (item, data, create) =>
    dispatch(actionsJs.changeActiveItemSideBarSettings(item, data, create)),
  changeActiveItemSideBarCompta: (data) =>
    dispatch(actionsJs.changeActiveItemSideBarCompta(data)),
});

class Support extends Component {
  constructor(props) {
    super(props);
    props.changeNamePage('Support');
    props.changeActiveItemSideBar(7);
    props.changeActiveItemSideBarCompta(null);
    props.changeActiveItemSideBarSettings(111, false, true);
    document.title = 'Support';
    this.state = {
      showFormation: true,
      showFAQ: false,
      showContact: false,
      showVersioning:false,
      setIsOpenModal: false,
      setIsOpenModalCall: false,
      hasError: false,
      switchBtnRadio: true,
      initialValues: {
        email: props.user.email,
        last_name: props.user.last_name,
        first_name: props.user.first_name,
        gsm: props.user.gsm,
        message: '',
      },
    };
    this.submitForm = this.submitForm.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
  }
  submitForm(initialValues) {
    if (
      !initialValues.email ||
      !initialValues.last_name ||
      !initialValues.first_name ||
      !initialValues.gsm ||
      !initialValues.message ||
      !validate(initialValues.first_name) ||
      !validate(initialValues.last_name) ||
      !firstNumberPhone(initialValues.gsm) ||
      !lengthPhone(initialValues.gsm) ||
      !isNumeric(initialValues.gsm)
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else {
      this.setState({
        ...initialValues,
        hasError: false,
      });
      this.props.sendMail(initialValues);
      this.props.submitContact(initialValues);
    }
  }
  handleChangeInput(event) {
    const { initialValues } = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      initialValues: {
        ...initialValues,
        [name]: value,
      },
    });
  }
  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.sendMail) {
      this.setState({
        initialValues: {
          ...this.state.initialValues,
          message: '',
        },
      });
    }
  }

  render() {
    const {
      showFormation,
      showFAQ,
      showContact,
      showVersioning,
      initialValues,
      hasError,
      switchBtnRadio,
    } = this.state;
    const { user, isLoadingAddContact } = this.props;
    return (
      <div className="main_content">
        <div className="bilan_container">
          <div className="main_content">
            <div className="facture_patient">
              <div className="facture_patients_body">
                <div
                  onClick={() =>
                    this.setState({
                      showFormation: true,
                      showFAQ: false,
                      showContact: false,
                      showVersioning:false,
                    })
                  }
                  key={1}
                  className={
                    showFormation
                      ? 'facture_patient_body_item selected_patient'
                      : 'facture_patient_body_item'
                  }
                >
                  <div className="facture_patient_info_container">
                    <div
                      // style={{ backgroundColor: selectedBill ? '#f8f9fc' : '#ECEEF5' }}
                      className="facture_patient_img_container"
                    >
                      <i class="fas fa-play"></i>
                      {/**
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                          fill="#8083A3"
                        />
                      </svg>
                       */}
                    </div>
                    <div className="facture_patient_info" style={{ alignSelf: 'center' }}>
                      <span id="facture_patient_name">Formation</span>
                    </div>
                  </div>

                  <div className="call_icon_bill">
                    <i className="fas fa-angle-right"></i>
                  </div>
                </div>
                <div
                  onClick={() =>
                    this.setState({
                      showFormation: false,
                      showFAQ: true,
                      showContact: false,
                      showVersioning:false,
                    })
                  }
                  key={2}
                  className={
                    showFAQ
                      ? 'facture_patient_body_item selected_patient'
                      : 'facture_patient_body_item'
                  }
                >
                  <div className="facture_patient_info_container">
                    <div
                      // style={{ backgroundColor: selectedBill ? '#f8f9fc' : '#ECEEF5' }}
                      className="facture_patient_img_container"
                    >
                      <i class="fas fa-question"></i>
                      {/*
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                          fill="#8083A3"
                        />
                      </svg>
                      */}
                    </div>
                    <div className="facture_patient_info" style={{ alignSelf: 'center' }}>
                      <span id="facture_patient_name">Questions fréquentes</span>
                    </div>
                  </div>

                  <div className="call_icon_bill">
                    <i className="fas fa-angle-right"></i>
                  </div>
                </div>
                <div
                  onClick={() =>
                    this.setState({
                      showFormation: false,
                      showFAQ: false,
                      showContact: false,
                      showVersioning: true
                    })
                  }
                  key={2}
                  className={
                    showVersioning
                      ? 'facture_patient_body_item selected_patient'
                      : 'facture_patient_body_item'
                  }
                >
                  <div className="facture_patient_info_container">
                    <div
                      className="facture_patient_img_container"
                    >
                      <i class="fas fa-sitemap"></i>
                    </div>
                    <div className="facture_patient_info" style={{alignSelf:'center'}}>
                      <span id="facture_patient_name">Fonctions à venir</span>
                    </div>
                  </div>

                  <div className="call_icon_bill">
                    <i className="fas fa-angle-right"></i>
                  </div>
                </div>
                <div
                  onClick={() =>
                    this.setState({
                      showFormation: false,
                      showFAQ: false,
                      showContact: true,
                      showVersioning:false,
                    })
                  }
                  key={3}
                  className={
                    showContact
                      ? 'facture_patient_body_item selected_patient'
                      : 'facture_patient_body_item'
                  }
                >
                  <div className="facture_patient_info_container">
                    <div
                      // style={{ backgroundColor: selectedBill ? '#f8f9fc' : '#ECEEF5' }}
                      className="facture_patient_img_container"
                    >
                      <i class="far fa-paper-plane"></i>
                      {/**
                       <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                          fill="#8083A3"
                        />
                      </svg>
                       */}
                    </div>
                    <div className="facture_patient_info" style={{ alignSelf: 'center' }}>
                      <span id="facture_patient_name">Contact</span>
                    </div>
                  </div>

                  <div className="call_icon_bill">
                    <i className="fas fa-angle-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="facture_info_section">
              <div className="bilan_main_content">
                {showFormation ? (
                  <Formation />
                ) : showFAQ ? (
                  <Faq />
                ) 
                : showVersioning ? 
                <VersionsCuida />
                : (
                  <Contact
                    user={user}
                    hasError={hasError}
                    submitForm={this.submitForm}
                    handleChangeInput={this.handleChangeInput}
                    initialValues={initialValues}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Support);
