import React, { Component } from 'react';
import moment from 'moment';
import Consulatation from './consulatation';
import consultationActions from '../../consultations/store/actions';
import actions from '../store/actions';
import { connect } from 'react-redux';
import Modals from '../../../components/modal';
import { Link } from 'react-router-dom';
import Empty_container from '../../../components/empty_container';
import { SwipeableDrawer } from '@material-ui/core';
import InputField from '../../../components/inputField';
import FormDrawer from '../../recette/form-recette';

import {
  isHasPermission,
  isNumeric,
  lengthPhone,
  newDataEmployeeManagementDraft,
  validate,
} from '../../../utils/helpres';
import SimpleReactValidator from 'simple-react-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import Protocol from '../../consultations/protocol';
import Bill from '../../consultations/composent/bill';
import Receipt from '../../consultations/composent/receipt';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import actionsJs from '../../../js/actions';
import { country, sortCities } from '../../../utils/constants';
import actionsPatient from '../store/actions';
import Session from './drawer-session';
import Tooltip from '@mui/material/Tooltip';

const mapStateToProps = (state) => ({
  updatePatientRecord: state.PatientReducer.updatePatientRecord,
  patientConsultationList: state.ConsultationReducer.patientConsultationList,
  paramsConsultation: state.ConsultationReducer.params,
  motifList: state.ConsultationReducer.motifList,
  loadingMotifs: state.ConsultationReducer.loadingMotifs,
  updateConsultation: state.ConsultationReducer.updateConsultation,
  loadingGetDispoDoctor: state.ConsultationReducer.loadingGetDispoDoctor,
  getDispoDate: state.ConsultationReducer.getDispoDate,
  loadingAddSessions: state.ConsultationReducer.loadingAddSessions,
  addSessions: state.ConsultationReducer.addSessions,
  user: state.AppReducer.user,
  billPatientRecord: state.PatientReducer.billPatientRecord,
  receiptPatientRecord: state.PatientReducer.receiptPatientRecord,
  patientStatistical: state.PatientReducer.patientStatistical,
  employeeManagement: state.AppReducer.employeeManagement,
  paramsBilan: state.PatientReducer.paramsBilan,
  allSessionsPatient: state.ConsultationReducer.allSessionsPatient,
  isLoadingAllSessionsPatient: state.ConsultationReducer.isLoadingAllSessionsPatient,
});

const mapDispatchToProps = (dispatch) => ({
  changeSearchedStringConsultation: (params, id) => {
    dispatch(consultationActions.changeSearchedString(params));
    dispatch(consultationActions.getPatientConsultations(params, id));
  },
  getDispoDoctor: (values) => dispatch(consultationActions.getDispoDoctor(values)),
  submitPatientRecordForm: async (values, id) => {
    await dispatch(actions.submitPatientRecordForm(values));
    dispatch(actions.getPatientRecordById(id));
  },
  displaylistMotifs: () => dispatch(consultationActions.getMotifs()),
  createConsultation: async (values, initialValuesMedecin, params, id) => {
    await dispatch(consultationActions.addConsultation(values, initialValuesMedecin));
    dispatch(consultationActions.getPatientConsultations(params, id));
  },
  getEmployeeManagement: (type) => dispatch(actionsJs.getEmployeeManagement(type)),
  getBilanByPatientRecordId: (params, id) =>
    dispatch(actions.getBilanByPatientRecordId(params, id)),
  getProtocoleByPatientRecordId: (params, id) =>
    dispatch(actions.getProtocoleByPatientRecordId(params, id)),
  getPatientSessions: async (id) => {
    await dispatch(consultationActions.getPatientSessions(id));
  },
});

class Patient extends Component {
  constructor(props) {
    super(props);
    props.getPatientSessions(this.props.patientRecord.patient.id);
    props.changeSearchedStringConsultation(
      {
        ...this.props.paramsConsultation,
        limit: 2,
        startDate: '',
      },
      this.props.patientRecord.id,
    );
    props.getBilanByPatientRecordId(
      {
        ...this.props.paramsBilan,
        limit: this.props.showBilans ? 10 : 2,
      },
      this.props.patientRecord.id,
    );
    props.getProtocoleByPatientRecordId(
      {
        ...this.props.paramsBilan,
        limit: this.props.showBilans ? 10 : 2,
      },
      this.props.patientRecord.id,
    );
    props.displaylistMotifs();
    props.getEmployeeManagement(true);
    this.state = {
      initialValuesMedecin: {
        newMedecin: false,
        hasError: false,
        first_name: '',
        speciality: '',
        last_name: '',
        phone: '',
      },
      initialValues: {
        practitioner_id:
          props.user.login_access.offices.length === 0 ? props.user.id : null,
        motif_id: null,
        doctor: '',
        diagnostic: '',
        date: '',
        note: '',
        patient_id: props.patientRecord && props.patientRecord.patient.id,
        start_date: '',
        end_date: '',
        unit_price: null,
        quality: '',
        price_with_out_cnam: '',
        price_cnam: '',
        type: 0,
        hourStart: '',
        regime: 'autre',
        duration: null,
        sessions_per_week: null,
        consultation_id: null,
        sessions_count: null,
      },
      showSession: false,
      setIsOpenModal: false,
      note: props.patientRecord && props.patientRecord.note,
      showNotePatientRecord: false,
      hasError: false,
      setIsOpen: false,
      errorDate: false,
      showDrawerRecette: false,
    };
    this.openModal = this.openModal.bind(this);
    this.handleShowNotePatientRecord = this.handleShowNotePatientRecord.bind(this);
    this.handleChangeNotePatientRecord = this.handleChangeNotePatientRecord.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.openModalConsultation = this.openModalConsultation.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.toggleDrawerSession = this.toggleDrawerSession.bind(this);
    this.toggleDrawerRecette = this.toggleDrawerRecette.bind(this);

    this.validator = new SimpleReactValidator({
      messages: {
        required: 'Champ requis.',
        integer: 'Champ ne doit contenir que des chiffres',
        string: 'Champ ne doit contenir que des lettres',
        // OR
        default: 'Cette information est requise.', // will override all messages
      },
    });
  }

  openModalConsultation() {
    const { initialValues } = this.state;
    this.setState({
      setIsOpenModal: !this.state.setIsOpenModal,
      initialValues: {
        ...initialValues,
        motif_id: null,
        note: '',
      },
    });
  }

  openModal() {
    this.setState({
      setIsOpen: !this.state.setIsOpen,
    });
  }
  componentDidMount() {
    this.props.changeSearchedStringBill(
      {
        ...this.props.paramsBilan,
        limit: 2,
        startDate: '',
      },
      this.props.patientRecord && this.props.patientRecord.id,
    );
    this.props.changeSearchedStringReceipt(
      {
        ...this.props.paramsBilan,
        limit: 2,
        startDate: '',
      },
      this.props.patientRecord && this.props.patientRecord.id,
    );
  }

  handleChangeNotePatientRecord(e) {
    this.setState({
      note: e.target.value,
    });
  }

  handleShowNotePatientRecord() {
    this.setState({
      showNotePatientRecord: true,
    });
  }

  submitFormConsultation(initialValues, initialValuesMedecin) {
    if (
      !initialValues.regime ||
      (initialValues.type === 1 && !initialValues.practitioner_id) ||
      // !initialValues.start_date ||
      !initialValues.sessions_count ||
      (initialValues.sessions_per_week &&
        isNumeric(initialValues.sessions_per_week) &&
        initialValues.sessions_per_week > 6) ||
      (initialValues.sessions_per_week && !isNumeric(initialValues.sessions_per_week)) ||
      (initialValues.sessions_count &&
        isNumeric(initialValues.sessions_count) &&
        initialValues.sessions_count > 36) ||
      (initialValues.sessions_count && !isNumeric(initialValues.sessions_count)) ||
      !initialValues.date ||
      !initialValues.motif_id ||
      //(initialValues.note && !validate(initialValues.note)) ||
      (initialValues.doctor && !validate(initialValues.doctor)) ||
      (initialValues.motif_id && !isNumeric(initialValues.motif_id)) ||
      (initialValues.motif_id &&
        initialValues.labelMotif === 'Autre' &&
        !initialValues.diagnostic) //|| (initialValues.date && moment(initialValues.date).isBefore(moment()))
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else if (
      (initialValuesMedecin.newMedecin && !initialValuesMedecin.first_name) ||
      (initialValuesMedecin.newMedecin && !initialValuesMedecin.last_name) ||
      (initialValuesMedecin.newMedecin && !initialValuesMedecin.speciality) ||
      (initialValuesMedecin.newMedecin && !initialValuesMedecin.phone) ||
      (initialValuesMedecin.newMedecin &&
        initialValuesMedecin.first_name &&
        !validate(initialValuesMedecin.first_name)) ||
      (initialValuesMedecin.newMedecin &&
        initialValuesMedecin.last_name &&
        !validate(initialValuesMedecin.last_name)) ||
      (initialValuesMedecin.newMedecin &&
        initialValuesMedecin.phone &&
        !lengthPhone(initialValuesMedecin.phone)) ||
      (initialValuesMedecin.newMedecin &&
        initialValuesMedecin.phone &&
        !isNumeric(initialValuesMedecin.phone))
    ) {
      this.setState({
        initialValuesMedecin: {
          ...initialValuesMedecin,
          hasError: true,
        },
      });
    } else if (this.validator.allValid()) {
      initialValues.patient_id =
        this.props.patientRecord && this.props.patientRecord.patient.id;
      this.props.createConsultation(
        initialValues,
        initialValuesMedecin,
        {
          ...this.props.paramsConsultation,
          limit: 2,
        },
        this.props.patientRecord.id,
      );
    } else {
      this.validator.showMessages();
      this.setState({
        ...initialValues,
        hasError: true,
      });
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {}

  componentWillReceiveProps(nextProps, nextContext) {
    const { updatePatientRecord, updateConsultation } = nextProps;
    const { initialValues } = this.state;
    const { getDispoDate, patientRecord, addSessions } = nextProps;
    if (getDispoDate && getDispoDate.length > 0) {
      const { patient } = patientRecord;
      initialValues.patient = patient;
      initialValues.patient_id = patient.id;
      initialValues.patient_record_id = patientRecord.id;
      this.props.gotToAgendaPrescription(getDispoDate, initialValues);
    }
    if (updatePatientRecord) {
      this.setState({
        ...this.state,
        showNotePatientRecord: false,
      });
    }
  }

  handleChangeInput(event) {
    const { initialValues } = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (target.options && target.options[target.selectedIndex].dataset.label) {
      this.setState({
        initialValues: {
          ...initialValues,
          labelMotif: target.options[target.selectedIndex].dataset.label,
          price_cnam: target.options[target.selectedIndex].dataset.price_cnam,
          price_with_out_cnam:
            target.options[target.selectedIndex].dataset.price_with_out_cnam,
          [name]: value,
          unit_price: initialValues.regime
            ? initialValues.regime === 'cnam'
              ? target.options[target.selectedIndex].dataset.price_cnam
              : initialValues.regime === 'autre'
              ? target.options[target.selectedIndex].dataset.price_with_out_cnam
              : ''
            : '',
        },
      });
    } else if (name === 'regime') {
      this.setState({
        initialValues: {
          ...initialValues,
          unit_price:
            value === 'cnam'
              ? initialValues.price_cnam
              : value === 'autre'
              ? initialValues.price_with_out_cnam
              : '',
          [name]: value,
        },
      });
    } else
      this.setState({
        initialValues: {
          ...initialValues,
          [name]: value,
        },
      });
  }

  toggleDrawer = (anchor, open, id) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      left: open,
      anchor: anchor,
      setIsOpenModal: false,
      hasError: false,
    });
  };
  toggleDrawerRecette = (anchor, open, id) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      left: open,
      anchor: anchor,
      setIsOpenModal: false,
      hasError: false,
    });
  };
  toggleDrawerSession = (anchor, open, id) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      left: open,
      anchor: anchor,
      showSession: false,
      hasError: false,
    });
  };
  render() {
    const {
      motifList,
      patientRecord,
      bilanPatientRecord,
      patientConsultationList,
      loadingAddSessions,
      user,
      protocolePatientRecord,
      medecins,
      isLoadingBilanPatientRecord,
      billPatientRecord,
      receiptPatientRecord,
      employeeManagement,
      sandwich_menu_btn,
      patientStatistical,
      isLoadingAllSessionsPatient,
      allSessionsPatient,
    } = this.props;
    const {
      showNotePatientRecord,
      setIsOpen,
      setIsOpenModal,
      initialValues,
      hasError,
      showDrawerRecette,
      initialValuesMedecin,
      showSession,
    } = this.state;
    let data;
    if (patientConsultationList) data = patientConsultationList.data;
    const duration = moment.duration(
      moment().diff(moment(patientRecord && patientRecord.patient.birthday)),
    );
    return (
      <div style={{ display: 'flex' }}>
        <Session
          toggleDrawer={this.toggleDrawerSession}
          //changeActiveItemSideBarRDV={this.props.changeActiveItemSideBarRDV}
          activeItemSideBarRDV={showSession}
          /*selected_id={selected_id}
              params={params}*/
          patient={patientRecord.patient}
          patient_record={patientRecord}
          onCancel={() => this.setState({ showSession: false })}
          patient_id={patientRecord.patient.id}
          patientStatistical={patientStatistical}
          isLoadingAllSessionsPatient={isLoadingAllSessionsPatient}
          allSessionsPatient={allSessionsPatient}
        />
        <FormDrawer
          toggleDrawer={this.toggleDrawerRecette}
          //changeActiveItemSideBarRDV={this.props.changeActiveItemSideBarRDV}
          activeItemSideBarRDV={showDrawerRecette}
          /*selected_id={selected_id}
            params={params}*/
          patient={true}
          onCancel={() => this.setState({ showDrawerRecette: false })}
          patient_id={patientRecord.patient.id}
        />
        <Modals
          data={
            <div className="recent_patients_item_component">
              <div style={{ marginRight: '10%' }} className="recent_patient_info">
                <span id="recent_patient_name">
                  {patientRecord.patient.phone ? patientRecord.patient.phone : '-'}
                </span>
              </div>
              {'   '}
              <div className="recent_patient_call">
                <i className="fas fa-phone-alt"></i>
              </div>
            </div>
          }
          setIsOpen={setIsOpen}
          openModal={this.openModal}
        />
        <div className="patient_detailed_info_section" style={{ flex: '50%' }}>
          <div
            style={{ marginLeft: '30px' }}
            className="patient_detailed_info_section_header"
          >
            <div className="patient_avatar">
              <svg
                width="33"
                height="34"
                viewBox="0 0 33 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.73958 23.2812C9.42708 21.7812 12.9583 21.0312 16.3333 21.0312C19.7083 21.0312 23.2083 21.7812 26.8333 23.2812C30.5208 24.7188 32.3646 26.625 32.3646 29V33.0312H0.302084V29C0.302084 26.625 2.11458 24.7188 5.73958 23.2812ZM21.9583 14.6562C20.3958 16.2188 18.5208 17 16.3333 17C14.1458 17 12.2708 16.2188 10.7083 14.6562C9.14583 13.0938 8.36458 11.2188 8.36458 9.03125C8.36458 6.84375 9.14583 4.96875 10.7083 3.40625C12.2708 1.78125 14.1458 0.96875 16.3333 0.96875C18.5208 0.96875 20.3958 1.78125 21.9583 3.40625C23.5208 4.96875 24.3021 6.84375 24.3021 9.03125C24.3021 11.2188 23.5208 13.0938 21.9583 14.6562Z"
                  fill="#8083A3"
                />
              </svg>
            </div>
            <div className="patient_info">
              <div className="patient_general_info">
                <span id="patient_general_info_name">
                  {`${patientRecord.patient.last_name.replace(
                    /(^\w{1})|(\s+\w{1})/g,
                    (letter) => letter.toUpperCase(),
                  )} ${patientRecord.patient.first_name.replace(
                    /(^\w{1})|(\s+\w{1})/g,
                    (letter) => letter.toUpperCase(),
                  )}`}{' '}
                  {patientRecord.pr_number ? `(FN° ${patientRecord.pr_number})` : ''}{' '}
                </span>
                <span id="patient_general_info_city">
                  {patientRecord.patient.address_patient &&
                    patientRecord.patient.address_patient.city}
                </span>
              </div>
              <div className="patient_general_info_btns">
                {isHasPermission(user.login_access.permissions, 'ADD_NEW_CURE') ? (
                  <div
                    style={{ width: 200 }}
                    onClick={() => this.openModalConsultation()}
                    className="np_btn"
                  >
                    <svg
                      width="14"
                      height="16"
                      viewBox="0 0 14 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.51172 10.5986V12.0752H3.23828V10.5986H8.51172ZM12.2383 14.3252V6.09863H1.76172V14.3252H12.2383ZM12.2383 2.33691C12.6367 2.33691 12.9883 2.48926 13.293 2.79395C13.5977 3.09863 13.75 3.4502 13.75 3.84863V14.3252C13.75 14.7236 13.5977 15.0752 13.293 15.3799C12.9883 15.6846 12.6367 15.8369 12.2383 15.8369H1.76172C1.33984 15.8369 0.976562 15.6846 0.671875 15.3799C0.390625 15.0752 0.25 14.7236 0.25 14.3252V3.84863C0.25 3.4502 0.390625 3.09863 0.671875 2.79395C0.976562 2.48926 1.33984 2.33691 1.76172 2.33691H2.5V0.825195H4.01172V2.33691H9.98828V0.825195H11.5V2.33691H12.2383ZM10.7617 7.5752V9.08691H3.23828V7.5752H10.7617Z"
                        fill="#FAFBFF"
                      />
                    </svg>
                    <span>Nouvelle cure</span>
                  </div>
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'UPDATE_PATIENT') ? (
                  <Tooltip title="Modifier un patient">
                    <div
                      style={{
                        cursor: 'pointer',
                      }}
                      className="more_icon"
                    >
                      <div
                        onClick={() => this.props.showFormPatient()}
                        className="op_item_bilan op_view"
                        data-toggle="modal"
                        data-target="#view_details"
                      >
                        <i className="fas fa-edit" aria-hidden="true"></i>
                      </div>
                    </div>
                  </Tooltip>
                ) : (
                  ''
                )}
                {isHasPermission(user.login_access.permissions, 'UPDATE_PATIENT') ? (
                  <Tooltip title="Ajouter une recette">
                    <div
                      style={{
                        cursor: 'pointer',
                      }}
                      className="more_icon"
                    >
                      <div
                        onClick={() => this.setState({ showDrawerRecette: true })}
                        className="op_item_bilan op_view"
                        data-toggle="modal"
                        data-target="#view_details"
                      >
                        <i className="fas fa-plus" aria-hidden="true"></i>
                      </div>
                    </div>
                  </Tooltip>
                ) : (
                  ''
                )}
                {allSessionsPatient && allSessionsPatient.length > 0 ? (
                  <Tooltip title="Journal des séances">
                    <div
                      style={{
                        cursor: 'pointer',
                      }}
                      className="more_icon"
                    >
                      <div
                        onClick={() => this.setState({ showSession: true })}
                        className="op_item_bilan op_view"
                        data-toggle="modal"
                        data-target="#view_details"
                      >
                        <i className="fas fa-list"></i>
                      </div>
                    </div>
                  </Tooltip>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div className="patient_detailed_info_section_body">
            <div
              className="patient_detailed_info_section_body_item patient_info_note"
              style={{ height: 'auto' }}
            >
              <div className="note" style={{ width: '84%' }}>
                <div className="note_label_container">
                  <span id="note_label">
                    Note ({this.state.note ? this.state.note.length : 0}/200)
                  </span>
                </div>
                {showNotePatientRecord ? (
                  <textarea
                    maxLength={200}
                    style={{
                      resize: 'none',
                      border: '0px',
                      borderBottom: '1px #ECEEF5 solid',
                      width: '90%',
                    }}
                    onChange={this.handleChangeNotePatientRecord}
                    name="note"
                    value={this.state.note}
                  ></textarea>
                ) : patientRecord.note ? (
                  <textarea
                    disabled={true}
                    value={patientRecord.note}
                    rows="4"
                    style={{
                      resize: 'none',
                      color: '#1a1c1d',
                      fontWeight: '500',
                      width: '90%',
                    }}
                  ></textarea>
                ) : (
                  <strong style={{ color: '#8080803d' }}>Note non renseignée</strong>
                )}
              </div>
              {showNotePatientRecord ? (
                <div
                  style={{
                    width: '100px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (this.state.note)
                      this.props.submitPatientRecordForm(
                        {
                          id: patientRecord.id,
                          note: this.state.note,
                        },
                        patientRecord.patient.id,
                      );
                  }}
                  className="patient_np_btn"
                >
                  <span>Valider</span>
                </div>
              ) : (
                <div className="patient_edit_button">
                  <button
                    onClick={this.handleShowNotePatientRecord}
                    style={{
                      width: '78px',
                      border: 'none',
                      color: '#8083A3',
                      borderRadius: '10px',
                      height: '30px',
                      fontFamily: 'Lato',
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      fontSize: '14px',
                      lineHeight: '21px',
                    }}
                  >
                    Modifier
                  </button>
                </div>
              )}
            </div>
            <br />

            <div className="patient_detailed_info_section_body_item patient_info_email">
              <div className="patient_detail_props">
                <div className="email_label_container" id="patient_email">
                  <span id="patient_detail_props_label">Email</span>
                </div>

                <span id="patient_detail_props_text">
                  {patientRecord.patient.email ? (
                    patientRecord.patient.email
                  ) : (
                    <strong style={{ color: '#8080803d' }}>Email non renseignée</strong>
                  )}
                </span>
              </div>
            </div>
            <div className="patient_detailed_info_section_body_item patient_info_email">
              <div className="patient_detail_props">
                <div className="email_label_container" id="patient_email">
                  <span id="patient_detail_props_label">Regime</span>
                </div>
                <span id="patient_detail_props_text">
                  {patientRecord.patient.regime === 1
                    ? 'CNAM'
                    : patientRecord.patient.regime === 2
                    ? 'ACT'
                    : 'Autre'}
                </span>
              </div>
            </div>
            <div className="patient_detailed_info_section_body_item patient_info_birthday">
              <div className="patient_detail_props">
                <div className="patient_detail_props">
                  <div className="birthday_label_container" id="patient_birthday">
                    <span id="patient_detail_props_label">Naissance</span>
                  </div>

                  <span id="patient_detail_props_text">
                    {' '}
                    {patientRecord.patient.birthday &&
                    patientRecord.patient.birthday !== 'Invalid date' ? (
                      `${moment(patientRecord.patient.birthday).format(
                        'DD/MM/YYYY',
                      )} (${parseFloat(duration.asYears()).toFixed(0)} ans)`
                    ) : (
                      <strong style={{ color: '#8080803d' }}>
                        Naissance non renseignée
                      </strong>
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className="patient_detailed_info_section_body_item patient_info_cnam">
              <div className="patient_detail_props">
                <div className="patient_detail_props">
                  <div className="cnam_label_container" id="patient_cnam">
                    <span id="patient_detail_props_label">Cnam</span>
                  </div>

                  <span id="patient_detail_props_text">
                    {patientRecord.patient.cnam_key && patientRecord.patient.cnam_root ? (
                      `${patientRecord.patient.cnam_root}/${patientRecord.patient.cnam_key}`
                    ) : (
                      <strong style={{ color: '#8080803d' }}>CNAM non renseignée</strong>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="patient_detailed_info_section_body_item patient_info_cnam">
              <div className="patient_detail_props">
                <div className="patient_detail_props">
                  <div className="cnam_label_container" id="patient_cnam">
                    <span id="patient_detail_props_label">Fonction</span>
                  </div>
                  <span id="patient_detail_props_text">
                    {patientRecord.patient.job ? (
                      patientRecord.patient.job
                    ) : (
                      <strong style={{ color: '#8080803d' }}>
                        Fonction non renseignée
                      </strong>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="patient_detailed_info_section_body_item patient_info_address"
              style={{ marginTop: '10px', marginBottom: '20px' }}
            >
              <div className="patient_detail_props">
                <div className="patient_detail_props">
                  <div className="address_label_container" id="patient_address">
                    <span id="patient_detail_props_label">Adresse</span>
                  </div>

                  <span id="patient_detail_props_text">
                    {patientRecord.patient.address_patient &&
                    patientRecord.patient.address_patient.street ? (
                      `${patientRecord.patient.address_patient.street},
                       ${patientRecord.patient.address_patient.zip_code || ''} 
                         ${patientRecord.patient.address_patient.city || ''}
                         ${patientRecord.patient.address_patient.country || ''}`
                    ) : (
                      <strong style={{ color: '#8080803d' }}>
                        Adresse non renseignée
                      </strong>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="patient_detailed_info_section_body_item patient_info_phone">
              <div className="patient_detail_props">
                <div className="patient_detail_props">
                  <div className="phone_label_container" id="patient_phone">
                    <span id="patient_detail_props_label">Téléphone</span>
                  </div>

                  <span id="patient_detail_props_text">
                    {patientRecord.patient.phone ? (
                      patientRecord.patient.phone
                    ) : (
                      <strong style={{ color: '#8080803d' }}>
                        Téléphone non renseignée
                      </strong>
                    )}
                  </span>
                </div>
              </div>
            </div>
            {isHasPermission(user.login_access.permissions, 'ACCOUNTING_STAT_ACCESS') ? (
              <div
                className="stats"
                style={{
                  marginLeft: '5%',
                  marginTop: '7%',
                  width: '90%',
                }}
              >
                <div className="stats_container_dashboard">
                  <div className="stats_items">
                    <div className="stats_item_text">
                      <span id="item_title">Total des séances réalisées</span>
                      <span id="item_desc">Depuis la création de la fiche patient</span>
                    </div>
                    <div className="item_stats actual_item_stats1">
                      <div className="stat_text">
                        {patientStatistical && patientStatistical.sumRdvCompleted
                          ? parseFloat(patientStatistical.sumRdvCompleted).toFixed(2)
                          : 0}
                      </div>
                      <div className="stat_bar">
                        <div id="full_length_bar">
                          <div id="progress" style={{ background: '#601cac' }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="stats_items">
                    <div className="stats_item_text">
                      <span id="item_title">Montant des séances réalisées</span>
                      <span id="item_desc">Depuis la création de la fiche patient</span>
                    </div>
                    <div className="item_stats actual_item_stats1">
                      <div className="stat_text">
                        {patientStatistical && patientStatistical.session_price
                          ? parseFloat(patientStatistical.session_price).toFixed(2)
                          : 0}
                      </div>
                      <div className="stat_bar">
                        <div id="full_length_bar">
                          <div id="progress"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="stats_items">
                    <div className="stats_item_text">
                      <span id="item_title">Montant total payé</span>
                      <span id="item_desc">Jusqu’à l’heure présente</span>
                    </div>
                    <div className="item_stats actual_item_stats3">
                      <div className="stat_text">
                        {(patientStatistical && patientStatistical.sumCreditPayed) ||
                        (patientStatistical && patientStatistical.sumRecette)
                          ? parseFloat(
                              parseFloat(patientStatistical.sumCreditPayed) +
                                parseFloat(patientStatistical.sumRecette),
                            ).toFixed(2)
                          : 0}
                      </div>
                      <div className="stat_bar">
                        <div id="full_length_bar">
                          <div id="progress"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="stats_items">
                    <div className="stats_item_text">
                      <span id="item_title">Montant total impayé</span>
                      <span id="item_desc">Jusqu’à l’heure présente</span>
                    </div>
                    <div className="item_stats actual_item_stats2">
                      <div className="stat_text">
                        {patientStatistical &&
                        patientStatistical.sumCreditInPayed &&
                        !patientRecord.patient.money_pot_amount
                          ? parseFloat(patientStatistical.sumCreditInPayed).toFixed(2)
                          : 0}
                      </div>
                      <div className="stat_bar">
                        <div id="full_length_bar">
                          <div id="progress"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="stats_items">
                    <div className="stats_item_text">
                      <span id="item_title">Montant en cagnotte</span>
                      <span id="item_desc">Avancé par le patient</span>
                    </div>
                    <div className="item_stats actual_item_stats2">
                      <div className="stat_text">
                        {patientRecord.patient.money_pot_amount || 0}
                      </div>
                      <div className="stat_bar">
                        <div id="full_length_bar">
                          <div id="progress" style={{ background: '#558dd2' }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div style={{ flex: '50%', paddingTop: '1%', paddingRight: '1%' }}>
          {isHasPermission(user.login_access.permissions, 'ACCESS_CURES_LIST') ? (
            patientConsultationList &&
            patientConsultationList.data &&
            patientConsultationList.data.length > 0 ? (
              <div
                className="patient_consultations_preview_container"
                style={{ width: 'auto', height: 'auto', marginBottom: '10px' }}
              >
                <div className="patient_consultations_preview_header">
                  <div className="left">
                    <span>Cures</span>
                  </div>
                  <div onClick={this.props.showConsultations} className="right">
                    <svg
                      width="14"
                      height="16"
                      viewBox="0 0 14 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.238 13.7383V5.51172H1.76147V13.7383H12.238ZM9.98804 0.238281H11.4998V1.75H12.238C12.6365 1.75 12.988 1.90234 13.2927 2.20703C13.5974 2.51172 13.7498 2.86328 13.7498 3.26172V13.7383C13.7498 14.1367 13.5974 14.4883 13.2927 14.793C12.988 15.0977 12.6365 15.25 12.238 15.25H1.76147C1.3396 15.25 0.976318 15.0977 0.671631 14.793C0.390381 14.4883 0.249756 14.1367 0.249756 13.7383V3.26172C0.249756 2.86328 0.390381 2.51172 0.671631 2.20703C0.976318 1.90234 1.3396 1.75 1.76147 1.75H2.49976V0.238281H4.01147V1.75H9.98804V0.238281ZM10.7615 8.5V12.2617H6.99976V8.5H10.7615Z"
                        fill="#5446DB"
                      />
                    </svg>

                    <span>Voir tous</span>
                  </div>
                </div>
                <div className="consultation_preview_body">
                  <div className="consultation_preview_container">
                    {data &&
                      data.length > 0 &&
                      data.map((item) => {
                        return <Consulatation key={item.id} item={item} />;
                      })}
                  </div>
                </div>
              </div>
            ) : (
              <Empty_container
                permission="ADD_NEW_CURE"
                user={user}
                title="Pas de cures !"
                body="Vous n'avez créé à présent aucune cure pour ce patient."
                btn_name="Nouvelle cure"
                onClick={this.openModalConsultation}
              />
            )
          ) : (
            ''
          )}{' '}
          {patientConsultationList &&
          patientConsultationList.data &&
          patientConsultationList.data.length > 0 ? (
            <>
              {isLoadingBilanPatientRecord ? (
                <CircularProgress
                  disableShrink
                  style={{ marginLeft: '50%', marginTop: '32%' }}
                />
              ) : bilanPatientRecord &&
                bilanPatientRecord.data &&
                bilanPatientRecord.data.length > 0 ? (
                <div
                  className="patient_bilans_preview_container"
                  style={{ marginBottom: '10px', width: '100%', height: 'auto' }}
                >
                  <div className="patient_bilan_preview_header">
                    <div className="left">
                      <span>Bilans</span>
                    </div>
                    <div onClick={this.props.showBilans} className="right">
                      <svg
                        width="14"
                        height="16"
                        viewBox="0 0 14 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.238 13.7383V5.51172H1.76147V13.7383H12.238ZM9.98804 0.238281H11.4998V1.75H12.238C12.6365 1.75 12.988 1.90234 13.2927 2.20703C13.5974 2.51172 13.7498 2.86328 13.7498 3.26172V13.7383C13.7498 14.1367 13.5974 14.4883 13.2927 14.793C12.988 15.0977 12.6365 15.25 12.238 15.25H1.76147C1.3396 15.25 0.976318 15.0977 0.671631 14.793C0.390381 14.4883 0.249756 14.1367 0.249756 13.7383V3.26172C0.249756 2.86328 0.390381 2.51172 0.671631 2.20703C0.976318 1.90234 1.3396 1.75 1.76147 1.75H2.49976V0.238281H4.01147V1.75H9.98804V0.238281ZM10.7615 8.5V12.2617H6.99976V8.5H10.7615Z"
                          fill="#5446DB"
                        />
                      </svg>

                      <span>Voir tous</span>
                    </div>
                  </div>
                  <div className="patient_bilan_preview_body">
                    <div className="patient_bilan_preview_container">
                      {bilanPatientRecord.data.map((bilanPatient) => {
                        if (bilanPatient.consultation)
                          return (
                            <Link
                              key={bilanPatient.id}
                              to={`/bilan/${bilanPatient.bilan.label}/${bilanPatient.consultation.id}/${bilanPatient.id}`}
                            >
                              <div className="add_bilan_content_preview">
                                <div className="add_bilan_content_preview_item">
                                  <div
                                    className="bilan_preview_sub_item"
                                    id="patient_consulation_timing"
                                  >
                                    <svg
                                      width="38"
                                      height="38"
                                      viewBox="0 0 38 38"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        x="0.5"
                                        y="0.5"
                                        width="37"
                                        height="37"
                                        rx="9.5"
                                        fill="white"
                                        stroke="#ECEEF5"
                                      />
                                      <path
                                        d="M25.0117 19V12.9883H21.25V19L23.1133 17.875L25.0117 19ZM25.0117 11.5117C25.4102 11.5117 25.75 11.6641 26.0312 11.9688C26.3359 12.25 26.4883 12.5898 26.4883 12.9883V21.9883C26.4883 22.3867 26.3359 22.7383 26.0312 23.043C25.75 23.3477 25.4102 23.5 25.0117 23.5H16.0117C15.6133 23.5 15.2617 23.3477 14.957 23.043C14.6523 22.7383 14.5 22.3867 14.5 21.9883V12.9883C14.5 12.5898 14.6523 12.25 14.957 11.9688C15.2617 11.6641 15.6133 11.5117 16.0117 11.5117H25.0117ZM12.9883 14.5V25.0117H23.5V26.4883H12.9883C12.5898 26.4883 12.2383 26.3477 11.9336 26.0664C11.6523 25.7617 11.5117 25.4102 11.5117 25.0117V14.5H12.9883Z"
                                        fill="#8083A3"
                                      />
                                    </svg>
                                    <div className="prise_en_charge_text">
                                      <span id="prise_en_charge_text_title">
                                        {bilanPatient.bilan.display_name}
                                      </span>
                                      <span id="prise_en_charge_text_desc">
                                        {bilanPatient.path.frequence_item_3} le{' '}
                                        {moment(bilanPatient.path.b_1_2).format(
                                          'DD/MM/YYYY',
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="recent_patient_icon">
                                    <i className="fas fa-angle-right"></i>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          );
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <Empty_container
                  user={user}
                  permission="ADD_NEW_BALANCE_SHEET"
                  hide={true}
                  title="Pas de bilans !"
                  body="Dès qu'une cure est créée, vous pourrez y ajouter des bilans."
                  btn_name="Ajouter bilan"
                  url="/consultations"
                />
              )}
              {protocolePatientRecord.length > 0 ? (
                <Protocol
                  showProtocole={this.props.showProtocole}
                  patient={true}
                  protocoleConsultation={protocolePatientRecord}
                  user={user}
                />
              ) : (
                <Empty_container
                  user={user}
                  permission="CREATE_NEW_PROTOCOLE"
                  hide={true}
                  title="Pas de Protocole !"
                  body="Dès qu'une cure est créée, vous pourrez y ajouter des protocoles."
                  btn_name="Ajouter Protocole"
                  url={`/consultations`}
                />
              )}
            </>
          ) : (
            ''
          )}
          <Bill
            regime={patientRecord.patient.regime}
            showProtocole={this.props.showProtocole}
            patient={true}
            pec={true}
            bills={billPatientRecord}
            patientItem={patientRecord.patient.id}
            user={user}
          />
          <Receipt
            showProtocole={this.props.showProtocole}
            patient={true}
            receipts={receiptPatientRecord}
            patientItem={patientRecord.patient.id}
            user={user}
          />
        </div>
        <SwipeableDrawer
          style={{ width: '50%' }}
          anchor="right"
          open={setIsOpenModal}
          onClose={this.toggleDrawer('right', false)}
          onOpen={this.toggleDrawer('right', true)}
        >
          <div className="right_side_bar">
            <div className="right_side_bar_header">
              <span id="add_app_sidebar_title">Nouvelle cure</span>
              <svg
                onClick={() => {
                  this.openModalConsultation();
                }}
                style={{ cursor: 'pointer' }}
                id="close-right-sidebar"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="9.5"
                  fill="white"
                  stroke="#ECEEF5"
                />
                <path
                  d="M25.2383 15.8164L21.0547 20L25.2383 24.1836L24.1836 25.2383L20 21.0547L15.8164 25.2383L14.7617 24.1836L18.9453 20L14.7617 15.8164L15.8164 14.7617L20 18.9453L24.1836 14.7617L25.2383 15.8164Z"
                  fill="#8083A3"
                />
              </svg>
            </div>
            <div className="right_side_bar_body">
              <div className="modal-body">
                <form style={{ padding: '2px 0px' }}>
                  <div className="form-row">
                    <div
                      style={{ width: '100%' }}
                      className={
                        hasError && !initialValues.motif_id
                          ? 'right_sidebar_body_item type3'
                          : 'right_sidebar_body_item'
                      }
                    >
                      <label htmlFor="">Diagnostique</label>
                      <div className="input_and_icon">
                        <select
                          value={initialValues.motif_id}
                          name="motif_id"
                          onChange={this.handleChangeInput}
                          className="form-control"
                          id="exampleFormControlSelect1"
                          //size={(motifList && motifList.length) || 1}
                        >
                          <option value="" selected>
                            Diagnostique
                          </option>
                          {motifList &&
                            motifList.length > 0 &&
                            motifList.map((motif) => {
                              if (motif.actif)
                                return (
                                  <option
                                    key={motif.id}
                                    data-label={motif.label}
                                    data-price_cnam={motif.complement}
                                    data-price_with_out_cnam={motif.price_with_out_cnam}
                                    value={parseInt(motif.id)}
                                  >
                                    {motif.label}
                                  </option>
                                );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                  {initialValues.labelMotif === 'Autre' ? (
                    <div className="form-row">
                      <div
                        style={{ width: '100%' }}
                        className={
                          hasError && !initialValues.display_name
                            ? 'form-group type3'
                            : 'form-group'
                        }
                      >
                        <label></label>
                        <div className="input_and_icon">
                          <input
                            maxLength={100}
                            value={initialValues.diagnostic}
                            name="diagnostic"
                            onChange={this.handleChangeInput}
                            type="text"
                            placeholder="Nouveau diagnostique"
                          />
                          <i className="fas fa-pen"></i>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="form-row">
                    <div
                      style={{ width: '100%' }}
                      className={
                        hasError && !initialValues.date
                          ? 'form-group type3'
                          : 'form-group'
                      }
                    >
                      <label>Date </label>
                      <div className="input_and_icon">
                        <input
                          style={{ width: '100%' }}
                          value={initialValues.date}
                          name="date"
                          onChange={this.handleChangeInput}
                          type="date"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div style={{ width: '100%' }} className="form-group">
                      <label>Note</label>
                      <div className="input_and_icon">
                        <textarea
                          maxLength={200}
                          style={{
                            border: '0px',
                            borderBottom: '1px #ECEEF5 solid',
                            width: '100%',
                          }}
                          value={initialValues.note}
                          name="note"
                          onChange={this.handleChangeInput}
                          placeholder="Vos notes ici."
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div style={{ width: '90%' }} className="form-group">
                      <label>Médecin prescripteur </label>
                      <Autocomplete
                        id="size-small-standard"
                        size="small"
                        options={newDataEmployeeManagementDraft(medecins)}
                        onChange={(event, option) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              doctor: option != null ? option.title : '',
                            },
                          });
                        }}
                        defaultValue={{
                          title: initialValues.doctor,
                        }}
                        getOptionLabel={(option) => option.title}
                        renderInput={(cities) => (
                          <TextField
                            style={{ width: '100%' }}
                            placeholder="Choisissez un médecin prescripteur"
                            {...cities}
                            variant="standard"
                            //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                            //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                            // error={hasError && !initialValues.address_practitioner.city}
                          />
                        )}
                      />
                    </div>
                    <div
                      className="more_icon"
                      title="Ajouter un médecin prescripteur"
                      style={{ textAlign: 'center', width: '3%', cursor: 'pointer' }}
                      onClick={() =>
                        this.setState({
                          initialValuesMedecin: {
                            ...initialValuesMedecin,
                            newMedecin: true,
                          },
                        })
                      }
                    >
                      <div
                        className="op_item_bilan op_view"
                        data-toggle="modal"
                        data-target="#view_details"
                      >
                        <i className="fas fa-plus" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                  <br />
                  {initialValuesMedecin.newMedecin ? (
                    <div className="" style={{ width: '100%' }}>
                      <form className="general_info_form">
                        <div className="title_and_button">
                          <span style={{ textAlign: 'inherit' }}>
                            Informations personnelles
                          </span>
                          <svg
                            onClick={() =>
                              this.setState({
                                initialValuesMedecin: {
                                  ...initialValuesMedecin,
                                  newMedecin: false,
                                },
                              })
                            }
                            id="close-right-sidebar"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ cursor: 'pointer' }}
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="39"
                              height="39"
                              rx="9.5"
                              fill="white"
                              stroke="#ECEEF5"
                            ></rect>
                            <path
                              d="M25.2383 15.8164L21.0547 20L25.2383 24.1836L24.1836 25.2383L20 21.0547L15.8164 25.2383L14.7617 24.1836L18.9453 20L14.7617 15.8164L15.8164 14.7617L20 18.9453L24.1836 14.7617L25.2383 15.8164Z"
                              fill="#8083A3"
                            ></path>
                          </svg>
                        </div>
                        <div className="general_info_actual_form">
                          <InputField
                            width={sandwich_menu_btn ? '26vw' : '30vw'}
                            className="general_info_form_item"
                            type="text"
                            placeholder="Prénom"
                            label="Prénom"
                            hasError={initialValuesMedecin.hasError}
                            validInput="string"
                            value={initialValuesMedecin.last_name}
                            name="last_name"
                            icon={<i className="fas fa-user"></i>}
                            onChange={(e) => {
                              this.setState({
                                initialValuesMedecin: {
                                  ...initialValuesMedecin,
                                  last_name: e.target.value,
                                  //  name_cabinet:  e.target.value,
                                },
                              });
                            }}
                          />
                          <InputField
                            width={sandwich_menu_btn ? '26vw' : '30vw'}
                            type="text"
                            placeholder="Nom"
                            className="general_info_form_item"
                            validInput="string"
                            label="Nom"
                            hasError={initialValuesMedecin.hasError}
                            value={initialValuesMedecin.first_name}
                            name="first_name"
                            icon={<i className="fas fa-user"></i>}
                            onChange={(e) => {
                              this.setState({
                                initialValuesMedecin: {
                                  ...initialValuesMedecin,
                                  first_name: e.target.value,
                                  // name_cabinet: e.target.value,
                                },
                              });
                            }}
                          />
                          <InputField
                            width={sandwich_menu_btn ? '26vw' : '30vw'}
                            type="text"
                            placeholder="Specialité"
                            className="general_info_form_item"
                            validInput="string"
                            label="Specialité"
                            maxLength={20}
                            hasError={initialValuesMedecin.hasError}
                            value={initialValuesMedecin.speciality}
                            name="speciality"
                            icon={<i className="fas fa-user"></i>}
                            onChange={(e, option) => {
                              this.setState({
                                initialValuesMedecin: {
                                  ...initialValuesMedecin,
                                  speciality: e.target.value,
                                },
                              });
                            }}
                          />
                          <InputField
                            width={sandwich_menu_btn ? '26vw' : '30vw'}
                            className="general_info_form_item"
                            type="text"
                            placeholder="Tél/Fax"
                            label="Tél/Fax"
                            minLength={8}
                            maxLength={20}
                            // hasError={z}
                            validInput="tel"
                            value={initialValuesMedecin.phone}
                            name="phone"
                            icon={<i className="fas fa-phone-alt"></i>}
                            required={this.validator.message(
                              'phone',
                              initialValuesMedecin.phone,
                              'integer',
                            )}
                            onChange={(e) => {
                              this.setState({
                                initialValuesMedecin: {
                                  ...initialValuesMedecin,
                                  phone: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <br />
                        <br />
                      </form>
                      {/*<div className="footer_buttons" style={{ paddingBottom: '4%' }}>
                      <button onClick={() => this.props.goBack()} className="footer_btn" id="cancel">
                        Annuler
                      </button>
                      <button
                          style={{
                            marginRight: sandwich_menu_btn ? '6%' : '6%',
                          }}
                          id="update"
                          className="footer_btn"
                          //disabled={isLoadingUpdateUser}
                          onClick={() => {
                            this.submitForm(initialValues);
                          }}
                      >
                        {isLoadingUpdateUser ? (
                            <CircularProgress
                                style={{
                                  marginLeft: '4%',
                                  width: '25px',
                                  height: '25px',
                                  color: 'white',
                                  marginTop: '5%',
                                }}
                            />
                        ) : (
                            'Valider'
                        )}
                      </button>
                    </div>*/}
                    </div>
                  ) : (
                    ''
                  )}

                  <br />

                  <div style={{ display: 'flex' }}>
                    <span
                      style={{
                        textAlign: 'initial',
                        color: 'black',
                        fontWeight: 'bold',
                        marginRight: '2%',
                      }}
                    >
                      Prescription médicale
                    </span>
                  </div>
                  <br />
                  <div className="form-row">
                    <div
                      className={
                        hasError && !initialValues.regime
                          ? 'right_sidebar_body_item type3'
                          : 'right_sidebar_body_item'
                      }
                      style={{ width: '30%' }}
                    >
                      <label>Regime</label>
                      <div className="input_and_icon">
                        <select
                          disabled={patientRecord.patient.regime === 3}
                          style={{ width: '100%' }}
                          value={initialValues.regime || 'autre'}
                          name="regime"
                          onChange={(e) => {
                            this.handleChangeInput(e);
                          }}
                        >
                          <option>Choissiez </option>
                          <option value="cnam">CNAM </option>
                          <option value="act"> Accident de travail </option>
                          <option value="autre">Autre</option>
                        </select>
                      </div>
                    </div>
                    <div style={{ width: '50%', display: 'flex' }}>
                      <InputField
                        width="30%"
                        className="add_patient_form_item"
                        type="text"
                        placeholder="10"
                        label="Nbr de séances"
                        hasError={hasError}
                        value={initialValues.sessions_count || ''}
                        name="sessions_count"
                        icon={<i className="fas fa-pen"></i>}
                        validInput="integerSessionC"
                        onChange={this.handleChangeInput}
                      />
                      <InputField
                        maxLength={1}
                        width="30%"
                        className="add_patient_form_item"
                        type="text"
                        label="S/semaine"
                        placeholder="S/semaine"
                        hasError={hasError}
                        value={initialValues.sessions_per_week || ''}
                        name="sessions_per_week"
                        icon={<i className="fas fa-pen"></i>}
                        validInput="integerNbrS"
                        onChange={this.handleChangeInput}
                      />
                      {initialValues.regime === 'act' ? (
                        ''
                      ) : (
                        <InputField
                          width="40%"
                          className="add_patient_form_item"
                          type="text"
                          placeholder="12.00"
                          label="Cout unitaire"
                          hasError={hasError}
                          value={initialValues.unit_price}
                          name="unit_price"
                          icon={<i className="fas fa-pen"></i>}
                          validInput="integer"
                          onChange={this.handleChangeInput}
                        />
                      )}
                    </div>
                  </div>
                  <br />
                  {/*<div className="form-row">
                    <div style={{ width: '50%', display: 'flex' }}>
                      <InputField
                        width="100%"
                        className="add_patient_form_item"
                        type="date"
                        placeholder="Date de début"
                        label="Date de début"
                        errorDate={errorDate}
                        hasError={hasError}
                        value={initialValues.start_date}
                        name="start_date"
                        icon=""
                        onChange={this.handleChangeInput}
                      />
                    </div>
                    <div style={{ width: '46%', display: 'flex' }}>
                      <InputField
                        width="30%"
                        className="add_patient_form_item"
                        type="time"
                        placeholder="10"
                        label="Heur de début"
                        //hasError={hasError}
                        value={initialValues.hourStart || ''}
                        name="hourStart"
                        //icon={<i className="fas fa-pen"></i>}
                        //validInput="integerSessionC"

                        onChange={this.handleChangeInput}
                      />
                      <InputField
                        maxLength={3}
                        width="30%"
                        className="add_patient_form_item"
                        type="text"
                        label="durée (en minutes)"
                        placeholder="durée (en minutes)"
                        //hasError={hasError}
                        value={initialValues.duration || ''}
                        name="duration"
                        icon={<i className="fas fa-pen"></i>}
                        validInput="integerDuration"
                        onChange={this.handleChangeInput}
                      />
                    </div>
                  </div>
                  <br />*/}
                </form>
              </div>
              <div
                className="modal-footer create_prise_en_charge_1_footer"
                style={{
                  borderBottom: '1px solid #dee2e6',
                  padding: '1.5rem 0rem',
                  margin: '0px 20px',
                }}
              >
                <button
                  onClick={() => this.openModalConsultation()}
                  type="button"
                  className="modal_close_btn"
                  id="retour"
                  style={{ backgroundColor: '#f6f6f6' }}
                >
                  Annuler
                </button>
                <button
                  onClick={() =>
                    this.submitFormConsultation(initialValues, initialValuesMedecin)
                  }
                  id="valider"
                  style={{ marginLeft: '0' }}
                  type="button"
                  className="btn_valid"
                >
                  {loadingAddSessions ? (
                    <CircularProgress
                      style={{
                        marginLeft: '4%',
                        width: '35px',
                        height: '25px',
                        color: 'white',
                        animation: 'infinite',
                      }}
                    />
                  ) : (
                    'Valider'
                  )}
                </button>
              </div>
            </div>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Patient);
