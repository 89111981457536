import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SelectorInputAndIcon } from '../../../utils';
import actions from '../store/actions';
import actionsJs from '../../../js/actions';
import moment from 'moment';
import SimpleReactValidator from 'simple-react-validator';
import update from 'immutability-helper';
import Form from '../Composent/form';
import './index.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import ToastAction from '../../../components/ToastAction';

const mapStateToProps = (state, ownProps) => {
  const { getUser } = ownProps;
  let doctor_id;
  let diplomas;
  let trainings;
  if (getUser) {
    doctor_id = getUser && getUser.id && getUser.id;
    diplomas =
      getUser && getUser.diplomas && getUser.diplomas.length > 0 ? getUser.diplomas : [];
    trainings =
      getUser && getUser.trainings && getUser.trainings.length > 0
        ? getUser.trainings
        : [];
  }
  return {
    isLoadingActionDiploma: state.ProfileReducer.isLoadingActionDiploma,
    sandwich_menu_btn: state.AppReducer.sandwich_menu_btn,
    initialValues: {
      //...getUser,
      doctor_id: doctor_id,
      diplomas: diplomas,
      trainings: trainings,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  submitDiplomaForm: async (values, id) => {
    await dispatch(actions.submitDiplomaForm(values));
    dispatch(actionsJs.getUserById(id));
  },
  submitTrainingsForm: async (values, id) => {
    await dispatch(actions.submitTrainingsForm(values));
    dispatch(actionsJs.getUserById(id));
  },
  deleteDiploma: async (diplomeId, id) => {
    await dispatch(actions.deleteDiploma(diplomeId));
    dispatch(actionsJs.getUserById(id));
  },
  deleteTrainings: async (formationId, id) => {
    await dispatch(actions.deleteTrainings(formationId));
    dispatch(actionsJs.getUserById(id));
  },
});

class DipolmeFormation extends Component {
  constructor(props) {
    super(props);
    document.title = 'Diplome & formation';
    this.state = {
      initialValues: {
        ...props.initialValues,
      },
      hasError: false,
    };
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        // OR
        default: 'Cette information est requise.', // will override all messages
      },
    });
    this.handleAddNewItem = this.handleAddNewItem.bind(this);
    this.handleChangeInputItem = this.handleChangeInputItem.bind(this);
    this.handleDeleteInputItem = this.handleDeleteInputItem.bind(this);
    this.handleInitialValues = this.handleInitialValues.bind(this);
  }

  componentDidMount() {
    SelectorInputAndIcon();
  }

  handleChangeInputItem(event, id, element) {
    const { initialValues } = this.state;
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState((prevState) => {
      const data = [...prevState.initialValues[element]];

      var commentIndex = data.findIndex(function (c) {
        return c.id === id;
      });
      const updatedComment = update(data[commentIndex], { [name]: { $set: value } });
      data[commentIndex] = updatedComment;
      this.setState({
        initialValues: {
          ...initialValues,
          [element]: data,
        },
      });
    });
  }

  handleDeleteInputItem(id, element) {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      const data = [...prevState.initialValues[element]];
      /*  var commentIndex = data.findIndex(function (c) {
        return c.id === id;
      });*/
      const commentIndex = data.filter((item) => item.id !== id);
      //delete data[commentIndex];
      this.setState({
        initialValues: {
          ...initialValues,
          [element]: commentIndex,
        },
      });
    });
  }

  handleAddNewItem(action) {
    const { initialValues } = this.state;
    if (initialValues[action] && initialValues[action].length === 0) {
      this.setState((prevState) => {
        const items = [...prevState.initialValues[action]];
        items.push({
          id: 1,
          title: '',
          date: moment(),
          [action === 'diplomas' ? 'university' : 'place']: '',
        });
        this.setState({
          initialValues: {
            ...initialValues,
            [action]: items,
          },
        });
      });
    }
    if (
      initialValues[action] &&
      initialValues[action].length > 0 &&
      initialValues[action][initialValues[action].length - 1].title !== ''
    ) {
      this.setState((prevState) => {
        const items = [...prevState.initialValues[action]];
        items.push({
          id: initialValues[action][initialValues[action].length - 1].id + 1,
          title: '',
          date: moment(),
          [action === 'diplomas' ? 'university' : 'place']: '',
        });
        this.setState({
          initialValues: {
            ...initialValues,
            [action]: items,
          },
        });
      });
    }
  }

  handleInitialValues() {
    const { initialValues } = this.state;
    if (initialValues.diplomas.length === 0 && initialValues.trainings.length === 0)
      this.setState({
        hasError: false,
        initialValues: {
          ...initialValues,
          diplomas: [
            ...initialValues.diplomas,
            {
              id: 1,
              title: '',
              date: moment(),
              university: '',
            },
          ],
          trainings: [
            ...initialValues.trainings,
            {
              id: 1,
              title: '',
              date: moment(),
              place: '',
            },
          ],
        },
      });
  }

  submitForm(diplomas, trainings, id) {
    if (
      (diplomas.length > 0 && diplomas[diplomas.length - 1].title === '') ||
      (trainings.length > 0 && trainings[trainings.length - 1].title === '')
    ) {
      this.setState({
        hasError: true,
      });
    } else if (diplomas.length === 0 && trainings.length === 0) {
      toast(
        ({ closeToast }) => (
          <ToastAction type="error" message="Diplôme ou Formation obligatoire" />
        ),
        { hideProgressBar: true },
      );
    } else if (this.validator.allValid()) {
      this.props.submitTrainingsForm(trainings, id);
      this.props.submitDiplomaForm(diplomas, id);
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  render() {
    const { sandwich_menu_btn, getUser, isLoadingActionDiploma } = this.props;
    const { initialValues, hasError } = this.state;
    let countDiplomas = 0;
    let countTrainings = 0;
    return (
      <div className="diploma_info" style={{ display: 'block' }}>
        <form className="payment_info_form">
          <div className="title_and_button">
            <span>Diplômes</span>
          </div>
          <div className="diploma_info_actual_form">
            {initialValues &&
              initialValues.diplomas &&
              initialValues.diplomas.length > 0 &&
              initialValues.diplomas.map((item) => {
                countDiplomas++;
                return (
                  <Form
                    userId={getUser.id}
                    key={item.id}
                    element="diplomas"
                    handleChangeInputItem={this.handleChangeInputItem}
                    deleteDiploma={this.props.deleteDiploma}
                    handleDeleteInputItem={this.handleDeleteInputItem}
                    hasError={hasError}
                    item={item}
                    countItem={countDiplomas}
                    sandwich_menu_btn={sandwich_menu_btn}
                  />
                );
              })}
          </div>
          <div className="add add_diploma">
            <div
              onClick={() => {
                this.handleAddNewItem('diplomas');
              }}
            >
              <i style={{ color: '#5446DB' }} className="fas fa-plus-circle"></i>
              <span>Ajouter un diplôme </span>
            </div>
          </div>
          <div className="formation_form">
            <div className="title_and_button">
              <span>Formations</span>
            </div>

            <div className="formation_info_actual_form">
              {initialValues &&
                initialValues.trainings &&
                initialValues.trainings.length > 0 &&
                initialValues.trainings.map((item) => {
                  countTrainings++;
                  return (
                    <Form
                      key={item.id}
                      userId={getUser.id}
                      element="trainings"
                      handleChangeInputItem={this.handleChangeInputItem}
                      handleDeleteInputItem={this.handleDeleteInputItem}
                      deleteTrainings={this.props.deleteTrainings}
                      item={item}
                      hasError={hasError}
                      countItem={countTrainings}
                      sandwich_menu_btn={sandwich_menu_btn}
                    />
                  );
                })}
            </div>
          </div>
          <div className="add add_formation">
            <div
              onClick={() => {
                this.handleAddNewItem('trainings');
              }}
            >
              <i style={{ color: '#5446DB' }} className="fas fa-plus-circle"></i>
              <span>Ajouter une formation</span>
            </div>
          </div>
        </form>
        <div className="footer_buttons">
          <button
            onClick={() => {
              this.handleInitialValues();
            }}
            className="footer_btn"
            id="cancel"
          >
            Annuler
          </button>
          <button
            style={{
              marginRight: sandwich_menu_btn ? '4%' : '4%',
            }}
            className="footer_btn"
            id="update"
            onClick={() => {
              this.submitForm(
                initialValues.diplomas,
                initialValues.trainings,
                initialValues.doctor_id,
              );
            }}
          >
            {isLoadingActionDiploma ? (
              <CircularProgress
                style={{
                  marginLeft: '4%',
                  width: '25px',
                  height: '25px',
                  color: 'white',
                  marginTop: '5%',
                }}
              />
            ) : (
              'Valider'
            )}
          </button>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DipolmeFormation);
