import React, { useState, useEffect, Fragment } from 'react';
//import React, { Component } from 'react';
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from '@react-pdf/renderer';
import { NumberToLetter } from 'convertir-nombre-lettre';

import { codeBureauCNAM } from '../../../utils/constants';
import moment from 'moment';
import Icon from '../../../assets/img/arrow1.png';
// Create styles
const newstyles = StyleSheet.create({
  page: {
    display: 'inline-grid',
    flexDirection: 'row',
    marginTop: 10,
  },
  PDFViewer: {
    width: '100%',
    height: '100%',
  },
  section: {
    padding: 10,
  },
  sectionNameDoctor: {
    marginLeft: 10,
    marginRight: 90,
    padding: 10,
  },
  section2: {
    marginLeft: 10,
    padding: 10,
  },
  hr: {
    BorderTop: '1px bold red',
  },
  cabinet: {
    display: 'flex',
    marginTop: 30,
    flexDirection: 'row',
    marginLeft: 10,
    paddingRight: 30,
  },
  sectionBill: {
    margin: 10,
    padding: 10,
    flexDirection: 'row',
  },
  detailBill: {
    margin: 10,
    padding: 10,
    flexDirection: 'row',
  },
  detailBillText: {
    color: '#070707',
    fontWeight: '1000',
    fontSize: 12,
    fontStyle: 'gras',
    //fontFamily: 'Georgia, serif',
    margin: 3,
  },
  detailBillText2: {
    color: '#070707',
    fontWeight: 'bolder',
    paddingTop: 10,
    paddingBottom: 10,
  },
  text: {
    color: '#5446db',
    fontWeight: 'bolder',
  },
  bill: {
    color: '#5446db',
    fontWeight: '500',
    marginLeft: '1%',
  },
  text1: {
    color: '#49494c',
    fontSize: 12,
    fontWeight: 'bolder',
    display: 'block',
    marginTop: 3,
    marginBottom: 3,
  },
  images: {
    marginTop: 150,
    marginLeft: 20,
    // padding: 10,
    width: 50,
    height: 20,
  },
  content: {
    color: '#070707',
    fontWeight: '1000',
    fontSize: 12,
    fontStyle: 'gras',
    margin: 3,
    paddingRight: 40,
  },
});
function BilanEpaulePdf(props) {
  document.title = `Bilan initial pour Mr Houcem SANAI`;
  const values = props.location.state.values;
  const user = props.location.state.user;
  return (
    <>
      <div className="bilan_details_header">
        <div
          onClick={() =>
            props.history.push({
              pathname: `/consultations`,
              //state: { facture_id: bill.id },
            })
          }
          className="back_icon"
        >
          <img src={Icon} />
        </div>
        <span>Retour</span>
      </div>
      <Fragment>
        <PDFViewer style={newstyles.PDFViewer}>
          <Document>
            {/*orientation="landscape"*/}
            <Page size="A4">
              <View style={newstyles.cabinet}>
                <View style={newstyles.sectionNameDoctor}>
                  <Text style={newstyles.detailBillText}>
                    Cabinet {`${user.first_name} ${user.last_name}`}
                  </Text>
                  <Text style={newstyles.detailBillText}>Kinésithérapeute</Text>
                </View>
                <View style={newstyles.section}>
                  <Text style={newstyles.detailBillText}>Code conventionnel</Text>
                  <Text style={newstyles.detailBillText}>Identification fiscale</Text>
                  <Text style={newstyles.detailBillText}>C.N.S.S</Text>
                  <Text style={newstyles.detailBillText}>R.I.B</Text>
                  <Text style={newstyles.detailBillText}>BANQUE</Text>
                </View>
                <View style={newstyles.section}>
                  <Text style={newstyles.text1}>{user.conventional_code || '-'}</Text>
                  <Text style={newstyles.text1}>{user.tax_identification || '-'}</Text>
                  <Text style={newstyles.text1}>{user.cnss || '-'}</Text>
                  <Text style={newstyles.text1}>{user.rib || '-'}</Text>
                  <Text style={newstyles.text1}>{user.bank || '-'}</Text>
                </View>
              </View>
              <View
                style={{
                  borderBottomColor: 'black',
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                }}
              />
              <View
                style={{
                  marginLeft: 50,
                  marginRight: 60,
                  marginTop: 5,
                }}
              >
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 10,
                    fontWeight: '500',
                    display: 'block',
                    margin: 3,
                    textAlign: 'center',
                  }}
                >
                  Une version numérique du bilan partageable avec vos confrères.
                </Text>
              </View>
              <View
                style={{
                  borderBottomColor: 'black',
                  marginTop: 5,
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                  borderWidth: 1,
                  borderRadius: 5,
                  height: 50,
                }}
              >
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    padding: 12,
                    fontStyle: 'gras',
                    textAlign: 'center',
                    //fontFamily: 'Georgia, serif',
                    margin: 3,
                  }}
                >
                  Bilan {values.frequence_item_3} d'épaule
                </Text>
              </View>
              <View
                style={{
                  marginLeft: 80,
                  marginTop: 20,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 12,
                    fontWeight: 'bolder',
                  }}
                >
                  Date diagnostic :
                </Text>
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    marginRight: 30,
                    marginLeft: 10,
                  }}
                >
                  {values.b_1_2
                    ? `${moment(values.b_1_2).format('DD')} ${moment(values.b_1_2)
                        .format('MMMM')
                        .substring(0, 3)} ${moment(values.b_1_2).format('YYYY')}`
                    : '-'}
                </Text>
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 12,
                    fontWeight: 'bolder',
                  }}
                >
                  Emplacement :
                </Text>
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    marginRight: 30,
                    marginLeft: 10,
                  }}
                >
                  {values.check_seance_5}
                </Text>
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 12,
                    fontWeight: 'bolder',
                  }}
                >
                  État :
                </Text>
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    marginRight: 30,
                    marginLeft: 10,
                  }}
                >
                  {values.frequence_item_3}
                </Text>
              </View>
              {/* SECTION NUM 1 */}
              <View
                style={{
                  borderBottomColor: 'black',
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                  borderWidth: 1,
                  borderRadius: 5,
                  height: 350,
                  marginTop: 30,
                }}
              >
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    padding: 12,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    marginLeft: 20,
                    textDecoration: 'underline',
                  }}
                >
                  Administratif - Bilan &amp; Prescription
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 10,
                    marginRight: 60,
                  }}
                >
                  <View style={newstyles.section2}>
                    <Text style={newstyles.text1}>Bilan :</Text>
                    <Text style={newstyles.text1}>Prescription : </Text>
                    <Text style={newstyles.text1}>Détails : </Text>
                  </View>
                  <View style={newstyles.section}>
                    <Text style={newstyles.content}>
                      Epaule {values.frequence_item_3} le{' '}
                      {`${moment(values.b_1_2).format('DD')} ${moment(values.b_1_2)
                        .format('MMMM')
                        .substring(0, 3)} ${moment(values.b_1_2).format('YYYY')}`}
                    </Text>
                    <Text style={newstyles.content}>
                      Le{' '}
                      {values.b_1_date
                        ? `${moment(values.b_1_date).format('DD')} ${moment(
                            values.b_1_date,
                          )
                            .format('MMMM')
                            .substring(0, 3)} ${moment(values.b_1_date).format('YYYY')}`
                        : '-'}{' '}
                      par Dr {values.medecin_name || '-'}
                    </Text>
                    <Text style={newstyles.content}>
                      Le patient a été opéré le mois dernier au centre Al chife2 par
                      Docteur BEN ACHOUR. Le patient a été opéré le mois dernier au centre
                      Al chife2 par Docteur BEN ACHOUR
                    </Text>
                  </View>
                </View>
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    padding: 12,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    marginLeft: 20,
                    textDecoration: 'underline',
                  }}
                >
                  Administratif - Séance
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 10,
                    marginRight: 60,
                  }}
                >
                  <View style={newstyles.section2}>
                    <Text style={newstyles.text1}>Prescription quantitative :</Text>
                    <Text style={newstyles.text1}>Nombre total : </Text>
                    <Text style={newstyles.text1}>Rythme : </Text>
                    <Text style={newstyles.text1}>Rythme : </Text>
                    <Text style={newstyles.text1}>Lieu : </Text>
                    <Text style={newstyles.text1}>Diagnostic : </Text>
                    <Text style={newstyles.text1}>
                      Y a-t-il eu des séances antérieures ? :{' '}
                    </Text>
                  </View>
                  <View style={newstyles.section}>
                    <Text style={newstyles.content}>{values.check_seance_1 || '-'}</Text>
                    <Text style={newstyles.content}>{values.text_seance_1 || '-'}</Text>
                    <Text style={newstyles.content}>{values.text_seance_2 || '-'}</Text>
                    <Text style={newstyles.content}>{values.check_seance_3 || '-'}</Text>
                    <Text style={newstyles.content}>{values.check_seance_5 || '-'}</Text>
                    <Text style={newstyles.content}>{values.text_seance_3 || '-'}</Text>
                    <Text style={newstyles.content}>{values.check_seance_7 || '-'}</Text>
                  </View>
                </View>
                {/* FOOTER1 */}
              </View>
              <View
                style={{
                  borderBottomColor: 'black',
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                  borderWidth: 1,
                  borderRadius: 5,
                  height: 400,
                  marginTop: 30,
                }}
              >
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    padding: 12,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    marginLeft: 20,
                    textDecoration: 'underline',
                  }}
                >
                  Contexte et Anamnèse - Contexte patient
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 10,
                    marginRight: 60,
                  }}
                >
                  <View style={newstyles.section2}>
                    <Text style={newstyles.text1}>Mode de vie :</Text>
                    <Text style={newstyles.text1}>Lieu de vie : </Text>
                    <Text style={newstyles.text1}>Aide extérieure : </Text>
                    <Text style={newstyles.text1}>Attente du patient : </Text>
                    <Text style={newstyles.text1}>Travail : </Text>
                    <Text style={newstyles.text1}>Sport & loisirs : </Text>
                    <Text style={newstyles.text1}>Relationnel : </Text>
                  </View>
                  <View style={newstyles.section}>
                    <Text style={newstyles.content}>
                      {values.check_contexte_1 || '-'}
                    </Text>
                    <Text style={newstyles.content}>
                      {values.check_contexte_5 || '-'}
                    </Text>
                    <Text style={newstyles.content}>
                      {values.check_contexte_12 || '-'}
                    </Text>
                    <Text style={newstyles.content}>
                      {values.check_contexte_18 || '-'}
                    </Text>
                    <Text style={newstyles.content}>
                      {values.check_contexte_26 || '-'}
                    </Text>
                    <Text style={newstyles.content}>
                      {values.check_contexte_33 || '-'}
                    </Text>
                    <Text style={newstyles.content}>
                      {values.check_contexte_37 || '-'}
                    </Text>
                  </View>
                </View>
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    padding: 12,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    marginLeft: 20,
                    textDecoration: 'underline',
                  }}
                >
                  Contexte et Anamnèse - Anamnèse
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 10,
                    marginRight: 60,
                  }}
                >
                  <View style={newstyles.section2}>
                    <Text style={newstyles.text1}>Latéralité :</Text>
                    <Text style={newstyles.text1}>Poids : </Text>
                    <Text style={newstyles.text1}>Taille : </Text>
                    <Text style={newstyles.text1}>Histoire de la malade : </Text>
                  </View>
                  <View style={newstyles.section}>
                    <Text style={newstyles.content}>
                      {values.check_contexte_42 || '-'}
                    </Text>
                    <Text style={newstyles.content}>{values.poids || '-'}</Text>
                    <Text style={newstyles.content}>{values.taille || '-'}</Text>
                    <Text style={newstyles.content}>
                      {values.textarea_contexte_1 || '-'}
                    </Text>
                  </View>
                </View>
                {/* FOOTER1 */}
              </View>
              <View
                style={{
                  bottom: 0,
                }}
              >
                <Text
                  style={{
                    top: 10,
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 10,
                    fontStyle: 'gras',
                    //fontFamily: 'Georgia, serif',
                    margin: 3,
                    textAlign: 'center',
                  }}
                >
                  01 rue de la liberté - Tél/Fax: +216 75 730 134 - GSM: +216 98 475 578
                </Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </Fragment>
    </>
  );
}

export default BilanEpaulePdf;
