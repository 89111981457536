import React, { Component } from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    overflow: 'hidden',
    borderRadius: '15px',
  },
};
class Modals extends Component {
  componentWillMount() {
    Modal.setAppElement('body');
  }
  render() {
    const { setIsOpen, openModal, data } = this.props;
    return (
      <Modal
        isOpen={setIsOpen}
        onRequestClose={openModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {data}
      </Modal>
    );
  }
}

export default Modals;
