import React, { Component } from 'react';
import { SelectorBilanItemSection, SelectorColorInputCheckbox } from '../../../../utils';
import UploadFile from '../../upload_file';import { Slider, RangeSlider } from 'rsuite';

class Objectif extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEntretien: true,
      showDouleur: false,
      showBilanArticulaire: false,
      showScore: false,
    };
    this.handleChangeSubSectionBtn = this.handleChangeSubSectionBtn.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    SelectorColorInputCheckbox();
    SelectorBilanItemSection();
  }
  handleChangeSubSectionBtn(name) {
    this.setState({
      [name]: !this.state[name],
    });
  }
  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }
  render() {
    const { sandwich_menu_btn, initialValues } = this.props;
    return (
      <div className="actual_bilan_settings">
        <div className="actual_bilan_settings_item head_shaking_test" id="headShaking1">
          <div
            style={{ backgroundColor: '#6c89bc' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}> Objectif</span>
          </div>
          <br />
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Objectifs (structurels)
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Antalgique"
                              onChange={this.props.handleChangeInput}
                              name="check_objectif_1"
                              checked={initialValues.check_objectif_1}
                              value="Antalgique"
                            />
                            <label className="form-check-label" htmlFor={`Antalgique`}>
                              Antalgique
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_objectif_2"
                              checked={initialValues.check_objectif_2}
                              value="Anti-inflamatoire"
                              className="form-check-input"
                              type="checkbox"
                              id={`Anti-inflamatoire`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`Anti-inflamatoire`}
                            >
                              Anti-inflamatoire
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_objectif_3"
                              checked={initialValues.check_objectif_3}
                              value="Diminution de l'oedème"
                              className="form-check-input"
                              type="checkbox"
                              id={`Diminution`}
                            />
                            <label className="form-check-label" htmlFor={`Diminution`}>
                              Diminution de l'oedème
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_objectif_4"
                              checked={initialValues.check_objectif_4}
                              value="Gain amplitude articulaire"
                              className="form-check-input"
                              type="checkbox"
                              id={`Gain`}
                            />
                            <label className="form-check-label" htmlFor={`Gain`}>
                              Gain amplitude articulaire
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_objectif_5"
                              checked={initialValues.check_objectif_5}
                              value="Gain de mobilité"
                              className="form-check-input"
                              type="checkbox"
                              id={`mobilite`}
                            />
                            <label className="form-check-label" htmlFor={`mobilite`}>
                              Gain de mobilité
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_objectif_6"
                              checked={initialValues.check_objectif_6}
                              value="Gain de souplesse"
                              className="form-check-input"
                              type="checkbox"
                              id={`souplesse`}
                            />
                            <label className="form-check-label" htmlFor={`souplesse`}>
                              Gain de souplesse
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_objectif_7"
                              checked={initialValues.check_objectif_7}
                              value="Gain de force musculaire"
                              className="form-check-input"
                              type="checkbox"
                              id={`musculaire`}
                            />
                            <label className="form-check-label" htmlFor={`musculaire`}>
                              Gain de force musculaire
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_objectif_8"
                              checked={initialValues.check_objectif_8}
                              value="Agir contre les rétractations musculaire"
                              className="form-check-input"
                              type="checkbox"
                              id={`retractations`}
                            />
                            <label className="form-check-label" htmlFor={`retractations`}>
                              Agir contre les rétractations musculaire
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Objectifs (AVQ)
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="travailler"
                              onChange={this.props.handleChangeInput}
                              name="check_objectif_9"
                              checked={initialValues.check_objectif_9}
                              value="Retrouver capacité à travailler"
                            />
                            <label className="form-check-label" htmlFor={`travailler`}>
                              Retrouver capacité à travailler
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_objectif_10"
                              checked={initialValues.check_objectif_10}
                              value="Retrouver capacités sportives"
                              className="form-check-input"
                              type="checkbox"
                              id={`sportives`}
                            />
                            <label className="form-check-label" htmlFor={`sportives`}>
                              Retrouver capacités sportives
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_objectif_11"
                              checked={initialValues.check_objectif_11}
                              value="Retrouver capacité à conduire"
                              className="form-check-input"
                              type="checkbox"
                              id={`conduire`}
                            />
                            <label className="form-check-label" htmlFor={`conduire`}>
                              Retrouver capacité à conduire
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_objectif_12"
                              checked={initialValues.check_objectif_12}
                              value="Améliorer la vie sociale"
                              className="form-check-input"
                              type="checkbox"
                              id={`sociale`}
                            />
                            <label className="form-check-label" htmlFor={`sociale`}>
                              Améliorer la vie sociale
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_objectif_13"
                              checked={initialValues.check_objectif_13}
                              value="Permettre la reprise des loisirs"
                              className="form-check-input"
                              type="checkbox"
                              id={`loisirs`}
                            />
                            <label className="form-check-label" htmlFor={`loisirs`}>
                              Permettre la reprise des loisirs
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Objectifs (Gestes M.SUP)
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="habiller"
                              onChange={this.props.handleChangeInput}
                              name="check_objectif_14"
                              checked={initialValues.check_objectif_14}
                              value="S'habiller"
                            />
                            <label className="form-check-label" htmlFor={`habiller`}>
                              S'habiller
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_objectif_15"
                              checked={initialValues.check_objectif_15}
                              value="Enfilier un T-Shirt"
                              className="form-check-input"
                              type="checkbox"
                              id={`Shirt`}
                            />
                            <label className="form-check-label" htmlFor={`Shirt`}>
                              Enfilier un T-Shirt
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_objectif_16"
                              checked={initialValues.check_objectif_16}
                              value="Mettre ses chaussenttes"
                              className="form-check-input"
                              type="checkbox"
                              id={`chaussenttes`}
                            />
                            <label className="form-check-label" htmlFor={`chaussenttes`}>
                              Mettre ses chaussenttes
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_objectif_17"
                              checked={initialValues.check_objectif_17}
                              value="Mettre une veste"
                              className="form-check-input"
                              type="checkbox"
                              id={`veste`}
                            />
                            <label className="form-check-label" htmlFor={`veste`}>
                              Mettre une veste
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_objectif_18"
                              checked={initialValues.check_objectif_18}
                              value="Ouvrir un meuble haut"
                              className="form-check-input"
                              type="checkbox"
                              id={`meuble`}
                            />
                            <label className="form-check-label" htmlFor={`meuble`}>
                              Ouvrir un meuble haut
                            </label>
                          </div>
                        </div>

                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_objectif_19"
                              checked={initialValues.check_objectif_19}
                              value="Se servir d'un couteau"
                              className="form-check-input"
                              type="checkbox"
                              id={`couteau`}
                            />
                            <label className="form-check-label" htmlFor={`couteau`}>
                              Se servir d'un couteau
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_objectif_20"
                              checked={initialValues.check_objectif_20}
                              value="Se servir d'une carafe"
                              className="form-check-input"
                              type="checkbox"
                              id={`carafe`}
                            />
                            <label className="form-check-label" htmlFor={`carafe`}>
                              Se servir d'une carafe
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{backgroundColor: '#f3f4f9',width:"100%"
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Autres objectifs
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="additional_container_item_field">
                        <textarea
                          onChange={this.props.handleChangeInput}
                          name="textarea_objectif"
                          value={initialValues.textarea_objectif}
                          maxLength="500"
                          rows="10"
                          style={{ width: '100%' }}
                          placeholder="Autres objectifs"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Objectif;
