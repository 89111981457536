import { getAppErrorCode } from '../../../../utils/helpres';

const initialAppState = {
  error: undefined,
  addAccount: false,
  emailDoctorExist: false,
  created: false,
  isLoading: false,
  isLoadingGetEmail: false,
  isLoadingNewAccountRequest: false,
  newUser: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    Roles: [],
    Address: {
      country: '',
      city: '',
      street: '',
      zipCode: '',
    },
  },
  message: '',
};
const RegisterReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_DOCTOR_BY_EMAIL_USER_REQUESTING': {
      return {
        ...state,
        isLoadingGetEmail: true,
        emailDoctorExist: null,
      };
    }
    case 'GET_DOCTOR_BY_EMAIL_USER_SUCCESS': {
      return {
        ...state,
        isLoadingGetEmail: false,
        emailDoctorExist: action.data,
      };
    }
    case 'GET_DOCTOR_BY_EMAIL_USER_FAILURE': {
      return {
        ...state,
        isLoadingGetEmail: false,
        emailDoctorExist: false,
        message: action.err,
        error: getAppErrorCode(action.status),
        ErrorMessage: action.ErrorMessage,
      };
    }
    case 'INITIAL_STATE_SIGN_UP': {
      return {
        ...state,
        isLoading: true,
        addAccount: false,
        emailDoctorExist: false,
      };
    }
    case 'CREATE_NEW_ACCOUNT_REQUEST_REQUESTING': {
      return {
        ...state,
        isLoadingNewAccountRequest: true,
        addAccount: false,
      };
    }
    case 'CREATE_NEW_ACCOUNT_REQUEST_SUCCESS': {
      return {
        ...state,
        isLoadingNewAccountRequest: false,
        addAccount: action.data,
      };
    }
    case 'CREATE_NEW_ACCOUNT_REQUEST_FAILURE': {
      return {
        ...state,
        isLoadingNewAccountRequest: false,
        addAccount: false,
        message: action.err,
        ErrorMessage: action.ErrorMessage,
      };
    }

    case 'CREATE_NEW_USER_REQUESTING': {
      return {
        ...state,
        isLoading: true,
        created: false,
      };
    }
    case 'CREATE_NEW_USER_SUCCESS': {
      return {
        ...state,
        isLoading: false,
        created: true,
        user: {
          ...action.data,
        },
      };
    }
    case 'CREATE_NEW_USER_FAILURE': {
      return {
        ...state,
        isLoading: false,
        created: false,
        user: {
          ...action.data,
        },
        message: action.err,
        //error: getAppErrorCode(action.status),
        ErrorMessage: action.ErrorMessage,
      };
    }
    default: {
      return state;
    }
  }
};

export default RegisterReducer;
