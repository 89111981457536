import moment from 'moment';

const initialUserState = {
  credits: [],
  isLoadingCredits: false,
  params: {
    limit: 15,
    currentPage: 1,
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    search: '',
    status: '',
  },
  destroyCredit: false,
  isLoadingDestroyCredit: true,
  sumCredit: null,
};

const CreditReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case 'UPDATE_CREDIT_REQUESTING': {
      return {
        ...state,
        isLoadingDestroyCredit: true,
      };
    }
    case 'UPDATE_CREDIT_SUCCESS': {
      return {
        ...state,
        isLoadingDestroyCredit: false,
        destroyCredit: true,
      };
    }
    case 'UPDATE_CREDIT_FAILURE': {
      return {
        ...state,
        isLoadingDestroyCredit: false,
        destroyCredit: null,
      };
    }

    case 'DELETE_CREDITS_REQUESTING': {
      return {
        ...state,
        isLoadingDestroyCredit: true,
      };
    }
    case 'DELETE_CREDITS_SUCCESS': {
      return {
        ...state,
        isLoadingDestroyCredit: false,
        destroyCredit: true,
      };
    }
    case 'DELETE_CREDITS_FAILURE': {
      return {
        ...state,
        isLoadingDestroyCredit: false,
        destroyCredit: null,
      };
    }

    case 'GET_CREDITS_REQUESTING': {
      return {
        ...state,
        isLoadingCredits: true,
        destroyCredit: false,
      };
    }
    case 'GET_CREDITS_SUCCESS': {
      return {
        ...state,
        isLoadingCredits: false,
        credits: action.data.data,
        sumCredit: action.data.sumCredit,
      };
    }
    case 'GET_CREDITS_FAILURE': {
      return {
        ...state,
        isLoadingCredits: false,
        credits: [],
      };
    }

    case 'UPDATE_SEARCH_CREDITS_PARAMS': {
      return {
        ...state,
        params: action.params,
      };
    }
    default: {
      return state;
    }
  }
};

export default CreditReducer;
