import React, { Component } from 'react';
import TransferListRDV from './containers/form-affect';
import actions from './store/actions';
import actionsM from '../messenger/store/actions';
import { connect } from 'react-redux';
import actionsJs from '../../js/actions';
import CircularProgress from '@material-ui/core/CircularProgress';

const mapStateToProps = (state) => ({
  getAllRDV: state.AgendaReducer.getAllRDV,
  params: state.AgendaReducer.params,
  isLoadingAddRdv: state.AgendaReducer.isLoadingAddRdv,
  paramsDoctor: state.MessengerReducer.params,
  employeeManagement: state.AppReducer.employeeManagement,
  isLoadingHolidayEmployeeManagement: state.AppReducer.isLoadingHolidayEmployeeManagement,
});

const mapDispatchToProps = (dispatch) => ({
  getAllRdv: (params, id) => dispatch(actions.getAllRdvByDay(params, id)),
  getEmployeeManagement: (type) => dispatch(actionsJs.getEmployeeManagement(type)),
});
class TransferList extends Component {
  constructor(props) {
    super(props);
    props.getEmployeeManagement(true);
    this.goTo = this.goTo.bind(this);
  }
  goTo() {
    this.props.history.push({
      pathname: '/agenda',
    });
  }
  render() {
    const { employeeManagement, isLoadingHolidayEmployeeManagement } = this.props;
    if (isLoadingHolidayEmployeeManagement)
      return (
        <CircularProgress disableShrink style={{ marginLeft: '45%', marginTop: '30%' }} />
      );
    return (
      <div
        className="main_content"
        style={{
          overflow: 'hidden',
          width: '101.5%',
        }}
      >
        <TransferListRDV goTo={this.goTo} employeeManagement={employeeManagement} />
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TransferList);
