import * as React from "react";
import ReactPDF, {Text, View} from "@react-pdf/renderer";
import {getDefaultBorderIncludes, transformToArray} from "./Utils";

/**
 * This component displays the associated content of it's children.
 */
export class TableCell extends React.PureComponent {
  render() {
    let content;

    if (typeof this.props.children === "string") {
      content = (
        <Text>{this.props.children}</Text>
      );
    } else if(typeof this.props.children === "number") {
      content = (
        <Text>{this.props.children.toString()}</Text>
      );
    } else {
      content = this.props.children;
    }

    const {includeRightBorder} = getDefaultBorderIncludes(this.props);
    const defaultStyle = {
      flex: this.props.weighting ?? 1,
      // @ts-ignore
      justifyContent: "stretch",
      margin: 3,
      textAlign: "center",
      fontSize: this.props.fontSize ?? (this.props.isHeader === true ? 14 : 12),
      color: '#222222',
      fontWeight: this.props.isHeader === true ? 'bolder' : '500',
      borderRight: includeRightBorder && '1pt solid black',
      wordWrap: "break-word",
      height:20,
      whiteSpace: "pre-wrap"
    };

    const mergedStyles = [
      defaultStyle,
      ...transformToArray(this.props.style)
    ];

    return (
      <View
        style={mergedStyles}
        wrap={true}
      >
        {content}
      </View>
    );
  }
}
