import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import actions from './store/actions';
import actionsJs from '../../js/actions';
import actionsDashboard from './../dashborad/store/actions';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import fr from 'date-fns/locale/fr';
import { firstNumberPhone, isNumeric, validate } from '../../utils/helpres';
import { SelectorContactPatientsBodyItem, SelectorWidthInput } from '../../utils';
import './index.css';
import Item_patient from './composent/item_patient';
import moment from 'moment';
import { sortCities } from '../../utils/constants';
import Patient from './composent/patient';
import consultationActions from '../consultations/store/actions';
import List_consultation from './containers/list_consultation';
import List_bilan from './containers/list_bilan';
import List_Protocole from './containers/list_protocole';
import FormPatient from './form_patient';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

registerLocale('fr', fr); // register it with the name you want

const mapStateToProps = (state) => ({
  patientRecord: state.PatientReducer.patientRecord,
  isLoadingPatientRecord: state.PatientReducer.isLoadingPatientRecord,
  patients: state.PatientReducer.patients,
  patientRecords: state.PatientReducer.patientRecords,
  params: state.PatientReducer.params,
  user: state.AppReducer.user,
  medecins: state.AppReducer.medecins,
  newPatient: state.PatientReducer.newPatient,
  isLoadingPatient: state.PatientReducer.isLoadingPatient,
  isLoadingPatientRecords: state.PatientReducer.isLoadingPatientRecords,
  isLoadingBilanPatientRecord: state.PatientReducer.isLoadingBilanPatientRecord,
  bilanPatientRecord: state.PatientReducer.bilanPatientRecord,
  protocolePatientRecord: state.PatientReducer.protocolePatientRecord,
  isLoadingProtocolePatientRecord: state.PatientReducer.isLoadingProtocolePatientRecord,
  paramsBilan: state.PatientReducer.paramsBilan,
  isLoadingLast10Patients: state.DashboardReducer.isLoadingLast10Patients,
  last10Patient: state.DashboardReducer.last10Patient,
  loadingConsultationPatientRecord:
    state.ConsultationReducer.loadingConsultationPatientRecord,
  patientConsultationList: state.ConsultationReducer.patientConsultationList,
  paramsConsultation: state.ConsultationReducer.params,
  updateBill: state.FactureReducer.updateBill,
  addPatient: state.PatientReducer.addPatient,
  addSessions: state.ConsultationReducer.addSessions,
  isLoadingBillPatientRecord: state.PatientReducer.isLoadingBillPatientRecord,
  isLoadingPatientStatistical: state.PatientReducer.isLoadingPatientStatistical,
  updateConsultation: state.ConsultationReducer.updateConsultation,
});

const mapDispatchToProps = (dispatch) => ({
  getPatientStatistical: (id) => dispatch(actions.getPatientStatistical(id)),
  getPatientRecordById: (id) => dispatch(actions.getPatientRecordById(id)),
  getMedecins: () => dispatch(actionsJs.getMedecins()),
  changeActiveItemSideBarCompta: (data) =>
    dispatch(actionsJs.changeActiveItemSideBarCompta(data)),
  initialState: () => dispatch(actions.initialState()),
  initialStateConsultation: () => dispatch(consultationActions.initialState()),
  submitPatientForm: async (values, id) => {
    await dispatch(actions.submitPatientForm(values));
    dispatch(actions.getPatientRecordsByDoctor());
  },
  changeSearchedPhone: async (params) => {
    await dispatch(actions.changeSearchedString(params));
    dispatch(actions.getPatientRecordsByDoctor(params));
  },
  changeSearched: async (params) => {
    await dispatch(actions.changeSearchedString(params));
    dispatch(actions.getPatientRecordsByDoctor(params));
  },
  changeSearchedCity: async (params) => {
    await dispatch(actions.changeSearchedString(params));
    dispatch(actions.getPatientRecordsByDoctor(params));
  },
  changeSearchedBirthday: async (params) => {
    await dispatch(actions.changeSearchedString(params));
    dispatch(actions.getPatientRecordsByDoctor(params));
  },
  submitAddPatientRecordForm: async (params, values) => {
    await dispatch(actions.submitAddPatientRecordForm(values));
    dispatch(actions.getPatientRecordsByDoctor(params));
  },
  changeSearchedStringLimit: async (params) => {
    await dispatch(actions.changeSearchedString(params));
    dispatch(actions.getPatientRecordsByDoctor(params));
  },
  getPatientRecordsByDoctor: (params) =>
    dispatch(actions.getPatientRecordsByDoctor(params)),
  getPatients: (params) => dispatch(actions.getPatients(params)),
  getLast10PatientByDoctor: () => dispatch(actionsDashboard.getLast10PatientByDoctor()),
  getBilanByPatientRecordId: (params, id) =>
    dispatch(actions.getBilanByPatientRecordId(params, id)),
  getProtocoleByPatientRecordId: (params, id) =>
    dispatch(actions.getProtocoleByPatientRecordId(params, id)),
  getDoctorConsultations: (params) =>
    dispatch(consultationActions.getDoctorConsultations(params)),
  changeSearchedStringConsultation: (params, id) => {
    dispatch(consultationActions.changeSearchedString(params));
    dispatch(consultationActions.getPatientConsultations(params, id));
  },
  changeSearchedStringBilan: (params, id) => {
    dispatch(actions.changeSearchedStringBilan(params));
    dispatch(actions.getBilanByPatientRecordId(params, id));
  },
  changeActiveItemSideBarSettings: (item, data, create) =>
    dispatch(actionsJs.changeActiveItemSideBarSettings(item, data, create)),
  changeSearchedStringBill: (params, id) => {
    dispatch(actions.changeSearchedStringBill(params));
    dispatch(actions.getBillByPatientRecordId(params, id));
  },
  changeSearchedStringReceipt: (params, id) => {
    dispatch(actions.changeSearchedStringReceipt(params));
    dispatch(actions.getReceiptByPatientRecordId(params, id));
  },
});

class Patients extends PureComponent {
  constructor(props) {
    super(props);
    props.getPatientRecordById(props.match.params.id);
    props.initialState();
    props.changeActiveItemSideBarCompta(null);
    props.initialStateConsultation();
    // props.getLast10PatientByDoctor();
    props.getMedecins();
    props.changeNamePage('Patients');
    props.changeActiveItemSideBarSettings(111, false, true);
    props.changeActiveItemSideBar(3);
    document.title = 'Patients';
    this.state = {
      defaultFilter: true,
      showPatient: true,
      showBilans: false,
      showProtocole: false,
      showConsultations: false,
      showFormPatient: false,
      errorPhone: false,
      errorName: false,
      errorDate: false,
      firstClick: false,
      patient: null,
      patient_selected:
        this.props.location.state && this.props.location.state.patient_id
          ? this.props.location.state.patient_id
          : null,
      note: '',
      last_name: props.params.last_name,
      phone: props.params.phone,
    };
    this.showConsultations = this.showConsultations.bind(this);
    this.showFormPatient = this.showFormPatient.bind(this);
    this.showBilans = this.showBilans.bind(this);
    this.showProtocole = this.showProtocole.bind(this);
    this.showPatient = this.showPatient.bind(this);
    this.showFormFacture = this.showFormFacture.bind(this);
    this.onChangePhoneParams = this.onChangePhoneParams.bind(this);
    this.onChangeNameParams = this.onChangeNameParams.bind(this);
    this.makeHttpRequestWithPage = this.makeHttpRequestWithPage.bind(this);
    this.gotToAgendaPrescription = this.gotToAgendaPrescription.bind(this);
  }
  makeHttpRequestWithPage = (event, page) => {
    this.props.changeSearchedStringLimit({
      ...this.props.params,
      currentPage: page ? page : this.props.params.currentPage - 1,
      limit: this.props.params.limit,
    });
  };
  showFormFacture() {
    this.setState({
      showPatient: false,
      showBilans: false,
      showProtocole: false,
      showConsultations: false,
      showFormPatient: false,
      showFormFacture: true,
    });
    // SelectorSandwichMenuBtn();
    SelectorWidthInput();
  }
  showFormPatient() {
    this.setState({
      showPatient: false,
      showBilans: false,
      showProtocole: false,
      showConsultations: false,
      showFormPatient: true,
    });
    // SelectorSandwichMenuBtn();
    SelectorWidthInput();
  }
  showBilans() {
    this.setState({
      showPatient: false,
      showBilans: true,
      showProtocole: false,
      showConsultations: false,
      showFormPatient: false,
    });
  }
  showProtocole() {
    this.setState({
      showPatient: false,
      showBilans: false,
      showProtocole: true,
      showConsultations: false,
      showFormPatient: false,
    });
  }
  showConsultations() {
    this.setState({
      showPatient: false,
      showBilans: false,
      showProtocole: false,
      showConsultations: true,
      showFormPatient: false,
    });
  }
  showPatient(id) {
    this.props.changeNamePage('Patients');
    this.setState({
      showPatient: true,
      showProtocole: false,
      showBilans: false,
      showConsultations: false,
      showFormPatient: false,
    });
    this.props.getPatientRecordById(id);
    this.props.getPatientStatistical(id);
    this.setState({
      patient_selected: id,
    });
    /*this.props.getPatientRecordsByDoctor({
      ...this.props.params,
      limit: 10,
    });*/
    /*this.props.getBilanByPatientRecordId(
      {
        ...this.props.paramsBilan,
        limit: 2,
      },
      this.props.patientRecord.id,
    );
    this.props.getPatientRecordsByDoctor({
      ...this.props.params,
      limit: 10,
    });
    this.props.getPatientRecordById(this.props.patientRecord.patient.id);
    this.props.changeSearchedStringConsultation(
      {
        ...this.props.paramsConsultation,
        limit: 2,
        startDate: '',
      },
      this.props.patientRecord.id,
    );*/
  }
  onChangePhoneParams(value) {
    const { params } = this.props;
    if (!isNumeric(value) || !firstNumberPhone(value)) {
      this.setState({
        errorPhone: true,
        phone: value,
      });
      if (!value)
        this.props.changeSearchedPhone({
          ...params,
          phone: value,
        });
    } else {
      this.setState({
        errorPhone: false,
        phone: value,
      });
      this.props.changeSearchedPhone({
        ...params,
        phone: value ? value : '',
      });
    }
  }
  onChangeNameParams(value) {
    const { params } = this.props;
    if (!validate(value)) {
      this.setState({
        errorName: true,
        first_name: value,
      });
      if (!value)
        this.props.changeSearchedLastName({
          ...params,
          first_name: '',
        });
    } else {
      this.setState({
        errorName: false,
        first_name: value,
      });
      this.props.changeSearchedLastName({
        ...params,
        first_name: value,
      });
    }
  }

  componentDidMount() {
    this.setState({
      showFormPatient: false,
    });
    SelectorContactPatientsBodyItem();
    this.props.changeSearched({
      search:
        this.props.location.state && this.props.location.state.name
          ? this.props.location.state.name
          : '',
      first_name: '',
      city: '',
      birthday: '',
      cnam: '',
      phone:
        this.props.location.state && this.props.location.state.phone
          ? this.props.location.state.phone
          : '',
      currentPage: 1,
      limit: 10,
    });
    /* this.props.getBilanByPatientRecordId(
       {
         ...this.props.paramsBilan,
         limit: 2,
       },
       this.props.patientRecord && this.props.patientRecord.id,
     );
     this.props.getProtocoleByPatientRecordId(
       {
         ...this.props.paramsBilan,
         limit: 2,
       },
       this.props.patientRecord && this.props.patientRecord.id,
     );
     this.props.changeSearchedStringConsultation(
       {
         ...this.props.paramsConsultation,
         limit: 2,
         startDate: '',
       },
       this.props.patientRecord && this.props.patientRecord.id,
     );*/
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {
      patients,
      patientRecords,
      updateConsultation,
      addSessions,
      newPatient,
      updateBill,
      addPatient,
    } = nextProps;
    if (updateConsultation) {
      this.props.history.push({
        pathname: '/consultations',
        state: { consultation_id: updateConsultation.id },
      });
    }
    const { showFormPatient } = this.state;
    if (updateBill) {
      nextProps.history.push(`/factures`);
    }

    if (addPatient && showFormPatient) {
      this.showPatient(newPatient.id);
      this.setState({
        showFormPatient: false,
      });
    }
    if (patients.total === 0 && patientRecords.total === 0) {
      this.props.history.push(`/form-patient`);
    }
    if (addSessions) {
      this.props.history.push(`/consultations`);
    }
  }
  gotToAgendaPrescription(getDispoDate, initialValues) {
    this.props.history.push({
      pathname: '/agenda-prescription',
      state: { getDispoDate: getDispoDate, initialValues: initialValues },
    });
  }
  render() {
    const {
      params,
      patientRecord,
      isLoadingPatient,
      isLoadingPatientRecords,
      loadingConsultationPatientRecord,
      isLoadingPatientRecord,
      isLoadingPatientStatistical,
      bilanPatientRecord,
      patients,
      patientRecords,
      last10Patient,
      patientConsultationList,
      paramsConsultation,
      paramsBilan,
      sandwich_menu_btn,
      user,
      protocolePatientRecord,
      medecins,
      isLoadingBilanPatientRecord,
      admin,
    } = this.props;
    const {
      defaultFilter,
      showPatient,
      showBilans,
      showProtocole,
      showConsultations,
      showFormPatient,
      errorPhone,
      patient_selected,
      firstClick,
    } = this.state;
    const data =
      patientRecords.data && patientRecords.data.length === 0
        ? patients.data
        : (patientRecords.data && patientRecords.data.length > 0) ||
          (patientRecords.data && patients.data.length === 0)
        ? patientRecords.data
        : last10Patient;
    return (
      <div className="main_content">
        <svg
          onClick={async () => {
            this.props.history.goBack();
          }}
          width="46"
          height="46"
          viewBox="0 0 46 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ cursor: 'pointer', margin: 5 }}
        >
          <rect
            x="0.5"
            y="45.5"
            width="45"
            height="45"
            rx="9.5"
            transform="rotate(-90 0.5 45.5)"
            fill="white"
            stroke="#ECEEF5"
          ></rect>
          <path
            d="M20.0117 23L24.5117 18.5L25.5664 19.5547L22.1211 23L25.5664 26.4453L24.5117 27.5L20.0117 23Z"
            fill="#8083A3"
          ></path>
        </svg>
        <div className="patient_info_section" style={{ width: '100%' }}>
          {isLoadingPatientRecord || isLoadingPatientStatistical ? (
            <CircularProgress
              disableShrink
              style={{ marginLeft: '50%', marginTop: '32%' }}
            />
          ) : !patientRecord ? (
            <div className="consultation_detailed_info_section">
              <div className="empty_consultation_result_container">
                <div className="empty_consultation_result">
                  <img src={require('../../assets/img/undraw_design_team_af2y.svg')} />
                  <span id="no_consultation">Pas de Patients !</span>
                  <span id="no_consultation_desc">
                    Essayez de créer des nouveaux patients
                  </span>
                </div>
              </div>
            </div>
          ) : data && data.length > 0 && showPatient ? (
            <Patient
              medecins={medecins}
              admin={admin}
              patientRecord={patientRecord}
              patientConsultationList={patientConsultationList}
              protocolePatientRecord={protocolePatientRecord}
              bilanPatientRecord={bilanPatientRecord}
              paramsConsultation={paramsConsultation}
              isLoadingBilanPatientRecord={isLoadingBilanPatientRecord}
              showConsultations={this.showConsultations}
              changeSearchedStringConsultation={
                this.props.changeSearchedStringConsultation
              }
              gotToAgendaPrescription={this.gotToAgendaPrescription}
              showBilans={this.showBilans}
              showProtocole={this.showProtocole}
              showFormPatient={this.showFormPatient}
              showFormFacture={this.showFormFacture}
              changeSearchedStringBill={this.props.changeSearchedStringBill}
              changeSearchedStringReceipt={this.props.changeSearchedStringReceipt}
            />
          ) : showConsultations ? (
            <List_consultation
              changeSearchedStringConsultation={
                this.props.changeSearchedStringConsultation
              }
              loadingConsultationPatientRecord={loadingConsultationPatientRecord}
              patientRecord={patientRecord}
              paramsConsultation={paramsConsultation}
              patientConsultationList={patientConsultationList}
              showPatient={this.showPatient}
            />
          ) : showBilans ? (
            <List_bilan
              showPatient={this.showPatient}
              patientRecord={patientRecord}
              bilanPatientRecord={bilanPatientRecord}
              changeSearchedStringBilan={this.props.changeSearchedStringBilan}
              paramsBilan={paramsBilan}
            />
          ) : showProtocole ? (
            <List_Protocole
              showPatient={this.showPatient}
              patientRecord={patientRecord}
              protocolePatientRecord={protocolePatientRecord}
              getProtocoleByPatientRecordId={this.props.getProtocoleByPatientRecordId}
              paramsBilan={paramsBilan}
            />
          ) : showFormPatient ? (
            <FormPatient
              changeNamePage={this.props.changeNamePage}
              title="Modifer un patient"
              changeActiveItemSideBar={this.props.changeActiveItemSideBar}
              sandwich_menu_btn={sandwich_menu_btn}
              showPatient={this.showPatient}
              patient={patientRecord.patient}
              patientRecordId={patientRecord.id}
              patientRecordNumber={patientRecord.pr_number}
            />
          ) : (
            <div className="consultation_detailed_info_section">
              <div className="empty_consultation_result_container">
                <div className="empty_consultation_result">
                  <img src={require('../../assets/img/undraw_design_team_af2y.svg')} />
                  <span id="no_consultation">Pas de Patients !</span>
                  <span id="no_consultation_desc">
                    Essayez de créer des nouveaux patients
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Patients);
