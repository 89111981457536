import React, { Component } from 'react';
import './index.css';
import './rendezvouslist-container.css';
import actions from './store/actions';
import { connect } from 'react-redux';
import { registerLocale } from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/fr';
import fr from 'date-fns/locale/fr'; // the locale you want
import actionsA from '../agenda/store/actions';
import actionsJs from '../../js/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import FullCalendar from '@fullcalendar/react';
import { isHasPermission, validate } from '../../utils/helpres';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import frLocale from '@fullcalendar/core/locales/fr';
import SwipeableDrawerCreate from './composent/SwipeableDrawerCreate';
import SwipeableDrawerUpdate from './composent/SwipeableDrawerUpdate';
import SwipeableDrawerCreateDuplicate from './composent/SwipeableDrawerDuplicate';
import actionsPatient from '../patient/store/actions';
import Alert from '@mui/material/Alert';
import InputField from '../../components/inputField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { sortCities } from '../../utils/constants';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import actionsCure from '../consultations/store/actions';

registerLocale('fr', fr); // register it with the name you want
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const mapStateToProps = (state) => ({
  isLoadingDuplicateRdv: state.AgendaReducer.isLoadingDuplicateRdv,
  duplicateRdv: state.AgendaReducer.duplicateRdv,
  destroyAbsence: state.AppReducer.deleteAbsence,
  isLoadingGetAllUnavailability: state.AgendaReducer.isLoadingGetAllUnavailability,
  getAllUnavailabilityList: state.AgendaReducer.getAllUnavailability,
  isLoadingGetAllRDV: state.AgendaReducer.isLoadingGetAllRDV,
  isLoadingAddRdv: state.AgendaReducer.isLoadingAddRdv,
  created: state.AgendaReducer.created,
  canceledRdv: state.AgendaReducer.canceledRdv,
  isLoadingAddDispo: state.AgendaReducer.isLoadingAddDispo,
  createdNewDispo: state.AgendaReducer.createdNewDispo,
  isLoadingDeleteRDV: state.AgendaReducer.isLoadingDeleteRDV,
  deleteRDV: state.AgendaReducer.deleteRDV,
  motifsAgenda: state.AgendaReducer.motifsAgenda,
  getAllRDV: state.AgendaReducer.getAllRDV,
  params: state.AgendaReducer.params,
  paymentRdv: state.AppReducer.payment,
  actionAbsences: state.AppReducer.actionAbsences,
  activeItemSideBarRDV: state.AppReducer.activeItemSideBarRDV,
  user: state.AppReducer.user,
  paramsPatients: state.PatientReducer.params,
  isLoadingPatientRecords: state.PatientReducer.isLoadingPatientRecords,
  patientRecords: state.PatientReducer.patientRecords,
  detailtPyamentRdv: state.AgendaReducer.detailtPyamentRdv,
  isLoadingDetailtPyamentRdv: state.AgendaReducer.isLoadingDetailtPyamentRdv,
  loadingAddSessions: state.ConsultationReducer.loadingAddSessions,
  medecins: state.AppReducer.medecins,
  updateConsultation: state.ConsultationReducer.updateConsultation,
  addPec: state.ConsultationReducer.addPec,
});

const mapDispatchToProps = (dispatch) => ({
  createConsultation: async (params, values, id) => {
    await dispatch(actionsCure.addConsultation(values));
    setTimeout(() => {
      dispatch(actions.getAllRdvByDay(params, id));
    }, 1000);
  },
  submitFormPEC: async (params, values, id) => {
    await dispatch(actionsCure.submitFormPEC(values));
    setTimeout(() => {
      dispatch(actions.getAllRdvByDay(params, id));
    }, 1000);
  },
  getMedecins: () => dispatch(actionsJs.getMedecins()),
  getLastAvailability: (id) => dispatch(actions.getLastAvailability(id)),
  getDipoDoctorByMotif: (valuse) => dispatch(actions.getDipoDoctorByMotif(valuse)),
  getAllUnavailability: (params) => dispatch(actions.getAllUnavailability(params)),
  getAllRdv: (params, id) => dispatch(actions.getAllRdvByDay(params, id)),
  getMotif: () => dispatch(actions.getMotif(true)),
  getAbsenses: (params, id) => dispatch(actionsJs.getAbsenses(params, id)),
  changeActiveItemSideBarRDV: (item) =>
    dispatch(actionsJs.changeActiveItemSideBarRDV(item)),
  changeActiveItemSideBarCompta: (data) =>
    dispatch(actionsJs.changeActiveItemSideBarCompta(data)),
  submitRDVForm: async (params, values, id) => {
    await dispatch(actions.submitRDVForm(values));
    setTimeout(() => {
      dispatch(actions.getAllRdvByDay(params, id));
    }, 1000);
  },
  deleteRdv: async (params, id, ids) => {
    await dispatch(actions.deleteRdv(id));
    dispatch(actions.getAllRdv(params, ids));
  },
  changeType: async (params, id) => {
    await dispatch(actions.changeParams(params));
    //dispatch(actions.getAllUnavailability(params));
    dispatch(actions.getAllRdvByDay(params, id));
  },
  changeDate: async (params, id) => {
    await dispatch(actions.changeParams(params));
    dispatch(actions.getAllUnavailability(params));
    dispatch(actions.getAllRdvByDay(params, id));
  },
  submitPaymentForm: async (params, values, id) => {
    await dispatch(actionsA.submitPaymentForm(values));
    setTimeout(() => {
      dispatch(actions.getAllRdvByDay(params, id));
    }, 1000);
  },
  changeStatusRdv: async (params, id) => {
    await dispatch(actions.changeParams(params));
    dispatch(actions.getAllRdvByDay(params, id));
  },
  deleteAbsence: async (params, item) => {
    await dispatch(actionsJs.deleteAbsence(item));
    dispatch(actions.getAllUnavailability(params, item.id));
  },
  submitAbsenceForm: async (params, values, id) => {
    await dispatch(actionsJs.submitAbsenceForm(values));
    dispatch(actions.getAllUnavailability(params, id));
  },
  duplicateRDVForm: async (params, values, id) => {
    await dispatch(actionsA.duplicateRDVForm(values));
    setTimeout(() => {
      dispatch(actions.getAllRdvByDay(params, id));
    }, 1000);
  },
  changeSearched: async (params) => {
    await dispatch(actionsPatient.changeSearchedString(params));
    dispatch(actionsPatient.getPatientRecordsByDoctor(params));
  },
  getDetailtPaymentAppointment: (id) =>
    dispatch(actionsA.getDetailtPaymentAppointment(id)),
  changeSearchedString: async (params) => {
    await dispatch(actionsPatient.changeSearchedString(params));
  },
});

const EventDetail = (eventInfo) => {
  let pathologie = '',
    pathologieColor = '#000000';
  try {
    pathologie =
      eventInfo.event._def.extendedProps.motif &&
      eventInfo.event._def.extendedProps.motif.label === 'Autre'
        ? eventInfo.event._def.extendedProps.diagnostic
        : eventInfo.event._def.extendedProps.motif.label;
    pathologieColor =
      eventInfo.event._def.extendedProps.motif &&
      eventInfo.event._def.extendedProps.motif.color;
  } catch (error) {
    console.error(error);
  }
  return eventInfo.event._def.extendedProps.type === 'rdv' ? (
    eventInfo.view.type === 'dayGridMonth' ? (
      <>
        <div
          className="fc-daygrid-event-dot"
          style={{ borderColor: eventInfo.borderColor, width: '2px', height: '2px' }}
        ></div>
        <div className="fc-event-title">
          <div style={{ fontWeight: '400' }}>{eventInfo.event.title}</div>
          {eventInfo.event._def.extendedProps.type === 'rdv' ? (
            <div style={{ fontWeight: '400' }}>
              <span style={{ color: pathologieColor }}>
                {pathologie.toString().slice(0, 15)}.
              </span>
              {' ' + moment(eventInfo.event.start).format('HH:mm')}
            </div>
          ) : (
            <div style={{ fontWeight: '400' }}>
              {moment(eventInfo.event.start).format('HH:mm') +
                ' - ' +
                moment(eventInfo.event.start).format('HH:mm')}
            </div>
          )}
        </div>
      </>
    ) : eventInfo.view.type === 'resourceTimeGridFourDay' ||
      eventInfo.view.type === 'resourceTimeGrid7Day' ? (
      <>
        {/* <div
          className="fc-daygrid-event-dot"
          style={{ borderColor: eventInfo.borderColor, width: '2px', height: '2px' }}
        ></div> */}
        <div className="fc-event-title">
          <div style={{ fontWeight: 'bold', color: 'black' }}>
            {eventInfo.event.title}
          </div>
          <div style={{ fontWeight: '400', color: 'black' }}>
            {moment(eventInfo.event.start).format('HH:mm')}
          </div>
        </div>
      </>
    ) : (
      <>
        <div className="fc-event-title">
          <div style={{ fontWeight: 'bold', color: 'black' }}>
            {eventInfo.event.title}
            <span style={{ color: 'black', fontWeight: '400' }}>
              {'   ' +
                moment(eventInfo.event.start).format('HH:mm') +
                ' - ' +
                moment(eventInfo.event.end).format('HH:mm')}
            </span>
          </div>

          <div style={{ fontWeight: '400' }}>
            <span style={{ color: pathologieColor }}>{pathologie.toString()}</span>
          </div>
        </div>
      </>
    )
  ) : (
    <div
      className="absenceDisplay"
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px',
        backgroundColor: 'gray',
        width: '-webkit-fill-available',
        borderRadius: '5px',
        color: 'white',
      }}
    >
      <div className="fc-event-title">
        <div style={{ fontWeight: '400' }}>{eventInfo.event.title}</div>
        <div style={{ fontWeight: '400' }}>
          {moment(eventInfo.event.start).format('HH:mm') +
            ' - ' +
            moment(eventInfo.event.end).format('HH:mm')}
        </div>
      </div>
    </div>
  );
};
class Agenda extends Component {
  constructor(props) {
    super(props);
    props.getAllRdv(props.params, props.user.id);
    props.getAllUnavailability(props.params);
    props.getMotif();
    props.changeActiveItemSideBarCompta(null);
    props.changeNamePage('Agenda');
    props.changeActiveItemSideBar(1);
    document.title = 'Agenda';
    this.state = {
      listItem: ['Motif', 'Date', 'Créneau', 'Patient', 'Validation'],
      nameItem: 'Motif',

      updateRdv: false,
      update: false,
      showInput: false,
      item: 0,
      value: 0,
      switchDuplicateRDV: false,
      showRightSideBar: false,
      showRightSideBarRDV: false,
      showRightSideBarRDVUpdate: false,
      showRdv: true,
      checkedStatus: false,
      statusList: [],
      calendarWeekends: true,
      date: props.params.date,
      showUnavailability: false,
      activeRDV: this.props.location.state ? this.props.location.state.showAddRdv : false,
      payment: {
        id: null,
        total_achieved: null,
        already_paid: null,
        left_to_pay: null,
        money_pot_amount: null,
        session_price: null,
        payment_method: null,
        settled_in_session: null,
        date: null,
      },
      initialValues: {
        errorDate: false,
        errorHours: false,
        labelMotif: '',
        hour_end: '',
        hour_start: '',
        pathologie: '',
        diagnostic: '',
        labelMotif_update: '',
        date: moment().format('YYYY-MM-DD'),
        date_update: '',
        display_name: '',
        display_name_update: '',
        city: '',
        city_update: '',
        doctor_id: null,
        login_access_id: null,
        phone_number: '',
        phone_number_update: '',
        motif_id: null,
        motif_id_update: null,
        pr_number: '',
        startDate: '',
        endDate: '',
        startHours: '',
        startHours_update: '',
        id_update: '',
        endHours: '',
        time: '',
        time_update: '',
        note: '',
        name_btn_indispo: 'Ajouter',
        header_name_indispo: 'Ajouter une nouvelle indisponibilité',
      },
    };
    this.handleBack = this.handleBack.bind(this);
    this.handleShowInput = this.handleShowInput.bind(this);
    this.getStepContent = this.getStepContent.bind(this);
    this.handleShowRightSideBar = this.handleShowRightSideBar.bind(this);
    this.getItemRdv = this.getItemRdv.bind(this);
    this.getItemUnavailability = this.getItemUnavailability.bind(this);
    this.handleChangeTopSectionItemLeftRDV =
      this.handleChangeTopSectionItemLeftRDV.bind(this);
    this.handleShowRightSideBarRDV = this.handleShowRightSideBarRDV.bind(this);
    this.handleBtnTimeClick = this.handleBtnTimeClick.bind(this);
    this.handleInitialStateUnavailability =
      this.handleInitialStateUnavailability.bind(this);
    this.handleInitialStateRDV = this.handleInitialStateRDV.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleErrorHoursChange = this.handleErrorHoursChange.bind(this);
    this.handleErrorDateChange = this.handleErrorDateChange.bind(this);
    this.handleChangeInputPayment = this.handleChangeInputPayment.bind(this);
    this.getItemRdvDuplicated = this.getItemRdvDuplicated.bind(this);
    this.handleNextItem = this.handleNextItem.bind(this);
    this.handleChangeView = this.handleChangeView.bind(this);
    this.handleChangeTopSectionItemLeft = this.handleChangeTopSectionItemLeft.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.goToCure = this.goToCure.bind(this);
    this.toggleDrawerUpdate = this.toggleDrawerUpdate.bind(this);
  }
  goToCure(consultation_id) {
    this.props.history.push({
      pathname: '/consultations',
      state: { consultation_id },
    });
  }

  handleChangeStatus(event) {
    const {
      target: { value },
    } = event;
    let status = [];
    const { statusList } = this.state;
    const { user } = this.props;
    if (statusList.indexOf(value[0]) > -1)
      status = statusList.filter((item) => item !== value[0]);
    else status = [...statusList, value[0]];
    this.props.changeStatusRdv({ ...this.props.params, status: value }, user.id);
    this.setState({
      statusList: typeof value === 'string' ? value.split(',') : value,
      checkedStatus: true,
    });
  }
  handleShowRightSideBar(action) {
    this.setState({
      showRightSideBar:
        action === true && this.state.showRightSideBar
          ? this.state.showRightSideBar
          : !this.state.showRightSideBar,
      initialValues: {
        //...this.state.initialValues,
        id: null,
        date_from: '',
        date_to: '',
        note: '',
        // endDate: unavailability.date_to,
        hour_from: '',
        hour_to: '',
        name_btn_indispo: 'Ajouter',
        header_name_indispo: 'Ajouter une nouvelle absence',
      },
    });
  }
  handleInitialStateUnavailability() {
    this.setState({
      showRightSideBar: false,
      initialValues: {
        ...this.state.initialValues,
        startDate: '',
        errorDate: false,
        errorHours: false,
        endDate: '',
        startHours: '',
        endHours: '',
        comment: '',
      },
    });
  }
  handleBack() {
    const { item } = this.state;
    this.setState({
      item: item - 1,
    });
  }
  handleShowRightSideBarRDV() {
    this.setState({
      showRightSideBarRDV: !this.state.showRightSideBarRDV,
      activeRDV: false,
      item: 0,
      initialValues: {
        ...this.state.initialValues,
        ///date: '',
        display_name: '',
        city: '',
        diagnostic: '',
        labelMotif: '',
        phone_number: '',
        gender: '',
        first_name: '',
        last_name: '',
        phone: '',
        motif_id: null,
        patient_id: null,
        startDate: '',
        endDate: '',
        //startHours: '',
        //endHours: '',
        time: '',
      },
    });
  }
  handleInitialStateRDV() {
    this.props.changeSearched({
      ...this.props.paramsPatients,
      search: '',
    });
    this.setState({
      item: 0,
      initialValues: {
        ...this.state.initialValues,
        date: '',
        patient_id: null,
        display_name: '',
        gender: '',
        first_name: '',
        last_name: '',
        phone: '',
        city: '',
        labelMotif: '',
        phone_number: '',
        motif_id: null,
        startDate: '',
        endDate: '',
        startHours: '',
        endHours: '',
        time: '',
      },
    });
  }
  handleChangeTopSectionItemLeftRDV() {
    this.setState({
      showRdv: true,
      showUnavailability: false,
    });
  }
  handleChangeTopSectionItemLeft() {
    this.setState({
      showRdv: false,
      showUnavailability: true,
    });
  }
  handleBtnTimeClick(event, time) {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        [time]: event.currentTarget.dataset.time,
      },
    });
  }
  handleChangeInput(event) {
    const { initialValues } = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (name === 'startHours') {
      this.setState({
        initialValues: {
          ...initialValues,
          startHours: value,
          endHours: moment(
            moment(
              moment(`${initialValues.date} ${value}`).format('YYYY-MM-DD HH:mm:ss'),
              'YYYY-MM-DD HH:mm:ss',
            )
              .add(30, 'minutes')
              .format('YYYY-MM-DD HH:mm:ss'),
          ).format('HH:mm'),
        },
      });
    } else
      this.setState({
        initialValues: {
          ...initialValues,
          [name]: value,
        },
      });
  }
  componentWillReceiveProps(nextProps, nextContext) {
    const { actionAbsences, created, canceledRdv, getLastAvailabilityDate } = nextProps;
    const { initialValues } = this.state;
    if (actionAbsences) {
      this.handleInitialStateUnavailability();
    }
    if (getLastAvailabilityDate) {
      this.setState({
        initialValues: {
          ...initialValues,
          date: moment(getLastAvailabilityDate.date).format('YYYY-MM-DD'),
        },
      });
    }
    /*if (created && !canceledRdv && !initialValues.id) {
      this.handleShowRightSideBarRDV();
    }*/
    const { createdNewDispo } = nextProps;
    if (createdNewDispo && !initialValues.id) {
      this.handleShowRightSideBar();
    }
  }
  handleCityChange(value) {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        city: value,
      },
    });
  }
  handleErrorHoursChange(value) {
    const { initialValues } = this.state;
    value.map((item) => {
      this.setState({
        initialValues: {
          ...initialValues,
          [item.name]: item.value,
        },
      });
    });
  }
  handleErrorDateChange(value) {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        errorDate: value,
      },
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      updateConsultation,
      duplicateRdv,
      created,
      paymentAction,
      activeItemSideBarRDV,
    } = this.props;
    const { showRightSideBarRDV } = this.state;
    /*if (created && !canceledRdv && !initialValues.id) {
      this.handleShowRightSideBarRDV();
    }
    const { createdNewDispo } = nextProps;
    if (createdNewDispo && !initialValues.id) {
      this.handleShowRightSideBar();
    }*/
    if ((created || paymentAction) && this.state.showInput) {
      this.setState({
        item: 0,
        switchList: false,
        showInput: !this.state.showInput,
      });
      //this.openModal();
    }
    if (duplicateRdv !== prevProps.duplicateRdv) {
      this.setState({
        switchDuplicateRDV: false,
      });
      this.handleInitialStateRDV();
      //this.openModal();
    }
    if (created && showRightSideBarRDV) {
      this.props.changeActiveItemSideBarRDV(activeItemSideBarRDV);
      this.setState({
        item: 0,
        hasError: false,
        update: false,
        showRightSideBarRDV: false,
      });
      // /this.handleInitialStateRDV();
    }
    if (paymentAction !== prevProps.paymentAction && paymentAction) {
      this.setState({
        showInput: !this.state.showInput,
        setIsOpen: false,
      });
    }
  }

  handleNextItem(item) {
    const { initialValues } = this.state;
    const { patientRecords, paramsPatients } = this.props;
    let data = [];
    if (patientRecords && patientRecords.total > 0) data = patientRecords.data;
    if (item === 0 && !initialValues.motif_id) {
      this.setState({
        hasError: true,
      });
    } else if (item === 0 && !initialValues.date) {
      this.setState({
        hasError: true,
      });
    } else if (item === 0 && !initialValues.startHours) {
      //|| !initialValues.endHours
      this.setState({
        hasError: true,
      });
    } else if (
      item === 0 &&
      moment(
        moment(`${initialValues.date} ${initialValues.startHours}`).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
      ).isSameOrAfter(
        moment(
          initialValues.endHours
            ? `${initialValues.date} ${initialValues.endHours}`
            : moment(
                `${initialValues.date} ${initialValues.startHours}`,
                'YYYY-MM-DD HH:mm:ss',
              )
                .add(30, 'minutes')
                .format('YYYY-MM-DD HH:mm:ss'),
        ).format('YYYY-MM-DD HH:mm:ss'),
      )
    ) {
      this.setState({
        hasError: true,
      });
    } else if (
      item === 1 &&
      data.length > 0 &&
      !initialValues.first_name &&
      !initialValues.patient_id
    ) {
      this.setState({
        hasError: true,
      });
    } else if (
      item === 1 &&
      data.length === 0 &&
      (!initialValues.last_name ||
        !initialValues.first_name ||
        !initialValues.city ||
        !initialValues.gender)
    ) {
      this.setState({
        hasError: true,
      });
    } else {
      this.setState({
        item: item + 1,
        hasError: false,
      });
    }
  }
  handleReset() {
    this.setState({
      item: 0,
      update: true,
    });
  }
  getStepContent(
    item,
    initialValues,
    motifs,
    getAllDayDispo,
    isLoadingGetAllDayDispo,
    params,
    hasError,
    getLastAvailabilityDate,
    isLoadingGetLastAvailability,
    isLoadingAddRdv,
    employeeManagement,
    userList,
    absenceHours,
  ) {
    const { isLoadingPatientRecords, paramsPatients, patientRecords } = this.props;
    let data = [];
    if (patientRecords && patientRecords.total > 0) data = patientRecords.data;
    switch (item) {
      case 0:
        return (
          <>
            <div className="right_sidebar_body" id="Date">
              <div className="right_sidebar_body_control">
                <div className="right_side_shit_body_item-1">
                  <span id="motif_num">1</span>
                  <span id="motif_text"> Information générales</span>
                </div>
                <div className="right_side_shit_body_item-other">
                  <span id="motif_num_other">2</span>
                  <span id="motif_num_other">3</span>
                </div>
              </div>
              <hr style={{ width: '100%' }} id="top_section_seperator" />
              <div
                className={
                  hasError && !initialValues.motif_id
                    ? 'right_sidebar_body_item type3'
                    : 'right_sidebar_body_item'
                }
              >
                <label>Pathologie</label>
                <div className="input_and_icon">
                  <select
                    value={initialValues.motif_id}
                    style={{ width: '100%' }}
                    onChange={this.handleChangeInput}
                    name="motif_id"
                  >
                    <option value="">Pathologie</option>;
                    {motifs.map((item) => {
                      if (item.actif)
                        return <option value={item.id}>{item.label}</option>;
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="right_sidebar_body" id="Date">
              <hr style={{ width: '100%' }} id="top_section_seperator" />
              <div className="right_sidebar_body_item">
                <label>Date</label>
                <div className="input_and_icon">
                  <input
                    style={{ width: '100%' }}
                    value={initialValues.date}
                    name="date"
                    onChange={this.handleChangeInput}
                    className="form-control"
                    type="date"
                    placeholder="John Doe"
                  />
                </div>
              </div>
            </div>
            <div className="right_sidebar_body" id="Creneau">
              <hr style={{ width: '100%' }} id="top_section_seperator" />
              <div className="right_sidebar_body_item">
                <span className="creneau_container_title">Heure début</span>
                <div
                  className={
                    hasError &&
                    (!initialValues.startHours ||
                      (initialValues.startHours &&
                        initialValues.endHours &&
                        moment(
                          moment(
                            `${initialValues.date} ${initialValues.startHours}`,
                          ).format('YYYY-MM-DD HH:mm:ss'),
                        ).isSameOrAfter(
                          moment(
                            `${initialValues.date} ${initialValues.endHours}`,
                          ).format('YYYY-MM-DD HH:mm:ss'),
                        )))
                      ? 'creneau_container type3'
                      : 'creneau_container'
                  }
                  style={{ maxHeight: '300px', overflow: 'auto' }}
                >
                  <div className="input_and_icon" style={{ width: '100%' }}>
                    <input
                      style={{ width: '100%' }}
                      value={initialValues.startHours}
                      name="startHours"
                      onChange={this.handleChangeInput}
                      className="form-control"
                      type="time"
                      placeholder="John Doe"
                    />
                  </div>
                </div>
              </div>
              <div className="right_sidebar_body_item">
                <span className="creneau_container_title">Heure fin</span>
                <div
                  className={
                    hasError &&
                    initialValues.endHours &&
                    moment(
                      moment(`${initialValues.date} ${initialValues.startHours}`).format(
                        'YYYY-MM-DD HH:mm:ss',
                      ),
                    ).isAfter(
                      moment(`${initialValues.date} ${initialValues.endHours}`).format(
                        'YYYY-MM-DD HH:mm:ss',
                      ),
                    )
                      ? 'creneau_container type3'
                      : 'creneau_container'
                  }
                  style={{ maxHeight: '300px', overflow: 'auto' }}
                >
                  <div style={{ width: '100%' }} className="input_and_icon">
                    <input
                      style={{ width: '100%' }}
                      value={initialValues.endHours}
                      name="endHours"
                      onChange={this.handleChangeInput}
                      className="form-control"
                      type="time"
                      placeholder="John Doe"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case 1:
        return (
          <div className="right_sidebar_body" id="Patient">
            <div className="right_sidebar_body_control">
              <div className="right_side_shit_body_item-1">
                <span id="motif_check_icon">
                  <i className="fas fa-check"></i>
                </span>
                <span id="motif_num">2</span>
                <span id="motif_text">Patient</span>
              </div>
              <div className="right_side_shit_body_item-other">
                <span id="motif_num_other">3</span>
              </div>
            </div>
            <hr style={{ width: '100%' }} id="top_section_seperator" />
            <div
              className={
                hasError && !initialValues.first_name && !initialValues.patient_id
                  ? 'right_sidebar_body_item type3'
                  : 'right_sidebar_body_item'
              }
            >
              <label>Prénom & Nom</label>
              <div className="input_and_icon">
                <input
                  style={{ width: '100%' }}
                  value={paramsPatients.search || ''}
                  onChange={(event) => {
                    this.props.changeSearched({
                      ...paramsPatients,
                      search: event.target.value ? event.target.value : '',
                    });
                  }}
                  //onChange={this.handleChangeInput}
                  className="form-control"
                  type="text"
                  placeholder="Prénom nom"
                />
              </div>
            </div>
            {data.length === 0 &&
            paramsPatients.search !== '' &&
            paramsPatients.search !== null ? (
              <>
                <Alert style={{ width: '100%' }} severity="info">
                  Essayez de créer un nouveau patient
                </Alert>
                <div className="add_patient_actual_form">
                  <div
                    style={{
                      width: '92%',
                    }}
                    className={
                      hasError && !initialValues.gender
                        ? `add_patient_form_item type3`
                        : 'add_patient_form_item'
                    }
                  >
                    <label>Civilité</label>
                    <div className="input_and_icon">
                      <select
                        value={initialValues.gender}
                        name="gender"
                        onChange={(e) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              gender: e.target.value,
                            },
                          });
                        }}
                        id="exampleFormControlSelect1"
                      >
                        <option value="" selected>
                          Choisissez la civilité
                        </option>
                        <option value="male">M.</option>
                        <option value="female">Mme.</option>
                      </select>
                    </div>
                  </div>
                  <div className="field_row">
                    <InputField
                      width="100%"
                      className="add_patient_form_item"
                      type="text"
                      placeholder="Prénom"
                      label="Prénom"
                      hasError={hasError}
                      validInput="string"
                      value={initialValues.last_name}
                      name="last_name"
                      icon={<i className="fas fa-user"></i>}
                      onChange={(e) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            last_name: e.target.value,
                          },
                        });
                      }}
                    />

                    <div style={{ width: '100%' }} className="add_patient_form_item">
                      <label>Ville</label>
                      <Autocomplete
                        id="size-small-standard"
                        size="small"
                        options={sortCities}
                        onChange={(event, option) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              city: option != null ? option.title : '',
                            },
                          });
                        }}
                        defaultValue={{
                          title: initialValues && initialValues.city,
                        }}
                        getOptionLabel={(option) => option.title}
                        renderInput={(cities) => (
                          <TextField
                            style={{ width: '100%' }}
                            placeholder="Ville"
                            {...cities}
                            variant="standard"
                            //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                            //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                            error={hasError && !initialValues.city}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="field_row">
                    <InputField
                      width="100%"
                      className="add_patient_form_item"
                      type="text"
                      placeholder="Nom"
                      validInput="string"
                      label="Nom"
                      hasError={hasError}
                      value={initialValues.first_name}
                      name="first_name"
                      icon={<i className="fas fa-user"></i>}
                      onChange={(e) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            first_name: e.target.value,
                          },
                        });
                      }}
                    />
                    <InputField
                      width="100%"
                      className="add_patient_form_item"
                      type="text"
                      minLength={8}
                      maxLength={20}
                      placeholder="Téléphone"
                      label="Téléphone"
                      hasError={hasError}
                      validInput="tel"
                      value={initialValues.phone}
                      name="phone"
                      icon={<i className="fas fa-phone-alt"></i>}
                      onChange={(e) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            phone: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </>
            ) : data.length > 0 &&
              paramsPatients.search !== '' &&
              paramsPatients.search !== null ? (
              <>
                <div style={{ height: '280px' }} className="contact_patients_body">
                  {isLoadingPatientRecords
                    ? ''
                    : data.length > 0
                    ? data.map((patient) => {
                        return (
                          <div
                            key={patient.id}
                            onClick={() => {
                              this.setState({
                                initialValues: {
                                  ...initialValues,
                                  patient_id: patient.id,
                                },
                              });
                              this.props.changeSearchedString({
                                ...paramsPatients,
                                search: `${patient.first_name} ${patient.last_name}`,
                              });
                            }}
                            className={`contact_patients_body_item ${
                              initialValues.patient_id === patient.id
                                ? 'selected_patient'
                                : ''
                            }`}
                          >
                            <div className="patient_contact_info_container">
                              <div className="patient_contact_img_container">
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                                    fill="#8083A3"
                                  />
                                </svg>
                              </div>
                              <div className="patient_contact_info">
                                <span id="patient_contact_name">{`${patient.last_name} ${patient.first_name}`}</span>
                                <span id="patient_contact_city">
                                  {patient.address_patient &&
                                    patient.address_patient.city}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : ''}
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        );
      case 2:
        const motif = motifs.find(
          (element) => element.id === parseInt(initialValues.motif_id),
        );
        const patient = data.find(
          (element) => element.id === parseInt(initialValues.patient_id),
        );
        return (
          <div className="right_sidebar_body" id="Validation">
            <div className="right_sidebar_body_control">
              <div className="right_side_shit_body_item-1">
                <span id="motif_check_icon">
                  <i className="fas fa-check"></i>
                </span>
                <span id="motif_check_icon">
                  <i className="fas fa-check"></i>
                </span>
                <span id="motif_num">3</span>
                <span id="motif_text">Validation</span>
              </div>
              <div className="right_side_shit_body_item-other"></div>
            </div>
            <hr id="top_section_seperator" />
            <div className="right_side_shit_body_item-1">
              <div className="validation_control">
                <span style={{ width: '60%' }} id="recap_text">
                  Récaputilatif
                </span>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.handleReset()}
                  className="edit_recap_btn"
                >
                  <i className="fas fa-pen" style={{ padding: '10px 5px' }}></i>Modifier
                </span>
              </div>
            </div>

            <hr id="top_section_seperator" />

            <div className="right_side_shit_body_item-1">
              <div className="recap_info_container">
                <div className="recap_info_additional_validation_control"></div>
                <div className="recap_info_main">
                  <div className="recap_info_container_header">
                    <div
                      style={{ width: '100px', height: '100px' }}
                      className="patient_avatar"
                    >
                      <svg
                        width="33"
                        height="34"
                        viewBox="0 0 33 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.73958 23.2812C9.42708 21.7812 12.9583 21.0312 16.3333 21.0312C19.7083 21.0312 23.2083 21.7812 26.8333 23.2812C30.5208 24.7188 32.3646 26.625 32.3646 29V33.0312H0.302084V29C0.302084 26.625 2.11458 24.7188 5.73958 23.2812ZM21.9583 14.6562C20.3958 16.2188 18.5208 17 16.3333 17C14.1458 17 12.2708 16.2188 10.7083 14.6562C9.14583 13.0938 8.36458 11.2188 8.36458 9.03125C8.36458 6.84375 9.14583 4.96875 10.7083 3.40625C12.2708 1.78125 14.1458 0.96875 16.3333 0.96875C18.5208 0.96875 20.3958 1.78125 21.9583 3.40625C23.5208 4.96875 24.3021 6.84375 24.3021 9.03125C24.3021 11.2188 23.5208 13.0938 21.9583 14.6562Z"
                          fill="#8083A3"
                        />
                      </svg>
                    </div>
                    <h5>
                      {patient
                        ? `${patient.last_name} ${patient.first_name}`
                        : `${initialValues.last_name} ${initialValues.first_name}`}
                    </h5>
                  </div>
                  <div className="recap_info_container_body">
                    <div className="motif_info">
                      <div className="motif_info_item">
                        <div className="motif_info_left_section">
                          <span>Pathologie </span>
                        </div>
                        <div className="motif_info_right_section">
                          <span className="motif_info_prop">{motif && motif.label}</span>
                        </div>
                      </div>

                      <div className="date_info_item">
                        <div className="date_info_left_section">
                          <span>Date</span>
                        </div>
                        <div className="date_info_right_section">
                          <span className="date_info_prop">
                            {moment(initialValues.date).format('DD/MM/YYYY')}
                          </span>
                        </div>
                      </div>
                      <div className="creneau_info_item">
                        <div className="creneau_info_left_section">
                          <span>Créneau</span>
                        </div>
                        <div className="creneau_info_right_section">
                          <span className="creneau_info_prop">
                            {initialValues.startHours}-{initialValues.endHours}
                          </span>
                        </div>
                      </div>
                      <div className="telephone_info_item">
                        <div className="telephone_info_left_section">
                          <span>Téléphone</span>
                        </div>
                        <div className="telephone_info_right_section">
                          <span className="telephone_info_prop">
                            {patient ? patient.phone : initialValues.phone}
                          </span>
                        </div>
                      </div>
                      <div className="ville_info_item">
                        <div className="ville_info_left_section">
                          <span>Ville</span>
                        </div>
                        <div className="ville_info_right_section">
                          <span className="motif_info_prop">
                            {patient
                              ? patient.address_patient && patient.address_patient.city
                              : initialValues.city}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right_sidebar_footer confirm_btn_container">
              <button
                onClick={async () => {
                  await this.props.submitRDVForm(params, initialValues);
                  //this.props.changeActiveItemSideBarRDV(true);
                }}
                className="confirm_recap_info_btn"
              >
                {isLoadingAddRdv ? (
                  <CircularProgress
                    style={{
                      marginLeft: '4%',
                      width: '35px',
                      height: '25px',
                      color: 'white',
                      animation: 'infinite',
                    }}
                  />
                ) : (
                  'Confirmer'
                )}
              </button>
            </div>
          </div>
        );
      default:
        return 'Unknown step';
    }
  }
  getItemRdv(id) {
    const { getAllRDV } = this.props;
    const rdv = getAllRDV.find((element) => element.id === parseInt(id));
    const { initialValues, paymentRdv } = this.state;
    this.setState({
      payment: {
        ...paymentRdv,
        id: rdv.payment ? rdv.payment.id : null,
        orde_virement: rdv.payment ? rdv.payment.orde_virement : '',
        num_cheque: rdv.payment ? rdv.payment.num_cheque : '',
        total_achieved: rdv.payment ? rdv.payment.total_achieved : null,
        already_paid: rdv.payment ? rdv.payment.already_paid : null,
        left_to_pay: rdv.payment ? rdv.payment.left_to_pay : null,
        money_pot_amount: rdv.payment ? rdv.payment.money_pot_amount : null,
        session_price: rdv.payment ? rdv.payment.session_price : null,
        payment_method: rdv.payment ? rdv.payment.payment_method : 'Espèce',
        settled_in_session: rdv.payment ? rdv.payment.settled_in_session : null,
        date: rdv.payment
          ? moment(rdv.payment.date).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
      },
      initialValues: {
        ...initialValues,
        doctor: rdv.practitioner,
        num_cnam: rdv.num_cnam,
        regime: rdv.regime,
        price_with_out_cnam: rdv.motif.price_with_out_cnam,
        price_medical_prescription: rdv.motif.price_medical_prescription,
        consultation: rdv.consultation,
        consultation_id: rdv.consultation_id,
        patient_id: rdv.patient_id,
        id: rdv.id,
        pr_number: rdv.pr_number,
        id_update: rdv.id,
        login_access_id: rdv.login_access_id,
        doctor_id: rdv.login_access_id,
        date_update: moment(rdv.start).format('YYYY-MM-DD'),
        display_name_update: rdv.display_name,
        city_update: rdv.city,
        city: rdv.city,
        status: rdv.status,
        motif_id: rdv.motif.id,
        labelMotif_update: rdv.motif.label === 'Autre' ? rdv.diagnostic : rdv.motif.label,
        phone_number_update: rdv.phone,
        motif_id_update: rdv.motif.id,
        startDate_update: moment(rdv.start).format('YYYY-MM-DD'),
        endDate: rdv.end,
        startHours: moment(rdv.start).format('HH:mm'),
        endHours: moment(rdv.end).format('HH:mm'),
      },
    });
    /* this.props.getDipoDoctorByMotif({
      date: moment(rdv.date_from).format('YYYY-MM-DD'),
      motif_id: rdv.motif.id,
      rdv_id: rdv.id,
    });*/
  }
  getItemUnavailability(id) {
    const { absences } = this.props;
    const unavailability = absences.find((element) => element.id === parseInt(id));
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        name_btn_indispo: 'Modifier',
        header_name_indispo: 'Modifier cette absence',
        id: unavailability.id,
        date_from: moment(unavailability.date_from).format('YYYY-MM-DD'),
        date_to: moment(unavailability.date_to).format('YYYY-MM-DD'),
        note: unavailability.note,
        // endDate: unavailability.date_to,
        hour_from: moment(unavailability.date_from).format('HH:mm'),
        hour_to: moment(unavailability.date_to).format('HH:mm'),
      },
    });
  }
  componentDidMount() {
    this.props.changeDate(
      {
        ...this.props.params,
        date: moment(),
      },
      this.props.user.id,
    );
  }
  handleChangeInputPayment(event) {
    const { payment } = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      payment: {
        ...payment,
        [name]: value,
      },
    });
  }
  gotoPast = (date) => {
    const { userList } = this.state;
    let calendarApi = this.calendarComponentRef.current.getApi();
    this.props.getAllRdv({ ...this.props.params, date });
    this.setState({
      date,
    });
    calendarApi.gotoDate(date); // call a method on the Calendar object
  };
  calendarComponentRef = React.createRef();
  toggleDrawer = (anchor, open, id) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      showRightSideBarRDV: false,
      showRightSideBarRDVUpdate: false,
      switchDuplicateRDV: false,
      left: open,
      anchor: 'left',
      selected_id: id,
      hasError: false,
    });
    this.props.changeActiveItemSideBarRDV(false);
  };
  toggleDrawerUpdate = (anchor, open, id) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      left: open,
      anchor: 'left',
      selected_id: id,
      hasError: false,
      switchList: false,
      switchDuplicateRDV: false,
    });
  };
  handleChangeView(info) {
    this.setState({
      typeView: info.view.type,
    });
  }
  handleShowInput() {
    this.setState({
      showInput: !this.state.showInput,
    });
  }
  handleChange = (event, value) => {
    this.setState({
      value,
    });
  };
  getItemRdvDuplicated(rdv) {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        doctor: rdv.doctor,
        id: rdv.id,
        doctor_id: rdv.doctor.id,
        date: rdv.date_update,
        endDate: rdv.end,
        startHours: rdv.startHours,
        endHours: rdv.endHours,
      },
    });
    // this.props.getDipoDoctorByMotif({
    //   date: moment(rdv.date_from).format('YYYY-MM-DD'),
    //   motif_id: rdv.motif.id,
    //   rdv_id: rdv.id,
    // });
  }
  handleChangeView(info) {
    this.setState({
      typeView: info.view.type,
    });
  }
  render() {
    const {
      getAllRDV,
      params,
      user,
      paramsPatients,
      motifsAgenda,
      isLoadingAddRdv,
      getLastAvailabilityDate,
      isLoadingDuplicateRdv,
      getAllDayDispo,
      isLoadingGetAllDayDispo,
      isLoadingGetLastAvailability,
      loadingAddSessions,
      detailtPyamentRdv,
      isLoadingDetailtPyamentRdv,
      activeItemSideBarRDV,
      medecins,
      addPec,
      updateConsultation,
    } = this.props;
    const {
      payment,
      date,
      typeView,
      showRightSideBarRDV,
      activeRDV,
      initialValues,
      value,
      showInput,
      hasError,
      item,
      showRightSideBarRDVUpdate,
      switchDuplicateRDV,
      statusList,
    } = this.state;

    return (
      <div className="main_content_dashboard" style={{ padding: '10px' }}>
        <section className="section_dashboard">
          <div className="demo-app-top">
            {typeView !== 'dayGridMonth' ? (
              <input
                style={{
                  width: '20%',
                  marginRight: '1%',
                  //borderBottom: errorDate ? '1px solid #ff808b' : '',
                }}
                id="date"
                type="date"
                onChange={(e) => {
                  this.gotoPast(
                    e.target.value ? moment(e.target.value).format('YYYY-MM-DD') : '',
                  );
                }}
              />
            ) : (
              ''
            )}

            <select
              onChange={(e) => {
                this.props.changeType(
                  {
                    ...params,
                    type: e.target.value,
                  },
                  user.id,
                );
              }}
              style={{
                width: '20%',
                marginRight: '1%',
                //borderBottom: errorDate ? '1px solid #ff808b' : '',
              }}
            >
              <option value="all">Choisissez</option>
              <option value="rdv">Rendez-vous</option>
              <option value="absence">Absence</option>
            </select>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-name-label">Statut</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label1"
                id="demo-multiple-checkbox1"
                multiple
                value={params.status}
                onChange={this.handleChangeStatus}
                input={<OutlinedInput label="Statut" />}
                MenuProps={MenuProps}
                renderValue={(selected) => {
                  let status = [];
                  selected.map((item) => {
                    if (item === 'not_confirmed') status.push('Non confirmé, ');
                    if (item === 'actif') status.push('Planifié, ');
                    if (item === 'completed') status.push('Terminé, ');
                    if (item === 'canceled') status.push('Annulé, ');
                  });
                  return status;
                }}
              >
                <MenuItem key={0} value="not_confirmed">
                  <Checkbox checked={params.status.indexOf('not_confirmed') > -1} />
                  <ListItemText primary="Non confirmé" />
                </MenuItem>
                <MenuItem value="actif" key={1}>
                  <Checkbox checked={params.status.indexOf('actif') > -1} />
                  <ListItemText primary="Planifié" />
                </MenuItem>
                <MenuItem value="completed" key={2}>
                  <Checkbox checked={params.status.indexOf('completed') > -1} />
                  <ListItemText primary="Terminé" />
                </MenuItem>
                <MenuItem value="canceled" key={3}>
                  <Checkbox checked={params.status.indexOf('canceled') > -1} />
                  <ListItemText primary="Annulé" />
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="demo-app-calendar">
            <FullCalendar
              viewClassNames={this.handleChangeView}
              firstDay={1}
              forceEventDuration={true}
              droppable={true}
              //nowIndicator
              dateClick={(e) => console.log(e.dateStr)}
              customButtons={{
                today: {
                  text: "Aujourd'hui",
                  click: async () => {
                    this.gotoPast(moment().format('YYYY-MM-DD'));
                  },
                },
                prev: {
                  click: async () => {
                    if (typeView === 'dayGridMonth')
                      this.gotoPast(moment(date).add(-1, 'months').format('YYYY-MM-DD'));
                    if (typeView === 'timeGridWeek')
                      this.gotoPast(moment(date).add(-1, 'weeks').format('YYYY-MM-DD'));
                    if (typeView === 'timeGrid4Day')
                      this.gotoPast(moment(date).add(-4, 'days').format('YYYY-MM-DD'));
                    if (typeView === 'timeGridDay')
                      this.gotoPast(moment(date).add(-1, 'days').format('YYYY-MM-DD'));
                    if (typeView === 'timeGrid4Day')
                      this.gotoPast(moment(date).add(-4, 'days').format('YYYY-MM-DD'));
                  },
                },
                next: {
                  click: () => {
                    if (typeView === 'dayGridMonth')
                      this.gotoPast(moment(date).add(1, 'months').format('YYYY-MM-DD'));
                    if (typeView === 'timeGridWeek')
                      this.gotoPast(moment(date).add(1, 'weeks').format('YYYY-MM-DD'));
                    if (typeView === 'timeGrid4Day')
                      this.gotoPast(moment(date).add(4, 'days').format('YYYY-MM-DD'));
                    if (typeView === 'timeGridDay')
                      this.gotoPast(moment(date).add(1, 'days').format('YYYY-MM-DD'));
                    if (typeView === 'timeGrid4Day')
                      this.gotoPast(moment(date).add(4, 'days').format('YYYY-MM-DD'));
                  },
                },
              }}
              titleFormat={function (date) {
                if (typeView === 'dayGridMonth')
                  return `${moment(date.date).format('MMMM')} ${moment(date.date).format(
                    'YYYY',
                  )}`;
                if (typeView === 'timeGridWeek' || typeView === 'timeGrid4Day')
                  return `De  ${moment(date.start).format('dddd')} ${moment(date.start)
                    .startOf('week')
                    .add(1, 'days')
                    .format('DD')} ${moment(date.date).format('MMMM')} à ${moment(
                    date.date,
                  )
                    .add(-3, 'days')
                    .format('dddd')} ${moment(date.date)
                    .endOf('week')
                    .add(-1, 'days')
                    .format('DD')} ${moment(date.end).format('MMMM')} ${moment(
                    date.end,
                  ).format('YYYY')}`;
                if (typeView === 'timeGridDay')
                  return `${moment(date.date).format('dddd')} ${moment(date.date).format(
                    'DD',
                  )} ${moment(date.date).format('MMMM')} ${moment(date.date).format(
                    'YYYY',
                  )}`;
              }}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGrid4Day,timeGridDay',
              }}
              defaultView="dayGridMonth"
              initialView="timeGridWeek"
              ref={this.calendarComponentRef}
              slotEventOverlap={false}
              weekends={this.state.calendarWeekends}
              eventOrder={(a, b) => {
                var result = 0;
                if (a.sort < b.sort) result = -1;
                //event a should be listed first
                else if (a.sort > b.sort) result = 1; //event b should be listed first
                return result;
              }}
              viewDidMount={(info) => {
                this.setState({ typeView: info.view.type });
              }}
              schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
              selectable={true}
              fixedWeekCount={false}
              //allDay={false}
              plugins={[
                //CustomView,
                dayGridPlugin,
                interactionPlugin,
                timeGridPlugin,
                resourceTimeGridPlugin,
              ]}
              views={{
                timeGrid4Day: {
                  type: 'timeGridWeek',
                  duration: { days: 4, start: 1 },
                  buttonText: '4 jours',
                  startDays: 1,
                },
              }}
              dayMaxEventRows={true}
              eventClick={(event) => {
                if (
                  isHasPermission(
                    user.login_access.permissions,
                    'SHOW_APPOINTMENT_DETAILS',
                  ) &&
                  event.event._def.extendedProps.type === 'rdv'
                ) {
                  if (event.event._def.extendedProps.consultation_id) {
                    this.props.getDetailtPaymentAppointment(
                      event.event._def.extendedProps.consultation_id,
                    );
                  } else this.props.getDetailtPaymentAppointment(null);
                  this.setState({
                    showRightSideBarRDVUpdate: true,
                  });
                  this.getItemRdv(parseInt(event.event._def.publicId));
                }
              }}
              eventContent={EventDetail}
              events={getAllRDV}
              select={(info) => {
                if (
                  isHasPermission(user.login_access.permissions, 'ADD_NEW_APPOINTMENT')
                ) {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      id: null,
                      date: moment(info.startStr).format('YYYY-MM-DD'),
                      startHours: !info.allDay
                        ? moment(info.startStr).format('HH:mm:ss')
                        : '',
                      endHours: !info.allDay
                        ? moment(info.endStr).format('HH:mm:ss')
                        : '',
                    },
                  });
                  this.props.changeSearched({
                    ...paramsPatients,
                    search: null,
                  });
                  this.handleShowRightSideBarRDV();
                }
              }}
              eventLimit={true}
              editable={true}
              eventDrop={async (info) => {
                //<--- see from here
                if (info.event._def.extendedProps.type === 'rdv')
                  if (
                    isHasPermission(
                      user.login_access.permissions,
                      'ADD_NEW_APPOINTMENT',
                    ) &&
                    (info.event._def.extendedProps.status === 'completed' ||
                      info.event._def.extendedProps.status === 'canceled')
                  ) {
                    await this.props.duplicateRDVForm(
                      {
                        ...params,
                        date: moment(info.event._instance.range.start).format(
                          'YYYY-MM-DD',
                        ),
                      },
                      {
                        date: moment(info.event._instance.range.start).format(
                          'YYYY-MM-DD',
                        ),
                        endHours: moment(info.event._instance.range.end)
                          .tz('UTC')
                          .utc()
                          .format('HH:mm'),
                        startHours: moment(info.event._instance.range.start)
                          .tz('UTC')
                          .utc()
                          .format('HH:mm'),
                        id: parseInt(info.event._def.publicId),
                        doctor_id: user.id,
                      },
                    );
                  } else if (
                    isHasPermission(user.login_access.permissions, 'UPDATE_APPOINTMENT')
                  )
                    await this.props.submitRDVForm(
                      {
                        ...params,
                        date: moment(info.event._instance.range.start).format(
                          'YYYY-MM-DD',
                        ),
                      },
                      {
                        date: moment(info.event._instance.range.start).format(
                          'YYYY-MM-DD',
                        ),
                        endHours: moment(info.event._instance.range.end)
                          .tz('UTC')
                          .utc()
                          .format('HH:mm'),
                        startHours: moment(info.event._instance.range.start)
                          .tz('UTC')
                          .utc()
                          .format('HH:mm'),
                        id: parseInt(info.event._def.publicId),
                        doctor_id: user.id,
                      },
                    );
              }}
              locale={frLocale}
              slotMinTime="08:00:00"
              slotMaxTime="20:00:00"
              //timeZone="UTC"
            />
          </div>
        </section>
        {isHasPermission(user.login_access.permissions, 'ADD_NEW_APPOINTMENT') ? (
          <SwipeableDrawerCreate
            toggleDrawer={this.toggleDrawer}
            handleNextItem={this.handleNextItem}
            getStepContent={this.getStepContent}
            handleCityChange={this.handleCityChange}
            handleChangeInput={this.handleChangeInput}
            handleBack={this.handleBack}
            handleShowRightSideBarRDV={this.handleShowRightSideBarRDV}
            handleInitialStateRDV={this.handleInitialStateRDV}
            showRightSideBarRDV={showRightSideBarRDV}
            activeRDV={activeRDV}
            changeActiveItemSideBarRDV={this.props.changeActiveItemSideBarRDV}
            activeItemSideBarRDV={activeItemSideBarRDV}
            item={item}
            initialValues={initialValues}
            motifsAgenda={motifsAgenda}
            getAllDayDispo={getAllDayDispo}
            isLoadingGetAllDayDispo={isLoadingGetAllDayDispo}
            params={params}
            hasError={hasError}
            getLastAvailabilityDate={getLastAvailabilityDate}
            isLoadingGetLastAvailability={isLoadingGetLastAvailability}
            isLoadingAddRdv={isLoadingAddRdv}
            user={user}
          />
        ) : (
          ''
        )}
        {isHasPermission(user.login_access.permissions, 'SHOW_APPOINTMENT_DETAILS') ? (
          <SwipeableDrawerUpdate
            addPec={addPec}
            updateConsultation={updateConsultation}
            loadingAddSessions={loadingAddSessions}
            detailtPyamentRdv={detailtPyamentRdv}
            isLoadingDetailtPyamentRdv={isLoadingDetailtPyamentRdv}
            changeActiveItemSideBarRDV={this.props.changeActiveItemSideBarRDV}
            showRightSideBarRDVUpdate={showRightSideBarRDVUpdate}
            hasError={hasError}
            payment={payment}
            initialValues={initialValues}
            params={params}
            user={user}
            motifsAgenda={motifsAgenda}
            value={value}
            showInput={showInput}
            medecins={medecins}
            toggleDrawer={this.toggleDrawerUpdate}
            handleChangeInput={this.handleChangeInput}
            handleChange={this.handleChange}
            handleShowInput={this.handleShowInput}
            handleBack={this.handleBack}
            handleSwitchList={() =>
              this.setState({ showInput: false, showRightSideBarRDVUpdate: false })
            }
            handleSwitchDuplicateRDV={() =>
              this.setState({
                showRightSideBarRDVUpdate: false,
                switchDuplicateRDV: true,
              })
            }
            handleChangeInputPayment={this.handleChangeInputPayment}
            submitRDVForm={this.props.submitRDVForm}
            submitFormPEC={this.props.submitFormPEC}
            createConsultation={this.props.createConsultation}
            submitPaymentForm={this.props.submitPaymentForm}
            getItemRdvDuplicated={this.getItemRdvDuplicated}
            goToCure={this.goToCure}
          />
        ) : (
          ''
        )}
        {/*<SwipeableDrawerCreateAbsence
          toggleDrawer={this.toggleDrawer}
          handleChangeInput={this.handleChangeInput}
          handleBack={this.handleBack}
          handleSwitchList={() =>
            this.setState({
              switchDuplicateRDV: false,
            })
          }
          handleInitialStateRDV={this.handleInitialStateRDV}
          switchDuplicateRDV={switchDuplicateRDV}
          item={item}
          initialValues={initialValues}
          motifs={motifs}
          getAllDayDispo={getAllDayDispo}
          isLoadingGetAllDayDispo={isLoadingGetAllDayDispo}
          params={params}
          hasError={hasError}
          getLastAvailabilityDate={getLastAvailabilityDate}
          isLoadingGetLastAvailability={isLoadingGetLastAvailability}
          isLoadingDuplicateRdv={isLoadingDuplicateRdv}
          isLoadingAddDispo={isLoadingAddDispo}
          user={user}
          //absenceHours={absenceHours}
          duplicateRDVForm={this.props.duplicateRDVForm}
        />*/}
        <SwipeableDrawerCreateDuplicate
          toggleDrawer={this.toggleDrawer}
          handleChangeInput={this.handleChangeInput}
          handleBack={this.handleBack}
          handleSwitchList={() =>
            this.setState({
              switchDuplicateRDV: false,
            })
          }
          handleInitialStateRDV={this.handleInitialStateRDV}
          switchDuplicateRDV={switchDuplicateRDV}
          item={item}
          initialValues={initialValues}
          motifsAgenda={motifsAgenda}
          getAllDayDispo={getAllDayDispo}
          isLoadingGetAllDayDispo={isLoadingGetAllDayDispo}
          params={params}
          hasError={hasError}
          getLastAvailabilityDate={getLastAvailabilityDate}
          isLoadingGetLastAvailability={isLoadingGetLastAvailability}
          isLoadingDuplicateRdv={isLoadingDuplicateRdv}
          user={user}
          //absenceHours={absenceHours}
          duplicateRDVForm={this.props.duplicateRDVForm}
        />
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Agenda);
