import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from './store/actions';

import '../profile/Abonnement/index.css';
import '../profile/DiplomeFormation/index.css';
import '../profile/InfoGenerale/index.css';
import '../profile/Notifications/index.css';
import '../profile/Securities/index.css';
import '../profile/index.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import actionsJs from '../../js/actions';
import Modals from '../../components/modal';

const mapStateToProps = (state) => ({
  credits: state.CreditReducer.credits,
  isLoadingCredits: state.CreditReducer.isLoadingCredits,
  params: state.CreditReducer.params,
  isLoadingDestroyCredit: state.CreditReducer.isLoadingDestroyCredit,
  sumCredit: state.CreditReducer.sumCredit,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCredits: (params) => dispatch(actions.getAllCredits(params)),
  deleteCredit: async (id, params) => {
    await dispatch(actions.deleteCredit(id));
    dispatch(actions.getAllCredits(params));
  },
  submitCreditForm: async (id, params) => {
    await dispatch(actions.submitCreditForm(id));
    dispatch(actions.getAllCredits(params));
  },
  changeActiveItemSideBarSettings: (item, data, create) =>
    dispatch(actionsJs.changeActiveItemSideBarSettings(item, data, create)),
  changeSearched: async (params) => {
    await dispatch(actions.changeSearchedStringCredits(params));
    dispatch(actions.getAllCredits(params));
  },
  changeSearchedStatus: async (params) => {
    await dispatch(actions.changeSearchedStringCredits(params));
    dispatch(actions.getAllCredits(params));
  },
  changeSearchedEndDate: async (params) => {
    await dispatch(actions.changeSearchedStringCredits(params));
    dispatch(actions.getAllCredits(params));
  },
  changeSearchedStartDate: async (params) => {
    await dispatch(actions.changeSearchedStringCredits(params));
    dispatch(actions.getAllCredits(params));
  },
});

class Credits extends Component {
  constructor(props) {
    super(props);
    props.changeNamePage('Crédits');
    props.changeActiveItemSideBarCompta(1023);
    document.title = 'Crédits';
    this.state = {
      setIsOpenDeleteModal: false,
      selected_id: null,
      status: null,
      errorDate: false,
    };
    this.openModal = this.openModal.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
  }
  openDeleteModal() {
    this.setState({
      setIsOpenDeleteModal: !this.state.setIsOpenDeleteModal,
    });
  }
  openModal() {
    this.setState({
      setIsOpen: !this.state.setIsOpen,
    });
  }
  componentDidMount() {
    this.props.changeSearched({
      ...this.props.params,
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      search: '',
      status: '',
    });
  }
  render() {
    const { credits, sumCredit, isLoadingCredits, params } = this.props;
    const { setIsOpenDeleteModal, errorDate, selected_id, setIsOpen, status } =
      this.state;
    return (
      <div className="main_content" style={{ paddingLeft: 0 }}>
        <div className="user_settings">
          <div className="container_changeable_content">
            <div className="facture_patient_header" style={{ display: 'table' }}>
              <p>Rechercher un crédit</p>
              <input
                style={{ width: '20%', margin: '0px 20px' }}
                onChange={(event) => {
                  this.props.changeSearched({
                    ...params,
                    search: event.target.value ? event.target.value : '',
                  });
                }}
                type="text"
                placeholder={'Nom prénom patient'}
              />
              <select
                style={{ width: '20%', margin: '0px 20px' }}
                id="inputState"
                name="status"
                value={params.status}
                onChange={(event, option) => {
                  this.props.changeSearchedStatus({
                    ...params,
                    status: event.target.value !== 'Statut' ? event.target.value : null,
                  });
                }}
              >
                <option selected value="">
                  Statut
                </option>
                <option value={0}>Non payé</option>
                <option value={1}>Payé</option>
              </select>
              <input
                style={{
                  width: '20%',
                  margin: '0px 20px',
                  borderBottom: errorDate ? '1px solid #ff808b' : '',
                }}
                id="date"
                type="date"
                value={
                  params.start_date ? moment(params.start_date).format('YYYY-MM-DD') : ''
                }
                onChange={(e) => {
                  this.props.changeSearchedStartDate({
                    ...params,
                    start_date: e.target.value
                      ? moment(e.target.value).format('YYYY-MM-DD')
                      : '',
                  });
                }}
              />
              <input
                style={{
                  width: '20%',
                  margin: '0px 20px',
                  borderBottom: errorDate ? '1px solid #ff808b' : '',
                }}
                id="date"
                type="date"
                className="type3"
                value={
                  params.end_date ? moment(params.end_date).format('YYYY-MM-DD') : ''
                }
                onChange={(e) => {
                  if (moment(params.start_date).isAfter(moment(e.target.value))) {
                    this.setState({
                      errorDate: true,
                    });
                    this.props.changeSearchedEndDate({
                      ...params,
                      end_date: e.target.value
                        ? moment(e.target.value).format('YYYY-MM-DD')
                        : '',
                    });
                  } else {
                    this.setState({
                      errorDate: false,
                    });
                    this.props.changeSearchedEndDate({
                      ...params,
                      end_date: e.target.value
                        ? moment(e.target.value).format('YYYY-MM-DD')
                        : '',
                    });
                  }
                }}
              />
              <br />
              {sumCredit ? (
                <input
                  disabled={true}
                  style={{
                    width: '20%',
                    margin: '0px 20px',
                    borderBottom: errorDate ? '1px solid #ff808b' : '',
                  }}
                  className="type3"
                  value={`Total : ${sumCredit}`}
                />
              ) : (
                ''
              )}
            </div>
            {isLoadingCredits ? (
              <CircularProgress
                disableShrink
                style={{ marginLeft: '45%', marginTop: '23%' }}
              />
            ) : (
              <div
                className="main_content"
                style={{
                  overflow: 'hidden',
                  width: '100%',
                  padding: '5%',
                  height: 'auto',
                }}
              >
                <div
                  className="general_info"
                  style={{ display: 'contents', width: '87%', height: '100%' }}
                >
                  <div className="contact_patients_body" style={{ height: '100%' }}>
                    {/* <span className="patients_consultations_record_label">Dépenses</span> */}

                    {credits && credits.length > 0 ? (
                      credits.map((item) => {
                        return (
                          <div
                            key={item.id}
                            className="rendez_vous_list_body_item_container"
                          >
                            <div className="rendez_vous_list_body_item">
                              <div
                                className="rv_list_body_sub_item"
                                id="time_container_indis"
                              >
                                <i className="far fa-clock"></i>
                                <span id="start_time">
                                  {' '}
                                  {moment(item.date).format('YYYY-MM-DD')}
                                </span>
                              </div>
                              <div
                                className={
                                  item.status === 1
                                    ? 'rv_list_body_sub_item btn_status'
                                    : 'rv_list_body_sub_item btn_status actif_status'
                                }
                              >
                                <span>{item.status === 1 ? 'Payé' : 'Non payé'}</span>
                              </div>
                              <div className="rv_list_body_sub_item rv_details">
                                <div className="rv_text_details">
                                  <div className="patient_consultation_text">
                                    <span id="type_consultation">
                                      {item.patient
                                        ? `${item.patient.first_name} ${item.patient.last_name}`
                                        : ''}
                                    </span>
                                    <span id="patient_name">{item.amount}DT</span>
                                  </div>
                                </div>
                              </div>
                              <div className="rv_more_details">
                                <div className="expanded_more_details">
                                  <i
                                    title="Voir detail"
                                    onClick={() => {
                                      this.setState({
                                        selected_id: item.id,
                                        status: item.status,
                                      });
                                      this.openModal();
                                    }}
                                    id="expanded_more_details_view"
                                    className="fas fa-edit"
                                  ></i>
                                  <i
                                    title="Supprimer rdv"
                                    onClick={() => {
                                      this.setState({
                                        selected_id: item.id,
                                      });
                                      this.openDeleteModal();
                                    }}
                                    id="expanded_more_details_delete"
                                    className="fas fa-trash"
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div
                        style={{ height: 'calc(50vh - 64px)' }}
                        className="consultation_detailed_info_section"
                      >
                        <div className="empty_consultation_result_container">
                          <div className="empty_consultation_result">
                            <img
                              src={require('../../assets/img/undraw_design_team_af2y.svg')}
                            />
                            <span id="no_consultation">Liste vide !</span>
                            <span id="no_consultation_desc">
                              Aucune crédit n'a été enregistrée aujourd'hui. Essayer d'en
                              ajouter une.
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Modals
          setIsOpen={setIsOpen}
          openModal={this.openModal}
          data={
            <>
              <div className="modal-header" style={{ marginTop: '-30px' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModal()}
                  style={{ marginTop: '1%' }}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '5px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Crédit!</h2>
                <span>
                  Marquer le crédit comme{' '}
                  <strong>{status === 1 ? 'Non payé' : 'Payé'}</strong>
                </span>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => this.openModal()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                  style={{ backgroundColor: '#f6f6f6' }}
                >
                  Non
                </button>
                <button
                  onClick={() => {
                    this.props.submitCreditForm(
                      {
                        id: selected_id,
                        status: status ? 0 : 1,
                      },
                      params,
                    );
                    this.openModal();
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
        <Modals
          setIsOpen={setIsOpenDeleteModal}
          openModal={this.openDeleteModal}
          data={
            <>
              <div className="modal-header" style={{ marginTop: '-9px' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openDeleteModal()}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '13px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Suppression!</h2>
                <span>Etes-vous sûr de vouloir supprimer cette crédit ?</span>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => this.openDeleteModal()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                  style={{ backgroundColor: '#f6f6f6' }}
                >
                  Non
                </button>
                <button
                  onClick={async () => {
                    await this.props.deleteCredit(selected_id, params);
                    this.openDeleteModal();
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Credits);
