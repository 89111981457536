import { apiCall } from '../../../js/actions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastAction from '../../../components/ToastAction';
import React from 'react';
import { getStatusError } from '../../../utils/helpres';
import moment from 'moment';

export const GET_MOTIF_List_SUCCESS = 'GET_MOTIF_List_SUCCESS';

const getDispoDoctor = (values, type) => async (dispatch) => {
  const {
    practitioner_id,
    hourStart,
    duration,
    start_date,
    sessions_per_week,
    patient_id,
    sessions_count,
    consultation_id,
  } = values;
  dispatch({ type: 'GET_DISPONIBILITY_DOCTOR_REQUESTING' });
  try {
    const nbrWeeks = (sessions_count / sessions_per_week) * 7;
    const { data } = await apiCall({
      method: 'post',
      url: `/api/getdipsodoctor/sessions`,
      data: {
        hourStart: moment(
          moment(`${start_date} ${hourStart}`).format('YYYY-MM-DD HH:mm:ss'),
        )
          .tz('UTC')
          .utc()
          .format('HH:mm'),
        duration,
        start_date: moment(moment(start_date).format('YYYY-MM-DDT08:00:00.000Z'))
          .tz('UTC')
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(
          moment(start_date)
            .add(nbrWeeks + 7, 'days')
            .format('YYYY-MM-DDT19:30:00.000Z'),
        )
          .tz('UTC')
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        type: values.type,
        sessions_per_week: parseInt(sessions_per_week),
        practitioner_id: parseInt(practitioner_id),
        consultation_id: parseInt(consultation_id),
        patient_id: parseInt(patient_id),
        sessions_count: type ? parseInt(sessions_per_week) : parseInt(sessions_count),
        hours08: moment(`${moment(start_date).format('YYYY/MM/DD')} 08:00:00`)
          .tz('UTC')
          .utc()
          .format('HH'),
        hours20: moment(`${moment(start_date).format('YYYY/MM/DD')} 20:00:00`)
          .tz('UTC')
          .utc()
          .format('HH'),
      },
    });

    dispatch({
      type: 'GET_DISPONIBILITY_DOCTOR__SUCCESS',
      data: data,
    });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: 'GET_DISPONIBILITY_DOCTOR__FAILURE',
      //msg: response.data.message,
      ///err: response.data.type,
    });
  }
};
const getSessionsVirtualPec = (values) => async (dispatch) => {
  const {
    practitioner_id,
    hourStart,
    duration,
    start_date,
    sessions_per_week,
    patient_id,
    sessions_count,
    consultation_id,
  } = values;
  dispatch({ type: 'GET_VIRTUAL_PEC_REQUESTING' });
  try {
    const nbrWeeks = (sessions_count / sessions_per_week) * 7;
    const { data } = await apiCall({
      method: 'post',
      url: `/api/get/virtual-pec/sessions`,
      data: {
        consultation_id,
        hourStart,
        duration,
        start_date: moment(moment(start_date).format('YYYY-MM-DDT08:00:00.000Z'))
          .tz('UTC')
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(
          moment(start_date)
            .add(nbrWeeks + 7, 'days')
            .format('YYYY-MM-DDT19:30:00.000Z'),
        )
          .tz('UTC')
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        sessions_per_week: parseInt(sessions_per_week),
        practitioner_id: parseInt(practitioner_id),
        patient_id: parseInt(patient_id),
        sessions_count: parseInt(sessions_count),
        hours08: moment(`${moment(start_date).format('YYYY/MM/DD')} 08:00:00`)
          .tz('UTC')
          .utc()
          .format('HH'),
        hours20: moment(`${moment(start_date).format('YYYY/MM/DD')} 20:00:00`)
          .tz('UTC')
          .utc()
          .format('HH'),
      },
    });
    dispatch({
      type: 'GET_VIRTUAL_PEC_SUCCESS',
      data: data,
    });
  } catch (e) {
    const { response } = e;
    dispatch({
      type: 'GET_VIRTUAL_PEC_FAILURE',
      //msg: response.data.message,
      //err: response.data.type,
    });
  }
};

const getPatientConsultations = (params, prid) => async (dispatch) => {
  dispatch({ type: 'GET_CONSULTATION_PATIENT_RECORD_REQUESTING' });
  const { currentPage } = params || {
    currentPage: 1,
  };
  const { startDate } = params || {
    startDate: 1,
  };
  const { limit } = params || {
    limit: '',
  };
  try {
    let url = `/api/consultations/patient_record/${prid}?limit=${limit}&current_page=${currentPage}`;
    if (startDate && startDate !== '') url += `&date=${startDate}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_CONSULTATION_PATIENT_RECORD_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_CONSULTATION_PATIENT_RECORD_FAILURE',
      err: e.message,
    });
  }
};

const getConsultations = (params) => async (dispatch) => {
  dispatch({ type: 'GET_CONSULTATION_DOCTOR_LIST_REQUESTING' });
  try {
    const { search } = params || {
      search: '',
    };
    const { endDate } = params || {
      endDate: '',
    };
    const { startDate } = params || {
      startDate: '',
    };
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    let url = `/api/consultations-practitioner?limit=${limit}&current_page=${currentPage}`;
    if (search && search !== '') url += `&search=${search}`;
    if (startDate && startDate !== '')
      url += `&startDate=${moment(startDate)
        .tz('UTC')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')}`;
    if (endDate && endDate !== '')
      url += `&endDate=${moment(endDate)
        .tz('UTC')
        .utc()
        .add(1, 'days')
        .format('YYYY-MM-DD HH:mm:ss')}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_CONSULTATION_DOCTOR_LIST_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_CONSULTATION_DOCTOR_LIST_FAILURE',
      err: e.message,
    });
  }
};

const getConsultationById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_CONSULTATION_REQUESTING' });
  try {
    let url = `/api/consultation/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_CONSULTATION_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_CONSULTATION_FAILURE',
      err: e.message,
    });
  }
};
const deletePEC = (id) => async (dispatch) => {
  dispatch({ type: 'DELETE_PEC_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/pec/${id}`,
    });
    if (data.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="PEC supprimée avec succès" />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`File a été supprimée `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'DELETE_PEC_SUCCESS',
        ErrorMessage: data.message,
        data: true,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'DELETE_PEC_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec de suppression du PEC" />
      ),
      {
        hideProgressBar: true,
      },
    );
    //.error(`File non supprimée`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'DELETE_PEC_FAILURE',
      err: e.message,
    });
  }
};

const deleteFile = (id) => async (dispatch) => {
  dispatch({ type: 'DELETE_File_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/delete-file/${id}`,
    });
    if (data.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Document supprimé avec succès" />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`File a été supprimée `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'DELETE_FILE_SUCCESS',
        ErrorMessage: data.message,
        data: true,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'DELETE_FILE_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec de suppression du document" />
      ),
      {
        hideProgressBar: true,
      },
    );
    //.error(`File non supprimée`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'DELETE_FILE_FAILURE',
      err: e.message,
    });
  }
};

const addConsultation = (values, initialValuesMedecin) => async (dispatch) => {
  dispatch({ type: 'UPDATE_CONSULTATION_REQUESTING' });
  const { hourStart, start_date, sessions_per_week, sessions_count } = values;
  try {
    const nbrWeeks = (sessions_count / sessions_per_week) * 7;
    const inputs = {
      rdv_id: values.rdv_id,
      patient_id: values.patient_id,
      motif_id: values.motif_id,
      duration: values.duration,
      note: values.note,
      created_at: moment(moment(`${values.date} ${moment().format('HH:mm:ss')}`))
        /*.tz('UTC')
        .utc()*/
        .format('YYYY-MM-DD HH:mm:ss'),
      doctor:
        initialValuesMedecin && initialValuesMedecin.newMedecin
          ? `${initialValuesMedecin.first_name} ${initialValuesMedecin.last_name}`
          : values.doctor,
      diagnostic: values.diagnostic,
      sessions_count: values.sessions_count,
      sessions_per_week: values.sessions_per_week,
      regime: values.regime,
      unit_price: values.unit_price ? values.unit_price : null,
      start_date: moment(moment(`${values.date} ${moment().format('HH:mm:ss')}`))
        .tz('UTC')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(
        moment(values.date)
          .add(nbrWeeks + 7, 'days')
          .format('YYYY-MM-DDT19:30:00.000Z'),
      )
        .tz('UTC')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
    };
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/consultation/${values.id || ''}`,
      data: inputs,
    });
    if (data.type === 'success') {
      if (initialValuesMedecin && initialValuesMedecin.newMedecin) {
        await apiCall({
          method: 'post',
          url: `/api/prescribing-practitioner/create`,
          data: {
            first_name: initialValuesMedecin.first_name,
            last_name: initialValuesMedecin.last_name,
            phone: initialValuesMedecin.phone,
            speciality: initialValuesMedecin.speciality,
          },
        });
      }
      if (!values.id) {
        await apiCall({
          method: 'post',
          url: `/api/pec`,
          data: {
            start_date: moment(moment(values.date).format('YYYY-MM-DDT08:00:00.000Z'))
              .tz('UTC')
              .utc()
              .format('YYYY-MM-DD HH:mm:ss'),
            end_date: moment(
              moment(values.date)
                .add(nbrWeeks + 7, 'days')
                .format('YYYY-MM-DDT19:30:00.000Z'),
            )
              .tz('UTC')
              .utc()
              .format('YYYY-MM-DD HH:mm:ss'),
            sessions_per_week: parseInt(sessions_per_week),
            regime: values.regime,
            sessions_count: parseInt(sessions_count),
            consultation_id: data.data.id,
            unit_price: values.unit_price ? values.unit_price : null,
          },
        });
      }

      if (values.file) {
        const file = values.file;
        //for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('consultation_id', values.id);
        await apiCall({
          method: 'post',
          url: `/api/upload-document`,
          data: formData,
        });
        //}
      }
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={`Cure ${
              !values.type
                ? values.id
                  ? 'modifiée avec succès'
                  : 'créée avec succès'
                : ''
            } `}
          />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`Consultation a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_CONSULTATION_SUCCESS',
        ErrorMessage: data.message,
        data: data.data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
      //toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_CONSULTATION_FAILURE',
        ErrorMessage: data.message,
        err: true,
        data: values,
      });
    }
  } catch (e) {
    console.log(e);
    const { response } = e;
    if (response && response.data) {
      toast(
        ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
        { hideProgressBar: true },
      );
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="error"
            message={`Échec de ${
              !values.type
                ? values.id
                  ? 'modification de cure'
                  : 'création de cure'
                : ''
            } `}
          />
        ),
        { hideProgressBar: true },
      );
    }
    // toast.error(`Consultation non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'UPDATE_CONSULTATION_FAILURE',
      err: e.message,
    });
  }
};

const uploadFIleConsultation = (values) => async (dispatch) => {
  dispatch({ type: 'UPLOAD_FILES_CONSULTATION_REQUESTING' });
  try {
    const file = values.file;
    //for (let i = 0; i < files.length; i++) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('consultation_id', values.id);
    const { data } = await apiCall({
      method: 'post',
      url: `/api/upload-document`,
      data: formData,
    });
    toast(
      ({ closeToast }) => (
        <ToastAction type="success" message="Document ajouté avec succès" />
      ),
      { hideProgressBar: true },
    );
    //toast.success(`Consultation a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'UPLOAD_FILES_CONSULTATION_SUCCESS',
      ErrorMessage: data.message,
      data: data.data,
    });
  } catch (e) {
    const { response } = e;
    if (response && response.data) {
      toast(
        ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
        { hideProgressBar: true },
      );
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction type="error" message="Échec d'ajout de document" />
        ),
        {
          hideProgressBar: true,
        },
      );
    }
    // toast.error(`Consultation non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'UPLOAD_FILES_CONSULTATION_FAILURE',
      err: e.message,
    });
  }
};

const getMotifs = () => async (dispatch) => {
  dispatch({ type: 'GET_MOTIFS_REQUESTING' });
  try {
    let url = `/api/motifs`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: GET_MOTIF_List_SUCCESS,
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_MOTIF_List_FAILURE',
      err: e.message,
    });
  }
};

const submitFormPEC = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_PEC_REQUESTING' });
  try {
    const val = {
      //number: values.num_pec,
      quality: values.quality,
      //consultation_id: values.consultation_id,
      num_order: values.num_order,
      year: values.year,
      code_bureau: values.code_bureau,
      sessions_per_week: values.sessions_per_week,
      sessions_count: values.sessions_count,
      unit_price: values.unit_price,
      start_date: moment(values.pecStartDate).format('YYYY-MM-DD 08:00:00'),
      end_date: moment(values.pecEndDate).format('YYYY-MM-DD 08:00:00'),
      regime: values.regime,
    };
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/pec/${values.id || ''}`,
      data: val,
    });
    if (data.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message={`Opération effectuée avec succès`} />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`Consultation a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_PEC_SUCCESS',
        ErrorMessage: data.message,
        data: data.data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
      //toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_PEC_FAILURE',
        ErrorMessage: data.message,
        err: true,
        data: values,
      });
    }
  } catch (e) {
    let message = `Opération non effectuée`;
    if (e && e.response)
      if (e.response && e.response.data) message = e.response.data.message;
    toast(({ closeToast }) => <ToastAction type="error" message={message} />, {
      hideProgressBar: true,
    });
    // toast.error(`Consultation non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'UPDATE_PEC_FAILURE',
      err: e.message,
    });
  }
};

const submitFormCure = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_CURE_REQUESTING' });
  console.log('VALUES => ', values);
  try {
    const valCure = {
      sessions_per_week: values.sessions_per_week,
      sessions_count: values.sessions_count,
      unit_price: values.unit_price,
    };
    let valPec = {
      regime: values.regime,
    };
    if (values.id) {
      await apiCall({
        method: 'patch',
        url: `/api/pec/${values.pecID}`,
        data: valPec,
      });
    }

    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/consultation/${values.id || ''}`,
      data: valCure,
    });
    console.log(data);
    if (data.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message={`Opération effectuée avec succès`} />
        ),
        { hideProgressBar: true },
      );
      dispatch({
        type: 'UPDATE_CURE_SUCCESS',
        ErrorMessage: data.message,
        data: data.data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
      dispatch({
        type: 'UPDATE_CURE_FAILURE',
        ErrorMessage: data.message,
        err: true,
        data: values,
      });
    }
  } catch (e) {
    console.log(e);
    let message = `Opération non effectuée`;
    if (e && e.response)
      if (e.response && e.response.data) message = e.response.data.message;
    toast(({ closeToast }) => <ToastAction type="error" message={message} />, {
      hideProgressBar: true,
    });
    dispatch({
      type: 'UPDATE_CURE_FAILURE',
      err: e.message,
    });
  }
};

const submitFormSession = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_SESSION_REQUESTING' });
  try {
    const val = {
      motif_id: values.motif_id,
      diagnostic: values.diagnostic,
      doctor: values.doctor,
      patient_record_id: values.patient_record_id,
      created_at: moment(moment(`${values.start_date} ${moment().format('HH:mm:ss')}`))
        .tz('UTC')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss'),
      labelMotif: values.labelMotif,
      regime: values.regime,
      practitioner_id: values.practitioner_id,
      patient_id: values.patient_id,
      quality: values.quality,
      sessions_count: values.sessions_count,
      sessions_per_week: values.sessions_per_week,
      unit_price: values.unit_price,
      end_date: moment(values.end_date).tz('UTC').utc().format('YYYY-MM-DD HH:mm:ss'),
      start_date: moment(values.start_date).tz('UTC').utc().format('YYYY-MM-DD HH:mm:ss'),
      consultation_id: values.consultation_id,
      getDispo: values.getDispo.slice().sort((a, b) => b.date - a.date),
      num_order: values.num_order,
      year: values.year,
      duration: values.duration,
      code_bureau: values.code_bureau,
    };
    const { data } = await apiCall({
      method: 'post',
      url: `/api/session`,
      data: val,
    });
    if (data.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Séances créées avec succès" />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`Consultation a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_SESSION_SUCCESS',
        ErrorMessage: data.message,
        data: data.data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
      //toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_SESSION_FAILURE',
        ErrorMessage: data.message,
        err: true,
        data: values,
      });
    }
  } catch (e) {
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec de création des séances" />
      ),
      { hideProgressBar: true },
    );
    // toast.error(`Consultation non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'UPDATE_SESSION_FAILURE',
      err: e.message,
    });
  }
};
const submitAgendaManuel = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_AGENDA_MANUEL_REQUESTING' });
  try {
    const val = {
      practitioner_id: values.practitioner_id,
      patient_id: values.patient_id,
      consultation_id: values.consultation_id,
      getDispo: values.getDispo.slice().sort((a, b) => b.date - a.date),
    };
    const { data } = await apiCall({
      method: 'post',
      url: `/api/agenda-manuel`,
      data: val,
    });
    if (data.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Séances créées avec succès" />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`Consultation a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_AGENDA_MANUEL_SUCCESS',
        ErrorMessage: data.message,
        data: data.data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
      //toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_AGENDA_MANUEL_FAILURE',
        ErrorMessage: data.message,
        err: true,
        data: values,
      });
    }
  } catch (e) {
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec de création des séances" />
      ),
      { hideProgressBar: true },
    );
    // toast.error(`Consultation non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'UPDATE_AGENDA_MANUEL_FAILURE',
      err: e.message,
    });
  }
};

const submitFormUpdateSession = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_SESSION_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'patch',
      url: `/api/session`,
      data: values,
    });
    if (data.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Séance modifiée avec succès" />
        ),
        { hideProgresssBar: true },
      );
      //toast.success(`Consultation a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_SESSION_SUCCESS',
        ErrorMessage: data.message,
        data: data.data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
      //toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_SESSION_FAILURE',
        ErrorMessage: data.message,
        err: true,
        data: values,
      });
    }
  } catch (e) {
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec de modification de séance" />
      ),
      {
        hideProgressBar: true,
      },
    );
    // toast.error(`Consultation non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'UPDATE_SESSION_FAILURE',
      err: e.message,
    });
  }
};

const changeSearchedString = (params) => ({
  type: 'UPDATE_SEARCH_CONSULTATION_PARAMS',
  params: params,
});

const changeSearchedStringBilan = (params) => ({
  type: 'UPDATE_SEARCH_BILAN_CONSULTATION_PARAMS',
  paramsBilan: params,
});

const initialState = () => ({
  type: 'INITIAL_STATE_CONSULTATION',
});

const getSessionByConsultation = (id) => async (dispatch) => {
  dispatch({ type: 'GET_SESSION_CONSULTATION_REQUESTING' });
  try {
    let url = `/api/sessions/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_SESSION_CONSULTATION_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_SESSION_CONSULTATION_FAILURE',
      err: e.message,
    });
  }
};

const getBilanByConsultationId = (params, id) => async (dispatch) => {
  dispatch({ type: 'GET_BILAN_BY_CONSULTATION_REQUESTING' });
  try {
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    const { search } = params || {
      search: '',
    };
    let url = `/api/bilan/consultation/${id}?limit=${limit}&current_page=${currentPage}`;
    if (search && search !== '') url += `&search=${search}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_BILAN_BY_CONSULTATION_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_BILAN_BY_CONSULTATION_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getProtocoleByConsultationId = (paramsProtocole, id) => async (dispatch) => {
  dispatch({ type: 'GET_PROTOCOLE_BY_CONSULTATION_REQUESTING', paramsProtocole });
  try {
    const { currentPage } = paramsProtocole || {
      currentPage: 1,
    };
    const { limit } = paramsProtocole || {
      limit: '',
    };
    const { search } = paramsProtocole || {
      search: '',
    };
    let url = `/api/protocole/consultation/${id}?limit=${limit}&current_page=${currentPage}`;
    if (search && search !== '') url += `&search=${search}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_PROTOCOLE_BY_CONSULTATION_SUCCESS',
      data: data.data,
      paramsProtocole,
    });
  } catch (e) {
    dispatch({
      type: 'GET_PROTOCOLE_BY_CONSULTATION_FAILURE',
      err: e.message,
      status: getStatusError(e),
      paramsProtocole,
    });
  }
};
const changeSearchedStringProtocole = (paramsProtocole) => ({
  type: 'UPDATE_SEARCH_PROTOCOLE_CONSULTATION_PARAMS',
  paramsProtocole,
});

const submitFormProtocole = (values) => async (dispatch) => {
  dispatch({ type: 'SUBMIT_FORM_PROTOCOLE_REQUESTING' });
  try {
    let url = `/api/upload/file/protocole`;
    let formData = new FormData();
    if (parseInt(values.protocole_id)) {
      formData.append('protocole_id', parseInt(values.protocole_id));
    }
    formData.append('name', values.initialValues.name);
    formData.append('consultation_id', parseInt(values.consultation_id));

    for (var key in values.initialValues) {
      if (key !== 'name' && key !== 'consultation_id' && key !== 'protocole_id') {
        formData.append(
          key,
          Array.isArray(values.initialValues[key]) &&
            typeof values.initialValues[key] !== 'string'
            ? JSON.stringify(values.initialValues[key])
            : values.initialValues[key],
        );
      }
    }
    if (values.initialValues.repetitions_series.length > 0) {
      let repetitions_series = values.initialValues.repetitions_series;

      for (let i = 0; i < repetitions_series.length; i++) {
        if (
          repetitions_series[i][`Consignes_file_${repetitions_series[i].id}`] &&
          repetitions_series[i][`Consignes_file_${repetitions_series[i].id}`].name
        ) {
          formData.append(
            `exercice_${repetitions_series[i].id}`,
            repetitions_series[i][`Consignes_file_${repetitions_series[i].id}`],
          );
        }
      }
    }
    const { data } = await apiCall({
      method: `post`,
      url,
      data: formData,
    });
    toast(
      ({ closeToast }) => (
        <ToastAction type="success" message="Protocole  créé avec succès" />
      ),
      { hideProgressBar: true },
    );
    dispatch({
      type: 'SUBMIT_FORM_PROTOCOLE_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec de création de protocole" />
      ),
      { hideProgressBar: true },
    );
    dispatch({
      type: 'SUBMIT_FORM_PROTOCOLE_FAILURE',
      err: e.message,
    });
  }
};

const getSectionProtocoleFile = (bilan_id, consultation_id) => async (dispatch) => {
  dispatch({ type: 'GET_SECTION_PROTOCOLE_REQUESTING' });
  try {
    let url = `/api/file/protocole/${bilan_id}/${consultation_id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_SECTION_PROTOCOLE_LIST_SUCCESS',
      data: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_SECTION_PROTOCOLE_LIST_FAILURE',
      err: e.message,
    });
  }
};
const getBillByConsultationId = (params, id) => async (dispatch) => {
  dispatch({ type: 'GET_BILL_BY_CONSULTATION_REQUESTING' });
  try {
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    const { search } = params || {
      search: '',
    };
    let url = `/api/bill/consultation/${id}?limit=${limit}&current_page=${currentPage}`;
    if (search && search !== '') url += `&search=${search}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_BILL_BY_CONSULTATION_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_BILL_BY_CONSULTATION_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const changeSearchedStringBill = (params) => ({
  type: 'UPDATE_SEARCH_BILL_CONSULTATION_PARAMS',
  paramsBilan: params,
});
const getReceiptByConsultationId = (params, id) => async (dispatch) => {
  dispatch({ type: 'GET_RECEIPT_BY_CONSULTATION_REQUESTING' });
  try {
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    const { search } = params || {
      search: '',
    };
    let url = `/api/receipt/consultation/${id}?limit=${limit}&current_page=${currentPage}`;
    if (search && search !== '') url += `&search=${search}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_RECEIPT_BY_CONSULTATION_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_RECEIPT_BY_CONSULTATION_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const changeSearchedStringReceipt = (params) => ({
  type: 'UPDATE_SEARCH_RECEIPT_CONSULTATION_PARAMS',
  paramsBilan: params,
});
const getAllPec = (params) => async (dispatch) => {
  dispatch({ type: 'GET_ALL_PEC_REQUESTING' });
  try {
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { status } = params || {
      status: '',
    };
    const { limit } = params || {
      limit: '',
    };
    const { search } = params || {
      search: '',
    };
    const { end_date } = params || {
      end_date: '',
    };
    const { start_date } = params || {
      start_date: '',
    };
    let url = `/api/pec?limit=${limit}&current_page=${currentPage}`;
    if (search && search !== '') url += `&search=${search}`;
    if (status && status !== '') url += `&status=${status}`;
    if (start_date && start_date !== '')
      url += `&start_date=${moment(start_date)
        .tz('UTC')
        .utc()
        .add(1, 'days')
        .format('YYYY-MM-DD 00:00:00')}`;
    if (end_date && end_date !== '')
      url += `&end_date=${moment(end_date)
        .tz('UTC')
        .utc()
        .add(1, 'days')
        .format('YYYY-MM-DD HH:mm:ss')}`;

    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_ALL_PEC_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_ALL_PEC_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const changeSearchedStringPec = (params) => ({
  type: 'UPDATE_SEARCH_ALL_PEC_PARAMS',
  paramsPec: params,
});

const closeTheSessions = (id) => async (dispatch) => {
  dispatch({ type: 'CLOSE_SESSION_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'patch',
      url: `/api/session/${id}`,
    });
    if (data.type === 'success') {
      toast(
        ({ closeToast }) => <ToastAction type="success" message="Clôturer les séances" />,
        { hideProgressBar: true },
      );
      //toast.success(`Consultation a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'CLOSE_SESSION_SUCCESS',
        ErrorMessage: data.message,
        data: true,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="error" message={data.message} />, {
        hideProgressBar: true,
      });
      //toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'CLOSE_SESSION_FAILURE',
        ErrorMessage: data.message,
        err: true,
      });
    }
  } catch (e) {
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec de clôturer les séances" />
      ),
      { hideProgressBar: true },
    );
    // toast.error(`Consultation non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'CLOSE_SESSION_FAILURE',
      err: e.message,
    });
  }
};
const getPatientSessions = (id) => async (dispatch) => {
  dispatch({ type: 'GET_PATIENT_SESSIONS_REQUESTING' });
  try {
    let url = `/api/list/appointment/patient/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_PATIENT_SESSIONS_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_PATIENT_SESSIONS_FAILURE',
      err: e.message,
    });
  }
};
const destroyCure = (id) => async (dispatch) => {
  dispatch({ type: 'DELETE_CURE_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api//consultation/delete/${id}`,
    });
    if (data.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Cure supprimée avec succès" />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`File a été supprimée `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'DELETE_CURE_SUCCESS',
        ErrorMessage: data.message,
        data: true,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'DELETE_CURE_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec de suppression du cure" />
      ),
      {
        hideProgressBar: true,
      },
    );
    //.error(`File non supprimée`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'DELETE_CURE_FAILURE',
      err: e.message,
    });
  }
};

export default {
  getPatientSessions,
  closeTheSessions,
  getAllPec,
  changeSearchedStringPec,
  getReceiptByConsultationId,
  changeSearchedStringReceipt,
  getBillByConsultationId,
  changeSearchedStringBill,
  getSectionProtocoleFile,
  submitFormProtocole,
  changeSearchedStringProtocole,
  getProtocoleByConsultationId,
  getSessionByConsultation,
  changeSearchedStringBilan,
  getBilanByConsultationId,
  getDispoDoctor,
  getConsultationById,
  initialState,
  getPatientConsultations,
  getMotifs,
  addConsultation,
  changeSearchedString,
  getConsultations,
  deleteFile,
  submitFormPEC,
  submitFormCure,
  submitFormSession,
  submitFormUpdateSession,
  uploadFIleConsultation,
  getSessionsVirtualPec,
  submitAgendaManuel,
  deletePEC,
  destroyCure,
};
