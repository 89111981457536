import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../consultations/store/actions';

import '../profile/Abonnement/index.css';
import '../profile/DiplomeFormation/index.css';
import '../profile/InfoGenerale/index.css';
import '../profile/Notifications/index.css';
import '../profile/Securities/index.css';
import '../profile/index.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import actionsJs from '../../js/actions';
import Modals from '../../components/modal';
import DrawerUpdate from '../pec/containers/drawerUpdate';
import { toast } from 'react-toastify';
import ToastAction from '../../components/ToastAction';
//import FormDrawer from './form-recette';

const mapStateToProps = (state) => ({
  listPec: state.ConsultationReducer.listPec,
  isLoadingListPec: state.ConsultationReducer.isLoadingListPec,
  params: state.ConsultationReducer.paramsPec,
  activeItemSideBarRDV: state.ConsultationReducer.activeItemSideBarRDV,
});

const mapDispatchToProps = (dispatch) => ({
  getAllPec: (params) => dispatch(actions.getAllPec(params)),
  changeActiveItemSideBarSettings: (item, data, create) =>
    dispatch(actionsJs.changeActiveItemSideBarSettings(item, data, create)),
  changeActiveItemSideBarRDV: (item) =>
    dispatch(actionsJs.changeActiveItemSideBarRDV(item)),
  changeSearched: async (params) => {
    await dispatch(actions.changeSearchedStringPec(params));
    dispatch(actions.getAllPec(params));
  },
  changeSearchedStatus: async (params) => {
    await dispatch(actions.changeSearchedStringPec(params));
    dispatch(actions.getAllPec(params));
  },
  changeSearchedEndDate: async (params) => {
    await dispatch(actions.changeSearchedStringPec(params));
    dispatch(actions.getAllPec(params));
  },
  changeSearchedStartDate: async (params) => {
    await dispatch(actions.changeSearchedStringPec(params));
    dispatch(actions.getAllPec(params));
  },
  submitFormPEC: async (values, params) => {
    await dispatch(actions.submitFormPEC(values));
    dispatch(actions.getAllPec(params));
  },
});

class Pec extends Component {
  constructor(props) {
    super(props);
    props.getAllPec(props.params);
    props.changeNamePage('Prises en charges');
    props.changeActiveItemSideBarCompta(33000);
    document.title = 'Prises en charges';
    this.state = {
      setIsOpenDeleteModal: false,
      selected_id: null,
      errorDate: false,
      isOpen: false,
      pec: null,
    };
    this.openDeleteModal = this.openDeleteModal.bind(this);
  }
  openDeleteModal() {
    this.setState({
      setIsOpenDeleteModal: !this.state.setIsOpenDeleteModal,
    });
  }
  componentDidMount() {
    this.props.changeSearched({
      ...this.props.params,
      search: '',
      start_date: '',
      end_date: '',
      status: '',
    });
  }
  toggleDrawer = (anchor, open, id) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      left: open,
      anchor: 'left',
    });
  };

  render() {
    const { listPec, activeItemSideBarRDV, isLoadingListPec, params, admin } = this.props;
    const { setIsOpenDeleteModal, selected_id, isOpen, pec, errorDate } = this.state;
    return (
      <div className="main_content" style={{ paddingLeft: 0 }}>
        {isOpen && (
          <DrawerUpdate
            submitFormPEC={this.props.submitFormPEC}
            toggleDrawer={this.toggleDrawer}
            params={params}
            selected_id={selected_id}
            pec={pec}
            isOpen={isOpen}
            cancel={() => {
              this.setState({
                pec: null,
                isOpen: false,
              });
            }}
          />
        )}

        <div className="user_settings">
          <div className="container_changeable_content">
            <div className="facture_patient_header" style={{ display: 'table' }}>
              <p>Rechercher une prise en charge</p>
              <input
                style={{ width: '20%', marginLeft: '-1%' }}
                onChange={(event) => {
                  this.props.changeSearched({
                    ...params,
                    search: event.target.value ? event.target.value : '',
                  });
                }}
                type="text"
                //placeholder="Nom et prénom patient - N de la décision"
                placeholder="Nom et prénom patient - N de la décision"
              />
              <select
                style={{ width: '20%', margin: '0px 20px' }}
                id="inputState"
                name="status"
                value={params.status}
                onChange={(event, option) => {
                  this.props.changeSearchedStatus({
                    ...params,
                    status: event.target.value !== 'Statut' ? event.target.value : null,
                  });
                }}
              >
                <option value={null}>Statut</option>
                <option value="in-progress">En cours</option>
                <option value="completed">Terminée</option>
                <option value="invoiced">Facturée</option>
                <option value="billable">Facturable </option>
              </select>
              <input
                style={{
                  width: '20%',
                  margin: '0px 20px',
                  borderBottom: errorDate ? '1px solid #ff808b' : '',
                }}
                id="date"
                type="date"
                value={
                  params.start_date ? moment(params.start_date).format('YYYY-MM-DD') : ''
                }
                onChange={(e) => {
                  this.props.changeSearchedStartDate({
                    ...params,
                    start_date: e.target.value
                      ? moment(e.target.value).format('YYYY-MM-DD')
                      : '',
                  });
                }}
              />
              <input
                style={{
                  width: '20%',
                  margin: '0px 20px',
                  borderBottom: errorDate ? '1px solid #ff808b' : '',
                }}
                id="date"
                type="date"
                className="type3"
                value={
                  params.end_date ? moment(params.end_date).format('YYYY-MM-DD') : ''
                }
                onChange={(e) => {
                  if (moment(params.start_date).isAfter(moment(e.target.value))) {
                    this.setState({
                      errorDate: true,
                    });
                  } else {
                    this.setState({
                      errorDate: false,
                    });
                    this.props.changeSearchedEndDate({
                      ...params,
                      end_date: e.target.value
                        ? moment(e.target.value).format('YYYY-MM-DD')
                        : '',
                    });
                  }
                }}
              />
            </div>
            {isLoadingListPec ? (
              <CircularProgress
                disableShrink
                style={{ marginLeft: '45%', marginTop: '23%' }}
              />
            ) : (
              <div
                className="main_content"
                style={{
                  overflow: 'hidden',
                  width: '100%',
                  padding: '5%',
                  height: 'auto',
                }}
              >
                <div
                  className="general_info"
                  style={{ display: 'contents', width: '87%', height: '100%' }}
                >
                  <div className="contact_patients_body" style={{ height: '100%' }}>
                    {/* <span className="patients_consultations_record_label">Recettes</span> */}

                    {listPec && listPec.length > 0 ? (
                      listPec.map((item) => {
                        if (item.consultation && item.code_bureau) {
                          const countIsDone =
                            item.consultation.appointments &&
                            item.consultation.appointments.length > 0 &&
                            item.consultation.appointments.filter(
                              (ele) => ele.status === 'completed',
                            );
                          return (
                            <div
                              onClick={() => {
                                if (item.status === 'in-progress') {
                                  toast(
                                    ({ closeToast }) => (
                                      <ToastAction
                                        type="info"
                                        message="Cette prise en charge ne peut pas être facturée avant qu’elle soit terminée."
                                      />
                                    ),
                                    { hideProgressBar: true },
                                  );
                                }
                                if (item.status === 'invoiced') {
                                  toast(
                                    ({ closeToast }) => (
                                      <ToastAction
                                        type="info"
                                        message=" Cette prise en charge est déjà facturée."
                                      />
                                    ),
                                    { hideProgressBar: true },
                                  );
                                }
                                if (item.status === 'completed') {
                                  toast(
                                    ({ closeToast }) => (
                                      <ToastAction
                                        type="info"
                                        message={`Cette prise en charge sera facturable à partir de ${moment(
                                          item.end_date,
                                        ).format('DD/MM/YYYY')}`}
                                      />
                                    ),
                                    { hideProgressBar: true },
                                  );
                                }
                              }}
                              key={item.id}
                              className="rendez_vous_list_body_item_container"
                              style={{
                                border: '1px solid grey',
                                marginBottom: '5px',
                              }}
                            >
                              <div className="rendez_vous_list_body_item">
                                <div
                                  className="rv_list_body_sub_item"
                                  id="time_container_indis"
                                >
                                  <span id="start_time">
                                    {' '}
                                    {`${item.consultation.patient_record.patient.last_name} ${item.consultation.patient_record.patient.first_name}`}
                                  </span>
                                </div>
                                <div className="rv_list_body_sub_item rv_details">
                                  <div
                                    className="rv_text_details"
                                    style={{ width: 'auto' }}
                                  >
                                    <div
                                      className="patient_consultation_text"
                                      style={{ width: 'inherit' }}
                                    >
                                      <span id="type_consultation">
                                        {item.code_bureau
                                          ? `${item.code_bureau}/${item.year}/${item.num_order}`
                                          : ''}
                                      </span>
                                      <span id="patient_name">
                                        De {moment(item.start_date).format('DD/MM/YYYY')}{' '}
                                        à {moment(item.end_date).format('DD/MM/YYYY')} -{' '}
                                        {countIsDone.length || 0} séance(s) /{' '}
                                        {item.sessions_count} - {item.quality}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="rv_list_body_sub_item rv_details"
                                  style={{ width: '30%' }}
                                >
                                  <div className="rv_text_details">
                                    <div
                                      onClick={() => {
                                        if (item.status !== 'invoiced') {
                                          this.props.goTo(item.consultation.id);
                                          //
                                        }
                                      }}
                                      className="patient_consultation_text"
                                      style={{ width: 'inherit' }}
                                    >
                                      <div className="payment_tag_container">
                                        <div className="payment_tag">
                                          <button
                                            //onClick={() => this.openModal()}
                                            className={
                                              item.status === 'billable'
                                                ? 'payed'
                                                : item.status === 'completed'
                                                ? 'completed'
                                                : ''
                                            }
                                          >
                                            {item.status === 'completed'
                                              ? 'Terminée'
                                              : item.status === 'invoiced'
                                              ? 'Facturée'
                                              : item.status === 'billable'
                                              ? 'Facturable'
                                              : 'En cours'}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      onClick={() => {
                                        this.setState({
                                          pec: item,
                                          isOpen: true,
                                        });
                                      }}
                                      style={{
                                        cursor: 'pointer',
                                        display:
                                          item.status === 'invoiced' ? 'none' : 'auto',
                                      }}
                                      className="more_icon_container"
                                    >
                                      <i
                                        style={{
                                          padding: '0',
                                          border: '',
                                          color: '',
                                          borderRadius: '',
                                          backgroundColor: 'white',
                                        }}
                                        className="fas fa-edit"
                                        aria-hidden="true"
                                      ></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })
                    ) : (
                      <div
                        style={{ height: 'calc(50vh - 64px)' }}
                        className="consultation_detailed_info_section"
                      >
                        <div className="empty_consultation_result_container">
                          <div className="empty_consultation_result">
                            <img
                              src={require('../../assets/img/undraw_design_team_af2y.svg')}
                            />
                            <span id="no_consultation">Liste vide !</span>
                            <span id="no_consultation_desc">
                              Aucune prise en charge n'est enregistrée.
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pec);
