import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.css';
import actions from './store/actions';
import { SelectorContactConsultationBodyItem } from '../../utils';
import Consultation from './containers/consultation';
import CircularProgress from '@material-ui/core/CircularProgress';
import actionsJs from '../../js/actions';

const mapStateToProps = (state) => ({
  consultationList: state.ConsultationReducer.doctorConsultationList,
  loadingConsultation: state.ConsultationReducer.loadingConsultation,
  loadingConsultationById: state.ConsultationReducer.loadingConsultationById,
  consultation: state.ConsultationReducer.consultation,
  params: state.ConsultationReducer.params,
  user: state.AppReducer.user,
  error: state.ConsultationReducer.error,
});

const mapDispatchToProps = (dispatch) => ({
  changeActiveItemSideBarSettings: (item, data, create) =>
    dispatch(actionsJs.changeActiveItemSideBarSettings(item, data, create)),
  changeActiveItemSideBarCompta: (data) =>
    dispatch(actionsJs.changeActiveItemSideBarCompta(data)),

  getConsultationById: (id) => dispatch(actions.getConsultationById(id)),
  deleteCure: async (params, id) => {
    await dispatch(actions.destroyCure(id));
    dispatch(actions.getConsultations(params));
  },
});

class Consultations extends Component {
  constructor(props) {
    super(props);
    console.log(props.match.params.id);
    props.changeActiveItemSideBarSettings(111, false, true);
    props.getConsultationById(props.match.params.id);
    props.changeActiveItemSideBarCompta(null);
    props.changeNamePage('Cures');
    props.changeActiveItemSideBar(2);
    document.title = 'Cures';
    this.state = {
      errorDate: false,
    };
    this.gotToAgendaManuel = this.gotToAgendaManuel.bind(this);
    this.goTo = this.goTo.bind(this);
    this.gotToAgendaPrescription = this.gotToAgendaPrescription.bind(this);
  }
  gotToAgendaManuel(initialValues, consultation) {
    this.props.history.push({
      pathname: '/agenda-manuel',
      state: {
        // getDispoDate: getDispoDate,
        initialValues: initialValues,
        patient: `${consultation.patient_record.patient.first_name} ${consultation.patient_record.patient.last_name}`,
      },
    });
  }
  gotToAgendaPrescription(initialValues, slots) {
    this.props.history.push({
      pathname: '/agenda-prescription',
      state: { getDispoDate: slots, initialValues: initialValues },
    });
  }
  componentDidMount() {
    SelectorContactConsultationBodyItem();
  }
  goTo(getDispoDate, office, consultation) {
    this.props.history.push({
      pathname: '/seance-pdf',
      state: {
        sessions: getDispoDate,
        office: office,
        //pec: pec,
        consultation: consultation,
      },
    });
  }

  render() {
    const {
      consultationList,
      consultation,
      loadingConsultationById,
      loadingConsultation,
      params,
      admin,
    } = this.props;
    console.log(loadingConsultationById);
    return (
      <div className="main_content">
        <svg
          onClick={async () => {
            this.props.history.goBack();
          }}
          width="46"
          height="46"
          viewBox="0 0 46 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ cursor: 'pointer', margin: 5 }}
        >
          <rect
            x="0.5"
            y="45.5"
            width="45"
            height="45"
            rx="9.5"
            transform="rotate(-90 0.5 45.5)"
            fill="white"
            stroke="#ECEEF5"
          ></rect>
          <path
            d="M20.0117 23L24.5117 18.5L25.5664 19.5547L22.1211 23L25.5664 26.4453L24.5117 27.5L20.0117 23Z"
            fill="#8083A3"
          ></path>
        </svg>
        {loadingConsultationById ? (
          <CircularProgress
            disableShrink
            style={{ marginLeft: '45%', marginTop: '20%' }}
          />
        ) : (
          <div className="consultation_info_section" style={{ width: '100%' }}>
            <Consultation
              key={5}
              gotToAgendaManuel={this.gotToAgendaManuel}
              goTo={this.goTo}
              gotToAgendaPrescription={this.gotToAgendaPrescription}
              deleteCure={this.props.deleteCure}
              goBack={() => this.props.history.goBack()}
              params={params}
              admin={admin}
              loadingConsultationById={loadingConsultationById}
              consultation={consultation}
            />
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Consultations);
