import React, { Component } from 'react';
import Modals from '../components/modal';
import { Link } from 'react-router-dom';
import actionsM from '../pages/messenger/store/actions';
import actionsJs from '../js/actions';
import { connect } from 'react-redux';
import { isHasPermission } from '../utils/helpres';
import Tooltip from '@mui/material/Tooltip';
const mapDispatchToProps = (dispatch) => ({
  changeActiveItemSideBarSettings: (data, item, create) =>
    dispatch(actionsJs.changeActiveItemSideBarSettings(data, item, create)),
  getAllDoctors: (params) => dispatch(actionsM.getAllDoctors(params)),
});
const tooltips = {
  1: 'Ajouter un collaborateur',
  2: 'Ajouter une secrétaire',
  3: 'Ajouter un assistant',
  4: 'Ajouter une pathologie',
  5: 'Ajouter une absence',
  6: 'Ajouter une mission',
  7: 'Ajouter un médecin',
  8: 'Ajouter un rdv',
};

class Headres extends Component {
  constructor(props) {
    super(props);
    props.getAllDoctors();
    this.state = {
      setIsOpen: false,
      showBtnProfil: false,
      showSearch: false,
    };
    this.openModal = this.openModal.bind(this);
    this.handleShowSearch = this.handleShowSearch.bind(this);
  }

  handleShowSearch() {
    this.setState({
      showSearch: !this.state.showSearch,
    });
  }
  openModal() {
    this.setState({
      setIsOpen: !this.state.setIsOpen,
    });
  }

  showBtnProfil() {
    this.setState({
      showBtnProfil: !this.state.showBtnProfil,
    });
  }

  render() {
    const {
      namePage,
      activeItemSideBar,
      sandwich_menu_btn,
      tag,
      user,
      handleChangeMenuBtn,
      activeItemSideBarRDV,
      admin,
      activeItemSideBarSETTINGS,
      activeItemSideBarCompta,
    } = this.props;
    const { setIsOpen } = this.state;
    return (
      <>
        <div className="upper_menu" style={{ width: '100%' }}>
          <div className="upper_menu_container">
            <div className="upper_menu_left_section">
              <div onClick={() => handleChangeMenuBtn()} className="menu_icon_container">
                {sandwich_menu_btn ? (
                  <img
                    src={require('../assets/img/close.svg')}
                    className="sandwich_menu_btn"
                    alt=""
                  />
                ) : (
                  <img
                    src={require('../assets/img/bar.svg')}
                    className="sandwich_menu_btn"
                    alt=""
                  />
                )}
              </div>
              <span>{namePage}</span>
            </div>

            <div className="upper_menu_right_section">
              <div className="upper_right_menu_controls">
                {activeItemSideBar === 1 &&
                ///  (admin || user.login_access.account_type === 'secretary') &&
                isHasPermission(user.login_access.permissions, 'ADD_NEW_APPOINTMENT') ? (
                  <Tooltip title="Ajouter un rdv">
                    {/* <Link
                      // onClick={() => this.props.getAllDoctors()}
                      to="/transfer-listRDV"
                      style={{
                        border: '1px solid rgb(236, 238, 245)',
                        borderRadius: '10px',
                        padding: '0px 12px',
                        height: 'inherit',
                      }}
                    >
                      <div
                        style={{ marginTop: '75%', cursor: 'pointer' }}
                        className="add_new_facture_icon"
                      >
                        <i
                          style={{ color: 'rgb(128, 131, 163)' }}
                          title="Affecter planning"
                          id="expanded_more_details_view"
                          className="fas fa-arrow-right"
                        ></i>
                      </div>
                    </Link>

                    <div style={{ marginTop: '12%' }} className="test_seperator"></div> */}
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        this.props.changeActiveItemSideBarRDV(activeItemSideBarRDV)
                      }
                      className="add_new_facture_icon"
                    >
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.5"
                          y="0.5"
                          width="39"
                          height="39"
                          rx="9.5"
                          fill="white"
                          stroke="#ECEEF5"
                        />
                        <path
                          d="M25.2383 20.7383H20.7383V25.2383H19.2617V20.7383H14.7617V19.2617H19.2617V14.7617H20.7383V19.2617H25.2383V20.7383Z"
                          fill="#8083A3"
                        />
                      </svg>
                    </div>
                  </Tooltip>
                ) : (
                  ''
                )}
                {activeItemSideBarSETTINGS in [1, 2, 3, 4, 5, 6, 7, 8] ? (
                  <>
                    <Tooltip title={tooltips[activeItemSideBarSETTINGS]}>
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          this.props.changeActiveItemSideBarSettings(
                            activeItemSideBarSETTINGS,
                            true,
                            true,
                          )
                        }
                        className="add_new_facture_icon"
                      >
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="39"
                            height="39"
                            rx="9.5"
                            fill="white"
                            stroke="#ECEEF5"
                          />
                          <path
                            d="M25.2383 20.7383H20.7383V25.2383H19.2617V20.7383H14.7617V19.2617H19.2617V14.7617H20.7383V19.2617H25.2383V20.7383Z"
                            fill="#8083A3"
                          />
                        </svg>
                      </div>
                    </Tooltip>
                    <div className="test_seperator"></div>
                  </>
                ) : (
                  ''
                )}
                {activeItemSideBar === 3 &&
                isHasPermission(user.login_access.permissions, 'ADD_NEW_PATIENT') ? (
                  <Tooltip title="Ajouter un patient">
                    <Link to="/form-patient">
                      <div className="add_new_patients_icon">
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="39"
                            height="39"
                            rx="9.5"
                            fill="white"
                            stroke="#ECEEF5"
                          />
                          <path
                            d="M18.2773 22.3555C19.6602 21.793 20.9844 21.5117 22.25 21.5117C23.5156 21.5117 24.8281 21.793 26.1875 22.3555C27.5703 22.8945 28.2617 23.6094 28.2617 24.5V26.0117H16.2383V24.5C16.2383 23.6094 16.918 22.8945 18.2773 22.3555ZM15.5 18.4883H17.75V20H15.5V22.25H13.9883V20H11.7383V18.4883H13.9883V16.2383H15.5V18.4883ZM24.3594 19.1211C23.7734 19.707 23.0703 20 22.25 20C21.4297 20 20.7266 19.707 20.1406 19.1211C19.5547 18.5352 19.2617 17.832 19.2617 17.0117C19.2617 16.1914 19.5547 15.4883 20.1406 14.9023C20.7266 14.293 21.4297 13.9883 22.25 13.9883C23.0703 13.9883 23.7734 14.293 24.3594 14.9023C24.9453 15.4883 25.2383 16.1914 25.2383 17.0117C25.2383 17.832 24.9453 18.5352 24.3594 19.1211Z"
                            fill="#8083A3"
                          />
                        </svg>
                      </div>
                    </Link>
                  </Tooltip>
                ) : (
                  ''
                )}
                {activeItemSideBarCompta === 150 &&
                isHasPermission(user.login_access.permissions, 'CREATE_CNAM_SLIP') ? (
                  <Tooltip title="Ajouter un bordreau">
                    <Link
                      to={{
                        pathname: `/form-bordreau/${tag}`,
                        state: { active: activeItemSideBar },
                      }}
                    >
                      <div className="add_new_facture_icon">
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="39"
                            height="39"
                            rx="9.5"
                            fill="white"
                            stroke="#ECEEF5"
                          />
                          <path
                            d="M25.2383 20.7383H20.7383V25.2383H19.2617V20.7383H14.7617V19.2617H19.2617V14.7617H20.7383V19.2617H25.2383V20.7383Z"
                            fill="#8083A3"
                          />
                        </svg>
                      </div>
                    </Link>
                  </Tooltip>
                ) : (
                  ''
                )}
                {activeItemSideBarCompta === 154 &&
                isHasPermission(user.login_access.permissions, 'CREATE_CNAM_SLIP') ? (
                  <Tooltip title="Ajouter une recette">
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        this.props.changeActiveItemSideBarRDV(activeItemSideBarRDV)
                      }
                      className="add_new_facture_icon"
                    >
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.5"
                          y="0.5"
                          width="39"
                          height="39"
                          rx="9.5"
                          fill="white"
                          stroke="#ECEEF5"
                        />
                        <path
                          d="M25.2383 20.7383H20.7383V25.2383H19.2617V20.7383H14.7617V19.2617H19.2617V14.7617H20.7383V19.2617H25.2383V20.7383Z"
                          fill="#8083A3"
                        />
                      </svg>
                    </div>
                  </Tooltip>
                ) : (
                  ''
                )}
                {activeItemSideBarCompta === 153 &&
                isHasPermission(user.login_access.permissions, 'CREATE_CNAM_SLIP') ? (
                  <Tooltip title="Ajouter un dépence">
                    <div
                      onClick={() =>
                        this.props.changeActiveItemSideBarRDV(activeItemSideBarRDV)
                      }
                      className="add_new_facture_icon"
                    >
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.5"
                          y="0.5"
                          width="39"
                          height="39"
                          rx="9.5"
                          fill="white"
                          stroke="#ECEEF5"
                        />
                        <path
                          d="M25.2383 20.7383H20.7383V25.2383H19.2617V20.7383H14.7617V19.2617H19.2617V14.7617H20.7383V19.2617H25.2383V20.7383Z"
                          fill="#8083A3"
                        />
                      </svg>
                    </div>
                  </Tooltip>
                ) : (
                  ''
                )}
                {activeItemSideBarCompta === 151 &&
                isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') &&
                isHasPermission(user.login_access.permissions, 'ADD_NEW_BILL') ? (
                  <Tooltip title="Ajouter une facture">
                    <Link
                      to={{
                        pathname: `/patient/facture`,
                        state: { active: activeItemSideBar },
                      }}
                    >
                      <div className="add_new_facture_icon">
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="39"
                            height="39"
                            rx="9.5"
                            fill="white"
                            stroke="#ECEEF5"
                          />
                          <path
                            d="M25.2383 20.7383H20.7383V25.2383H19.2617V20.7383H14.7617V19.2617H19.2617V14.7617H20.7383V19.2617H25.2383V20.7383Z"
                            fill="#8083A3"
                          />
                        </svg>
                      </div>
                    </Link>
                  </Tooltip>
                ) : (
                  ''
                )}
                {activeItemSideBarCompta === 152 &&
                isHasPermission(user.login_access.permissions, 'RECEIPT_ACCESS') &&
                isHasPermission(user.login_access.permissions, 'ADD_NEW_RECEIPT') ? (
                  <Tooltip title="Ajouter un reçu">
                    <Link to={`/patient/receipt`}>
                      <div className="add_new_facture_icon">
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="39"
                            height="39"
                            rx="9.5"
                            fill="white"
                            stroke="#ECEEF5"
                          />
                          <path
                            d="M25.2383 20.7383H20.7383V25.2383H19.2617V20.7383H14.7617V19.2617H19.2617V14.7617H20.7383V19.2617H25.2383V20.7383Z"
                            fill="#8083A3"
                          />
                        </svg>
                      </div>
                    </Link>
                  </Tooltip>
                ) : (
                  ''
                )}
              </div>
              {activeItemSideBar === 3 ||
              activeItemSideBar === 1 ||
              // activeItemSideBar === 8 ||
              activeItemSideBar === 100 ||
              activeItemSideBarCompta === 150 ||
              activeItemSideBarCompta === 151 ||
              activeItemSideBarCompta === 152 ||
              activeItemSideBarCompta === 153 ||
              activeItemSideBarCompta === 154 ||
              activeItemSideBar === 101 ||
              activeItemSideBar === 102 ||
              activeItemSideBar === 103 ||
              activeItemSideBar === 104 ||
              activeItemSideBar === 6 ||
              activeItemSideBar === 5 ||
              activeItemSideBar === 9 ? (
                <div className="test_seperator"></div>
              ) : (
                ''
              )}

              <div className="upper_menu_right_section_img">
                <div className="btn-group">
                  <button
                    onClick={() => this.showBtnProfil}
                    type="button"
                    className="btn btn-secondary dropdown-toggle"
                    data-toggle="dropdown"
                    data-display="static"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {user.image_practitioners && user.image_practitioners.length > 0 ? (
                      <img
                        src={`${process.env.REACT_APP_PUBLIC_URL}${user.image_practitioners[0].url}`}
                        width="35"
                        height="35"
                        alt=""
                      />
                    ) : user.photo ? (
                      <img
                        src={`${process.env.REACT_APP_PUBLIC_URL}${user.photo}`}
                        width="35"
                        height="35"
                        alt=""
                      />
                    ) : (
                      <div
                        style={{ marginTop: '57%' }}
                        className="patient_contact_img_container"
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                            fill="#8083A3"
                          />
                        </svg>
                      </div>
                    )}
                  </button>
                  <div
                    style={{ zIndex: '2000' }}
                    className="dropdown-menu dropdown-menu-lg-right"
                  >
                    <Link to="/profil">
                      <button className="dropdown-item " type="button">
                        <div className="profil_btn">
                          <i
                            style={{ color: '#16181b' }}
                            className="fas fa-user-circle"
                          ></i>
                          <span>Profil</span>
                        </div>
                      </button>
                    </Link>
                    <hr />
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => this.openModal()}
                    >
                      <div className="disconnect_btn">
                        <img src={require('../assets/img/logout.svg')} alt="" />
                        <span>Déconnexion</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modals
          setIsOpen={setIsOpen}
          openModal={this.openModal}
          data={
            <>
              {' '}
              <div className="modal-header" style={{ marginTop: '0' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModal()}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '10px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Se déconnecter!</h2>
                <span>Etes vous sûrs de vouloir vous déconnecter ?</span>
              </div>
              <div className="modal-footer">
                <button
                  style={{ backgroundColor: '#f6f6f6' }}
                  onClick={() => this.openModal()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                >
                  Non
                </button>
                <button
                  onClick={() => {
                    localStorage.clear();
                    window.location.href = '/';
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Se déconnecter
                </button>
              </div>
            </>
          }
        />
      </>
    );
  }
}
export default connect(null, mapDispatchToProps)(Headres);
