import React, { Component } from 'react';
import { connect } from 'react-redux';
import './index.css';
import actions from './store/actions';
import Item_Consultation from './composent/item_consultation';
import { SelectorContactConsultationBodyItem } from '../../utils';
import Consultation from './containers/consultation';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import actionsJs from '../../js/actions';

const mapStateToProps = (state) => ({
  consultationList: state.ConsultationReducer.doctorConsultationList,
  loadingConsultation: state.ConsultationReducer.loadingConsultation,
  loadingConsultationById: state.ConsultationReducer.loadingConsultationById,
  consultation: state.ConsultationReducer.consultation,
  params: state.ConsultationReducer.params,
  user: state.AppReducer.user,
  error: state.ConsultationReducer.error,
});

const mapDispatchToProps = (dispatch) => ({
  changeActiveItemSideBarSettings: (item, data, create) =>
    dispatch(actionsJs.changeActiveItemSideBarSettings(item, data, create)),
  changeActiveItemSideBarCompta: (data) =>
    dispatch(actionsJs.changeActiveItemSideBarCompta(data)),

  getConsultations: (params) => dispatch(actions.getConsultations(params)),
  getConsultationById: (id) => dispatch(actions.getConsultationById(id)),
  changeSearchedStringLimit: (params) => {
    dispatch(actions.changeSearchedString(params));
    dispatch(actions.getConsultations(params));
  },
  deleteCure: async (params, id) => {
    await dispatch(actions.destroyCure(id));
    dispatch(actions.getConsultations(params));
  },
  changeSearchedSearch: async (params) => {
    await dispatch(actions.changeSearchedString(params));
    dispatch(actions.getConsultations(params));
  },
  changeSearchedEndDate: async (params) => {
    await dispatch(actions.changeSearchedString(params));
    dispatch(actions.getConsultations(params));
  },
  changeSearchedStartDate: async (params) => {
    await dispatch(actions.changeSearchedString(params));
    dispatch(actions.getConsultations(params));
  },
});

class Consultations extends Component {
  constructor(props) {
    super(props);
    props.changeActiveItemSideBarSettings(111, false, true);
    //props.getConsultations(props.params);
    props.changeActiveItemSideBarCompta(null);
    props.changeNamePage('Cures');
    props.changeActiveItemSideBar(2);
    document.title = 'Cures';
    this.state = {
      errorDate: false,
    };
    this.gotToAgendaManuel = this.gotToAgendaManuel.bind(this);
    this.goTo = this.goTo.bind(this);
    this.gotToAgendaPrescription = this.gotToAgendaPrescription.bind(this);
  }
  gotToAgendaManuel(initialValues, consultation) {
    this.props.history.push({
      pathname: '/agenda-manuel',
      state: {
        // getDispoDate: getDispoDate,
        initialValues: initialValues,
        patient: `${consultation.patient_record.patient.first_name} ${consultation.patient_record.patient.last_name}`,
      },
    });
  }
  gotToAgendaPrescription(initialValues, slots) {
    this.props.history.push({
      pathname: '/agenda-prescription',
      state: { getDispoDate: slots, initialValues: initialValues },
    });
  }
  componentDidMount() {
    const { params } = this.props;
    this.props.changeSearchedSearch({
      ...params,
      limit: 30,
      currentPage: 1,
      search: this.props.location.state ? this.props.location.state.phone : '',
      startDate: this.props.location.state ? this.props.location.state.date : '',
      //startDate: '',
      endDate: '',
    });
    SelectorContactConsultationBodyItem();
  }
  goTo(getDispoDate, office, consultation) {
    this.props.history.push({
      pathname: '/seance-pdf',
      state: {
        sessions: getDispoDate,
        office: office,
        //pec: pec,
        consultation: consultation,
      },
    });
  }

  render() {
    const {
      consultationList,
      consultation,
      loadingConsultationById,
      loadingConsultation,
      params,
      admin,
    } = this.props;
    const { errorDate } = this.state;
    const { data } = consultationList;
    return (
      <div className="main_content">
        <div style={{ width: '100%' }} className="consultation_section">
          <div
            style={{ width: '95%', marginTop: 30 }}
            className="consultation_section_header"
          >
            <p>Rechercher une cure</p>
            <div className="search_consultation_section">
              <div
                style={{
                  marginRight: 20,
                  width: '70%',
                  border: '1px solid #f1f1f1',
                  borderRadius: 5,
                }}
              >
                <input
                  //style={{}}
                  type="text"
                  value={params.search || ''}
                  onChange={(event) => {
                    this.props.changeSearchedSearch({
                      ...params,
                      search: event.target.value,
                    });
                  }}
                  style={{ textIndent: 10, borderRight: 'none' }}
                  placeholder="Recherche par nom patient ou numéro de téléphone"
                />
                <i style={{ color: '#f1f1f1' }} className="fas fa-search"></i>
              </div>

              <div className="consultation_section_controls">
                <TextField
                  style={{ width: '50%' }}
                  id="datetime-local"
                  error={errorDate}
                  type="date"
                  defaultValue={
                    params.startDate
                      ? moment(params.startDate).format('YYYY-MM-DD HH:mm:ss')
                      : moment().format('YYYY-MM-DD HH:mm:ss')
                  }
                  onChange={(event) => {
                    if (
                      params.endDate &&
                      moment(params.endDate).isBefore(moment(event.target.value))
                    ) {
                      this.setState({
                        errorDate: true,
                      });
                    } else {
                      this.setState({
                        errorDate: false,
                      });
                      this.props.changeSearchedStartDate({
                        ...params,
                        startDate: event.target.value
                          ? moment(event.target.value).format('YYYY-MM-DD HH:mm:ss')
                          : '',
                      });
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  style={{ width: '50%' }}
                  id="datetime-local"
                  type="date"
                  error={errorDate}
                  defaultValue={
                    params.endDate
                      ? moment(params.endDate).format('YYYY-MM-DD HH:mm:ss')
                      : moment().format('YYYY-MM-DD HH:mm:ss')
                  }
                  onChange={(e) => {
                    if (
                      params.startDate &&
                      moment(params.startDate).isAfter(moment(e.target.value))
                    ) {
                      this.setState({
                        errorDate: true,
                      });
                    } else {
                      this.setState({
                        errorDate: false,
                      });
                      this.props.changeSearchedEndDate({
                        ...params,
                        endDate: e.target.value
                          ? moment(e.target.value).format('YYYY-MM-DD HH:mm:ss')
                          : '',
                      });
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </div>
          {loadingConsultation ? (
            <CircularProgress
              disableShrink
              style={{ marginLeft: '47%', marginTop: '30%' }}
            />
          ) : (
            <>
              <div
                /*onClick={() => {
                  this.props.getConsultationById(item.id);
                }}*/
                className="consultation_schedule_item"
              >
                <div
                  style={{ width: '90%', marginLeft: 74 }}
                  className="consultation_schedule_sub_item"
                  id="consultation_schedule_timing"
                >
                  <div
                    style={{
                      width: '4%',
                      display: 'flex',
                      gap: '30px',
                    }}
                  >
                    <div className="consultation_schedule_text">
                      <span id="type_consultation">Date</span>
                    </div>
                  </div>
                  <div style={{ width: '18%' }} className="consultation_schedule_text">
                    <span id="type_consultation">Diagnostique</span>
                  </div>
                  <div style={{ width: '13%' }} className="consultation_schedule_text">
                    <span id="type_consultation">Téléphone</span>
                  </div>
                  <div style={{ width: '12%' }} className="consultation_schedule_text">
                    <span id="type_consultation">Cnam</span>
                  </div>
                  <div style={{ width: '12%' }} className="consultation_schedule_text">
                    <span id="type_consultation">Régime</span>
                  </div>
                  <div style={{ width: '11%' }} className="consultation_schedule_text">
                    <span id="type_consultation">Nbr de séances</span>
                  </div>
                  <div style={{ width: '15%' }} className="consultation_schedule_text">
                    <span id="type_consultation">S/semaine</span>
                  </div>
                </div>
              </div>
              <div className="consultation_sections_body">
                {data && data.length > 0
                  ? data.map((item) => {
                      return (
                        <Item_Consultation
                          key={item.id}
                          getConsultationById={this.props.getConsultationById}
                          item={item}
                          id={
                            this.props.location &&
                            this.props.location.state &&
                            this.props.location.state.consultation_id
                              ? parseInt(this.props.location.state.consultation_id)
                              : data && data.length > 0 && data[0].id
                          }
                        />
                      );
                    })
                  : ''}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Consultations);
