import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../../js/actions';
import '../../profile/InfoGenerale/index.css';

import CircularProgress from '@material-ui/core/CircularProgress';
import { SelectorInputAndIcon } from '../../../utils';
import {
  isNumeric,
  lengthPhone,
  validate,
} from '../../../utils/helpres';
import SimpleReactValidator from 'simple-react-validator';
import InputField from '../../../components/inputField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  sortCities,
  country,
} from '../../../utils/constants';
import TextField from '@material-ui/core/TextField';

const mapStateToProps = (state, ownProps) => {
  const { medecin } = ownProps;

  let first_name;
  let last_name;
  let country;
  let city;
  let street;
  let zip_code;
  let phone;
  let speciality;
  let id;
  if (medecin) {
    id = medecin && medecin.id ? medecin.id : null;
    speciality = medecin && medecin.speciality ? medecin.speciality : null;
    first_name = medecin && medecin.first_name ? medecin.first_name : '';
    last_name = medecin && medecin.last_name ? medecin.last_name : '';
    phone = medecin && medecin.phone ? medecin.phone : '';
    country = medecin && medecin.country ? medecin.country : '';
    city = medecin && medecin.city ? medecin.city : '';
    street = medecin && medecin.street ? medecin.street : '';
    zip_code = medecin && medecin.zip_code ? medecin.zip_code : '';
  }
  return {
    sandwich_menu_btn: state.AppReducer.sandwich_menu_btn,
    isLoadingSpecialities: state.AppReducer.isLoadingSpecialities,
    specialities: state.AppReducer.specialities,
    actionMedecin: state.AppReducer.actionMedecin,
    initialValues: {
      id: id,
      first_name: first_name,
      speciality: speciality,
      last_name: last_name,
      phone: phone,
      country: country,
      city: city,
      street: street,
      zip_code: zip_code,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  submitMedecinForm: async (values) => {
    await dispatch(actions.submitMedecinForm(values));
    dispatch(actions.getMedecins());
  },
  getSpecialities: () => dispatch(actions.getSpecialities()),
});

class FormMedecins extends Component {
  constructor(props) {
    super(props);
    document.title = 'Info générale';
    //props.getSpecialities();
    this.state = {
      initialValues: {
        ...props.initialValues,
      },
      hasError: false,
    };
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        // OR
        default: 'Cette information est requise.', // will override all messages
      },
    });
    this.handleInitialValues = this.handleInitialValues.bind(this);
  }
  handleInitialValues() {
    this.setState({
      hasError: false,
      initialValues: {
        ...this.props.initialValues,
      },
    });
  }
  componentDidMount() {
    SelectorInputAndIcon();
  }

  submitForm(initialValues, id) {
    if (
      !initialValues.first_name ||
      !initialValues.last_name ||
      !initialValues.speciality ||
      !validate(initialValues.first_name) ||
      !validate(initialValues.last_name) ||
      //!firstNumberPhone(initialValues.phone) ||
      (initialValues.phone && !lengthPhone(initialValues.phone)) ||
      (initialValues.phone && !isNumeric(initialValues.phone))
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else {
      this.props.submitMedecinForm(initialValues, id);
    }
  }

  render() {
    const {
      isLoadingUpdateUser,
      isLoadingSpecialities,
      sandwich_menu_btn,
      specialities,
    } = this.props;
    const { initialValues, hasError } = this.state;
    if (isLoadingSpecialities)
      return (
        <CircularProgress disableShrink style={{ marginLeft: '33%', marginTop: '20%' }} />
      );
    return (
      <div className="" style={{ width: '100%' }}>
        <form className="general_info_form">
          <div className="title_and_button">
            <span>Informations personnelles</span>
          </div>
          <div className="general_info_actual_form">
            <InputField
              width={sandwich_menu_btn ? '26vw' : '30vw'}
              className="general_info_form_item"
              type="text"
              placeholder="Prénom"
              label="Prénom"
              hasError={hasError}
              validInput="string"
              value={initialValues.last_name}
              name="last_name"
              icon={<i className="fas fa-user"></i>}
              onChange={(e) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    last_name: e.target.value,
                    //  name_cabinet:  e.target.value,
                  },
                });
              }}
            />
            <InputField
              width={sandwich_menu_btn ? '26vw' : '30vw'}
              type="text"
              placeholder="Nom"
              className="general_info_form_item"
              validInput="string"
              label="Nom"
              hasError={hasError}
              value={initialValues.first_name}
              name="first_name"
              icon={<i className="fas fa-user"></i>}
              onChange={(e) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    first_name: e.target.value,
                    // name_cabinet: e.target.value,
                  },
                });
              }}
            />
            <InputField
              width={sandwich_menu_btn ? '26vw' : '30vw'}
              type="text"
              placeholder="Specialité"
              className="general_info_form_item"
              validInput="string"
              label="Specialité"
              maxLength={20}
              hasError={hasError}
              value={initialValues.speciality}
              name="speciality"
              icon={<i className="fas fa-user"></i>}
              onChange={(e, option) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    speciality: e.target.value,
                  },
                });
              }}
            />
          {/*  <div
              className="view_item"
              style={{ marginRight: '75px', width: hasError ? '42%' : '40%' }}
            >
              <label htmlFor="">Specialité</label>
              <div className="view_input_container">
                <div style={{ borderBottom: '0' }} className="view_input">
                  <select
                    name="speciality"
                    value={initialValues.speciality}
                    onChange={(e, option) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          speciality: e.target.value,
                        },
                      });
                    }}
                  >
                    <option value={null}>Choisissez</option>
                    {specialities.map((speciality) => {
                      return <option value={speciality.name}>{speciality.name}</option>;
                    })}
                  </select>
                </div>
              </div>
            </div>*/}
            <InputField
              width={sandwich_menu_btn ? '26vw' : '30vw'}
              className="general_info_form_item"
              type="text"
              placeholder="Tél/Fax"
              label="Tél/Fax"
              minLength={8}
              maxLength={20}
             // hasError={hasError}
              validInput="tel"
              value={initialValues.phone}
              name="phone"
              icon={<i className="fas fa-phone-alt"></i>}
              required={this.validator.message('phone', initialValues.phone, 'integer')}
              onChange={(e) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    phone: e.target.value,
                  },
                });
              }}
            />
          </div>

          <div className="address_form">
            <div className="title_and_button">
              <span id="">Adresse</span>
            </div>
            <div className="add_patient_actual_form">
              <div className="field_row">
                <InputField
                  width="100%"
                  className="add_patient_form_item"
                  type="text"
                  placeholder="Adresse"
                  label="Adresse"
                 // hasError={hasError}
                  validInput=""
                  value={initialValues.street}
                  name="street"
                  icon={<i className="fas fa-city"></i>}
                  required={this.validator.message(
                    'street',
                    initialValues.street,
                    'required|string',
                  )}
                  onChange={(e) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        street: e.target.value,
                      },
                    });
                  }}
                />
                <div style={{ width: '100%' }} className="add_patient_form_item">
                  <label>Ville</label>
                  <Autocomplete
                    id="size-small-standard"
                    size="small"
                    options={sortCities}
                    onChange={(event, option) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          city: option != null ? option.title : '',
                        },
                      });
                    }}
                    defaultValue={{
                      title: initialValues.city,
                    }}
                    getOptionLabel={(option) => option.title}
                    renderInput={(cities) => (
                      <TextField
                        style={{ width: '100%' }}
                        placeholder="Ville"
                        {...cities}
                        variant="standard"
                        //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                        //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                      //  error={hasError && !initialValues.city}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="field_row">
                <InputField
                  width="100%"
                  className="add_patient_form_item"
                  type="text"
                  placeholder="Code Postal"
                  label="Code Postal"
                  //hasError={hasError}
                  validInput="integer"
                  value={initialValues.zip_code}
                  name="zip_code"
                  icon={<i className="fas fa-globe-africa"></i>}
                  required={this.validator.message(
                    'zip_code',
                    initialValues.zip_code,
                    'required|integer',
                  )}
                  onChange={(e) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        zip_code: e.target.value,
                      },
                    });
                  }}
                />
                <div style={{ width: '100%' }} className="add_patient_form_item">
                  <label>Pays</label>
                  <Autocomplete
                    id="size-small-standard"
                    size="small"
                    options={country}
                    onChange={(event, option) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          country: option != null ? option.title : '',
                        },
                      });
                    }}
                    defaultValue={{
                      title: initialValues.country,
                    }}
                    getOptionLabel={(option) => option.title}
                    renderInput={(country) => (
                      <TextField
                        style={{ width: '100%' }}
                        placeholder="Pays"
                        {...country}
                        variant="standard"
                        //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                        //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                       // error={hasError && !initialValues.country}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </form>
        <div className="footer_buttons" style={{ paddingBottom: '4%' }}>
          <button onClick={() => this.props.goBack()} className="footer_btn" id="cancel">
            Annuler
          </button>
          <button
            style={{
              marginRight: sandwich_menu_btn ? '6%' : '6%',
            }}
            id="update"
            className="footer_btn"
            //disabled={isLoadingUpdateUser}
            onClick={() => {
              this.submitForm(initialValues);
            }}
          >
            {isLoadingUpdateUser ? (
              <CircularProgress
                style={{
                  marginLeft: '4%',
                  width: '25px',
                  height: '25px',
                  color: 'white',
                  marginTop: '5%',
                }}
              />
            ) : (
              'Valider'
            )}
          </button>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormMedecins);
