import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ToastAction from './ToastAction';

class Bilan extends Component {
  render() {
    const { item, sandwich_menu_btn, consultationId } = this.props;
    return (
      <div
        key={item.id}
        className="bilan_item"
        style={{
          width: sandwich_menu_btn ? '250px' : '150px',
          backgroundColor: !item.actif ? '#f3f4f9' : '',
        }}
      >
        <div className="bilan_handler">
          {item.actif ? (
            <Link
              to={{
                pathname: item.actif
                  ? `/bilan/${item.label}/${consultationId}/${item.id}`
                  : '#',
                state: {
                  type: 'create',
                },
              }}
              /*to={item.actif ? `/bilan/epaule/${consultationId}/${item.id}` : '#'}*/
            >
              <div className="bilan_handler_info">
                <div className="bilan_contact_icon">
                  <img
                    src={
                      item.icon
                        ? require(`../assets/icon_bilan/${item.icon}.svg`)
                        : require('../assets/img/file.png')
                    }
                    width="35"
                    height="35"
                  />
                </div>
                <div className="bilan_contact_info">
                  <span id="name">{item.display_name}</span>
                  {/* <span id={elem.id} key={elem.id}>
                        {elem.label}
                      </span>*/}
                </div>
              </div>
            </Link>
          ) : (
            <div
              onClick={() => {
                toast(
                  ({ closeToast }) => (
                    <ToastAction
                      type="info"
                      message="Ce bilan est en cours de construction. Il sera bientôt disponible"
                    />
                  ),
                  { hideProgressBar: true },
                );
              }}
              className="bilan_handler_info"
            >
              <div className="bilan_contact_icon">
                <img
                  src={
                    item.icon
                      ? require(`../assets/icon_bilan/${item.icon}.svg`)
                      : require('../assets/img/file.png')
                  }
                  width="35"
                  height="35"
                />
              </div>
              <div className="bilan_contact_info">
                <span id="name">{item.display_name}</span>
                {/* <span id={elem.id} key={elem.id}>
                        {elem.label}
                      </span>*/}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Bilan;
