import { apiCall } from '../../../js/actions';
import { toast } from 'react-toastify';
import ToastAction from '../../../components/ToastAction';
import React from 'react';
import { getStatusError } from '../../../utils/helpres';
import moment from 'moment-timezone';

const getBillById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_BILL_REQUESTING' });
  try {
    let url = `/api/bill/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_BILL_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_BILL_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getAllBill = (params, tag, regime) => async (dispatch) => {
  dispatch({ type: 'GET_BILLS_REQUESTING' });
  try {
    const { start_date } = params || {
      start_date: '',
    };
    const { num_decision } = params || {
      num_decision: '',
    };
    const { end_date } = params || {
      end_date: '',
    };
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    const { search } = params || {
      search: '',
    };
    const { status } = params || {
      status: '',
    };
    const { type } = params || {
      type: '',
    };
    let url = `/api/bills?tag=${tag}&type=${type}&regime=${regime}&limit=${limit}&current_page=${currentPage}`;
    if (search && search !== '') url += `&search=${search}`;
    if (num_decision && num_decision !== '') url += `&num_decision=${num_decision}`;
    if (start_date && start_date !== '') url += `&start_date=${start_date}`;
    if (end_date && end_date !== '')
      url += `&end_date=${moment(moment(end_date).format('YYYY-MM-DD 23:59:00'))
        .tz('UTC')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')}`;
    if (status && status !== '') url += `&status=${status}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_BILLS_SUCCESS',
      data: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_BILLS_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const submitBillForm = (values, tag, action) => async (dispatch) => {
  dispatch({ type: 'UPDATE_BILL_REQUESTING' });
  const {
    date,
    pec_id,
    code_bureau,
    regime,
    amm_accorde,
    year,
    num_order,
    patient_record_id,
    number,
    type,
    type_id,
    submitted_in,
    line_bill,
    tva,
    price_tva,
    price_ht,
    price_ttc,
    price_unitaire,
    amm,
    conventionnel_price,
  } = values;
  let request = {
    tva,
    price_tva,
    price_ht,
    amm_accorde: amm_accorde || '',
    price_ttc:
      price_tva && price_ht
        ? parseFloat(parseFloat(price_ht) + parseFloat(price_tva)).toFixed(2)
        : '',
    price_unitaire: price_ht ? line_bill.unit_price : null,
    amm: amm || '',
    conventionnel_price,
    number,
    patient_record_id,
    type_id,
    type,
    pec_id,
    //submitted_in,
    line_bill,
    start_date: line_bill.start_date,
    end_date: line_bill.end_date,
    date,
    tag,
    regime: regime || 'autre',
    code_bureau,
    year,
    num_order,
  };
  try {
    const { data } = await apiCall({
      method: action === 'edit' ? 'patch' : 'post',
      url: action === 'edit' ? `/api/bill/${values.id}` : `/api/bill`,
      data: request,
    });
    if (data.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success" //
            message={`${
              tag === 'bill'
                ? `${
                    action === 'edit'
                      ? 'Facture modifiée avec succès'
                      : 'Facture créée avec succès'
                  }`
                : 'Récu créé avec succès'
            }`}
          />
        ),
        { hideProgressBar: true },
      );
      dispatch({
        type: 'UPDATE_BILL_SUCCESS',
        data: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_BILL_FAILURE',
        ErrorMessage: data.message,
        data: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={
            data
              ? data.message
              : `Échec de création ${tag === 'bill' ? 'de facture' : 'de récu'}`
          }
        />
      ),
      { hideProgressBar: true },
    );
    //  toast.error(`Patient non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'UPDATE_BILL_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: values,
    });
  }
};

const submitUpdateBillForm = (values) => async (dispatch) => {
  dispatch({ type: 'SUBMIT_BILL_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'patch',
      url: `/api/bill/${values.id}`,
      data: values,
    });
    if (!data.error) {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Facture modifiée avec succès" />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`Patient a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'SUBMIT_BILL_SUCCESS',
        data: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'SUBMIT_BILL_FAILURE',
        ErrorMessage: data.message,
        data: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={data ? data.message : `Échec de modification de facture`}
        />
      ),
      { hideProgressBar: true },
    );
    //  toast.error(`Patient non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'SUBMIT_BILL_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: values,
    });
  }
};

const submitSendBill = (values) => async (dispatch) => {
  dispatch({ type: 'SUBMIT_SEND_BILL_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'post',
      url: `/api/send/bill/${values.id}`,
      data: { email: values.email },
    });
    if (data) {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Bordereau CNAM créé avec succès" />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`Patient a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'SUBMIT_SEND_BILL_SUCCESS',
        data: data,
      });
    } else {
      toast(
        ({ closeToast }) => (
          <ToastAction type="error" message="Echec d'envoi du bordereau" />
        ),
        {
          hideProgressBar: true,
        },
      );
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'SUBMIT_SEND_BILL_FAILURE',
        //ErrorMessage: data,
        data: null,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={data ? data.message : `Facture n'est pas envoyée `}
        />
      ),
      { hideProgressBar: true },
    );
    //  toast.error(`Patient non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'SUBMIT_SEND_BILL_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: null,
    });
  }
};
const getBillByIds = (ids) => async (dispatch) => {
  dispatch({ type: 'GET_BILLS_BY_IDS_REQUESTING' });
  try {
    let url = `/api/bills/practitioner/bordereau/ids`;
    const { data } = await apiCall({
      method: 'get',
      url,
      params: { ids },
    });
    dispatch({
      type: 'GET_BILLS_BY_IDS_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_BILLS_BY_IDS_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const getLastBill = (tag) => async (dispatch) => {
  dispatch({ type: 'GET_LAST_BILL_REQUESTING' });
  try {
    let url = `/api/get/last/bill/${tag}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_LAST_BILL_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_LAST_BILL_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const getLastBordereau = () => async (dispatch) => {
  dispatch({ type: 'GET_LAST_BORDEREAU_REQUESTING' });
  try {
    let url = `/api/last/bordereau`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_LAST_BORDEREAU_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_LAST_BORDEREAU_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const submitBordereau = () => async (dispatch) => {
  dispatch({ type: 'SUBMIT_BORDEREAU_REQUESTING' });
  try {
    let url = `/api/bordereau`;
    const { data } = await apiCall({
      method: 'post',
      url,
    });
    dispatch({
      type: 'SUBMIT_BORDEREAU_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'SUBMIT_BORDEREAU_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const getPecById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_PEC_REQUESTING' });
  try {
    let url = `/api/pec/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_PEC_SUCCESS',
      data: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_PEC_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const changeSearchedStringBill = (params) => ({
  type: 'UPDATE_SEARCH_BILL_PARAMS',
  params: params,
});
const deleteFacture = (id) => async (dispatch) => {
  dispatch({ type: 'DELETE_BILL_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/bill/${id}`,
    });
    if (data.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Facture supprimée avec succès" />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`File a été supprimée `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'DELETE_BILL_SUCCESS',
        ErrorMessage: data.message,
        data: true,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'DELETE_BILL_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec de suppression du facture" />
      ),
      {
        hideProgressBar: true,
      },
    );
    //.error(`File non supprimée`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'DELETE_BILL_FAILURE',
      err: e.message,
    });
  }
};

export default {
  changeSearchedStringBill,
  getAllBill,
  submitBillForm,
  getLastBill,
  getPecById,
  getBillById,
  submitUpdateBillForm,
  submitSendBill,
  getBillByIds,
  getLastBordereau,
  submitBordereau,
  deleteFacture,
};
