import React, { Component } from 'react';
import Item_rdv from './item_rdv';
import actions from '../store/actions';
import actionsJs from '../../../js/actions';
import { connect } from 'react-redux';
import {
  firstNumberPhone,
  isNumeric,
  lengthPhone,
  validate,
} from '../../../utils/helpres';
import moment from 'moment';
import { ModePaiement, sortCities } from '../../../utils/constants';
import Modals from '../../../components/modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const mapStateToProps = (state) => ({
  //payment: state.AppReducer.payment,
  isLoadingActionPayment: state.AgendaReducer.isLoadingActionPayment,
  paymentAction: state.AgendaReducer.paymentAction,
  isLoadingPayment: state.AppReducer.isLoadingPayment,
  activeItemSideBarRDV: state.AppReducer.activeItemSideBarRDV,
  sandwich_menu_btn: state.AppReducer.sandwich_menu_btn,
  isLoadingGetAllDayDispo: state.AgendaReducer.isLoadingGetAllDayDispo,
  isLoadingGetLastAvailability: state.AgendaReducer.isLoadingGetLastAvailability,
  getAllDayDispo: state.AgendaReducer.getAllDayDispo,
  message: state.AgendaReducer.message,
});

const mapDispatchToProps = (dispatch) => ({
  getPayment: (id) => dispatch(actions.getPayment(id)),
  getDipoDoctorByMotif: (valuse) => dispatch(actions.getDipoDoctorByMotif(valuse)),
  changeActiveItemSideBarRDV: (activeItemSideBarRDV) =>
    dispatch(actionsJs.changeActiveItemSideBarRDV(activeItemSideBarRDV)),
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class List_rdv extends Component {
  constructor(props) {
    super(props);
    props.getAllRdvByDay(props.params, props.doctor_id);

    this.state = {
      value: 0,
      item: 0,
      updateRdv: false,
      update: false,
      showInput: false,
      hasError: false,
      listItem: ['Motif', 'Date', 'Créneau', 'Patient', 'Validation'],
      nameItem: 'Motif',
      initialValues: {
        motif: null,
        doctor_id: null,
        date: null,
      },
    };
    this.openModal = this.openModal.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleShowInput = this.handleShowInput.bind(this);
  }
  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  handleChange = (event, value) => {
    this.setState({
      value,
    });
  };
  openModal() {
    this.setState({
      setIsOpen: !this.state.setIsOpen,
      showInput: false,
    });
  }
  handleShowInput() {
    this.setState({
      showInput: !this.state.showInput,
    });
  }
  handleReset() {
    this.setState({
      item: 0,
      update: true,
    });
  }
  handleBack() {
    const { item } = this.state;
    this.setState({
      item: item - 1,
    });
  }
  handleNextItem(item) {
    const { initialValues } = this.props;
    const { update } = this.state;
    /*if (item === 2 && initialValues.date) {
      this.props.getDipoDoctorByMotif(initialValues);
    }
    if (item === 1 && initialValues.motif_id && !update) {
      this.props.getLastAvailability(initialValues.motif_id, initialValues.doctor_id);
    }*/

    if (item === 0 && !initialValues.doctor_id) {
      this.setState({
        hasError: true,
      });
    } else if (item === 1 && !initialValues.motif_id) {
      this.setState({
        hasError: true,
      });
    } else if (
      item === 2 &&
      moment(initialValues.date).isBefore(moment().format('YYYY-MM-DD'))
    ) {
      this.setState({
        hasError: true,
      });
    } else if (item === 2 && !initialValues.date) {
      this.setState({
        hasError: true,
      });
    } else if (item === 3 && (!initialValues.startHours || !initialValues.endHours)) {
      this.setState({
        hasError: true,
      });
    } else if (
      item === 3 &&
      moment(
        moment(`${initialValues.date} ${initialValues.startHours}`).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
      ).isSameOrAfter(
        moment(`${initialValues.date} ${initialValues.endHours}`).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
      )
    ) {
      this.setState({
        hasError: true,
      });
    } else if (
      item === 4 &&
      (!initialValues.phone_number || !initialValues.display_name || !initialValues.city)
    ) {
      this.setState({
        hasError: true,
      });
    } else if (item === 4 && !initialValues.display_name) {
      this.setState({
        hasError: true,
      });
    } else if (
      item === 4 &&
      initialValues.display_name &&
      !validate(initialValues.display_name)
    ) {
      this.setState({
        hasError: true,
      });
    } else if (
      item === 4 &&
      initialValues.phone_number &&
      !lengthPhone(initialValues.phone_number)
    ) {
      this.setState({
        hasError: true,
      });
    } else if (
      item === 4 &&
      initialValues.phone_number &&
      !firstNumberPhone(initialValues.phone_number)
    ) {
      this.setState({
        hasError: true,
      });
    } else if (
      item === 4 &&
      initialValues.phone_number &&
      !isNumeric(initialValues.phone_number)
    ) {
      this.setState({
        hasError: true,
      });
    } else if (item === 4 && initialValues.city && !validate(initialValues.city)) {
      this.setState({
        hasError: true,
      });
    } else if (item === 4 && !initialValues.city) {
      this.setState({
        hasError: true,
      });
    } else {
      this.setState({
        item: item + 1,
        hasError: false,
      });
    }
  }
  getStepContent(
    item,
    initialValues,
    motifs,
    getAllDayDispo,
    isLoadingGetAllDayDispo,
    params,
    hasError,
    getLastAvailabilityDate,
    isLoadingGetLastAvailability,
    isLoadingAddRdv,
    doctors,
    userList,
  ) {
    switch (item) {
      case 0:
        return (
          <div className="right_sidebar_body" id="Motif">
            <div className="right_sidebar_body_control">
              <div className="right_side_shit_body_item-1">
                <span id="motif_num">1</span>
                <span id="motif_text">Collaborateurs</span>
              </div>
              <div className="right_side_shit_body_item-other">
                <span id="motif_num_other">2</span>
                <span id="motif_num_other">3</span>
                <span id="motif_num_other">4</span>
                <span id="motif_num_other">5</span>
                <span id="motif_num_other">6</span>
              </div>
            </div>

            <hr style={{ width: '100%' }} id="top_section_seperator" />
            <div
              className={
                hasError && !initialValues.doctor_id
                  ? 'right_sidebar_body_item type3'
                  : 'right_sidebar_body_item type3'
              }
            >
              <label>Collaborateurs</label>
              <select
                style={{ width: '100%' }}
                value={initialValues.doctor_id}
                onChange={this.props.handleChangeInput}
                name="doctor_id"
              >
                <option value="">Collaborateurs</option>
                {doctors.map((item) => {
                  return (
                    <option
                      value={item.id}
                    >{`${item.first_name} ${item.last_name}`}</option>
                  );
                })}
              </select>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="right_sidebar_body" id="Date">
            <div className="right_sidebar_body_item">
              <div className="right_sidebar_body_control">
                <div className="right_side_shit_body_item-1">
                  <span id="motif_check_icon">
                    <i className="fas fa-check"></i>
                  </span>
                  <span id="motif_num">2</span>
                  <span id="motif_text">Pathologie</span>
                </div>
                <div className="right_side_shit_body_item-other">
                  <span id="motif_num_other">3</span>
                  <span id="motif_num_other">4</span>
                  <span id="motif_num_other">5</span>
                  <span id="motif_num_other">6</span>
                </div>
              </div>
            </div>
            <hr style={{ width: '100%' }} id="top_section_seperator" />
            <div
              className={
                hasError && !initialValues.motif_id
                  ? 'right_sidebar_body_item type3'
                  : 'right_sidebar_body_item'
              }
            >
              <label>Pathologie</label>
              <div className="input_and_icon">
                <select
                  value={initialValues.motif_id}
                  style={{ width: '100%' }}
                  onChange={this.props.handleChangeInput}
                  name="motif_id"
                >
                  <option value="">Pathologie</option>;
                  {motifs.map((item) => {
                    return <option value={item.id}>{item.label}</option>;
                  })}
                </select>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="right_sidebar_body" id="Date">
            <div className="right_sidebar_body_item">
              <div className="right_sidebar_body_control">
                <div className="right_side_shit_body_item-1">
                  <span id="motif_check_icon">
                    <i className="fas fa-check"></i>
                  </span>
                  <span id="motif_check_icon">
                    <i className="fas fa-check"></i>
                  </span>
                  <span id="motif_num">3</span>
                  <span id="motif_text">Date</span>
                </div>
                <div className="right_side_shit_body_item-other">
                  <span id="motif_num_other">4</span>
                  <span id="motif_num_other">5</span>
                  <span id="motif_num_other">6</span>
                </div>
              </div>
            </div>
            <hr style={{ width: '100%' }} id="top_section_seperator" />
            <div className="right_sidebar_body_item">
              <label>Date</label>
              <div className="input_and_icon">
                <input
                  style={{ width: '100%' }}
                  value={initialValues.date}
                  name="date"
                  onChange={this.props.handleChangeInput}
                  className="form-control"
                  type="date"
                  placeholder="John Doe"
                />
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="right_sidebar_body" id="Creneau">
            <div className="right_sidebar_body_control">
              <div className="right_side_shit_body_item-1">
                <span id="motif_check_icon">
                  <i className="fas fa-check"></i>
                </span>
                <span id="motif_check_icon">
                  <i className="fas fa-check"></i>
                </span>
                <span id="motif_check_icon">
                  <i className="fas fa-check"></i>
                </span>
                <span id="motif_num">4</span>
                <span id="motif_text">Heure rendez-vous</span>
              </div>
              <div className="right_side_shit_body_item-other">
                <span id="motif_num_other">5</span>
                <span id="motif_num_other">6</span>
              </div>
            </div>
            <hr style={{ width: '100%' }} id="top_section_seperator" />
            <div className="right_sidebar_body_item">
              <span className="creneau_container_title">Heure début</span>
              <div
                className={
                  hasError &&
                  (!initialValues.startHours ||
                    moment(
                      moment(`${initialValues.date} ${initialValues.startHours}`).format(
                        'YYYY-MM-DD HH:mm:ss',
                      ),
                    ).isSameOrAfter(
                      moment(`${initialValues.date} ${initialValues.endHours}`).format(
                        'YYYY-MM-DD HH:mm:ss',
                      ),
                    ))
                    ? 'creneau_container type3'
                    : 'creneau_container'
                }
                style={{ maxHeight: '300px', overflow: 'auto' }}
              >
                <div className="input_and_icon" style={{ width: '100%' }}>
                  <input
                    style={{ width: '100%' }}
                    value={initialValues.startHours}
                    name="startHours"
                    onChange={this.props.handleChangeInput}
                    className="form-control"
                    type="time"
                    placeholder="John Doe"
                  />
                </div>
              </div>
            </div>
            <div className="right_sidebar_body_item">
              <span className="creneau_container_title">Heure fin</span>
              <div
                className={
                  hasError &&
                  (!initialValues.endHours ||
                    moment(
                      moment(`${initialValues.date} ${initialValues.startHours}`).format(
                        'YYYY-MM-DD HH:mm:ss',
                      ),
                    ).isAfter(
                      moment(`${initialValues.date} ${initialValues.endHours}`).format(
                        'YYYY-MM-DD HH:mm:ss',
                      ),
                    ))
                    ? 'creneau_container type3'
                    : 'creneau_container'
                }
                style={{ maxHeight: '300px', overflow: 'auto' }}
              >
                <div style={{ width: '100%' }} className="input_and_icon">
                  <input
                    style={{ width: '100%' }}
                    value={initialValues.endHours}
                    name="endHours"
                    onChange={this.props.handleChangeInput}
                    className="form-control"
                    type="time"
                    placeholder="John Doe"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="right_sidebar_body" id="Patient">
            <div className="right_sidebar_body_control">
              <div className="right_side_shit_body_item-1">
                <span id="motif_check_icon">
                  <i className="fas fa-check"></i>
                </span>
                <span id="motif_check_icon">
                  <i className="fas fa-check"></i>
                </span>
                <span id="motif_check_icon">
                  <i className="fas fa-check"></i>
                </span>
                <span id="motif_check_icon">
                  <i className="fas fa-check"></i>
                </span>
                <span id="motif_num">5</span>
                <span id="motif_text">Patient</span>
              </div>
              <div className="right_side_shit_body_item-other">
                <span id="motif_num_other">6</span>
              </div>
            </div>
            <hr style={{ width: '100%' }} id="top_section_seperator" />
            <div
              className={
                (hasError && !initialValues.display_name) ||
                (hasError &&
                  initialValues.display_name &&
                  !validate(initialValues.display_name))
                  ? 'right_sidebar_body_item type3'
                  : 'right_sidebar_body_item'
              }
            >
              <label>Prénom & Nom</label>
              <div className="input_and_icon">
                <input
                  style={{ width: '100%' }}
                  value={initialValues.display_name}
                  onChange={this.props.handleChangeInput}
                  name="display_name"
                  className="form-control"
                  type="text"
                  placeholder="Prénom nom"
                />
              </div>
            </div>

            <div
              className={
                (hasError && !initialValues.phone_number) ||
                (initialValues.phone_number && !isNumeric(initialValues.phone_number))
                  ? 'right_sidebar_body_item type3'
                  : 'right_sidebar_body_item'
              }
            >
              <label>Téléphone</label>
              <div className="input_and_icon">
                <input
                  minLength={8}
                  style={{ width: '100%' }}
                  value={initialValues.phone_number}
                  onChange={this.props.handleChangeInput}
                  name="phone_number"
                  className="form-control"
                  type="text"
                  placeholder="99 999 999"
                />
              </div>
            </div>
            <div className="view_item">
              <label htmlFor="">Ville</label>
              <div className="view_input_container">
                <div style={{ borderBottom: '0' }} className="view_input">
                  <Autocomplete
                    style={{ width: '100%' }}
                    id="size-small-standard"
                    size="small"
                    options={sortCities}
                    onChange={(event, option) => {
                      this.props.handleCityChange(option !== null ? option.title : '');
                    }}
                    defaultValue={{
                      title: initialValues.city,
                    }}
                    getOptionLabel={(option) => option.title}
                    renderInput={(cities) => (
                      <TextField
                        style={{ width: '100%' }}
                        placeholder="Ville"
                        {...cities}
                        error={hasError && !initialValues.city}
                        variant="standard"
                        //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                        //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 5:
        const motif = motifs.find(
          (element) => element.id === parseInt(initialValues.motif_id),
        );
        const coll = doctors.find(
          (element) => element.id === parseInt(initialValues.doctor_id),
        );
        return (
          <div className="right_sidebar_body" id="Validation">
            <div className="right_sidebar_body_control">
              <div className="right_side_shit_body_item-1">
                <span id="motif_check_icon">
                  <i className="fas fa-check"></i>
                </span>
                <span id="motif_check_icon">
                  <i className="fas fa-check"></i>
                </span>
                <span id="motif_check_icon">
                  <i className="fas fa-check"></i>
                </span>
                <span id="motif_check_icon">
                  <i className="fas fa-check"></i>
                </span>
                <span id="motif_check_icon">
                  <i className="fas fa-check"></i>
                </span>
                <span id="motif_num">6</span>
                <span id="motif_text">Validation</span>
              </div>
              <div className="right_side_shit_body_item-other"></div>
            </div>
            <hr id="top_section_seperator" />
            <div className="right_side_shit_body_item-1">
              <div className="validation_control">
                <span style={{ width: '60%' }} id="recap_text">
                  Récaputilatif
                </span>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.handleReset()}
                  className="edit_recap_btn"
                >
                  <i className="fas fa-pen" style={{ padding: '10px 5px' }}></i>Modifier
                </span>
              </div>
            </div>

            <hr id="top_section_seperator" />

            <div className="right_side_shit_body_item-1">
              <div className="recap_info_container">
                <div className="recap_info_additional_validation_control"></div>
                <div className="recap_info_main">
                  <div className="recap_info_container_header">
                    <div
                      style={{ width: '100px', height: '100px' }}
                      className="patient_avatar"
                    >
                      <svg
                        width="33"
                        height="34"
                        viewBox="0 0 33 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.73958 23.2812C9.42708 21.7812 12.9583 21.0312 16.3333 21.0312C19.7083 21.0312 23.2083 21.7812 26.8333 23.2812C30.5208 24.7188 32.3646 26.625 32.3646 29V33.0312H0.302084V29C0.302084 26.625 2.11458 24.7188 5.73958 23.2812ZM21.9583 14.6562C20.3958 16.2188 18.5208 17 16.3333 17C14.1458 17 12.2708 16.2188 10.7083 14.6562C9.14583 13.0938 8.36458 11.2188 8.36458 9.03125C8.36458 6.84375 9.14583 4.96875 10.7083 3.40625C12.2708 1.78125 14.1458 0.96875 16.3333 0.96875C18.5208 0.96875 20.3958 1.78125 21.9583 3.40625C23.5208 4.96875 24.3021 6.84375 24.3021 9.03125C24.3021 11.2188 23.5208 13.0938 21.9583 14.6562Z"
                          fill="#8083A3"
                        />
                      </svg>
                    </div>
                    <h5>{initialValues.display_name}</h5>
                  </div>
                  <div className="recap_info_container_body">
                    <div className="motif_info">
                      <div className="motif_info_item">
                        <div className="motif_info_left_section">
                          <span>Collaborateur </span>
                        </div>
                        <div className="motif_info_right_section">
                          <span className="motif_info_prop">{`${coll.first_name} ${coll.last_name}`}</span>
                        </div>
                      </div>
                      <div className="motif_info_item">
                        <div className="motif_info_left_section">
                          <span>Pathologie </span>
                        </div>
                        <div className="motif_info_right_section">
                          <span className="motif_info_prop">{motif.label}</span>
                        </div>
                      </div>

                      <div className="date_info_item">
                        <div className="date_info_left_section">
                          <span>Date</span>
                        </div>
                        <div className="date_info_right_section">
                          <span className="date_info_prop">
                            {moment(initialValues.date).format('DD/MM/YYYY')}
                          </span>
                        </div>
                      </div>
                      <div className="creneau_info_item">
                        <div className="creneau_info_left_section">
                          <span>Créneau</span>
                        </div>
                        <div className="creneau_info_right_section">
                          <span className="creneau_info_prop">
                            {initialValues.startHours}-{initialValues.endHours}
                          </span>
                        </div>
                      </div>
                      <div className="telephone_info_item">
                        <div className="telephone_info_left_section">
                          <span>Téléphone</span>
                        </div>
                        <div className="telephone_info_right_section">
                          <span className="telephone_info_prop">
                            {initialValues.phone_number}
                          </span>
                        </div>
                      </div>
                      <div className="ville_info_item">
                        <div className="ville_info_left_section">
                          <span>Ville</span>
                        </div>
                        <div className="ville_info_right_section">
                          <span className="motif_info_prop">{initialValues.city}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right_sidebar_footer confirm_btn_container">
              <button
                onClick={async () => {
                  await this.props.submitRDVForm(
                    params,
                    initialValues,
                    userList.length > 1 ? userList : initialValues.doctor_id,
                  );
                  this.props.changeActiveItemSideBarRDV(true);
                }}
                className="confirm_recap_info_btn"
              >
                {isLoadingAddRdv ? (
                  <CircularProgress
                    style={{
                      marginLeft: '4%',
                      width: '35px',
                      height: '25px',
                      color: 'white',
                      animation: 'infinite',
                    }}
                  />
                ) : (
                  'Confirmer'
                )}
              </button>
            </div>
          </div>
        );
      default:
        return 'Unknown step';
    }
  }
  componentWillReceiveProps(nextProps, nextContext) {
    const { initialValues, created, paymentAction } = nextProps;
    if (!initialValues.motif_id) {
      this.setState({
        item: 0,
        hasError: false,
        update: false,
      });
    }
    if ((created || paymentAction) && this.state.showInput) {
      this.setState({
        showInput: !this.state.showInput,
      });
      this.props.handleInitialStateRDV();
      this.openModal();
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { paymentAction } = this.props;
    if (paymentAction !== prevProps.paymentAction && paymentAction) {
      this.setState({
        showInput: !this.state.showInput,
        setIsOpen: false,
      });
    }
  }
  componentDidMount() {
    this.props.handleChangeTopSectionItemLeftRDV(
      this.props.doctors[0].id,
      this.props.getAllRDV,
    );
  }

  render() {
    const {
      initialValues,
      motifs,
      params,
      activeRDV,
      data,
      color,
      getAllRDV,
      getAllDayDispo,
      isLoadingGetAllDayDispo,
      isLoadingGetAllRDV,
      getLastAvailabilityDate,
      isLoadingGetLastAvailability,
      isLoadingAddRdv,
      activeItemSideBarRDV,
      user,
      isLoadingPayment,
      doctors,
      payment,
      userList,
    } = this.props;
    const { setIsOpen, value, showInput, hasError, item } = this.state;
    return (
      <>
        <div className="appointment_details_container">
          <div className="rendez_vous_list">
            <div className="rendez_vous_list_header">
              <div className="rendez_vous_list_header_left_item">
                <span>
                  {' '}
                  {`${moment(params.date).format('DD')}  
                ${
                  moment(params.date).format('MMMM').charAt(0).toUpperCase() +
                  moment(params.date).format('MMMM').substring(1).toLowerCase()
                } 
                ${moment(params.date).format('YYYY')}`}
                </span>
                {activeItemSideBarRDV || activeRDV ? (
                  params.status &&
                  !activeItemSideBarRDV &&
                  !activeRDV &&
                  data &&
                  data.length === 0 ? (
                    <select
                      style={{ borderRadius: '6px' }}
                      onChange={(e) => {
                        if (userList.length > 0) {
                          this.props.changeStatusRdv(
                            {
                              ...params,
                              status: e.target.value,
                            },
                            userList.length > 1 ? userList : userList[0],
                          );
                          this.props.changeStatus(e.target.value);
                        }
                      }}
                      className="form-control"
                      name=""
                      id=""
                    >
                      <option value="">Statut</option>
                      <option value="completed">Terminé</option>
                      <option value="actif">Planifié </option>
                      <option value="canceled">Annulé</option>
                    </select>
                  ) : (
                    ''
                  )
                ) : (
                  <select
                    style={{ borderRadius: '6px' }}
                    onChange={(e) => {
                      if (userList.length > 0) {
                        this.props.changeStatusRdv(
                          {
                            ...params,
                            status: e.target.value,
                          },
                          userList.length > 1 ? userList : userList[0],
                        );
                        this.props.changeStatus(e.target.value);
                      }
                    }}
                    className="form-control"
                    name=""
                    id=""
                  >
                    <option value="">Statut</option>
                    <option value="completed">Terminé</option>
                    <option value="actif">Planifié</option>
                    <option value="canceled">Annulé</option>
                  </select>
                )}
              </div>
            </div>
            {isLoadingGetAllRDV ? (
              <CircularProgress
                disableShrink
                style={{ marginLeft: '47%', marginTop: '23%' }}
              />
            ) : getAllRDV && getAllRDV.length > 0 ? (
              <div className="rendez_vous_list_body">
                {getAllRDV.map((item) => {
                  return (
                    <Item_rdv
                      key={item.id}
                      showRightSideBar={activeItemSideBarRDV}
                      activeRDV={activeRDV}
                      deleteRdv={this.props.deleteRdv}
                      openModal={this.openModal}
                      getItemRdv={this.props.getItemRdv}
                      getPayment={this.props.getPayment}
                      submitRDVForm={this.props.submitRDVForm}
                      params={params}
                      user={user}
                      userList={userList}
                      item={item}
                      color={color}
                      payment={payment}
                      isLoadingPayment={isLoadingPayment}
                    />
                  );
                })}
              </div>
            ) : (
              <div className="not_app_container">
                <img src={require('../../../assets/img/pas_de_disponibilite.svg')} />
                <span id="appointment_details_indispo">Pas de séances !</span>
                <span id="appointment_details_sub">
                  Essayez de créer des nouveaux séances ou synchroniser votre service de
                  calendrier
                </span>
              </div>
            )}
          </div>
        </div>
        <div
          className="right_side_bar"
          style={{
            width: '40%',
            height: '100%',
            marginRight: '2%',
            display: activeItemSideBarRDV || activeRDV ? '' : 'none',
            borderLeft: '1px solid #ECEEF5',
            overflowY: 'auto',
          }}
        >
          <div className="right_side_bar_header">
            <span id="add_app_sidebar_title">Ajouter un nouveau rendez-vous</span>
            <svg
              onClick={() => {
                this.props.handleShowRightSideBarRDV();
                this.props.changeActiveItemSideBarRDV(activeItemSideBarRDV);
              }}
              style={{ cursor: 'pointer' }}
              id="close-right-sidebar"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="39"
                height="39"
                rx="9.5"
                fill="white"
                stroke="#ECEEF5"
              />
              <path
                d="M25.2383 15.8164L21.0547 20L25.2383 24.1836L24.1836 25.2383L20 21.0547L15.8164 25.2383L14.7617 24.1836L18.9453 20L14.7617 15.8164L15.8164 14.7617L20 18.9453L24.1836 14.7617L25.2383 15.8164Z"
                fill="#8083A3"
              />
            </svg>
          </div>
          <div className="right_side_bar_body">
            {this.getStepContent(
              item,
              initialValues,
              motifs,
              getAllDayDispo,
              isLoadingGetAllDayDispo,
              params,
              hasError,
              getLastAvailabilityDate,
              isLoadingGetLastAvailability,
              isLoadingAddRdv,
              doctors,
              userList,
            )}
            {item === 5 ? (
              ''
            ) : (
              <div
                className="right_sidebar_footer"
                style={{ marginTop: '10%', marginBottom: '10%' }}
              >
                {item > 0 ? (
                  <svg
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      this.handleBack();
                    }}
                    className="patient_previous_button"
                    width="46"
                    height="46"
                    viewBox="0 0 46 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="45.5"
                      width="45"
                      height="45"
                      rx="9.5"
                      transform="rotate(-90 0.5 45.5)"
                      fill="white"
                      stroke="#ECEEF5"
                    />
                    <path
                      d="M20.0117 23L24.5117 18.5L25.5664 19.5547L22.1211 23L25.5664 26.4453L24.5117 27.5L20.0117 23Z"
                      fill="#8083A3"
                    />
                  </svg>
                ) : (
                  ''
                )}
                <button
                  onClick={() => {
                    this.handleNextItem(item);
                  }}
                  className="motif_next_button"
                >
                  Suivant
                </button>
              </div>
            )}
          </div>
        </div>
        <Modals
          setIsOpen={setIsOpen}
          openModal={this.openModal}
          data={
            <>
              <div
                className="modal-header"
                style={{ height: '1px', marginTop: '-22px', marginBottom: '1%' }}
              >
                <span style={{ marginLeft: '1%' }}>Détails rendez-vous</span>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModal()}
                  //style={{ marginTop: '-3%', margin: '0' }}
                >
                  <span aria-hidden="true" style={{ color: '#1A1C1D' }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="modal_body_section_1">
                  <div className="modal_body_section_1_left">
                    <div
                      style={{
                        width: '65px',
                        height: '65px',
                      }}
                      className="patient_contact_img_container"
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                          fill="#8083A3"
                        />
                      </svg>
                    </div>
                    <div className="user_gen_info">
                      <h4>{initialValues.display_name_update}</h4>
                      <span id="recap_username">{initialValues.city_update}</span>
                    </div>
                  </div>
                  {value === 2 ? (
                    !showInput ? (
                      <div className="edit_button" style={{ background: 'white' }}>
                        <button
                          className="edit_btn"
                          onClick={() => this.handleShowInput()}
                        >
                          Modifier
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={async () => {
                          if (
                            initialValues.startDate_update &&
                            getAllDayDispo &&
                            getAllDayDispo.length > 0
                          ) {
                            await this.props.submitRDVForm(
                              params,
                              {
                                note: initialValues.note,
                                startHours: initialValues.startHours,
                                endHours: initialValues.endHours,
                                doctor_id: initialValues.doctor_id,
                                display_name: initialValues.display_name_update,
                                city: initialValues.city,
                                time: initialValues.time_update,
                                date: moment(initialValues.startDate_update).format(
                                  'YYYY-MM-DD',
                                ),
                                motif_id: initialValues.motif_id_update,
                                phone_number: initialValues.phone_number_update,
                                id: initialValues.id_update,
                              },
                              userList.length > 1 ? userList : initialValues.doctor_id,
                            );
                            if (!isLoadingGetAllRDV)
                              this.props.handleChangeTopSectionItemLeftRDV(
                                initialValues.doctor_id,
                              );
                          }
                        }}
                        className="btn_valid"
                      >
                        Valider
                      </button>
                    )
                  ) : value === 0 ? (
                    initialValues.status === 'canceled' ||
                    initialValues.status === 'completed' ? (
                      ''
                    ) : !showInput ? (
                      <div className="edit_button" style={{ background: 'white' }}>
                        <button
                          className="edit_btn"
                          onClick={() => this.handleShowInput()}
                        >
                          Modifier
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={async () => {
                          if (
                            initialValues.startDate_update &&
                            getAllDayDispo &&
                            getAllDayDispo.length > 0
                          ) {
                            await this.props.submitRDVForm(
                              params,
                              {
                                doctor_id: initialValues.doctor_id,
                                display_name: initialValues.display_name_update,
                                startHours: initialValues.startHours,
                                note: initialValues.note,
                                endHours: initialValues.endHours,
                                city: initialValues.city,
                                time: initialValues.time_update,
                                date: moment(initialValues.startDate_update).format(
                                  'YYYY-MM-DD',
                                ),
                                motif_id: initialValues.motif_id_update,
                                phone_number: initialValues.phone_number_update,
                                id: initialValues.id_update,
                              },
                              userList.length > 1 ? userList : initialValues.doctor_id,
                            );
                            if (!isLoadingGetAllRDV)
                              this.props.handleChangeTopSectionItemLeftRDV(
                                initialValues.doctor_id,
                              );
                          }
                        }}
                        className="btn_valid"
                      >
                        Valider
                      </button>
                    )
                  ) : value === 1 &&
                    initialValues.status !== 'canceled' &&
                    initialValues.status !== 'completed' ? (
                    !payment.id ? (
                      <button
                        onClick={async () => {
                          if (
                            payment.date ||
                            payment.settled_in_session ||
                            payment.payment_method ||
                            payment.session_price ||
                            payment.money_pot_amount ||
                            payment.total_achieved ||
                            payment.already_paid ||
                            payment.left_to_pay
                          )
                            await this.props.submitPaymentForm(
                              params,
                              {
                                total_achieved: payment.total_achieved,
                                already_paid: payment.already_paid,
                                left_to_pay: payment.left_to_pay,
                                money_pot_amount: payment.money_pot_amount,
                                session_price: payment.session_price,
                                payment_method: payment.payment_method,
                                settled_in_session: payment.settled_in_session,
                                date: moment(payment.date).format('YYYY-MM-DD'),
                                id: payment.id,
                                appointment_id: initialValues.id,
                              },
                              userList.length > 1 ? userList : initialValues.doctor_id,
                            );
                        }}
                        className="btn_valid"
                      >
                        Valider
                      </button>
                    ) : !showInput ? (
                      <div className="edit_button" style={{ background: 'white' }}>
                        <button
                          className="edit_btn"
                          onClick={() => this.handleShowInput()}
                        >
                          Modifier
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={async () => {
                          if (
                            payment.date ||
                            payment.settled_in_session ||
                            payment.payment_method ||
                            payment.session_price ||
                            payment.money_pot_amount ||
                            payment.total_achieved ||
                            payment.already_paid ||
                            payment.left_to_pay
                          )
                            await this.props.submitPaymentForm(
                              params,
                              {
                                total_achieved: payment.total_achieved,
                                already_paid: payment.already_paid,
                                left_to_pay: payment.left_to_pay,
                                money_pot_amount: payment.money_pot_amount,
                                session_price: payment.session_price,
                                payment_method: payment.payment_method,
                                settled_in_session: payment.settled_in_session,
                                date: moment(payment.date).format('YYYY-MM-DD'),
                                id: payment.id,
                                appointment_id: initialValues.id,
                              },
                              userList.length > 1 ? userList : initialValues.doctor_id,
                            );
                        }}
                        className="btn_valid"
                      >
                        Valider
                      </button>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <AppBar position="static">
                  <Tabs
                    value={value}
                    onChange={this.handleChange}
                    aria-label="simple tabs example"
                  >
                    <Tab
                      onClick={() => this.setState({ showInput: false })}
                      label="Détails"
                      {...this.a11yProps(0)}
                    />
                    <Tab
                      onClick={() => this.setState({ showInput: false })}
                      label="Paiement"
                      {...this.a11yProps(1)}
                    />
                    <Tab
                      onClick={() => this.setState({ showInput: false })}
                      label="Note"
                      {...this.a11yProps(2)}
                    />
                  </Tabs>
                </AppBar>
                <br />
                <TabPanel value={value} index={0}>
                  <div className="modal_body_recap_details">
                    <div className="modal_body_recap_details_item">
                      <div className="modal_body_recap_details_item_left">
                        <span>Pathologie </span>
                      </div>
                      <div className="modal_body_recap_details_item_right">
                        <div className="conto">
                          <span style={{ lineHeight: '42px' }}>
                            {initialValues.labelMotif_update}
                          </span>
                          <button
                            style={{ cursor: 'auto' }}
                            className={
                              initialValues.status === 'actif'
                                ? 'status_rdv btn_status actif_status'
                                : 'status_rdv btn_status span_completed'
                            }
                            //  className="status_rdv span_completed"
                          >
                            {initialValues.status === 'actif'
                              ? 'Actif'
                              : initialValues.status === 'canceled'
                              ? 'Annulé'
                              : 'Terminé'}
                          </button>
                        </div>
                      </div>
                    </div>
                    <hr id="top_section_seperator" />
                    <div className="modal_body_recap_details_item">
                      <div className="modal_body_recap_details_item_left">
                        <span>Date</span>
                      </div>
                      <div className="modal_body_recap_details_item_right">
                        <span style={{ display: showInput ? 'none' : '' }}>
                          {moment(initialValues.startDate_update).format('DD/MM/YYYY')}
                        </span>
                        <input
                          style={{ display: !showInput ? 'none' : '', width: '79%' }}
                          value={initialValues.startDate_update}
                          // min={moment()}
                          name="startDate_update"
                          onChange={(e) => {
                            this.props.handleChangeInput(e);
                          }}
                          className="form-control"
                          type="date"
                        />
                      </div>
                    </div>

                    <div
                      className="modal_body_recap_details_item"
                      style={{ height: showInput ? '145px' : '50px' }}
                    >
                      <div className="modal_body_recap_details_item_left">
                        <span>Heure</span>
                      </div>
                      <div
                        className="modal_body_recap_details_item_right"
                        style={{ marginLeft: showInput ? '2%' : '' }}
                      >
                        {showInput ? (
                          <div
                            className="creneau_container"
                            style={{ width: '80%', maxHeight: '125px', overflow: 'auto' }}
                          >
                            <div className="right_sidebar_body_item">
                              <span className="creneau_container_title">Heure début</span>
                              <div
                                className={
                                  hasError &&
                                  (!initialValues.startHours ||
                                    moment(
                                      moment(
                                        `${initialValues.date} ${initialValues.startHours}`,
                                      ).format('YYYY-MM-DD HH:mm:ss'),
                                    ).isAfter(
                                      moment(
                                        `${initialValues.date} ${initialValues.endHours}`,
                                      ).format('YYYY-MM-DD HH:mm:ss'),
                                    ))
                                    ? 'creneau_container type3'
                                    : 'creneau_container'
                                }
                                style={{ maxHeight: '300px', overflow: 'auto' }}
                              >
                                <div className="input_and_icon" style={{ width: '100%' }}>
                                  <input
                                    style={{ width: '100%' }}
                                    value={initialValues.startHours}
                                    name="startHours"
                                    onChange={this.props.handleChangeInput}
                                    className="form-control"
                                    type="time"
                                    placeholder="John Doe"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="right_sidebar_body_item">
                              <span className="creneau_container_title">Heure fin</span>
                              <div
                                className={
                                  hasError &&
                                  (!initialValues.endHours ||
                                    moment(
                                      moment(
                                        `${initialValues.date} ${initialValues.startHours}`,
                                      ).format('YYYY-MM-DD HH:mm:ss'),
                                    ).isAfter(
                                      moment(
                                        `${initialValues.date} ${initialValues.endHours}`,
                                      ).format('YYYY-MM-DD HH:mm:ss'),
                                    ))
                                    ? 'creneau_container type3'
                                    : 'creneau_container'
                                }
                                style={{ maxHeight: '300px', overflow: 'auto' }}
                              >
                                <div style={{ width: '100%' }} className="input_and_icon">
                                  <input
                                    style={{ width: '100%' }}
                                    value={initialValues.endHours}
                                    name="endHours"
                                    onChange={this.props.handleChangeInput}
                                    className="form-control"
                                    type="time"
                                    placeholder="John Doe"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <span style={{ display: showInput ? 'none' : '' }}>
                            {initialValues.startHours}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="modal_body_recap_details_item">
                      <div className="modal_body_recap_details_item_left">
                        <span>Collaborateur</span>
                      </div>
                      <div className="modal_body_recap_details_item_right">
                        <div className="conto">
                          <span style={{ display: showInput ? 'none' : '' }}>
                            {initialValues.doctor
                              ? `${initialValues.doctor.first_name} ${initialValues.doctor.last_name}`
                              : ''}
                          </span>
                          <select
                            style={{ display: !showInput ? 'none' : '' }}
                            value={initialValues.doctor_id}
                            name="doctor_id"
                            onChange={this.props.handleChangeInput}
                            className="form-control"
                            id="exampleFormControlSelect1"
                          >
                            <option value="">Collaborateur</option>
                            {doctors &&
                              doctors.length > 0 &&
                              doctors.map((item) => {
                                return (
                                  <option
                                    key={item.id}
                                    data-label={`${item.first_name} ${item.last_name}`}
                                    value={parseInt(item.id)}
                                  >
                                    {`${item.first_name} ${item.last_name}`}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className="modal_body_recap_details">
                    <div className="modal_body_recap_details_item">
                      <div className="modal_body_recap_details_item_left">
                        <span>Total réalisé </span>
                      </div>
                      <div className="modal_body_recap_details_item_right">
                        <div className="conto">
                          <span
                            style={{
                              display: showInput
                                ? 'none'
                                : !showInput && !payment.id
                                ? 'none'
                                : '',
                            }}
                          >
                            {payment.total_achieved}
                          </span>
                          <input
                            style={{
                              display: !showInput && payment.id ? 'none' : '',
                              width: '79%',
                            }}
                            value={payment.total_achieved}
                            onChange={this.props.handleChangeInputPayment}
                            name="total_achieved"
                            className="form-control type3"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <hr id="top_section_seperator" />
                    <div className="modal_body_recap_details_item">
                      <div className="modal_body_recap_details_item_left">
                        <span>Déjà réglé</span>
                      </div>
                      <div className="modal_body_recap_details_item_right">
                        <span
                          style={{
                            display: showInput
                              ? 'none'
                              : !showInput && !payment.id
                              ? 'none'
                              : '',
                          }}
                        >
                          {payment.already_paid}
                        </span>
                        <input
                          style={{
                            display: !showInput && payment.id ? 'none' : '',
                            width: '79%',
                          }}
                          value={payment.already_paid}
                          onChange={this.props.handleChangeInputPayment}
                          name="already_paid"
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>

                    <div
                      className="modal_body_recap_details_item"
                      style={{ height: showInput ? '50px' : '50px' }}
                    >
                      <div className="modal_body_recap_details_item_left">
                        <span>Reste à payer</span>
                      </div>
                      <div className="modal_body_recap_details_item_right">
                        <span
                          style={{
                            display: showInput
                              ? 'none'
                              : !showInput && !payment.id
                              ? 'none'
                              : '',
                          }}
                        >
                          {payment.left_to_pay}
                        </span>
                        <input
                          style={{
                            display: !showInput && payment.id ? 'none' : '',
                            width: '79%',
                          }}
                          value={payment.left_to_pay}
                          onChange={this.props.handleChangeInputPayment}
                          name="left_to_pay"
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="modal_body_recap_details_item">
                      <div className="modal_body_recap_details_item_left">
                        <span>Montant cagnotte</span>
                      </div>
                      <div className="modal_body_recap_details_item_right">
                        <span
                          style={{
                            display: showInput
                              ? 'none'
                              : !showInput && !payment.id
                              ? 'none'
                              : '',
                          }}
                        >
                          {payment.money_pot_amount}
                        </span>
                        <input
                          style={{
                            display: !showInput && payment.id ? 'none' : '',
                            width: '79%',
                          }}
                          value={payment.money_pot_amount}
                          onChange={this.props.handleChangeInputPayment}
                          name="money_pot_amount"
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="modal_body_recap_details_item">
                      <div className="modal_body_recap_details_item_left">
                        <span>Tarif Séance</span>
                      </div>
                      <div className="modal_body_recap_details_item_right">
                        <span
                          style={{
                            display: showInput
                              ? 'none'
                              : !showInput && !payment.id
                              ? 'none'
                              : '',
                          }}
                        >
                          {payment.session_price}
                        </span>
                        <input
                          style={{
                            display: !showInput && payment.id ? 'none' : '',
                            width: '79%',
                          }}
                          value={payment.session_price}
                          onChange={this.props.handleChangeInputPayment}
                          name="session_price"
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="modal_body_recap_details_item">
                      <div className="modal_body_recap_details_item_left">
                        <span>Mode paiement</span>
                      </div>
                      <div className="modal_body_recap_details_item_right">
                        <span
                          style={{
                            display: showInput
                              ? 'none'
                              : !showInput && !payment.id
                              ? 'none'
                              : '',
                          }}
                        >
                          {payment.payment_method}
                        </span>
                        <select
                          style={{
                            display: !showInput && payment.id ? 'none' : '',
                            width: '82%',
                          }}
                          onChange={this.props.handleChangeInputPayment}
                          name="payment_method"
                          value={payment.payment_method}
                        >
                          <option>Mode paiement</option>;
                          {ModePaiement.map((modePaiement) => {
                            return (
                              <option value={modePaiement.title}>
                                {modePaiement.title}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="modal_body_recap_details_item">
                      <div className="modal_body_recap_details_item_left">
                        <span>Réglé en séance</span>
                      </div>
                      <div className="modal_body_recap_details_item_right">
                        <span
                          style={{
                            display: showInput
                              ? 'none'
                              : !showInput && !payment.id
                              ? 'none'
                              : '',
                          }}
                        >
                          {payment.settled_in_session}
                        </span>
                        <input
                          style={{
                            display: !showInput && payment.id ? 'none' : '',
                            width: '79%',
                          }}
                          defaultValue={payment.settled_in_session}
                          onChange={this.props.handleChangeInputPayment}
                          name="settled_in_session"
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="modal_body_recap_details_item">
                      <div className="modal_body_recap_details_item_left">
                        <span>Date de réglement</span>
                      </div>
                      <div className="modal_body_recap_details_item_right">
                        <span
                          style={{
                            display: showInput
                              ? 'none'
                              : !showInput && !payment.id
                              ? 'none'
                              : '',
                          }}
                        >
                          {moment(payment.date).format('DD/MM/YYYY')}
                        </span>
                        <input
                          style={{
                            display: !showInput && payment.id ? 'none' : '',
                            width: '79%',
                          }}
                          defaultValue={payment.date}
                          onChange={this.props.handleChangeInputPayment}
                          name="date"
                          className="form-control"
                          type="date"
                        />
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div className="modal_body_recap_details">
                    <div className="modal_body_recap_details_item">
                      <div className="modal_body_recap_details_item_left">
                        <span>Note</span>
                      </div>
                      <div className="modal_body_recap_details_item_right">
                        <span style={{ display: showInput ? 'none' : '' }}>
                          {' '}
                          {initialValues.note}
                        </span>
                        <textarea
                          style={{ display: !showInput ? 'none' : '', width: '79%' }}
                          value={initialValues.note}
                          onChange={this.props.handleChangeInput}
                          name="note"
                          className="form-control"
                        >
                          {initialValues.note}
                        </textarea>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                {/*<div className="footer_buttons">
                  <button
                    style={{ width: '18%', marginRight: '65%' }}
                    className="btn_valid"
                  >
                    Finaliser
                  </button>
                  <button id="cancel" style={{ width: '20%' }} className="footer_btn">
                    Enregistrer
                  </button>
                </div>*/}
              </div>
            </>
          }
        />
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(List_rdv);
