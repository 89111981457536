import React, { Component } from 'react';
import actions from '../store/actions';
import actionsPatient from '../../patient/store/actions';
import { connect } from 'react-redux';
import InputField from '../../../components/inputField';
import CircularProgress from '@material-ui/core/CircularProgress';

const mapStateToProps = (state, ownProps) => {
  const { recette } = ownProps;
  let label;
  let payment_mode;
  let payment_date;
  let note;
  let id;
  let orde_virement;
  let price;
  let date_cheque;
  let num_cheque;
  if (recette) {
    id = recette.id;
    label = recette.label;
    payment_mode = recette.payment_mode;
    payment_date = recette.payment_date;
    note = recette.note || '';
    orde_virement = recette.orde_virement;
    date_cheque = recette.date_cheque;
    num_cheque = recette.num_cheque;
    price = recette.price;
  }
  return {
    sandwich_menu_btn: state.AppReducer.sandwich_menu_btn,
    isLoadingActionRecette: state.RecetteReducer.isLoadingActionRecette,
    message: state.FactureReducer.message,
    error: state.FactureReducer.error,
    user: state.AppReducer.user,
    params: state.RecetteReducer.params,
    initialValues: {
      ...recette,
      id,
      label,
      num_cheque,
      date_cheque: date_cheque || '',
      price,
      orde_virement,
      payment_mode,
      payment_date,
      note: note || '',
    },
  };
};
const mapDispatchToProps = (dispatch) => ({
  submitRecetteForm: async (values, params) => {
    await dispatch(actions.submitRecetteForm(values));
    if (!values.patient_id) {
      await dispatch(actions.getAllRecettes(params));
    } else await dispatch(actionsPatient.getPatientStatistical(values.patient_id));
  },
});

class FormRecette extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        ...props.initialValues,
        alert: false,
      },
      activeTab: '1',
      isOpenToggleDiplome: false,
      isOpenToggleFormation: false,
      errorDate: false,
      hasError: false,
      errorDateBill: false,
    };
    this.handleInitialValues = this.handleInitialValues.bind(this);
  }
  handleInitialValues() {
    this.setState({
      initialValues: {
        note: '',
        payment_date: '',
        payment_mode: '',
        label: '',
        orde_virement: '',
        price: '',
        date_cheque: '',
        num_cheque: '',
      },
      hasError: false,
      errorDateBill: false,
    });
  }
  submitForm(initialValues, patient_id) {
    if (
      !initialValues.label ||
      !initialValues.payment_date ||
      !initialValues.payment_mode ||
      !initialValues.price
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else {
      if (!patient_id) {
        this.props.submitRecetteForm(initialValues, this.props.params);
        this.props.changeActiveItemSideBarRDV(this.props.activeItemSideBarRDV);
        this.props.getRecetteById(null);
      } else {
        initialValues.patient_id = patient_id;
        this.props.submitRecetteForm(initialValues, this.props.params);
        this.props.onCancel();
      }
    }
  }

  render() {
    const { isLoadingActionRecette, patient_id } = this.props;
    const { initialValues, hasError } = this.state;
    return (
      <form>
        <div className="form-row">
          <InputField
            width="46%"
            className="form-group"
            type="text"
            placeholder="Motif"
            label="Motif"
            hasError={hasError}
            //  validInput="string"
            value={initialValues.label}
            name="label"
            icon={<i className="fas fa-pen"></i>}
            onChange={(e) => {
              this.setState({
                initialValues: {
                  ...initialValues,
                  label: e.target.value,
                },
              });
            }}
          />
          <InputField
            width="46%"
            className="form-group"
            type="date"
            placeholder="Date de paiment"
            label="Date de paiment"
            //errorDate={initialValues.payment_date}
            hasError={hasError}
            value={initialValues.payment_date}
            name="payment_date"
            icon=""
            onChange={(e) => {
              this.setState({
                initialValues: {
                  ...initialValues,
                  payment_date: e.target.value,
                },
              });
            }}
          />
        </div>
        <div className="form-row">
          <div
            style={{
              width: '46%',
            }}
            className={
              hasError && !initialValues.payment_mode ? 'form-group type3' : 'form-group'
            }
          >
            <label htmlFor="">Mode de paiement</label>
            <div className="input_and_icon">
              <select
                value={initialValues.payment_mode}
                style={{
                  marginBottom: '1%',
                  height: 'fit-content',
                  alignSelf: 'end',
                  width: '100%',
                  border: 0,
                }}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      payment_mode: e.target.value,
                    },
                  });
                }}
              >
                <option value="">Choisissez</option>
                <option value="Espèce">Espèce</option>
                <option value="Chèque">Chèque</option>
                <option value="Virement">Virement</option>
              </select>
            </div>
          </div>
          <InputField
            width="46%"
            className="form-group"
            type="text"
            placeholder="12.00"
            label="Prix"
            hasError={hasError}
            value={initialValues.price}
            name="price"
            icon={<i className="fas fa-pen"></i>}
            validInput="integer"
            onChange={(e) => {
              this.setState({
                initialValues: {
                  ...initialValues,
                  price: e.target.value,
                },
              });
            }}
          />
        </div>
        {initialValues.payment_mode === 'Chèque' ? (
          <div className="form-row">
            <InputField
              width="46%"
              className="form-group"
              type="text"
              placeholder="Numéro du chèque"
              label="Numéro du chèque"
              hasError={hasError}
              value={initialValues.num_cheque}
              name="num_cheque"
              icon={<i className="fas fa-pen"></i>}
              validInput="string"
              onChange={(e) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    num_cheque: e.target.value,
                  },
                });
              }}
            />
            <InputField
              width="50%"
              className="form-group"
              type="date"
              placeholder="Date d'échéance"
              label="Date d'échéance"
              hasError={hasError}
              value={initialValues.date_cheque}
              name="date_cheque"
              icon={<i className="fas fa-pen"></i>}
              validInput="string"
              onChange={(e) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    date_cheque: e.target.value,
                  },
                });
              }}
            />
          </div>
        ) : (
          ''
        )}
        {initialValues.payment_mode === 'Virement' ? (
          <div className="form-row">
            <InputField
              width="50%"
              className="form-group"
              type="text"
              placeholder="Ordre de virement"
              label="Ordre de virement"
              hasError={hasError}
              value={initialValues.orde_virement}
              name="orde_virement"
              icon={<i className="fas fa-pen"></i>}
              validInput="string"
              onChange={(e) => {
                this.setState({
                  initialValues: {
                    ...initialValues,
                    orde_virement: e.target.value,
                  },
                });
              }}
            />
          </div>
        ) : (
          ''
        )}

        <div
          className="form-4row"
          style={{
            width: '98%',
          }}
        >
          <div
            style={{ width: '100%' }}
            className="col-md-12 mb-3 commentaire add_dispo_item commentaire"
          >
            <span id="date_de_debut_title">
              Note ({(initialValues.note && initialValues.note.length) || 0}/255)
            </span>
            <div className="date_de_debut_icon input_and_icon">
              <textarea
                maxLength="255"
                rows={5}
                style={{ width: '100%' }}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      note: e.target.value,
                    },
                  });
                }}
                value={initialValues.note}
                name="message"
                className="sub_text_container"
                id="date_de_debut_text"
                placeholder="Note"
              ></textarea>
            </div>
            <span></span>
          </div>
        </div>
        <div className="footer_buttons">
          {/*<button
            type="button"
            onClick={() => {
              this.handleInitialValues();
            }}
            className="footer_btn"
            id="cancel"
          >
            Annuler
          </button>*/}
          <button
            style={{ marginRight: '5%' }}
            onClick={() => {
              this.submitForm(initialValues, patient_id);
            }}
            type="button"
            className="footer_btn"
            id="update"
          >
            {isLoadingActionRecette ? (
              <CircularProgress
                style={{
                  marginLeft: '4%',
                  width: '25px',
                  height: '25px',
                  color: 'white',
                  marginTop: '5%',
                }}
              />
            ) : (
              'Valider'
            )}
          </button>
        </div>
      </form>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormRecette);
