import { apiCall } from '../../../js/actions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastAction from '../../../components/ToastAction';
import React from 'react';

const getBilanList = (params) => async (dispatch) => {
  dispatch({ type: 'GET_BILAN_REQUESTING' });
  try {
    const { search } = params || {
      search: '',
    };
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: 10,
    };
    let url = `/api/bilan?limit=${limit}&current_page=${currentPage}&search=${search}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_BILAN_LIST_SUCCESS',
      data: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_BILAN_LIST_FAILURE',
      err: e.message,
    });
  }
};

const submitFormBilan = (values) => async (dispatch) => {
  dispatch({ type: 'SUBMIT_FORM_BILAN_REQUESTING' });
  try {
    let url = `/api/upload/file/bilan`;
    let formData = new FormData();
    formData.append('id', parseInt(values.id));
    formData.append('bilan_id', parseInt(values.bilan_id));
    formData.append('consultation_id', parseInt(values.consultation_id));

    for (var key in values.initialValues) {
      if (key !== 'bilan_id' && key !== 'consultation_id' && key !== 'id') {
        formData.append(
          key,
          Array.isArray(values.initialValues[key]) &&
            typeof values.initialValues[key] !== 'string'
            ? JSON.stringify(values.initialValues[key])
            : values.initialValues[key],
        );
      }
    }
    if (values.initialValues.file && values.initialValues.file.name) {
      formData.append('file', values.initialValues.file);
    }
    const { data } = await apiCall({
      method: `post`,
      url,
      data: formData,
    });
    toast(
      ({ closeToast }) => <ToastAction type="success" message="Bilan créé avec succès" />,
      { hideProgressBar: true },
    );
    dispatch({
      type: 'SUBMIT_FORM_BILAN_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec de création de bilan" />
      ),
      { hideProgressBar: true },
    );
    dispatch({
      type: 'SUBMIT_FORM_BILAN_FAILURE',
      err: e.message,
    });
  }
};

const getSectionBilanFile = (bilan_id, consultation_id) => async (dispatch) => {
  dispatch({ type: 'GET_SECTION_BILAN_REQUESTING' });
  try {
    let url = `/api/file/bilan/${bilan_id}/${consultation_id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_SECTION_BILAN_LIST_SUCCESS',
      data: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_SECTION_BILAN_LIST_FAILURE',
      err: e.message,
    });
  }
};
const initialState = () => ({
  type: 'INITIAL_STATE',
});
const changeSearchedString = (params) => ({
  type: 'UPDATE_SEARCH_BILAN_PARAMS',
  params: params,
});

export default {
  initialState,
  getBilanList,
  getSectionBilanFile,
  submitFormBilan,
  changeSearchedString,
};
