import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import routes from '../routes';
import Pusher from 'pusher-js';

import actions from '../js/actions';
import actionsMessenger from '../pages/messenger/store/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Factures from '../pages/facture';
import { isHasPermission } from '../utils/helpres';
const pusher = new Pusher('9c5bef3c6c40e92e26cb', {
  cluster: 'eu',
  encrypted: true,
});
const channel = pusher.subscribe('conversations');
const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  tag: state.AppReducer.tag,
  countMessagesIsSeenNow: state.AppReducer.countMessageIsSeen,
});

const mapDispatchToProps = (dispatch) => ({
  countMessagesIsSeen: () => dispatch(actionsMessenger.countMessagesIsSeen()),
  countMessagesIsSeenTo: (id) => dispatch(actionsMessenger.countMessagesIsSeenTo(id)),
  changeCountMessage: (countMessage) =>
    dispatch(actions.changeCountMessage(countMessage)),
});
class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countMessageIsSeen: 0,
      activeLink: 0,
      dropDown: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    channel.bind(
      `countMessageIsSeen-office-${this.props.user.login_access.office_id}`,
      (data) => {
        if (
          data &&
          this.props.user.login_access.acount_type !== data.user_type &&
          data.user_id !== this.props.user.id
        )
          this.props.changeCountMessage(data.count);
      },
    );
    channel.bind(`countMessageIsSeen-${this.props.user.login_access.id}`, (data) => {
      this.props.changeCountMessage(data.count);
    });
    channel.bind(`countMessageIsSeen-to-${this.props.user.login_access.id}`, (data) => {
      this.props.changeCountMessage(data.count);
    });
  }

  handleClick = (id) => {
    this.setState({ activeLink: id });
    let name;
    switch (id) {
      case 0:
        name = 'Tableau de bord';
        break;
      case 1:
        name = 'Agenda';
        break;
      case 2:
        name = 'Cures';
        break;
      case 3:
        name = 'Patients';
        break;
      case 4:
        name = 'Messenger';
        break;
      case 5:
        name = 'Factures';
        break;
      case 6:
        name = 'Bordereaux';
        break;
      case 7:
        name = 'Support';
        break;
      case 9:
        name = 'Comptabilité';
        break;
      case 10:
        name = 'Module CNAM';
        break;
      default:
        name = '';
    }
    this.props.changeNamePage(name);
    if (id !== 10 && id !== 9) this.props.changeActiveItemSideBar(id);
  };
  render() {
    const {
      countMessagesIsSeenNow,
      user,
      tag,
      activeItemSideBar,
      sandwich_menu_btn,
      admin,
    } = this.props;
    return (
      <section
        className={sandwich_menu_btn ? 'sidenav dfde activated' : 'sidenav dfde'}
        //style={{ width: sandwich_menu_btn ? '250px' : '72px' }}
      >
        <div className="sidenav_container">
          <div className="sidenav_items">
            <div
              className={
                sandwich_menu_btn
                  ? 'sidenav_container_item main_logo new_container_layout'
                  : 'sidenav_container_item main_logo'
              }
            >
              <Link to="/dashboard">
                <div
                  className="item_section_left"
                  style={{ marginRight: sandwich_menu_btn ? '15px' : '0' }}
                >
                  <img
                    className="main_log"
                    src={require('../assets/img/Dark.svg')}
                    width="37.49"
                    height="40"
                    alt=""
                  />
                </div>
              </Link>
              <div className="item_section_right" id="first">
                {sandwich_menu_btn ? (
                  <Link to="/dashboard">
                    <span className="hi">
                      <img
                        src={require('../assets/img/Cuida.svg')}
                        alt=""
                        className="complet_logo"
                      />
                    </span>
                  </Link>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="sidenav_container_icons">
              {routes.map((route) => {
                if (
                  route.id === 1 &&
                  (admin === true || user.login_access.account_type === 'secretary') &&
                  //!sandwich_menu_btn &&
                  isHasPermission(user.login_access.permissions, route.permission)
                ) {
                  return (
                    <Link key={route.id} to={route.path}>
                      <div
                        title={route.title}
                        onClick={() => this.handleClick(route.id)}
                        className={
                          sandwich_menu_btn && route.id === activeItemSideBar
                            ? 'cont open_navbar_bg_color'
                            : 'cont'
                        }
                      >
                        <div
                          className={
                            sandwich_menu_btn
                              ? 'sidenav_container_item new_container_layout'
                              : 'sidenav_container_item'
                          }
                        >
                          <div
                            id="agenda"
                            className={
                              !sandwich_menu_btn && route.id === activeItemSideBar
                                ? 'item_section_left active_container'
                                : 'item_section_left'
                            }
                            style={{ display: 'block', textAlign: 'center' }}
                          >
                            <img
                              className={
                                (!sandwich_menu_btn && route.id === activeItemSideBar) ||
                                (sandwich_menu_btn && route.id === activeItemSideBar)
                                  ? 'active_icon'
                                  : ''
                              }
                              src={
                                (!sandwich_menu_btn && route.id === activeItemSideBar) ||
                                (sandwich_menu_btn && route.id === activeItemSideBar)
                                  ? route.imgColor
                                  : route.img
                              }
                              width="20px"
                              height="20px"
                              alt=""
                            />
                            {!sandwich_menu_btn && <small>{route.sous_title}</small>}
                          </div>
                          <div className="item_section_right">
                            {sandwich_menu_btn ? (
                              <>
                                <span className="hi">{route.name}</span>
                                <span />
                                {route.id === 4 && countMessagesIsSeenNow > 0 ? (
                                  <span
                                    style={{
                                      color: 'white',
                                      marginTop: '-23px',
                                      marginLeft: '7px',
                                    }}
                                    className="badge badge-pill badge-primary"
                                  >
                                    {countMessagesIsSeenNow}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                }
                if (
                  route.id === 1 &&
                  admin === false &&
                  //!sandwich_menu_btn &&
                  isHasPermission(user.login_access.permissions, route.permission)
                ) {
                  return (
                    <Link key={route.id} to="/agenda-practitioner">
                      <div
                        title={route.title}
                        onClick={() => this.handleClick(route.id)}
                        className={
                          sandwich_menu_btn && route.id === activeItemSideBar
                            ? 'cont open_navbar_bg_color'
                            : 'cont'
                        }
                      >
                        <div
                          className={
                            sandwich_menu_btn
                              ? 'sidenav_container_item new_container_layout'
                              : 'sidenav_container_item'
                          }
                        >
                          <div
                            id="agenda"
                            className={
                              !sandwich_menu_btn && route.id === activeItemSideBar
                                ? 'item_section_left active_container'
                                : 'item_section_left'
                            }
                            style={{ display: 'block', textAlign: 'center' }}
                          >
                            <img
                              className={
                                (!sandwich_menu_btn && route.id === activeItemSideBar) ||
                                (sandwich_menu_btn && route.id === activeItemSideBar)
                                  ? 'active_icon'
                                  : ''
                              }
                              src={
                                (!sandwich_menu_btn && route.id === activeItemSideBar) ||
                                (sandwich_menu_btn && route.id === activeItemSideBar)
                                  ? route.imgColor
                                  : route.img
                              }
                              width="20px"
                              height="20px"
                              alt=""
                            />
                            {!sandwich_menu_btn && <small>{route.sous_title}</small>}
                          </div>
                          <div className="item_section_right">
                            {sandwich_menu_btn ? (
                              <>
                                <span className="hi">{route.name}</span>
                                <span />
                                {route.id === 4 && countMessagesIsSeenNow > 0 ? (
                                  <span
                                    style={{
                                      color: 'white',
                                      marginTop: '-23px',
                                      marginLeft: '7px',
                                    }}
                                    className="badge badge-pill badge-primary"
                                  >
                                    {countMessagesIsSeenNow}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                }
                if (route.id === 7 && !sandwich_menu_btn && !route.permission) {
                  return (
                    <Link key={route.id} to="/support">
                      <div
                        title={route.title}
                        onClick={() => this.handleClick(route.id)}
                        className="cont"
                      >
                        <div
                          className={
                            sandwich_menu_btn
                              ? 'sidenav_container_item new_container_layout'
                              : 'sidenav_container_item'
                          }
                        >
                          <div
                            id="Cabinet"
                            className={
                              !sandwich_menu_btn && route.id === activeItemSideBar
                                ? 'item_section_left active_container'
                                : 'item_section_left'
                            }
                            style={{ display: 'block', textAlign: 'center' }}
                          >
                            <img
                              className={
                                !sandwich_menu_btn && route.id === activeItemSideBar
                                  ? 'active_icon'
                                  : ''
                              }
                              src={
                                !sandwich_menu_btn && route.id === activeItemSideBar
                                  ? route.imgColor
                                  : route.img
                              }
                              width="20px"
                              height="20px"
                              alt=""
                            />
                            {!sandwich_menu_btn && <small>{route.sous_title}</small>}
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                }
                if (
                  route.id !== 7 &&
                  route.id !== 100 &&
                  route.id !== 1 &&
                  route.id !== 9 &&
                  route.id !== 10 &&
                  route.id !== 8 &&
                  isHasPermission(user.login_access.permissions, route.permission)
                ) {
                  return (
                    <Link key={route.id} to={route.path}>
                      <div
                        title={route.title}
                        onClick={() => this.handleClick(route.id)}
                        className={
                          sandwich_menu_btn && route.id === activeItemSideBar
                            ? 'cont open_navbar_bg_color'
                            : 'cont'
                        }
                      >
                        <div
                          className={
                            sandwich_menu_btn
                              ? 'sidenav_container_item new_container_layout'
                              : 'sidenav_container_item'
                          }
                        >
                          <div
                            id="agenda"
                            className={
                              !sandwich_menu_btn && route.id === activeItemSideBar
                                ? 'item_section_left active_container'
                                : 'item_section_left'
                            }
                            style={{ display: 'block', textAlign: 'center' }}
                          >
                            {/*&& countMessagesIsSeenNow > 0 */}
                            <img
                              className={
                                (!sandwich_menu_btn && route.id === activeItemSideBar) ||
                                (sandwich_menu_btn && route.id === activeItemSideBar)
                                  ? 'active_icon'
                                  : ''
                              }
                              src={
                                (!sandwich_menu_btn && route.id === activeItemSideBar) ||
                                (sandwich_menu_btn && route.id === activeItemSideBar)
                                  ? route.imgColor
                                  : route.img
                              }
                              width="20px"
                              height="20px"
                              alt=""
                            />
                            {!sandwich_menu_btn && <small>{route.sous_title}</small>}
                            {route.id === 4 &&
                            !sandwich_menu_btn &&
                            countMessagesIsSeenNow > 0 ? (
                              /* <div className="notification_dot">5</div>*/
                              <span
                                style={{
                                  color: 'white',
                                  marginTop: '-23px',
                                  marginRight: '-22px',
                                }}
                                className="badge badge-pill badge-primary"
                              >
                                {countMessagesIsSeenNow}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="item_section_right">
                            {sandwich_menu_btn ? (
                              <>
                                <span className="hi">{route.name}</span>
                                <span />
                                {route.id === 4 && countMessagesIsSeenNow > 0 ? (
                                  <span
                                    style={{
                                      color: 'white',
                                      marginTop: '-23px',
                                      marginLeft: '7px',
                                    }}
                                    className="badge badge-pill badge-primary"
                                  >
                                    {countMessagesIsSeenNow}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                }
                if (
                  route.id === 8 &&
                  (isHasPermission(user.login_access.permissions, 'COMPANY_MANAG') ||
                    isHasPermission(user.login_access.permissions, 'PARTNERS_MANAG') ||
                    isHasPermission(user.login_access.permissions, 'ABSENCES_MANAG') ||
                    isHasPermission(user.login_access.permissions, 'ASSISTANTS_MANAG') ||
                    isHasPermission(user.login_access.permissions, 'SECRETERIES_MANAG') ||
                    isHasPermission(user.login_access.permissions, 'PERMISSIONS_MANAG') ||
                    isHasPermission(user.login_access.permissions, 'REGISTER_MANAG') ||
                    isHasPermission(user.login_access.permissions, 'PATHOLOGIES_MANAG') ||
                    isHasPermission(
                      user.login_access.permissions,
                      'PRESCRIBING_DOCTORS_MANAG',
                    ) ||
                    isHasPermission(user.login_access.permissions, 'MISSIONS_MANAG'))
                ) {
                  return (
                    <Link key={route.id} to={route.path}>
                      <div
                        title={route.title}
                        onClick={() => this.handleClick(route.id)}
                        className={
                          sandwich_menu_btn && route.id === activeItemSideBar
                            ? 'cont open_navbar_bg_color'
                            : 'cont'
                        }
                      >
                        <div
                          className={
                            sandwich_menu_btn
                              ? 'sidenav_container_item new_container_layout'
                              : 'sidenav_container_item'
                          }
                        >
                          <div
                            id="agenda"
                            className={
                              !sandwich_menu_btn && route.id === activeItemSideBar
                                ? 'item_section_left active_container'
                                : 'item_section_left'
                            }
                            style={{ display: 'block', textAlign: 'center' }}
                          >
                            {/*&& countMessagesIsSeenNow > 0 */}
                            <img
                              className={
                                (!sandwich_menu_btn && route.id === activeItemSideBar) ||
                                (sandwich_menu_btn && route.id === activeItemSideBar)
                                  ? 'active_icon'
                                  : ''
                              }
                              src={
                                (!sandwich_menu_btn && route.id === activeItemSideBar) ||
                                (sandwich_menu_btn && route.id === activeItemSideBar)
                                  ? route.imgColor
                                  : route.img
                              }
                              width="20px"
                              height="20px"
                              alt=""
                            />
                            {!sandwich_menu_btn && <small>{route.sous_title}</small>}
                            {route.id === 4 &&
                            !sandwich_menu_btn &&
                            countMessagesIsSeenNow > 0 ? (
                              /* <div className="notification_dot">5</div>*/
                              <span
                                style={{
                                  color: 'white',
                                  marginTop: '-23px',
                                  marginRight: '-22px',
                                }}
                                className="badge badge-pill badge-primary"
                              >
                                {countMessagesIsSeenNow}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="item_section_right">
                            {sandwich_menu_btn ? (
                              <>
                                <span className="hi">{route.name}</span>
                                <span />
                                {route.id === 4 && countMessagesIsSeenNow > 0 ? (
                                  <span
                                    style={{
                                      color: 'white',
                                      marginTop: '-23px',
                                      marginLeft: '7px',
                                    }}
                                    className="badge badge-pill badge-primary"
                                  >
                                    {countMessagesIsSeenNow}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                }
                if (route.id === 0 && admin && !route.permission) {
                  return (
                    <Link key={route.id} to={route.path}>
                      <div
                        title={route.title}
                        onClick={() => this.handleClick(route.id)}
                        className={
                          sandwich_menu_btn && route.id === activeItemSideBar
                            ? 'cont open_navbar_bg_color'
                            : 'cont'
                        }
                      >
                        <div
                          className={
                            sandwich_menu_btn
                              ? 'sidenav_container_item new_container_layout'
                              : 'sidenav_container_item'
                          }
                        >
                          <div
                            id="agenda"
                            className={
                              !sandwich_menu_btn && route.id === activeItemSideBar
                                ? 'item_section_left active_container'
                                : 'item_section_left'
                            }
                            style={{ display: 'block', textAlign: 'center' }}
                          >
                            {/*&& countMessagesIsSeenNow > 0 */}
                            <img
                              className={
                                (!sandwich_menu_btn && route.id === activeItemSideBar) ||
                                (sandwich_menu_btn && route.id === activeItemSideBar)
                                  ? 'active_icon'
                                  : ''
                              }
                              src={
                                (!sandwich_menu_btn && route.id === activeItemSideBar) ||
                                (sandwich_menu_btn && route.id === activeItemSideBar)
                                  ? route.imgColor
                                  : route.img
                              }
                              width="20px"
                              height="20px"
                              alt=""
                            />
                            {!sandwich_menu_btn && <small>{route.sous_title}</small>}
                            {route.id === 4 &&
                            !sandwich_menu_btn &&
                            countMessagesIsSeenNow > 0 ? (
                              /* <div className="notification_dot">5</div>*/
                              <span
                                style={{
                                  color: 'white',
                                  marginTop: '-23px',
                                  marginRight: '-22px',
                                }}
                                className="badge badge-pill badge-primary"
                              >
                                {countMessagesIsSeenNow}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="item_section_right">
                            {sandwich_menu_btn ? (
                              <>
                                <span className="hi">{route.name}</span>
                                <span />
                                {route.id === 4 && countMessagesIsSeenNow > 0 ? (
                                  <span
                                    style={{
                                      color: 'white',
                                      marginTop: '-23px',
                                      marginLeft: '7px',
                                    }}
                                    className="badge badge-pill badge-primary"
                                  >
                                    {countMessagesIsSeenNow}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                }
                if (
                  route.id !== 7 &&
                  route.id !== 10 &&
                  route.id !== 9 &&
                  route.id !== 1 &&
                  route.id !== 100 &&
                  route.id !== 0 &&
                  !route.permission
                ) {
                  return (
                    <Link key={route.id} to={route.path}>
                      <div
                        title={route.title}
                        onClick={() => this.handleClick(route.id)}
                        className={
                          sandwich_menu_btn && route.id === activeItemSideBar
                            ? 'cont open_navbar_bg_color'
                            : 'cont'
                        }
                      >
                        <div
                          className={
                            sandwich_menu_btn
                              ? 'sidenav_container_item new_container_layout'
                              : 'sidenav_container_item'
                          }
                        >
                          <div
                            id="agenda"
                            className={
                              !sandwich_menu_btn && route.id === activeItemSideBar
                                ? 'item_section_left active_container'
                                : 'item_section_left'
                            }
                            style={{ display: 'block', textAlign: 'center' }}
                          >
                            {/*&& countMessagesIsSeenNow > 0 */}
                            <img
                              className={
                                (!sandwich_menu_btn && route.id === activeItemSideBar) ||
                                (sandwich_menu_btn && route.id === activeItemSideBar)
                                  ? 'active_icon'
                                  : ''
                              }
                              src={
                                (!sandwich_menu_btn && route.id === activeItemSideBar) ||
                                (sandwich_menu_btn && route.id === activeItemSideBar)
                                  ? route.imgColor
                                  : route.img
                              }
                              width="20px"
                              height="20px"
                              alt=""
                            />
                            {!sandwich_menu_btn && <small>{route.sous_title}</small>}
                            {route.id === 4 &&
                            !sandwich_menu_btn &&
                            countMessagesIsSeenNow > 0 ? (
                              /* <div className="notification_dot">5</div>*/
                              <span
                                style={{
                                  color: 'white',
                                  marginTop: '-23px',
                                  marginRight: '-22px',
                                }}
                                className="badge badge-pill badge-primary"
                              >
                                {countMessagesIsSeenNow}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="item_section_right">
                            {sandwich_menu_btn ? (
                              <>
                                <span className="hi">{route.name}</span>
                                <span />
                                {route.id === 4 && countMessagesIsSeenNow > 0 ? (
                                  <span
                                    style={{
                                      color: 'white',
                                      marginTop: '-23px',
                                      marginLeft: '7px',
                                    }}
                                    className="badge badge-pill badge-primary"
                                  >
                                    {countMessagesIsSeenNow}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                }
                if (
                  route.id === 9 &&
                  (isHasPermission(
                    user.login_access.permissions,
                    'ACCOUNTING_STAT_ACCESS',
                  ) ||
                    isHasPermission(user.login_access.permissions, 'CNAM_SLIP_ACCESS') ||
                    isHasPermission(
                      user.login_access.permissions,
                      'ACCESS_CREDITS_LIST',
                    ) ||
                    isHasPermission(
                      user.login_access.permissions,
                      'ACCESS_DEPENSES_LIST',
                    ) ||
                    isHasPermission(
                      user.login_access.permissions,
                      'ACCESS_RECETTE_LIST',
                    ) ||
                    isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ||
                    isHasPermission(user.login_access.permissions, 'RECEIPT_ACCESS'))
                ) {
                  return (
                    <Link
                      to={{
                        pathname: '/compta',
                      }}
                    >
                      <div
                        title="Comptabilité"
                        onClick={() => {
                          this.handleClick(route.id);
                          /* this.setState({
                          dropDown: !dropDown,
                        });*/
                        }}
                        className={
                          sandwich_menu_btn && route.id === activeItemSideBar
                            ? 'cont open_navbar_bg_color'
                            : 'cont'
                        }
                        // data-toggle="collapse"
                        // data-target="#navbarSupportedContent1"
                        // aria-controls="navbarSupportedContent1"
                        // aria-expanded="false"
                        // aria-label="Toggle navigation"
                      >
                        <div
                          className={
                            sandwich_menu_btn
                              ? 'sidenav_container_item new_container_layout'
                              : 'sidenav_container_item'
                          }
                        >
                          <div
                            id="compta"
                            className={
                              !sandwich_menu_btn && route.id === activeItemSideBar
                                ? 'item_section_left active_container'
                                : 'item_section_left'
                            }
                            style={{ display: 'block', textAlign: 'center' }}
                          >
                            <img
                              className={
                                !sandwich_menu_btn && route.id === activeItemSideBar
                                  ? 'active_icon'
                                  : ''
                              }
                              src={
                                route.id === activeItemSideBar
                                  ? route.imgColor
                                  : route.img
                              }
                              width="20px"
                              height="20px"
                              alt=""
                            />
                            {!sandwich_menu_btn && <small>{route.sous_title}</small>}
                          </div>
                          <div className="item_section_right">
                            {sandwich_menu_btn ? (
                              <span className="hi">Comptabilité</span>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        {/* <div
                        style={{ marginLeft: sandwich_menu_btn ? '1%' : '16%' }}
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent1"
                      >
                        <ul className="navbar-nav">
                          {isHasPermission(
                            user.login_access.permissions,
                            route.compt.permission,
                          ) ? (
                            <Link
                              to={{
                                pathname: '/payment/cnam',
                                state: {
                                  cnam: true,
                                },
                              }}
                            >
                              <li title={route.compt.title} className="nav-item">
                                <button>
                                  <img src={route.compt.img} width="20px" height="20px" />
                                  {sandwich_menu_btn ? (
                                    <span className="hi"> Compta CNAM</span>
                                  ) : (
                                    ''
                                  )}
                                </button>
                              </li>
                            </Link>
                          ) : (
                            ''
                          )}
                          {isHasPermission(
                            user.login_access.permissions,
                            route.bordereau_cnam.permission,
                          ) ? (
                            <Link to="/bordereaux/cnam">
                              <li title={route.bordereau_cnam.title} className="nav-item">
                                <button>
                                  <img
                                    src={route.bordereau_cnam.img}
                                    width="20px"
                                    height="20px"
                                  />
                                  {sandwich_menu_btn ? (
                                    <span className="hi">Bordereaux Cnam</span>
                                  ) : (
                                    ''
                                  )}
                                </button>
                              </li>
                            </Link>
                          ) : (
                            ''
                          )}
                          {isHasPermission(
                            user.login_access.permissions,
                            route.bordereau_act.permission,
                          ) ? (
                            <Link to="/bordereaux/regime/act">
                              <li title={route.bordereau_act.title} className="nav-item">
                                <button>
                                  <img
                                    src={route.bordereau_act.img}
                                    width="20px"
                                    height="20px"
                                  />
                                  {sandwich_menu_btn ? (
                                    <span className="hi">Bordereaux ACT</span>
                                  ) : (
                                    ''
                                  )}
                                </button>
                              </li>
                            </Link>
                          ) : (
                            ''
                          )}
                          {isHasPermission(
                            user.login_access.permissions,
                            route.bill_cnam.permission,
                          ) ? (
                            <Link
                              onClick={() => this.props.changeNamePage('Factures CNAM')}
                              to="/factures/cnam"
                            >
                              <li title={route.bill_cnam.title} className="nav-item">
                                <button>
                                  <img
                                    src={route.bill_cnam.img}
                                    width="20px"
                                    height="20px"
                                  />
                                  {sandwich_menu_btn ? (
                                    <span className="hi">Factures Cnam</span>
                                  ) : (
                                    ''
                                  )}
                                </button>
                              </li>
                            </Link>
                          ) : (
                            ''
                          )}
                          {isHasPermission(
                            user.login_access.permissions,
                            route.bill_act.permission,
                          ) ? (
                            <Link to="/factures/regime/act">
                              <li title={route.bill_act.title} className="nav-item">
                                <button>
                                  <img
                                    src={route.bill_act.img}
                                    width="20px"
                                    height="20px"
                                  />
                                  {sandwich_menu_btn ? (
                                    <span className="hi">Factures ACT</span>
                                  ) : (
                                    ''
                                  )}
                                </button>
                              </li>
                            </Link>
                          ) : (
                            ''
                          )}
                        </ul>
                      </div> */}
                      </div>
                    </Link>
                  );
                }
                if (
                  route.id === 10 &&
                  (isHasPermission(
                    user.login_access.permissions,
                    'ACCOUNTING_STAT_ACCESS',
                  ) ||
                    isHasPermission(user.login_access.permissions, 'CNAM_SLIP_ACCESS') ||
                    isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ||
                    isHasPermission(user.login_access.permissions, 'PEC_MANAG'))
                ) {
                  return (
                    <Link
                      to={{
                        pathname: '/module-cnam',
                        state: {
                          cnam: true,
                        },
                      }}
                    >
                      <div
                        title="Module Cnam"
                        onClick={() => {
                          this.handleClick(route.id);
                          /* this.setState({
                          dropDown: !dropDown,
                        });*/
                        }}
                        className={
                          sandwich_menu_btn && route.id === activeItemSideBar
                            ? 'cont open_navbar_bg_color'
                            : 'cont'
                        }
                        // data-toggle="collapse"
                        // data-target="#navbarSupportedContent1"
                        // aria-controls="navbarSupportedContent1"
                        // aria-expanded="false"
                        // aria-label="Toggle navigation"
                      >
                        <div
                          className={
                            sandwich_menu_btn
                              ? 'sidenav_container_item new_container_layout'
                              : 'sidenav_container_item'
                          }
                        >
                          <div
                            id="compta"
                            className={
                              !sandwich_menu_btn && route.id === activeItemSideBar
                                ? 'item_section_left active_container'
                                : 'item_section_left'
                            }
                            style={{ display: 'block', textAlign: 'center' }}
                          >
                            <img
                              className={
                                !sandwich_menu_btn && route.id === activeItemSideBar
                                  ? 'active_icon'
                                  : ''
                              }
                              src={
                                route.id === activeItemSideBar
                                  ? route.imgColor
                                  : route.img
                              }
                              width="20px"
                              height="20px"
                              alt=""
                            />
                            {!sandwich_menu_btn && <small>{route.sous_title}</small>}
                          </div>
                          <div className="item_section_right">
                            {sandwich_menu_btn ? (
                              <span className="hi">Module Cnam</span>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        {/* <div
                        style={{ marginLeft: sandwich_menu_btn ? '1%' : '16%' }}
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent1"
                      >
                        <ul className="navbar-nav">
                          {isHasPermission(
                            user.login_access.permissions,
                            route.compt.permission,
                          ) ? (
                            <Link
                              to={{
                                pathname: '/payment/cnam',
                                state: {
                                  cnam: true,
                                },
                              }}
                            >
                              <li title={route.compt.title} className="nav-item">
                                <button>
                                  <img src={route.compt.img} width="20px" height="20px" />
                                  {sandwich_menu_btn ? (
                                    <span className="hi"> Compta CNAM</span>
                                  ) : (
                                    ''
                                  )}
                                </button>
                              </li>
                            </Link>
                          ) : (
                            ''
                          )}
                          {isHasPermission(
                            user.login_access.permissions,
                            route.bordereau_cnam.permission,
                          ) ? (
                            <Link to="/bordereaux/cnam">
                              <li title={route.bordereau_cnam.title} className="nav-item">
                                <button>
                                  <img
                                    src={route.bordereau_cnam.img}
                                    width="20px"
                                    height="20px"
                                  />
                                  {sandwich_menu_btn ? (
                                    <span className="hi">Bordereaux Cnam</span>
                                  ) : (
                                    ''
                                  )}
                                </button>
                              </li>
                            </Link>
                          ) : (
                            ''
                          )}
                          {isHasPermission(
                            user.login_access.permissions,
                            route.bordereau_act.permission,
                          ) ? (
                            <Link to="/bordereaux/regime/act">
                              <li title={route.bordereau_act.title} className="nav-item">
                                <button>
                                  <img
                                    src={route.bordereau_act.img}
                                    width="20px"
                                    height="20px"
                                  />
                                  {sandwich_menu_btn ? (
                                    <span className="hi">Bordereaux ACT</span>
                                  ) : (
                                    ''
                                  )}
                                </button>
                              </li>
                            </Link>
                          ) : (
                            ''
                          )}
                          {isHasPermission(
                            user.login_access.permissions,
                            route.bill_cnam.permission,
                          ) ? (
                            <Link
                              onClick={() => this.props.changeNamePage('Factures CNAM')}
                              to="/factures/cnam"
                            >
                              <li title={route.bill_cnam.title} className="nav-item">
                                <button>
                                  <img
                                    src={route.bill_cnam.img}
                                    width="20px"
                                    height="20px"
                                  />
                                  {sandwich_menu_btn ? (
                                    <span className="hi">Factures Cnam</span>
                                  ) : (
                                    ''
                                  )}
                                </button>
                              </li>
                            </Link>
                          ) : (
                            ''
                          )}
                          {isHasPermission(
                            user.login_access.permissions,
                            route.bill_act.permission,
                          ) ? (
                            <Link to="/factures/regime/act">
                              <li title={route.bill_act.title} className="nav-item">
                                <button>
                                  <img
                                    src={route.bill_act.img}
                                    width="20px"
                                    height="20px"
                                  />
                                  {sandwich_menu_btn ? (
                                    <span className="hi">Factures ACT</span>
                                  ) : (
                                    ''
                                  )}
                                </button>
                              </li>
                            </Link>
                          ) : (
                            ''
                          )}
                        </ul>
                      </div> */}
                      </div>
                    </Link>
                  );
                }
              })}
            </div>
          </div>
        </div>
        {sandwich_menu_btn ? (
          <Link to="/support">
            <img id="support_art" src={require('../assets/img/support_art.svg')}></img>
          </Link>
        ) : (
          ''
        )}
      </section>
    );
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideBar));
