import React, { Component } from 'react';
import { SelectorBilanItemSection, SelectorColorInputCheckbox } from '../../../../utils';
import UploadFile from '../../upload_file';import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
class ContexteAnamnese extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poids: 0,
      taille: 0,
      showEntretien: true,
      showDouleur: false,
      showBilanArticulaire: false,
      showScore: false,
    };
    this.handleChangeSubSectionBtn = this.handleChangeSubSectionBtn.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    SelectorColorInputCheckbox();
    SelectorBilanItemSection();
  }
  handleChangeSubSectionBtn(name) {
    this.setState({
      [name]: !this.state[name],
    });
  }
  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }
  render() {
    const { sandwich_menu_btn, initialValues } = this.props;
    return (
      <div className="actual_bilan_settings">
        <div className="actual_bilan_settings_item head_shaking_test" id="headShaking1">
          <div
            style={{ backgroundColor: '#035ef8' }}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}> Contexte patient</span>
          </div>
          <br />
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Mode de vie
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="context_patient_actif"
                              onChange={this.props.handleChangeInput}
                              name="context_patient_1"
                              checked={
                                initialValues.context_patient_1 === 'Actif' ? true : false
                              }
                              value="Actif"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`context_patient_actif`}
                            >
                              Actif
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="context_patient_1"
                              checked={
                                initialValues.context_patient_1 === 'Retraite'
                                  ? true
                                  : false
                              }
                              value="Retraite"
                              id={`context_patient_retraite`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`context_patient_retraite`}
                            >
                              Retraite
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="context_patient_2"
                              checked={
                                initialValues.context_patient_2 === 'isolé'
                                  ? true
                                  : false
                              }
                              value="isolé"
                              className="form-check-input"
                              type="radio"
                              id={`context_patient_isole`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`context_patient_isole`}
                            >
                              Isolé
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="context_patient_2"
                              checked={
                                initialValues.context_patient_2 === 'en famille'
                                  ? true
                                  : false
                              }
                              value="en famille"
                              className="form-check-input"
                              type="radio"
                              id={`context_patient_famille`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`context_patient_famille`}
                            >
                              En famille
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Lieu de vie
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="context_patient_lieuDeVie_maison"
                              onChange={this.props.handleChangeInput}
                              name="context_patient_lieuDeVie_1"
                              checked={
                                initialValues.context_patient_lieuDeVie_1 === 'Maison' ? true : false
                              }
                              value="Maison"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`context_patient_lieuDeVie_maison`}
                            >
                              Maison
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="context_patient_lieuDeVie_1"
                              checked={
                                initialValues.context_patient_lieuDeVie_1 === 'Appartement'
                                  ? true
                                  : false
                              }
                              value="Appartement"
                              id={`context_patient_lieuDeVie_appart`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`context_patient_lieuDeVie_appart`}
                            >
                              Appartement
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="context_patient_lieuDeVie_etage"
                              checked={
                                initialValues.context_patient_lieuDeVie_etage === 'étage' ? true : false
                              }
                              value="étage"
                              className="form-check-input"
                              type="radio"
                              id={`context_patient_lieuDeVie_3`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`context_patient_lieuDeVie_3`}
                            >
                              À l'étage
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="context_patient_lieuDeVie_etage"
                              checked={
                                initialValues.context_patient_lieuDeVie_etage === 'au RDC' ? true : false
                              }
                              value="au RDC"
                              className="form-check-input"
                              type="radio"
                              id={`context_patient_lieuDeVie_4`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`context_patient_lieuDeVie_4`}
                            >
                              Au RDC
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="context_patient_lieuDeVie_ascenseur"
                              checked={
                                initialValues.context_patient_lieuDeVie_ascenseur === 'Avec ascenseur'
                                  ? true
                                  : false
                              }
                              value="Avec ascenseur"
                              className="form-check-input"
                              type="radio"
                              id={`context_patient_lieuDeVie_5`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`context_patient_lieuDeVie_5`}
                            >
                              Avec ascenseur
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="context_patient_lieuDeVie_ascenseur"
                              checked={
                                initialValues.context_patient_lieuDeVie_ascenseur === 'Sans ascenseur'
                                  ? true
                                  : false
                              }
                              value="Sans ascenseur"
                              className="form-check-input"
                              type="radio"
                              id={`context_patient_lieuDeVie_6`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`context_patient_lieuDeVie_6`}
                            >
                              Sans ascenseur
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '50%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Aide extérieure
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="frequence_itemd_31"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_12"
                              checked={
                                initialValues.check_contexte_12 === 'Quotidienne'
                                  ? true
                                  : false
                              }
                              value="Quotidienne"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_31`}
                            >
                              Quotidienne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_12"
                              checked={
                                initialValues.check_contexte_12 === 'Hebdomadaire'
                                  ? true
                                  : false
                              }
                              value="Hebdomadaire"
                              id={`frequence_itemd_32`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_32`}
                            >
                              Hebdomadaire
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_12"
                              checked={
                                initialValues.check_contexte_12 === 'Pluri-hebdomadaire'
                                  ? true
                                  : false
                              }
                              value="Pluri-hebdomadaire"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_itemd_33`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_33`}
                            >
                              Pluri-hebdomadaire
                            </label>
                          </div>
                        </div>
                        <br />

                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_12"
                              checked={
                                initialValues.check_contexte_12 === 'Permanente'
                                  ? true
                                  : false
                              }
                              value="Permanente"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_item_122`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_122`}
                            >
                              Permanente
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_12"
                              checked={
                                initialValues.check_contexte_12 === '(Famille)'
                                  ? true
                                  : false
                              }
                              value="(Famille)"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_item_121`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_121`}
                            >
                              (Famille)
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_12"
                              checked={
                                initialValues.check_contexte_12 === '(Aide ménagère)'
                                  ? true
                                  : false
                              }
                              value="(Aide ménagère)"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_item_1s4`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_1s4`}
                            >
                              (Aide ménagère)
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Attentes du patient
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c31"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_18"
                              checked={
                                initialValues.check_contexte_18 === 'Antalgique'
                                  ? true
                                  : false
                              }
                              value="Antalgique"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c31`}
                            >
                              Antalgique
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_18"
                              checked={
                                initialValues.check_contexte_18 === 'Orthopédique'
                                  ? true
                                  : false
                              }
                              value="Orthopédique"
                              className="form-check-input"
                              type="checkbox"
                              id={`frequence_itemd_s32`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_s32`}
                            >
                              Orthopédique
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_18"
                              checked={
                                initialValues.check_contexte_18 === 'Psychologique'
                                  ? true
                                  : false
                              }
                              value="Psychologique"
                              className="form-check-input"
                              type="checkbox"
                              id={`frequence_itemd_3e3`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_3e3`}
                            >
                              Psychologique
                            </label>
                          </div>
                        </div>
                        <br />

                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_18"
                              checked={
                                initialValues.check_contexte_18 === 'Respiratoire'
                                  ? true
                                  : false
                              }
                              value="Respiratoire"
                              className="form-check-input"
                              type="checkbox"
                              id={`frequence_item_q122`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_q122`}
                            >
                              Respiratoire
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_18"
                              checked={
                                initialValues.check_contexte_18 === 'Fonctionnelles'
                                  ? true
                                  : false
                              }
                              value="Fonctionnelles"
                              className="form-check-input"
                              type="checkbox"
                              id={`frequence_item_q121`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_q121`}
                            >
                              Fonctionnelle
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_18"
                              checked={
                                initialValues.check_contexte_18 === 'Autre' ? true : false
                              }
                              value="Autre"
                              className="form-check-input"
                              type="checkbox"
                              id={`frequence_item_q1s4`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_q1s4`}
                            >
                              Autres
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Travail
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="frequence_itemd_cs31"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_26"
                              checked={
                                initialValues.check_contexte_26 === 'Sédentaire'
                                  ? true
                                  : false
                              }
                              value="Sédentaire"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_cs31`}
                            >
                              Sédentaire
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_26"
                              checked={
                                initialValues.check_contexte_26 === 'Posté' ? true : false
                              }
                              value="Posté"
                              id={`frequence_itemd_ss32`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_ss32`}
                            >
                              Posté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_26"
                              checked={
                                initialValues.check_contexte_26 === 'Debout'
                                  ? true
                                  : false
                              }
                              value="Debout"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_itemd_s3e3`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_s3e3`}
                            >
                              Debout
                            </label>
                          </div>
                        </div>
                        <br />

                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_26"
                              checked={
                                initialValues.check_contexte_26 === 'Manuel'
                                  ? true
                                  : false
                              }
                              value="Manuel"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_item_sq122`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_sq122`}
                            >
                              Manuel
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_26"
                              checked={
                                initialValues.check_contexte_26 === 'Port de charges'
                                  ? true
                                  : false
                              }
                              value="Port de charges"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_item_sq121`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_sq121`}
                            >
                              Port de charges
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_26"
                              checked={
                                initialValues.check_contexte_26 === 'Gestes répétitifs'
                                  ? true
                                  : false
                              }
                              value="Gestes répétitifs"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_item_sq1s4`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_sq1s4`}
                            >
                              Gestes répétitifs
                            </label>
                          </div>
                        </div>
                        <br />

                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_26"
                              checked={
                                initialValues.check_contexte_26 === 'Travail de force'
                                  ? true
                                  : false
                              }
                              value="Travail de force"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_item_ssq122`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_ssq122`}
                            >
                              Travail de force
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_26"
                              checked={
                                initialValues.check_contexte_26 === '(TMS)' ? true : false
                              }
                              value="(TMS)"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_item_ssq121`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_ssq121`}
                            >
                              (TMS)
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_26"
                              checked={
                                initialValues.check_contexte_26 ===
                                '(Accident du travail)'
                                  ? true
                                  : false
                              }
                              value="(Accident du travail)"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_item_sqs1s4`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_sqs1s4`}
                            >
                              (Accident du travail)
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Sport & loisirs
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="frequence_itemd_cs3s1"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_33"
                              checked={
                                initialValues.check_contexte_33 === 'Sport loisir'
                                  ? true
                                  : false
                              }
                              value="Sport loisir"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_cs3s1`}
                            >
                              Sport loisir
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_33"
                              checked={
                                initialValues.check_contexte_33 === 'Compétition'
                                  ? true
                                  : false
                              }
                              value="Compétition"
                              id={`frequence_itemd_sss32`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_sss32`}
                            >
                              Compétition
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_33"
                              checked={
                                initialValues.check_contexte_33 === 'Sportif haut niveau'
                                  ? true
                                  : false
                              }
                              value="Sportif haut niveau"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_itemd_ss3e3`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_ss3e3`}
                            >
                              Sportif haut niveau
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_33"
                              checked={
                                initialValues.check_contexte_33 === 'Vie associative'
                                  ? true
                                  : false
                              }
                              value="Vie associative"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_item_sqd122`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_sqd122`}
                            >
                              Vie associative
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Relationnel
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="frequence_itemd_cs3ds1"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_37"
                              checked={
                                initialValues.check_contexte_37 ===
                                'Participe à la prise en charge'
                                  ? true
                                  : false
                              }
                              value="Participe à la prise en charge"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_cs3ds1`}
                            >
                              Participe à la prise en charge
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id={`frequence_itemd_sss3s2`}
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_37"
                              checked={
                                initialValues.check_contexte_37 === 'Opposant'
                                  ? true
                                  : false
                              }
                              value="Opposant"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_sss3s2`}
                            >
                              Opposant
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_37"
                              checked={
                                initialValues.check_contexte_37 === 'Relations familiales'
                                  ? true
                                  : false
                              }
                              value="Relations familiales"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_itemd_ss3ew3`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_ss3ew3`}
                            >
                              Relations familiales
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_37"
                              checked={
                                initialValues.check_contexte_37 === 'Relations aidants'
                                  ? true
                                  : false
                              }
                              value="Relations aidants"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_item_sqd122s`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_sqd122s`}
                            >
                              Relations aidants
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_37"
                              checked={
                                initialValues.check_contexte_37 ===
                                'Interactions limitées'
                                  ? true
                                  : false
                              }
                              value="Interactions limitées"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_item_sqd12w2s`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_sqd12w2s`}
                            >
                              Interactions limitées
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ backgroundColor: '#035ef8' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}> Anamnèse</span>
          </div>
          <br />
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Latéralité
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="frequence_item_qx1"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_42"
                              checked={
                                initialValues.check_contexte_42 === 'Droitier'
                                  ? true
                                  : false
                              }
                              value="Droitier"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_qx1`}
                            >
                              Droitier
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_42"
                              checked={
                                initialValues.check_contexte_42 === 'Gauche'
                                  ? true
                                  : false
                              }
                              value="Gauche"
                              id={`frequence_item_xc2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_xc2`}
                            >
                              Gauche
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_42"
                              checked={
                                initialValues.check_contexte_42 === 'Ambidextre'
                                  ? true
                                  : false
                              }
                              value="Ambidextre"
                              id={`frequence_item_xsc2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_xsc2`}
                            >
                              Ambidextre
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Poids
                    </span>
                  </div>
                  <div className="field_sub_section_container_item">
                    <div
                      style={{ textAlign: 'center' }}
                      className="additional_container_item_field"
                    >
                      <input
                        maxLength="500"
                        value={initialValues.poids}
                        style={{ width: '60%' }}
                        name="flexion_bilan_musculaire"
                        type="number"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <Slider
                      progress
                      defaultValue={initialValues.poids}
                      style={{ marginTop: 16 }}
                      onChange={(value) => {
                        this.props.handleChangeAnyInput(value, 'poids');
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Taille
                    </span>
                  </div>
                  <div className="field_sub_section_container_item">
                    <div
                      style={{ textAlign: 'center' }}
                      className="additional_container_item_field"
                    >
                      <input
                        value={initialValues.taille}
                        maxLength="500"
                        style={{ width: '60%' }}
                        name="taille"
                        type="number"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <Slider
                      progress
                      defaultValue={initialValues.taille}
                      style={{ marginTop: 16 }}
                      onChange={(value) => {
                        this.props.handleChangeAnyInput(value, 'taille');
                      }}
                    />
                  </div>
                  <div className="field_sub_section_container_item">
                    <div
                      style={{ marginTop: '10%', marginLeft: '41%' }}
                      className="checkbox_options"
                    >
                      <div className="checkbox_options_item">
                        <div className="form-check form-check-inline">
                          <label
                            className="form-check-label"
                            htmlFor={`frequence_item_qx1xcf`}
                          >
                            G
                          </label>
                        </div>
                      </div>
                      <span>|</span>
                      <div className="checkbox_options_item">
                        <div className="form-check form-check-inline">
                          <label
                            className="form-check-label"
                            htmlFor={`frequence_item_xxccc2`}
                          >
                            D
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Histoire de la malade
                    </span>
                  </div>
                  <div className="field_sub_section_container_item">
                    <div className="additional_container_item_field">
                      <textarea
                        onChange={this.props.handleChangeInput}
                        maxLength="500"
                        style={{ width: '60%' }}
                        name="textarea_contexte_1"
                        value={initialValues.textarea_contexte_1}
                        placeholder=""
                      ></textarea>
                    </div>
                  </div>
                  <div className="field_sub_section_container_item">
                    <div
                      style={{ marginTop: '10%', marginLeft: '41%' }}
                      className="checkbox_options"
                    >
                      <div className="checkbox_options_item">
                        <div className="form-check form-check-inline">
                          <label
                            className="form-check-label"
                            htmlFor={`frequence_item_qx1xcf`}
                          >
                            G
                          </label>
                        </div>
                      </div>
                      <span>|</span>
                      <div className="checkbox_options_item">
                        <div className="form-check form-check-inline">
                          <label
                            className="form-check-label"
                            htmlFor={`frequence_item_xxccc2`}
                          >
                            D
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContexteAnamnese;
