import React, { Component } from 'react';
import actions from '../store/actions';
import { connect } from 'react-redux';
import actionsBill from '../../facture/store/actions';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputField from '../../../components/inputField';

import update from 'immutability-helper';
const mapStateToProps = (state) => ({
  billsByIds: state.BordereauxReducer.billsByIds,
  isLoadingAddBordereau: state.BordereauxReducer.isLoadingAddBordereau,
  addBordereau: state.BordereauxReducer.addBordereau,

  user: state.AppReducer.user,
  bordereau: state.BordereauxReducer.bordereau,
  bordereaux: state.BordereauxReducer.bordereaux,
  isLoadingLastBordereau: state.BordereauxReducer.isLoadingLastBordereau,
  lastBordereau: state.BordereauxReducer.lastBordereau,
  isLoadingBordereaux: state.BordereauxReducer.isLoadingBordereaux,
  isLoadingBordereau: state.BordereauxReducer.isLoadingBordereau,
  params: state.BordereauxReducer.params,
  paramsBill: state.FactureReducer.params,
  bills: state.FactureReducer.bills,
  isLoadingBills: state.FactureReducer.isLoadingBills,
});

const mapDispatchToProps = (dispatch) => ({
  getAllBill: (paramsBill, regime) =>
    dispatch(actionsBill.getAllBill(paramsBill, 'bill', regime)),

  getLastBordereau: () => dispatch(actions.getLastBordereau()),
  submitBordereau: (values) => dispatch(actions.submitBordereau(values)),
  changeSearchedEndDate: async (paramsBill, regime) => {
    await dispatch(actionsBill.changeSearchedStringBill(paramsBill));
    dispatch(actionsBill.getAllBill(paramsBill, 'bill', regime));
  },
  changeSearchedStartDate: async (paramsBill, regime) => {
    await dispatch(actionsBill.changeSearchedStringBill(paramsBill));
    dispatch(actionsBill.getAllBill(paramsBill, 'bill', regime));
  },
  changeSearchedStringLimit: async (paramsBill, regime) => {
    await dispatch(actionsBill.changeSearchedStringBill(paramsBill));
    dispatch(actionsBill.getAllBill(paramsBill, 'bill', regime));
  },
  changeSearched: async (paramsBill, regime) => {
    await dispatch(actionsBill.changeSearchedStringBill(paramsBill));
    dispatch(actionsBill.getAllBill(paramsBill, 'bill', regime));
  },
});

class FormBordereau extends Component {
  constructor(props) {
    super(props);
    //props.submitBordereau();
    props.getLastBordereau();
    //props.getAllBill();
    props.changeNamePage('Ajouter bordereau');
    props.changeActiveItemSideBar(
      props.location.state && props.location.state.active
        ? props.location.state.active
        : 9,
    );
    this.state = {
      selectedAll: true,
      errorDate: false,
      errorDateBordereau: false,
      setIsOpen: false,
      date: moment().format('YYYY-MM-DD'),
      allBills: [],
      allBillsIds: [],
    };
    this.handleChangeBill = this.handleChangeBill.bind(this);
    this.handleSelectedAllBill = this.handleSelectedAllBill.bind(this);
  }

  componentDidMount() {
    this.props.changeSearchedStartDate(
      {
        limit: 300,
        currentPage: 1,
        start_date: '',
        end_date: '',
        search: '',
        status: '',
        type: 'bordereau',
      },
      this.props.match.params.regime,
    );
  }

  handleChangeBill(id) {
    this.setState((prevState) => {
      const data = [...prevState.allBills];
      let dataAllBillsIds = [...prevState.allBillsIds];
      let commentIndex = data.findIndex(function (c) {
        return c.id === id;
      });
      const updatedComment = update(data[commentIndex], {
        is_done: { $set: !data[commentIndex].is_done },
      });

      data[commentIndex] = updatedComment;
      let commentIndexBill = dataAllBillsIds.findIndex(function (c) {
        return c === id;
      });
      if (commentIndexBill < 0 && data[commentIndex].is_done) {
        dataAllBillsIds = [...dataAllBillsIds, id];
      } else {
        dataAllBillsIds = dataAllBillsIds.filter((item) => item !== id);
      }
      this.setState({
        allBills: data,
        allBillsIds: dataAllBillsIds,
      });
    });
  }
  handleSelectedAllBill(selectedAll) {
    if (selectedAll) {
      this.setState((prevState) => {
        const data = [...prevState.allBills];
        let dataAllBillsIds = [];
        for (let item of data) {
          dataAllBillsIds.push(item.id);
          let commentIndex = data.findIndex(function (c) {
            return c.id === item.id;
          });
          const updatedComment = update(data[commentIndex], {
            is_done: { $set: false },
          });
          data[commentIndex] = updatedComment;
        }
        this.setState({
          selectedAll: !prevState.selectedAll,
          allBills: data,
          allBillsIds: [],
        });
      });
    } else {
      this.setState((prevState) => {
        const data = [...prevState.allBills];
        let dataAllBillsIds = [];
        for (let item of data) {
          dataAllBillsIds.push(item.id);
          let commentIndex = data.findIndex(function (c) {
            return c.id === item.id;
          });
          const updatedComment = update(data[commentIndex], {
            is_done: { $set: !prevState.selectedAll ? true : false },
          });
          data[commentIndex] = updatedComment;
        }
        this.setState({
          selectedAll: !prevState.selectedAll,
          allBills: data,
          allBillsIds: dataAllBillsIds,
        });
      });
    }
  }
  componentWillReceiveProps(nextProps, nextContext) {
    const { bills, addBordereau } = nextProps;
    let allBillsIds = [];
    if (addBordereau && addBordereau.id) {
      if (this.props.match.params.regime === 'autre') {
        nextProps.history.push({
          pathname: `/compta`,
          state: {
            bAutre: true,
          },
        });
      } else {
        nextProps.history.push({
          pathname: `/module-cnam`,
          state: {
            bCnam: this.props.match.params.regime === 'cnam' ? true : false,
            bACT: this.props.match.params.regime === 'act' ? true : false,
          },
        });
      }
    }
    let newBills = [];
    if (bills && bills.data && bills.data.length > 0) {
      bills.data.map((bill) => {
        if (bill.consultation) {
          allBillsIds.push(bill.id);
          newBills.push({
            ...bill,
            is_done: true,
          });
        }
      });
    }
    this.setState({
      allBills: newBills,
      allBillsIds,
    });
  }

  render() {
    const { paramsBill, isLoadingBills, isLoadingAddBordereau, lastBordereau } =
      this.props;
    const { errorDate, selectedAll, errorDateBordereau, allBillsIds, allBills } =
      this.state;
    /*if (isLoadingBills) {
      return (
        <CircularProgress disableShrink style={{ marginLeft: '47%', marginTop: '30%' }} />
      );
    }*/
    return (
      <div className={'main_content'}>
        <div className="facture_info_section_form">
          <div className="container_changeable_content">
            <div className="add_facture_section">
              <div
                style={{ background: 'white', margin: '0px' }}
                className="selected_facture_details"
              >
                <div
                  style={{ display: 'block', width: '100%' }}
                  className="selected_facture_info_container"
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'revert',
                      alignItems: 'center',
                    }}
                    className="selected_facture_patient_info"
                  >
                    <span
                      style={{
                        fontSize: '17px',
                        width: '-webkit-fit-content',
                        marginRight: '10%',
                        alignSelf: 'center',
                      }}
                      id="selected_facture_patient_name"
                    >
                      BORDEREAU N° {lastBordereau}
                    </span>
                    <InputField
                      style={{
                        borderBottom: errorDate ? '1px solid #ff808b' : '',
                      }}
                      width="250px"
                      value={this.state.date}
                      name="dateBordereau"
                      onChange={(e) => {
                        if (moment(e.target.value).isAfter(moment()))
                          this.setState({
                            errorDateBordereau: true,
                          });
                        else
                          this.setState({
                            errorDateBordereau: false,
                            date: e.target.value,
                          });
                      }}
                      type="date"
                      label="Date bordereau"
                    />
                  </div>
                </div>
                {allBills && allBills.length > 0 ? (
                  allBills.find((item) => item.consultation) ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                      }}
                      className="footer_buttons"
                    >
                      <button
                        onClick={() =>
                          this.props.history.push({
                            pathname:
                              this.props.match.params.regime === 'act' ||
                              this.props.match.params.regime === 'cnam'
                                ? '/module-cnam'
                                : '/compta',
                            state: {
                              bCnam:
                                this.props.match.params.regime === 'cnam' ? true : false,
                              bACT:
                                this.props.match.params.regime === 'act' ? true : false,
                              bAutre:
                                this.props.match.params.regime === 'autre' ? true : false,
                            },
                          })
                        }
                        className="footer_btn"
                        id="cancel"
                      >
                        Annuler
                      </button>
                      <button
                        disabled={isLoadingAddBordereau}
                        onClick={() => {
                          if (!errorDateBordereau && allBillsIds.length > 0) {
                            const values = {
                              allBillsIds,
                              date: moment(this.state.date).format('YYYY-MM-DD'),
                              number: lastBordereau,
                            };
                            this.props.submitBordereau(values);
                          }
                        }}
                        className="footer_btn"
                        id="update"
                      >
                        {isLoadingAddBordereau ? (
                          <CircularProgress
                            style={{
                              marginLeft: '4%',
                              width: '25px',
                              height: '25px',
                              color: 'white',
                              marginTop: '5%',
                            }}
                          />
                        ) : (
                          'Valider'
                        )}
                      </button>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end',
                      }}
                      className="footer_buttons"
                    >
                      <button
                        onClick={() =>
                          this.props.history.push({
                            pathname:
                              this.props.match.params.regime === 'act' ||
                              this.props.match.params.regime === 'cnam'
                                ? '/module-cnam'
                                : '/compta',
                            state: {
                              bCnam:
                                this.props.match.params.regime === 'cnam' ? true : false,
                              bACT:
                                this.props.match.params.regime === 'act' ? true : false,
                              bAutre:
                                this.props.match.params.regime === 'autre' ? true : false,
                            },
                          })
                        }
                        className="footer_btn"
                        id="cancel"
                      >
                        Annuler
                      </button>
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'end',
                    }}
                    className="footer_buttons"
                  >
                    <button
                      onClick={() =>
                        this.props.history.push({
                          pathname:
                            this.props.match.params.regime === 'act' ||
                            this.props.match.params.regime === 'cnam'
                              ? '/module-cnam'
                              : '/compta',
                          state: {
                            bCnam:
                              this.props.match.params.regime === 'cnam' ? true : false,
                            bACT: this.props.match.params.regime === 'act' ? true : false,
                            bAutre:
                              this.props.match.params.regime === 'autre' ? true : false,
                          },
                        })
                      }
                      className="footer_btn"
                      id="cancel"
                    >
                      Annuler
                    </button>
                  </div>
                )}
              </div>
              <div style={{ marginLeft: '4%' }} className="facture_patient_header">
                <div className="facture_patient_controls">
                  <InputField
                    width="20%"
                    className="add_patient_form_item"
                    type="text"
                    placeholder="XX/XXXXX"
                    label="N° Facture"
                    icon={<i className="fa fa-file-invoice-dollar"></i>}
                    onChange={(event) => {
                      this.props.changeSearched(
                        {
                          ...paramsBill,
                          search: event.target.value ? event.target.value : '',
                        },
                        this.props.match.params.regime,
                      );
                    }}
                  />
                  <InputField
                    width="20%"
                    className="add_patient_form_item"
                    type="text"
                    placeholder="XX/XXXX/XXXXX"
                    label="N° Décision"
                    icon={<i className="fa fa-file-medical"></i>}
                    onChange={(event) => {
                      this.props.changeSearched(
                        {
                          ...paramsBill,
                          num_decision: event.target.value ? event.target.value : '',
                        },
                        this.props.match.params.regime,
                      );
                    }}
                  />
                  <div style={{ marginTop: '10px', marginRight: '30px' }}>
                    <InputField
                      style={{
                        borderBottom: errorDate ? '1px solid #ff808b' : '',
                      }}
                      width="250px"
                      value={
                        paramsBill.start_date
                          ? moment(paramsBill.start_date).format('YYYY-MM-DD')
                          : ''
                      }
                      name="dateDebut"
                      onChange={(e) => {
                        this.props.changeSearchedStartDate(
                          {
                            ...paramsBill,
                            start_date: e.target.value
                              ? moment(e.target.value).format('YYYY-MM-DD')
                              : '',
                          },
                          this.props.match.params.regime,
                        );
                      }}
                      type="date"
                      label="Date de début"
                    />
                  </div>
                  <div style={{ marginTop: '10px', marginRight: '30px' }}>
                    <InputField
                      style={{
                        borderBottom: errorDate ? '1px solid #ff808b' : '',
                      }}
                      width="250px"
                      value={
                        paramsBill.end_date
                          ? moment(paramsBill.end_date).format('YYYY-MM-DD')
                          : ''
                      }
                      name="dateFin"
                      onChange={(e) => {
                        if (
                          moment(paramsBill.start_date).isAfter(moment(e.target.value))
                        ) {
                          this.setState({
                            errorDate: true,
                          });
                        } else {
                          this.setState({
                            errorDate: false,
                          });
                          this.props.changeSearchedEndDate(
                            {
                              ...paramsBill,
                              end_date: e.target.value
                                ? moment(e.target.value).format('YYYY-MM-DD')
                                : '',
                            },
                            this.props.match.params.regime,
                          );
                        }
                      }}
                      type="date"
                      label="Date de fin"
                    />
                  </div>
                </div>
              </div>
              <div className="patients_consultations_record_container">
                {isLoadingBills ? (
                  <CircularProgress
                    disableShrink
                    style={{ marginLeft: '47%', marginTop: '30%' }}
                  />
                ) : allBills && allBills.length > 0 ? (
                  <>
                    <div
                      className="notification_info_form_item"
                      style={{
                        marginTop: '1%',
                      }}
                    >
                      <div>
                        <span>N°</span>
                      </div>
                      <div>
                        <span>N° DÉCISION</span>
                      </div>
                      <div>
                        <span>PATIENT</span>
                      </div>
                      <div>
                        <span>N° FACTURE</span>
                      </div>
                      <div>
                        <span>DATE</span>
                      </div>
                      <div>
                        <span>TOTAL HT</span>
                      </div>
                      <div>
                        <span>TOTAL TVA</span>
                      </div>
                      <div>
                        <span>TOTAL TTC</span>
                      </div>
                      <div style={{ width: '10%' }} className="seance-status">
                        <div
                          onClick={() => {
                            // this.setState({ selectedAll: !selectedAll });
                            this.handleSelectedAllBill(selectedAll);
                          }}
                          style={{ cursor: 'pointer' }}
                          className={selectedAll ? 'active_btn' : 'deactive_btn'}
                        >
                          <div
                            className={
                              selectedAll
                                ? 'deactive_btn_circle active_btn_circle'
                                : 'deactive_btn_circle'
                            }
                          ></div>
                        </div>
                      </div>
                    </div>
                    {/*<EnhancedTable data={allBills} />*/}
                    {allBills
                      .sort((a, b) => a.number > b.number)
                      .map((item, index) => {
                        if (item.consultation)
                          return (
                            <div className="notification_info_form_item">
                              <div>
                                <span>{index + 1}</span>
                              </div>
                              <div>
                                <span>{`${item.consultation.prise_en_charges[0].code_bureau}/${item.consultation.prise_en_charges[0].year}/${item.consultation.prise_en_charges[0].num_order}`}</span>
                              </div>
                              <div>
                                <span>
                                  {item.patient_record
                                    ? `${item.patient_record.patient.last_name} ${item.patient_record.patient.first_name}`
                                    : `${item.consultation.patient_record.patient.last_name} ${item.consultation.patient_record.patient.first_name}`}
                                </span>
                              </div>
                              <div>
                                <span>F° {item.number}</span>
                              </div>
                              <div>
                                <span>{moment(item.date).format('DD/MM/YYYY')}</span>
                              </div>
                              <div>
                                <span>{parseFloat(item.price_ht).toFixed(3)}</span>
                              </div>
                              <div>
                                <span>{parseFloat(item.price_tva).toFixed(3)}</span>
                              </div>
                              <div>
                                <span>{parseFloat(item.price_ttc).toFixed(3)}</span>
                              </div>
                              <div style={{ width: '10%' }} className="seance-status">
                                <div
                                  onClick={() => this.handleChangeBill(item.id)}
                                  style={{ cursor: 'pointer' }}
                                  className={item.is_done ? 'active_btn' : 'deactive_btn'}
                                >
                                  <div
                                    className={
                                      item.is_done
                                        ? 'deactive_btn_circle active_btn_circle'
                                        : 'deactive_btn_circle'
                                    }
                                  ></div>
                                </div>
                              </div>
                            </div>
                          );
                      })}
                  </>
                ) : (
                  <div className="container_changeable_content">
                    <div className="patient_detailed_info_section">
                      <div className="patient_detailed_info_section_body">
                        <div className="empty_consultation_result_container">
                          <div className="empty_consultation_result">
                            <img
                              src={require('../../../assets/img/undraw_design_team_af2y.svg')}
                            />
                            <span id="no_consultation">Pas de Factures !</span>
                            <span id="no_consultation_desc">
                              Essayez de créer des nouvelles factures. Si vous n'avez pas
                              des cures, commencez déja par en créer des nouvelles.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormBordereau);
