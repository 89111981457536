import React, { Component } from 'react';
import './containers/formPatient.css';
import FormPatient from './containers/formPatient';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import actions from './store/actions';
import { generateBillNumber } from '../../utils/helpres';
import moment from 'moment';

const mapStateToProps = (state, ownProps) => {
  return {
    addPatient: state.PatientReducer.addPatient,
    newPatient: state.PatientReducer.newPatient,
    isLoadingLastPatientRecord: state.PatientReducer.isLoadingLastPatientRecord,
    lastPatientRecord: state.PatientReducer.lastPatientRecord,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getLastPatientRecord: async () => {
    await dispatch(actions.getLastPatientRecord());
  },
});
class Form_Patient extends Component {
  constructor(props) {
    super(props);
    props.getLastPatientRecord();
    props.changeActiveItemSideBar(3);
    props.changeNamePage(props.title ? props.title : 'Ajouter un patient');
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { addPatient, patient, newPatient } = this.props;
    if (addPatient /*&& prevProps.addPatient !== addPatient*/ && !patient) {
      this.props.history.push(`/patients`, {
        phone: newPatient.phone,
        name: `${newPatient.first_name} ${newPatient.last_name}`,
      });
    }
  }
  goBack() {
    this.props.history.goBack();
  }
  render() {
    const {
      patient,
      lastPatientRecord,
      patientRecordId,
      patientRecordNumber,
      isLoadingLastPatientRecord,
    } = this.props;
    return (
      <>
        {isLoadingLastPatientRecord ? (
          <CircularProgress
            disableShrink
            style={{ marginLeft: '45%', marginTop: '30%' }}
          />
        ) : (
          <FormPatient
            goBack={this.goBack}
            showPatient={this.props.showPatient}
            patient={patient}
            patientRecordId={patientRecordId}
            patientRecordNumber={patientRecordNumber}
            patientRecordNewNumber={
              lastPatientRecord &&
              lastPatientRecord.length > 0 &&
              lastPatientRecord[0].pr_number
                ? `${generateBillNumber(
                    parseInt(lastPatientRecord[0].pr_number.split('/')[0]) + 1,
                    moment().format('YYYY'),
                  )}`
                : `${generateBillNumber(0, moment().format('YYYY'))}`
            }
          />
        )}
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form_Patient);

//export default Form_Patient;
