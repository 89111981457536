/* @flow */
const initialAppState = {
  error: undefined,
  isLoadingCountRdv: false,
  isLoadingTotalTtcBill: false,
  isLoadingCountPatient: false,
  isLoadingCountConsultation: false,
  isLoadingLast10Patients: false,
  isLoadingLast10Consultation: false,
  isLoadingLast10RDV: false,
  countRdv: 0,
  countConsultation: 0,
  countPatient: 0,
  totalTTc: 0,
  last10Consultation: [],
  last10RDV: [],
};

const DashboardReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'GET_LAST_10_RDV_REQUESTING':
      return {
        ...state,
        isLoadingLast10RDV: true,
      };
    case 'GET_LAST_10_RDV_SUCCESS':
      return {
        ...state,
        isLoadingLast10RDV: false,
        last10RDV: action.data.data,
      };
    case 'GET_LAST_10_RDV_FAILURE':
      return {
        ...state,
        isLoadingLast10RDV: false,
        last10RDV: [],
      };

      case 'GET_TOTAL_TTC_BILL_REQUESTING':
      return {
        ...state,
        isLoadingTotalTtcBill: true,
      };
    case 'GET_TOTAL_TTC_BILL_SUCCESS':
      return {
        ...state,
        isLoadingTotalTtcBill: false,
        totalTTc: action.data.data,
      };
    case 'GET_TOTAL_TTC_BILL_FAILURE':
      return {
        ...state,
        isLoadingTotalTtcBill: false,
        totalTTc: 0,
      };

    case 'GET_LAST_10_CONSULTATION_DOCTOR_REQUESTING':
      return {
        ...state,
        isLoadingLast10Consultation: true,
      };
    case 'GET_LAST_10_CONSULTATION_DOCTOR_SUCCESS':
      return {
        ...state,
        isLoadingLast10Consultation: false,
        last10Consultation: action.data.data,
      };
    case 'GET_LAST_10_CONSULTATION_DOCTOR_FAILURE':
      return {
        ...state,
        isLoadingLast10Consultation: false,
        last10Consultation: [],
      };
    case 'GET_LAST_10_PATIENT_DOCTOR_REQUESTING':
      return {
        ...state,
        isLoadingLast10Patients: true,
      };
    case 'GET_LAST_10_PATIENT_DOCTOR_SUCCESS':
      return {
        ...state,
        isLoadingLast10Patients: false,
        last10Patient: action.data.data,
      };
    case 'GET_LAST_10_PATIENT_DOCTOR_FAILURE':
      return {
        ...state,
        isLoadingLast10Patients: false,
        last10Patient: [],
      };

    case 'GET_RDV_DOCTOR_REQUESTING':
      return {
        ...state,
        isLoadingCountRdv: true,
      };
    case 'GET_RDV_DOCTOR_SUCCESS':
      return {
        ...state,
        isLoadingCountRdv: false,
        countRdv: action.data,
      };
    case 'GET_RDV_DOCTOR_FAILURE':
      return {
        ...state,
        isLoadingCountRdv: false,
        countRdv: 0,
      };
    case 'GET_CONSULTATION_DOCTOR_REQUESTING':
      return {
        ...state,
        isLoadingCountConsultation: true,
      };
    case 'GET_CONSULTATION_DOCTOR_SUCCESS':
      return {
        ...state,
        isLoadingCountConsultation: false,
        countConsultation: action.data,
      };
    case 'GET_CONSULTATION_DOCTOR_FAILURE':
      return {
        ...state,
        isLoadingCountConsultation: false,
        countConsultation: 0,
      };
    case 'GET_PATIENT_DOCTOR_REQUESTING':
      return {
        ...state,
        isLoadingCountPatient: true,
      };
    case 'GET_PATIENT_DOCTOR_SUCCESS':
      return {
        ...state,
        isLoadingCountPatient: false,
        countPatient: action.data,
      };
    case 'GET_PATIENT_DOCTOR_FAILURE':
      return {
        ...state,
        isLoadingCountPatient: false,
        countPatient: 0,
      };

    default:
      return state;
  }
};

export default DashboardReducer;
