import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from './store/actions';

import '../profile/Abonnement/index.css';
import '../profile/DiplomeFormation/index.css';
import '../profile/InfoGenerale/index.css';
import '../profile/Notifications/index.css';
import '../profile/Securities/index.css';
import '../profile/index.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import actionsJs from '../../js/actions';
import { Link } from 'react-router-dom';
import Modals from '../../components/modal';
import { ModePaiement } from '../../utils/constants';
import FormDrawer from './form-depense';

const mapStateToProps = (state) => ({
  depenses: state.DepenseReducer.depenses,
  isLoadingDepenses: state.DepenseReducer.isLoadingDepenses,
  params: state.DepenseReducer.params,
  destroyDepense: state.DepenseReducer.destroyDepense,
  activeItemSideBarRDV: state.AppReducer.activeItemSideBarRDV,
  depense: state.DepenseReducer.depense,
  sumDepense: state.DepenseReducer.sumDepense,
});

const mapDispatchToProps = (dispatch) => ({
  getAllDepenses: (params) => dispatch(actions.getAllDepenses(params)),
  deleteDepense: async (id, params) => {
    await dispatch(actions.deleteDepense(id));
    dispatch(actions.getAllDepenses(params));
  },
  changeActiveItemSideBarSettings: (item, data, create) =>
    dispatch(actionsJs.changeActiveItemSideBarSettings(item, data, create)),
  changeSearchedStatus: async (params) => {
    await dispatch(actions.changeSearchedStringDepenses(params));
    dispatch(actions.getAllDepenses(params));
  },
  changeSearchedEndDate: async (params) => {
    await dispatch(actions.changeSearchedStringDepenses(params));
    dispatch(actions.getAllDepenses(params));
  },
  changeSearchedStartDate: async (params) => {
    await dispatch(actions.changeSearchedStringDepenses(params));
    dispatch(actions.getAllDepenses(params));
  },
  changeActiveItemSideBarRDV: (item) =>
    dispatch(actionsJs.changeActiveItemSideBarRDV(item)),
  getDepenseById: (id) => dispatch(actions.getDepenseById(id)),
});

class Depenses extends Component {
  constructor(props) {
    super(props);
    props.changeNamePage('Dépenses');
    props.changeActiveItemSideBarCompta(153);
    document.title = 'Dépenses';
    this.state = {
      errorDate: false,
      setIsOpenDeleteModal: false,
      selected_id: null,
    };
    this.openDeleteModal = this.openDeleteModal.bind(this);
  }
  componentDidMount() {
    this.props.changeSearchedStartDate({
      ...this.props.params,
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      search: '',
      status: '',
    });
  }
  openDeleteModal() {
    this.setState({
      setIsOpenDeleteModal: !this.state.setIsOpenDeleteModal,
    });
  }
  toggleDrawer = (anchor, open, id) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    this.setState({
      left: open,
      anchor: 'left',
    });
  };
  render() {
    const {
      depenses,
      sumDepense,
      depense,
      activeItemSideBarRDV,
      isLoadingDepenses,
      params,
    } = this.props;
    const { setIsOpenDeleteModal, errorDate, selected_id } = this.state;
    return (
      <div className="main_content" style={{ paddingLeft: 0 }}>
        <FormDrawer
          toggleDrawer={this.toggleDrawer}
          changeActiveItemSideBarRDV={this.props.changeActiveItemSideBarRDV}
          activeItemSideBarRDV={activeItemSideBarRDV}
          selected_id={selected_id}
          params={params}
          depense={depense}
        />
        <div className="user_settings">
          <div className="container_changeable_content">
            <div className="facture_patient_header" style={{ display: 'table' }}>
              <p>Rechercher une dépense</p>
              <select
                style={{ width: '20%', margin: '0px 20px' }}
                id="inputState"
                name="status"
                value={params.status}
                onChange={(event, option) => {
                  this.props.changeSearchedStatus({
                    ...params,
                    status:
                      event.target.value !== 'Mode paiement' ? event.target.value : null,
                  });
                }}
              >
                <option value={null}>Mode paiement</option>;
                {ModePaiement.map((modePaiement) => {
                  return <option value={modePaiement.title}>{modePaiement.title}</option>;
                })}
              </select>
              <input
                style={{
                  width: '20%',
                  margin: '0px 20px',
                  borderBottom: errorDate ? '1px solid #ff808b' : '',
                }}
                id="date"
                type="date"
                value={
                  params.start_date ? moment(params.start_date).format('YYYY-MM-DD') : ''
                }
                onChange={(e) => {
                  this.props.changeSearchedStartDate({
                    ...params,
                    start_date: e.target.value
                      ? moment(e.target.value).format('YYYY-MM-DD')
                      : '',
                  });
                }}
              />
              <input
                style={{
                  width: '20%',
                  margin: '0px 20px',
                  borderBottom: errorDate ? '1px solid #ff808b' : '',
                }}
                id="date"
                type="date"
                className="type3"
                value={
                  params.end_date ? moment(params.end_date).format('YYYY-MM-DD') : ''
                }
                onChange={(e) => {
                  if (moment(params.start_date).isAfter(moment(e.target.value))) {
                    this.setState({
                      errorDate: true,
                    });
                    this.props.changeSearchedEndDate({
                      ...params,
                      end_date: e.target.value
                        ? moment(e.target.value).format('YYYY-MM-DD')
                        : '',
                    });
                  } else {
                    this.setState({
                      errorDate: false,
                    });
                    this.props.changeSearchedEndDate({
                      ...params,
                      end_date: e.target.value
                        ? moment(e.target.value).format('YYYY-MM-DD')
                        : '',
                    });
                  }
                }}
              />
              {sumDepense ? (
                <input
                  disabled={true}
                  style={{
                    width: '20%',
                    margin: '0px 20px',
                    borderBottom: errorDate ? '1px solid #ff808b' : '',
                  }}
                  className="type3"
                  value={`Total : ${sumDepense}`}
                />
              ) : (
                ''
              )}
            </div>
            {isLoadingDepenses ? (
              <CircularProgress
                disableShrink
                style={{ marginLeft: '45%', marginTop: '23%' }}
              />
            ) : (
              <div
                className="main_content"
                style={{
                  overflow: 'hidden',
                  width: '100%',
                  padding: '5%',
                  height: 'auto',
                }}
              >
                <div
                  className="general_info"
                  style={{ display: 'contents', width: '87%', height: '100%' }}
                >
                  <div className="contact_patients_body" style={{ height: '100%' }}>
                    {/* <span className="patients_consultations_record_label">Dépenses</span> */}

                    {depenses && depenses.length > 0 ? (
                      depenses.map((item) => {
                        return (
                          <div
                            key={item.id}
                            className="rendez_vous_list_body_item_container"
                            style={{
                              border: '1px solid grey',
                              marginBottom: '5px',
                            }}
                          >
                            <div className="rendez_vous_list_body_item">
                              <div
                                className="rv_list_body_sub_item"
                                id="time_container_indis"
                              >
                                <span id="start_time">
                                  {' '}
                                  {moment(item.payment_date).format('YYYY-MM-DD')}
                                </span>
                              </div>
                              <div className="rv_list_body_sub_item rv_details">
                                <div className="rv_text_details">
                                  <div
                                    className="patient_consultation_text"
                                    style={{ width: 'inherit' }}
                                  >
                                    <span id="type_consultation">{item.label}</span>
                                    <span id="patient_name">{item.note}</span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="rv_list_body_sub_item rv_details"
                                style={{ width: '30%' }}
                              >
                                <div className="rv_text_details">
                                  <div
                                    className="patient_consultation_text"
                                    style={{ width: 'inherit' }}
                                  >
                                    <span id="type_consultation">{item.price} Dt</span>
                                    <span id="patient_name">{item.payment_mode}</span>
                                  </div>
                                </div>
                              </div>
                              <div
                                onClick={() => {
                                  this.setState({
                                    selected_id: item.id,
                                  });
                                  this.props.getDepenseById(item.id);
                                  this.props.changeActiveItemSideBarRDV(
                                    activeItemSideBarRDV,
                                  );
                                }}
                                className="more_icon_container"
                                style={{ marginRight: '20px' }}
                              >
                                <i
                                  style={{
                                    padding: '0',
                                    border: '',
                                    color: '',
                                    borderRadius: '',
                                    backgroundColor: 'white',
                                  }}
                                  className="fas fa-edit"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div
                                onClick={() => {
                                  this.setState({
                                    selected_id: item.id,
                                  });
                                  this.openDeleteModal();
                                }}
                                className="more_icon_container"
                                style={{ marginRight: '20px' }}
                              >
                                <i
                                  style={{
                                    padding: '0',
                                    border: '',
                                    color: '',
                                    borderRadius: '',
                                    backgroundColor: 'white',
                                  }}
                                  className="fas fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div
                        style={{ height: 'calc(50vh - 64px)' }}
                        className="consultation_detailed_info_section"
                      >
                        <div className="empty_consultation_result_container">
                          <div className="empty_consultation_result">
                            <img
                              src={require('../../assets/img/undraw_design_team_af2y.svg')}
                            />
                            <span id="no_consultation">Liste vide !</span>
                            <span id="no_consultation_desc">
                              Aucune dépense n'a été enregistrée aujourd'hui. Essayer d'en
                              ajouter une.
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Modals
          setIsOpen={setIsOpenDeleteModal}
          openModal={this.openDeleteModal}
          data={
            <>
              <div className="modal-header" style={{ marginTop: '-9px' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openDeleteModal()}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '13px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Suppression!</h2>
                <span>Etes-vous sûr de vouloir supprimer cette dépense ?</span>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => this.openDeleteModal()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                  style={{ backgroundColor: '#f6f6f6' }}
                >
                  Non
                </button>
                <button
                  onClick={async () => {
                    await this.props.deleteDepense(selected_id, params);
                    this.openDeleteModal();
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Depenses);
