import { getAppErrorCode } from '../../../../utils/helpres';

const initialAppState = {
  error: undefined,
  isLoadingGetEmail: false,
  emailExist: false,
  isLoading: false,
  isLoadingRestPassword: false,
  updated: false,
  user: {},
  message: '',
};
const ResetPasswordReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'INITIAL_STATE_EMAIL_USER': {
      return {
        ...state,
        updated: false,
        emailExist: false,
      };
    }
    case 'GET_EMAIL_USER_REQUESTING': {
      return {
        ...state,
        isLoadingGetEmail: true,
        emailExist: false,
      };
    }
    case 'GET_EMAIL_USER_SUCCESS': {
      return {
        ...state,
        isLoadingGetEmail: false,
        emailExist: action.data,
      };
    }
    case 'GET_EMAIL_USER_FAILURE': {
      return {
        ...state,
        isLoadingGetEmail: false,
        emailExist: false,
        message: action.err,
        error: getAppErrorCode(action.status),
        ErrorMessage: action.ErrorMessage,
      };
    }
    case 'RESET_PASSWORD_REQUESTING': {
      return {
        ...state,
        isLoadingRestPassword: true,
        updated: false,
      };
    }
    case 'RESET_PASSWORD_SUCCESS': {
      return {
        ...state,
        isLoadingRestPassword: false,
        updated: true,
        user: action.data,
      };
    }
    case 'RESET_PASSWORD_FAILURE': {
      return {
        ...state,
        isLoadingRestPassword: false,
        updated: false,
        message: action.err,
        // error: getAppErrorCode(action.status),
        ErrorMessage: action.ErrorMessage,
      };
    }
    default: {
      return state;
    }
  }
};

export default ResetPasswordReducer;
