/* @flow */
import moment from 'moment';
import { getAppErrorCode } from '../utils/helpres';

const initialAppState = {
  tag: 'bill',
  sideBarActivate: false,
  keep_my_session_open: false,
  sandwich_menu_btn: false,
  error: undefined,
  isLoading: true,
  isLoadingGetUser: false,
  noInternet: false,
  notificationEnable: false,
  isLoadingLogin: false,
  isLoadingSpecialities: false,
  isLoadingUpdateUser: false,
  isAuthorized: false,
  specialities: [],
  verifyAccount: false,
  authLogin: false,
  updateUser: false,
  emailExist: false,
  emailDoctorExist: false,
  roles: [],
  // Données pour le doctor selectionné
  getUser: {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    roles: [],
    address_practitioner: {
      country: '',
      city: '',
      street: '',
      zipCode: '',
    },
  },
  // Données pour le doctor connecté
  user: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
    roles: [],
    address_practitioner: {
      country: '',
      city: '',
      street: '',
      zipCode: '',
    },
    Profile: {
      url: '',
    },
    Cover: {
      url: '',
    },
  },
  message: '',
  countMessageIsSeen: 0,
  namePage: 'Tableau de bord',
  activeItemSideBar: 0,
  activeItemSideBarRDV: false,
  isLoadingSecretaries: false,
  secretaries: [],
  isLoadingAssistants: false,
  assistants: [],
  isLoadingAssistant: false,
  assistant: null,
  isLoadingSecretary: false,
  secretary: null,
  isLoadingMissions: false,
  missions: [],
  isLoadingMission: false,
  mission: null,
  motif_references: null,
  isLoadingMotifReferences: null,
  isLoadingPermission: false,
  permissions: [],
  isLoadingPermissionUser: false,
  permissionsUser: [],
  isLoadingActionSecretary: false,
  actionSecretary: false,
  isLoadingMedecin: false,
  medecin: null,
  isLoadingMedecins: false,
  medecins: [],
  isLoadingActionMedecin: false,
  actionMedecin: null,
  isLoadingAbsences: false,
  absences: [],
  isLoadingHoliday: false,
  holidays: [],
  isLoadingHolidayUser: false,
  holidaysUser: [],
  isLoadingHolidayEmployeeManagement: false,
  employeeManagement: [],
  isLoadingActionOffice: false,
  isLoadingOffice: false,
  office: null,
  addOffice: false,
  isLoadingActionAbsences: false,
  actionAbsences: null,
  isLoadingPayment: false,
  payment: null,
  isLoadingActionEmployee: false,
  actionEmployee: false,
  isLoadingActionMission: true,
  actionMission: false,
  isLoadingEmployeeManagement: false,
  getEmployeeManagement: null,
  isLoadingArchive: false,
  archives: [],
  isLoadingDeleteAbsence: false,
  deleteAbsence: false,
  isLoadingStatistical: true,
  statistical: null,
  activeItemSideBarSETTINGS: 'COMPANY_MANAG',
  switchList: false,
  create: true,
  isLoadingAbsenceHours: false,
  absenceHours: false,
  regime: '',
  paramsPayment: {
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
  },
};

const AppReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case 'UPDATE_CHANGE_PAYMENT_PARAMS': {
      return {
        ...state,
        paramsPayment: action.paramsPayment,
      };
    }
    case 'UPDATE_REGIME':
      return {
        ...state,
        regime: action.regime,
      };
    case 'GET_ABSENCES_HOURS_REQUESTING':
      return {
        ...state,
        isLoadingAbsenceHours: false,
      };
    case 'GET_ABSENCES_HOURS_SUCCESS':
      return {
        ...state,
        isLoadingAbsenceHours: true,
        absenceHours: action.data,
      };
    case 'GET_ABSENCES_HOURS_FAILURE': {
      return {
        ...state,
        isLoadingAbsenceHours: false,
        absenceHours: false,
      };
    }

    case 'UPDATE_ACTIVE_ITEM_SIDE_BAR_SETTINGS':
      return {
        ...state,
        activeItemSideBarSETTINGS: action.activeItemSideBarSETTINGS,
        switchList: action.switchList,
        create: action.create,
      };
    case 'UPDATE_ACTIVE_ITEM_SIDE_BAR_COMPTA':
      return {
        ...state,
        activeItemSideBarCompta: action.activeItemSideBarCompta,
      };
    case 'GET_STATISTICAL_REQUESTING':
      return {
        ...state,
        isLoadingStatistical: true,
      };
    case 'GET_STATISTICAL_SUCCESS': {
      return {
        ...state,
        isLoadingStatistical: false,
        statistical: action.data,
      };
    }
    case 'GET_STATISTICAL_FAILURE': {
      return {
        ...state,
        isLoadingStatistical: false,
        statistical: null,
      };
    }

    case 'UPDATE_TAG':
      return {
        ...state,
        tag: action.tag,
      };
    case 'GET_DELETE_ABSENCE_REQUESTING':
      return {
        ...state,
        isLoadingDeleteAbsence: true,
        createdNewDispo: false,
      };
    case 'GET_DELETE_ABSENCE_SUCCESS': {
      return {
        ...state,
        isLoadingDeleteAbsence: false,
        createdNewDispo: false,
        deleteAbsence: true,
      };
    }
    case 'GET_DELETE_ABSENCE_FAILURE': {
      return {
        ...state,
        isLoadingDeleteAbsence: false,
        error: getAppErrorCode(action.status),
        message: action.msg,
        createdNewDispo: false,
        deleteAbsence: false,
      };
    }
    case 'GET_ARCHIVE_REQUESTING':
      return {
        ...state,
        isLoadingArchive: true,
      };
    case 'GET_ARCHIVE_SUCCESS':
      return {
        ...state,
        isLoadingArchive: false,
        archives: action.data,
      };
    case 'GET_ARCHIVE_FAILURE':
      return {
        ...state,
        isLoadingArchive: false,
        archives: null,
      };

    case 'GET_Employer_REQUESTING':
      return {
        ...state,
        isLoadingEmployeeManagement: true,
      };
    case 'GET_Employer_SUCCESS':
      return {
        ...state,
        isLoadingEmployeeManagement: false,
        getEmployeeManagement: action.data,
      };
    case 'GET_Employer_FAILURE':
      return {
        ...state,
        isLoadingEmployeeManagement: false,
        getEmployeeManagement: null,
      };

    case 'UPDATE_MISSION_REQUESTING': {
      return {
        ...state,
        isLoadingActionMission: true,
        actionMission: false,
      };
    }
    case 'UPDATE_MISSION_SUCCESS': {
      return {
        ...state,
        isLoadingActionMission: false,
        actionMission: true,
      };
    }
    case 'UPDATE_MISSION_FAILURE': {
      return {
        ...state,
        isLoadingActionMission: false,
        actionMission: false,
      };
    }

    case 'UPDATE_Employee_Management_REQUESTING': {
      return {
        ...state,
        isLoadingActionEmployee: true,
        actionEmployee: false,
      };
    }
    case 'UPDATE_Employee_Management_SUCCESS': {
      return {
        ...state,
        isLoadingActionEmployee: false,
        actionEmployee: true,
      };
    }
    case 'UPDATE_Employee_Management_FAILURE': {
      return {
        ...state,
        isLoadingActionEmployee: false,
        actionEmployee: false,
      };
    }

    case 'GET_PAYMENT_REQUESTING': {
      return {
        ...state,
        isLoadingPayment: true,
        payment: false,
      };
    }
    case 'GET_PAYMENT_SUCCESS': {
      return {
        ...state,
        isLoadingPayment: false,
        payment: action.data.data,
      };
    }
    case 'GET_PAYMENT_FAILURE': {
      return {
        ...state,
        isLoadingPayment: false,
        payment: false,
      };
    }

    case 'UPDATE_ABSENCE_REQUESTING': {
      return {
        ...state,
        isLoadingActionAbsences: true,
        actionAbsences: false,
      };
    }
    case 'UPDATE_ABSENCE_SUCCESS': {
      return {
        ...state,
        isLoadingActionAbsences: false,
        actionAbsences: true,
      };
    }
    case 'UPDATE_ABSENCE_FAILURE': {
      return {
        ...state,
        isLoadingActionAbsences: false,
        actionAbsences: false,
      };
    }

    case 'UPDATE_OFFICE_REQUESTING': {
      return {
        ...state,
        isLoadingActionOffice: true,
        addOffice: false,
      };
    }
    case 'UPDATE_OFFICE_SUCCESS': {
      return {
        ...state,
        isLoadingActionOffice: false,
        addOffice: true,
      };
    }
    case 'UPDATE_OFFICE_FAILURE': {
      return {
        ...state,
        isLoadingActionOffice: false,
        addOffice: false,
      };
    }

    /**Get office By Id*/
    case 'GET_OFFICE_REQUESTING': {
      return {
        ...state,
        isLoadingOffice: true,
      };
    }
    case 'GET_OFFICE_SUCCESS': {
      return {
        ...state,
        isLoadingOffice: false,
        office: action.data,
      };
    }
    case 'GET_OFFICE_FAILURE': {
      return {
        ...state,
        isLoadingOffice: false,
      };
    }
    case 'GET_USER_HOLIDAY_REQUESTING':
      return {
        ...state,
        isLoadingHolidayUser: true,
      };
    case 'GET_USER_HOLIDAY_SUCCESS':
      return {
        ...state,
        isLoadingHolidayUser: false,
        holidaysUser: action.data,
      };
    case 'GET_USER_HOLIDAY_FAILURE':
      return {
        ...state,
        isLoadingHolidayUser: false,
        holidaysUser: [],
      };

    case 'GET_EmployeeManagement_REQUESTING':
      return {
        ...state,
        isLoadingHolidayEmployeeManagement: true,
        actionEmployee: false,
      };
    case 'GET_EmployeeManagement_SUCCESS':
      return {
        ...state,
        isLoadingHolidayEmployeeManagement: false,
        employeeManagement: action.data,
      };
    case 'GET_EmployeeManagement_FAILURE':
      return {
        ...state,
        isLoadingHolidayEmployeeManagement: false,
        employeeManagement: [],
      };

    case 'GET_HOLIDAY_REQUESTING':
      return {
        ...state,
        isLoadingHoliday: true,
      };
    case 'GET_HOLIDAY_SUCCESS':
      return {
        ...state,
        isLoadingHoliday: false,
        holidays: action.data,
      };
    case 'GET_HOLIDAY_FAILURE':
      return {
        ...state,
        isLoadingHoliday: false,
        holidays: [],
      };

    case 'GET_ABSENCES_REQUESTING':
      return {
        ...state,
        actionAbsences: false,
        deleteAbsence: false,
        isLoadingAbsenses: true,
      };
    case 'GET_ABSENCES_SUCCESS':
      return {
        ...state,
        actionAbsences: false,
        deleteAbsence: false,
        isLoadingAbsenses: false,
        absences: action.data,
      };
    case 'GET_ABSENCES_FAILURE':
      return {
        ...state,
        deleteAbsence: false,
        isLoadingAbsenses: false,
        absences: [],
      };

    case 'GET_MEDECINS_REQUESTING':
      return {
        ...state,
        isLoadingMedecins: true,
      };
    case 'GET_MEDECINS_SUCCESS':
      return {
        ...state,
        isLoadingMedecins: false,
        medecins: action.data,
      };
    case 'GET_MEDECINS_FAILURE':
      return {
        ...state,
        isLoadingMedecins: false,
        medecins: [],
      };

    case 'UPDATE_MEDECIN_REQUESTING':
      return {
        ...state,
        isLoadingActionMedecin: true,
      };
    case 'UPDATE_MEDECIN_SUCCESS':
      return {
        ...state,
        isLoadingActionMedecin: false,
        actionMedecin: action.data,
      };
    case 'UPDATE_MEDECIN_FAILURE':
      return {
        ...state,
        isLoadingActionMedecin: false,
        actionMedecin: null,
      };

    case 'GET_MEDECIN_REQUESTING':
      return {
        ...state,
        isLoadingMedecin: true,
      };
    case 'GET_MEDECIN_SUCCESS':
      return {
        ...state,
        isLoadingMedecin: false,
        medecin: action.data,
      };
    case 'GET_MEDECIN_FAILURE':
      return {
        ...state,
        isLoadingMedecin: false,
        medecin: null,
      };

    case 'UPDATE_SECRETARY_REQUESTING':
      return {
        ...state,
        isLoadingActionSecretary: true,
      };
    case 'UPDATE_SECRETARY_SUCCESS':
      return {
        ...state,
        isLoadingActionSecretary: false,
        actionSecretary: action.data.data,
      };
    case 'UPDATE_SECRETARY_FAILURE':
      return {
        ...state,
        isLoadingActionSecretary: false,
        actionSecretary: false,
      };

    case 'GET_PERMISSIONS_BY_USER_REQUESTING':
      return {
        ...state,
        isLoadingPermissionUser: true,
      };
    case 'GET_PERMISSIONS_BY_USER_SUCCESS':
      return {
        ...state,
        isLoadingPermissionUser: false,
        permissionsUser: action.data,
      };
    case 'GET_PERMISSIONS_BY_USER_FAILURE':
      return {
        ...state,
        isLoadingPermissionUser: false,
        permissionsUser: [],
      };

    case 'GET_PERMISSIONS_REQUESTING':
      return {
        ...state,
        isLoadingPermission: true,
      };
    case 'GET_PERMISSIONS_SUCCESS':
      return {
        ...state,
        isLoadingPermission: false,
        permissions: action.data,
      };
    case 'GET_PERMISSIONS_FAILURE':
      return {
        ...state,
        isLoadingPermission: false,
        permissions: [],
      };

    case 'GET_MOTIF_REFERENCE_REQUESTING':
      return {
        ...state,
        isLoadingMotifReferences: true,
      };
    case 'GET_MOTIF_REFERENCE_SUCCESS':
      return {
        ...state,
        isLoadingMotifReferences: false,
        motif_references: action.data,
      };
    case 'GET_MOTIF_REFERENCE_FAILURE':
      return {
        ...state,
        isLoadingMotifReferences: false,
        motif_references: null,
      };

    case 'GET_MISSION_REQUESTING':
      return {
        ...state,
        isLoadingMission: true,
      };
    case 'GET_MISSION_SUCCESS':
      return {
        ...state,
        isLoadingMission: false,
        mission: action.data,
      };
    case 'GET_MISSION_FAILURE':
      return {
        ...state,
        isLoadingMission: false,
        mission: null,
      };

    case 'GET_MISSIONS_REQUESTING':
      return {
        ...state,
        isLoadingMissions: true,
      };
    case 'GET_MISSIONS_SUCCESS':
      return {
        ...state,
        isLoadingMissions: false,
        missions: action.data.data,
      };
    case 'GET_MISSIONS_FAILURE':
      return {
        ...state,
        isLoadingMissions: false,
        missions: [],
      };

    case 'GET_ASSISTANT_REQUESTING':
      return {
        ...state,
        isLoadingAssistants: true,
      };
    case 'GET_ASSISTANT_SUCCESS':
      return {
        ...state,
        isLoadingAssistants: false,
        assistant: action.data,
      };
    case 'GET_ASSISTANT_FAILURE':
      return {
        ...state,
        isLoadingAssistants: false,
        assistant: null,
      };
    case 'GET_ASSISTANTS_REQUESTING':
      return {
        ...state,
        isLoadingAssistants: true,
        actionSecretary: false,
      };
    case 'GET_ASSISTANTS_SUCCESS':
      return {
        ...state,
        isLoadingAssistants: false,
        assistants: action.data.data,
      };
    case 'GET_ASSISTANTS_FAILURE':
      return {
        ...state,
        isLoadingAssistants: false,
        assistants: [],
      };
    case 'GET_SECRETARY_REQUESTING':
      return {
        ...state,
        isLoadingSecretary: true,
      };
    case 'GET_SECRETARY_SUCCESS':
      return {
        ...state,
        isLoadingSecretary: false,
        secretary: action.data.data,
      };
    case 'GET_SECRETARY_FAILURE':
      return {
        ...state,
        isLoadingSecretary: false,
        secretary: null,
      };
    case 'GET_SECRETARIES_REQUESTING':
      return {
        ...state,
        isLoadingSecretaries: true,
      };
    case 'GET_SECRETARIES_SUCCESS':
      return {
        ...state,
        isLoadingSecretaries: false,
        secretaries: action.data.data,
      };
    case 'GET_SECRETARIES_FAILURE':
      return {
        ...state,
        isLoadingSecretaries: false,
        secretaries: [],
      };
    case 'ACTIVE_ITEM_SIDE_BAR_RDV':
      return {
        ...state,
        activeItemSideBarRDV: !state.activeItemSideBarRDV,
      };
    case 'UPDATE_SandwichMenuBtn':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        sandwich_menu_btn: !state.sandwich_menu_btn,
      };
    case 'UPDATE_ITEM_SIDE_BAR':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        side_bar_menu: action.side_bar_menu,
      };
    case 'UPDATE_USER_REQUESTING':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        isLoadingUpdateUser: true,
      };
    case 'UPDATE_USER_SUCCESS':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        isLoadingUpdateUser: false,
        updateUser: true,
        message: action.data.message,
      };
    case 'UPDATE_USER_FAILURE':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        isLoadingUpdateUser: false,
        updateUser: false,
        message: action.err,
        // error: getAppErrorCode(action.status),
      };

    case 'GET_USER_REQUESTING':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        isLoadingGetUser: true,
      };
    case 'GET_USER_SUCCESS':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        isLoadingGetUser: false,
        isAuthorized: true,
        getUser: action.data,
        message: action.data.message,
        updateUser: false,
      };
    case 'GET_USER_FAILURE':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        isLoadingGetUser: false,
        isAuthorized: false,
        message: action.err,
        error: getAppErrorCode(action.status),
      };

    case 'ENABLE_NOTIFICATION_REQUESTING':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        notificationEnable: false,
      };
    case 'ENABLE_NOTIFICATION_SUCCESS':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        notificationEnable: true,
        message: action.data.message,
      };
    case 'ENABLE_NOTIFICATION_FAILURE':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        notificationEnable: false,
        message: action.err,
        error: getAppErrorCode(action.status),
      };

    case 'GET_VERIFY_ACCOUNT_REQUESTING':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        verifyAccount: false,
      };
    case 'GET_VERIFY_ACCOUNT_SUCCESS':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        verifyAccount: action.data.complet,
      };
    case 'GET_VERIFY_ACCOUNT_FAILURE':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        verifyAccount: false,
      };

    case 'LOGIN_REQUESTING':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        //  isLoading: true,
        isLoadingLogin: true,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        //isLoading: false,
        isLoadingLogin: false,
        //isAuthorized: true,
        authLogin: true,
        user: {
          ...action.data.user,
        },
        message: action.data.message,
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        authLogin: false,
        //isLoading: false,
        isLoadingLogin: false,
        //isAuthorized: false,
        message: action.err,
        // error: getAppErrorCode(action.status),
      };
    case 'GET_ME_SUCCESS':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        isAuthorized: true,
        isLoading: false,
        user: {
          ...action.data,
        },
      };
    case 'GET_ME_REQUESTING':
      return {
        ...state,
        isAuthorized: false,
        isLoading: true,
        keep_my_session_open: state.keep_my_session_open,
      };
    case 'GET_ME_FAILURE':
      return {
        keep_my_session_open: state.keep_my_session_open,
        isLoading: false,
        isAuthorized: false,
        error: getAppErrorCode(action.status),
        user: '',
        message: '',
      };
    case 'LOGOUT_FAILURE':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        isLoading: false,
        isAuthorized: false,
        user: '',
        message: '',
      };
    /**Get Role*/
    case 'GET_ROLES_REQUESTING':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        roles: action.data,
      };
    case 'GET_ROLES_SUCCESS':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        roles: action.data,
      };
    case 'GET_ROLES_FAILURE':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        roles: action.data,
      };
    /**Get Specialities*/
    case 'GET_SPECIALITIES_REQUESTING':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        isLoadingSpecialities: true,
      };
    case 'GET_SPECIALITIES_SUCCESS':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        isLoadingSpecialities: false,
        //isAuthorized: true,
        specialities: action.data.data,
        message: action.data.message,
      };
    case 'GET_SPECIALITIES_FAILURE':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        isLoadingSpecialities: false,
        // isAuthorized: false,
        message: action.err,
        error: getAppErrorCode(action.status),
      };
    case 'UPDATE_COUNT_MESSAGE_SEEN':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        countMessageIsSeen: action.countMessageIsSeen,
      };
    case 'UPDATE_NAME_PAGE':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        namePage: action.namePage,
      };
    case 'UPDATE_ACTIVE_ITEM_SIDE_BAR':
      return {
        ...state,
        keep_my_session_open: state.keep_my_session_open,
        activeItemSideBar: action.activeItemSideBar,
      };
    case 'keep_my_session_open':
      return {
        ...state,
        keep_my_session_open: action.keep_my_session_open,
      };

    default:
      return state;
  }
};

export default AppReducer;
