import React, { Component } from 'react';
import './index.css';
import actions from './store/actions';
import actionsJs from '../../js/actions';
import { connect } from 'react-redux';
import moment from 'moment';
import { SelectorContactFacturesBodyItem } from '../../utils';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const mapStateToProps = (state) => ({
  billsByIds: state.FactureReducer.billsByIds,
  addBordereau: state.FactureReducer.addBordereau,

  user: state.AppReducer.user,
  bill: state.FactureReducer.bill,
  bills: state.FactureReducer.bills,
  isLoadingBills: state.FactureReducer.isLoadingBills,
  isLoadingBill: state.FactureReducer.isLoadingBill,
  isLoadingSendBillByEmail: state.FactureReducer.isLoadingSendBillByEmail,
  sendBillByEmail: state.FactureReducer.sendBillByEmail,
  params: state.FactureReducer.params,
  office: state.AppReducer.office,
});

const mapDispatchToProps = (dispatch) => ({
  getBillById: (id) => dispatch(actions.getBillById(id)),
  submitSendBill: (id) => dispatch(actions.submitSendBill(id)),
  getAllBill: (params, type, regime) =>
    dispatch(actions.getAllBill(params, type, regime)),

  getBillByIds: (ids) => dispatch(actions.getBillByIds(ids)),

  changeTag: (tag) => dispatch(actionsJs.changeTag(tag)),
  getOfficeByIdDoctor: () => dispatch(actionsJs.getOfficeByIdDoctor()),
  changeSearched: async (params, type, regime) => {
    await dispatch(actions.changeSearchedStringBill(params));
    dispatch(actions.getAllBill(params, type, regime));
  },
  submitUpdateBillForm: async (values) => {
    await dispatch(actions.submitUpdateBillForm(values));
    dispatch(actions.getBillById(values.id));
  },
  changeSearchedStatus: async (params, type, regime) => {
    await dispatch(actions.changeSearchedStringBill(params));
    dispatch(actions.getAllBill(params, type, regime));
  },
  deleteFacture: async (id, params, type, regime) => {
    await dispatch(actions.deleteFacture(id));
    dispatch(actions.getAllBill(params, type, regime));
  },
  changeSearchedEndDate: async (params, type, regime) => {
    await dispatch(actions.changeSearchedStringBill(params));
    dispatch(actions.getAllBill(params, type, regime));
  },
  changeSearchedStartDate: async (params, type, regime) => {
    await dispatch(actions.changeSearchedStringBill(params));
    dispatch(actions.getAllBill(params, type, regime));
  },
  changeSearchedStringLimit: async (params, type, regime) => {
    await dispatch(actions.changeSearchedStringBill(params));
    dispatch(actions.getAllBill(params, type, regime));
  },
  changeLimit: async (params, type, regime) => {
    await dispatch(actions.changeSearchedStringBill(params));
    dispatch(actions.getAllBill(params, type, regime));
  },
  changeActiveItemSideBarSettings: (item, data, create) =>
    dispatch(actionsJs.changeActiveItemSideBarSettings(item, data, create)),
  changeRegime: (regime) => dispatch(actionsJs.changeRegime(regime)),
  changeActiveItemSideBarCompta: (regime) =>
    dispatch(actionsJs.changeActiveItemSideBarCompta(regime)),
});
function createData(number, date, total, status, actions) {
  return {
    number,
    date,
    total,
    status,
    actions,
    bills: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row, openModalDelete, office, user, type, openModalStatus } = props;
  const [open, setOpen] = React.useState(false);
  console.log(row.bills);
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.number}</TableCell>
        <TableCell>
          {row.patient_record && row.patient_record.patient ? (
            `${row.patient_record.patient.last_name} ${row.patient_record.patient.first_name}`
          ) : row.consultation &&
            row.consultation.patient_record &&
            row.consultation.patient_record.patient ? (
            `${row.consultation.patient_record.patient.first_name} ${row.consultation.patient_record.patient.last_name}`
          ) : (
            <strong style={{ color: '#8080803d' }}>Patient non renseignée</strong>
          )}
        </TableCell>
        <TableCell>
          {row.consultation &&
          row.consultation.prise_en_charges &&
          row.consultation.prise_en_charges.length > 0 &&
          row.consultation.prise_en_charges[0].code_bureau ? (
            `${row.consultation.prise_en_charges[0].code_bureau}/${row.consultation.prise_en_charges[0].year}/${row.consultation.prise_en_charges[0].num_order}`
          ) : (
            <strong style={{ color: '#8080803d' }}>PEC non renseignée</strong>
          )}
        </TableCell>
        <TableCell>
          {' '}
          {row.consultation &&
          row.consultation.prise_en_charges &&
          row.consultation.prise_en_charges.length > 0 &&
          row.consultation.prise_en_charges[0].quality ? (
            row.consultation.prise_en_charges[0].quality
          ) : (
            <strong style={{ color: '#8080803d' }}>Qualité non renseignée</strong>
          )}
        </TableCell>
        <TableCell align="left">{moment(row.date).format('YYYY-MM-DD')}</TableCell>
        <TableCell align="left">{row.total_ttc}</TableCell>
        <TableCell align="left">
          {
            //7ordereau
            row.bordereau_bill ? (
              <button
                style={{ width: '150px' }}
                className={
                  row.bordereau_bill.bordereau.status === 'invoiced' ? 'payed' : ''
                }
              >
                {row.bordereau_bill.bordereau.status === 'invoiced'
                  ? `B ${row.bordereau_bill.bordereau.number} - Facturée`
                  : `B ${row.bordereau_bill.bordereau.number} - Payée`}
              </button>
            ) : (
              <button style={{ width: '100px' }}>Non attachée</button>
            )
          }
        </TableCell>
        <TableCell align="left">
          <div>
            <Link
              to={{
                pathname: '/facture-pdf',
                state: {
                  office: office,
                  facture: row,
                  tag: type,
                },
              }}
            >
              <LocalPrintshopIcon
                fontSize="large"
                color="info"
                sx={{ marginInline: '0.1vw' }}
              />
            </Link>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Liste des factures
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Act</TableCell>
                    <TableCell> Date début</TableCell>
                    <TableCell>Date fin</TableCell>
                    {/* <TableCell align="right">Amount</TableCell>*/}
                    <TableCell align="right">Séances</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={row.date}>
                    <TableCell component="th" scope="row">
                      {row.line_bill.act || '-'}
                    </TableCell>
                    <TableCell>
                      {' '}
                      {row.start_date ? (
                        ` Du ${moment(row.start_date).format('DD/MM/YYYY')}`
                      ) : (
                        <strong style={{ color: 'rgba(0,0,0,0.24)' }}>
                          Date début non renseignée
                        </strong>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {' '}
                      {row.end_date ? (
                        ` Au ${moment(row.end_date).format('DD/MM/YYYY')}`
                      ) : (
                        <strong style={{ color: 'rgba(0,0,0,0.24)' }}>
                          Date fin non renseignée
                        </strong>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {row.line_bill.sessions_nbr || '-'}
                    </TableCell>
                    <TableCell align="center">
                      {parseFloat(row.total_ttc).toFixed(2) || '-'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
class Factures extends Component {
  constructor(props) {
    super(props);
    props.changeActiveItemSideBarCompta(33000);
    props.changeTag('bill');
    props.getOfficeByIdDoctor();
    props.getAllBill(props.params, 'bill', props.type || 'autre');
    props.changeNamePage(
      props.type === 'cnam'
        ? 'Factures CNAM'
        : props.type === 'act'
        ? 'Factures ACT'
        : 'Factures',
    );
    props.changeRegime(props.type);
    document.title =
      props.type === 'cnam'
        ? 'Factures CNAM'
        : props.type === 'act'
        ? 'Factures ACT'
        : 'Factures';
    this.state = {
      errorDate: false,
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }
  handleChangePage(event, newPage) {
    console.log(newPage);
    this.props.changeLimit(
      { ...this.props.params, currentPage: newPage + 1 },
      'bill',
      this.props.type || 'autre',
    );
    //setPage(newPage);
  }

  handleChangeRowsPerPage(event) {
    this.props.changeLimit(
      { ...this.props.params, currentPage: 1, limit: parseInt(event.target.value, 10) },
      'bill',
      this.props.type || 'autre',
    );
    /*setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);*/
  }
  componentDidMount() {
    SelectorContactFacturesBodyItem();
    this.props.changeSearchedStartDate(
      {
        limit: 10,
        currentPage: 1,
        start_date: '',
        end_date: '',
        search: '',
        status: '',
        type: '',
      },
      'bill',
      this.props.type || 'autre',
    );
  }
  handleSelectedBill(selectedBill) {
    this.setState({
      selectedBill: !selectedBill,
    });
  }

  render() {
    const {
      params,
      office,
      sendBillByEmail,
      bills,
      bill,
      isLoadingBills,
      isLoadingBill,
      type,
      selectedId,
    } = this.props;
    const { errorDate } = this.state;
    const { data } = bills;
    return (
      <>
        <div className="facture_patient_header">
          <p>Rechercher une facture</p>
          <div className="facture_patient_controls">
            <input
              style={{ width: '20%', marginLeft: '-7%' }}
              onChange={(event) => {
                this.props.changeSearched(
                  {
                    ...params,
                    search: event.target.value ? event.target.value : '',
                  },
                  'bill',
                  type || 'autre',
                );
              }}
              type="text"
              placeholder={'Nom,  ou numéro de facture'}
            />
            <input
              style={{
                width: '30%',
                borderBottom: errorDate ? '1px solid #ff808b' : '',
              }}
              id="date"
              type="date"
              value={
                params.start_date ? moment(params.start_date).format('YYYY-MM-DD') : ''
              }
              onChange={(e) => {
                this.props.changeSearchedStartDate(
                  {
                    ...params,
                    start_date: e.target.value
                      ? moment(e.target.value).format('YYYY-MM-DD')
                      : '',
                  },
                  'bill',
                  type || 'autre',
                );
              }}
            />
            <input
              style={{
                width: '30%',
                borderBottom: errorDate ? '1px solid #ff808b' : '',
              }}
              id="date"
              type="date"
              className="type3"
              value={params.end_date ? moment(params.end_date).format('YYYY-MM-DD') : ''}
              onChange={(e) => {
                if (moment(params.start_date).isAfter(moment(e.target.value))) {
                  this.setState({
                    errorDate: true,
                  });
                  this.props.changeSearchedEndDate(
                    {
                      ...params,
                      end_date: e.target.value
                        ? moment(e.target.value).format('YYYY-MM-DD')
                        : '',
                    },
                    'bill',
                    type || 'autre',
                  );
                } else {
                  this.setState({
                    errorDate: false,
                  });
                  this.props.changeSearchedEndDate(
                    {
                      ...params,
                      end_date: e.target.value
                        ? moment(e.target.value).format('YYYY-MM-DD')
                        : '',
                    },
                    'bill',
                    type || 'autre',
                  );
                }
              }}
            />
          </div>
        </div>
        <div className="facture_patient_controls">

        </div>
        <TableContainer
          component={Paper}
          sx={{
            width: '100% !important',
            height: 'auto !important',
          }}
        >
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell sx={{ fontWeight: 'bold' }}>Numéro facture</TableCell>
                <TableCell align="left">Patient</TableCell>
                <TableCell align="left">N° PEC</TableCell>
                <TableCell align="left">Qualité</TableCell>
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Total TTC</TableCell>
                <TableCell align="left">Statut</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bills &&
                bills.data &&
                bills.data.length > 0 &&
                bills.data.map((row) => (
                  <Row
                    key={row.name}
                    type={type}
                    office={office}
                    //user={user}
                    row={row}
                    openModalDelete={this.openModalDelete}
                    openModalStatus={this.openModalStatus}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {bills && bills.total > 3 && (
          <TablePagination
            rowsPerPageOptions={[3, 5, 10, 25, 100]}
            component="div"
            count={bills.total}
            rowsPerPage={bills.per_page}
            page={bills.current_page - 1}
            onPageChange={this.handleChangePage}
            labelRowsPerPage="Lignes par page :"
            // labelDisplayedRows="00000000"
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        )}
      </>
      /*<div>
        <div className="main_content">
          <div className="facture_patient">
            
            {isLoadingBills ? (
              <CircularProgress
                disableShrink
                style={{ marginLeft: '47%', marginTop: '30%' }}
              />
            ) : data && data.length > 0 ? (
              <div className="facture_patients_body">
                {data.map((item) => {
                  return (
                    <Item_bill
                      key={item.id}
                      getBillById={this.props.getBillById}
                      tag="bill"
                      item={item}
                      id={
                        selectedId
                          ? parseInt(selectedId)
                          : data && data.length > 0 && data[0].id
                      }
                    />
                  );
                })}
              </div>
            ) : (
              ''
            )}
          </div>

          <div className="facture_info_section">
            {data && data.length === 0 ? (
              <div className="container_changeable_content">
                <div className="fatcure_detailed_info_section">
                  <div
                    style={{ marginTop: '10%' }}
                    className="empty_consultation_result_container"
                  >
                    <div className="patient_detailed_info_section_body">
                      <div className="empty_consultation_result">
                        <img
                          src={require('../../assets/img/undraw_design_team_af2y.svg')}
                        />
                        <span id="no_consultation">Pas de Factures !</span>
                        <span id="no_consultation_desc">
                          Essayez de créer des nouvelles factures. Si vous n'avez pas des
                          cures, commencez déja par en créer des nouvelles.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : isLoadingBill ? (
              <CircularProgress
                disableShrink
                style={{ marginLeft: '47%', marginTop: '30%' }}
              />
            ) : data && data.length === 0 && !isLoadingBill ? (
              <div className="container_changeable_content">
                <div className="fatcure_detailed_info_section">
                  <div
                    style={{ marginTop: '10%' }}
                    className="empty_consultation_result_container"
                  >
                    <div className="patient_detailed_info_section_body">
                      <div className="empty_consultation_result">
                        <img
                          src={require('../../assets/img/undraw_design_team_af2y.svg')}
                        />
                        <span id="no_consultation">Pas de Fatcure ! !</span>
                        <span id="no_consultation_desc">
                          Essayez de créer de nouveaux consultation ou synchroniser votre
                          service de calendrier
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Facture
                deleteFacture={this.props.deleteFacture}
                submitUpdateBillForm={this.props.submitUpdateBillForm}
                submitSendBill={this.props.submitSendBill}
                sendBillByEmail={sendBillByEmail}
                bill={bill}
                office={office}
                params={params}
                type={type}
                tag="bill"
              />
            )}
          </div>
        </div>
      </div>*/
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Factures);
