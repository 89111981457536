import React, { Component } from 'react';
import './index.css';
import actions from './store/actions';
import actionsJs from '../../js/actions';
import { connect } from 'react-redux';
import Item_bill from './containers/item_bill';
import moment from 'moment';
import { SelectorContactFacturesBodyItem } from '../../utils';
import ReceiptForm from './containers/facture';
import CircularProgress from '@material-ui/core/CircularProgress';

const mapStateToProps = (state) => ({
  receiptsByIds: state.ReceiptReducer.receiptsByIds,
  user: state.AppReducer.user,
  receipt: state.ReceiptReducer.receipt,
  receipts: state.ReceiptReducer.receipts,
  isLoadingReceipts: state.ReceiptReducer.isLoadingReceipts,
  isLoadingReceipt: state.ReceiptReducer.isLoadingReceipt,
  params: state.ReceiptReducer.params,
  office: state.AppReducer.office,
});

const mapDispatchToProps = (dispatch) => ({
  getReceiptById: (id) => dispatch(actions.getReceiptById(id)),

  submitUpdateReceiptForm: async (values) => {
    await dispatch(actions.submitUpdateReceiptForm(values));
    dispatch(actions.getReceiptById(values.id));
  },
  changeActiveItemSideBarCompta: (regime) =>
    dispatch(actionsJs.changeActiveItemSideBarCompta(regime)),
  getOfficeByIdDoctor: () => dispatch(actionsJs.getOfficeByIdDoctor()),
});

class Details extends Component {
  constructor(props) {
    super(props);
    props.getReceiptById(props.match.params.id);
    props.changeNamePage('Détail reçu');
    props.changeActiveItemSideBarCompta(152);
    props.getOfficeByIdDoctor();
    props.changeActiveItemSideBar(9);
    document.title = 'Détail reçu';
  }

  render() {
    const { receipt, office, isLoadingReceipt } = this.props;
    if (isLoadingReceipt)
      return (
        <CircularProgress disableShrink style={{ marginLeft: '47%', marginTop: '30%' }} />
      );
    return (
      <div>
        <div className="main_content">
          <div className="facture_info_section">
            <ReceiptForm
              submitUpdateReceiptForm={this.props.submitUpdateReceiptForm}
              receipt={receipt}
              office={office}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);
