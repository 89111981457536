import moment from 'moment';

const initialUserState = {
  isLoadingActionRecette: false,
  actionRecette: false,
  recettes: [],
  recette: null,
  isLoadingRecettes: false,
  isLoadingReceiptById: true,
  receiptById: null,
  totalRecette: null,
  isLoadingRecette: true,
  params: {
    limit: 100,
    currentPage: 1,
    search: '',
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    status: '',
  },
  destrpyRecette: null,
  isLoadingDestroyRecette: true,
};

const RecetteReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case 'DELETE_RECETTE_REQUESTING': {
      return {
        ...state,
        isLoadingDestroyRecette: true,
      };
    }
    case 'DELETE_RECETTE_SUCCESS': {
      return {
        ...state,
        isLoadingDestroyRecette: false,
        destrpyRecette: true,
      };
    }
    case 'DELETE_RECETTE_FAILURE': {
      return {
        ...state,
        isLoadingDestroyRecette: false,
        destrpyRecette: null,
      };
    }

    case 'GET_RECETTE_BY_ID_REQUESTING': {
      return {
        ...state,
        isLoadingReceiptById: true,
      };
    }
    case 'GET_RECETTE_BY_ID_SUCCESS': {
      return {
        ...state,
        isLoadingReceiptById: false,
        recette: action.data,
      };
    }
    case 'GET_RECETTE_BY_ID_FAILURE': {
      return {
        ...state,
        isLoadingReceiptById: false,
        recette: null,
      };
    }

    case 'GET_RECETTES_REQUESTING': {
      return {
        ...state,
        isLoadingRecettes: true,
        actionRecette: false,
      };
    }
    case 'GET_RECETTES_SUCCESS': {
      return {
        ...state,
        isLoadingRecettes: false,
        recettes: action.data.data,
        totalRecette: action.data.sumRecette,
      };
    }
    case 'GET_RECETTES_FAILURE': {
      return {
        ...state,
        isLoadingRecettes: false,
        recettes: [],
      };
    }

    case 'UPDATE_RECETTE_REQUESTING': {
      return {
        ...state,
        isLoadingActionRecette: true,
        actionRecette: false,
      };
    }
    case 'UPDATE_RECETTE_SUCCESS': {
      return {
        ...state,
        isLoadingActionRecette: false,
        actionRecette: true,
      };
    }
    case 'UPDATE_RECETTE_FAILURE': {
      return {
        ...state,
        isLoadingActionRecette: false,
        actionRecette: false,
      };
    }

    case 'UPDATE_SEARCH_RECETTE_PARAMS': {
      return {
        ...state,
        params: action.params,
      };
    }
    default: {
      return state;
    }
  }
};

export default RecetteReducer;
