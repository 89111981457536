import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../profile/InfoGenerale/index.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SelectorInputAndIcon } from '../../../utils';
import { isNumeric, lengthPhone } from '../../../utils/helpres';
import SimpleReactValidator from 'simple-react-validator';
import InputField from '../../../components/inputField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { sortCities, country } from '../../../utils/constants';
import TextField from '@material-ui/core/TextField';
import actions from '../../../js/actions';

const mapStateToProps = (state, ownProps) => {
  const { office } = ownProps;
  let first_name;
  let last_name;
  let email;
  let phone;
  let country;
  let city;
  let zip_code;
  let street;
  let cnam;
  let office_code;
  let cnss;
  let bank;
  let rib;
  let tax_registration_number;
  let name;
  let conventional_code;
  let number_employer;

  if (office) {
    office_code = office && office.office_code ? office.office_code : '';
    bank = office && office.bank ? office.bank : '';
    conventional_code =
      office && office.conventional_code ? office.conventional_code : '';
    cnam = office && office.cnam ? office.cnam : '';
    number_employer = office && office.number_employer ? office.number_employer : '';
    cnss = office && office.cnss ? office.cnss : '';
    name = office && office.name ? office.name : '';
    rib = office && office.rib ? office.rib : '';
    phone = office && office.phone ? office.phone : '';
    first_name = office && office.first_name ? office.first_name : '';
    last_name = office && office.last_name ? office.last_name : '';
    email = office && office.email ? office.email : '';
    tax_registration_number =
      office && office.tax_registration_number ? office.tax_registration_number : '';
    country =
      office && office.address_office && office.address_office.country
        ? office.address_office.country
        : '';
    city =
      office && office.address_office && office.address_office.city
        ? office.address_office.city
        : '';
    street =
      office && office.address_office && office.address_office.street
        ? office.address_office.street
        : '';
    zip_code =
      office && office.address_office && office.address_office.zip_code
        ? office.address_office.zip_code
        : '';
  }
  return {
    sandwich_menu_btn: state.AppReducer.sandwich_menu_btn,
    isLoadingActionOffice: state.AppReducer.isLoadingActionOffice,
    office: office,
    initialValues: {
      ...office,
      cnam: cnam,
      tax_registration_number: tax_registration_number,
      cnss: cnss,
      bank: bank,
      name: name,
      rib: rib,
      conventional_code: conventional_code,
      first_name: first_name,
      last_name: last_name,
      number_employer: number_employer,
      email: email,
      office_code: office_code,
      phone: phone,
      address_office: {
        country: country,
        city: city,
        street: street,
        zip_code: zip_code,
      },
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    AddOffice: async (values) => {
      await dispatch(actions.AddOffice(values));
      dispatch(actions.getOfficeByIdDoctor(values.id));
    },
  };
};
class CompanyManagement extends Component {
  constructor(props) {
    super(props);
    props.changeNamePage('Gestion de la société');
    document.title = 'Gestion de la Societé';
    this.state = {
      initialValues: {
        ...props.initialValues,
      },
      hasError: false,
    };
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        // OR
        default: 'Cette information est requise.', // will override all messages
      },
    });
    this.handleInitialValues = this.handleInitialValues.bind(this);
  }
  handleInitialValues() {
    this.setState({
      initialValues: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        country: '',
        city: '',
        rib: '',
        zip_code: '',
        street: '',
        cnam: '',
        office_code: '',
        conventional_code: '',
        number_employer: '',
        cnss: '',
        bank: '',
        tax_registration_number: '',
        name: '',
        address_office: {
          country: '',
          city: '',
          street: '',
          zip_code: '',
        },
      },
      hasError: false,
    });
  }
  componentDidMount() {
    SelectorInputAndIcon();
  }

  submitForm(initialValues) {
    if (
      (initialValues.phone && !lengthPhone(initialValues.phone)) ||
      // (initialValues.office_code && !isCodeCNAM(initialValues.office_code)) ||
      (initialValues.phone && !isNumeric(initialValues.phone)) ||
      //(initialValues.office_code && !isNumeric(initialValues.office_code)) ||
      (initialValues.address_office.zip_code &&
        !isNumeric(initialValues.address_office.zip_code)) ||
      !initialValues.address_office.zip_code ||
      !initialValues.address_office.city ||
      !initialValues.address_office.country ||
      !initialValues.address_office.street ||
      !initialValues.name ||
      !initialValues.email ||
      !initialValues.conventional_code ||
      !initialValues.number_employer ||
      !initialValues.rib ||
      //!initialValues.office_code ||
      !initialValues.bank ||
      !initialValues.last_name ||
      !initialValues.first_name ||
      !initialValues.phone ||
      !initialValues.tax_registration_number
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else {
      this.props.AddOffice(initialValues);
    }
  }

  render() {
    const { sandwich_menu_btn, isLoadingActionOffice } = this.props;
    const { initialValues, hasError } = this.state;
    return (
      <div className="general_info" style={{ marginTop: 0, width: '97%' }}>
        <>
          <form className="general_info_form">
            <div style={{ display: 'flex', gap: 10 }}>
              <svg
                onClick={async () => {
                  this.props.goBackItem('showInfo');
                }}
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
              >
                <rect
                  x="0.5"
                  y="45.5"
                  width="45"
                  height="45"
                  rx="9.5"
                  transform="rotate(-90 0.5 45.5)"
                  fill="white"
                  stroke="#ECEEF5"
                ></rect>
                <path
                  d="M20.0117 23L24.5117 18.5L25.5664 19.5547L22.1211 23L25.5664 26.4453L24.5117 27.5L20.0117 23Z"
                  fill="#8083A3"
                ></path>
              </svg>
              <div style={{ marginTop: 10 }} className="title_and_button">
                <span>Informations personnelles</span>
              </div>
            </div>
            <div className="general_info_actual_form">
              <InputField
                width={sandwich_menu_btn ? '26vw' : '30vw'}
                type="text"
                placeholder="Nom"
                className="general_info_form_item"
                validInput="string"
                label="Nom"
                hasError={hasError}
                value={initialValues.first_name}
                name="first_name"
                icon={<i className="fas fa-user"></i>}
                // required={this.validator.message(
                //   'first_name',
                //   initialValues.first_name,
                //   'required|string',
                // )}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      first_name: e.target.value,
                    },
                  });
                }}
              />
              <InputField
                width={sandwich_menu_btn ? '26vw' : '30vw'}
                className="general_info_form_item"
                type="text"
                placeholder="Prénom"
                label="Prénom"
                hasError={hasError}
                validInput="string"
                value={initialValues.last_name}
                name="last_name"
                icon={<i className="fas fa-user"></i>}
                // required={this.validator.message(
                //   'last_name',
                //   initialValues.last_name,
                //   'required|string',
                // )}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      last_name: e.target.value,
                    },
                  });
                }}
              />
              <InputField
                width={sandwich_menu_btn ? '26vw' : '30vw'}
                className="general_info_form_item"
                type="text"
                placeholder="Adresse e-mail"
                label="Adresse e-mail"
                hasError={hasError}
                // disabled={true}
                value={initialValues.email}
                name="email"
                icon={<i className="fas fa-envelope"></i>}
                // required={this.validator.message(
                //   'email',
                //   initialValues.email,
                //   'required|email',
                // )}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      email: e.target.value,
                    },
                  });
                }}
              />

              <InputField
                width={sandwich_menu_btn ? '26vw' : '30vw'}
                className="general_info_form_item"
                type="text"
                placeholder="Tél/Fax"
                label="Tél/Fax"
                minLength={8}
                maxLength={20}
                hasError={hasError}
                validInput="tel"
                value={initialValues.phone}
                name="phone"
                icon={<i className="fas fa-phone-alt"></i>}
                // required={this.validator.message('phone', initialValues.phone, 'integer')}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      phone: e.target.value,
                    },
                  });
                }}
              />
              <InputField
                width={sandwich_menu_btn ? '26vw' : '30vw'}
                className="general_info_form_item"
                type="text"
                placeholder="Nom du cabinet"
                label="Nom du cabinet"
                hasError={hasError}
                validInput=""
                value={initialValues.name}
                name="name_cabinet"
                icon={<i className="fas fa-user"></i>}
                // required={this.validator.message(
                //   'name_cabinet',
                //   initialValues.name,
                //   'required|string',
                // )}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      name: e.target.value,
                    },
                  });
                }}
              />
            </div>

            <div className="address_form">
              <div className="title_and_button">
                <span id="">Adresse</span>
              </div>
              <div className="add_patient_actual_form">
                <div className="field_row">
                  <InputField
                    width="100%"
                    className="add_patient_form_item"
                    type="text"
                    placeholder="Adresse"
                    label="Adresse"
                    hasError={hasError}
                    validInput=""
                    value={initialValues.address_office.street}
                    name="street"
                    icon={<i className="fas fa-city"></i>}
                    // required={this.validator.message(
                    //   'street',
                    //   initialValues.address_office.street,
                    //   'required|string',
                    // )}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          address_office: {
                            ...initialValues.address_office,
                            street: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                  <div style={{ width: '100%' }} className="add_patient_form_item">
                    <label>Ville</label>
                    <Autocomplete
                      id="size-small-standard"
                      size="small"
                      options={sortCities}
                      icon={<i className="fas fa-city"></i>}
                      onChange={(event, option) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            address_office: {
                              ...initialValues.address_office,
                              city: option != null ? option.title : '',
                            },
                          },
                        });
                      }}
                      defaultValue={{
                        title:
                          initialValues.address_office &&
                          initialValues.address_office.city,
                      }}
                      getOptionLabel={(option) => option.title}
                      renderInput={(cities) => (
                        <TextField
                          style={{ width: '100%' }}
                          placeholder="Ville"
                          {...cities}
                          variant="standard"
                          //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                          //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                          error={hasError && !initialValues.address_office.city}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="field_row">
                  <InputField
                    width="100%"
                    className="add_patient_form_item"
                    type="text"
                    placeholder="Code Postal"
                    label="Code Postal"
                    hasError={hasError}
                    validInput="integer"
                    value={initialValues.address_office.zip_code}
                    name="zip_code"
                    icon={<i className="fas fa-keyboard"></i>}
                    // required={this.validator.message(
                    //   'zip_code',
                    //   initialValues.address_office.zip_code,
                    //   'required|integer',
                    // )}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          address_office: {
                            ...initialValues.address_office,
                            zip_code: e.target.value,
                          },
                        },
                      });
                    }}
                  />
                  <div style={{ width: '100%' }} className="add_patient_form_item">
                    <label>Pays</label>
                    <Autocomplete
                      id="size-small-standard"
                      size="small"
                      options={country}
                      icon={<i className="fas fa-globe-africa"></i>}
                      onChange={(event, option) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            address_office: {
                              ...initialValues.address_office,
                              country: option != null ? option.title : '',
                            },
                          },
                        });
                      }}
                      defaultValue={{
                        title:
                          initialValues.address_office &&
                          initialValues.address_office.country,
                      }}
                      getOptionLabel={(option) => option.title}
                      renderInput={(country) => (
                        <TextField
                          style={{ width: '100%' }}
                          placeholder="Pays"
                          {...country}
                          variant="standard"
                          //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                          //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                          error={hasError && !initialValues.address_office.country}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <div className="title_and_button">
              <span>Coordonnées bancaires et CNAM</span>
            </div>
            <div className="general_info_actual_form">
              <br />
              <br />
              <br />
              <br />
              <InputField
                width={sandwich_menu_btn ? '26vw' : '30vw'}
                className="general_info_form_item"
                type="text"
                placeholder="Code conventionnel "
                label="Code conventionnel "
                hasError={hasError}
                //disabled={true}
                value={initialValues.conventional_code}
                name="conventional_code"
                icon={<i className="fab fa-accessible-icon"></i>}
                // required={this.validator.message(
                //   'cnss',
                //   initialValues.cnss,
                //   'required|string',
                // )}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      conventional_code: e.target.value,
                    },
                  });
                }}
              />
              <InputField
                width={sandwich_menu_btn ? '26vw' : '30vw'}
                className="general_info_form_item"
                type="text"
                placeholder="N° Employeur"
                label="N° Employeur"
                hasError={hasError}
                //disabled={true}
                value={initialValues.number_employer}
                name="number_employer"
                icon={<i className="fab fa-accessible-icon"></i>}
                // required={this.validator.message(
                //   'cnss',
                //   initialValues.cnss,
                //   'required|string',
                // )}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      number_employer: e.target.value,
                    },
                  });
                }}
              />

              <InputField
                width={sandwich_menu_btn ? '26vw' : '30vw'}
                type="text"
                placeholder="Code bureau régional"
                className="general_info_form_item"
                validInput="TRN"
                label="Code bureau régional"
                hasError={hasError}
                value={initialValues.office_code}
                maxLength={2}
                name="office_code"
                icon={<i className="fas fa-fingerprint"></i>}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      office_code: e.target.value.replace(/\D/g, ''),
                    },
                  });
                }}
              />
              <InputField
                width={sandwich_menu_btn ? '26vw' : '30vw'}
                className="general_info_form_item"
                type="text"
                placeholder="BANQUE"
                label="BANQUE"
                hasError={hasError}
                validInput="string"
                value={initialValues.bank}
                name="bank"
                icon={<i className="fas fa-money-check-alt"></i>}
                // required={this.validator.message(
                //   'bank',
                //   initialValues.bank,
                //   'required|string',
                // )}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      bank: e.target.value,
                    },
                  });
                }}
              />
              <InputField
                width={sandwich_menu_btn ? '26vw' : '30vw'}
                type="text"
                placeholder="Identification fiscale"
                className="general_info_form_item"
                validInput="TRN"
                label="Identification fiscale"
                hasError={hasError}
                value={initialValues.tax_registration_number}
                name="tax_registration_number"
                icon={<i className="fas fa-fingerprint"></i>}
                // required={this.validator.message(
                //   'tax_identification',
                //   initialValues.tax_registration_number,
                //   'required|string',
                // )}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      tax_registration_number: e.target.value,
                    },
                  });
                }}
              />
              <InputField
                width={sandwich_menu_btn ? '26vw' : '30vw'}
                className="general_info_form_item"
                type="text"
                placeholder="R.I.B"
                label="R.I.B"
                hasError={hasError}
                validInput="integer"
                value={initialValues.rib}
                name="rib"
                icon={<i className="fab fa-scribd"></i>}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      rib: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </form>
          <div className="footer_buttons" style={{ paddingBottom: '4%' }}>
            <button
              style={{
                marginRight: sandwich_menu_btn ? '6%' : '6%',
              }}
              disabled={isLoadingActionOffice}
              onClick={() => {
                this.submitForm(initialValues);
              }}
              className="footer_btn"
              id="update"
            >
              {isLoadingActionOffice ? (
                <CircularProgress
                  style={{
                    marginLeft: '4%',
                    width: '25px',
                    height: '25px',
                    color: 'white',
                    marginTop: '5%',
                  }}
                />
              ) : (
                'Valider'
              )}
            </button>
          </div>
        </>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CompanyManagement);
