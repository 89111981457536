import React, { useState, useEffect, Fragment } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';
import { Table } from './tablePdf/Table';
import { TableHeader } from './tablePdf/TableHeader';
import { TableCell } from './tablePdf/TableCell';
import { TableBody } from './tablePdf/TableBody';
import { DataTableCell } from './tablePdf/DataTableCell';
import moment from 'moment';
import { zeros } from '../../utils/constants';
import IconKine from '../../assets/img/logo-kine.png';
import { toast } from 'react-toastify';
import ToastAction from '../../components/ToastAction';

// Create styles
const newstyles = StyleSheet.create({
  page: {
    display: 'inline-grid',
    flexDirection: 'row',
    marginTop: 10,
  },
  PDFViewer: {
    width: '100%',
    height: '550px',
  },
  section: {
    marginLeft: 40,
    padding: 0,
  },
  sectionNameDoctor: {
    marginLeft: 10,
    marginRight: 40,
    padding: 10,
  },
  section2: {
    marginLeft: 10,
    padding: 10,
  },
  hr: {
    BorderTop: '1px bold red',
  },
  cabinet: {
    display: 'flex',
    marginTop: 30,
    flexDirection: 'row',
    marginLeft: 30,
    marginRight: 60,
  },
  sectionBill: {
    margin: 10,
    padding: 10,
    flexDirection: 'row',
  },
  detailBill: {
    margin: 10,
    padding: 10,
    flexDirection: 'row',
  },
  detailBillText: {
    color: '#070707',
    fontWeight: '1000',
    fontSize: 10,
    fontStyle: 'gras',
    //fontFamily: 'Georgia, serif',
    margin: 2,
  },
  sectionLogo: {
    marginLeft: 0,
    marginRight: 10,
    padding: 10,
  },
  detailBillText2: {
    color: '#070707',
    fontWeight: 'bolder',
    marginLeft: 10,
    padding: 10,
  },
  text: {
    color: '#5446db',
    fontWeight: 'bolder',
  },
  bill: {
    color: '#5446db',
    fontWeight: '500',
    marginLeft: '1%',
  },
  text1: {
    color: '#49494c',
    fontSize: 10,
    fontWeight: 'bolder',
    display: 'block',
    margin: 2,
    width: '150px',
  },
  images: {
    marginTop: 150,
    marginLeft: 20,
    // padding: 10,
    width: 50,
    height: 20,
  },
});
function SeancesPdf(props) {
  document.title = `Mémoire des séances de physiothérapie`;
  const sessions = props.location.state.sessions;
  //const pec = this.props.location.state.pec;
  const user = props.location.state.office;
  const consultation = props.location.state.consultation;
  if (
    consultation.patient_record.patient.cnam_root === '' ||
    consultation.patient_record.patient.cnam_key === '' ||
    consultation.prise_en_charges[0].quality === ''
  ) {
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="warning"
          message="Vérifier les informations
du patient."
        />
      ),
      {
        hideProgressBar: true,
      },
    );
    props.history.push({
      pathname: '/consultations',
      state: { consultation_id: consultation.id },
    });
  }
  const [i, setI] = useState(0);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  useEffect(() => {
    let i = 0;
    props.changeActiveItemSideBar(2);
    props.changeNamePage('Mémoire des séances de physiothérapie');
    sessions &&
      sessions.length > 0 &&
      sessions.map((session) => {
        if (session.status !== 'canceled') {
          setI(i + 1);
          i++;
          if (i < 17)
            data.push({
              num: `Séance n° ${i}`,
              date: session.date_from
                ? moment(session.date_from).format('DD/MM/YYYY')
                : moment(session.date).format('DD/MM/YYYY'),
              signature: '',
            });
          else if (i >= 17 && i < 41)
            data2.push({
              num: `Séance n° ${i}`,
              date: session.date_from
                ? moment(session.date_from).format('DD/MM/YYYY')
                : moment(session.date).format('DD/MM/YYYY'),
              signature: '',
            });
          else
            data3.push({
              num: `Séance n° ${i}`,
              date: session.date_from
                ? moment(session.date_from).format('DD/MM/YYYY')
                : moment(session.date).format('DD/MM/YYYY'),
              signature: '',
            });
        }
      });

    setData(data);
  }, [data]);

  return (
    <div>
      <div
        onClick={() =>
          props.history.push({
            pathname: `/consultations/${consultation.id}`,
            state: { consultation_id: consultation.id },
          })
        }
        className="bilan_details_header"
      >
        <div className="back_icon">
          <img src={require('../../assets/img/arrow1.png')} alt="" />
        </div>
        <span>Retour</span>
      </div>
      <Fragment>
        <PDFViewer style={newstyles.PDFViewer}>
          <Document
            orientation="landscape"
            onRender={({ pageNumber, totalPages }) => console.log(pageNumber)}
          >
            <Page>
              <View style={newstyles.cabinet}>
                <View style={newstyles.sectionNameDoctor}>
                  <Text
                    style={{ ...newstyles.detailBillText, display: 'block', width: 120 }}
                  >
                    Nom:{' '}
                    {user && user.name
                      ? user.name
                      : `${user.last_name} ${user.first_name}`}
                  </Text>
                  <Text style={newstyles.detailBillText}>
                    Code CNAM: {user ? user.conventional_code : '-'}{' '}
                  </Text>

                  <Text style={newstyles.detailBillText}>
                    N° Employeur: {user ? user.number_employer : '-'}{' '}
                  </Text>
                </View>
                <View style={newstyles.sectionLogo}>
                  <Image style={{ width: 60, height: 60 }} src={IconKine} />
                </View>
                <View style={newstyles.section}>
                  <Text style={newstyles.detailBillText}>
                    R.I.B: {user ? user.rib : '-'}
                  </Text>
                  <Text style={newstyles.detailBillText}>
                    BANQUE: {user ? user.bank : '-'}
                  </Text>
                  <Text style={newstyles.detailBillText}>
                    Identification fiscale: {user ? user.tax_registration_number : '-'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  borderBottomColor: 'black',
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                }}
              />
              <View
                style={{
                  marginLeft: 50,
                  marginRight: 60,
                  marginTop: 3,
                }}
              >
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 14,
                    fontWeight: '700',
                    display: 'block',
                    margin: 3,
                    textAlign: 'center',
                    fontStyle: 'gras',
                  }}
                >
                  Mémoire des séances de physiothérapie
                </Text>
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 17,
                    fontWeight: '700',
                    display: 'block',
                    margin: 3,
                    textAlign: 'center',
                    fontStyle: 'gras',
                  }}
                >
                  et de rééducation fonctionnelle
                </Text>
              </View>
              <View style={newstyles.cabinet}>
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 14,
                    fontWeight: '700',
                    display: 'block',
                    margin: 3,
                    textAlign: 'center',
                    fontStyle: 'gras',
                  }}
                >
                  Je sousigné(e){' '}
                  {consultation.patient_record && consultation.patient_record.patient
                    ? `${consultation.patient_record.patient.last_name} ${consultation.patient_record.patient.first_name}`
                    : '-'}{' '}
                  {consultation.patient_record &&
                  consultation.patient_record.patient &&
                  consultation.patient_record.patient.cnam_root
                    ? `(N° ${consultation.patient_record.patient.cnam_root}/${consultation.patient_record.patient.cnam_key})`
                    : ''}
                  déclare avoir pratiqué des séances de kinésithérapeute{' '}
                  {consultation.prise_en_charges &&
                  consultation.prise_en_charges.length > 0 &&
                  consultation.prise_en_charges[0].quality
                    ? `en qualité de  ${consultation.prise_en_charges[0].quality}`
                    : ''}{' '}
                  à raison de {consultation.prise_en_charges[0].sessions_per_week}{' '}
                  Séance(s) par semaine au près de {user.name} conformément au tableau
                  ci-dessous :
                </Text>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginRight: 40,
                  marginTop: 3,
                }}
              >
                <Table data={data}>
                  <TableHeader textAlign={'center'}>
                    <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                      N° séance
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                      Date
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                      Signature
                    </TableCell>
                  </TableHeader>
                  <TableBody>
                    <DataTableCell getContent={(r) => r.num} />
                    <DataTableCell getContent={(r) => r.date} />
                    <DataTableCell getContent={(r) => r.signature} />
                  </TableBody>
                </Table>
              </View>
              <View
                style={{
                  height: '40px',
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  width: '100%',
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                <View
                  style={{
                    borderBottomColor: 'black',
                    marginLeft: 50,
                    marginRight: 60,
                    //marginTop: 350,
                    borderBottomWidth: 1,
                  }}
                />
                <View>
                  <Text
                    style={{
                      top: 10,
                      color: '#070707',
                      fontWeight: '1000',
                      fontSize: 10,
                      fontStyle: 'gras',
                      //fontFamily: 'Georgia, serif',
                      margin: 3,
                    }}
                  >
                    {user && user.address_office
                      ? `Adresse: ${user.address_office.street}, ${user.address_office.zip_code} ${user.address_office.city} ${user.address_office.country}`
                      : '-'}
                    {' - '}
                    {user.phone ? `Tél/Fax: +216 ${user.phone} ` : '-'}
                  </Text>
                </View>
              </View>
            </Page>
            {data2 && data2.length > 0 ? (
              <Page>
                <View style={newstyles.cabinet}>
                  <View style={newstyles.sectionNameDoctor}>
                    <Text
                      style={{
                        ...newstyles.detailBillText,
                        display: 'block',
                        width: 120,
                      }}
                    >
                      Nom:{' '}
                      {user && user.name
                        ? user.name
                        : `${user.last_name} ${user.first_name}`}
                    </Text>
                    <Text style={newstyles.detailBillText}>
                      Code CNAM: {user ? user.conventional_code : '-'}{' '}
                    </Text>

                    <Text style={newstyles.detailBillText}>
                      N° Employeur: {user ? user.number_employer : '-'}{' '}
                    </Text>
                  </View>
                  <View style={newstyles.sectionLogo}>
                    <Image style={{ width: 60, height: 60 }} src={IconKine} />
                  </View>
                  <View style={newstyles.section}>
                    <Text style={newstyles.detailBillText}>
                      R.I.B: {user ? user.rib : '-'}
                    </Text>
                    <Text style={newstyles.detailBillText}>
                      BANQUE: {user ? user.bank : '-'}
                    </Text>
                    <Text style={newstyles.detailBillText}>
                      Identification fiscale: {user ? user.tax_registration_number : '-'}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    borderBottomColor: 'black',
                    marginLeft: 50,
                    marginRight: 60,
                    borderBottomWidth: 1,
                  }}
                />
                <View
                  style={{
                    marginLeft: 30,
                    marginRight: 40,
                    marginTop: 3,
                  }}
                >
                  <Table data={data2}>
                    <TableHeader textAlign={'center'}>
                      <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        N° séance
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        Date
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        Signature
                      </TableCell>
                    </TableHeader>
                    <TableBody>
                      <DataTableCell getContent={(r) => r.num} />
                      <DataTableCell getContent={(r) => r.date} />
                      <DataTableCell getContent={(r) => r.signature} />
                    </TableBody>
                  </Table>
                </View>
                <View
                  style={{
                    height: '40px',
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    color: 'white',
                    textAlign: 'center',
                  }}
                >
                  <View
                    style={{
                      borderBottomColor: 'black',
                      marginLeft: 50,
                      marginRight: 60,
                      //marginTop: 350,
                      borderBottomWidth: 1,
                    }}
                  />
                  <View>
                    <Text
                      style={{
                        top: 10,
                        color: '#070707',
                        fontWeight: '1000',
                        fontSize: 10,
                        fontStyle: 'gras',
                        //fontFamily: 'Georgia, serif',
                        margin: 3,
                      }}
                    >
                      {user && user.address_office
                        ? `Adresse: ${user.address_office.street}, ${user.address_office.zip_code} ${user.address_office.city} ${user.address_office.country}`
                        : '-'}
                      {user.phone ? `Tél/Fax: +216 ${user.phone} ` : '-'}
                    </Text>
                  </View>
                </View>
              </Page>
            ) : undefined}
            {data3 && data3.length > 0 ? (
              <Page>
                <View style={newstyles.cabinet}>
                  <View style={newstyles.sectionNameDoctor}>
                    <Text
                      style={{
                        ...newstyles.detailBillText,
                        display: 'block',
                        width: 120,
                      }}
                    >
                      Nom:{' '}
                      {user && user.name
                        ? user.name
                        : `${user.last_name} ${user.first_name}`}
                    </Text>
                    <Text style={newstyles.detailBillText}>
                      Code CNAM: {user ? user.conventional_code : '-'}{' '}
                    </Text>

                    <Text style={newstyles.detailBillText}>
                      N° Employeur: {user ? user.number_employer : '-'}{' '}
                    </Text>
                  </View>
                  <View style={newstyles.sectionLogo}>
                    <Image style={{ width: 60, height: 60 }} src={IconKine} />
                  </View>
                  <View style={newstyles.section}>
                    <Text style={newstyles.detailBillText}>
                      R.I.B: {user ? user.rib : '-'}
                    </Text>
                    <Text style={newstyles.detailBillText}>
                      BANQUE: {user ? user.bank : '-'}
                    </Text>
                    <Text style={newstyles.detailBillText}>
                      Identification fiscale: {user ? user.tax_registration_number : '-'}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    borderBottomColor: 'black',
                    marginLeft: 50,
                    marginRight: 60,
                    borderBottomWidth: 1,
                  }}
                />
                <View
                  style={{
                    marginLeft: 30,
                    marginRight: 40,
                    marginTop: 3,
                  }}
                >
                  <Table data={data3}>
                    <TableHeader textAlign={'center'}>
                      <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        N° séance
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        Date
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        Signature
                      </TableCell>
                    </TableHeader>
                    <TableBody>
                      <DataTableCell getContent={(r) => r.num} />
                      <DataTableCell getContent={(r) => r.date} />
                      <DataTableCell getContent={(r) => r.signature} />
                    </TableBody>
                  </Table>
                </View>
                <View
                  style={{
                    height: '40px',
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    color: 'white',
                    textAlign: 'center',
                  }}
                >
                  <View
                    style={{
                      borderBottomColor: 'black',
                      marginLeft: 50,
                      marginRight: 60,
                      //marginTop: 350,
                      borderBottomWidth: 1,
                    }}
                  />
                  <View>
                    <Text
                      style={{
                        top: 10,
                        color: '#070707',
                        fontWeight: '1000',
                        fontSize: 10,
                        fontStyle: 'gras',
                        //fontFamily: 'Georgia, serif',
                        margin: 3,
                      }}
                    >
                      {user && user.address_office
                        ? `Adresse: ${user.address_office.street}, ${user.address_office.zip_code} ${user.address_office.city} ${user.address_office.country}`
                        : '-'}
                      {user.phone ? `Tél/Fax: +216 ${user.phone} ` : '-'}
                    </Text>
                  </View>
                </View>
              </Page>
            ) : undefined}
          </Document>
        </PDFViewer>
      </Fragment>
    </div>
  );
}

export default SeancesPdf;
