import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import actions from '../../../js/actions';
import actionsResetPassword from '../resetPassword/store/actions';
import SimpleReactValidator from 'simple-react-validator';
import { Link } from 'react-router-dom';
import '../index.css';
import InputField from '../../../components/inputFieldV2';
import CircularProgress from '@material-ui/core/CircularProgress';

const mapStateToProps = (state) => ({
  isLoadingLogin: state.AppReducer.isLoadingLogin,
  isAuthorized: state.AppReducer.isAuthorized,
  user: state.AppReducer.user,
  authLogin: state.AppReducer.authLogin,
  message: state.AppReducer.message,
  error: state.AppReducer.error,
});

const mapDispatchToProps = (dispatch) => ({
  login: (values) => dispatch(actions.login(values)),
  initialState: () => dispatch(actionsResetPassword.initialState()),
});

class Login extends PureComponent {
  constructor(props) {
    super(props);
    props.initialState();
    document.title = 'Authentification | login';
    this.state = {
      hasError: false,
      showPassword: false,
      checkedBtnRadio: false,
      initialState: {
        email: '',
        password: '',
      },
    };
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail n’est pas valide',
        min: 'Impossible de saisir une valeur inférieur à 6',
        max: 'Impossible de saisir une valeur supérieure à 20',
        // OR
        default: 'Champ requise.', // will override all messages
      },
    });
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.keyPressed = this.keyPressed.bind(this);
    this.handleShowPassword = this.handleShowPassword.bind(this);
  }

  handleClickShowPassword = () => {
    const { initialState } = this.state;
    this.setState({
      initialState: {
        ...initialState,
        showPassword: !initialState.showPassword,
      },
      hasError: false,
    });
  };

  submitForm(initialState) {
    if (this.validator.allValid()) {
      this.props.login(initialState);
    } else {
      this.setState({
        ...initialState,
        hasError: true,
      });
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  keyPressed(event) {
    if (event.key === 'Enter') {
      const { initialState, hasError } = this.state;
      if (!initialState.email || !initialState.password)
        this.setState({
          hasError: !hasError,
        });
      if (initialState.email && initialState.password) {
        this.submitForm(initialState);
      }
    }
  }

  checkedBtnRadio = () => {
    this.setState({
      checkedBtnRadio: !this.state.checkedBtnRadio,
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.authLogin) window.location.href = '/';
  }

  componentDidMount() {
    var backspaceEvnt = new KeyboardEvent('keydown');
    document.dispatchEvent(backspaceEvnt);
  }
  handleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }
  render() {
    const { initialState, checkedBtnRadio, hasError, showPassword } = this.state;
    const { isLoadingLogin } = this.props;
    return (
      <div className="authentication_view">
        <div className="view_header">
          <span>Bienvenue à Cuida</span>
          <span>Connectez-vous</span>
        </div>
        <p id="header_subtext">Entrez vos coordonnées pour continuer</p>
        <form className="sign_in_form">
          <InputField
            width={'60%'}
            className="view_item"
            type="text"
            placeholder="Adresse e-mail"
            label=""
            hasError={hasError}
            value={initialState.email}
            onKeyPress={this.keyPressed}
            name="email"
            icon={<i className="fas fa-envelope"></i>}
            /* required={this.validator.message(
              'email',
              initialState.email,
              'required|email',
            )}*/
            onChange={(e) => {
              this.setState({
                initialState: {
                  ...initialState,
                  email: e.target.value,
                },
              });
            }}
          />
          <InputField
            width={'60%'}
            className="view_item"
            type={showPassword ? 'text' : 'password'}
            placeholder="Mot de passe"
            label=""
            hasError={hasError}
            value={initialState.password}
            name="password"
            onKeyPress={this.keyPressed}
            icon={<i onClick={this.handleShowPassword} className="fas fa-lock"></i>}
            required={this.validator.message(
              'password',
              initialState.password,
              'required|min:3|max:20',
            )}
            onChange={(e) => {
              this.setState({
                initialState: {
                  ...initialState,
                  password: e.target.value,
                },
              });
            }}
          />
          <div className="view_item additional_btns">
            {/*<div className="remember_me" onClick={() => this.checkedBtnRadio()}>
              <div className={checkedBtnRadio ? 'checkbox-btn checked' : 'checkbox-btn'}>
                {checkedBtnRadio ? <div className="selection_opt"></div> : ''}
              </div>
              <label id="remember_me_checkbox">Se souvenir de moi</label>
            </div>*/}
          </div>
          <div className="view_item">
            <button
              onClick={() => {
                this.submitForm(initialState);
              }}
              id="connect_btn"
              type="button"
            >
              {isLoadingLogin ? (
                <CircularProgress
                  style={{
                    marginRight: '45%',
                    width: '30px',
                    height: '30px',
                    color: 'white',
                  }}
                />
              ) : (
                'Se connecter'
              )}
            </button>
            <div
              style={{ marginTop: '2%', textAlign: 'end' }}
              className="recover_password"
            >
              <Link to="/reset/password">
                <span className="recover_password">Mot de passe oublié?</span>
              </Link>
            </div>
            <Link style={{ display: 'flex', justifyContent: 'center' }} to="/register">
              <button
                style={{
                  backgroundColor: '#368821',
                  color: 'rgb(255, 255, 255)',
                  width: '40%',
                }}
                type="button"
                id="create_account_btn"
              >
                Créer un compte
              </button>
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
