import React, { Component } from 'react';
import { SelectorBilanItemSection, SelectorColorInputCheckbox } from '../../../../utils';
import UploadFile from '../../upload_file';
class MobilitySynthetique extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEntretien: true,
      showDouleur: false,
      showBilanArticulaire: false,
      showScore: false,
    };
    this.handleChangeSubSectionBtn = this.handleChangeSubSectionBtn.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    SelectorColorInputCheckbox();
    SelectorBilanItemSection();
  }
  handleChangeSubSectionBtn(name) {
    this.setState({
      [name]: !this.state[name],
    });
  }
  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }
  render() {
    const { sandwich_menu_btn, initialValues } = this.props;
    return (
      <div className="actual_bilan_settings">
        <div className="actual_bilan_settings_item head_shaking_test" id="headShaking1">
          <div
            style={{ backgroundColor: '#6c89bc' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}> Mobilités Synthétiques</span>
          </div>
          <br />
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Mobilité coude
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="Aucune"
                              onChange={this.props.handleChangeInput}
                              name="check_mobility_synthetique_1"
                              checked={
                                initialValues.check_mobility_synthetique_1 === 'Aucune'
                                  ? true
                                  : false
                              }
                              value="Aucune"
                            />
                            <label className="form-check-label" htmlFor={`Aucune`}>
                              Aucune
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_mobility_synthetique_1"
                              checked={
                                initialValues.check_mobility_synthetique_1 ===
                                'Trés limitée'
                                  ? true
                                  : false
                              }
                              value="Trés limitée"
                              id={`limitee`}
                            />
                            <label className="form-check-label" htmlFor={`limitee`}>
                              Trés limitée
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_mobility_synthetique_1"
                              checked={
                                initialValues.check_mobility_synthetique_1 === 'Limitée'
                                  ? true
                                  : false
                              }
                              value="Limitée"
                              className="form-check-input"
                              type="radio"
                              id={`Limite`}
                            />
                            <label className="form-check-label" htmlFor={`Limite`}>
                              Limitée
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_mobility_synthetique_1"
                              checked={
                                initialValues.check_mobility_synthetique_1 === 'Normale'
                                  ? true
                                  : false
                              }
                              value="Normale"
                              className="form-check-input"
                              type="radio"
                              id={`Normale`}
                            />
                            <label className="form-check-label" htmlFor={`Normale`}>
                              Normale
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Mobilité membre sup
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="Aucune1"
                              onChange={this.props.handleChangeInput}
                              name="check_mobility_synthetique_2"
                              checked={
                                initialValues.check_mobility_synthetique_2 === 'Aucune'
                                  ? true
                                  : false
                              }
                              value="Aucune"
                            />
                            <label className="form-check-label" htmlFor={`Aucune1`}>
                              Aucune
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id={`limitee1`}
                              onChange={this.props.handleChangeInput}
                              name="check_mobility_synthetique_2"
                              checked={
                                initialValues.check_mobility_synthetique_2 ===
                                'Trés limitée'
                                  ? true
                                  : false
                              }
                              value="Trés limitée"
                            />
                            <label className="form-check-label" htmlFor={`limitee1`}>
                              Trés limitée
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_mobility_synthetique_2"
                              checked={
                                initialValues.check_mobility_synthetique_2 === 'Limitée'
                                  ? true
                                  : false
                              }
                              value="Limitée"
                              className="form-check-input"
                              type="radio"
                              id={`Limite1`}
                            />
                            <label className="form-check-label" htmlFor={`Limite1`}>
                              Limitée
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_mobility_synthetique_2"
                              checked={
                                initialValues.check_mobility_synthetique_2 === 'Normale'
                                  ? true
                                  : false
                              }
                              value="Normale"
                              className="form-check-input"
                              type="radio"
                              id={`Normale1`}
                            />
                            <label className="form-check-label" htmlFor={`Normale1`}>
                              Normale
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
              className="actual_bilan_settings_item_body "
              id="headShaking1_sub_section"
              style={{ display: 'flex', width: '100%' }}
          >
            <div
                className="bilan_item"
                style={{
                  width: '50%',

                  backgroundColor: '#f3f4f9',
                }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                      style={{
                        alignItems: 'start',
                      }}
                      className="bilan_contact_info"
                  >
                    <span
                        style={{
                          marginLeft: '3%',
                        }}
                        id="name"
                    >
                      Remarques
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="additional_container_item_field">
                        <textarea
                            onChange={this.props.handleChangeInput}
                            maxLength="500"
                            style={{ width: '60%' }}
                            value={initialValues.notes111_11}
                            name="notes111_11"
                            placeholder="Remarques "
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MobilitySynthetique;
