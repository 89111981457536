import React, { Component } from 'react';
import './index.css';
import actions from './store/actions';
import { connect } from 'react-redux';
import { SelectorContactFacturesBodyItem } from '../../utils';
import actionsJs from '../../js/actions';
import actionsBill from '../facture/store/actions';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import PaidIcon from '@mui/icons-material/Paid';
import DeleteIcon from '@mui/icons-material/Delete';
import Modals from '../../components/modal';
import { Link } from 'react-router-dom';
import moment from 'moment';
import BordereauPdf from './bordereau';

const mapStateToProps = (state) => ({
  billsByIds: state.BordereauxReducer.billsByIds,
  addBordereau: state.BordereauxReducer.addBordereau,

  user: state.AppReducer.user,
  bordereau: state.BordereauxReducer.bordereau,
  bordereaux: state.BordereauxReducer.bordereaux,
  isLoadingBordereaux: state.BordereauxReducer.isLoadingBordereaux,
  isLoadingBordereau: state.BordereauxReducer.isLoadingBordereau,
  params: state.BordereauxReducer.params,
  office: state.AppReducer.office,
  bordereauDeleted: state.BordereauxReducer.bordereauDeleted,
});

const mapDispatchToProps = (dispatch) => ({
  getOfficeByIdDoctor: () => dispatch(actionsJs.getOfficeByIdDoctor()),
  getBordereauById: (id) => dispatch(actions.getBordereauById(id)),
  getAllBordereaux: (params, regime) =>
    dispatch(actions.getAllBordereaux(params, regime)),
  deleteBordereauBill: async (values, params, regime) => {
    await dispatch(actions.deleteBordereauBill(values));
    dispatch(actions.getBordereauById(values.bordereau_id));
    dispatch(actions.getAllBordereaux(params, regime));
  },
  getBillByIds: (ids) => dispatch(actions.getBillByIds(ids)),
  submitBordereau: async (params, regime, value) => {
    await dispatch(actions.submitBordereau(value));
    dispatch(actions.getAllBordereaux(params, regime));
  },
  submitUpdateBillForm: async (values, params, regime) => {
    await dispatch(actionsBill.submitUpdateBillForm(values));
    dispatch(actions.getAllBordereaux(params, regime));
  },
  changeSearched: async (params, regime) => {
    await dispatch(actions.changeSearchedStringBordereau(params));
    dispatch(actions.getAllBordereaux(params, regime));
  },
  changeLimit: async (params, regime) => {
    await dispatch(actions.changeSearchedStringBordereau(params));
    dispatch(actions.getAllBordereaux(params, regime));
  },
  changeSearchedStringLimit: async (params, regime) => {
    await dispatch(actions.changeSearchedStringBordereau(params));
    dispatch(actions.getAllBordereaux(params, regime));
  },
  changeSearchedStatus: async (params, regime) => {
    await dispatch(actions.changeSearchedStringBordereau(params));
    dispatch(actions.getAllBordereaux(params, regime));
  },
  changeActiveItemSideBarSettings: (item, data, create) =>
    dispatch(actionsJs.changeActiveItemSideBarSettings(item, data, create)),
  changeTag: (tag) => dispatch(actionsJs.changeTag(tag)),
  changeActiveItemSideBarCompta: (tag) =>
    dispatch(actionsJs.changeActiveItemSideBarCompta(tag)),

  submitDeleteBordereau: async (id, params, regime) => {
    await dispatch(actions.submitDeleteBordereau(id));
    dispatch(actions.getAllBordereaux(params, regime));
  },
});

function createData(number, date, total, status, actions) {
  return {
    number,
    date,
    total,
    status,
    actions,
    bills: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row, openModalDelete, office, user, type, openModalStatus } = props;
  const [open, setOpen] = React.useState(false);
  console.log(row.bills);
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.number}</TableCell>
        <TableCell align="right">{moment(row.date).format('YYYY-MM-DD')}</TableCell>
        <TableCell align="right">{row.total_ttc}</TableCell>
        <TableCell align="right">{row.status}</TableCell>
        <TableCell align="right">
          <div>
            {type === 'cnam' || type === 'act' ? (
              <Link
                to="#"
                onClick={() => {
                  BordereauPdf(row, row.bills, office);
                }}
              >
                <DownloadForOfflineIcon
                  fontSize="large"
                  color="success"
                  sx={{ marginInline: '0.1vw' }}
                />
              </Link>
            ) : (
              ''
            )}

            <Link
              to={{
                pathname:
                  type === 'autre'
                    ? '/facture/bordereau-pdf/autre'
                    : '/facture/bordereau-pdf',
                state: {
                  office: office,
                  type: type,
                  billsByIds: row.bills,
                  addBordereau: row,
                  doctor: {
                    cnss: user.cnss,
                    name_cabinet: user.name_cabinet,
                    speciality: user.speciality ? user.speciality.name : null,
                    conventional_code: user.conventional_code,
                    tax_identification: user.tax_identification,
                    rib: user.rib,
                    bank: user.bank,
                  },
                },
              }}
            >
              <LocalPrintshopIcon
                fontSize="large"
                color="info"
                sx={{ marginInline: '0.1vw' }}
              />
            </Link>
            {row.status === 'invoiced' && type !== 'autre' ? (
              <PaidIcon
                style={{ cursor: 'pointer' }}
                onClick={() => openModalStatus(row.id)}
                fontSize="large"
                color="primary"
                sx={{ marginInline: '0.1vw' }}
              />
            ) : (
              ''
            )}
            {row.status !== 'payed' && (
              <DeleteIcon
                style={{ cursor: 'pointer' }}
                onClick={() => openModalDelete(row.id)}
                fontSize="large"
                color="error"
                sx={{ marginInline: '0.1vw' }}
              />
            )}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Liste des factures
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Customer</TableCell>
                    {/* <TableCell align="right">Amount</TableCell>*/}
                    <TableCell align="right">Total price ($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.bills.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {moment(historyRow.date).format('YYYY-MM-DD')}
                      </TableCell>
u                      <TableCell>{`${historyRow.patient_record.patient.last_name} ${historyRow.patient_record.patient.first_name}`}</TableCell>
                      <TableCell align="right">{historyRow.price_ttc}</TableCell>
                      {/* <TableCell align="right">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </TableCell>*/}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const rows = [
  createData('01/2023', '23/01/2023', 6234.5, 'Facturé', 'actions'),
  createData('02/2023', '23/01/2023', 6234.5, 'Facturé', 'actions'),
  createData('03/2023', '23/01/2023', 6234.5, 'Facturé', 'actions'),
  createData('04/2023', '23/01/2023', 6234.5, 'Facturé', 'actions'),
];

class Bordereaux extends Component {
  constructor(props) {
    super(props);
    props.getOfficeByIdDoctor();
    props.changeActiveItemSideBarCompta(150);
    props.getAllBordereaux(props.params, props.type);
    props.changeTag(props.type || 'autre');
    props.changeNamePage(
      props.type === 'cnam'
        ? 'Bordereaux CNAM'
        : props.type === 'act'
        ? 'Bordereaux ACT'
        : 'Bordereaux',
    );
    document.title =
      props.type === 'cnam'
        ? 'Bordereaux CNAM'
        : props.type === 'act'
        ? 'Bordereaux ACT'
        : 'Bordereaux';
    this.state = {
      errorDate: false,
      setIsOpen: false,
      selectedBill: false,
      selectedBordereau: null,
      bordereau: null,
      setIsOpenModalStatus: false,
      setIsOpenDelete: false,
      selectedBillIds: [],
      payment_date: moment().format('YYYY-MM-DD'),
    };
    this.handleClickInputCheckbox = this.handleClickInputCheckbox.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openModalDelete = this.openModalDelete.bind(this);
    this.openModalStatus = this.openModalStatus.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }
  openModalStatus(id) {
    this.setState({
      setIsOpenModalStatus: !this.state.setIsOpenModalStatus,
      selectedBordereau: id || null,
    });
  }
  componentDidMount() {
    SelectorContactFacturesBodyItem();
    this.props.changeSearched(
      {
        limit: 10,
        currentPage: 1,
        search: '',
        status: '',
      },
      this.props.type,
    );
  }
  openModal() {
    this.setState({
      setIsOpen: !this.state.setIsOpen,
    });
  }
  handleSelectedBill(selectedBill) {
    this.setState({
      selectedBill: !selectedBill,
    });
  }
  handleClickInputCheckbox(event, id) {
    const value = event.target.checked;
    let selectedBillIds = this.state.selectedBillIds;
    if (value) {
      selectedBillIds = [...selectedBillIds, id];
    } else {
      selectedBillIds = selectedBillIds.filter((item) => item !== id);
    }
    this.setState({
      selectedBillIds: selectedBillIds,
    });
  }
  openModalDelete(id) {
    this.setState({
      setIsOpenDelete: !this.state.setIsOpenDelete,
      selectedBordereau: id || null,
    });
  }
  handleChangePage(event, newPage) {
    console.log(newPage);
    this.props.changeLimit(
      { ...this.props.params, currentPage: newPage + 1 },
      this.props.type,
    );
    //setPage(newPage);
  }

  handleChangeRowsPerPage(event) {
    this.props.changeLimit(
      { ...this.props.params, currentPage: 1, limit: parseInt(event.target.value, 10) },
      this.props.type,
    );
    /*setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);*/
  }

  render() {
    const {
      params,
      bordereaux,
      bordereau,
      isLoadingBordereaux,
      isLoadingBordereau,
      user,
      office,
      type,
      bordereauDeleted,
    } = this.props;
    console.log(bordereaux);
    const { setIsOpenDelete, selectedBordereau, setIsOpenModalStatus, payment_date } =
      this.state;
    const { data } = bordereaux;
    return (
      <>
        <div className="facture_patient_header">
          <p>Rechercher un bordereau</p>
          <div className="facture_patient_controls">
            <input
              style={{ width: '50%', marginLeft: '-5%' }}
              onChange={(event) => {
                this.props.changeSearched(
                  {
                    ...params,
                    search: event.target.value ? event.target.value : '',
                  },
                  type,
                );
              }}
              type="text"
              placeholder="Numéro bordereau"
            />
            <select
              style={{ width: '50%', margin: '0px 20px' }}
              id="inputState"
              name="status"
              value={params.status}
              onChange={(event, option) => {
                this.props.changeSearchedStatus(
                  {
                    ...params,
                    status: event.target.value !== 'Statut' ? event.target.value : null,
                  },
                  type,
                );
              }}
            >
              <option value={null}>Statut</option>
              <option value="invoiced">Facturé </option>
              <option value="payed">Payé </option>
            </select>
          </div>
        </div>
        <TableContainer
          component={Paper}
          sx={{
            width: '100% !important',
            height: 'auto !important',
          }}
        >
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell sx={{ fontWeight: 'bold' }}>Numéro bordereau</TableCell>
                <TableCell align="right">Date</TableCell>
                <TableCell align="right">Total TTC</TableCell>
                <TableCell align="right">Statut</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bordereaux &&
                bordereaux.data &&
                bordereaux.data.length > 0 &&
                bordereaux.data.map((row) => (
                  <Row
                    key={row.name}
                    type={type}
                    office={office}
                    user={user}
                    row={row}
                    openModalDelete={this.openModalDelete}
                    openModalStatus={this.openModalStatus}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {bordereaux && bordereaux.total > 3 && (
          <TablePagination
            rowsPerPageOptions={[3, 5, 10, 25]}
            component="div"
            count={bordereaux.total}
            rowsPerPage={bordereaux.per_page}
            page={bordereaux.current_page - 1}
            onPageChange={this.handleChangePage}
            labelRowsPerPage="Lignes par page :"
            // labelDisplayedRows="00000000"
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        )}

        <Modals
          setIsOpen={setIsOpenModalStatus}
          openModal={this.openModalStatus}
          data={
            <>
              {' '}
              <div className="modal-header" style={{ marginTop: '0' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModalStatus()}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '10px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Confirmation!</h2>
                <span>
                  Voulez-vous vraiment marquer ce bordereau comme payé par la CNAM ?
                </span>
                <div className="modal_body_recap_details_item">
                  <div className="modal_body_recap_details_item_left">
                    <span>Date de paiment</span>
                  </div>
                  <div className="modal_body_recap_details_item_right">
                    <input
                      value={payment_date}
                      onChange={(e) =>
                        this.setState({
                          payment_date: e.target.value,
                        })
                      }
                      name="payment_date"
                      className="form-control"
                      type="date"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  style={{ backgroundColor: '#f6f6f6' }}
                  onClick={() => this.openModalStatus()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                >
                  Non
                </button>
                <button
                  onClick={() => {
                    this.props.submitBordereau(params, type, {
                      id: selectedBordereau,
                      status: 'payed',
                      payment_date: payment_date,
                      type,
                    });
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
        <Modals
          setIsOpen={setIsOpenDelete}
          openModal={this.openModalDelete}
          data={
            <>
              {' '}
              <div className="modal-header" style={{ marginTop: '0' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModalDelete(null)}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '10px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Suppression du bordereau !</h2>
                <span>Voulez-vous vraiment supprimer ce bordereau ?</span>
              </div>
              <div className="modal-footer">
                <button
                  style={{ backgroundColor: '#f6f6f6' }}
                  onClick={() => this.openModalDelete()}
                  type="button"
                  className="modal_close_btn"
                >
                  Non
                </button>
                <button
                  onClick={() => {
                    this.props.submitDeleteBordereau(selectedBordereau, params, 'cnam');
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
      </>

      // <div>
      //   <div style={{ width: '100% !important' }}>
      //     <div className="facture_patient_header">
      //       <p>Rechercher un bordereau</p>
      //       <div className="facture_patient_controls">
      //         <input
      //           style={{ width: '50%', marginLeft: '-5%' }}
      //           onChange={(event) => {
      //             this.props.changeSearched(
      //               {
      //                 ...params,
      //                 search: event.target.value ? event.target.value : '',
      //               },
      //               type,
      //             );
      //           }}
      //           type="text"
      //           placeholder="Numéro bordereau"
      //         />
      //         <select
      //           style={{ width: '50%', margin: '0px 20px' }}
      //           id="inputState"
      //           name="status"
      //           value={params.status}
      //           onChange={(event, option) => {
      //             this.props.changeSearchedStatus(
      //               {
      //                 ...params,
      //                 status: event.target.value !== 'Statut' ? event.target.value : null,
      //               },
      //               type,
      //             );
      //           }}
      //         >
      //           <option value={null}>Statut</option>
      //           <option value="invoiced">Facturé </option>
      //           <option value="payed">Payé </option>
      //         </select>
      //       </div>
      //     </div>
      //     {isLoadingBordereaux ? (
      //       <CircularProgress
      //         disableShrink
      //         style={{ marginLeft: '47%', marginTop: '30%' }}
      //       />
      //     ) : data && data.length > 0 ? (
      //       <>
      //         <div className="facture_patients_body" style={{ height: 'auto' }}>
      //           {data.map((item) => {
      //             return (
      //               <Item_bill
      //                 key={item.id}
      //                 getBordereauById={this.props.getBordereauById}
      //                 handleClickInputCheckbox={this.handleClickInputCheckbox}
      //                 selectedBill={selectedBill}
      //                 item={item}
      //                 id={data && data.length > 0 && data[0].id}
      //               />
      //             );
      //           })}
      //         </div>
      //       </>
      //     ) : (
      //       ''
      //     )}
      //   </div>

      //   {/* <div className="facture_info_section">
      //       {data && data.length === 0 ? (
      //         <div className="container_changeable_content">
      //           <div className="fatcure_detailed_info_section">
      //             <div
      //               style={{ marginTop: '10%' }}
      //               className="empty_consultation_result_container"
      //             >
      //               <div className="patient_detailed_info_section_body">
      //                 <div className="empty_consultation_result">
      //                   <img
      //                     src={require('../../assets/img/undraw_design_team_af2y.svg')}
      //                   />
      //                   <span id="no_consultation">Pas de Bordereaux !</span>
      //                   <span id="no_consultation_desc">
      //                     Générez un nouveau bordereau à partir d'une sélection de vos
      //                     factures. Le cas échéant, commencer par créer des cures et des
      //                     factures.
      //                   </span>
      //                 </div>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //       ) : isLoadingBordereau ? (
      //         <CircularProgress
      //           disableShrink
      //           style={{ marginLeft: '47%', marginTop: '30%' }}
      //         />
      //       ) : data && data.length === 0 && !isLoadingBordereau ? (
      //         <div className="container_changeable_content">
      //           <div className="fatcure_detailed_info_section">
      //             <div
      //               style={{ marginTop: '10%' }}
      //               className="empty_consultation_result_container"
      //             >
      //               <div className="patient_detailed_info_section_body">
      //                 <div className="empty_consultation_result">
      //                   <img
      //                     src={require('../../assets/img/undraw_design_team_af2y.svg')}
      //                   />
      //                   <span id="no_consultation">Pas de Bordereaux !</span>
      //                   <span id="no_consultation_desc">
      //                     Générez un nouveau bordereau à partir d'une sélection de vos
      //                     factures. Le cas échéant, commencer par créer des cures et des
      //                     factures.
      //                   </span>
      //                 </div>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //       ) : (
      //         <Bordereau
      //           office={office}
      //           user={user}
      //           type={type}
      //           bordereau={bordereau}
      //           params={params}
      //           isLoadingBordereau={isLoadingBordereau}
      //           deleteBordereauBill={this.props.deleteBordereauBill}
      //           submitBordereau={this.props.submitBordereau}
      //           submitUpdateBillForm={this.props.submitUpdateBillForm}
      //           submitDeleteBordereau={this.props.submitDeleteBordereau}
      //           bordereauDeleted={bordereauDeleted}
      //         />
      //       )}
      //     </div> */}
      // </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bordereaux);
