import React, { Component } from 'react';
import InputField from '../../../components/inputField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  country,
  host,
  ModePaiement,
  sortCities,
  typeContract,
} from '../../../utils/constants';
import TextField from '@material-ui/core/TextField';
import Modals from '../../../components/modal';
import actionsJs from '../../../js/actions';
import { connect } from 'react-redux';
import { isNumeric, lengthPhone, validate } from '../../../utils/helpres';
import moment from 'moment';

const mapStateToProps = (state, ownProps) => {
  const { secretary } = ownProps;
  let first_name;
  let last_name;
  let email;
  let country;
  let city;
  let street;
  let zip_code;
  let login;
  let photo;
  let date_of_birth;
  let gender;
  let hiring_date = moment().format('YYYY-MM-DD');
  let departure_date;
  let type_of_contract;
  let contract_length;
  let hour_from;
  let hour_to;
  let iban;
  let bank;
  let payment_date;
  let payment_method;
  let salary;
  let street_number;
  let gsm;
  let cv;
  let id;
  let access_status;
  let cnss;
  let phone;
  if (secretary) {
    id = secretary && secretary.id ? secretary.id : null;
    cnss = secretary && secretary.cnss ? secretary.cnss : '';
    street_number = secretary && secretary.street_number ? secretary.street_number : '';
    bank = secretary && secretary.bank ? secretary.bank : '';
    date_of_birth =
      secretary && secretary.date_of_birth
        ? moment(secretary.date_of_birth).format('YYYY-MM-DD')
        : '';
    salary = secretary && secretary.salary ? secretary.salary : '';
    payment_date = secretary && secretary.payment_date ? secretary.payment_date : '';
    payment_method =
      secretary && secretary.payment_method ? secretary.payment_method : '';
    photo = secretary && secretary.photo ? secretary.photo : '';
    iban = secretary && secretary.iban ? secretary.iban : '';
    cv = secretary && secretary.cv ? secretary.cv : '';
    hiring_date =
      secretary && secretary.hiring_date
        ? moment(secretary.hiring_date).format('YYYY-MM-DD')
        : '';
    departure_date =
      secretary && secretary.departure_date
        ? moment(secretary.departure_date).format('YYYY-MM-DD')
        : '';
    type_of_contract =
      secretary && secretary.type_of_contract ? secretary.type_of_contract : '';
    contract_length =
      secretary && secretary.contract_length ? secretary.contract_length : '';
    hour_from = secretary && secretary.hour_from ? secretary.hour_from : '';
    hour_to = secretary && secretary.hour_to ? secretary.hour_to : '';
    gsm = secretary && secretary.gsm ? secretary.gsm : '';
    phone = secretary && secretary.phone ? secretary.phone : '';
    first_name = secretary && secretary.first_name ? secretary.first_name : '';
    last_name = secretary && secretary.last_name ? secretary.last_name : '';
    email = secretary && secretary.email ? secretary.email : '';
    country = secretary && secretary.country ? secretary.country : '';
    city = secretary && secretary.city ? secretary.city : '';
    street = secretary && secretary.street ? secretary.street : '';
    zip_code = secretary && secretary.zip_code ? secretary.zip_code : '';
    login = secretary && secretary.login_access ? secretary.login_access.login : '';
    access_status =
      secretary && secretary.login_access ? secretary.login_access.access_status : '';
  }
  return {
    isLoadingSecretary: state.AppReducer.isLoadingSecretary,
    actionSecretary: state.AppReducer.actionSecretary,
    initialValues: {
      id,
      first_name,
      login,
      last_name,
      email,
      country,
      cnss,
      city,
      street,
      zip_code,
      photo,
      date_of_birth,
      gender,
      hiring_date: hiring_date || moment().format('YYYY-MM-DD'),
      departure_date,
      type_of_contract,
      phone,
      contract_length,
      hour_from,
      hour_to,
      iban,
      bank,
      payment_date,
      payment_method,
      salary,
      gsm,
      street_number,
      cv,
      access_status,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  submitSecretaryForm: async (values, type) => {
    await dispatch(actionsJs.submitSecretaryForm(values, type));
    dispatch(actionsJs.getSecretaries());
  },
  ActivitedDeactivetedAccount: async (values) => {
    await dispatch(actionsJs.ActivitedDeactivetedAccount(values));
    dispatch(actionsJs.getSecretaries());
  },
});

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setIsOpen: false,
      setIsOpenAcces: false,
      active: false,
      hasError: false,
      hasErrorDate: false,
      setIsOpenActif: false,
      activeAcces: false,
      updatePassword: false,
      initialValues: {
        ...props.initialValues,
        file: null,
      },
    };
    this.openModal = this.openModal.bind(this);
    this.openModalAcces = this.openModalAcces.bind(this);
    this.openModalActif = this.openModalActif.bind(this);
  }

  openModal() {
    this.setState({
      setIsOpen: !this.state.setIsOpen,
    });
  }

  openModalAcces() {
    this.setState({
      setIsOpenAcces: !this.state.setIsOpenAcces,
    });
  }
  openModalActif() {
    this.setState({
      setIsOpenActif: !this.state.setIsOpenActif,
    });
  }

  submitForm(initialValues, id) {
    if (
      (!initialValues.id && !initialValues.password) ||
      !initialValues.last_name ||
      !initialValues.first_name ||
      !initialValues.login ||
      //!initialValues.date_of_birth ||
      // !initialValues.hiring_date ||
      //!initialValues.departure_date ||
      !validate(initialValues.first_name) ||
      !validate(initialValues.last_name) ||
      (initialValues.gsm && !lengthPhone(initialValues.gsm)) ||
      (initialValues.gsm && !isNumeric(initialValues.gsm))
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else {
      this.props.submitSecretaryForm(initialValues, this.props.type);
    }
  }
  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }

  handleChangeFiles = (event, name) => {
    const fileUploaded = event.target.files[0];
    this.setState({
      [name]: fileUploaded,
    });
  };

  render() {
    const { secretary, sandwich_menu_btn } = this.props;
    const {
      setIsOpen,
      hasError,
      setIsOpenAcces,
      setIsOpenActif,
      initialValues,
      updatePassword,
    } = this.state;
    return (
      <div className="" style={{ width: '100%' }}>
        <Modals
          setIsOpen={setIsOpenActif}
          openModal={this.openModalActif}
          data={
            <>
              {' '}
              <div className="modal-header" style={{ marginTop: '0' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModalActif()}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '10px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>
                  {' '}
                  {initialValues.access_status === 'active' ? ' Désactiver' : ' Activer'}!
                </h2>

                <span>
                  {initialValues.access_status === 'active'
                    ? 'Êtes vous sûrs de vouloir désactiver ce compte ?'
                    : 'Etes vous sûrs de vouloir vous activer ?'}
                </span>
              </div>
              <div className="modal-footer">
                <button
                  style={{ backgroundColor: '#f6f6f6' }}
                  onClick={() => this.openModalActif()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                >
                  Annuler
                </button>
                <button
                  onClick={() => {
                    this.props.ActivitedDeactivetedAccount({
                      id: secretary.login_access.id,
                      access_status:
                        initialValues.access_status === 'active' ? 'disabled' : 'active',
                    });
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        access_status:
                          initialValues.access_status === 'active'
                            ? 'disabled'
                            : 'active',
                      },
                    });
                    this.openModalActif(setIsOpenActif);
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                  // data-dismiss="modal"
                >
                  Valider
                </button>
              </div>
            </>
          }
        />
        <Modals
          setIsOpen={setIsOpenAcces}
          openModal={this.openModalAcces}
          data={
            <>
              {' '}
              <div className="modal-header" style={{ marginTop: '0' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModalAcces()}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '10px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="not_app_container">
                  <img src={require('../../../assets/img/pas_de_disponibilite.svg')} />
                  <span id="appointment_details_indispo" style={{ marginTop: '10px' }}>
                    Pas d'historique !
                  </span>
                  <span id="appointment_details_sub">En cours d'intégration</span>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  style={{ backgroundColor: '#f6f6f6' }}
                  onClick={() => this.openModalAcces()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </>
          }
        />
        <Modals
          setIsOpen={setIsOpen}
          openModal={this.openModal}
          data={
            <>
              {' '}
              <div className="modal-header" style={{ marginTop: '0' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openModal()}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '10px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="not_app_container">
                  <img src={require('../../../assets/img/pas_de_disponibilite.svg')} />
                  <span id="appointment_details_indispo" style={{ marginTop: '10px' }}>
                    Pas d'historique !
                  </span>
                  <span id="appointment_details_sub">En cours d'intégration</span>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  style={{ backgroundColor: '#f6f6f6' }}
                  onClick={() => this.openModal()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </>
          }
        />
        {/*<div className="patient_detailed_info_section_header">
          <div style={{ height: '20vh', marginTop: '1px' }} className="user_img_name">
            <div className="user_img">
              {!initialValues.file && !initialValues.photo ? (
                <div className="patient_avatar">
                  <svg
                    width="33"
                    height="34"
                    viewBox="0 0 33 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.73958 23.2812C9.42708 21.7812 12.9583 21.0312 16.3333 21.0312C19.7083 21.0312 23.2083 21.7812 26.8333 23.2812C30.5208 24.7188 32.3646 26.625 32.3646 29V33.0312H0.302084V29C0.302084 26.625 2.11458 24.7188 5.73958 23.2812ZM21.9583 14.6562C20.3958 16.2188 18.5208 17 16.3333 17C14.1458 17 12.2708 16.2188 10.7083 14.6562C9.14583 13.0938 8.36458 11.2188 8.36458 9.03125C8.36458 6.84375 9.14583 4.96875 10.7083 3.40625C12.2708 1.78125 14.1458 0.96875 16.3333 0.96875C18.5208 0.96875 20.3958 1.78125 21.9583 3.40625C23.5208 4.96875 24.3021 6.84375 24.3021 9.03125C24.3021 11.2188 23.5208 13.0938 21.9583 14.6562Z"
                      fill="#8083A3"
                    />
                  </svg>
                </div>
              ) : (
                <img
                  src={
                    initialValues.file
                      ? URL.createObjectURL(initialValues.file)
                      : initialValues.photo
                      ? `${process.env.REACT_APP_PUBLIC_URL}${initialValues.photo}`
                      : require('../../../assets/img/kar.jpg')
                  }
                  alt=""
                />
              )}

              <input
                //onChange={(e) => this.handleChangeFiles(e, 'file')}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      file: e.target.files[0],
                    },
                  });
                }}
                type="file"
                ref="fileUploaderDoctor"
                name="file"
                hidden
                accept=".png, .jpg,.jpeg"
              />
              <i
                onClick={() => this.handleClick('fileUploaderDoctor')}
                className="fa fa-camera"
              ></i>
            </div>
            <div className="user_name">
              <span className="name">
                {initialValues.first_name && initialValues.last_name
                  ? `${initialValues.first_name} ${initialValues.last_name}`
                  : ''}
              </span>
            </div>
          </div>
        </div>*/}
        <div className="container_changeable_content" style={{ height: '100%' }}>
          <div
            className="patient_detailed_info_section"
            style={{
              width: 'fit-content',
            }}
          >
            <div className="user_img_name" style={{ margin: '0px' }}>
              <div className="user_img">
                {!initialValues.file && !initialValues.photo ? (
                  <div className="patient_avatar">
                    <svg
                      width="33"
                      height="34"
                      viewBox="0 0 33 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.73958 23.2812C9.42708 21.7812 12.9583 21.0312 16.3333 21.0312C19.7083 21.0312 23.2083 21.7812 26.8333 23.2812C30.5208 24.7188 32.3646 26.625 32.3646 29V33.0312H0.302084V29C0.302084 26.625 2.11458 24.7188 5.73958 23.2812ZM21.9583 14.6562C20.3958 16.2188 18.5208 17 16.3333 17C14.1458 17 12.2708 16.2188 10.7083 14.6562C9.14583 13.0938 8.36458 11.2188 8.36458 9.03125C8.36458 6.84375 9.14583 4.96875 10.7083 3.40625C12.2708 1.78125 14.1458 0.96875 16.3333 0.96875C18.5208 0.96875 20.3958 1.78125 21.9583 3.40625C23.5208 4.96875 24.3021 6.84375 24.3021 9.03125C24.3021 11.2188 23.5208 13.0938 21.9583 14.6562Z"
                        fill="#8083A3"
                      />
                    </svg>
                  </div>
                ) : !this.state.file &&
                  initialValues.photo &&
                  initialValues.photo.length === 0 ? (
                  <div className="patient_avatar">
                    <svg
                      width="33"
                      height="34"
                      viewBox="0 0 33 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.73958 23.2812C9.42708 21.7812 12.9583 21.0312 16.3333 21.0312C19.7083 21.0312 23.2083 21.7812 26.8333 23.2812C30.5208 24.7188 32.3646 26.625 32.3646 29V33.0312H0.302084V29C0.302084 26.625 2.11458 24.7188 5.73958 23.2812ZM21.9583 14.6562C20.3958 16.2188 18.5208 17 16.3333 17C14.1458 17 12.2708 16.2188 10.7083 14.6562C9.14583 13.0938 8.36458 11.2188 8.36458 9.03125C8.36458 6.84375 9.14583 4.96875 10.7083 3.40625C12.2708 1.78125 14.1458 0.96875 16.3333 0.96875C18.5208 0.96875 20.3958 1.78125 21.9583 3.40625C23.5208 4.96875 24.3021 6.84375 24.3021 9.03125C24.3021 11.2188 23.5208 13.0938 21.9583 14.6562Z"
                        fill="#8083A3"
                      />
                    </svg>
                  </div>
                ) : (
                  <img
                    src={
                      initialValues.file
                        ? URL.createObjectURL(initialValues.file)
                        : initialValues.photo
                        ? `${process.env.REACT_APP_PUBLIC_URL}${initialValues.photo}`
                        : require('../../../assets/img/kar.jpg')
                    }
                    alt=""
                  />
                )}

                <input
                  //onChange={(e) => this.handleChangeFiles(e, 'file')}
                  onChange={(e) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        file: e.target.files[0],
                      },
                    });
                  }}
                  type="file"
                  ref="fileUploaderDoctor"
                  name="file"
                  hidden
                  accept=".png, .jpg,.jpeg"
                />
                <i
                  onClick={() => this.handleClick('fileUploaderDoctor')}
                  className="fa fa-camera"
                ></i>
              </div>
              <div className="user_name" style={{ margin: '0px' }}>
                <span className="name">{`${
                  initialValues.first_name ? initialValues.first_name : ''
                } ${initialValues.last_name ? initialValues.last_name : ''}`}</span>
              </div>
            </div>
          </div>
          <div className="patient_detailed_info_section">
            <div className="general_info" style={{ width: '97%' }}>
              <form className="general_info_form">
                <div className="title_and_button">
                  <span>Informations générales</span>
                </div>
                <div className="general_info_actual_form">
                  <InputField
                    width={sandwich_menu_btn ? '26vw' : '30vw'}
                    className="general_info_form_item"
                    type="text"
                    placeholder="Prénom"
                    label="Prénom"
                    validInput="string"
                    hasError={hasError}
                    value={initialValues.last_name}
                    name="last_name"
                    icon={<i className="fas fa-user"></i>}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          last_name: e.target.value,
                        },
                      });
                    }}
                  />
                  <InputField
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          first_name: e.target.value,
                        },
                      });
                    }}
                    width={sandwich_menu_btn ? '26vw' : '30vw'}
                    type="text"
                    placeholder="Nom"
                    className="general_info_form_item"
                    validInput="string"
                    label="Nom"
                    value={initialValues.first_name}
                    hasError={hasError}
                    name="first_name"
                    icon={<i className="fas fa-user"></i>}
                  />
                  <InputField
                    width={sandwich_menu_btn ? '26vw' : '30vw'}
                    className="general_info_form_item"
                    type="text"
                    // hasError={hasError}
                    placeholder="Adresse e-mail"
                    label="Adresse e-mail"
                    value={initialValues.email}
                    //disabled={initialValues.id}
                    name="email"
                    icon={<i className="fas fa-envelope"></i>}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          email: e.target.value,
                        },
                      });
                    }}
                  />
                  <InputField
                    width={sandwich_menu_btn ? '26vw' : '30vw'}
                    className="general_info_form_item"
                    type="text"
                    placeholder="GSM"
                    label="GSM"
                    value={initialValues.gsm}
                    minLength={8}
                    maxLength={20}
                    validInput="tel"
                    //hasError={hasError}
                    name="gsm"
                    icon={<i className="fas fa-phone-alt"></i>}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          gsm: e.target.value,
                          phone: e.target.value,
                        },
                      });
                    }}
                  />
                  <div className="general_info_form_item">
                    <label>CV</label>
                    <div className="input_and_icon">
                      <input
                        type="file"
                        name="cv"
                        onChange={(e) =>
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              cv: e.target.files[0],
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  {initialValues.cv && !initialValues.cv.name ? (
                    <div className="general_info_form_item">
                      <div style={{ marginTop: '6%' }} className="input_and_icon">
                        <label>
                          {' '}
                          <a
                            target="_blank"
                            href={`${process.env.REACT_APP_PUBLIC_URL}${initialValues.cv}`}
                          >
                            Afficher le CV
                          </a>
                        </label>
                      </div>
                    </div>
                  ) : initialValues.cv && initialValues.cv.name ? (
                    <div className="general_info_form_item">
                      <div style={{ marginTop: '6%' }} className="input_and_icon">
                        <label>
                          {' '}
                          <a target="_blank" href={URL.createObjectURL(initialValues.cv)}>
                            Afficher le CV
                          </a>
                        </label>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {/*<div className="general_info_form_item">
                    <label>CV</label>
                    <div className="input_and_icon">
                      <input
                        type="file"
                        name="cv"
                      />
                    </div>
                  </div>*/}
                </div>
                <div className="address_form">
                  <div className="title_and_button">
                    <span id="">Adresse</span>
                  </div>

                  <div className="add_patient_actual_form">
                    <div className="field_row">
                      <InputField
                        width="100%"
                        className="add_patient_form_item"
                        type="text"
                        placeholder="Adresse"
                        label="Adresse"
                        validInput=""
                        name="street"
                        // hasError={hasError}
                        value={initialValues.street}
                        icon={<i className="fas fa-city"></i>}
                        onChange={(e) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              street: e.target.value,
                            },
                          });
                        }}
                      />
                      <div style={{ width: '100%' }} className="add_patient_form_item">
                        <label>Ville</label>
                        <Autocomplete
                          id="size-small-standard"
                          size="small"
                          options={sortCities}
                          getOptionLabel={(option) => option.title}
                          onChange={(e, option) => {
                            this.setState({
                              initialValues: {
                                ...initialValues,
                                city: option != null ? option.title : '',
                              },
                            });
                          }}
                          defaultValue={{
                            title: initialValues.city,
                          }}
                          renderInput={(cities) => (
                            <TextField
                              //   error={hasError && !initialValues.city}
                              style={{ width: '100%' }}
                              placeholder="Ville"
                              {...cities}
                              variant="standard"
                              value={initialValues.city}
                              //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                              //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="field_row">
                      <InputField
                        width="100%"
                        className="add_patient_form_item"
                        type="text"
                        placeholder="Code Postal"
                        label="Code Postal"
                        validInput="integer"
                        name="zip_code"
                        // hasError={hasError}
                        onChange={(e) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              zip_code: e.target.value,
                            },
                          });
                        }}
                        value={initialValues.zip_code}
                        icon={<i className="fas fa-globe-africa"></i>}
                      />
                      <div style={{ width: '100%' }} className="add_patient_form_item">
                        <label>Pays</label>
                        <Autocomplete
                          id="size-small-standard"
                          size="small"
                          options={country}
                          getOptionLabel={(option) => option.title}
                          onChange={(e, option) => {
                            this.setState({
                              initialValues: {
                                ...initialValues,
                                country: option != null ? option.title : '',
                              },
                            });
                          }}
                          defaultValue={{
                            title: initialValues.country,
                          }}
                          renderInput={(country) => (
                            <TextField
                              //  error={hasError && !initialValues.country}
                              style={{ width: '100%' }}
                              placeholder="Pays"
                              {...country}
                              name="country"
                              variant="standard"
                              value={initialValues.country}
                              //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="address_form">
                  <div className="title_and_button">
                    <span id="">Informations professionnelles</span>
                  </div>

                  <div className="add_patient_actual_form">
                    <div className="field_row">
                      <InputField
                        width="100%"
                        // hasError={hasError}
                        className="add_patient_form_item"
                        type="date"
                        placeholder="Date d’embauche"
                        label="Date d’embauche"
                        validInput=""
                        name="hiring_date"
                        value={initialValues.hiring_date}
                        onChange={(e) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              hiring_date: e.target.value,
                            },
                          });
                        }}
                      />
                      <div style={{ width: '100%' }} className="add_patient_form_item">
                        <label>Type de contrat</label>
                        <Autocomplete
                          id="size-small-standard"
                          size="small"
                          options={typeContract}
                          name="type_of_contract"
                          getOptionLabel={(option) => option.title}
                          onChange={(e, option) => {
                            this.setState({
                              initialValues: {
                                ...initialValues,
                                type_of_contract: option != null ? option.title : '',
                              },
                            });
                          }}
                          defaultValue={{
                            title: initialValues.type_of_contract,
                          }}
                          renderInput={(cities) => (
                            <TextField
                              // error={hasError && !initialValues.type_of_contract}
                              style={{ width: '100%' }}
                              placeholder="Type de contrat"
                              {...cities}
                              variant="standard"
                              value={initialValues.type_of_contract}
                              //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                            />
                          )}
                        />
                      </div>
                      <InputField
                        width="100%"
                        className="add_patient_form_item"
                        type="time"
                        placeholder="07:00"
                        label="Heure de début"
                        //validInput="integer"
                        name="hour_from"
                        //   hasError={hasError}
                        value={initialValues.hour_from}
                        onChange={(e) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              hour_from: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="field_row">
                      <InputField
                        width="100%"
                        className="add_patient_form_item"
                        type="date"
                        placeholder="Date départ "
                        label="Date départ "
                        validInput=""
                        //hasError={hasError}
                        onChange={(e) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              departure_date: e.target.value,
                            },
                          });
                        }}
                        name="departure_date"
                        value={initialValues.departure_date}
                      />
                      {initialValues.type_of_contract === 'CDI' ? (
                        ''
                      ) : (
                        <InputField
                          width="100%"
                          className="add_patient_form_item"
                          type="text"
                          placeholder="6"
                          label="Durée du contrat"
                          validInput="string"
                          name="contract_length"
                          maxLength="50"
                          // hasError={hasError}
                          value={initialValues.contract_length}
                          icon={<i className="fas fa-history"></i>}
                          onChange={(e) => {
                            this.setState({
                              initialValues: {
                                ...initialValues,
                                contract_length: e.target.value,
                              },
                            });
                          }}
                        />
                      )}

                      <InputField
                        width="100%"
                        className="add_patient_form_item"
                        type="time"
                        placeholder="19:00"
                        label="Heure de fin"
                        // validInput="integer"
                        name="hour_to"
                        // hasError={hasError}
                        value={initialValues.hour_to}
                        onChange={(e) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              hour_to: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="field_row"></div>
                  </div>
                </div>
                <br />
                <br />
                <div className="title_and_button">
                  <span>Gestion financière</span>
                  <p
                    onClick={() => this.openModal()}
                    style={{
                      cursor: 'pointer',
                      fontSize: '13px',
                      color: 'blue',
                      marginRight: '10%',
                    }}
                  >
                    Afficher historique de rémunérations
                  </p>
                </div>
                <div className="add_patient_actual_form">
                  <InputField
                    width={sandwich_menu_btn ? '45%' : '45%'}
                    className="general_info_form_item"
                    type="text"
                    placeholder="IBAN"
                    label="IBAN"
                    // validInput="integer"
                    name="iban"
                    // hasError={hasError}
                    value={initialValues.iban}
                    icon={<i className="fab fa-scribd"></i>}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          iban: e.target.value,
                        },
                      });
                    }}
                  />
                  <InputField
                    width={sandwich_menu_btn ? '45%' : '45%'}
                    className="general_info_form_item"
                    type="text"
                    placeholder="BANQUE"
                    label="BANQUE"
                    validInput="string"
                    name="bank"
                    value={initialValues.bank}
                    icon={<i className="fas fa-money-check-alt"></i>}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          bank: e.target.value,
                        },
                      });
                    }}
                  />
                  <InputField
                    width={sandwich_menu_btn ? '45%' : '45%'}
                    className="general_info_form_item"
                    type="text"
                    placeholder="CNSS "
                    label="CNSS "
                    validInput="integer"
                    name="cnss"
                    // hasError={hasError}
                    value={initialValues.cnss}
                    icon={<i className="fas fa-money-check-alt"></i>}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          cnss: e.target.value,
                        },
                      });
                    }}
                  />
                  <InputField
                    width={sandwich_menu_btn ? '45%' : '45%'}
                    className="general_info_form_item"
                    type="text"
                    placeholder="Salaire"
                    label="salaire "
                    validInput="integer"
                    name="salary"
                    // hasError={hasError}
                    value={initialValues.salary}
                    icon={<i className="fas fa-money-check-alt"></i>}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          salary: e.target.value,
                        },
                      });
                    }}
                  />
                  <InputField
                    width={sandwich_menu_btn ? '45%' : '45%'}
                    className="general_info_form_item"
                    type="text"
                    placeholder="Jour de paiement"
                    label="Jour de paiement"
                    validInput=""
                    maxLength={20}
                    name="payment_date"
                    //  hasError={hasError}
                    value={initialValues.payment_date}
                    onChange={(e) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          payment_date: e.target.value,
                        },
                      });
                    }}
                  />
                  <Autocomplete
                    id="size-small-standard"
                    size="small"
                    options={ModePaiement}
                    getOptionLabel={(option) => option.title}
                    style={{ height: 'fit-content', alignSelf: 'end', width: '44%' }}
                    onChange={(e, option) => {
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          payment_method: option != null ? option.title : '',
                        },
                      });
                    }}
                    defaultValue={{
                      title: initialValues.payment_method,
                    }}
                    renderInput={(cities) => (
                      <TextField
                        style={{ width: '100%' }}
                        placeholder="Mode de paiement"
                        {...cities}
                        //  error={hasError && !initialValues.payment_method}
                        variant="standard"
                        value={initialValues.payment_method}
                        //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                      />
                    )}
                  />
                </div>
                <div className="address_form">
                  <div className="title_and_button">
                    <span id="">Gestion des accès</span>
                    <p
                      onClick={() => this.openModalAcces()}
                      style={{
                        fontSize: '13px',
                        color: 'blue',
                        marginRight: '10%',
                        cursor: 'pointer',
                      }}
                    >
                      Afficher historique des accès
                    </p>
                  </div>

                  <div className="add_patient_actual_form">
                    <div className="field_row">
                      <InputField
                        width="100%"
                        className="add_patient_form_item"
                        type="text"
                        placeholder="Login"
                        label="Login"
                        validInput="login"
                        value={initialValues.login}
                        hasError={hasError}
                        name="login"
                        icon={<i className="fas fa-city"></i>}
                        onChange={(e) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              login: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    {updatePassword || !initialValues.id ? (
                      <div className="field_row">
                        <InputField
                          width="100%"
                          className="add_patient_form_item"
                          type="password"
                          hasError={hasError}
                          placeholder="*************"
                          value={initialValues.password}
                          label="Mot de passe"
                          //hasError={hasError}
                          validInput="login"
                          name="password"
                          onChange={(e) => {
                            this.setState({
                              initialValues: {
                                ...initialValues,
                                password: e.target.value,
                              },
                            });
                          }}
                          icon={<i className="fas fa-globe-africa"></i>}
                        />
                      </div>
                    ) : (
                      <div
                        className="footer_buttons"
                        style={{ width: '50%', paddingBottom: '4%' }}
                      >
                        <button
                          type="button"
                          style={{ width: '50%' }}
                          onClick={() =>
                            this.setState({ updatePassword: !updatePassword })
                          }
                          className="footer_btn"
                          id="cancel"
                        >
                          Modifier Mot de passe
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </form>
              <div className="footer_buttons" style={{ paddingBottom: '4%' }}>
                <button
                  onClick={() => this.props.goBack()}
                  className="footer_btn"
                  id="cancel"
                >
                  Annuler
                </button>
                {initialValues.id ? (
                  <div
                    style={{ width: '26%', marginTop: '6%' }}
                    className="seance-status"
                  >
                    <span style={{ color: 'black', fontWeight: 'bold' }}>
                      {initialValues.access_status === 'active'
                        ? 'Désactiver le compte'
                        : 'Activer le compte'}
                    </span>
                    <div
                      onClick={() => {
                        this.openModalActif(setIsOpenActif);
                      }}
                      style={{ cursor: 'pointer' }}
                      className={
                        initialValues.access_status === 'active'
                          ? 'active_btn'
                          : 'deactive_btn'
                      }
                    >
                      <div
                        className={
                          initialValues.access_status === 'active'
                            ? 'deactive_btn_circle active_btn_circle'
                            : 'deactive_btn_circle'
                        }
                      ></div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <button
                  style={{
                    marginRight: sandwich_menu_btn ? '6%' : '6%',
                  }}
                  onClick={() => this.submitForm(initialValues)}
                  id="update"
                  className="footer_btn"
                  //disabled={isLoadingUpdateUser}
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Form);
