import * as React from "react";
import {TableCell, TableCellProps} from "./TableCell";

/**
 * This component is used to display data in the the {@see TableRow} component.
 */
export class DataTableCell extends React.PureComponent{
  render() {
    return (
      <TableCell
        {...this.props}
      >
        {this.props.getContent(this.props.data)}
      </TableCell>
    )
  }
}
