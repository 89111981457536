import InputField from '../../../components/inputField';
import { SwipeableDrawer } from '@material-ui/core';
import React from 'react';
import moment from 'moment';
import { isNumeric } from '../../../utils/helpres';
import actions from '../../consultations/store/actions';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  sessionsVirtualPec: state.ConsultationReducer.sessionsVirtualPec,
});

const mapDispatchToProps = (dispatch) => ({
  getDispoDoctor: (values) => dispatch(actions.getSessionsVirtualPec(values)),
});
class DrawerUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionsVirtualPec: [],
      isOpen: false,
      type_pec: '',
      type: 0,
      left: false,
      setIsOpen: false,
      anchor: 'left',
      initialValues: {
        type: 'pec',
        num_order: props.pec.num_order,
        year: props.pec.year,
        code_bureau: props.pec.code_bureau,
        hourStart: '10:00',
        duration: '60',
        quality: props.pec.quality,
        sessions_count: props.pec.sessions_count,
        sessions_per_week: props.pec.sessions_per_week,
        pecStartDate: moment(this.props.pec.start_date).format('YYYY-MM-DD'),
        pecEndDate: moment(this.props.pec.end_date).format('YYYY-MM-DD'),
      },
      hasError: false,
    };
    this.submitPEC = this.submitPEC.bind(this);
    this.openPec = this.openPec.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.handleChangeDateInput = this.handleChangeDateInput.bind(this);
  }
  componentWillReceiveProps(nextProps, nextContext) {
    const { initialValues } = this.state;
    const { sessionsVirtualPec } = nextProps;
    if (sessionsVirtualPec && sessionsVirtualPec.length > 0) {
      this.setState({
        initialValues: {
          ...initialValues,
          pecEndDate: moment(sessionsVirtualPec[sessionsVirtualPec.length - 1].date).format('YYYY-MM-DD'),
        },
      });
    }
  }

  handleChangeDateInput = (event) => {
    const { initialValues } = this.state;

    this.props.getDispoDoctor({
      consultation_id: this.props.pec.consultation.id,
      start_date: moment(event.target.value).format('YYYY-MM-DD 07:00:00'), //"2023-02-13 07:00:00",
      end_date: moment(event.target.value).format('YYYY-MM-DD 07:00:00'),
      sessions_per_week: initialValues.sessions_per_week,
      practitioner_id: null,
      patient_id: 1,
      sessions_count: initialValues.sessions_count,
      hours08: '07',
      hours20: '19',
    });
    this.setState({
      initialValues: {
        ...initialValues,
        pecStartDate: moment(event.target.value).format('YYYY-MM-DD'),
      },
    });
  };

  toggleDrawer = (anchor, open, id) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    this.props.cancel();
  };
  async submitPEC(initialValues, type) {
    const { pec, consultation_id, consultation, params } = this.props;
    if (
      !initialValues.quality ||
      !initialValues.num_order ||
      !initialValues.year ||
      !initialValues.code_bureau ||
      !initialValues.pecStartDate ||
      !initialValues.pecEndDate ||
      // (initialValues.num_order && !isNumeric(initialValues.num_order)) ||
      //(initialValues.code_bureau && !isCodeCNAM(initialValues.code_bureau.toString())) ||
      // (initialValues.num_order &&
      //   // isNumeric(initialValues.num_order) &&
      //   initialValues.num_order <= 4) ||
      (initialValues.year && !isNumeric(initialValues.year)) ||
      (initialValues.code_bureau && !isNumeric(initialValues.code_bureau)) ||
      /*(initialValues.code_bureau &&
              isNumeric(initialValues.code_bureau) &&
              initialValues.code_bureau.toString().length !== 2) ||*/
      // (initialValues.num_order && !isNumeric(initialValues.num_order)) ||
      (initialValues.year &&
        isNumeric(initialValues.year) &&
        initialValues.year.toString().length !== 4) ||
      (initialValues.year &&
        isNumeric(initialValues.year) &&
        initialValues.year > moment().format('YYYY'))
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else {
      initialValues.consultation_id = pec.consultation.id;
      initialValues.id = pec.id;

      await this.props.submitFormPEC(initialValues, params);
      this.props.cancel();
    }
  }

  openPec(pec) {
    const { initialValues } = this.state;
    this.setState({
      isOpen: true,
      initialValues: {
        ...initialValues,
        id: pec.id,
        num_order: pec.num_order,
        year: pec.year,
        code_bureau: pec.code_bureau,
        quality: pec.quality,
      },
    });
  }
  render() {
    const { num_cnam, initialValues, hasError } = this.state;
    const { pec, isOpen } = this.props;
    return (
      <div>
        <SwipeableDrawer
          style={{ width: '50%' }}
          anchor="right"
          open={isOpen}
          onClose={this.toggleDrawer('right', false)}
          onOpen={this.toggleDrawer('right', true)}
        >
          <div className="right_side_bar">
            <div className="right_side_bar_header">
              <span id="add_app_sidebar_title">Modifier pec cnam</span>
              <svg
                onClick={() => {
                  this.props.cancel();
                }}
                style={{ cursor: 'pointer' }}
                id="close-right-sidebar"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="9.5"
                  fill="white"
                  stroke="#ECEEF5"
                />
                <path
                  d="M25.2383 15.8164L21.0547 20L25.2383 24.1836L24.1836 25.2383L20 21.0547L15.8164 25.2383L14.7617 24.1836L18.9453 20L14.7617 15.8164L15.8164 14.7617L20 18.9453L24.1836 14.7617L25.2383 15.8164Z"
                  fill="#8083A3"
                />
              </svg>
            </div>
            <div className="right_side_bar_body">
              <div className="form-row">
                <div
                  style={{ width: '46%' }}
                  className={
                    hasError && !initialValues.quality ? 'form-group type3' : 'form-group'
                  }
                >
                  <label htmlFor="">Qualité</label>
                  <div className="input_and_icon">
                    <select
                      value={initialValues.quality || pec.quality}
                      name="quality"
                      onChange={(e) =>
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            quality: e.target.value,
                          },
                        })
                      }
                      className="form-control"
                      id="exampleFormControlSelect1"
                    >
                      <option>Qualité</option>
                      <option value="Assuré lui même">Assuré lui même</option>
                      <option value="Conjoint">Conjoint</option>
                      <option value="Enfant">Enfant</option>
                      <option value="Mére">Mère</option>
                      <option value="Père">Père</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form_subtitle">
                <span>Décision</span>
              </div>
              <div className="form-row">
                <div style={{ width: '46%', display: 'flex' }}>
                  <InputField
                    width="20%"
                    className="add_patient_form_item"
                    type="text"
                    placeholder="XX"
                    label="Code bureau"
                    ///maxLength={2}
                    hasError={hasError}
                    value={parseInt(initialValues.code_bureau) || pec.code_bureau || null}
                    validInput="integerCnamKey"
                    name="code_bureau"
                    icon={<i className="fas fa-key"></i>}
                    onChange={(e) =>
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          code_bureau: e.target.value,
                        },
                      })
                    }
                  />
                  <InputField
                    width="30%"
                    className="add_patient_form_item"
                    type="text"
                    placeholder="XXXX"
                    label="Année"
                    maxLength={4}
                    hasError={hasError}
                    value={parseInt(initialValues.year) || pec.year || null}
                    validInput="integerYear"
                    name="year"
                    icon={<i className="fas fa-dot-circle"></i>}
                    onChange={(e) =>
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          year: e.target.value,
                        },
                      })
                    }
                  />
                  <InputField
                    width="50%"
                    className="add_patient_form_item"
                    type="text"
                    placeholder="XXXXXX"
                    label="N° ordre"
                    hasError={hasError}
                    value={initialValues.num_order || pec.num_order || ''}
                    validInput="string"
                    name="num_order"
                    icon={<i className="fas fa-dot-circle"></i>}
                    onChange={(e) =>
                      this.setState({
                        initialValues: {
                          ...initialValues,
                          num_order: e.target.value,
                        },
                      })
                    }
                  />
                </div>
                <InputField
                  width="46%"
                  className="add_patient_form_item"
                  type="text"
                  placeholder="Combiné automatiquement"
                  label="N° décision"
                  maxLength={10}
                  hasError={hasError}
                  value={
                    (initialValues.num_order || pec.num_order) &&
                    (initialValues.year || pec.year) &&
                    (initialValues.code_bureau || pec.code_bureau)
                      ? `${parseInt(initialValues.code_bureau || pec.num_order)}/${
                          initialValues.year || pec.num_order
                        }/${initialValues.num_order || pec.num_order}`
                      : ''
                  }
                  disabled={true}
                  icon={<i className="fas fa-dot-circle"></i>}
                />
              </div>
              <div className="form-row" style={{ marginTop: '30px' }}>
                <InputField
                  width="20%"
                  className="add_patient_form_item"
                  type="text"
                  placeholder="Taper.."
                  label="Nombre des séances"
                  value={initialValues.sessions_count || ''}
                  validInput="string"
                  name="sessions_count"
                  icon={<i className="fas fa-dot-circle"></i>}
                  onChange={(e) => {
                    if (e.target.value.toString() !== '') {
                      const { initialValues } = this.state;

                      this.props.getDispoDoctor({
                        consultation_id: this.props.pec.consultation.id,
                        start_date: moment(initialValues.pecStartDate).format('YYYY-MM-DD 07:00:00'), //"2023-02-13 07:00:00",
                        end_date: moment(initialValues.pecStartDate).format('YYYY-MM-DD 07:00:00'),
                        sessions_per_week: initialValues.sessions_per_week,
                        practitioner_id: null,
                        patient_id: 1,
                        sessions_count: e.target.value,
                        hours08: '07',
                        hours20: '19',
                      });
                    }
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        sessions_count: e.target.value,
                      },
                    })
                  }

                  }
                />
                <InputField
                  width="20%"
                  className="add_patient_form_item"
                  type="text"
                  placeholder="Taper.."
                  label="Séances / S"
                  value={initialValues.sessions_per_week || ''}
                  validInput="string"
                  name="sessions_per_week"
                  icon={<i className="fas fa-dot-circle"></i>}
                  onChange={(e) => {
                    if (e.target.value.toString() !== '') {
                      const { initialValues } = this.state;

                      this.props.getDispoDoctor({
                        consultation_id: this.props.pec.consultation.id,
                        start_date: moment(initialValues.pecStartDate).format('YYYY-MM-DD 07:00:00'), //"2023-02-13 07:00:00",
                        end_date: moment(initialValues.pecStartDate).format('YYYY-MM-DD 07:00:00'),
                        sessions_per_week: e.target.value,
                        practitioner_id: null,
                        patient_id: 1,
                        sessions_count: initialValues.sessions_count,
                        hours08: '07',
                        hours20: '19',
                      });
                    }

                    this.setState({
                      initialValues: {
                        ...initialValues,
                        sessions_per_week: e.target.value,
                      },
                    })
                  }
                  }
                />
                <InputField
                  width="23%"
                  value={initialValues.pecStartDate ?? pec.start_date}
                  name="dateDebut"
                  onChange={this.handleChangeDateInput}
                  type="date"
                  label="Date de début"
                />
                <InputField
                  width="23%"
                  /*value={
                                       initialValues.pecEndDate
                                         ? moment(initialValues.pecEndDate).format('DD/MM/YYYY')
                                         : moment(pec.end_date).format('DD/MM/YYYY')
                                     }*/
                  value={initialValues.pecEndDate ?? pec.end_date}
                  name="pecEndDate"
                  placeholder="Calculée automatiquement"
                  type="date"
                  label="Date de fin"
                  onChange={(e) =>
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        pecEndDate: e.target.value,
                        end_date: e.target.value,
                      },
                    })
                  }
                  //disabled={true}
                />
              </div>

              <div className="footer_buttons">
                <button
                  style={{ marginRight: '3%', width: '285px' }}
                  onClick={() => {
                    this.submitPEC(initialValues, false);
                  }}
                  type="button"
                  className="footer_btn"
                  id="update"
                >
                  Valider
                </button>
                {pec.code_bureau && pec.year && pec.num_order ? (
                  ''
                ) : (
                  <button
                    style={{ width: '215px', marginRight: '5%' }}
                    type="button"
                    className="footer_btn"
                    id="update"
                    onClick={() => {
                      this.submitPEC(initialValues, true);
                    }}
                  >
                    Valider et imprimer
                  </button>
                )}
              </div>
            </div>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DrawerUpdate);
