import React, { Component } from 'react';
import Factures from '../facture';

class FactureCnam extends Component {
  render() {
    return (
      <Factures
        type="cnam"
        selectedId={this.props.selectedId}
        active={10}
        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
        changeNamePage={this.props.changeNamePage}
        changeActiveItemSideBarCompta={this.props.changeActiveItemSideBarCompta}
      />
    );
  }
}

export default FactureCnam;
