import React, { useState, useEffect, Fragment } from 'react';
//import React, { Component } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from '@react-pdf/renderer';
import { zerosNumBordereau } from '../../utils/constants';
import moment from 'moment';
import { Table } from '../consultations/tablePdf/Table';
import { TableHeader } from '../consultations/tablePdf/TableHeader';
import { TableCell } from '../consultations/tablePdf/TableCell';
import { TableBody } from '../consultations/tablePdf/TableBody';
import { DataTableCell } from '../consultations/tablePdf/DataTableCell';
import Icon from '../../assets/img/arrow1.png';
import IconKine from '../../assets/img/logo-kine.png';

// Create styles
const newstyles = StyleSheet.create({
  page: {
    display: 'inline-grid',
    flexDirection: 'row',
    marginTop: 10,
  },
  Header: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '28px',
    marginBottom: '30px',
    cursor: 'pointer',
  },
  icon: {
    padding: '10px',
    border: '1px solid #eceef5',
    boxSizing: 'border-box',
    borderRadius: '10px',
    width: '46px',
    height: '46px',
    marginLeft: '36px',
    marginRight: '21px',
  },
  PDFViewer: {
    width: '100%',
    height: '550px',
  },
  section: {
    marginLeft: 130,
    padding: 10,
  },
  sectionNameDoctor: {
    marginLeft: 10,
    marginRight: 110,
    padding: 10,
  },
  section2: {
    marginLeft: 30,
    padding: 10,
  },
  hr: {
    BorderTop: '1px bold red',
  },
  cabinet: {
    display: 'flex',
    marginTop: 30,
    flexDirection: 'row',
    marginLeft: 30,
    marginRight: 60,
  },
  sectionBill: {
    margin: 10,
    padding: 10,
    flexDirection: 'row',
  },
  detailBill: {
    margin: 10,
    padding: 10,
    flexDirection: 'row',
  },
  tableTOTAL: {
    display: 'table',
    width: '80%',
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginHorizontal: '20',
    alignItems: 'right',
  },
  detailBillText: {
    color: '#070707',
    fontWeight: '1000',
    fontSize: 12,
    fontStyle: 'gras',
    //fontFamily: 'Georgia, serif',
    margin: 3,
  },
  detailBillText2: {
    color: '#070707',
    fontWeight: 'bolder',
    marginLeft: 10,
    padding: 10,
  },
  text: {
    color: '#5446db',
    fontWeight: 'bolder',
  },
  bill: {
    color: '#5446db',
    fontWeight: '500',
    marginLeft: '1%',
  },
  text1: {
    color: '#49494c',
    fontSize: 12,
    fontWeight: 'bolder',
    display: 'block',
    margin: 3,
    width: '150px',
  },
  images: {
    marginTop: 150,
    marginLeft: 20,
    // padding: 10,
    width: 50,
    height: 20,
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});
function Bordereau(props) {
  const bills = props.location.state.billsByIds;
  const office = props.location.state.office;
  const doctor = props.location.state.doctor;
  const bordereau = props.location.state.addBordereau;
  const type = props.location.state.type;
  const [i, setI] = useState(0);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    let i = 0;
    let total = 0;
    props.changeActiveItemSideBar(6);
    console.log(bills);
    props.changeNamePage(`Bordereau n°: ${bordereau.number}`);
    bills &&
      bills.length > 0 &&
      bills.map((bill) => {
        setI(i + 1);
        total += bill.total_ttc;
        i++;
        if (i < 10)
          data.push({
            num: i,
            number_bill: bill.number,
            date: moment(bill.date).format('DD/MM/YYYY'),
            patient: `${bill.consultation.patient_record.patient.last_name} ${bill.consultation.patient_record.patient.first_name}`,
            total: bill.total_ttc,
          });
        else if (i < 20)
          data2.push({
            num: i,
            number_bill: bill.number,
            date: moment(bill.date).format('DD/MM/YYYY'),
            patient: `${bill.consultation.patient_record.patient.last_name} ${bill.consultation.patient_record.patient.first_name}`,
            total: bill.total_ttc,
          });
        else
          data3.push({
            num: i,
            number_bill: bill.number,
            date: moment(bill.date).format('DD/MM/YYYY'),
            patient: `${bill.consultation.patient_record.patient.last_name} ${bill.consultation.patient_record.patient.first_name}`,
            total: bill.total_ttc,
          });
      });
    setTotal(parseFloat(total).toFixed(2));
    setData(data);
    setData2(data2);
  }, [data, total]);
  return (
    <>
      <div className="bilan_details_header">
        <div
          onClick={() =>
            props.history.push({
              pathname:
                type === 'cnam'
                  ? `/module-cnam`
                  : type === 'act'
                  ? '/module-cnam'
                  : '/compta',
              state: {
                bordereau_id: bordereau.id,
                bCnam: type === 'cnam' ? true : false,
                bACT: type === 'act' ? true : false,
                bAutre: type === 'autre' ? true : false,
              },
            })
          }
          className="back_icon"
        >
          <img src={Icon} />
        </div>
        <span>Retour</span>
      </div>
      <Fragment>
        <PDFViewer style={newstyles.PDFViewer}>
          <Document>
            <Page
              style={newstyles.body}
              //rulerSteps={20}
              orientation="landscape"
              size="A4"
              // wrap
            >
              <View style={newstyles.cabinet}>
                <View style={newstyles.sectionNameDoctor}>
                  <Text
                    style={{ ...newstyles.detailBillText, display: 'block', width: 120 }}
                  >
                    Nom:{' '}
                    {office && office.name
                      ? office.name
                      : `${office.first_name} ${office.last_name}`}
                  </Text>
                  <Text style={newstyles.detailBillText}>
                    Code CNAM: {office ? office.conventional_code : '-'}{' '}
                  </Text>

                  <Text style={newstyles.detailBillText}>
                    N° Employeur: {office ? office.number_employer : '-'}{' '}
                  </Text>
                </View>
                <View style={newstyles.sectionLogo}>
                  <Image style={{ width: 60, height: 60 }} src={IconKine} />
                </View>
                <View style={newstyles.section}>
                  <Text style={newstyles.detailBillText}>
                    R.I.B: {office ? office.rib : '-'}
                  </Text>
                  <Text style={newstyles.detailBillText}>
                    BANQUE: {office ? office.bank : '-'}
                  </Text>
                  <Text style={newstyles.detailBillText}>
                    Identification fiscale:{' '}
                    {office ? office.tax_registration_number : '-'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  borderBottomColor: 'black',
                  marginLeft: 50,
                  marginRight: 60,
                  borderBottomWidth: 1,
                }}
              />
              <View
                style={{
                  marginLeft: 50,
                  marginRight: 60,
                  marginTop: 5,
                }}
              >
                <Text
                  style={{
                    color: '#000000',
                    fontSize: 15,
                    fontWeight: '700',
                    display: 'block',
                    textAlign: 'center',
                    margin: 3,
                  }}
                >
                  {type === 'autre'
                    ? 'Bordereau des factures'
                    : 'Bordereau des Quotes-parts prescription médicale par la cnam'}
                </Text>
              </View>
              <View
                style={{
                  justifyContent: 'center',
                  marginLeft: 50,
                  marginTop: 10,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 12,
                    fontWeight: 'bolder',
                    marginLeft: -60,
                  }}
                >
                  Date :
                </Text>
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    fontStyle: 'gras',
                    marginRight: 30,
                  }}
                >
                  {moment(bordereau.created_at).format('DD/MM/YYYY')}
                </Text>
                <Text
                  style={{
                    color: '#49494c',
                    fontSize: 12,
                    fontWeight: 'bolder',
                  }}
                >
                  N° :
                </Text>
                <Text
                  style={{
                    color: '#070707',
                    fontWeight: '1000',
                    fontSize: 12,
                    fontStyle: 'gras',
                  }}
                >
                  {bordereau.number}
                </Text>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginRight: 40,
                  marginTop: 3,
                }}
              >
                <Table data={data}>
                  <TableHeader textAlign={'center'}>
                    <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                      N°
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold', textAlign: 'left' }}>
                      N° Fct
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold', textAlign: 'left' }}>
                      Date Fct
                    </TableCell>
                    <TableCell
                      style={{ width: '150px', fontWeight: 'bold', textAlign: 'left' }}
                    >
                      Patient
                    </TableCell>
                    <TableCell style={{ fontWeight: 'bold', textAlign: 'left' }}>
                      Montant
                    </TableCell>
                  </TableHeader>
                  <TableBody>
                    <DataTableCell
                      style={{ textAlign: 'center' }}
                      getContent={(r) => r.num}
                    />
                    <DataTableCell
                      style={{ textAlign: 'left' }}
                      getContent={(r) => r.number_bill}
                    />
                    <DataTableCell
                      style={{ textAlign: 'left' }}
                      getContent={(r) => r.date}
                    />
                    <DataTableCell
                      style={{ width: '150px', textAlign: 'left' }}
                      getContent={(r) => r.patient}
                    />
                    <DataTableCell
                      style={{ textAlign: 'left' }}
                      getContent={(r) => parseFloat(r.total).toFixed(3)}
                    />
                  </TableBody>
                </Table>
              </View>
              {data && data.length !== 0 ? (
                <View style={newstyles.tableTOTAL}>
                  <View
                    style={{
                      width: '20%',
                      borderStyle: 'solid',
                      borderWidth: 1,
                      paddingLeft: '1%',
                      paddingRight: '1%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginTop: '20px',
                    }}
                  >
                    <View
                      style={{
                        borderLeftWidth: 0,
                        borderTopWidth: 0,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                      }}
                    >
                      <Text
                        style={{
                          margin: 'auto',
                          marginTop: 5,
                          fontSize: 8,
                          textAlign: 'left',
                          paddingLeft: 0,
                        }}
                      >
                        TOT. HT
                      </Text>
                    </View>
                    <View
                      style={{
                        borderLeftWidth: 0,
                        borderTopWidth: 0,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                      }}
                    >
                      <Text
                        style={{
                          margin: 'auto',
                          marginTop: 5,
                          fontSize: 8,
                          // textAlign: "",
                          paddingLeft: 0,
                        }}
                      >
                        {(parseFloat(total) / 1.07).toFixed(3)}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: '20%',
                      borderStyle: 'solid',
                      borderWidth: 1,
                      borderTopWidth: 0,
                      paddingLeft: '1%',
                      paddingRight: '1%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <View
                      style={{
                        borderLeftWidth: 0,
                        borderTopWidth: 0,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                      }}
                    >
                      <Text
                        style={{
                          margin: 'auto',
                          marginTop: 5,
                          fontSize: 8,
                          textAlign: 'left',
                          paddingLeft: 0,
                        }}
                      >
                        MNT. TVA
                      </Text>
                    </View>
                    <View
                      style={{
                        borderLeftWidth: 0,
                        borderTopWidth: 0,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                      }}
                    >
                      <Text
                        style={{
                          margin: 'auto',
                          marginTop: 5,
                          fontSize: 8,
                          // textAlign: "",
                          paddingLeft: 0,
                        }}
                      >
                        {(parseFloat(total) - parseFloat(total) / 1.07).toFixed(
                          3,
                        )}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      width: '20%',
                      borderStyle: 'solid',
                      borderWidth: 1,
                      borderTopWidth: 0,
                      paddingLeft: '1%',
                      paddingRight: '1%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <View
                      style={{
                        borderLeftWidth: 0,
                        borderTopWidth: 0,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                      }}
                    >
                      <Text
                        style={{
                          margin: 'auto',
                          marginTop: 5,
                          fontSize: 8,
                          textAlign: 'left',
                          paddingLeft: 0,
                        }}
                      >
                        TOT. TTC
                      </Text>
                    </View>
                    <View
                      style={{
                        borderLeftWidth: 0,
                        borderTopWidth: 0,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                      }}
                    >
                      <Text
                        style={{
                          margin: 'auto',
                          marginTop: 5,
                          fontSize: 8,
                          // textAlign: "",
                          paddingLeft: 0,
                        }}
                      >
                        {parseFloat(total).toFixed(3)}
                      </Text>
                    </View>
                  </View>
                </View>
              ) : (
                <Text></Text>
              )}
              {/*<View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: 450,
                  marginRight: 60,
                  alignItems: 'flex-end',
                }}
              >
                <View style={newstyles.section}>
                  <Text style={{ ...newstyles.detailBillText, fontSize: 13 }}>
                    Total: {total}
                  </Text>
                </View>
              </View>*/}
              <Text
                style={newstyles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
              />
            </Page>
            {data2 && data2.length > 0 ? (
              <Page
                style={newstyles.body}
                //rulerSteps={20}
                orientation="landscape"
                size="A4"
                // wrap
              >
                {' '}
                <View style={newstyles.cabinet}>
                  <View style={newstyles.sectionNameDoctor}>
                    <Text
                      style={{
                        ...newstyles.detailBillText,
                        display: 'block',
                        width: 120,
                      }}
                    >
                      Nom:{' '}
                      {office && office.name
                        ? office.name
                        : `${office.first_name} ${office.last_name}`}
                    </Text>
                    <Text style={newstyles.detailBillText}>
                      Code CNAM: {office ? office.conventional_code : '-'}{' '}
                    </Text>

                    <Text style={newstyles.detailBillText}>
                      N° Employeur: {office ? office.number_employer : '-'}{' '}
                    </Text>
                  </View>
                  <View style={newstyles.sectionLogo}>
                    <Image style={{ width: 60, height: 60 }} src={IconKine} />
                  </View>
                  <View style={newstyles.section}>
                    <Text style={newstyles.detailBillText}>
                      R.I.B: {office ? office.rib : '-'}
                    </Text>
                    <Text style={newstyles.detailBillText}>
                      BANQUE: {office ? office.bank : '-'}
                    </Text>
                    <Text style={newstyles.detailBillText}>
                      Identification fiscale:{' '}
                      {office ? office.tax_registration_number : '-'}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    borderBottomColor: 'black',
                    marginLeft: 50,
                    marginRight: 60,
                    borderBottomWidth: 1,
                  }}
                />
                <View
                  style={{
                    marginLeft: 30,
                    marginRight: 40,
                    marginTop: 3,
                  }}
                >
                  <Table data={data2}>
                    <TableHeader textAlign={'center'}>
                      <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        N°
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', textAlign: 'left' }}>
                        N° Fct
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', textAlign: 'left' }}>
                        Date Fct
                      </TableCell>
                      <TableCell
                        style={{ width: '150px', fontWeight: 'bold', textAlign: 'left' }}
                      >
                        Patient
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', textAlign: 'left' }}>
                        Montant
                      </TableCell>
                    </TableHeader>
                    <TableBody>
                      <DataTableCell
                        style={{ textAlign: 'center' }}
                        getContent={(r) => r.num}
                      />
                      <DataTableCell
                        style={{ textAlign: 'left' }}
                        getContent={(r) => r.number_bill}
                      />
                      <DataTableCell
                        style={{ textAlign: 'left' }}
                        getContent={(r) => r.date}
                      />
                      <DataTableCell
                        style={{ width: '150px', textAlign: 'left' }}
                        getContent={(r) => r.patient}
                      />
                      <DataTableCell
                        style={{ textAlign: 'left' }}
                        getContent={(r) => r.cnam}
                      />
                      <DataTableCell
                        style={{ textAlign: 'left' }}
                        getContent={(r) => r.number_pec}
                      />
                      <DataTableCell
                        style={{ textAlign: 'left' }}
                        getContent={(r) => parseFloat(r.total).toFixed(3)}
                      />
                    </TableBody>
                  </Table>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 450,
                    marginRight: 60,
                    alignItems: 'flex-end',
                  }}
                >
                  <View style={newstyles.section}>
                    <Text style={{ ...newstyles.detailBillText, fontSize: 13 }}>
                      Total: {total}
                    </Text>
                  </View>
                </View>
                <Text
                  style={newstyles.pageNumber}
                  render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                  fixed
                />
              </Page>
            ) : undefined}
            {data3 && data3.length > 0 ? (
              <Page
                style={newstyles.body}
                //rulerSteps={20}
                orientation="landscape"
                size="A4"
                // wrap
              >
                {' '}
                <View style={newstyles.cabinet}>
                  <View style={newstyles.sectionNameDoctor}>
                    <Text
                      style={{
                        ...newstyles.detailBillText,
                        display: 'block',
                        width: 120,
                      }}
                    >
                      Nom:{' '}
                      {office && office.name
                        ? office.name
                        : `${office.first_name} ${office.last_name}`}
                    </Text>
                    <Text style={newstyles.detailBillText}>
                      Code CNAM: {office ? office.conventional_code : '-'}{' '}
                    </Text>

                    <Text style={newstyles.detailBillText}>
                      N° Employeur: {office ? office.number_employer : '-'}{' '}
                    </Text>
                  </View>
                  <View style={newstyles.sectionLogo}>
                    <Image style={{ width: 60, height: 60 }} src={IconKine} />
                  </View>
                  <View style={newstyles.section}>
                    <Text style={newstyles.detailBillText}>
                      R.I.B: {office ? office.rib : '-'}
                    </Text>
                    <Text style={newstyles.detailBillText}>
                      BANQUE: {office ? office.bank : '-'}
                    </Text>
                    <Text style={newstyles.detailBillText}>
                      Identification fiscale:{' '}
                      {office ? office.tax_registration_number : '-'}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    borderBottomColor: 'black',
                    marginLeft: 50,
                    marginRight: 60,
                    borderBottomWidth: 1,
                  }}
                />
                <View
                  style={{
                    marginLeft: 30,
                    marginRight: 40,
                    marginTop: 3,
                  }}
                >
                  <Table data={data3}>
                    <TableHeader textAlign={'center'}>
                      <TableCell style={{ fontWeight: 'bold', textAlign: 'center' }}>
                        N°
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', textAlign: 'left' }}>
                        N° Fct
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', textAlign: 'left' }}>
                        Date Fct
                      </TableCell>
                      <TableCell
                        style={{ width: '150px', fontWeight: 'bold', textAlign: 'left' }}
                      >
                        Patient
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold', textAlign: 'left' }}>
                        Montant
                      </TableCell>
                    </TableHeader>
                    <TableBody>
                      <DataTableCell
                        style={{ textAlign: 'center' }}
                        getContent={(r) => r.num}
                      />
                      <DataTableCell
                        style={{ textAlign: 'left' }}
                        getContent={(r) => r.number_bill}
                      />
                      <DataTableCell
                        style={{ textAlign: 'left' }}
                        getContent={(r) => r.date}
                      />
                      <DataTableCell
                        style={{ width: '150px', textAlign: 'left' }}
                        getContent={(r) => r.patient}
                      />
                      <DataTableCell
                        style={{ textAlign: 'left' }}
                        getContent={(r) => r.cnam}
                      />
                      <DataTableCell
                        style={{ textAlign: 'left' }}
                        getContent={(r) => r.number_pec}
                      />
                      <DataTableCell
                        style={{ textAlign: 'left' }}
                        getContent={(r) => parseFloat(r.total).toFixed(3)}
                      />
                    </TableBody>
                  </Table>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: 450,
                    marginRight: 60,
                    alignItems: 'flex-end',
                  }}
                >
                  <View style={newstyles.section}>
                    <Text style={{ ...newstyles.detailBillText, fontSize: 13 }}>
                      Total: {total}
                    </Text>
                  </View>
                </View>
                <Text
                  style={newstyles.pageNumber}
                  render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                  fixed
                />
              </Page>
            ) : undefined}
          </Document>
        </PDFViewer>
      </Fragment>
    </>
  );
}

export default Bordereau;
