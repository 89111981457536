import { apiCall } from '../../../js/actions';
import { toast } from 'react-toastify';
import ToastAction from '../../../components/ToastAction';
import React from 'react';
import { getStatusError } from '../../../utils/helpres';
import moment from 'moment-timezone';

const getAllCredits = (params) => async (dispatch) => {
  dispatch({ type: 'GET_CREDITS_REQUESTING' });
  try {
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    const { search } = params || {
      search: '',
    };
    const { start_date } = params || {
      start_date: '',
    };
    const { end_date } = params || {
      end_date: '',
    };
    const { status } = params || {
      status: '',
    };

    let url = `/api/credit?limit=${limit}&current_page=${currentPage}`;
    if (start_date && start_date !== '') url += `&start_date=${start_date}`;
    if (end_date && end_date !== '')
      url += `&end_date=${moment(moment(end_date).add(1, 'days').format('YYYY-MM-DD'))
        .tz('UTC')
        .utc()
        .format('YYYY-MM-DD')}`;
    if (status && status !== '') url += `&status=${status}`;
    if (search && search !== '') url += `&search=${search}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_CREDITS_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_CREDITS_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const changeSearchedStringCredits = (params) => ({
  type: 'UPDATE_SEARCH_CREDITS_PARAMS',
  params: params,
});
const submitCreditForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_CREDIT_REQUESTING' });
  const { status } = values;
  let request = {
    status,
  };
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/credit/update/${values.id} : 'create'}`,
      data: request,
    });
    const incompletePatient = !!(
      data.type_id === '' ||
      data.type === '' ||
      data.submitted_in === ''
    );
    if (!data.error) {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={`Crédit ${values.id ? 'modifée' : 'créée'} avec succès`}
          />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`Patient a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      if (incompletePatient)
        toast(
          ({ closeToast }) => (
            <ToastAction type="warning" message="Crédit est incomplète !" />
          ),
          { hideProgressBar: true },
        );
      dispatch({
        type: 'UPDATE_CREDIT_SUCCESS',
        data: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_CREDIT_FAILURE',
        ErrorMessage: data.message,
        data: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={data ? data.message : `Échec de création Crédit`}
        />
      ),
      { hideProgressBar: true },
    );
    //  toast.error(`Patient non ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'UPDATE_CREDIT_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: values,
    });
  }
};

const deleteCredit = (id) => async (dispatch) => {
  dispatch({ type: 'DELETE_CREDITS_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/credit/delete/${id}`,
    });
    if (data.type === 'success') {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Crédit supprimé avec succès" />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`File a été supprimée `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'DELETE_CREDITS_SUCCESS',
        ErrorMessage: data.message,
        data: true,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'DELETE_CREDITS_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec de suppression du crédit" />
      ),
      {
        hideProgressBar: true,
      },
    );
    //.error(`File non supprimée`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'DELETE_CREDITS_FAILURE',
      err: e.message,
    });
  }
};
export default {
  getAllCredits,
  deleteCredit,
  changeSearchedStringCredits,
  submitCreditForm,
};
