import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { isHasPermission } from '../utils/helpres';

export default class Empty_container extends Component {
  render() {
    const { title, hide, user, permission, body, btn_name, url } = this.props;

    return (
      <div style={{ marginTop: '10px' }} className="empty_bilan_container">
        <div className="empty_container">
          <div className="empty_container_title">{title}</div>
          <div className="empty_container_body">
            <span id="empty_container_text">{body}</span>
            {!hide && isHasPermission(user.login_access.permissions, permission) ? (
              url ? (
                <Link to={url}>
                  <div className="empty_container_btn">
                    <svg
                      width="11"
                      height="12"
                      viewBox="0 0 11 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.7383 6.95557H6.23828V11.4556H4.76172V6.95557H0.261719V5.479H4.76172V0.979004H6.23828V5.479H10.7383V6.95557Z"
                        fill="#5446DB"
                      />
                    </svg>{' '}
                    <span>{btn_name}</span>
                  </div>
                </Link>
              ) : (
                <div
                  className="empty_container_btn"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.props.onClick()}
                >
                  <svg
                    width="11"
                    height="12"
                    viewBox="0 0 11 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.7383 6.95557H6.23828V11.4556H4.76172V6.95557H0.261719V5.479H4.76172V0.979004H6.23828V5.479H10.7383V6.95557Z"
                      fill="#5446DB"
                    />
                  </svg>{' '}
                  <span>{btn_name}</span>
                </div>
              )
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}
