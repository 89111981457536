import React from 'react';

export default function HeaderList({ headers }) {
  return (
    <div
      style={{
        cursor: 'default',
        background: '#f8f9fc',
        marginBottom: 10,
      }}
      className="rendez_vous_list_body_item_container"
    >
      <div
        style={{
          marginLeft: 10,
        }}
        className="rendez_vous_list_body_item"
      >
        {' '}
        {headers.map((item, index) => {
          if (item.type === 'date')
            return (
              <div
                style={{ width: '15%' }}
                className="rv_list_body_sub_item"
                id="time_container_indis"
              >
                <span id="start_time"> {item.name}</span>
              </div>
            );
          else if (item.type === 'string')
            return (
              <div
                style={{ width: item.width || '25%' }}
                className="rv_list_body_sub_item rv_details"
              >
                <div style={{ paddingLeft: 0 }} className="rv_text_details">
                  <span>{item.name}</span>
                </div>
              </div>
            );
          else if (item.type === 'text')
            return (
              <div style={{ width: '30%', paddingLeft: 0 }} className="rv_text_details">
                <span>{item.name}</span>
              </div>
            );
          else
            return (
              <div
                key={index}
                style={{ width: item.width }}
                className="rv_list_body_sub_item"
                id="time_container_indis"
              >
                <div style={{ paddingLeft: 0 }} className="rv_text_details">
                  <span>{item.name}</span>
                </div>
              </div>
            );
        })}
      </div>
    </div>
  );
}
