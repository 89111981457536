import { apiCall } from '../../../js/actions';
import { getStatusError } from '../../../utils/helpres';

const getPatientById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_PATIENT_REQUESTING' });
  try {
    let url = `/api/patient/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_PATIENT_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_PATIENT_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getPatientRecordById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_PATIENT_RECORD_REQUESTING' });
  try {
    let url = `/api/patient-record/patient/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_PATIENT_RECORD_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_PATIENT_RECORD_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getPatients = (params) => async (dispatch) => {
  dispatch({ type: 'GET_PATIENTS_REQUESTING' });
  try {
    const { cnam } = params || {
      cnam: '',
    };
    const { phone } = params || {
      phone: '',
    };
    const { last_name } = params || {
      last_name: '',
    };
    const { city } = params || {
      city: '',
    };
    const { birthday } = params || {
      birthday: '',
    };
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    const { search } = params || {
      search: '',
    };
    let url = `/api/patients?limit=${limit}&current_page=${currentPage}`;
    if (search !== '') url += `&search=${search}`;
    if (phone !== '') url += `&phone=${phone}`;
    if (last_name !== '') url += `&last_name=${last_name}`;
    if (city !== '') url += `&city=${city}`;
    if (birthday !== '') url += `&birthday=${birthday}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_PATIENTS_SUCCESS',
      data: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_PATIENTS_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getPatientRecordsByDoctor = (params) => async (dispatch) => {
  dispatch({ type: 'GET_PATIENTS_RECORDS_BY_DOCTOR_REQUESTING' });
  try {
    const { search } = params || {
      search: '',
    };
    const { phone } = params || {
      phone: '',
    };
    const { first_name } = params || {
      first_name: '',
    };
    const { city } = params || {
      city: '',
    };
    const { birthday } = params || {
      birthday: '',
    };
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    let url = `/api/patients-record-practitioner?limit=${limit}&current_page=${currentPage}`;
    if (search !== '') url += `&search=${search}`;
    if (phone !== '') url += `&phone=${phone}`;
    if (first_name !== '') url += `&first_name=${first_name}`;
    if (city !== '') url += `&city=${city}`;
    if (birthday !== '') url += `&birthday=${birthday}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_PATIENTS_RECORDS_BY_DOCTOR_SUCCESS',
      data: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_PATIENTS_RECORDS_BY_DOCTOR_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const changeSearchedString = (params) => ({
  type: 'UPDATE_SEARCH_PATIENT_PARAMS',
  params: params,
});

const initialState = () => ({
  type: 'INITIAL_STATE',
});

export default {
  getPatientById,
  changeSearchedString,
  getPatientRecordById,
  getPatients,
  initialState,
  getPatientRecordsByDoctor,
};
