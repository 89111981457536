import { apiCall } from '../../../js/actions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastAction from '../../../components/ToastAction';
import React from 'react';
import { getStatusError } from '../../../utils/helpres';

const submitDiplomaForm = (values) => async (dispatch) => {
  dispatch({ type: 'ACTION_DIPLOME_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'patch',
      url: `/api/diploma/create/or/update`,
      data: values,
    });
    if (!data.error) {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Expertises modifiées avec succès" />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`Diplome ${values.id ? 'modifié' : 'ajouté'} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_DIPLOME_SUCCESS',
        data: data,
      });
    } else {
      toast(
        ({ closeToast }) => <ToastAction color="warning" message={data.data.message} />,
        { hideProgressBar: true },
      );
      //toast.warn(data.data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_DIPLOME_FAILURE',
        ErrorMessage: data.message,
        data: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={`${values.id ? 'Échec de modification de diplôme' : 'Échec d’ajout de diplôme'} `}
        />
      ),
      { hideProgressBar: true },
    );
    //  toast.error(`Diplome n'est pas ${values.id ? 'modifié' : 'ajouté'} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'ACTION_DIPLOME_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: values,
    });
  }
};

const submitTrainingsForm = (values) => async (dispatch) => {
  try {
    const { data } = await apiCall({
      method: 'patch',
      url: `/api/training/create/or/update`,
      data: values,
    });
    if (!data.error) {
      /*toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={`Formation ${values.id ? 'modifié' : 'ajouté'} `}
          />
        ),
        { hideProgressBar: true },
      );
      // toast.success(`Formation ${values.id ? 'modifié' : 'ajouté'} `, {position: toast.POSITION.TOP_CENTER});
    */ dispatch({
        type: 'ACTION_FORMATION_SUCCESS',
        data: data,
      });
    } else {
      /* toast(
        ({ closeToast }) => <ToastAction type="warning" message={data.data.message} />,
        { hideProgressBar: true },
      );*/
      //toast.warn(data.data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_FORMATION_FAILURE',
        ErrorMessage: data.message,
        data: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    /* toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={`Formation n'est pas ${values.id ? 'modifié' : 'ajouté'} `}
        />
      ),
      { hideProgressBar: true },
    );*/
    //toast.error(`Formation n'est pas${values.id ? 'modifié' : 'ajouté'} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'ACTION_FORMATION_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: values,
    });
  }
};

const deleteDiploma = (id) => async (dispatch) => {
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/diploma/${id}`,
    });
    if (!data.error) {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Diplôme supprimé avec succès" />
        ),
        { hideProgressBar: true },
      );
      // toast.success(`Diplome supprimé`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_DELETE_DIPLOME_SUCCESS',
        data: data,
      });
    } else {
      toast(
        ({ closeToast }) => <ToastAction type="warning" message={data.data.message} />,
        { hideProgressBar: true },
      );
      //toast.warn(data.data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_DELETE_DIPLOME_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec de suppression de diplôme" />
      ),
      { hideProgressBar: true },
    );
    // toast.error(`Diplome n'est pas supprimé`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'ACTION_DELETE_DIPLOME_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: false,
    });
  }
};

const deleteTrainings = (id) => async (dispatch) => {
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/training/${id}`,
    });
    if (!data.error) {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Formation supprimée avec succès" />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`Formation supprimé`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_DELETE_FORMATION_SUCCESS',
        data: data,
      });
    } else {
      toast(
        ({ closeToast }) => <ToastAction type="warning" message={data.data.message} />,
        { hideProgressBar: true },
      );
      //toast.warn(data.data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_DELETE_FORMATION_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec de suppression de formation" />
      ),
      { hideProgressBar: true },
    );
    // toast.error(`Formation n'est pas supprimé`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'ACTION_DELETE_FORMATION_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: false,
    });
  }
};

const updatePassword = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_PASSWORD_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'post',
      url: `/api/change-password/${values.id}`,
      data: values,
    });
    if (data.type !== 'error') {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Mot de passe modifié avec succès" />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`Mot de passe a été modifié`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_PASSWORD_SUCCESS',
        data: data,
      });
    } else {
      toast(
        ({ closeToast }) => <ToastAction type="warning" message={data.data.message} />,
        { hideProgressBar: true },
      );
      //toast.warn(data.data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_PASSWORD_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={data.message ? data.message : `Échec de modification de mot de passe`}
        />
      ),
      { hideProgressBar: true },
    );
    // toast.error(data.message ? data.message : `Mot de passe n'est pas modifié`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'UPDATE_PASSWORD_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: false,
    });
  }
};

const verifyPassword = (values) => async (dispatch) => {
  dispatch({ type: 'VERIFY_PASSWORD_REQUESTING' });
  try {
    const { data } = await apiCall({
      method: 'post',
      url: `/api/practitioner/verify/password`,
      data: values,
    });
    if (data.type !== 'error') {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message="Mot de passe vérifié avec succès" />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`Mot de passe a été modifié`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'VERIFY_PASSWORD_SUCCESS',
        data: data,
      });
    } else {
      toast(
        ({ closeToast }) => <ToastAction type="warning" message={data.data.message} />,
        { hideProgressBar: true },
      );
      //toast.warn(data.data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'VERIFY_PASSWORD_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={data.message ? data.message : `Échec de vérification de mot de passe`}
        />
      ),
      { hideProgressBar: true },
    );
    // toast.error(data.message ? data.message : `Mot de passe n'est pas modifié`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'VERIFY_PASSWORD_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: false,
    });
  }
};

const getMotifs = (type) => async (dispatch) => {
  dispatch({ type: 'GET_MOTIFS_REQUESTING' });
  try {
    let url = `/api/motifs?type=${type}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_MOTIFS_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_MOTIFS_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const addUserMotif = (values) => async (dispatch) => {
  dispatch({ type: 'ADD_MOTIF_USER_REQUESTING' });
  try {
    const url = `/api/motif/user`;
    const { data } = await apiCall({
      method: 'post',
      url,
      data: values,
    });
    toast(
      ({ closeToast }) => (
        <ToastAction type="success" message="Modification effectuée avec succès" />
      ),
      {
        hideProgressBar: true,
      },
    );
    dispatch({ type: 'ADD_MOTIF_BY_USER_SUCCESS', data: data.data });
  } catch (err) {
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec de modification" />
      ),
      { hideProgressBar: true },
    );
    const { response } = err;
    if (response && response.data && response.data.type === 'error')
      dispatch({
        type: 'ADD_MOTIF_BY_USER_FAILURE',
        err: err.message,
        status: getStatusError(err),
      });
  }
};
const getUserMotifs = () => async (dispatch) => {
  dispatch({ type: 'GET_USER_MOTIFS_REQUESTING' });
  try {
    let url = `/api/list/user/motifs`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_USER_MOTIFS_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_USER_MOTIFS_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const submitMotifForm = (values) => async (dispatch) => {
  const {
    actif,
    duration,
    price_with_out_cnam,
    price_medical_prescription,
    color,
    label,
    complement,
  } = values;
  let request = {
    actif,
    duration,
    price_with_out_cnam,
    price_medical_prescription,
    color,
    label,
    complement,
  };
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/motif/${values.id || ''}`,
      data: request,
    });
    if (!data.error) {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={`Pathologie ${values.id ? 'modifiée' : 'créé'} avec succès `}
          />
        ),
        { hideProgressBar: true },
      );
      // toast.success(`Motif ${values.id ? 'modifié' : 'ajouté'} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_MOTIF_SUCCESS',
        data: data,
      });
    } else {
      toast(
        ({ closeToast }) => <ToastAction type="warning" message={data.data.message} />,
        { hideProgressBar: true },
      );
      //toast.warn(data.data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_MOTIF_FAILURE',
        ErrorMessage: data.message,
        data: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction
          type="error"
          message={`Échec de ${values.id ? 'modification' : 'création'}  de pathologie `}
        />
      ),
      { hideProgressBar: true },
    );
    //toast.error(`Motif n'est pas ${values.id ? 'modifié' : 'ajouté'} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'ACTION_MOTIF_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: values,
    });
  }
};

const deleteMotif = (id) => async (dispatch) => {
  try {
    const { data } = await apiCall({
      method: 'delete',
      url: `/api/motif/${id}`,
    });
    if (!data.error) {
      toast(
        ({ closeToast }) => (
          <ToastAction color="success" message="Pathologie désactivée avec succès" />
        ),
        { hideProgressBar: true },
      );
      //  toast.success(`Motif déactivé`, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_DELETE_MOTIF_SUCCESS',
        data: data,
      });
    } else {
      toast(
        ({ closeToast }) => <ToastAction color="warning" message={data.data.message} />,
        { hideProgressBar: true },
      );
      // toast.warn(data.data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'ACTION_DELETE_MOTIF_FAILURE',
        ErrorMessage: data.message,
        data: false,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction color="warning" message="Échec de désactivation de pathologie" />
      ),
      { hideProgressBar: true },
    );
    // toast.error(`Motif n'est pas déactivé`, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'ACTION_DELETE_MOTIF_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: false,
    });
  }
};
export default {
  submitDiplomaForm,
  submitTrainingsForm,
  deleteDiploma,
  deleteTrainings,
  updatePassword,
  deleteMotif,
  submitMotifForm,
  getMotifs,
  verifyPassword,
  getUserMotifs,
  addUserMotif,
};
