import { apiCall } from '../../../js/actions';
import { toast } from 'react-toastify';
import { getStatusError, phoneNumberToIntlFormat } from '../../../utils/helpres';
import ToastAction from '../../../components/ToastAction';
import React from 'react';

const getPatientById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_PATIENT_REQUESTING' });
  try {
    let url = `/api/patient/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_PATIENT_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_PATIENT_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getPatientRecordById = (id) => async (dispatch) => {
  dispatch({ type: 'GET_PATIENT_RECORD_REQUESTING' });
  try {
    let url = `/api/patient-record/patient/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_PATIENT_RECORD_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_PATIENT_RECORD_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getBilanByPatientRecordId = (params, id) => async (dispatch) => {
  dispatch({ type: 'GET_BILAN_BY_PATIENT_RECORD_REQUESTING' });
  try {
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    const { search } = params || {
      search: '',
    };
    let url = `/api/bilan/pateint-record/${id}?limit=${limit}&current_page=${currentPage}`;
    if (search && search !== '') url += `&search=${search}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_BILAN_BY_PATIENT_RECORD_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_BILAN_BY_PATIENT_RECORD_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getProtocoleByPatientRecordId = (params, id) => async (dispatch) => {
  dispatch({ type: 'GET_PROTOCOLE_BY_PATIENT_RECORD_REQUESTING' });
  try {
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    const { search } = params || {
      search: '',
    };
    let url = `/api/protocole/pateint-record/${id}?limit=${limit}&current_page=${currentPage}`;
    if (search && search !== '') url += `&search=${search}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_PROTOCOLE_BY_PATIENT_RECORD_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_PROTOCOLE_BY_PATIENT_RECORD_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getPatients = (params) => async (dispatch) => {
  dispatch({ type: 'GET_PATIENTS_REQUESTING' });
  try {
    const { cnam } = params || {
      cnam: '',
    };
    const { phone } = params || {
      phone: '',
    };
    const { last_name } = params || {
      last_name: '',
    };
    const { city } = params || {
      city: '',
    };
    const { birthday } = params || {
      birthday: '',
    };
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    const { search } = params || {
      search: '',
    };
    let url = `/api/patients?limit=${limit}&current_page=${currentPage}`;
    if (search !== '') url += `&search=${search}`;
    if (phone !== '') url += `&phone=${phone}`;
    if (last_name !== '') url += `&last_name=${last_name}`;
    if (city !== '') url += `&city=${city}`;
    if (birthday !== '') url += `&birthday=${birthday}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_PATIENTS_SUCCESS',
      data: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_PATIENTS_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const getPatientRecordsByDoctor = (params) => async (dispatch) => {
  dispatch({ type: 'GET_PATIENTS_RECORDS_BY_DOCTOR_REQUESTING' });
  try {
    const { search } = params || {
      search: '',
    };
    const { phone } = params || {
      phone: '',
    };
    const { first_name } = params || {
      first_name: '',
    };
    const { city } = params || {
      city: '',
    };
    const { birthday } = params || {
      birthday: '',
    };
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    let url = `/api/patients-record-practitioner?limit=${limit}&current_page=${currentPage}`;
    if (search !== '') url += `&search=${search}`;
    if (phone !== '') url += `&phone=${phone}`;
    if (first_name !== '') url += `&first_name=${first_name}`;
    if (city !== '') url += `&city=${city}`;
    if (birthday !== '') url += `&birthday=${birthday}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_PATIENTS_RECORDS_BY_DOCTOR_SUCCESS',
      data: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_PATIENTS_RECORDS_BY_DOCTOR_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const submitAddPatientRecordForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_PATIENT_RECORD_REQUESTING' });
  const { patient_id, doctor_id } = values;
  let request = {
    patient_id,
    doctor_id,
  };
  try {
    const { data } = await apiCall({
      method: 'post',
      url: `/api/patient-record`,
      data: request,
    });
    if (!data.error) {
      toast(
        ({ closeToast }) => (
          <ToastAction type="success" message={`Fiche patient créée avec succès`} />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`Patient a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_PATIENT_RECORD_SUCCESS',
        data: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_PATIENT_RECORD_FAILURE',
        ErrorMessage: data.message,
        data: values,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response && response.data) {
      toast(
        ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
        { hideProgressBar: true },
      );
    }
    dispatch({
      type: 'UPDATE_PATIENT_RECORD_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      ///err: data.errors || data.message,
      data: values,
    });
  }
};

const submitPatientForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_PATIENT_REQUESTING' });
  const {
    pr_number,
    address_patient,
    first_name,
    job,
    last_name,
    practitioner,
    phone,
    email,
    profession,
    cnam_key,
    cnam_root,
    birthday,
    regime,
    gender,
  } = values;
  let request = {
    first_name: first_name.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase(),
    ),
    address_patient: address_patient || {},
    last_name: last_name.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
      letter.toUpperCase(),
    ),
    email,
    pr_number,
    profession,
    phone,
    cnam_key,
    cnam_root,
    job,
    practitioner,
    regime,
    birthday,
    gender,
  };
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/patient/${values.id || ''}`,
      data: request,
    });
    const incompletePatient = !!(
      data.first_name === '' ||
      data.last_name === '' ||
      data.email === ''
    );
    if (!data.error) {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={`Fiche patient ${
              !values.type
                ? values.id
                  ? 'modifiée avec succès'
                  : 'créée avec succès'
                : ''
            } `}
          />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`Patient a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      if (incompletePatient)
        toast(({ closeToast }) => <ToastAction type="warning" message="" />, {
          hideProgressBar: true,
        });
      dispatch({
        type: 'UPDATE_PATIENT_SUCCESS',
        data: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      // toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_PATIENT_FAILURE',
        ErrorMessage: data.message,
        data: values,
      });
    }
  } catch (e) {
    const { response } = e;
    if (response && response.data) {
      toast(
        ({ closeToast }) => <ToastAction type="error" message={response.data.message} />,
        { hideProgressBar: true },
      );
    }
    dispatch({
      type: 'UPDATE_PATIENT_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      ///err: data.errors || data.message,
      data: values,
    });
  }
};

const submitPatientRecordForm = (values) => async (dispatch) => {
  dispatch({ type: 'UPDATE_PATIENT_RECORD_REQUESTING' });
  const { note } = values;
  let request = {
    note,
  };
  try {
    const { data } = await apiCall({
      method: values.id ? 'patch' : 'post',
      url: `/api/patient-record/${values.id || ''}`,
      data: request,
    });
    if (!data.error) {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="success"
            message={`Note patient ${
              !values.type ? (values.id ? 'modifiée avec succès' : 'ajoutée') : ''
            } `}
          />
        ),
        { hideProgressBar: true },
      );
      //toast.success(`Note a été ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_PATIENT_RECORD_SUCCESS',
        data: data,
      });
    } else {
      toast(({ closeToast }) => <ToastAction type="warning" message={data.message} />, {
        hideProgressBar: true,
      });
      //toast.warn(data.message, {position: toast.POSITION.TOP_CENTER});
      dispatch({
        type: 'UPDATE_PATIENT_RECORD_FAILURE',
        ErrorMessage: data.message,
        data: values,
      });
    }
  } catch (e) {
    const { response } = e;
    const { data } = response;
    toast(
      ({ closeToast }) => (
        <ToastAction type="error" message="Échec de modification de note patient" />
      ),
      { hideProgressBar: true },
    );
    // toast.error(`Note n'est pas ${!values.type ? values.id ? 'modifié' : 'ajouté' : ''} `, {position: toast.POSITION.TOP_CENTER});
    dispatch({
      type: 'UPDATE_PATIENT_FAILURE',
      msg: 'Un problème est survenu au niveau du serveur. Veuillez essayer de nouveau.',
      err: data.errors || data.message,
      data: values,
    });
  }
};

const changeSearchedString = (params) => ({
  type: 'UPDATE_SEARCH_PATIENT_PARAMS',
  params: params,
});
const changeSearchedStringBilan = (params) => ({
  type: 'UPDATE_SEARCH_BILAN_PATIENT_PARAMS',
  paramsBilan: params,
});

const initialState = () => ({
  type: 'INITIAL_STATE_PATIENT',
});

const changeSearchedStringBill = (params) => ({
  type: 'UPDATE_SEARCH_BILL_PATIENT_PARAMS',
  paramsBilan: params,
});
const getBillByPatientRecordId = (params, id) => async (dispatch) => {
  dispatch({ type: 'GET_BILL_BY_PATIENT_RECORD_REQUESTING' });
  try {
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    const { search } = params || {
      search: '',
    };
    let url = `/api/list/bill/patient-record/${id}?limit=${limit}&current_page=${currentPage}`;
    if (search && search !== '') url += `&search=${search}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_BILL_BY_PATIENT_RECORD_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_BILL_BY_PATIENT_RECORD_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};

const changeSearchedStringReceipt = (params) => ({
  type: 'UPDATE_SEARCH_RECEIPT_PATIENT_PARAMS',
  paramsBilan: params,
});
const getReceiptByPatientRecordId = (params, id) => async (dispatch) => {
  dispatch({ type: 'GET_RECEIPT_BY_PATIENT_RECORD_REQUESTING' });
  try {
    const { currentPage } = params || {
      currentPage: 1,
    };
    const { limit } = params || {
      limit: '',
    };
    const { search } = params || {
      search: '',
    };
    let url = `/api/list/receipt/patient-record/${id}?limit=${limit}&current_page=${currentPage}`;
    if (search && search !== '') url += `&search=${search}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_RECEIPT_BY_PATIENT_RECORD_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_RECEIPT_BY_PATIENT_RECORD_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
//patient-statistical
const getPatientStatistical = (id) => async (dispatch) => {
  dispatch({ type: 'GET_PATIENT_STATISTICAL_REQUESTING' });
  try {
    let url = `/api/patient-statistical/${id}`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_PATIENT_STATISTICAL_SUCCESS',
      data: data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_PATIENT_STATISTICAL_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
const getLastPatientRecord = () => async (dispatch) => {
  dispatch({ type: 'GET_LAST_PATIENT_RECORD_REQUESTING' });
  try {
    let url = `/api/get/last/patient_record`;
    const { data } = await apiCall({
      method: 'get',
      url,
    });
    dispatch({
      type: 'GET_LAST_PATIENT_RECORD_SUCCESS',
      data: data.data,
    });
  } catch (e) {
    dispatch({
      type: 'GET_LAST_PATIENT_RECORD_FAILURE',
      err: e.message,
      status: getStatusError(e),
    });
  }
};
export default {
  getLastPatientRecord,
  changeSearchedStringReceipt,
  getReceiptByPatientRecordId,
  changeSearchedStringBill,
  getBillByPatientRecordId,
  getPatientById,
  changeSearchedString,
  changeSearchedStringBilan,
  getPatientRecordById,
  getPatients,
  submitPatientForm,
  initialState,
  submitPatientRecordForm,
  getPatientRecordsByDoctor,
  getBilanByPatientRecordId,
  submitAddPatientRecordForm,
  getProtocoleByPatientRecordId,
  getPatientStatistical,
};
