import React, { Component } from 'react';
import MissionsManagement from './composents/missions-management';
import SettingMenuItemsProfil from '../../components/settingMenuItemsProfil';
import EmployeeManagement from './composents/employee-management';
import SecretarialManagement from './composents/secretarial-management';
import ManagementOfOtherEmployees from './composents/management-of-other-employees';
import ManagementOfPathologies from './composents/management-of-pathologies';
import AbsenceManagement from './composents/absence-management';
import CompanyManagement from './composents/company-managment';
import Holidays from './composents/holidays';
import Permission from './composents/permission';
import { connect } from 'react-redux';
import actions from '../../js/actions';
import actionsM from '../messenger/store/actions';
import SimpleReactValidator from 'simple-react-validator';

import '../profile/Abonnement/index.css';
import '../profile/DiplomeFormation/index.css';
import '../profile/InfoGenerale/index.css';
import '../profile/Notifications/index.css';
import '../profile/Securities/index.css';
import '../profile/index.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import actionsJs from '../../js/actions';
import Medecins from './composents/medecins';
import { isHasPermission } from '../../utils/helpres';
import Archive from './composents/archive';
import ItemComponents from './composents/itemComponents';
import './new-css.css';
const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  error: state.AppReducer.error,
  isLoadingArchive: state.AppReducer.isLoadingArchive,
  archives: state.AppReducer.archives,
  specialities: state.AppReducer.specialities,
  isLoadingSpecialities: state.AppReducer.isLoadingSpecialities,
  sandwich_menu_btn: state.AppReducer.sandwich_menu_btn,
  isLoadingSecretaries: state.AppReducer.isLoadingSecretaries,
  secretaries: state.AppReducer.secretaries,
  isLoadingAssistants: state.AppReducer.isLoadingAssistants,
  assistants: state.AppReducer.assistants,
  doctors: state.MessengerReducer.doctors,
  isLoadingMissions: state.AppReducer.isLoadingMissions,
  missions: state.AppReducer.missions,
  isLoadingPermission: state.AppReducer.isLoadingPermission,
  permissions: state.AppReducer.permissions,
  isLoadingMedecins: state.AppReducer.isLoadingMedecins,
  medecins: state.AppReducer.medecins,
  employeeManagement: state.AppReducer.employeeManagement,
  isLoadingHolidayEmployeeManagement: state.AppReducer.isLoadingHolidayEmployeeManagement,
  isLoadingOffice: state.AppReducer.isLoadingOffice,
  office: state.AppReducer.office,
  isLoadingAbsenses: state.AppReducer.isLoadingAbsenses,
  params: state.AgendaReducer.params,
});

const mapDispatchToProps = (dispatch) => ({
  getAllDoctors: (params) => dispatch(actionsM.getAllDoctors(params)),
  changeActiveItemSideBarSettings: (item, data, create) =>
    dispatch(actionsJs.changeActiveItemSideBarSettings(item, data, create)),
  getEmployeeManagement: (type) => dispatch(actionsJs.getEmployeeManagement(type)),
  getHolidayUser: (id) => dispatch(actionsJs.getHolidayUser(id)),
  getAbsenses: (params, id) => dispatch(actionsJs.getAbsenses(params, id)),
  getUserById: (id) => dispatch(actions.getUserById(id)),
  getSpecialities: () => dispatch(actionsJs.getSpecialities()),
  getSecretaries: () => dispatch(actionsJs.getSecretaries()),
  getAssistants: () => dispatch(actionsJs.getAssistants()),
  getMissions: () => dispatch(actionsJs.getMissions()),
  getPermissions: () => dispatch(actionsJs.getPermissions()),
  getMedecins: () => dispatch(actionsJs.getMedecins()),
  getAllArchive: () => dispatch(actionsJs.getAllArchive()),
  getHoliday: () => dispatch(actionsJs.getHoliday()),
  changeActiveItemSideBarCompta: (data) =>
    dispatch(actionsJs.changeActiveItemSideBarCompta(data)),
  getOfficeByIdDoctor: () => {
    dispatch(actionsJs.getOfficeByIdDoctor());
  },
});
const actionCenter = [
  {
    actions: [
      {
        id: 0,
        title: 'Gestion de la société',
        description: 'Détails proprietaire & centre',
        icon: 'fas fa-address-card',
        permission: 'COMPANY_MANAG',
        path: '/company-management',
      },
      {
        id: 1,
        title: 'Gestion des collaborateurs',
        description: 'Autres kinés dans le centre',
        icon: 'fas fa-hospital-user',
        permission: 'PARTNERS_MANAG',
        path: '/employee-management',
      },
      {
        id: 2,
        title: 'Gestion de secrétariat',
        description: 'Ajouter, modifier une secrétaire',
        icon: 'fa fa-user-nurse',
        permission: 'SECRETERIES_MANAG',
        path: '/secretarial-management',
      },
      {
        id: 3,
        title: 'Gestion des assistants',
        description: 'Ajouter, modifier un assistant',
        icon: 'fa fa-users',
        permission: 'ASSISTANTS_MANAG',
        path: '/assistants-management',
      },
    ],
  },
  {
    actions: [
      {
        id: 4,
        title: 'Gestion des pathologies',
        description: 'Ajouter, modifier une pathologie',
        icon: 'fa fa-dot-circle',
        permission: 'PATHOLOGIES_MANAG',
        path: '/pathologies-management',
      },
      {
        id: 5,
        title: 'Gestion des absences',
        description: 'Gérer les absences et les congés',
        icon: 'fas fa-adjust',
        permission: 'ABSENCES_MANAG',
        path: '/absence-management',
      },
      {
        id: 12,
        title: 'Gestion des jours fériés',
        description: 'Gestion des jours fériés',
        icon: 'fas fa-adjust',
        permission: 'HOLIDAY_ACCESS',
        path: '/holidays',
      },
      {
        id: 6,
        title: 'Gestion des missions',
        description: 'Historique des missions',
        icon: 'fa fa-laptop-house',
        permission: 'MISSIONS_MANAG',
        path: '/missions-management',
      },
    ],
  },
  {
    actions: [
      {
        id: 7,
        icon: 'fa fa-user-md',
        title: 'Gestion des médecins ',
        description: 'Les médecins prescripteurs',
        permission: 'PRESCRIBING_DOCTORS_MANAG',
        path: '/medecins-management',
      },
      {
        id: 8,
        icon: 'fas fa-user-lock',
        title: 'Gestion des permissions',
        description: 'Affectation des permissions',
        permission: 'PERMISSIONS_MANAG',
        path: '/permissions',
      },
      {
        id: 9,
        icon: 'fas fa-hand-paper',
        description: 'Historique d’activités',
        title: 'Registre d’activités',
        permission: 'REGISTER_MANAG',
        path: '/archive',
      },
    ],
  },
];

class Settings extends Component {
  constructor(props) {
    super(props);
    props.getOfficeByIdDoctor();
    props.getUserById(props.user.id);
    props.changeNamePage('Gestion de la société');
    props.changeActiveItemSideBar(8);
    props.getMedecins();
    props.getAssistants();
    props.getSecretaries();
    props.getPermissions();
    props.getEmployeeManagement(null);
    props.changeActiveItemSideBarCompta(null);
    props.getMissions();
    this.state = {
      item:
        props.location.state && props.location.state.item
          ? props.location.state.item
          : isHasPermission(props.user.login_access.permissions, 'COMPANY_MANAG')
          ? 0
          : isHasPermission(props.user.login_access.permissions, 'PARTNERS_MANAGs')
          ? 1
          : isHasPermission(props.user.login_access.permissions, 'SECRETERIES_MANAG')
          ? 2
          : isHasPermission(props.user.login_access.permissions, 'ASSISTANTS_MANAG')
          ? 3
          : isHasPermission(props.user.login_access.permissions, 'PATHOLOGIES_MANAG')
          ? 4
          : isHasPermission(props.user.login_access.permissions, 'ABSENCES_MANAG')
          ? 5
          : isHasPermission(props.user.login_access.permissions, 'MISSIONS_MANAG')
          ? 6
          : isHasPermission(
              props.user.login_access.permissions,
              'PRESCRIBING_DOCTORS_MANAG',
            )
          ? 7
          : isHasPermission(props.user.login_access.permissions, 'PERMISSIONS_MANAG')
          ? 8
          : 9,
      initialValues: {
        ...props.initialValues,
        alert: false,
      },
      activeTab: '1',
      doctor: null,
      showInfo:
        !props.location.state &&
        isHasPermission(props.user.login_access.permissions, 'COMPANY_MANAG')
          ? false
          : false,
      showEmployee:
        props.location.state &&
        props.location.state.item === 1 &&
        isHasPermission(props.user.login_access.permissions, 'PARTNERS_MANAG')
          ? false
          : false,
      showMedecins: !props.location.state
        ? isHasPermission(
            props.user.login_access.permissions,
            'PRESCRIBING_DOCTORS_MANAG',
          )
          ? false
          : false
        : false,
      showSecretarialManagement: !props.location.state
        ? isHasPermission(props.user.login_access.permissions, 'SECRETERIES_MANAG')
          ? false
          : false
        : false,
      showManagementOfPathologies: !props.location.state
        ? isHasPermission(props.user.login_access.permissions, 'PATHOLOGIES_MANAG')
          ? false
          : false
        : false,
      showAbsenceManagement: !props.location.state
        ? isHasPermission(props.user.login_access.permissions, 'ABSENCES_MANAG')
          ? false
          : false
        : false,
      showMissionManagement:
        props.location.state &&
        props.location.state.item === 6 &&
        isHasPermission(props.user.login_access.permissions, 'MISSIONS_MANAG')
          ? false
          : false,
      showPermission:
        props.location.state &&
        props.location.state.item === 8 &&
        isHasPermission(props.user.login_access.permissions, 'PERMISSIONS_MANAG')
          ? false
          : false,
      showHoliday:
        !props.location.state &&
        isHasPermission(props.user.login_access.permissions, 'HOLIDAY_ACCESS')
          ? false
          : false,
      showManagementOfOtherEmployees:
        !props.location.state &&
        isHasPermission(props.user.login_access.permissions, 'ASSISTANTS_MANAG')
          ? false
          : false,
      file: null,
      isOpenToggleDiplome: false,
      isOpenToggleFormation: false,
      hasError: false,
    };

    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        // OR
        default: 'Cette information est requise.', // will override all messages
      },
    });
    this.HandelChangePage = this.HandelChangePage.bind(this);
    this.handleChangeFiles = this.handleChangeFiles.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goBackItem = this.goBackItem.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  HandelChangePage(item) {
    const { employeeManagement } = this.props;
    switch (item) {
      case 0:
        this.setState({
          showInfo: true,
          showManagementOfOtherEmployees: false,
          showEmployee: false,
          showSecretarialManagement: false,
          showManagementOfPathologies: false,
          showAbsenceManagement: false,
          showMissionManagement: false,
          showMedecins: false,
          showPermission: false,
          showArchive: false,
          showHoliday: false,
        });
        break;
      case 1:
        this.props.getEmployeeManagement(null);
        this.props.changeActiveItemSideBarSettings(1, false, true);
        this.setState({
          showInfo: false,
          showEmployee: true,
          showManagementOfOtherEmployees: false,
          showSecretarialManagement: false,
          showManagementOfPathologies: false,
          showAbsenceManagement: false,
          showMissionManagement: false,
          showMedecins: false,
          showPermission: false,
          showArchive: false,
          showHoliday: false,
        });
        break;
      case 2:
        this.props.changeActiveItemSideBarSettings(2, false, true);
        this.props.getSecretaries();
        this.setState({
          showInfo: false,
          showEmployee: false,
          showSecretarialManagement: true,
          showManagementOfPathologies: false,
          showManagementOfOtherEmployees: false,
          showAbsenceManagement: false,
          showMissionManagement: false,
          showMedecins: false,
          showPermission: false,
          showArchive: false,
          showHoliday: false,
        });
        break;
      case 3:
        this.props.changeActiveItemSideBarSettings(3, false, true);
        this.props.getAssistants();
        this.setState({
          showInfo: false,
          showEmployee: false,
          showSecretarialManagement: false,
          showManagementOfOtherEmployees: true,
          showManagementOfPathologies: false,
          showAbsenceManagement: false,
          showMissionManagement: false,
          showMedecins: false,
          showPermission: false,
          showArchive: false,
          showHoliday: false,
        });
        break;
      case 4:
        this.props.changeActiveItemSideBarSettings(4, false, false);
        this.setState({
          showInfo: false,
          showEmployee: false,
          showSecretarialManagement: false,
          showManagementOfPathologies: true,
          showManagementOfOtherEmployees: false,
          showAbsenceManagement: false,
          showMissionManagement: false,
          showMedecins: false,
          showPermission: false,
          showArchive: false,
          showHoliday: false,
        });
        break;
      case 5:
        this.props.getEmployeeManagement(true);
        this.props.changeActiveItemSideBarSettings(5, false, true);
        //this.props.getHoliday();
        if (employeeManagement && employeeManagement.length > 0) {
          this.setState({
            doctor: employeeManagement[0],
          });
          //this.props.getHolidayUser(employeeManagement[0].id);
          // this.props.getAbsenses(employeeManagement[0].id);
        }
        this.setState({
          showInfo: false,
          showEmployee: false,
          showManagementOfOtherEmployees: false,
          showSecretarialManagement: false,
          showManagementOfPathologies: false,
          showAbsenceManagement: true,
          showMissionManagement: false,
          showMedecins: false,
          showPermission: false,
          showArchive: false,
          showHoliday: false,
        });
        break;
      case 6:
        this.props.getMissions();
        this.props.getEmployeeManagement(true);
        this.props.changeActiveItemSideBarSettings(6, false, true);
        this.setState({
          showInfo: false,
          showEmployee: false,
          showManagementOfOtherEmployees: false,
          showSecretarialManagement: false,
          showManagementOfPathologies: false,
          showMissionManagement: true,
          showAbsenceManagement: false,
          showMedecins: false,
          showPermission: false,
          showArchive: false,
          showHoliday: false,
        });
        break;
      case 7:
        this.props.changeActiveItemSideBarSettings(7, false, true);
        this.props.getMedecins();
        this.props.getSpecialities();
        this.setState({
          showInfo: false,
          showEmployee: false,
          showManagementOfOtherEmployees: false,
          showSecretarialManagement: false,
          showManagementOfPathologies: false,
          showMissionManagement: false,
          showAbsenceManagement: false,
          showMedecins: true,
          showPermission: false,
          showArchive: false,
          showHoliday: false,
        });
        break;
      case 8:
        this.props.getAssistants();
        this.props.getSecretaries();
        this.props.getPermissions();
        this.props.getEmployeeManagement(true);
        this.props.changeActiveItemSideBarSettings(30, false, true);
        this.setState({
          showInfo: false,
          showEmployee: false,
          showManagementOfOtherEmployees: false,
          showSecretarialManagement: false,
          showManagementOfPathologies: false,
          showMissionManagement: false,
          showAbsenceManagement: false,
          showMedecins: false,
          showPermission: true,
          showArchive: false,
          showHoliday: false,
        });
        break;
      case 9:
        this.props.getAllArchive();
        this.setState({
          showInfo: false,
          showEmployee: false,
          showManagementOfOtherEmployees: false,
          showSecretarialManagement: false,
          showManagementOfPathologies: false,
          showMissionManagement: false,
          showAbsenceManagement: false,
          showMedecins: false,
          showPermission: false,
          showHoliday: false,
          showArchive: true,
        });
        break;
      case 12:
        this.props.changeActiveItemSideBarSettings(20, false, true);
        this.props.getHoliday();
        if (employeeManagement && employeeManagement.length > 0) {
          this.setState({
            doctor: employeeManagement[0],
          });
        }
        this.setState({
          showInfo: false,
          showEmployee: false,
          showManagementOfOtherEmployees: false,
          showSecretarialManagement: false,
          showManagementOfPathologies: false,
          showMissionManagement: false,
          showAbsenceManagement: false,
          showMedecins: false,
          showPermission: false,
          showHoliday: true,
          showArchive: false,
        });
        break;
      default:
        this.setState({
          showInfo: true,
          showEmployee: false,
          showSecretarialManagement: false,
          showManagementOfPathologies: false,
          showManagementOfOtherEmployees: false,
          showAbsenceManagement: false,
          showMedecins: false,
          showPermission: false,
          showArchive: false,
          showHoliday: false,
        });
    }
  }
  goBackItem(item) {
    this.setState({
      [item]: false,
    });
  }
  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }

  handleChangeFiles = (event, name) => {
    const fileUploaded = event.target.files[0];
    this.setState({
      [name]: fileUploaded,
    });
  };

  render() {
    const {
      getUser,
      isLoadingSecretaries,
      secretaries,
      isLoadingGetUser,
      last10Patient,
      sandwich_menu_btn,
      assistants,
      isLoadingMissions,
      missions,
      medecins,
      permissions,
      employeeManagement,
      office,
      isLoadingOffice,
      isLoadingHolidayEmployeeManagement,
      isLoadingAssistants,
      isLoadingMedecins,
      isLoadingPermission,
      user,
      archives,
      isLoadingArchive,
    } = this.props;
    const {
      doctor,
      file,
      showAbsenceManagement,
      showManagementOfPathologies,
      showInfo,
      showHoliday,
      showEmployee,
      showSecretarialManagement,
      showManagementOfOtherEmployees,
      showMissionManagement,
      showMedecins,
      showPermission,
      showArchive,
      item,
    } = this.state;
    return (
      <>
        {!showArchive &&
        !showPermission &&
        !showMedecins &&
        !showMissionManagement &&
        !showEmployee &&
        !showHoliday &&
        !showInfo &&
        !showManagementOfPathologies &&
        !showAbsenceManagement &&
        !showSecretarialManagement &&
        !showManagementOfOtherEmployees ? (
          <div
            style={{ backgroundColor: 'rgb(239 239 239)', padding: 35 }}
            className="container-bloc"
          >
            {actionCenter.map((item, index) => {
              return (
                <div key={index} className="row-bloc">
                  {item.actions.map((item2) => {
                    if (isHasPermission(user.login_access.permissions, item2.permission))
                      return (
                        <ItemComponents
                          HandelChangePage={this.HandelChangePage}
                          item={item2}
                        />
                      );
                  })}
                </div>
              );
            })}
          </div>
        ) : isLoadingGetUser ? (
          <CircularProgress
            disableShrink
            style={{ marginLeft: '45%', marginTop: '23%' }}
          />
        ) : (
          <div className="container_changeable_content">
            {showInfo &&
            isHasPermission(user.login_access.permissions, 'COMPANY_MANAG') ? (
              isLoadingOffice ? (
                <CircularProgress
                  disableShrink
                  style={{ marginLeft: '45%', marginTop: '23%' }}
                />
              ) : (
                <CompanyManagement
                  goBackItem={this.goBackItem}
                  changeNamePage={this.props.changeNamePage}
                  office={office}
                  file={file}
                  getUser={getUser}
                />
              )
            ) : showEmployee &&
              isHasPermission(user.login_access.permissions, 'PARTNERS_MANAG') ? (
              <EmployeeManagement
                goBackItem={this.goBackItem}
                changeNamePage={this.props.changeNamePage}
                changeActiveItemSideBarSettings={
                  this.props.changeActiveItemSideBarSettings
                }
                isLoadingHolidayEmployeeManagement={isLoadingHolidayEmployeeManagement}
                doctors={employeeManagement}
                file={file}
                user={user}
                sandwich_menu_btn={sandwich_menu_btn}
              />
            ) : showSecretarialManagement &&
              isHasPermission(user.login_access.permissions, 'SECRETERIES_MANAG') ? (
              <SecretarialManagement
                goBackItem={this.goBackItem}
                changeNamePage={this.props.changeNamePage}
                changeActiveItemSideBarSettings={
                  this.props.changeActiveItemSideBarSettings
                }
                isLoadingSecretaries={isLoadingSecretaries}
                secretaries={secretaries}
                last10Patient={last10Patient}
                goBack={this.goBack}
                file={file}
                getUser={getUser}
              />
            ) : showManagementOfOtherEmployees &&
              isHasPermission(user.login_access.permissions, 'ASSISTANTS_MANAG') ? (
              <ManagementOfOtherEmployees
                goBackItem={this.goBackItem}
                changeNamePage={this.props.changeNamePage}
                changeActiveItemSideBarSettings={
                  this.props.changeActiveItemSideBarSettings
                }
                OtherEmployees="OtherEmployees"
                isLoadingAssistants={isLoadingAssistants}
                getUser={getUser}
                assistants={assistants}
              />
            ) : showManagementOfPathologies &&
              isHasPermission(user.login_access.permissions, 'PATHOLOGIES_MANAG') ? (
              <ManagementOfPathologies
                goBackItem={this.goBackItem}
                changeNamePage={this.props.changeNamePage}
                changeActiveItemSideBarSettings={
                  this.props.changeActiveItemSideBarSettings
                }
                last10Patient={last10Patient}
                getUser={getUser}
              />
            ) : showAbsenceManagement &&
              isHasPermission(user.login_access.permissions, 'ABSENCES_MANAG') ? (
              <AbsenceManagement
                goBackItem={this.goBackItem}
                changeNamePage={this.props.changeNamePage}
                changeActiveItemSideBarSettings={
                  this.props.changeActiveItemSideBarSettings
                }
                secretaries={secretaries}
                assistants={assistants}
                firstDoctor={doctor}
                doctors={employeeManagement}
                last10Patient={last10Patient}
                getUser={getUser}
              />
            ) : showHoliday &&
              isHasPermission(user.login_access.permissions, 'HOLIDAY_ACCESS') ? (
              <Holidays
                goBackItem={this.goBackItem}
                changeNamePage={this.props.changeNamePage}
                firstDoctor={doctor}
                doctors={employeeManagement}
                secretaries={secretaries}
                assistants={assistants}
                last10Patient={last10Patient}
                getUser={getUser}
              />
            ) : showMissionManagement &&
              isHasPermission(user.login_access.permissions, 'MISSIONS_MANAG') ? (
              <MissionsManagement
                goBackItem={this.goBackItem}
                changeNamePage={this.props.changeNamePage}
                changeActiveItemSideBarSettings={
                  this.props.changeActiveItemSideBarSettings
                }
                isLoadingMissions={isLoadingMissions}
                missions={missions}
                doctors={employeeManagement}
                last10Patient={last10Patient}
                getUser={getUser}
              />
            ) : showMedecins &&
              isHasPermission(
                user.login_access.permissions,
                'PRESCRIBING_DOCTORS_MANAG',
              ) ? (
              <Medecins
                goBackItem={this.goBackItem}
                changeNamePage={this.props.changeNamePage}
                changeActiveItemSideBarSettings={
                  this.props.changeActiveItemSideBarSettings
                }
                isLoadingMedecins={isLoadingMedecins}
                medecins={medecins}
                last10Patient={last10Patient}
                getUser={getUser}
              />
            ) : showPermission &&
              isHasPermission(user.login_access.permissions, 'PERMISSIONS_MANAG') ? (
              <Permission
                goBackItem={this.goBackItem}
                changeNamePage={this.props.changeNamePage}
                isLoadingPermission={isLoadingPermission}
                group_permissions={permissions}
                assistants={assistants}
                doctors={employeeManagement}
                secretaries={secretaries}
                last10Patient={last10Patient}
                getUser={getUser}
              />
            ) : showArchive ? (
              <Archive
                goBackItem={this.goBackItem}
                changeNamePage={this.props.changeNamePage}
                archives={archives}
                isLoadingArchive={isLoadingArchive}
              />
            ) : (
              ''
            )}
          </div>
        )}

        {/*<div className="user_info">
          <div className="settings_menu">
            {isHasPermission(user.login_access.permissions, 'COMPANY_MANAG') ? (
              <>
                <SettingMenuItemsProfil
                  activate={showInfo}
                  item={0}
                  HandelChangePage={this.HandelChangePage}
                  icon="fas fa-address-card"
                  name="Gestion de la société"
                  description="Détails proprietaire &amp; centre"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(user.login_access.permissions, 'PARTNERS_MANAG') ? (
              <>
                <SettingMenuItemsProfil
                  item={1}
                  activate={showEmployee}
                  HandelChangePage={this.HandelChangePage}
                  icon="fas fa-hospital-user"
                  name="Gestion des collaborateurs"
                  description="Autres kinés dans le centre"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(user.login_access.permissions, 'SECRETERIES_MANAG') ? (
              <>
                <SettingMenuItemsProfil
                  item={2}
                  HandelChangePage={this.HandelChangePage}
                  icon="fa fa-user-nurse"
                  name="Gestion de secrétariat"
                  description="Ajouter, modifier une secrétaire"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(user.login_access.permissions, 'ASSISTANTS_MANAG') ? (
              <>
                <SettingMenuItemsProfil
                  item={3}
                  HandelChangePage={this.HandelChangePage}
                  icon="fa fa-users"
                  name="Gestion des assistants"
                  description="Ajouter, modifier un assistant"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(user.login_access.permissions, 'PATHOLOGIES_MANAG') ? (
              <>
                <SettingMenuItemsProfil
                  item={4}
                  HandelChangePage={this.HandelChangePage}
                  icon="fa fa-dot-circle"
                  name="Gestion des pathologies"
                  description="Ajouter, modifier une pathologie"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(user.login_access.permissions, 'ABSENCES_MANAG') ? (
              <>
                <SettingMenuItemsProfil
                  item={5}
                  HandelChangePage={this.HandelChangePage}
                  icon="fas fa-adjust"
                  name="Gestion des absences"
                  description="Gérer les absences et les congés"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(user.login_access.permissions, 'HOLIDAY_ACCESS') ? (
              <>
                <SettingMenuItemsProfil
                  item={12}
                  HandelChangePage={this.HandelChangePage}
                  icon="fas fa-adjust"
                  name="Gestion des jours fériés"
                  description="Gestion des jours fériés"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(user.login_access.permissions, 'MISSIONS_MANAG') ? (
              <>
                <SettingMenuItemsProfil
                  item={6}
                  activate={showMissionManagement}
                  HandelChangePage={this.HandelChangePage}
                  icon="fa fa-laptop-house"
                  name="Gestion des missions"
                  description="Historique des missions"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(
              user.login_access.permissions,
              'PRESCRIBING_DOCTORS_MANAG',
            ) ? (
              <>
                <SettingMenuItemsProfil
                  item={7}
                  HandelChangePage={this.HandelChangePage}
                  icon="fa fa-user-md"
                  name="Gestion des médecins "
                  description="Les médecins prescripteurs"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(user.login_access.permissions, 'PERMISSIONS_MANAG') ? (
              <>
                <SettingMenuItemsProfil
                  item={8}
                  activate={showPermission}
                  HandelChangePage={this.HandelChangePage}
                  icon="fas fa-user-lock"
                  name="Gestion des permissions"
                  description="Affectation des permissions"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(user.login_access.permissions, 'REGISTER_MANAG') ? (
              <>
                <SettingMenuItemsProfil
                  item={9}
                  HandelChangePage={this.HandelChangePage}
                  icon="fas fa-hand-paper"
                  description="Historique d’activités"
                  name="Registre d’activités"
                />
                <hr />
              </>
            ) : (
              ''
            )}
          </div>
        </div>*/}
        {}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
