import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../store/actions';
import InputField from '../../../components/inputField';
import SimpleReactValidator from 'simple-react-validator';
import {
  firstNumberPhone,
  generateBillNumber,
  isNumeric,
  lengthPhone,
  validate,
} from '../../../utils/helpres';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { sortCities, country } from '../../../utils/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import actionsJs from '../../../js/actions';

const mapStateToProps = (state, ownProps) => {
  const { patient, patientRecordNumber, patientRecordNewNumber } = ownProps;
  let first_name;
  let last_name;
  let email;
  let country;
  let city;
  let street;
  let zip_code;
  let profession;
  let cnam_root;
  let birthday;
  let phone;
  let gender;
  let job;
  let cnam_key;
  let practitioner;
  let regime;
  let pr_number = patientRecordNewNumber;
  if (patient) {
    regime = patient && patient.regime ? patient.regime : 3;
    practitioner = patient && patient.practitioner ? patient.practitioner : '';
    pr_number = patientRecordNumber ? patientRecordNumber : '';
    gender = patient && patient.gender ? patient.gender : '';
    birthday = patient && patient.birthday ? patient.birthday : '';
    gender = patient && patient.gender ? patient.gender : '';
    job = patient && patient.job ? patient.job : '';
    phone = patient && patient.phone ? patient.phone : '';
    cnam_key = patient && patient.cnam_key ? patient.cnam_key : '';
    cnam_root = patient && patient.cnam_root ? patient.cnam_root : '';
    profession = patient && patient.profession ? patient.profession : '';
    first_name = patient && patient.first_name ? patient.first_name : '';
    last_name = patient && patient.last_name ? patient.last_name : '';
    email = patient && patient.email ? patient.email : '';
    country =
      patient && patient.address_patient && patient.address_patient.country
        ? patient.address_patient.country
        : '';
    city =
      patient && patient.address_patient && patient.address_patient.city
        ? patient.address_patient.city
        : '';
    street =
      patient && patient.address_patient && patient.address_patient.street
        ? patient.address_patient.street
        : '';
    zip_code =
      patient && patient.address_patient && patient.address_patient.zip_code
        ? patient.address_patient.zip_code
        : null;
  }
  return {
    sandwich_menu_btn: state.AppReducer.sandwich_menu_btn,
    isLoadingActionPatient: state.PatientReducer.isLoadingActionPatient,
    addPatient: state.PatientReducer.addPatient,
    message: state.PatientReducer.message,
    error: state.PatientReducer.error,
    user: state.AppReducer.user,
    employeeManagement: state.AppReducer.employeeManagement,
    isLoadingHolidayEmployeeManagement:
      state.AppReducer.isLoadingHolidayEmployeeManagement,
    patient: patient,
    isLoadingMedecins: state.AppReducer.isLoadingMedecins,
    medecins: state.AppReducer.medecins,
    initialValues: {
      ...patient,
      first_name: first_name,
      cnam_key: cnam_key,
      cnam_root: cnam_root,
      pr_number: pr_number,
      birthday: birthday,
      profession: profession,
      last_name: last_name,
      email: email,
      gender: gender,
      phone: phone,
      regime: regime,
      practitioner,
      job: job,
      address_patient: {
        country: country,
        city: city,
        street: street,
        zip_code: zip_code,
      },
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  submitPatientForm: async (values) => {
    await dispatch(actions.submitPatientForm(values));
  },
  getMedecins: () => dispatch(actionsJs.getMedecins()),
  getEmployeeManagement: (type) => dispatch(actionsJs.getEmployeeManagement(type)),
});
class FormPatient extends Component {
  constructor(props) {
    super(props);
    props.getEmployeeManagement(true);
    props.getMedecins();
    this.state = {
      initialValues: {
        ...props.initialValues,
        alert: false,
      },
      activeTab: '1',
      active: false,
      isOpenToggleDiplome: false,
      isOpenToggleFormation: false,
      errorDate: false,
      hasError: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInitialValues = this.handleInitialValues.bind(this);

    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        required: 'Champ requis.',
        integer: 'Champ ne doit contenir que des chiffres',
        string: 'Champ ne doit contenir que des lettres',
        // OR
        default: 'Cette information est requise.', // will override all messages
      },
    });
  }
  handleInitialValues() {
    this.setState({
      initialValues: {
        last_name: '',
        first_name: '',
        phone: '',
        birthday: '',
        cnam: '',
        email: '',
        address_patient: {
          country: '',
          city: '',
          street: '',
          zip_code: '',
        },
        alert: false,
      },
      hasError: false,
    });
  }

  submitForm(initialValues) {
    const { errorDate } = this.state;
    if (
      !initialValues.last_name ||
      !initialValues.first_name ||
      (initialValues.last_name && !validate(initialValues.last_name)) ||
      (initialValues.first_name && !validate(initialValues.first_name)) ||
      //(initialValues.phone && !firstNumberPhone(initialValues.phone)) ||
      (initialValues.phone && !lengthPhone(initialValues.phone)) ||
      (initialValues.phone && !isNumeric(initialValues.phone)) ||
      //(initialValues.cnam_key && !isNumeric(initialValues.cnam_key)) ||
      //(initialValues.cnam_key && initialValues.cnam_key.length !== 2) ||
      // (initialValues.cnam_root && initialValues.cnam_root.length !== 10) ||
      (initialValues.cnam_root && !isNumeric(initialValues.cnam_root)) /* ||
      !initialValues.address_patient.city ||
      !initialValues.address_patient.country ||
      !initialValues.address_patient.street ||
      !initialValues.address_patient.zip_code*/ ||
      errorDate
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else if (this.validator.allValid()) {
      this.props.submitPatientForm(initialValues);
    } else {
      this.validator.showMessages();
      this.setState({
        ...initialValues,
        hasError: true,
      });
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  handleInputChange(event) {
    const { initialValues } = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      initialValues: {
        ...initialValues,
        [name]: value,
      },
    });
  }

  render() {
    const { isLoadingActionPatient, sandwich_menu_btn, patient, employeeManagement } =
      this.props;
    const { initialValues, hasError, errorDate } = this.state;
    return (
      <div className={!patient ? 'main_content' : ''}>
        {!patient ? (
          <div className="user_info">
            <div className="user_img_name">
              <div className="patient_avatar">
                <svg
                  width="33"
                  height="34"
                  viewBox="0 0 33 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.73958 23.2812C9.42708 21.7812 12.9583 21.0312 16.3333 21.0312C19.7083 21.0312 23.2083 21.7812 26.8333 23.2812C30.5208 24.7188 32.3646 26.625 32.3646 29V33.0312H0.302084V29C0.302084 26.625 2.11458 24.7188 5.73958 23.2812ZM21.9583 14.6562C20.3958 16.2188 18.5208 17 16.3333 17C14.1458 17 12.2708 16.2188 10.7083 14.6562C9.14583 13.0938 8.36458 11.2188 8.36458 9.03125C8.36458 6.84375 9.14583 4.96875 10.7083 3.40625C12.2708 1.78125 14.1458 0.96875 16.3333 0.96875C18.5208 0.96875 20.3958 1.78125 21.9583 3.40625C23.5208 4.96875 24.3021 6.84375 24.3021 9.03125C24.3021 11.2188 23.5208 13.0938 21.9583 14.6562Z"
                    fill="#8083A3"
                  />
                </svg>
              </div>

              <div className="user_name">
                <span className="name">{`${
                  initialValues.last_name ? initialValues.last_name : 'Nom'
                }
                ${
                  initialValues.first_name ? initialValues.first_name : 'Prénom'
                } `}</span>

                <span className="job">
                  {initialValues.address_patient.city
                    ? initialValues.address_patient.city
                    : 'Ville'}
                </span>
              </div>
            </div>
            <div className="general_info_add_option">
              <div className="setting_menu_items">
                <div
                  className="item_container active_item_container"
                  id="informations_generales"
                >
                  <div className="icon">
                    <i className="fas fa-info-circle"></i>
                  </div>
                  <div className="text">
                    <span className="title" id="informations_generales_title">
                      Informations générales
                    </span>
                    <span className="desc">Détails, adresse et CNAM</span>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        ) : (
          <div className="patients_consultations_record_header">
            <svg
              onClick={() => this.props.showPatient(patient.id)}
              style={{ cursor: 'pointer' }}
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="45.5"
                width="45"
                height="45"
                rx="9.5"
                transform="rotate(-90 0.5 45.5)"
                fill="white"
                stroke="#ECEEF5"
              />
              <path
                d="M20.0117 23L24.5117 18.5L25.5664 19.5547L22.1211 23L25.5664 26.4453L24.5117 27.5L20.0117 23Z"
                fill="#8083A3"
              />
            </svg>
            <div style={{ width: '50px', height: '50px' }} className="patient_avatar">
              <svg
                width="20"
                height="34"
                viewBox="0 0 33 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.73958 23.2812C9.42708 21.7812 12.9583 21.0312 16.3333 21.0312C19.7083 21.0312 23.2083 21.7812 26.8333 23.2812C30.5208 24.7188 32.3646 26.625 32.3646 29V33.0312H0.302084V29C0.302084 26.625 2.11458 24.7188 5.73958 23.2812ZM21.9583 14.6562C20.3958 16.2188 18.5208 17 16.3333 17C14.1458 17 12.2708 16.2188 10.7083 14.6562C9.14583 13.0938 8.36458 11.2188 8.36458 9.03125C8.36458 6.84375 9.14583 4.96875 10.7083 3.40625C12.2708 1.78125 14.1458 0.96875 16.3333 0.96875C18.5208 0.96875 20.3958 1.78125 21.9583 3.40625C23.5208 4.96875 24.3021 6.84375 24.3021 9.03125C24.3021 11.2188 23.5208 13.0938 21.9583 14.6562Z"
                  fill="#8083A3"
                />
              </svg>
            </div>

            <div className="patient_basic_info">
              <span id="patient_name">{`${patient.last_name} ${patient.first_name}`}</span>
              <span id="patient_city">
                {patient.address_patient ? patient.address_patient.city : '-'}
              </span>
            </div>
          </div>
        )}
        <div className="user_settings">
          <div className="container_changeable_content">
            <div className="add_patient_info">
              <form className="add_patient_form">
                <div className="title_and_button">
                  <span id="general_info_title">Informations générales</span>
                </div>
                <div className="add_patient_actual_form">
                  <div className="field_row">
                    <InputField
                      width="100%"
                      className="form-group"
                      type="text"
                      placeholder="N° fiche patient"
                      label="N° fiche patient"
                      validInput="string"
                      value={initialValues.pr_number}
                      name="pr_number"
                      onChange={(e) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            pr_number: e.target.value,
                          },
                        });
                      }}
                    />

                    <InputField
                      width="100%"
                      className="add_patient_form_item"
                      type="text"
                      placeholder="Nom"
                      label="Nom"
                      hasError={hasError}
                      validInput="string"
                      value={initialValues.last_name}
                      name="last_name"
                      icon={<i className="fas fa-user"></i>}
                      onChange={(e) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            last_name: e.target.value,
                          },
                        });
                      }}
                    />
                    <InputField
                      width="100%"
                      className="add_patient_form_item"
                      type="text"
                      placeholder="Adresse e-mail"
                      label="Adresse e-mail"
                      //  hasError={hasError}
                      value={initialValues.email}
                      name="email"
                      icon={<i className="fas fa-envelope"></i>}
                      required={this.validator.message(
                        'email',
                        initialValues.email,
                        'email',
                      )}
                      onChange={(e) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            email: e.target.value,
                          },
                        });
                      }}
                    />

                    <InputField
                      width="100%"
                      className="add_patient_form_item"
                      type="date"
                      placeholder="Date de naissance"
                      label="Date de naissance"
                      errorDate={errorDate}
                      //hasError={hasError}
                      value={
                        initialValues.birthday
                          ? moment(initialValues.birthday).format('YYYY-MM-DD')
                          : ''
                      }
                      name="birthday"
                      icon=""
                      onChange={(e) => {
                        if (moment().isBefore(moment(e.target.value))) {
                          this.setState({
                            errorDate: true,
                            initialValues: {
                              ...initialValues,
                              birthday: e.target.value,
                            },
                          });
                        } else
                          this.setState({
                            errorDate: false,
                            initialValues: {
                              ...initialValues,
                              birthday: e.target.value,
                            },
                          });
                      }}
                    />
                    <div style={{ width: '100%', display: 'flex' }}>
                      <div style={{ width: '40%' }} className="add_patient_form_item">
                        <label>Regime</label>
                        <div className="input_and_icon">
                          <select
                            value={initialValues.regime}
                            onChange={(e) => {
                              this.setState({
                                initialValues: {
                                  ...initialValues,
                                  regime: e.target.value,
                                },
                              });
                            }}
                          >
                            <option>Choissiez </option>
                            <option value={1}>CNAM </option>
                            <option value={2}> Accident de travail </option>
                            <option value={3}>Autre</option>
                          </select>
                        </div>
                      </div>
                      {parseInt(initialValues.regime) === 1 ||
                      parseInt(initialValues.regime) === 2 ? (
                        <>
                          <InputField
                            width="30%"
                            className="add_patient_form_item"
                            type="text"
                            placeholder="Racine CNAM"
                            label="Racine CNAM"
                            // maxLength={10}
                            //hasError={hasError}
                            value={initialValues.cnam_root}
                            validInput="integerCnamRoot"
                            name="cnam_root"
                            icon={<i className="fas fa-dot-circle"></i>}
                            onChange={(e) => {
                              this.setState({
                                initialValues: {
                                  ...initialValues,
                                  cnam_root: e.target.value,
                                },
                              });
                            }}
                          />
                          <InputField
                            width="20%"
                            className="add_patient_form_item"
                            type="text"
                            placeholder="Clé CNAM"
                            label="Clé CNAM"
                            //maxLength={2}
                            //hasError={hasError}
                            value={initialValues.cnam_key}
                            validInput="string"
                            name="cnam_key"
                            icon={<i className="fas fa-key"></i>}
                            onChange={(e) => {
                              this.setState({
                                initialValues: {
                                  ...initialValues,
                                  cnam_key: e.target.value,
                                },
                              });
                            }}
                          />
                        </>
                      ) : (
                        ''
                      )}{' '}
                    </div>
                  </div>

                  <div className="field_row">
                    <div
                      style={{
                        width: '100%',
                        marginTop: '-4px',
                      }}
                      className="add_patient_form_item"
                    >
                      <label>Civilité</label>
                      <div className="input_and_icon">
                        <select
                          style={{
                            width: '100%',
                          }}
                          value={initialValues.gender}
                          name="gender"
                          onChange={(e) => {
                            this.setState({
                              initialValues: {
                                ...initialValues,
                                gender: e.target.value,
                              },
                            });
                          }}
                          id="exampleFormControlSelect1"
                        >
                          <option value="" selected>
                            Choisissez la civilité
                          </option>
                          <option value="male">M.</option>
                          <option value="female">Mme.</option>
                        </select>
                      </div>
                    </div>
                    <InputField
                      width="100%"
                      className="add_patient_form_item"
                      type="text"
                      placeholder="Prénom"
                      validInput="string"
                      label="Prénom"
                      hasError={hasError}
                      value={initialValues.first_name}
                      name="first_name"
                      icon={<i className="fas fa-user"></i>}
                      onChange={(e) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            first_name: e.target.value,
                          },
                        });
                      }}
                    />
                    <InputField
                      width="100%"
                      className="add_patient_form_item"
                      type="text"
                      minLength={8}
                      maxLength={20}
                      placeholder="Téléphone"
                      label="Téléphone"
                      //hasError={hasError}
                      validInput="tel"
                      value={initialValues.phone}
                      name="phone"
                      icon={<i className="fas fa-phone-alt"></i>}
                      onChange={(e) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            phone: e.target.value,
                          },
                        });
                      }}
                    />
                    <InputField
                      width="100%"
                      className="add_patient_form_item"
                      type="text"
                      placeholder="Fonction"
                      validInput="string"
                      label="Fonction"
                      //hasError={hasError}
                      value={initialValues.job}
                      name="job"
                      icon={<i className="fas fa-briefcase"></i>}
                      onChange={(e) => {
                        this.setState({
                          initialValues: {
                            ...initialValues,
                            job: e.target.value,
                          },
                        });
                      }}
                    />
                    {parseInt(initialValues.regime) === 1 ||
                    parseInt(initialValues.regime) === 2 ? (
                      <InputField
                        width="100%"
                        icon={<i className="fas fa-sync"></i>}
                        className="add_patient_form_item"
                        type="text"
                        placeholder="Combiné automatiquement"
                        label="Numéro assuré"
                        disabled={true}
                        value={
                          initialValues.cnam_root && initialValues.cnam_key
                            ? `${initialValues.cnam_root}/${initialValues.cnam_key}`
                            : ''
                        }
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="address_form">
                  <div className="title_and_button">
                    <span id="general_info_title">Adresse</span>
                  </div>

                  <div className="add_patient_actual_form">
                    <div className="field_row">
                      <InputField
                        width="100%"
                        className="add_patient_form_item"
                        type="text"
                        placeholder="Adresse"
                        label="Adresse"
                        // hasError={hasError}
                        validInput=""
                        value={initialValues.address_patient.street}
                        name="street"
                        icon={<i className="fas fa-city"></i>}
                        onChange={(e) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              address_patient: {
                                ...initialValues.address_patient,
                                street: e.target.value,
                              },
                            },
                          });
                        }}
                      />
                      <div style={{ width: '100%' }} className="add_patient_form_item">
                        <label>Ville</label>
                        <Autocomplete
                          id="size-small-standard"
                          size="small"
                          options={sortCities}
                          onChange={(event, option) => {
                            this.setState({
                              initialValues: {
                                ...initialValues,
                                address_patient: {
                                  ...initialValues.address_patient,
                                  city: option != null ? option.title : '',
                                },
                              },
                            });
                          }}
                          defaultValue={{
                            title:
                              initialValues.address_patient &&
                              initialValues.address_patient.city,
                          }}
                          getOptionLabel={(option) => option.title}
                          renderInput={(cities) => (
                            <TextField
                              style={{ width: '100%' }}
                              placeholder="Ville"
                              {...cities}
                              variant="standard"
                              //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                              //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                              // error={hasError && !initialValues.address_patient.city}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="field_row">
                      <InputField
                        width="100%"
                        className="add_patient_form_item"
                        type="text"
                        placeholder="Code Postal"
                        label="Code Postal"
                        //hasError={hasError}
                        validInput="integer"
                        value={initialValues.address_patient.zip_code}
                        name="zip_code"
                        icon={<i className="fas fa-map-pin"></i>}
                        onChange={(e) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              address_patient: {
                                ...initialValues.address_patient,
                                zip_code: e.target.value,
                              },
                            },
                          });
                        }}
                      />
                      <div style={{ width: '100%' }} className="add_patient_form_item">
                        <label>Pays</label>
                        <Autocomplete
                          id="size-small-standard"
                          size="small"
                          options={country}
                          onChange={(event, option) => {
                            this.setState({
                              initialValues: {
                                ...initialValues,
                                address_patient: {
                                  ...initialValues.address_patient,
                                  country: option != null ? option.title : '',
                                },
                              },
                            });
                          }}
                          defaultValue={{
                            title:
                              initialValues.address_patient &&
                              initialValues.address_patient.country,
                          }}
                          getOptionLabel={(option) => option.title}
                          renderInput={(country) => (
                            <TextField
                              style={{ width: '100%' }}
                              placeholder="Pays"
                              {...country}
                              variant="standard"
                              //value={initialValues.address_practitioner && initialValues.address_practitioner.city}
                              //label={<span>Ville <span style={{color: 'red'}}>*</span> </span>}
                              //  error={hasError && !initialValues.address_patient.country}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="address_form">
                  <div className="add_patient_actual_form">
                    <div className="field_row">
                      <InputField
                        width="100%"
                        className="add_patient_form_item"
                        type="text"
                        placeholder="Médecin traitant"
                        label="Médecin traitant"
                        //hasError={hasError}
                        validInput="string"
                        value={initialValues.practitioner}
                        name="practitioner"
                        icon={<i className="fas fa-user"></i>}
                        onChange={(e) => {
                          this.setState({
                            initialValues: {
                              ...initialValues,
                              practitioner: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div style={{ marginTop: '15px' }} className="footer_buttons">
                <button
                  style={{
                    marginRight:
                      sandwich_menu_btn && patient && patient.id
                        ? '38px'
                        : !sandwich_menu_btn && patient && patient.id
                        ? '49px'
                        : sandwich_menu_btn
                        ? '70px'
                        : '80px',
                  }}
                  onClick={() => {
                    if (!patient) this.props.history.push('/patients');
                    else this.props.showPatient();
                  }}
                  className="footer_btn"
                  id="cancel"
                >
                  Annuler
                </button>
                <button
                  style={{ marginRight: '7%' }}
                  disabled={isLoadingActionPatient}
                  onClick={() => {
                    this.submitForm(initialValues);
                  }}
                  className="footer_btn"
                  id="update"
                >
                  {isLoadingActionPatient ? (
                    <CircularProgress
                      style={{
                        marginLeft: '4%',
                        width: '25px',
                        height: '25px',
                        color: 'white',
                        marginTop: '5%',
                      }}
                    />
                  ) : (
                    'Valider'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FormPatient);
