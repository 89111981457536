import React, { Component } from 'react';
import { diffDate } from '../../../utils/helpres';
import Modals from '../../../components/modal';

class Item_conversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setIsOpenDeleteModal: false,
    };
    this.openDeleteModal = this.openDeleteModal.bind(this);
  }
  openDeleteModal() {
    this.setState({
      setIsOpenDeleteModal: !this.state.setIsOpenDeleteModal,
    });
  }
  componentDidMount() {
    if (this.props.id === this.props.user_checked) {
      /*this.props.submitConversationForm({
        from: this.props.user.login_access.id,
        to: this.props.id,
        short_message: '',
      });*/
      this.props.getMessages(this.props.id, this.props.accountType);
      this.props.handleChangeIdConsultation(this.props.id);
    }
  }
  render() {
    const {
      date,
      user,
      paramsMessages,
      type,
      displayName,
      id,
      office,
      doctor,
      short_message,
      paramsConversation,
      messageIsSeen,
      user_checked,
      accountType,
    } = this.props;
    const { setIsOpenDeleteModal } = this.state;
    return (
      <>
        <div
          onClick={async () => {
            await this.props.submitConversationForm(
              {
                from: user.login_access.id,
                to: id,
                office_id: office,
                short_message: '',
              },
              user.id,
            );
            await this.props.getMessages(id, accountType);
            this.props.handleChangeIdConsultation(id, type);
          }}
          key={id}
          style={{ cursor: 'pointer' }}
          className={
            user_checked === id
              ? 'patients_messenger_contact_list_item messenger_selected_patient selected_messenger'
              : 'patients_messenger_contact_list_item'
          }
        >
          <div className="patient_contact_details_container">
            <div className="patient_contact_img_container">
              {doctor.practitioner ? (
                doctor.practitioner.image_practitioners.length > 0 ? (
                  <img
                    style={{ width: '40px', height: '40px' }}
                    src={`${process.env.REACT_APP_PUBLIC_URL}${doctor.practitioner.image_practitioners[0].url}`}
                    alt=""
                  />
                ) : (
                  <div className="patient_contact_img_container">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                        fill="#8083A3"
                      />
                    </svg>
                  </div>
                )
              ) : doctor.assistant ? (
                doctor.assistant.photo ? (
                  <img
                    style={{ width: '40px', height: '40px' }}
                    src={`${process.env.REACT_APP_PUBLIC_URL}${doctor.assistant.photo}`}
                    alt=""
                  />
                ) : (
                  <div className="patient_contact_img_container">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                        fill="#8083A3"
                      />
                    </svg>
                  </div>
                )
              ) : doctor.secretary && doctor.secretary.photo ? (
                <img
                  style={{ width: '40px', height: '40px' }}
                  src={`${process.env.REACT_APP_PUBLIC_URL}${doctor.secretary.photo}`}
                  alt=""
                />
              ) : doctor.office_id ? (
                <div className="patient_contact_img_container">
                  <i className="fas fa-users" />{' '}
                </div>
              ) : (
                <div className="patient_contact_img_container">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                      fill="#8083A3"
                    />
                  </svg>
                </div>
              )}
              {/*{img ? (
                <img src={img} alt="" />
              ) : office ? (
                <div className="patient_contact_img_container">
                  <i className="fas fa-users" />{' '}
                </div>
              ) : (
                <div className="patient_contact_img_container">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                      fill="#8083A3"
                    />
                  </svg>
                </div>
              )}*/}
            </div>
            <div className="patient_contact_text_container">
              <div className="first_row">
                <span
                  style={{
                    marginTop: office ? '18%' : '',
                    fontWeight: messageIsSeen ? 'bold' : '400',
                  }}
                  id="patient_username"
                >
                  {office ? 'Groupe' : displayName}
                </span>
                {/*<span
                  style={{ fontWeight: messageIsSeen ? 'bold' : 'normal' }}
                  id="patient_time"
                >
                  {date ? diffDate(date) : '-'}
                </span>*/}
              </div>
              <div className="second_row">
                <span
                  style={{ fontWeight: messageIsSeen ? 'bold' : 'normal' }}
                  id="patient_desc"
                >
                  {office ? '' : short_message}
                </span>
              </div>
            </div>
          </div>
          {type === 'conv' ? (
            <div className="more_icon_container_messenger">
              <i
                className="fas fa-ellipsis-h message"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              ></i>

              <div
                style={{
                  marginTop: '10px',
                  width: '239px',
                  height: '49',
                }}
                className="dropdown-menu  dropdown-menu-right"
                aria-labelledby="dropdownMenuButton"
              >
                <div className="carre_bilan"></div>
                <div className="op_bilan">
                  <div
                    onClick={() => this.openDeleteModal()}
                    className="op_item_bilan op_view"
                    data-toggle="modal"
                    data-target="#view_details"
                  >
                    <i
                      style={{ color: 'black', border: '0px' }}
                      className="fas fa-trash"
                      aria-hidden="true"
                    ></i>
                    <span>Supprimer</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <Modals
          setIsOpen={setIsOpenDeleteModal}
          openModal={this.openDeleteModal}
          data={
            <>
              <div className="modal-header" style={{ marginTop: '-30px' }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.openDeleteModal()}
                  style={{ marginTop: '1%' }}
                >
                  <span
                    aria-hidden="true"
                    style={{ marginRight: '5px', color: '#1A1C1D' }}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Suppression!</h2>
                <span>Etes-vous sûr de vouloir supprimer</span>
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => this.openDeleteModal()}
                  type="button"
                  className="modal_close_btn"
                  // data-dismiss="modal"
                  style={{ backgroundColor: '#f6f6f6' }}
                >
                  Non
                </button>
                <button
                  onClick={async () => {
                    await this.props.deleteConversation(id, paramsConversation, user.id);
                    this.openDeleteModal();
                  }}
                  type="button"
                  className="modal_disconnect_btn"
                >
                  Oui
                </button>
              </div>
            </>
          }
        />
      </>
    );
  }
}
export default Item_conversation;
