import React, { Component } from 'react';
import Factures from '../facture';

class FactureAct extends Component {
  render() {
    return (
      <Factures
        type="act"
        active={10}
        selectedId={this.props.selectedId}
        changeActiveItemSideBar={this.props.changeActiveItemSideBar}
        changeNamePage={this.props.changeNamePage}
        changeActiveItemSideBarCompta={this.props.changeActiveItemSideBarCompta}
      />
    );
  }
}

export default FactureAct;
