import React, { Component } from 'react';
import { SelectorBilanItemSection, SelectorColorInputCheckbox } from '../../../../utils';
import UploadFile from '../../upload_file';import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
class ContexteAnamnese extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poids: 0,
      taille: 0,
      showEntretien: true,
      showDouleur: false,
      showBilanArticulaire: false,
      showScore: false,
    };
    this.handleChangeSubSectionBtn = this.handleChangeSubSectionBtn.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    SelectorColorInputCheckbox();
    SelectorBilanItemSection();
  }
  handleChangeSubSectionBtn(name) {
    this.setState({
      [name]: !this.state[name],
    });
  }
  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }
  render() {
    const { sandwich_menu_btn, initialValues } = this.props;
    return (
      <div className="actual_bilan_settings">
        <div className="actual_bilan_settings_item head_shaking_test" id="headShaking1">
          <div
            style={{ backgroundColor: '#6c89bc' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}> Contexte et Anamnèse</span>
          </div>
          <br />
          <div
            style={{ backgroundColor: '#035ef8' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}> Contexte patient</span>
          </div>
          <br />
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Mode de vie
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="frequence_item_1"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_1"
                              checked={
                                initialValues.check_contexte_1 === 'Actif' ? true : false
                              }
                              //checked={initialValues.check_contexte_1}
                              value="Actif"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_1`}
                            >
                              Actif
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_1"
                              checked={
                                initialValues.check_contexte_1 === 'Retraite'
                                  ? true
                                  : false
                              }
                              value="Retraite"
                              id={`frequence_item_2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_2`}
                            >
                              Retraite
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_1_2"
                              checked={
                                initialValues.check_contexte_1_2 === '(Isolé)'
                                  ? true
                                  : false
                              }
                              value="(Isolé)"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_item_3`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_3`}
                            >
                              (Isolé)
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_1_2"
                              checked={
                                initialValues.check_contexte_1_2 === '(En famille)'
                                  ? true
                                  : false
                              }
                              value="(En famille)"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_item_13`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_13`}
                            >
                              (En famille)
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Lieu de vie
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="frequence_itemd_1"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_5"
                              checked={
                                initialValues.check_contexte_5 === 'Maison' ? true : false
                              }
                              value="Maison"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_1`}
                            >
                              Maison
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_5"
                              checked={
                                initialValues.check_contexte_5 === 'Appartement'
                                  ? true
                                  : false
                              }
                              value="Appartement"
                              id={`frequence_itemd_2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_2`}
                            >
                              Appartement
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_5_1"
                              checked={
                                initialValues.check_contexte_5_1 === 'au RDC'
                                  ? true
                                  : false
                              }
                              value="au RDC"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_item_1d3`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_1d3`}
                            >
                              au RDC
                            </label>
                          </div>
                        </div>

                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_5_1"
                              checked={
                                initialValues.check_contexte_5_1 === "à l'etage"
                                  ? true
                                  : false
                              }
                              value="à l'etage"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_item_12`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_12`}
                            >
                              à l'etage
                            </label>
                          </div>
                        </div>
                        <br />
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_5_2"
                              checked={
                                initialValues.check_contexte_5_2 === 'Avec ascensur'
                                  ? true
                                  : false
                              }
                              value="Avec ascensur"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_item_11`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_11`}
                            >
                              Avec ascensur
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_5_2"
                              checked={
                                initialValues.check_contexte_5_2 === 'Sans ascenseur'
                                  ? true
                                  : false
                              }
                              value="Sans ascenseur"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_item_14`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_14`}
                            >
                              Sans ascenseur
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Aide extérieure
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="frequence_itemd_31"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_12_1"
                              checked={
                                initialValues.check_contexte_12_1 === 'Quotidienne'
                                  ? true
                                  : false
                              }
                              value="Quotidienne"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_31`}
                            >
                              Quotidienne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_12_1"
                              checked={
                                initialValues.check_contexte_12_1 === 'Hebdomadaire'
                                  ? true
                                  : false
                              }
                              value="Hebdomadaire"
                              id={`frequence_itemd_32`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_32`}
                            >
                              Hebdomadaire
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_12_1"
                              checked={
                                initialValues.check_contexte_12_1 === 'Pluri-hebdomadaire'
                                  ? true
                                  : false
                              }
                              value="Pluri-hebdomadaire"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_itemd_33`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_33`}
                            >
                              Pluri-hebdomadaire
                            </label>
                          </div>
                        </div>
                        <br />

                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_item_122"
                              name="check_seance_1__3"
                              value="Permanente"
                              checked={initialValues.check_seance_1__3}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_122`}
                            >
                              Permanente
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_item_x1"
                              name="check_seance_1__1"
                              value="Famille"
                              checked={initialValues.check_seance_1__1}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_x1`}
                            >
                              Famille
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_item_1s14"
                              name="check_seance_1__2"
                              value="Aide ménagère"
                              checked={initialValues.check_seance_1__2}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_1s14`}
                            >
                              Aide ménagère
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Attente du patient
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c31"
                              name="check_seance_1__4"
                              value="Antalgique"
                              checked={initialValues.check_seance_1__4}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c31`}
                            >
                              Antalgique
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c301"
                              name="check_seance_1__5"
                              value="Orthopédique"
                              checked={initialValues.check_seance_1__5}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c301`}
                            >
                              Orthopédique
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c311"
                              name="check_seance_1__6"
                              value="Psychologique"
                              checked={initialValues.check_seance_1__6}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c311`}
                            >
                              Psychologique
                            </label>
                          </div>
                        </div>
                        <br />

                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c321"
                              name="check_seance_1__7"
                              value="Respiratoire"
                              checked={initialValues.check_seance_1__7}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c321`}
                            >
                              Respiratoire
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c331"
                              name="check_seance_1__8"
                              value="Fonctionnelles"
                              checked={initialValues.check_seance_1__8}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c331`}
                            >
                              Fonctionnelles
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c341"
                              name="check_seance_1__9"
                              value="Autre"
                              checked={initialValues.check_seance_1__9}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c341`}
                            >
                              Autre
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Travail
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c351"
                              name="check_seance_2__1"
                              value="Sédentaire"
                              checked={initialValues.check_seance_2__1}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c351`}
                            >
                              Sédentaire
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c361"
                              name="check_seance_2__2"
                              value="Posté"
                              checked={initialValues.check_seance_2__2}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c361`}
                            >
                              Posté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c371"
                              name="check_seance_2__3"
                              value="Debout"
                              checked={initialValues.check_seance_2__3}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c371`}
                            >
                              Debout
                            </label>
                          </div>
                        </div>
                        <br />

                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c381"
                              name="check_seance_2__4"
                              value="Manuel"
                              checked={initialValues.check_seance_2__4}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c381`}
                            >
                              Manuel
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c391"
                              name="check_seance_2__5"
                              value="Port de charges"
                              checked={initialValues.check_seance_2__5}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c391`}
                            >
                              Port de charges
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c3000"
                              name="check_seance_2__6"
                              value="Gestes répétitifs"
                              checked={initialValues.check_seance_2__6}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c3000`}
                            >
                              Gestes répétitifs
                            </label>
                          </div>
                        </div>
                        <br />

                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c3001"
                              name="check_seance_2__7"
                              value="Travail de force"
                              checked={initialValues.check_seance_2__7}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c3001`}
                            >
                              Travail de force
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c3002"
                              name="check_seance_2__8"
                              value="TMS"
                              checked={initialValues.check_seance_2__8}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c3002`}
                            >
                              TMS
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c3003"
                              name="check_seance_2__9"
                              value="Accident du travail"
                              checked={initialValues.check_seance_2__9}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c3003`}
                            >
                              Accident du travail
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Sport & loisirs
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c3004"
                              name="check_seance_3__1"
                              value="Sport loisir"
                              checked={initialValues.check_seance_3__1}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c3004`}
                            >
                              Sport loisir
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c3005"
                              name="check_seance_3__2"
                              value="Compétition"
                              checked={initialValues.check_seance_3__2}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c3005`}
                            >
                              Compétition
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c3006"
                              name="check_seance_3__3"
                              value="Sportif haut niveau"
                              checked={initialValues.check_seance_3__3}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c3006`}
                            >
                              Sportif haut niveau
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c3007"
                              name="check_seance_3__4"
                              value="Vie associative"
                              checked={initialValues.check_seance_3__4}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c3007`}
                            >
                              Vie associative
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Profession
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="additional_container_item_field">
                        <textarea
                          onChange={this.props.handleChangeInput}
                          maxLength="500"
                          style={{ width: '60%' }}
                          value={initialValues.profession_11}
                          name="profession_11"
                          placeholder="Profession"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Loisirs
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <textarea
                        onChange={this.props.handleChangeInput}
                        maxLength="500"
                        style={{ width: '60%' }}
                        value={initialValues.loisirs_11}
                        name="loisirs_11"
                        placeholder="Loisirs"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Relationnel
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c30010"
                              name="check_seance_4__1"
                              value="Participe à la prise en charge"
                              checked={initialValues.check_seance_4__1}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c30010`}
                            >
                              Participe à la prise en charge
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c30011"
                              name="check_seance_4__2"
                              value="Opposant"
                              checked={initialValues.check_seance_4__2}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c30011`}
                            >
                              Opposant
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c30012"
                              name="check_seance_4__3"
                              value="Relations familiales"
                              checked={initialValues.check_seance_4__3}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c30012`}
                            >
                              Relations familiales
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c30013"
                              name="check_seance_4__4"
                              value="Relations aidants"
                              checked={initialValues.check_seance_4__4}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c30013`}
                            >
                              Relations aidants
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c30014"
                              name="check_seance_4__5"
                              value="Interactions limitées"
                              checked={initialValues.check_seance_4__5}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c30014`}
                            >
                              Interactions limitées
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Transport
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c30015"
                              name="check_seance_4__6"
                              value="Voiture"
                              checked={initialValues.check_seance_4__6}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c30015`}
                            >
                              Voiture
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c30016"
                              name="check_seance_4__7"
                              value="Moto"
                              checked={initialValues.check_seance_4__7}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c30016`}
                            >
                              Moto
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c30017"
                              name="check_seance_4__8"
                              value="VSL"
                              checked={initialValues.check_seance_4__8}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c30017`}
                            >
                              VSL
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c30018"
                              name="check_seance_4__9"
                              value="En commun"
                              checked={initialValues.check_seance_4__9}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c30018`}
                            >
                              En commun
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="checkbox"
                              id="frequence_itemd_c30019"
                              name="check_seance_4__10"
                              value="A pied"
                              checked={initialValues.check_seance_4__10}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_itemd_c30019`}
                            >
                              A pied
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Remarques
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <textarea
                        onChange={this.props.handleChangeInput}
                        maxLength="500"
                        style={{ width: '60%' }}
                        value={initialValues.remarques_11}
                        name="remarques_11"
                        placeholder="Remarques"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ backgroundColor: '#035ef8' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}> Anamnèse</span>
          </div>
          <br />
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Latéralité
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="frequence_item_qx1"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_42"
                              checked={
                                initialValues.check_contexte_42 === 'Droitier'
                                  ? true
                                  : false
                              }
                              value="Droitier"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_qx1`}
                            >
                              Droitier
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_42"
                              checked={
                                initialValues.check_contexte_42 === 'Gauche'
                                  ? true
                                  : false
                              }
                              value="Gauche"
                              id={`frequence_item_xc2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_xc2`}
                            >
                              Gauche
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_contexte_42"
                              checked={
                                initialValues.check_contexte_42 === 'Ambidextre'
                                  ? true
                                  : false
                              }
                              value="Ambidextre"
                              id={`frequence_item_xsc2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_xsc2`}
                            >
                              Ambidextre
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Poids
                    </span>
                  </div>
                  <div className="field_sub_section_container_item">
                    <div
                      style={{ textAlign: 'center' }}
                      className="additional_container_item_field"
                    >
                      <input
                        maxLength="500"
                        value={initialValues.poids}
                        style={{ width: '60%' }}
                        name="flexion_bilan_musculaire"
                        type="number"
                        placeholder=""
                      />
                      kg
                    </div>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <Slider
                      progress
                      defaultValue={initialValues.poids}
                      style={{ marginTop: 16 }}
                      onChange={(value) => {
                        this.props.handleChangeAnyInput(value, 'poids');
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Taille
                    </span>
                  </div>
                  <div className="field_sub_section_container_item">
                    <div
                      style={{ textAlign: 'center' }}
                      className="additional_container_item_field"
                    >
                      <input
                        value={initialValues.taille}
                        maxLength="500"
                        style={{ width: '60%' }}
                        name="taille"
                        type="number"
                        placeholder=""
                      />
                      cm
                    </div>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <Slider
                      progress
                      defaultValue={initialValues.taille}
                      style={{ marginTop: 16 }}
                      onChange={(value) => {
                        this.props.handleChangeAnyInput(value, 'taille');
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Histoire de la maladie
                    </span>
                  </div>
                  <div className="field_sub_section_container_item">
                    <div className="additional_container_item_field">
                      <textarea
                        onChange={this.props.handleChangeInput}
                        maxLength="500"
                        style={{ width: '60%' }}
                        name="textarea_contexte_1"
                        value={initialValues.textarea_contexte_1}
                        placeholder="Histoire de la maladie"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Antécédents médicaux
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <textarea
                        onChange={this.props.handleChangeInput}
                        maxLength="500"
                        style={{ width: '60%' }}
                        value={initialValues.remarques_111}
                        name="remarques_111"
                        placeholder="Antécédents médicaux"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Antécédents chirurgicaux
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <textarea
                        onChange={this.props.handleChangeInput}
                        maxLength="500"
                        style={{ width: '60%' }}
                        value={initialValues.remarques_121}
                        name="remarques_121"
                        placeholder="Antécédents chirurgicaux"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Antécédents familiaux
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <textarea
                        onChange={this.props.handleChangeInput}
                        maxLength="500"
                        style={{ width: '60%' }}
                        value={initialValues.remarques_131}
                        name="remarques_131"
                        placeholder="Antécédents familiaux"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Date de dernière opération
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <input
                        onChange={this.props.handleChangeInput}
                        maxLength="500"
                        style={{ width: '60%' }}
                        type="date"
                        name="b_1_2_3"
                        value={initialValues.b_1_2_3}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
              className="actual_bilan_settings_item_body "
              id="headShaking1_sub_section"
              style={{ display: 'flex', width: '100%' }}
          >
            <div
                className="bilan_item"
                style={{
                  width: '50%',

                  backgroundColor: '#f3f4f9',
                }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                      style={{
                        alignItems: 'start',
                      }}
                      className="bilan_contact_info"
                  >
                    <span
                        style={{
                          marginLeft: '3%',
                        }}
                        id="name"
                    >
                      Morphostatique
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                                onChange={this.props.handleChangeInput}
                                className="form-check-input"
                                type="checkbox"
                                id="frequence_itemd_c131"
                                name="check_seance_5__1"
                                value="Déviation du tronc"
                                checked={initialValues.check_seance_5__1}
                            />
                            <label
                                className="form-check-label"
                                htmlFor={`frequence_itemd_c131`}
                            >
                              Déviation du tronc
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                                onChange={this.props.handleChangeInput}
                                className="form-check-input"
                                type="checkbox"
                                id="frequence_itemd_c1301"
                                name="check_seance_5__2"
                                value="Différence longueurs MI"
                                checked={initialValues.check_seance_5__2}
                            />
                            <label
                                className="form-check-label"
                                htmlFor={`frequence_itemd_c1301`}
                            >
                              Différence longueurs MI
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                                onChange={this.props.handleChangeInput}
                                className="form-check-input"
                                type="checkbox"
                                id="frequence_itemd_c31011"
                                name="check_seance_5__3"
                                value="Différence longueur MS"
                                checked={initialValues.check_seance_5__3}
                            />
                            <label
                                className="form-check-label"
                                htmlFor={`frequence_itemd_c31011`}
                            >
                              Différence longueur MS
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
                className="bilan_item"
                style={{
                  width: '50%',

                  backgroundColor: '#f3f4f9',
                }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                      style={{
                        alignItems: 'start',
                      }}
                      className="bilan_contact_info"
                  >
                    <span
                        style={{
                          marginLeft: '3%',
                        }}
                        id="name"
                    >
                      Détails morphostatique
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="additional_container_item_field">
                        <textarea
                            onChange={this.props.handleChangeInput}
                            maxLength="500"
                            style={{ width: '60%' }}
                            value={initialValues.morphostatique_11}
                            name="morphostatique_11"
                            placeholder="Détails morphostatique"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
              className="actual_bilan_settings_item_body "
              id="headShaking1_sub_section"
              style={{ display: 'flex', width: '100%' }}
          >
            <div
                className="bilan_item"
                style={{
                  width: '50%',

                  backgroundColor: '#f3f4f9',
                }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                      style={{
                        alignItems: 'start',
                      }}
                      className="bilan_contact_info"
                  >
                    <span
                        style={{
                          marginLeft: '3%',
                        }}
                        id="name"
                    >
                      Imagerie(s) réalisée(s)
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                                onChange={this.props.handleChangeInput}
                                className="form-check-input"
                                type="checkbox"
                                id="frequence_itemd_c11131"
                                name="check_seance_5__5"
                                value="Radio"
                                checked={initialValues.check_seance_5__5}
                            />
                            <label
                                className="form-check-label"
                                htmlFor={`frequence_itemd_c11131`}
                            >
                              Radio
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                                onChange={this.props.handleChangeInput}
                                className="form-check-input"
                                type="checkbox"
                                id="check_seance_5__6"
                                name="check_seance_5__6"
                                value="Arthroscanner"
                                checked={initialValues.check_seance_5__6}
                            />
                            <label
                                className="form-check-label"
                                htmlFor={`check_seance_5__6`}
                            >
                              Arthroscanner
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                                onChange={this.props.handleChangeInput}
                                className="form-check-input"
                                type="checkbox"
                                id="check_seance_5__7"
                                name="check_seance_5__7"
                                value="IRM"
                                checked={initialValues.check_seance_5__7}
                            />
                            <label
                                className="form-check-label"
                                htmlFor={`check_seance_5__7`}
                            >
                              IRM
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                                onChange={this.props.handleChangeInput}
                                className="form-check-input"
                                type="checkbox"
                                id="check_seance_5__8"
                                name="check_seance_5__8"
                                value="Échographie"
                                checked={initialValues.check_seance_5__8}
                            />
                            <label
                                className="form-check-label"
                                htmlFor={`check_seance_5__8`}
                            >
                              Échographie
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
                className="bilan_item"
                style={{
                  width: '50%',

                  backgroundColor: '#f3f4f9',
                }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                      style={{
                        alignItems: 'start',
                      }}
                      className="bilan_contact_info"
                  >
                    <span
                        style={{
                          marginLeft: '3%',
                        }}
                        id="name"
                    >
                      Autres remarques
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="additional_container_item_field">
                        <textarea
                            onChange={this.props.handleChangeInput}
                            maxLength="500"
                            style={{ width: '60%' }}
                            value={initialValues.morphostatique_111}
                            name="morphostatique_111"
                            placeholder="Autres remarques"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContexteAnamnese;
