import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import actions from './store/actions';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import fr from 'date-fns/locale/fr';
import './index.css';
import AddReceipt from '../receipt/add_received';
import CircularProgress from '@material-ui/core/CircularProgress';
import actionsJs from '../../js/actions';

registerLocale('fr', fr); // register it with the name you want

const mapStateToProps = (state) => ({
  patientRecord: state.PatientReducer.patientRecord,
  isLoadingPatientRecord: state.PatientReducer.isLoadingPatientRecord,
  patients: state.PatientReducer.patients,
  patientRecords: state.PatientReducer.patientRecords,
  params: state.PatientReducer.params,
  user: state.AppReducer.user,
  isLoadingPatient: state.PatientReducer.isLoadingPatient,
  isLoadingPatientRecords: state.PatientReducer.isLoadingPatientRecords,
  isLoadingLast10Patients: state.DashboardReducer.isLoadingLast10Patients,
  last10Patient: state.DashboardReducer.last10Patient,
  updateReceipt: state.ReceiptReducer.updateReceipt,
  tag: state.AppReducer.tag,
});

const mapDispatchToProps = (dispatch) => ({
  changeActiveItemSideBarSettings: (item, data, create) =>
    dispatch(actionsJs.changeActiveItemSideBarSettings(item, data, create)),
  changeActiveItemSideBarCompta: (data) =>
    dispatch(actionsJs.changeActiveItemSideBarCompta(data)),
  getPatientRecordById: (id) => dispatch(actions.getPatientRecordById(id)),
  initialState: () => dispatch(actions.initialState()),
});

class Patients extends PureComponent {
  constructor(props) {
    super(props);
    props.initialState();
    props.getPatientRecordById(props.location.state.patient_id);
    props.changeActiveItemSideBarCompta(null);
    document.title = 'Ajouter une reçu';
    props.changeActiveItemSideBar(9);
    props.changeNamePage('Ajouter une reçu');
    props.changeActiveItemSideBarSettings(111, false, true);
    this.state = {
      defaultFilter: true,
      errorPhone: false,
      errorName: false,
      errorDate: false,
      patient: null,
      note: '',
      last_name: props.params.last_name,
      phone: props.params.phone,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { updateReceipt, patient, consultation } = this.props;
    if (updateReceipt && updateReceipt !== prevProps.updateReceipt) {
      this.props.history.goBack();
    }
  }

  render() {
    const { patientRecord, isLoadingPatientRecord, sandwich_menu_btn } = this.props;

    return (
      <div className="main_content">
        <div style={{ width: '100%' }} className="patient_info_section">
          {isLoadingPatientRecord ? (
            <CircularProgress
              disableShrink
              style={{ marginLeft: '50%', marginTop: '32%' }}
            />
          ) : (
            <AddReceipt
              goBack={() => this.props.history.goBack()}
              patient={true}
              changeNamePage={this.props.changeNamePage}
              changeActiveItemSideBar={this.props.changeActiveItemSideBar}
              sandwich_menu_btn={sandwich_menu_btn}
              showFormFacture={this.showFormFacture}
              showPatient={this.showPatient}
              bill={{
                id: patientRecord.id,
                patient: {
                  cnam: `${patientRecord.patient.cnam_root}/${patientRecord.patient.cnam_key}`,
                  displayName: `${patientRecord.patient.last_name} ${patientRecord.patient.first_name}`,
                },
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Patients);
