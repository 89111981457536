import React, { Component } from 'react';
import './index.css';
import { SelectorInputAndIcon } from '../../../utils';
import actions from '../store/actions';
import actionsJs from '../../../js/actions.js';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import CircularProgress from '@material-ui/core/CircularProgress';

const iconError = (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.71094 0.289062C10.0022 0.580307 10.0022 1.05251 9.71094 1.34375L6.05469 5L9.71094 8.65625C10.0022 8.94749 10.0022 9.41969 9.71094 9.71094C9.41969 10.0022 8.94749 10.0022 8.65625 9.71094L5 6.05469L1.34375 9.71094C1.05251 10.0022 0.580307 10.0022 0.289062 9.71094C-0.0021815 9.41969 -0.00218129 8.94749 0.289063 8.65625L3.94531 5L0.289063 1.34375C-0.00218055 1.05251 -0.0021815 0.580307 0.289062 0.289062C0.580307 -0.0021815 1.05251 -0.00218129 1.34375 0.289063L5 3.94531L8.65625 0.289063C8.94749 -0.00218055 9.41969 -0.0021815 9.71094 0.289062Z"
      fill="#F6583E"
    />
  </svg>
);
const iconSuccess = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.67572 10.548C7.0657 10.9416 7.70143 10.9431 8.09325 10.5513L12.0273 6.61726C12.3199 6.32459 12.3238 5.85128 12.036 5.55385C11.7414 5.24945 11.2545 5.24546 10.955 5.54499L7.38281 9.11719L5.23952 6.97389C4.94759 6.68197 4.47428 6.68197 4.18236 6.97389C3.8914 7.26485 3.89029 7.73625 4.17988 8.02857L6.67572 10.548ZM2.69141 2.72656C4.16797 1.25 5.9375 0.511719 8 0.511719C10.0625 0.511719 11.8203 1.25 13.2734 2.72656C14.75 4.17969 15.4883 5.9375 15.4883 8C15.4883 10.0625 14.75 11.832 13.2734 13.3086C11.8203 14.7617 10.0625 15.4883 8 15.4883C5.9375 15.4883 4.16797 14.7617 2.69141 13.3086C1.23828 11.832 0.511719 10.0625 0.511719 8C0.511719 5.9375 1.23828 4.17969 2.69141 2.72656Z"
      fill="#1DD2C1"
    />
  </svg>
);
const mapStateToProps = (state, ownProps) => {
  const { getUser } = ownProps;
  let security_answer;
  let security_question;
  if (getUser) {
    security_question = getUser && getUser.security_question && getUser.security_question;
    security_answer = getUser && getUser.security_answer && getUser.security_answer;
  }
  return {
    sandwich_menu_btn: state.AppReducer.sandwich_menu_btn,
    isLoadingChangePassword: state.ProfileReducer.isLoadingChangePassword,
    changePassword: state.ProfileReducer.changePassword,
    password: state.ProfileReducer.password,
    message: state.ProfileReducer.message,
    error: state.AppReducer.error,
    user: state.AppReducer.user,
    getUser: state.AppReducer.getUser,
    notificationEnable: state.AppReducer.notificationEnable,
    initialValues: {
      //...getUser,
      id: getUser.login_access.id,
      security_question: security_question || '',
      security_answer: security_answer || '',
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  updatePassword: (values) => dispatch(actions.updatePassword(values)),
  notificationEnabled: (values) => dispatch(actionsJs.notificationEnabled(values)),
});

class Securities extends Component {
  constructor(props) {
    super(props);
    document.title = 'Securities';
    this.state = {
      isOpen: false,
      hasError: false,
      hasErrorNewPassword: false,
      showNewPassword: false,
      showRepeatPassword: false,
      showOldPassword: false,
      initialValues: {
        ...props.initialValues,
        //id: props.user.id,
        old_password: '',
        //password: '',
        new_password: '',
        confirm_password: '',
      },
    };
    this.handleShowNewPassword = this.handleShowNewPassword.bind(this);
    this.handleShowOldPassword = this.handleShowOldPassword.bind(this);
    this.handleShowRepeatPassword = this.handleShowRepeatPassword.bind(this);
    this.handleInitialValues = this.handleInitialValues.bind(this);
    this.validator = new SimpleReactValidator({
      messages: {
        required: 'Champ requis.',
        email: 'Adresse e-mail invalide',
        min: 'Saisissez un mot de passe comprenant au moins 6 caractères.',
        max: 'Le mot de passe doit comporter de 6 à 20 caractères. ',
        // OR
        default: 'Cette information est requise.', // will override all messages
      },
    });
  }

  handleInitialValues() {
    this.setState({
      initialValues: {
        ...this.state.initialValues,
        old_password: '',
        new_password: '',
        confirm_password: '',
        security_question: '',
        security_answer: '',
        alert: false,
      },
      isOpen: false,
      hasError: false,
      hasErrorNewPassword: false,
      showNewPassword: false,
      showRepeatPassword: false,
      showOldPassword: false,
    });
  }

  handleShowNewPassword() {
    this.setState({
      showNewPassword: !this.state.showNewPassword,
    });
  }

  handleShowOldPassword() {
    this.setState({
      showOldPassword: !this.state.showOldPassword,
    });
  }

  handleShowRepeatPassword() {
    this.setState({
      showRepeatPassword: !this.state.showRepeatPassword,
    });
  }

  submitForm(initialValues) {
    if (!this.validator.allValid()) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    } else if (
      !initialValues.old_password ||
      !initialValues.new_password ||
      !initialValues.confirm_password
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else if (
      initialValues.old_password &&
      initialValues.new_password.localeCompare(initialValues.confirm_password)
    ) {
      this.setState({
        ...initialValues,
        hasErrorNewPassword: true,
      });
    } else {
      if (this.props.file) {
        initialValues.file = this.props.file;
      }
      if (
        initialValues.old_password &&
        initialValues.new_password &&
        initialValues.confirm_password
      )
        this.props.updatePassword(initialValues);
    }
  }

  componentDidMount() {
    SelectorInputAndIcon();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { changePassword } = nextProps;
    if (changePassword) {
      this.setState({
        initialValues: {
          ...this.state.initialValues,
          old_password: '',
          new_password: '',
          confirm_password: '',
          /*security_question: '',
          security_answer: '',*/
          alert: false,
        },
        isOpen: false,
        hasError: false,
        hasErrorNewPassword: false,
        showNewPassword: false,
        showRepeatPassword: false,
        showOldPassword: false,
      });
    }
  }

  render() {
    const {
      initialValues,
      hasError,
      showNewPassword,
      showRepeatPassword,
      showOldPassword,
      hasErrorNewPassword,
    } = this.state;
    const { sandwich_menu_btn, isLoadingChangePassword } = this.props;
    return (
      <div className="security_info">
        <form style={{ marginTop: '5%' }} className="security_info_form">
          <div className="title_and_button">
            <span>Changer le mot de passe</span>
          </div>
          <div className="security_info_actual_form">
            <div
              style={{ width: sandwich_menu_btn ? '27vw' : '30vw' }}
              className={
                hasError && !initialValues.old_password
                  ? `security_info_form_item type3`
                  : 'security_info_form_item'
              }
            >
              <label>Mot de passe actuel</label>
              <div className="input_and_icon">
                <input
                  id="standard-adornment-password"
                  name="password"
                  type={showOldPassword ? 'text' : 'password'}
                  value={initialValues.old_password}
                  onChange={(data) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        old_password: data.target.value,
                      },
                    });
                  }}
                  placeholder="Mot de passe actuel . . . "
                />
                {hasError && !initialValues.old_password ? (
                  iconError
                ) : (
                  <i onClick={this.handleShowOldPassword} className="fas fa-lock"></i>
                )}
              </div>
            </div>
            <div className="security_info_form_item"></div>

            <div
              style={{ width: sandwich_menu_btn ? '27vw' : '30vw' }}
              className={
                (initialValues.old_password && hasError && !initialValues.new_password) ||
                (hasError && !initialValues.new_password)
                  ? `security_info_form_item type3`
                  : initialValues.new_password &&
                    initialValues.confirm_password &&
                    !initialValues.new_password.localeCompare(
                      initialValues.confirm_password,
                    )
                  ? 'security_info_form_item type4'
                  : 'security_info_form_item'
              }
            >
              <label>Nouveau mot de passe</label>
              <div className="input_and_icon">
                <input
                  id="standard-adornment-password"
                  name="password"
                  type={showNewPassword ? 'text' : 'password'}
                  value={initialValues.new_password}
                  onChange={(data) => {
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        new_password: data.target.value,
                      },
                    });
                  }}
                  placeholder="Nouveau mot de passe . . . "
                />
                {(initialValues.old_password &&
                  hasError &&
                  !initialValues.new_password) ||
                (hasError && !initialValues.new_password) ? (
                  iconError
                ) : initialValues.new_password &&
                  initialValues.confirm_password &&
                  !initialValues.new_password.localeCompare(
                    initialValues.confirm_password,
                  ) ? (
                  iconSuccess
                ) : (
                  <i onClick={this.handleShowNewPassword} className="fas fa-lock"></i>
                )}
              </div>
            </div>
            <div
              style={{ width: sandwich_menu_btn ? '27vw' : '30vw' }}
              className={
                (initialValues.old_password &&
                  hasError &&
                  !initialValues.confirm_password) ||
                (hasError && !initialValues.confirm_password)
                  ? `security_info_form_item type3`
                  : initialValues.new_password &&
                    initialValues.confirm_password &&
                    !initialValues.new_password.localeCompare(
                      initialValues.confirm_password,
                    )
                  ? 'security_info_form_item type4'
                  : 'security_info_form_item'
              }
            >
              <label>Confirmer mot de passe</label>
              <div className="input_and_icon">
                <input
                  id="standard-adornment-password"
                  name="new_password"
                  type={showRepeatPassword ? 'text' : 'password'}
                  value={initialValues.confirm_password}
                  onChange={(data) => {
                    if (initialValues.new_password.localeCompare(data.target.value)) {
                      this.setState({
                        ...initialValues,
                        hasError: true,
                      });
                    }
                    this.setState({
                      initialValues: {
                        ...initialValues,
                        confirm_password: data.target.value,
                      },
                    });
                  }}
                  placeholder="confirmer mot de passe. . . "
                />
                {(initialValues.old_password &&
                  hasError &&
                  !initialValues.confirm_password) ||
                (hasError && !initialValues.confirm_password) ||
                (initialValues.confirm_password && hasErrorNewPassword) ? (
                  iconError
                ) : initialValues.new_password &&
                  initialValues.confirm_password &&
                  !initialValues.new_password.localeCompare(
                    initialValues.confirm_password,
                  ) ? (
                  iconSuccess
                ) : (
                  <i onClick={this.handleShowRepeatPassword} className="fas fa-lock"></i>
                )}
              </div>
            </div>
          </div>
          {/*  <div className="address_form">
            <div className="title_and_button">
              <span>Question de sécurité</span>
            </div>

            <div className="security_info_actual_form">
              <InputField
                width={sandwich_menu_btn ? '27vw' : '30vw'}
                className="security_info_form_item"
                type="text"
                placeholder="Question"
                label="Question"
                //hasError={hasError}
                validInput=""
                value={initialValues.security_question}
                name="security_question"
                icon={<i className="fas fa-shield-alt"></i>}
                required={this.validator.message(
                  'security_question',
                  initialValues.security_question,
                  'required|string',
                )}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      security_question: e.target.value,
                    },
                  });
                }}
              />
              <InputField
                width={sandwich_menu_btn ? '27vw' : '30vw'}
                className="security_info_form_item"
                type="text"
                placeholder="Réponse"
                label="Réponse"
                // hasError={hasError}
                validInput=""
                value={initialValues.security_answer}
                name="security_answer"
                icon={<i className="fas fa-shield-alt"></i>}
                required={this.validator.message(
                  'security_answer',
                  initialValues.security_answer,
                  'required|string',
                )}
                onChange={(e) => {
                  this.setState({
                    initialValues: {
                      ...initialValues,
                      security_answer: e.target.value,
                    },
                  });
                }}
              />
            </div>
          </div>*/}
        </form>
        <div className="footer_buttons">
          <button onClick={this.handleInitialValues} className="footer_btn" id="cancel">
            Annuler
          </button>
          <button
            style={{
              marginRight: sandwich_menu_btn ? '8%' : '10%',
            }}
            //disabled={!initialValues.email || !initialValues.lastName || !initialValues.firstName}
            className="footer_btn"
            id="update"
            onClick={() => {
              this.submitForm(initialValues);
            }}
          >
            {isLoadingChangePassword ? (
              <CircularProgress
                style={{
                  marginLeft: '4%',
                  width: '25px',
                  height: '25px',
                  color: 'white',
                  marginTop: '5%',
                }}
              />
            ) : (
              'Valider'
            )}
          </button>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Securities);
