import React, { Component } from 'react';
import { codeBureauCNAM } from '../../../utils/constants';
import moment from 'moment';
import Modals from '../../../components/modal';
import { Link } from 'react-router-dom';
import InputField from '../../../components/inputField';
import SimpleReactValidator from 'simple-react-validator';

class Facture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setIsOpen: false,
      setIsOpenSendMail: false,
      hasError: false,
      initialValues: {
        id: props.receipt.id,
        email:
          props.receipt.patient_record && props.receipt.patient_record.patient
            ? props.receipt.patient_record.patient.email
            : props.receipt &&
              props.receipt.consultation &&
              props.receipt.consultation.patient_record &&
              props.receipt.consultation.patient_record.patient
            ? props.receipt.consultation.patient_record.patient.email
            : '',
        file_name: `F°${props.receipt.number}.pdf`,
        last_name:
          props.receipt.patient_record && props.receipt.patient_record.patient
            ? props.receipt.patient_record.patient.first_name
            : props.receipt &&
              props.receipt.consultation &&
              props.receipt.consultation.patient_record &&
              props.receipt.consultation.patient_record.patient
            ? props.receipt.consultation.patient_record.patient.first_name
            : '-',
        number: `F°${props.receipt.number}`,
      },
    };
    this.openModal = this.openModal.bind(this);
    this.openModalSendMail = this.openModalSendMail.bind(this);
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        required: 'Champ requis.',
        // OR
        default: 'Cette information est requise.', // will override all messages
      },
    });
  }

  openModal() {
    this.setState({
      setIsOpen: !this.state.setIsOpen,
    });
  }
  openModalSendMail() {
    this.setState({
      setIsOpenSendMail: !this.state.setIsOpenSendMail,
    });
  }
  async submitForm(initialValues) {
    if (!initialValues.email)
      this.setState({
        ...initialValues,
        hasError: true,
      });
    else if (this.validator.allValid()) {
      await this.props.submitSendReceipt(initialValues);
      this.setState({
        setIsOpenSendMail: !this.state.setIsOpenSendMail,
      });
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  render() {
    const { receipt, tag, office } = this.props;
    const { setIsOpen, setIsOpenSendMail, initialValues, hasError } = this.state;
    return (
      <div className="container_changeable_content">
        <div className="fatcure_detailed_info_section">
          <div className="patient_detailed_info_section_header">
            <div className="patient_info">
              <div className="facture_general_info">
                <span id="facture_general_info_name">{`R°${receipt.number}`}</span>
                <span id="facture_general_info_city">
                  {moment(receipt.date).format('DD/MM/YYYY')}
                </span>
              </div>
              <div className="facture_general_info_btns">
                <Link
                  // target="_blank"
                  to={{
                    pathname: '/receipt-pdf',
                    state: {
                      facture: receipt,
                      office: office,
                      tag: tag,
                    },
                  }}
                >
                  <div className="np_btn">
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.5 0.336914V3.3252H3.5V0.336914H12.5ZM12.7109 6.87598C12.8516 7.0166 13.0273 7.08691 13.2383 7.08691C13.4492 7.08691 13.625 7.0166 13.7656 6.87598C13.9297 6.73535 14.0117 6.55957 14.0117 6.34863C14.0117 6.1377 13.9297 5.96191 13.7656 5.82129C13.625 5.65723 13.4492 5.5752 13.2383 5.5752C13.0273 5.5752 12.8516 5.65723 12.7109 5.82129C12.5703 5.96191 12.5 6.1377 12.5 6.34863C12.5 6.55957 12.5703 6.73535 12.7109 6.87598ZM10.9883 12.3252V8.59863H5.01172V12.3252H10.9883ZM13.2383 4.09863C13.8477 4.09863 14.375 4.32129 14.8203 4.7666C15.2656 5.21191 15.4883 5.73926 15.4883 6.34863V10.8486H12.5V13.8369H3.5V10.8486H0.511719V6.34863C0.511719 5.73926 0.734375 5.21191 1.17969 4.7666C1.625 4.32129 2.15234 4.09863 2.76172 4.09863H13.2383Z"
                        fill="#FAFBFF"
                      />
                    </svg>

                    <span>Imprimer</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="patient_detailed_info_section_body">
            <div className="facture_payment_details">
              <div className="facture_payment_details_container">
                {/*<div className="patient_detailed_info_section_body_item patient_info_email">
                  <div className="patient_detail_props">
                    <div className="email_label_container" id="patient_email">
                      <span id="patient_detail_props_label">Doit à</span>
                    </div>

                    <span id="patient_detail_props_text">
                      {(receipt.consultation &&
                        receipt.consultation.prise_en_charges &&
                        receipt.consultation.prise_en_charges.length > 0 &&
                        codeBureauCNAM[
                          receipt.consultation.prise_en_charges[0].code_bureau
                        ]) ||
                        '-'}
                    </span>
                  </div>
                </div>*/}
                <div className="patient_detailed_info_section_body_item patient_info_birthday">
                  <div className="patient_detail_props">
                    <div className="patient_detail_props">
                      <div className="birthday_label_container" id="patient_birthday">
                        <span id="patient_detail_props_label">Patient</span>
                      </div>

                      <span id="patient_detail_props_text">
                        {receipt.patient_record && receipt.patient_record.patient ? (
                          `${receipt.patient_record.patient.last_name} ${receipt.patient_record.patient.first_name}`
                        ) : receipt.consultation &&
                          receipt.consultation.patient_record &&
                          receipt.consultation.patient_record.patient ? (
                          `${receipt.consultation.patient_record.patient.last_name} ${receipt.consultation.patient_record.patient.first_name}`
                        ) : (
                          <strong style={{ color: '#8080803d' }}>
                            Patient non renseignée
                          </strong>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                {/*<div className="patient_detailed_info_section_body_item patient_info_cnam">
                  <div className="patient_detail_props">
                    <div className="patient_detail_props">
                      <div className="cnam_label_container" id="patient_cnam">
                        <span id="patient_detail_props_label">N° PEC</span>
                      </div>

                      <span id="patient_detail_props_text">
                        {receipt.consultation &&
                        receipt.consultation.prise_en_charges &&
                        receipt.consultation.prise_en_charges.length > 0 &&
                        receipt.consultation.prise_en_charges[0].code_bureau ? (
                          `${receipt.consultation.prise_en_charges[0].code_bureau}/${receipt.consultation.prise_en_charges[0].year}/${receipt.consultation.prise_en_charges[0].num_order}`
                        ) : (
                          <strong style={{ color: '#8080803d' }}>
                            PEC non renseignée
                          </strong>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="patient_detailed_info_section_body_item patient_info_cnam">
                  <div className="patient_detail_props">
                    <div className="patient_detail_props">
                      <div className="cnam_label_container" id="patient_cnam">
                        <span id="patient_detail_props_label">Qualité</span>
                      </div>

                      <span id="patient_detail_props_text">
                        {receipt.consultation &&
                        receipt.consultation.prise_en_charges &&
                        receipt.consultation.prise_en_charges.length > 0 &&
                        receipt.consultation.prise_en_charges[0].quality ? (
                          receipt.consultation.prise_en_charges[0].quality
                        ) : (
                          <strong style={{ color: '#8080803d' }}>
                            Qualité non renseignée
                          </strong>
                        )}
                      </span>
                    </div>
                  </div>
                </div>*/}
              </div>
            </div>

            <hr
              style={{
                marginLeft: '150px',
                marginRight: '50px',
                marginTop: '20px',
                marginBottom: '20px',
                width: '73%',
              }}
            />

            <div className="payment_checkout">
              {/*  <div className="code_facture">
                <span>{receipt.line_receipt.matricule || '-'}</span>
              </div>*/}
              <div className="event">
                <span>{receipt.line_bill.act || '-'}</span>
              </div>
              <div className="date">
                <span>
                  {receipt.start_date ? (
                    ` Du ${moment(receipt.start_date).format('DD/MM/YYYY')}`
                  ) : (
                    <strong style={{ color: 'rgba(0,0,0,0.24)' }}>
                      Date début non renseignée
                    </strong>
                  )}
                </span>
                <span>
                  {receipt.end_date ? (
                    ` Au ${moment(receipt.end_date).format('DD/MM/YYYY')}`
                  ) : (
                    <strong style={{ color: 'rgba(0,0,0,0.24)' }}>
                      Date fin non renseignée
                    </strong>
                  )}
                </span>
              </div>
              <div className="seance">
                <span>{receipt.line_bill.sessions_nbr || '-'} séances</span>
              </div>
              <div className="prix" style={{ marginLeft: '13%' }}>
                <span>{parseFloat(receipt.total_ttc).toFixed(2) || '-'} DT</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Facture;
