import React, { Component } from 'react';
import InputField from '../../../components/inputField';
import CircularProgress from '@material-ui/core/CircularProgress';
import SimpleReactValidator from 'simple-react-validator';

class ForminitialValues extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      messages: {
        required: 'Champ requis.',
        integer: 'Champ ne doit contenir que des chiffres',
        string: 'Champ ne doit contenir que des lettres',
        // OR
        default: 'Cette information est requise.', // will override all messages
      },
    });
  }

  render() {
    const { loadingGetDispoDoctor, patient, hasError, errorDate, initialValues } =
      this.props;
    return (
      <form>
        <div className="form-row">
          <div style={{ width: '46%', display: 'flex' }}>
            <InputField
              width="30%"
              className="add_patient_form_item"
              type="text"
              placeholder="10"
              label="Nbr de séances"
              hasError={hasError}
              value={initialValues.sessions_count || ''}
              name="sessions_count"
              icon={<i className="fas fa-pen"></i>}
              validInput="integerSessionC"
              required={this.validator.message(
                'sessions_count',
                initialValues.sessions_count,
                'required',
              )}
              onChange={this.props.handleChangeInput}
            />
            <InputField
              maxLength={1}
              width="30%"
              className="add_patient_form_item"
              type="text"
              label="S/semaine"
              placeholder="S/semaine"
              hasError={hasError}
              value={initialValues.sessions_per_week || ''}
              name="sessions_per_week"
              icon={<i className="fas fa-pen"></i>}
              validInput="integerNbrS"
              required={this.validator.message(
                'sessions_per_week',
                initialValues.sessions_per_week,
                'required',
              )}
              onChange={this.props.handleChangeInput}
            />
            <InputField
              width="40%"
              className="add_patient_form_item"
              type="text"
              placeholder="12.00"
              label="Cout unitaire"
              hasError={hasError}
              value={initialValues.unit_price}
              name="unit_price"
              icon={<i className="fas fa-pen"></i>}
              validInput="integer"
              required={this.validator.message(
                'unit_price',
                initialValues.unit_price,
                'required',
              )}
              onChange={this.props.handleChangeInput}
            />
          </div>
          <div style={{ width: '47%', display: 'flex' }}>
            <InputField
              width="100%"
              className="add_patient_form_item"
              type="date"
              placeholder="Date de début"
              label="Date de début"
              errorDate={errorDate}
              hasError={hasError}
              value={initialValues.start_date}
              name="start_date"
              icon=""
              required={this.validator.message(
                'start_date',
                initialValues.start_date,
                'required',
              )}
              onChange={this.props.handleChangeInput}
            />
           {/* <InputField
              width="50%"
              className="add_patient_form_item"
              type="date"
              placeholder="Date de fin"
              label="Date de fin"
              disabled={true}
              value={initialValues.end_date}
              icon=""
            />*/}
          </div>
        </div>
        <br />

        <div className="form-row">
          <InputField
            width="46%"
            className="form-group"
            type="text"
            placeholder="N° Assuré"
            label="N° Assuré"
            validInput="string"
            disabled={true}
            value={
              patient.cnam_root && patient.cnam_key
                ? `${patient.cnam_root}/${patient.cnam_key}`
                : 'N° Assuré non renseignée'
            }
            icon={<i className="fas fa-pen"></i>}
          />
          <div
            style={{ width: '46%' }}
            className={
              hasError && !initialValues.quality ? 'form-group type3' : 'form-group'
            }
          >
            <label htmlFor="">Qualité</label>
            <div className="input_and_icon">
              <select
                value={initialValues.quality}
                name="quality"
                onChange={this.props.handleChangeInput}
                className="form-control"
                id="exampleFormControlSelect1"
              >
                <option>Qualité</option>
                <option value="Assuré lui même">Assuré lui même</option>
                <option value="Conjoint">Conjoint</option>
                <option value="Enfant">Enfant</option>
                <option value="Mére">Mère</option>
                <option value="Père">Père</option>
              </select>
            </div>
          </div>
        </div>
        <div className="form_subtitle">
          <span>Décision</span>
        </div>
        <div className="form-row">
          <div style={{ width: '46%', display: 'flex' }}>
            <InputField
              width="20%"
              className="add_patient_form_item"
              type="text"
              placeholder="XX"
              label="Code bureau"
              maxLength={2}
              hasError={hasError}
              value={initialValues.code_bureau || ''}
              validInput="integerCnamKey"
              name="code_bureau"
              icon={<i className="fas fa-key"></i>}
              onChange={this.props.handleChangeInput}
            />
            <InputField
              width="30%"
              className="add_patient_form_item"
              type="text"
              placeholder="XXXX"
              label="Année"
              maxLength={4}
              hasError={hasError}
              value={initialValues.year || ''}
              validInput="integerYear"
              name="year"
              icon={<i className="fas fa-dot-circle"></i>}
              onChange={this.props.handleChangeInput}
            />
            <InputField
              width="50%"
              className="add_patient_form_item"
              type="text"
              placeholder="XXXXXX"
              label="N° ordre"
              maxLength={6}
              hasError={hasError}
              value={initialValues.num_order || ''}
              validInput="integerNumOrder"
              name="num_order"
              icon={<i className="fas fa-dot-circle"></i>}
              onChange={this.props.handleChangeInput}
            />
          </div>
          <InputField
            width="46%"
            className="add_patient_form_item"
            type="text"
            placeholder="Combiné automatiquement"
            label="N° décision"
            maxLength={10}
            hasError={hasError}
            value={
              initialValues.num_order && initialValues.year && initialValues.code_bureau
                ? `${initialValues.code_bureau}/${initialValues.year}/${initialValues.num_order}`
                : ''
            }
            disabled={true}
            icon={<i className="fas fa-dot-circle"></i>}
          />
        </div>

        <div className="footer_buttons">
          <button
            type="button"
            onClick={() => {
              this.props.goBack();
            }}
            className="footer_btn"
            id="cancel"
          >
            Annuler
          </button>
          <button
            style={{ marginRight: '3%', width: '285px' }}
            onClick={() => {
              this.props.submitPEC(initialValues);
            }}
            type="button"
            className="footer_btn"
            id="update"
          >
            {loadingGetDispoDoctor ? (
              <CircularProgress
                style={{
                  marginLeft: '4%',
                  width: '25px',
                  height: '25px',
                  color: 'white',
                  marginTop: '5%',
                }}
              />
            ) : (
              'Générer le calendrier CNAM'
            )}
          </button>
        </div>
      </form>
    );
  }
}
export default ForminitialValues;
