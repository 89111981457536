import React, { Component } from 'react';
import { SelectorBilanItemSection, SelectorColorInputCheckbox } from '../../../../utils';
import UploadFile from '../../upload_file';
class Administartif extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEntretien: true,
      showDouleur: false,
      showBilanArticulaire: false,
      showScore: false,
    };
    this.handleChangeSubSectionBtn = this.handleChangeSubSectionBtn.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    SelectorColorInputCheckbox();
    SelectorBilanItemSection();
  }
  handleChangeSubSectionBtn(name) {
    this.setState({
      [name]: !this.state[name],
    });
  }
  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }
  render() {
    const { sandwich_menu_btn, medecins, initialValues } = this.props;
    return (
      <div className="actual_bilan_settings">
        <div className="actual_bilan_settings_item head_shaking_test" id="headShaking1">
          <div
            style={{ backgroundColor: '#6c89bc' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}> Administartif</span>
          </div>
          <br />
          <div
            style={{ backgroundColor: '#035ef8' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}> Bilan & Prescription</span>
          </div>
          <br />
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Bilan
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="frequence_item_1"
                              name={`frequence_item_3`}
                              value="Initial"
                              onChange={this.props.handleChangeInput}
                              checked={
                                initialValues.frequence_item_3 === 'Initial'
                                  ? true
                                  : false
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_1`}
                            >
                              Initial
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              value="Intermédiare"
                              className="form-check-input"
                              type="radio"
                              name={`frequence_item_3`}
                              id={`frequence_item_2`}
                              checked={
                                initialValues.frequence_item_3 === 'Intermédiare'
                                  ? true
                                  : false
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_2`}
                            >
                              Intermédiare
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              checked={
                                initialValues.frequence_item_3 === 'Final' ? true : false
                              }
                              onChange={this.props.handleChangeInput}
                              name={`frequence_item_3`}
                              value="Final"
                              className="form-check-input"
                              type="radio"
                              id={`frequence_item_3`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_3`}
                            >
                              Final
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ textAlign: 'center' }}
                      className="field_sub_section_container_item"
                    >
                      <div className="additional_container_item_field">
                        <input
                          onChange={this.props.handleChangeInput}
                          maxLength="500"
                          style={{ width: '60%' }}
                          type="date"
                          name="b_1_2"
                          value={initialValues.b_1_2}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Prescription
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <label>Date</label>
                      <div className="additional_container_item_field">
                        <input
                          onChange={this.props.handleChangeInput}
                          maxLength="500"
                          style={{ width: '60%' }}
                          type="date"
                          name="b_1_date"
                          value={initialValues.b_1_date}
                        />
                      </div>
                    </div>
                    <div className="field_sub_section_container_item">
                      <label>Médecin</label>
                      <div className="additional_container_item_field">
                        <select
                          onChange={this.props.handleChangeInput}
                          value={initialValues.medecin_name}
                          name="medecin_name"
                          style={{ width: '60%' }}
                        >
                          <option>Choisir un médecin</option>
                          {medecins &&
                            medecins.length > 0 &&
                            medecins.map((medecin) => {
                              return (
                                <option
                                  value={`${medecin.first_name} ${medecin.last_name}`}
                                >
                                  {`${medecin.first_name} ${medecin.last_name}`}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Description
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <label>Description</label>
                      <div className="additional_container_item_field">
                        <textarea
                          onChange={this.props.handleChangeInput}
                          maxLength="500"
                          style={{ width: '60%' }}
                          value={initialValues.textarea_1}
                          name="textarea_1"
                          placeholder="Description"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundColor: '#035ef8' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}> Séance</span>
          </div>
          <br />
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Prescription quantitative
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="radio"
                              id="frequence_item_x1"
                              name="check_seance_1"
                              value="Oui"
                              checked={
                                initialValues.check_seance_1 === 'Oui' ? true : false
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_x1`}
                            >
                              Oui
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              value="Non"
                              className="form-check-input"
                              type="radio"
                              name="check_seance_1"
                              id={`frequence_item_c2`}
                              checked={
                                initialValues.check_seance_1 === 'Non' ? true : false
                              }
                              //  checked={initialValues.check_seance_2}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_c2`}
                            >
                              Non
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Nombre total
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="additional_container_item_field">
                        <input
                          onChange={this.props.handleChangeInput}
                          maxLength="500"
                          style={{ width: '60%' }}
                          type="number"
                          name="text_seance_1"
                          placeholder=""
                          value={initialValues.text_seance_1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Rythme
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="additional_container_item_field">
                        <input
                          onChange={this.props.handleChangeInput}
                          value={initialValues.text_seance_2}
                          maxLength="500"
                          style={{ width: '60%' }}
                          name="text_seance_2"
                          type="number"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              onChange={this.props.handleChangeInput}
                              className="form-check-input"
                              type="radio"
                              id="frequence_item_xx1"
                              name="check_seance_3"
                              value="/ semaine"
                              checked={
                                initialValues.check_seance_3 === '/ semaine'
                                  ? true
                                  : false
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_xx1`}
                            >
                              / semaine
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              value="/ mois"
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_seance_3"
                              checked={
                                initialValues.check_seance_3 === '/ mois' ? true : false
                              }
                              id={`frequence_item_cd2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_cd2`}
                            >
                              / mois
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Lieu
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="frequence_item_xxx1"
                              onChange={this.props.handleChangeInput}
                              name="check_seance_5"
                              //checked={initialValues.check_seance_5}
                              checked={
                                initialValues.check_seance_5 === '/ Cabinet'
                                  ? true
                                  : false
                              }
                              value="/ Cabinet"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_xxx1`}
                            >
                              / Cabinet
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_seance_5"
                              value="/ Domicile"
                              checked={
                                initialValues.check_seance_5 === '/ Domicile'
                                  ? true
                                  : false
                              }
                              id={`frequence_item_xcd2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_xcd2`}
                            >
                              / Domicile
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Diagnostic
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="additional_container_item_field">
                        <textarea
                          onChange={this.props.handleChangeInput}
                          name="text_seance_3"
                          value={initialValues.text_seance_3}
                          maxLength="500"
                          style={{ width: '60%' }}
                          placeholder=""
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Y a-t-il eu des séances antérieures ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="frequence_item_xxxx1"
                              onChange={this.props.handleChangeInput}
                              name="check_seance_7"
                              checked={
                                initialValues.check_seance_7 === '/ Oui' ? true : false
                              }
                              value="/ Oui"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_xxxx1`}
                            >
                              / Oui
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_seance_7"
                              checked={
                                initialValues.check_seance_7 === '/ Non' ? true : false
                              }
                              value="/ Non"
                              id={`frequence_item_wxcd2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_wxcd2`}
                            >
                              / Non
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_seance_7"
                              checked={
                                initialValues.check_seance_7 === '/ Je ne sais pas'
                                  ? true
                                  : false
                              }
                              value="/ Je ne sais pas"
                              id={`frequence_item_wxscd2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_wxscd2`}
                            >
                              / Je ne sais pas
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Type
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="frequence_item_xxxx1"
                              onChange={this.props.handleChangeInput}
                              name="check_seance_100"
                              checked={
                                initialValues.check_seance_100 === '/ Oui' ? true : false
                              }
                              value="/ Oui"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_xxxx1`}
                            >
                              / Oui
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_seance_100"
                              checked={
                                initialValues.check_seance_100 === '/ Non' ? true : false
                              }
                              value="/ Non"
                              id={`frequence_item_wxcd2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_wxcd2`}
                            >
                              / Non
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Balnéo prescrite
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="frequence_item_xxxx1"
                              onChange={this.props.handleChangeInput}
                              name="check_seance_117"
                              checked={
                                initialValues.check_seance_117 === '/ Oui' ? true : false
                              }
                              value="/ Oui"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_xxxx1`}
                            >
                              / Oui
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_seance_117"
                              checked={
                                initialValues.check_seance_117 === '/ Non' ? true : false
                              }
                              value="/ Non"
                              id={`frequence_item_wxcd2`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`frequence_item_wxcd2`}
                            >
                              / Non
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
              className="actual_bilan_settings_item_body "
              id="headShaking1_sub_section"
              style={{ display: 'flex', width: '100%' }}
          >
            <div
                className="bilan_item"
                style={{
                  width: '50%',
                  backgroundColor: '#f3f4f9',
                }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                      style={{
                        alignItems: 'start',
                      }}
                      className="bilan_contact_info"
                  >
                    <span
                        style={{
                          marginLeft: '3%',
                        }}
                        id="name"
                    >
                      Remarques
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="additional_container_item_field">
                        <textarea
                            onChange={this.props.handleChangeInput}
                            maxLength="500"
                            style={{ width: '60%' }}
                            value={initialValues.textarea_11}
                            name="textarea_11"
                            placeholder="Remarques"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Administartif;
