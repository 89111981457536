import React, { Component } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Faq from "react-faq-component";
const data = {
  title: "Les questions fréquemment posées",
  rows: [
    {
      title: "Je viens de m’inscrire à CUIDA, comment je prends en main l’application ?",
      content: `Rassurez-vous ! Une formation vidéo complète est mise à votre disposition dans la rubrique <strong>Formation</strong>. Aussi, cette foire aux questions répond à vos interrogations majeures.
      Si vous ne trouvez tout de même pas une réponse à vos questions ni dans la formation ni dans la foire aux questions, vous pouvez nous écrire via <strong>le formulaire de contact</strong> ou nous appeler par téléphone, whatsapp ou messenger.`,
    },
    {
      title: "Est-ce que mes données sont confidentielles ?",
      content:
      <div>
        <span>OUI. Forcément !</span><br></br>
        <ul>
          <li>Vos données sont hébergées en TUNISIE, accessibles uniquement via cuida.tn</li>
          <li>Le domaine cuida.tn est sécurisé, le cadenas à côté de l’adresse du site dans la barre d’adresses en témoigne.</li>
          <li>Votre mot de passe est chiffré, vous êtes le seul à le connaître. Veillez toutefois à ne le partager avec personne.</li>
          <li>De la même façon que les données des autres centres ne sont pas visibles pour vous, les vôtres ne sont également pas visibles en dehors de votre espace personnel CUIDA.</li>
          <li>Ni l’équipe CUIDA ni aucun organisme tiers, ne peut en aucun cas deviner vos accès uniques et chiffrés ni accéder à vos données sécurisées et confidentielles.</li>
        </ul>
      </div>
    },
    {
      title: "Est-ce que je peux importer l’historique de mon activité dans mon espace CUIDA ?",
      content: `Cela dépend du format de vos données. N’hésitez pas à prendre contact avec notre équipe qui ne manquera pas de vous aider.`,
    },
    {
      title: "Puis-je créer un compte spécial comptabilité et fiscalité ?",
      content: "OUI. Il suffit de créer un nouveau compte assistant et de lui accorder les permissions nécessaires. En fonction de vos souhaits, vous pouvez par exemple lui permettre d’accéder seulement aux factures et/ou au chiffre d'affaires généré par vos prestations facturées.",
    },
    {
      title: "Combien de comptes puis-je créer dans mon cabinet ?",
      content: 
      <div>
        <span>Ceci dépend de l’abonnement que vous choisissez.</span>
        <ul>
          <li>L’offre KINÉ, étant dédiée aux kinésithérapeutes indépendants, n’offre qu’un seul compte.</li>
          <li>L’offre CENTER, étant dédiée aux centres de kinésithérapie ayant une taille moyenne, offre la possibilité de créer un compte secrétaire, jusqu’à 3 comptes collaborateurs et des comptes assistants sans limite.</li>
          <li>L’offre CENT+, étant dédiée aux centres de kinésithérapie les plus grands, offre la possibilité de créer un compte secrétaire, jusqu’à 5 comptes collaborateurs inclus dans le pack et des comptes assistants sans limite. Au-delà de cinq, chaque collaborateur supplémentaire génère un supplément d’abonnement</li>
        </ul>
      </div>
    },
    {
      title: "Comment puis-je activer mon abonnement CUIDA ?",
      content: "Il suffit de sélectionner votre abonnement préféré sur le paramétrage de votre compte et d’envoyer un justificatif de paiement par email, whatsapp ou messenger. Une fois le justificatif reçu, votre abonnement sera validé sous 48 heures",
    },
    {
      title:"Comment puis-je configurer mon cabinet digital ?",
      content:
      <div>
        <span>Avant de commencer à utiliser l’application dans votre quotidien, il est recommandé de :</span><br></br>
        <ul>
          <li>Compléter votre <a href="/profil" style={{color:'blueviolet'}}><strong>profil</strong></a></li>
          <li>Créer vos <a href="/settings" style={{color:'blueviolet'}}><strong>collaborateurs</strong></a>, <a href="/settings" style={{color:'blueviolet'}}><strong>secrétaire</strong></a> et <a href="/settings" style={{color:'blueviolet'}}><strong>assistants</strong></a></li>
          <li>Configurer <a href="/settings" style={{color:'blueviolet'}}><strong>les permissions</strong></a> que vous souhaitez accorder à chaque compte</li>
          <li>Créer et configurer <a href="/settings" style={{color:'blueviolet'}}><strong>les pathologies</strong></a></li>
          <li>Configurer <a href="/settings" style={{color:'blueviolet'}}><strong>les jours fériés</strong></a> travaillés de l’année</li>
        </ul>
        <span>
        Pour plus des détails, référez-vous à la rubrique <strong>formation</strong>, vous y trouverez des vidéos qui vous démontrent pas à pas comment configurer votre compte
        </span>
      </div>
    },
    {
      title:"Comment puis-je gérer les permissions des différents collaborateurs ?",
      content: 
      <div>
        <span>Sachez que chaque collaborateur, secrétaire ou assistant créé, lui sera accordé par défaut certaines permissions :</span><br></br>
        <ul>
          <li>Aux kinésithérapeutes collaborateurs seront accordées les permissions d’accès et d’usage des modules “Agenda”, “Cures”, “Patients” et “Messagerie”</li>
          <li>A la secrétaire seront accordées les permissions d’accès et d’usage des modules “Agenda”, “Cures”, “Patients”, “Messagerie” et “Comptabilité”. Elle sera toutefois privée, par défaut, d’accéder à la comptabilité en chiffres du module “Comptabilité” ainsi qu’à l’ajout des bilans et protocoles du module “Cures”</li>
          <li>Aux assistants, aucun accès ne sera accordé par défaut. Un assistant nouvellement créé peut accéder seulement à l'accueil de l’application et au support.</li>
        </ul>
      </div>
    },
    {
      title:"Je ne peux pas créer un bordereau pour un de mes patients",
      content:"Il est possible de créer des bordereaux seulement pour les patients affilié de CNAM. Veuillez donc vérifier sur la fiche de votre patient, si c’est le cas pour lui."
    },
    {
      title:"Je tente de créer un nouveau bordereau, mais la listes des factures est incomplète",
      content:
      <div>
        <span>Les bordereaux étant destinés à la CNAM, deux conditions sont nécessaires et suffisantes pour qu’une facture soit ajoutable à un bordereau :</span>
        <ul>
          <li>Il faut qu’elle soit au statut “Payée”. Sinon vous pouvez accéder à la facture et la marquer en payée si c’est réellement le cas.</li>
          <li>Il faut qu’elle soit au nom d’un patient affilié de CNAM.</li>
        </ul>
      </div>
    },
    {
      title:"On ne peut pas planifier un rendez-vous sur l’agenda d’un collaborateur",
      content:"Il faudrait commencer par vérifier si le compte chargé de la planification du rendez-vous possède les permissions nécessaires. Si c’est le cas, il ne reste qu'à vérifier si le collaborateur est déclaré absent sur le créneau sélectionné, ou si ce dernier est déjà réservé pour un autre rendez-vous."
    },
    {
      title:"Je ne peux pas écrire un message à un de mes collaborateurs",
      content:"Vous pouvez échanger via la messagerie seulement avec des collaborateurs, secrétaires ou assistants dont les comptes n’ont pas été désactivés."
    }
  ],
};

const styles = {
  //bgColor: 'white',
  titleTextColor: '#bdbdbd',
  // titleTextSize: '48px',
  rowTitleColor: '#8083a3',
  // rowTitleTextSize: 'medium',
  // rowContentColor: 'grey',
  rowContentTextSize: '16px',
  // rowContentPaddingTop: '10px',
  rowContentPaddingBottom: '10px',
  rowContentPaddingLeft: '20px',
  // rowContentPaddingRight: '150px',
   arrowColor: "#8083a3",
  //transitionDuration: "1s",
  // timingFunc: "ease"
};

const config = {
  animate: true,
  // arrowIcon: "V",
  // tabFocus: true
};
class Faqs extends Component {
  render() {
    return (
      <div className="offers">
        <div className="offre_actuelle" style={{ width: '100%' }}>
          <Faq data={data} styles={styles} config={config} />
          {/*<div className="offre_actuelle_info">
            <span id="offre_actuelle_title">FAQ</span>
            <Accordion>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What harsh truths do you prefer to ignore?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What harsh truths do you prefer to ignore?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What harsh truths do you prefer to ignore?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What harsh truths do you prefer to ignore?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat
                    ut occaecat consequat est minim minim esse tempor laborum consequat
                    esse adipisicing eu reprehenderit enim.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Is free will real or just an illusion?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    In ad velit in ex nostrud dolore cupidatat consectetur ea in ut
                    nostrud velit in irure cillum tempor laboris sed adipisicing eu esse
                    duis nulla non.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>*/}
          {/* La sous section de FAQ */}
        </div>
      </div>
    );
  }
}

export default Faqs;
