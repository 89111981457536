import React, { Component } from 'react';
import SettingMenuItemsProfil from '../../components/settingMenuItemsProfil';

import SimpleReactValidator from 'simple-react-validator';
import { isHasPermission } from '../../utils/helpres';

import Payment from '../payment';
import Bordereaux from '../bordereau';
import Factures from '../facture';
import Recettes from '../recette';
import Depenses from '../depense';
import Receipts from '../receipt';
import Credits from '../credit';

class Compta extends Component {
  constructor(props) {
    super(props);

    props.changeNamePage('Comptabilité en chiffres');
    props.changeActiveItemSideBar(9);
    let actionR = props.location.state;
    this.state = {
      item: 0,
      initialValues: {
        ...props.initialValues,
        alert: false,
      },
      activeTab: '1',
      doctor: null,
      showCompta: actionR
        ? actionR.bAutre === false &&
          actionR.receipt === false &&
          actionR.fAutre === false &&
          actionR.recette === false &&
          actionR.depense === false &&
          isHasPermission(props.user.login_access.permissions, 'ACCOUNTING_STAT_ACCESS')
          ? true
          : false
        : true,
      showB:
        (!isHasPermission(
          props.user.login_access.permissions,
          'ACCOUNTING_STAT_ACCESS',
        ) &&
          isHasPermission(props.user.login_access.permissions, 'CNAM_SLIP_ACCESS')) ||
        (actionR && actionR.bAutre)
          ? true
          : false,
      showF:
        (!isHasPermission(
          props.user.login_access.permissions,
          'ACCOUNTING_STAT_ACCESS',
        ) &&
          !isHasPermission(props.user.login_access.permissions, 'CNAM_SLIP_ACCESS') &&
          isHasPermission(props.user.login_access.permissions, 'BILLS_ACCESS')) ||
        (actionR && actionR.fAutre)
          ? true
          : false,
      showReceipt:
        (!isHasPermission(props.user.login_access.permissions, 'CNAM_SLIP_ACCESS') &&
          !isHasPermission(props.user.login_access.permissions, 'BILLS_ACCESS') &&
          isHasPermission(props.user.login_access.permissions, 'RECEIPT_ACCESS')) ||
        (actionR && actionR.receipt)
          ? true
          : false,
      showDepense:
        (!isHasPermission(props.user.login_access.permissions, 'CNAM_SLIP_ACCESS') &&
          !isHasPermission(props.user.login_access.permissions, 'BILLS_ACCESS') &&
          !isHasPermission(props.user.login_access.permissions, 'RECEIPT_ACCESS') &&
          isHasPermission(props.user.login_access.permissions, 'ACCESS_DEPENSES_LIST')) ||
        (actionR && actionR.depense)
          ? true
          : false,
      showRecette:
        (!isHasPermission(props.user.login_access.permissions, 'CNAM_SLIP_ACCESS') &&
          !isHasPermission(props.user.login_access.permissions, 'BILLS_ACCESS') &&
          !isHasPermission(props.user.login_access.permissions, 'RECEIPT_ACCESS') &&
          !isHasPermission(props.user.login_access.permissions, 'ACCESS_DEPENSES_LIST') &&
          isHasPermission(props.user.login_access.permissions, 'ACCESS_RECETTE_LIST')) ||
        (actionR && actionR.recette)
          ? true
          : false,
      showCredit:
        (!isHasPermission(props.user.login_access.permissions, 'CNAM_SLIP_ACCESS') &&
          !isHasPermission(props.user.login_access.permissions, 'BILLS_ACCESS') &&
          !isHasPermission(props.user.login_access.permissions, 'RECEIPT_ACCESS') &&
          !isHasPermission(props.user.login_access.permissions, 'ACCESS_DEPENSES_LIST') &&
          !isHasPermission(props.user.login_access.permissions, 'ACCESS_RECETTE_LIST') &&
          isHasPermission(props.user.login_access.permissions, 'ACCESS_CREDITS_LIST')) ||
        (actionR && actionR.credit)
          ? true
          : false,

      file: null,
      isOpenToggleDiplome: false,
      isOpenToggleFormation: false,
      hasError: false,
    };

    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Adresse e-mail invalide',
        // OR
        default: 'Cette information est requise.', // will override all messages
      },
    });
    this.HandelChangePage = this.HandelChangePage.bind(this);
    this.handleChangeFiles = this.handleChangeFiles.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  HandelChangePage(item) {
    const { employeeManagement } = this.props;
    switch (item) {
      case 0:
        this.setState({
          showCompta: true,
          showB: false,
          showF: false,
          showReceipt: false,
          showDepense: false,
          showRecette: false,
          showCredit: false,
        });
        break;
      case 1:
        this.setState({
          showCompta: false,
          showB: true,
          showF: false,
          showReceipt: false,
          showDepense: false,
          showRecette: false,
          showCredit: false,
        });
        break;
      case 2:
        this.setState({
          showCompta: false,
          showB: false,
          showF: true,
          showReceipt: false,
          showDepense: false,
          showRecette: false,
          showCredit: false,
        });
        break;
      case 3:
        this.setState({
          showCompta: false,
          showB: false,
          showF: false,
          showReceipt: true,
          showDepense: false,
          showRecette: false,
          showCredit: false,
        });
        break;
      case 4:
        this.setState({
          showCompta: false,
          showB: false,
          showF: false,
          showReceipt: false,
          showDepense: true,
          showRecette: false,
          showCredit: false,
        });
        break;
      case 5:
        this.setState({
          showCompta: false,
          showB: false,
          showF: false,
          showReceipt: false,
          showDepense: false,
          showRecette: true,
          showCredit: false,
        });
        break;
      case 6:
        this.setState({
          showCompta: false,
          showB: false,
          showF: false,
          showReceipt: false,
          showDepense: false,
          showRecette: false,
          showCredit: true,
        });
        break;
      default:
        this.setState({
          showCompta: true,
          showB: false,
          showF: false,
          showReceipt: false,
          showDepense: false,
          showRecette: false,
        });
    }
  }

  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }

  handleChangeFiles = (event, name) => {
    const fileUploaded = event.target.files[0];
    this.setState({
      [name]: fileUploaded,
    });
  };

  render() {
    const {
      showB,
      showCompta,
      showCredit,
      showDepense,
      showRecette,
      showReceipt,
      showF,
      item,
    } = this.state;
    const { user, admin } = this.props;
    return (
      <div className="main_content" style={{ paddingLeft: 0 }}>
        <div className="user_info">
          <div className="settings_menu">
            {isHasPermission(user.login_access.permissions, 'ACCOUNTING_STAT_ACCESS') ? (
              <>
                <SettingMenuItemsProfil
                  activate={showCompta}
                  item={0}
                  HandelChangePage={this.HandelChangePage}
                  icon=""
                  name="Comptabilité en chiffres"
                  imgColor={require('../../assets/img/icons8-insert-card-96.png')}
                  img={require('../../assets/img/icons8-insert-card-96.png')}
                  //description="Détails proprietaire &amp; centre"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(user.login_access.permissions, 'CNAM_SLIP_ACCESS') ? (
              <>
                <SettingMenuItemsProfil
                  item={1}
                  activate={showB}
                  HandelChangePage={this.HandelChangePage}
                  imgColor={require('../../assets/img/icons8-bill-96 (1).png')}
                  img={require('../../assets/img/icons8-bill-96 (1).png')}
                  name="Bordereaux"
                  //description="Autres kinés dans le centre"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ? (
              <>
                <SettingMenuItemsProfil
                  item={2}
                  activate={showF}
                  HandelChangePage={this.HandelChangePage}
                  imgColor={require('../../assets/img/facture_color.svg')}
                  img={require('../../assets/img/facture_grey.svg')}
                  name="Factures diverses"
                  //description="Ajouter, modifier une secrétaire"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(user.login_access.permissions, 'RECEIPT_ACCESS') ? (
              <>
                <SettingMenuItemsProfil
                  item={3}
                  activate={showReceipt}
                  HandelChangePage={this.HandelChangePage}
                  imgColor={require('../../assets/img/facture_color.svg')}
                  img={require('../../assets/img/facture_grey.svg')}
                  name="Reçus divers"
                  //description="Ajouter, modifier un assistant"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(user.login_access.permissions, 'ACCESS_DEPENSES_LIST') ? (
              <>
                <SettingMenuItemsProfil
                  item={4}
                  HandelChangePage={this.HandelChangePage}
                  imgColor={require('../../assets/img/facture_color.svg')}
                  img={require('../../assets/img/facture_grey.svg')}
                  name="Dépenses"
                  activate={showDepense}
                  //description="Ajouter, modifier une pathologie"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(user.login_access.permissions, 'ACCESS_RECETTE_LIST') ? (
              <>
                <SettingMenuItemsProfil
                  item={5}
                  HandelChangePage={this.HandelChangePage}
                  imgColor={require('../../assets/img/facture_color.svg')}
                  img={require('../../assets/img/facture_grey.svg')}
                  name="Recettes"
                  activate={showRecette}
                  //description="Ajouter, modifier une pathologie"
                />
                <hr />
              </>
            ) : (
              ''
            )}
            {isHasPermission(user.login_access.permissions, 'ACCESS_CREDITS_LIST') ? (
              <>
                <SettingMenuItemsProfil
                  item={6}
                  HandelChangePage={this.HandelChangePage}
                  imgColor={require('../../assets/img/facture_color.svg')}
                  img={require('../../assets/img/facture_grey.svg')}
                  name="Crédits"
                  activate={showCredit}
                  //description="Ajouter, modifier une pathologie"
                />
                <hr />
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="user_settings">
          <div className="container_changeable_content">
            {showCompta &&
            isHasPermission(user.login_access.permissions, 'ACCOUNTING_STAT_ACCESS') ? (
              <Payment
                admin={admin}
                type="autre"
                changeActiveItemSideBarCompta={this.props.changeActiveItemSideBarCompta}
                changeNamePage={this.props.changeNamePage}
              />
            ) : showB &&
              isHasPermission(user.login_access.permissions, 'CNAM_SLIP_ACCESS') ? (
              <Bordereaux
                active={9}
                type="autre"
                changeActiveItemSideBarCompta={this.props.changeActiveItemSideBarCompta}
                changeNamePage={this.props.changeNamePage}
              />
            ) : showF &&
              isHasPermission(user.login_access.permissions, 'BILLS_ACCESS') ? (
              <Factures
                selectedId={
                  this.props.location &&
                  this.props.location.state &&
                  this.props.location.state.facture_id
                    ? this.props.location.state.facture_id
                    : null
                }
                type="autre"
                active={9}
                changeActiveItemSideBarCompta={this.props.changeActiveItemSideBarCompta}
                changeNamePage={this.props.changeNamePage}
              />
            ) : showReceipt &&
              isHasPermission(user.login_access.permissions, 'RECEIPT_ACCESS') ? (
              <Receipts
                selectedId={
                  this.props.location &&
                  this.props.location.state &&
                  this.props.location.state.facture_id
                    ? this.props.location.state.facture_id
                    : null
                }
                type="cnam"
                changeNamePage={this.props.changeNamePage}
                changeActiveItemSideBarCompta={this.props.changeActiveItemSideBarCompta}
              />
            ) : showDepense &&
              isHasPermission(user.login_access.permissions, 'ACCESS_DEPENSES_LIST') ? (
              <Depenses
                type="act"
                changeActiveItemSideBarCompta={this.props.changeActiveItemSideBarCompta}
                changeNamePage={this.props.changeNamePage}
              />
            ) : showRecette &&
              isHasPermission(user.login_access.permissions, 'ACCESS_RECETTE_LIST') ? (
              <Recettes
                admin={admin}
                user={user}
                type="act"
                changeActiveItemSideBarCompta={this.props.changeActiveItemSideBarCompta}
                changeNamePage={this.props.changeNamePage}
              />
            ) : showCredit &&
              isHasPermission(user.login_access.permissions, 'ACCESS_CREDITS_LIST') ? (
              <Credits
                type="act"
                changeActiveItemSideBarCompta={this.props.changeActiveItemSideBarCompta}
                changeNamePage={this.props.changeNamePage}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Compta;
