import React, { Component } from 'react';

class VersionsCuida extends Component {
  render() {
    return (
      <div style={{ width: '100%', margin: '1% 2%' }}>
        <div className="contact_us" style={{ height: '100%' }}>
          <div className="contact_us_text">
            <span id="contact_us_title" style={{ fontWeight: 'bold' }}>
              CUIDA évolue chaque jour, grâce à notre équipe dédiée à votre écoute.
            </span>
            <span id="contact_us_subtext">
              Vous et/ou d'autres centres avez demandé les fonctions ci-dessous. Nos
              ingénieurs ont déjà commencé à les traiter.
            </span>
          </div>
          <div className="faq-sect-col-elem" style={{ padding: '8px' }}>
            <div>
              <h5 style={{ color: '#5446db', fontWeight: 'bold' }}>
                Afficher les “Etats des comptes” de vos patients
              </h5>
              <span>Chaque patient de votre centre doit payer ses cures.</span>
              <br></br>
              <span>
                Pour un suivi facile et efficace, on vous affichera l’état de compte de
                chaque patient :
              </span>
              <br></br>
              <ul>
                <li>
                  Le prix total de ses cures (déjà réalisée comme celles planifiées)
                </li>
                <li>Combien il vous a avancé (la valeur de sa cagnotte)</li>
                <li>Combien il a payé jusqu'à l'heure présente</li>
                <li>Combien il lui reste à payer.</li>
              </ul>
              <span>
                En complément, on affichera aussi les transaction des paiements :
              </span>
              <br></br>
              <ul>
                <li>La date de de chaque paiement</li>
                <li>Le montant payé</li>
                <li>Le mode de paiement</li>
              </ul>
            </div>
            <br></br>
            <div>
              <h5 style={{ color: '#5446db', fontWeight: 'bold' }}>
                Partager les rendez-vous sur les agendas de vos patients
              </h5>
              <p>
                Simplifier la vie à vos patients en leur partageant les rendez-vous
                directement sur leurs agendas. Qu'il ait un agenda google, outlook,
                android, iphone ou autres, un patient recevra l'invitation sans effort
                grâce à un seul clic depuis votre agenda CUIDA.
              </p>
            </div>
            <br></br>
            <div>
              <h5 style={{ color: '#5446db', fontWeight: 'bold' }}>
                Envoyer les bilans par mail à vos médecins prescripteurs
              </h5>
              <p>
                Pour donner à votre centre l'image qu'il mérite et la déployer dans votre
                réseau professionnel, CUIDA vous permettra dès les jours à venir d'envoyer
                vos bilans à vos médecins prescripteurs. Un seul clic vous suffira pour
                transmettre un bilan au format pdf avec les coordonnées de votre centre.
              </p>
            </div>
            <br></br>
            <div>
              <h5 style={{ color: '#5446db', fontWeight: 'bold' }}>
              Télécharger les données de votre centre
              </h5>
              <p>
                Vos données sont votre patrimoine le plus cher, nous en sommes conscients.
                C'est pour cela que CUIDA vous permettra très prochainement de les
                télécharger à la fréquence que vous souhaitez et de les conserver sur vos
                propres supports.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VersionsCuida;
