const initialUserState = {
  doctors: [],
  isLoadingDoctors: true,
  isLoadingMessages: false,
  countMessagesIsSeen: 0,
  isLoadingconversationAccount: false,
  createdMessage: false,
  conversationAccount: [],
  message: '',
  messages: [],
  params: {
    limit: 6,
    currentPage: 1,
    search: '',
  },
  paramsMessages: {
    currentPage: 1,
    limit: 10,
    search: '',
  },
  isLoadingNewConversation: false,
  newConversation: null,
  isLoadingDeleteConversation: false,
  deleteConversation: false,
  conversationTo: null,
  allUsers: [],
  isLoadingAllUsers: true,
};

const MessengerReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case 'INITIAL_STATE': {
      return {
        ...state,
        deleteConversation: false,
        conversationTo: null,
      };
    }
    case 'GET_USERS_REQUESTING': {
      return {
        ...state,
        isLoadingAllUsers: true,
      };
    }
    case 'GET_USERS_SUCCESS': {
      return {
        ...state,
        isLoadingAllUsers: false,
        allUsers: action.data,
      };
    }
    case 'GET_USERS_FAILURE': {
      return {
        ...state,
        isLoadingAllUsers: false,
        allUsers: [],
      };
    }

    case 'GET_DELETE_CONVERSATION_REQUESTING': {
      return {
        ...state,
        isLoadingDeleteConversation: true,
      };
    }
    case 'GET_DELETE_CONVERSATION_SUCCESS': {
      return {
        ...state,
        isLoadingDeleteConversation: false,
        deleteConversation: true,
        conversationTo: action.data.data,
      };
    }
    case 'GET_DELETE_CONVERSATION_FAILURE': {
      return {
        ...state,
        isLoadingDeleteConversation: false,
        deleteConversation: false,
      };
    }

    case 'GET_DOCTORS_REQUESTING': {
      return {
        ...state,
        isLoadingDoctors: true,
        deleteConversation: false,
      };
    }
    case 'GET_DOCTORS_SUCCESS': {
      return {
        ...state,
        isLoadingDoctors: false,
        deleteConversation: false,
        doctors: action.data,
      };
    }
    case 'GET_DOCTORS_FAILURE': {
      return {
        ...state,
        isLoadingDoctors: false,
        deleteConversation: false,
        doctors: [],
      };
    }
    /**Messenger*/
    case 'GET_MESSAGE_REQUESTING': {
      return {
        ...state,
        isLoadingMessages: true,
        deleteConversation: false,
      };
    }
    case 'GET_MESSAGE_SUCCESS': {
      return {
        ...state,
        isLoadingMessages: false,
        deleteConversation: false,
        messages: action.data,
      };
    }
    case 'GET_MESSAGE_FAILURE': {
      return {
        ...state,
        isLoadingMessages: false,
        deleteConversation: false,
        messages: [],
      };
    }
    case 'CREATE_NEW_CONVERSATION_SUCCESS': {
      return {
        ...state,
        isLoadingNewConversation: false,
        newConversation: true,
      };
    }
    case 'CREATE_NEW_CONVERSATION_FAILURE': {
      return {
        ...state,
        isLoadingNewConversation: false,
        newConversation: null,
      };
    }
    case 'CREATE_NEW_MESSAGE_SUCCESS': {
      return {
        ...state,
        createdMessage: action.data,
        newConversation: false,
      };
    }
    case 'CREATE_NEW_MESSAGE_FAILURE': {
      return {
        ...state,
        newConversation: false,
      };
    }
    case 'GET_COUNT_MESSAGE_IS_SEEN_REQUESTING': {
      return {
        ...state,
        countMessagesIsSeen: 0,
      };
    }
    case 'GET_COUNT_MESSAGE_IS_SEEN_SUCCESS': {
      return {
        ...state,
        error: action.msg,
        countMessagesIsSeen: action.data,
      };
    }
    case 'GET_COUNT_MESSAGE_IS_SEEN_FAILURE': {
      return {
        ...state,
        countMessagesIsSeen: 0,
        message: action.err,
        error: action.msg,
        ErrorMessage: action.ErrorMessage,
      };
    }

    case 'GET_CONSULTATIONS_ACCOUNT_REQUESTING': {
      return {
        ...state,
        isLoadingconversationAccount: true,
        deleteConversation: false,
      };
    }
    case 'GET_CONSULTATIONS_ACCOUNT_SUCCESS': {
      return {
        ...state,
        isLoadingconversationAccount: false,
        deleteConversation: false,
        error: action.msg,
        conversationAccount: action.data,
      };
    }
    case 'GET_CONSULTATIONS_ACCOUNT_FAILURE': {
      return {
        ...state,
        isLoadingconversationAccount: false,
        deleteConversation: false,
        conversationAccount: [],
        message: action.err,
        error: action.msg,
        ErrorMessage: action.ErrorMessage,
      };
    }

    case 'UPDATE_SEARCH_DOCTORS_PARAMS': {
      return {
        ...state,
        params: action.params,
      };
    }
    case 'UPDATE_PARAMS_MESSAGES': {
      return {
        ...state,
        paramsMessages: action.paramsMessages,
      };
    }
    default: {
      return state;
    }
  }
};

export default MessengerReducer;
