import React, { Component } from 'react';
import { SelectorContactFacturesBodyItem } from '../../../utils';

class Item_bill extends Component {
  componentDidMount() {
    if (this.props.item.id === this.props.id) {
      this.props.getReceiptById(this.props.item.id);
    }
    SelectorContactFacturesBodyItem();
  }
  render() {
    const { item, id } = this.props;
    return (
      <div
        onClick={() => {
          this.props.getReceiptById(item.id);
        }}
        key={item.id}
        className={`facture_patient_body_item ${
          id === item.id ? 'selected_patient' : ''
        }`}
      >
        <div className="facture_patient_info_container">
          <div
            // style={{ backgroundColor: selectedBill ? '#f8f9fc' : '#ECEEF5' }}
            className="facture_patient_img_container"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.02734 9.35547C4.41016 8.79297 5.73438 8.51172 7 8.51172C8.26562 8.51172 9.57812 8.79297 10.9375 9.35547C12.3203 9.89453 13.0117 10.6094 13.0117 11.5V13.0117H0.988281V11.5C0.988281 10.6094 1.66797 9.89453 3.02734 9.35547ZM9.10938 6.12109C8.52344 6.70703 7.82031 7 7 7C6.17969 7 5.47656 6.70703 4.89062 6.12109C4.30469 5.53516 4.01172 4.83203 4.01172 4.01172C4.01172 3.19141 4.30469 2.48828 4.89062 1.90234C5.47656 1.29297 6.17969 0.988281 7 0.988281C7.82031 0.988281 8.52344 1.29297 9.10938 1.90234C9.69531 2.48828 9.98828 3.19141 9.98828 4.01172C9.98828 4.83203 9.69531 5.53516 9.10938 6.12109Z"
                fill="#8083A3"
              />
            </svg>
          </div>
          <div className="facture_patient_info">
            <span id="facture_patient_name">
              {item.patient_record && item.patient_record.patient
                ? `${item.patient_record.patient.last_name} ${item.patient_record.patient.first_name}`
                : item.consultation &&
                  item.consultation.patient_record &&
                  item.consultation.patient_record.patient
                ? `${item.consultation.patient_record.patient.last_name} ${item.consultation.patient_record.patient.first_name}`
                : ''}
            </span>
            <span id="facture_patient_city">R°{item.number}</span>
          </div>
        </div>

        <div className="call_icon_bill">
          <i className="fas fa-angle-right"></i>
        </div>
      </div>
    );
  }
}
export default Item_bill;
