import React, { Component } from 'react';
import { SelectorBilanItemSection, SelectorColorInputCheckbox } from '../../utils';
import Modals from '../../components/modal';
import Select from 'react-select';
import update from 'immutability-helper';
import actions from './store/actions';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputField from '../../components/inputField';
import { isNumeric } from '../../utils/helpres';
import axios from 'axios';

const options = [
  { value: 'antalgie', label: 'Antalgie' },
  { value: 'amplitude_articulaire', label: 'Amplitude Articulaire' },
  { value: 'amelioration_de_la_force', label: 'Amélioration de la force' },
  { value: 'amelioration_de_la_stabilite', label: 'Amélioration de la stabilité' },
  {
    value: 'amelioration_des_capacite',
    label: 'Amélioration des capacités cradio-vasculaire',
  },
  {
    value: 'amelioration_des_capacite_motrices',
    label: 'Amélioration des capacités motrices',
  },
  {
    value: 'amelioration_de_la_comprehension',
    label: 'Amélioration de la compréhension de la pathologie',
  },
];
const styles = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
};
const orderOptions = (values) => {
  return values.filter((v) => v.isFixed).concat(values.filter((v) => !v.isFixed));
};
const mapStateToProps = (state) => ({
  addProtocole: state.ConsultationReducer.addProtocole,
  section_protocole: state.ConsultationReducer.section_protocole,
  isLoadingAddProtocole: state.ConsultationReducer.isLoadingAddProtocole,
  paramsBilan: state.ConsultationReducer.paramsBilan,
  isLoadingBilanConsultation: state.ConsultationReducer.isLoadingBilanConsultation,
  bilanConsultation: state.ConsultationReducer.bilanConsultation,
});

const mapDispatchToProps = (dispatch) => ({
  initialState: () => dispatch(actions.initialState()),
  submitFormProtocole: (values) => dispatch(actions.submitFormProtocole(values)),
  getSectionProtocoleFile: (id, consultation_id) =>
    dispatch(actions.getSectionProtocoleFile(id, consultation_id)),
  getBilanByConsultationId: (params, id) =>
    dispatch(actions.getBilanByConsultationId(params, id)),
});

class PlanDeReeducation extends Component {
  constructor(props) {
    super(props);
    if (props.match.params.protocole_id) {
      props.getSectionProtocoleFile(
        props.match.params.protocole_id,
        props.match.params.consultation_id,
      );
      props.getBilanByConsultationId(
        props.paramsBilan,
        props.match.params.consultation_id,
      );
    }

    this.state = {
      showPlan: true,
      showPrescription: false,
      hasError: false,
      showAction: false,
      file: null,
      setIsOpen: false,
      fileName: '',
      isLoading: false,
      consultation_id: props.match.params.consultation_id,
      protocole_id: props.match.params.protocole_id
        ? parseInt(props.match.params.protocole_id)
        : null,
      initialValues: {
        notes: [
          {
            id: 1,
            notes: '',
          },
        ],
        name: '',
        motif_consultation: '',
        repetitions_series: [
          {
            id: 1,
            nom_exercice_1: '',
            repetitions: '',
            series: '',
            Consignes_file_1: null,
            frequence_item_1_1: false,
            consignes_1: '',
            file_1: null,
          },
        ],
      },
    };
    this.handleChangeSubSectionBtn = this.handleChangeSubSectionBtn.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.handleDeleteItemExercice = this.handleDeleteItemExercice.bind(this);
    this.handleChangeInputItem = this.handleChangeInputItem.bind(this);
    this.handleAddNewItem = this.handleAddNewItem.bind(this);
    this.handleChangeAnyInput = this.handleChangeAnyInput.bind(this);
  }
  handleChangeAnyInput = (value, name) => {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        [name]: value,
      },
    });
  };
  handleAddNewItem(element) {
    const { initialValues } = this.state;
    if (
      initialValues[element] &&
      initialValues[element].length > 0 &&
      initialValues[element][initialValues[element].length - 1].nom_exercice &&
      initialValues[element][initialValues[element].length - 1].series &&
      isNumeric(initialValues[element][initialValues[element].length - 1].series) &&
      initialValues[element][initialValues[element].length - 1].repetitions &&
      isNumeric(initialValues[element][initialValues[element].length - 1].series)
    ) {
      this.setState((prevState) => {
        const items = [...prevState.initialValues[element]];
        items.push({
          id: initialValues[element][initialValues[element].length - 1].id + 1,
          [`frequence_item_1_${
            initialValues[element][initialValues[element].length - 1].id + 1
          }`]: '',
          [`consignes_${
            initialValues[element][initialValues[element].length - 1].id + 1
          }`]: '',
          [`Consignes_file_${
            initialValues[element][initialValues[element].length - 1].id + 1
          }`]: '',
          [`file_${initialValues[element][initialValues[element].length - 1].id + 1}`]:
            '',
          [`nom_exercice_${
            initialValues[element][initialValues[element].length - 1].id + 1
          }`]: '',
        });
        this.setState({
          initialValues: {
            ...initialValues,
            [element]: items,
          },
        });
      });
    }
  }
  handleChangeInputItem(event, id, element) {
    const { initialValues } = this.state;
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (target.files) value = target.files[0];
    this.setState((prevState) => {
      const data = [...prevState.initialValues[element]];

      var commentIndex = data.findIndex(function (c) {
        return c.id === id;
      });
      const updatedComment = update(data[commentIndex], {
        [name]: {
          $set: name === 'series' || name === 'repetitions' ? value : value,
        },
      });
      data[commentIndex] = updatedComment;
      this.setState({
        initialValues: {
          ...initialValues,
          [element]: data,
        },
      });
    });
  }
  handleDeleteItemExercice(id) {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      let data = [...prevState.initialValues.repetitions_series];
      if (data.length > 1) {
        data = data.filter((item) => item.id !== id);
      }
      this.setState({
        initialValues: {
          ...initialValues,
          repetitions_series: data,
        },
      });
    });
  }
  handleChangeInput(event) {
    const { initialValues } = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      initialValues: {
        ...initialValues,
        [name]: value,
      },
    });
  }
  onChangeSelect(value, { action, removedValue }, name, options) {
    const { initialValues } = this.state;
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        value = options.filter((v) => v.isFixed);
        break;
    }

    value = orderOptions(value || []);
    this.setState({
      initialValues: {
        ...initialValues,
        [name]: value,
      },
    });
  }
  componentDidMount() {
    SelectorColorInputCheckbox();
    SelectorBilanItemSection();
  }
  handleChangeSubSectionBtn(name) {
    this.setState({
      [name]: !this.state[name],
    });
  }
  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }
  openModal() {
    this.setState({
      setIsOpen: !this.state.setIsOpen,
    });
  }
  componentWillReceiveProps(nextProps, nextContext) {
    const { section_protocole, addProtocole } = nextProps;

    if (addProtocole) {
      nextProps.history.push('/consultations', {
        consultation_id: this.props.match.params.consultation_id,
      });
    }
    const { initialValues } = this.state;
    if (section_protocole && section_protocole.path.name) {
      let repetitions_series = JSON.parse(section_protocole.path.repetitions_series);
      this.setState({
        consultation_id: section_protocole.path.consultation_id,
        showPrescription: repetitions_series[0].nom_exercice ? true : false,
        initialValues: {
          ...section_protocole.path,
          repetitions_series: section_protocole.path.repetitions_series
            ? JSON.parse(section_protocole.path.repetitions_series)
            : initialValues.repetitions_series,
          notes: section_protocole.path.notes
            ? JSON.parse(section_protocole.path.notes)
            : initialValues.notes,
          /* motif_consultation: section_protocole.path.motif_consultation
            ? JSON.parse(section_protocole.path.motif_consultation)
            : initialValues.motif_consultation,*/
        },
      });
    }
  }
  render() {
    const { setIsOpen, hasError, isLoading, file, fileName, initialValues } = this.state;
    const { bilanConsultation } = this.props;
    return (
      <div style={{ overflow: 'auto' }} className="main_content">
        <div style={{ width: '80%', padding: '5%' }} className="actual_bilan_settings">
          <div className="actual_bilan_settings_item head_shaking_test" id="showPlan">
            <svg
              onClick={() => this.props.history.goBack()}
              style={{ cursor: 'pointer' }}
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="45.5"
                width="45"
                height="45"
                rx="9.5"
                transform="rotate(-90 0.5 45.5)"
                fill="white"
                stroke="#ECEEF5"
              />
              <path
                d="M20.0117 23L24.5117 18.5L25.5664 19.5547L22.1211 23L25.5664 26.4453L24.5117 27.5L20.0117 23Z"
                fill="#8083A3"
              />
            </svg>
            <div
              onClick={() => this.handleChangeSubSectionBtn('showPlan')}
              className="actual_bilan_settings_item_header sub_section_btn"
            >
              <span> Plan de traitement</span>
              <i
                className={this.state.showPlan ? 'fas fa-angle-up' : 'fas fa-angle-down'}
              ></i>
            </div>
            <div
              className="actual_bilan_settings_item_body "
              id="headShaking1_sub_section"
              style={{ display: this.state.showPlan ? '' : 'none' }}
            >
              <div className="field_sub_section_container_item type3">
                <div className="additional_container_item_field ">
                  <label htmlFor="">Nom</label>
                  <input
                    maxLength="100"
                    style={{
                      width: '100%',
                      borderBottom:
                        hasError && !initialValues.name ? '1px solid red' : '',
                    }}
                    type="text"
                    onChange={this.handleChangeInput}
                    value={initialValues.name}
                    name="name"
                    placeholder="Nom"
                  />
                </div>
              </div>
              <div className="field_sub_section_container_item">
                <div className="additional_container_item_field">
                  <label htmlFor="">Objectifs du traitement</label>
                  <input
                    maxLength="100"
                    style={{
                      width: '100%',
                      borderBottom:
                        hasError && !initialValues.motif_consultation
                          ? '1px solid red'
                          : '',
                    }}
                    type="text"
                    onChange={this.handleChangeInput}
                    value={initialValues.motif_consultation}
                    name="motif_consultation"
                    placeholder="Objectifs du traitement"
                  />
                  {/*<Select
                    value={initialValues.motif_consultation}
                    isMulti
                    name="motif_consultation"
                    placeholder="Choisissez une optien dans liste"
                    options={options}
                    styles={styles}
                    onChange={(value, { action, removedValue }) => {
                      this.onChangeSelect(
                        value,
                        { action, removedValue },
                        'motif_consultation',
                        options,
                      );
                    }}
                    className="basic-multi-select"
                  />*/}
                </div>
              </div>
              <div className="field_sub_section_container_item">
                <div className="additional_container_item_field">
                  <label htmlFor="">
                    Notes (
                    {initialValues.notes_plan ? initialValues.notes_plan.length : 0}
                    /500)
                  </label>

                  <textarea
                    maxLength="500"
                    style={{ width: '100%' }}
                    type="text"
                    onChange={this.handleChangeInput}
                    value={initialValues.notes_plan}
                    name="notes_plan"
                    placeholder="Vos notes ici"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="actual_bilan_settings_item test_fukuda" id="showPrescription">
            <div
              className="actual_bilan_settings_item_header"
              onClick={() => this.handleChangeSubSectionBtn('showPrescription')}
            >
              <span>Prescription d'exercices</span>
              <i
                className={
                  this.state.showPrescription ? 'fas fa-angle-up' : 'fas fa-angle-down'
                }
              ></i>
            </div>
            <div
              className="actual_bilan_settings_item_body"
              id="repetitions_sub_section"
              style={{ display: this.state.showPrescription ? '' : 'none' }}
            >
              {initialValues.repetitions_series &&
                initialValues.repetitions_series.map((item) => {
                  return (
                    <div key={item.id}>
                      <div key={item.id} className="field_sub_section_container_item">
                        <div className="additional_container_item_field">
                          <label style={{ display: 'flex' }} htmlFor="">
                            Nom de l'exercice{' '}
                            <span
                              onClick={() => this.handleDeleteItemExercice(item.id)}
                              style={{ cursor: 'pointer', marginLeft: '85%' }}
                            >
                              <i className="fas fa-trash"></i>
                            </span>
                          </label>
                          <input
                            type="text"
                            onChange={(event) => {
                              this.handleChangeInputItem(
                                event,
                                item.id,
                                'repetitions_series',
                              );
                            }}
                            value={item.nom_exercice}
                            name="nom_exercice"
                            placeholder="Notez le nom de l'exercice"
                          />
                        </div>
                      </div>
                      <div className="field_sub_section_container_item">
                        <label>Répétitions et séries</label>
                        <div className="textbox_container">
                          <div className="textbox_container_item">
                            <label>Répétitions </label>
                            <InputField
                              onChange={(event) => {
                                this.handleChangeInputItem(
                                  event,
                                  item.id,
                                  'repetitions_series',
                                );
                              }}
                              validInput="integer"
                              value={item.repetitions}
                              name="repetitions"
                              type="text"
                              maxLength="4"
                              placeholder="Saisir un nombre ici …"
                            />
                          </div>
                          <div className="textbox_container_item">
                            <label>Séries</label>
                            <InputField
                              onChange={(event) => {
                                this.handleChangeInputItem(
                                  event,
                                  item.id,
                                  'repetitions_series',
                                );
                              }}
                              validInput="integer"
                              value={item.series}
                              name="series"
                              type="text"
                              maxLength="4"
                              placeholder="Saisir un nombre ici …"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="field_sub_section_container_item">
                        <label className="sub_label">Fréquence</label>
                        <div className="checkbox_options">
                          <div className="checkbox_options_item">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                id={`frequence_item_1${item.id}`}
                                name={`frequence_item_1_${item.id}`}
                                checked={
                                  item[`frequence_item_1_${item.id}`] === '1x/s'
                                    ? true
                                    : false
                                }
                                onChange={(event) => {
                                  this.handleChangeInputItem(
                                    event,
                                    item.id,
                                    'repetitions_series',
                                  );
                                }}
                                value="1x/s"
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`frequence_item_1${item.id}`}
                              >
                                1x/s
                              </label>
                            </div>
                          </div>
                          <div className="checkbox_options_item">
                            <div className="form-check form-check-inline">
                              <input
                                value="2x/s"
                                className="form-check-input"
                                type="radio"
                                name={`frequence_item_1_${item.id}`}
                                checked={
                                  item[`frequence_item_1_${item.id}`] === '2x/s'
                                    ? true
                                    : false
                                }
                                onChange={(event) => {
                                  this.handleChangeInputItem(
                                    event,
                                    item.id,
                                    'repetitions_series',
                                  );
                                }}
                                id={`frequence_item_2${item.id}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`frequence_item_2${item.id}`}
                              >
                                2x/s
                              </label>
                            </div>
                          </div>
                          <div className="checkbox_options_item">
                            <div className="form-check form-check-inline">
                              <input
                                value="3x/s"
                                className="form-check-input"
                                type="radio"
                                name={`frequence_item_1_${item.id}`}
                                checked={
                                  item[`frequence_item_1_${item.id}`] === '3x/s'
                                    ? true
                                    : false
                                }
                                onChange={(event) => {
                                  this.handleChangeInputItem(
                                    event,
                                    item.id,
                                    'repetitions_series',
                                  );
                                }}
                                id={`frequence_item_3${item.id}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`frequence_item_3${item.id}`}
                              >
                                3x/s
                              </label>
                            </div>
                          </div>

                          <div className="checkbox_options_item">
                            <div className="form-check form-check-inline">
                              <input
                                value="4x/s"
                                className="form-check-input"
                                type="radio"
                                name={`frequence_item_1_${item.id}`}
                                checked={
                                  item[`frequence_item_1_${item.id}`] === '4x/s'
                                    ? true
                                    : false
                                }
                                onChange={(event) => {
                                  this.handleChangeInputItem(
                                    event,
                                    item.id,
                                    'repetitions_series',
                                  );
                                }}
                                id={`frequence_item_4${item.id}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`frequence_item_4${item.id}`}
                              >
                                4x/s
                              </label>
                            </div>
                          </div>
                          <div className="checkbox_options_item">
                            <div className="form-check form-check-inline">
                              <input
                                value="5x/s"
                                className="form-check-input"
                                type="radio"
                                name={`frequence_item_1_${item.id}`}
                                checked={
                                  item[`frequence_item_1_${item.id}`] === '5x/s'
                                    ? true
                                    : false
                                }
                                onChange={(event) => {
                                  this.handleChangeInputItem(
                                    event,
                                    item.id,
                                    'repetitions_series',
                                  );
                                }}
                                id={`frequence_item_5${item.id}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`frequence_item_5${item.id}`}
                              >
                                5x/s
                              </label>
                            </div>
                          </div>
                          <div className="checkbox_options_item">
                            <div className="form-check form-check-inline">
                              <input
                                value="6x/s"
                                className="form-check-input"
                                type="radio"
                                name={`frequence_item_1_${item.id}`}
                                checked={
                                  item[`frequence_item_1_${item.id}`] === '6x/s'
                                    ? true
                                    : false
                                }
                                onChange={(event) => {
                                  this.handleChangeInputItem(
                                    event,
                                    item.id,
                                    'repetitions_series',
                                  );
                                }}
                                id={`frequence_item_6${item.id}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`frequence_item_6${item.id}`}
                              >
                                6x/s
                              </label>
                            </div>
                          </div>
                          <div className="checkbox_options_item">
                            <div className="form-check form-check-inline">
                              <input
                                value="Tous les jours"
                                className="form-check-input"
                                type="radio"
                                name={`frequence_item_1_${item.id}`}
                                checked={
                                  item[`frequence_item_1_${item.id}`] === 'Tous les jours'
                                    ? true
                                    : false
                                }
                                onChange={(event) => {
                                  this.handleChangeInputItem(
                                    event,
                                    item.id,
                                    'repetitions_series',
                                  );
                                }}
                                id={`frequence_item_7${item.id}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`frequence_item_7${item.id}`}
                              >
                                Tous les jours
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field_sub_section_container_item">
                        <div className="description_container">
                          <div className="description_input_main_container">
                            <div className="description_item">
                              <div className="description_container_item">
                                <div className="additional_container_item_field">
                                  <label htmlFor="">
                                    Consignes (
                                    {item.consignes ? item.consignes.length : 0}
                                    /500)
                                  </label>
                                  <textarea
                                    style={{ width: '100%' }}
                                    maxLength="500"
                                    onChange={(event) => {
                                      this.handleChangeInputItem(
                                        event,
                                        item.id,
                                        'repetitions_series',
                                      );
                                    }}
                                    value={item[`consignes_${item.id}`]}
                                    name={`consignes_${item.id}`}
                                  ></textarea>
                                </div>
                              </div>

                              {/* //ajouter doc */}
                              <div className="attachement_icons">
                                <div className="attachement_icon_item">
                                  <input
                                    accept=".png, .jpg,.pdf,.jpeg"
                                    onChange={(event) => {
                                      this.handleChangeInputItem(
                                        event,
                                        item.id,
                                        'repetitions_series',
                                      );
                                    }}
                                    type="file"
                                    ref={`fileUploaderConsignes${item.id}`}
                                    name={`Consignes_file_${item.id}`}
                                    hidden
                                  />
                                  <img
                                    onClick={() =>
                                      this.handleClick(`fileUploaderConsignes${item.id}`)
                                    }
                                    src={require('../../assets/img/link.png')}
                                    width="16px"
                                    height="auto"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="add_document_container_body">
                        <div style={{ marginRight: '1%' }}>
                          {item[`Consignes_file_${item.id}`] &&
                          item[`Consignes_file_${item.id}`].name ? (
                            <div
                              style={{ display: 'flex' }}
                              className="field_sub_section_container_item"
                            >
                              <span
                                onClick={() => {
                                  this.setState({
                                    file: initialValues[`exercice_${item.id}`]
                                      ? initialValues[`exercice_${item.id}`]
                                      : item[`Consignes_file_${item.id}`],
                                  });
                                  this.openModal();
                                }}
                                style={{
                                  color: 'gray',
                                  marginRight: '126px',
                                  cursor: 'pointer',
                                }}
                              >
                                {item[`Consignes_file_${item.id}`].name}
                              </span>
                            </div>
                          ) : initialValues[`exercice_${item.id}`] ? (
                            <div
                              style={{ display: 'flex' }}
                              className="field_sub_section_container_item"
                            >
                              <span
                                onClick={() => {
                                  axios(
                                    `${process.env.REACT_APP_PUBLIC_URL}${
                                      initialValues[`exercice_${item.id}`]
                                        ? initialValues[`exercice_${item.id}`]
                                        : item[`Consignes_file_${item.id}`]
                                    }`,
                                    {
                                      method: 'GET',
                                      headers: {
                                        Authorization: `JWT ${window.localStorage.getItem(
                                          'token',
                                        )}`,
                                      },
                                      responseType: 'blob', //Force to receive data in a Blob Format
                                    },
                                  )
                                    .then((response) => {
                                      let url;
                                      let file = initialValues[`exercice_${item.id}`]
                                        ? initialValues[`exercice_${item.id}`]
                                        : item[`Consignes_file_${item.id}`];
                                      if (file.split('.')[1] === 'pdf') {
                                        const fileP = new Blob([response.data], {
                                          type: 'application/pdf',
                                        });
                                        url = URL.createObjectURL(fileP);
                                      } else {
                                        url = URL.createObjectURL(
                                          new Blob([response.data]),
                                        );
                                      }
                                      this.setState({
                                        file: url,
                                        fileName: initialValues[`file_name_${item.id}`],
                                      });
                                      this.openModal();
                                    })
                                    .catch((error) => {
                                      console.log(error);
                                    });
                                }}
                                //  onClick={() => {}}
                                style={{
                                  color: 'gray',
                                  marginRight: '126px',
                                  cursor: 'pointer',
                                  width: '100%',
                                }}
                              >
                                {initialValues[`file_name_${item.id}`]}
                              </span>

                              {item[`Consignes_file_${item.id}`] ? (
                                <span
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    this.handleChangeAnyInput('', `exercice_${item.id}`);
                                  }}
                                >
                                  X
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <hr style={{ height: '4px', width: '100%' }} />
                      <br />
                    </div>
                  );
                })}
              <div style={{ marginLeft: '40%' }} className="validate_bilan_btn">
                <button
                  onClick={() => {
                    this.handleAddNewItem('repetitions_series');
                  }}
                >
                  <i style={{ color: '#ffffff' }} className="fas fa-plus"></i> Ajouter un
                  exercice
                </button>
              </div>
            </div>
          </div>{' '}
          <div className="actual_bilan_settings_item test_fukuda" id="testFukuda">
            <div className="actual_bilan_settings_item_body" id="testFukuda_sub_section">
              <div className="field_sub_section_container_item">
                <div className="additional_container_item_field">
                  <div className="footer_buttons">
                    {/*<button
                      type="button"
                      onClick={() => {
                        this.handleInitialValues();
                      }}
                      className="footer_btn"
                      id="cancel"
                    >
                      Annuler
                    </button>*/}
                    <button
                      onClick={() => {
                        if (initialValues.name && initialValues.motif_consultation) {
                          this.setState({
                            isLoading: true,
                            hasError: false,
                          });
                          this.props.submitFormProtocole(this.state);
                        } else {
                          this.setState({
                            hasError: true,
                          });
                        }
                      }}
                      //style={{ marginRight: '3%' }}
                      type="button"
                      disabled={isLoading}
                      className="footer_btn"
                      id="update"
                    >
                      {isLoading ? (
                        <CircularProgress
                          disableShrink
                          style={{
                            width: '26px',
                            color: 'white',
                            marginRight: '50%',
                            height: '1',
                          }}
                        />
                      ) : (
                        'Enregistrer'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modals
            setIsOpen={setIsOpen}
            openModal={this.openModal}
            data={
              <>
                <div
                  className="modal-header"
                  style={{
                    borderBottom: '1px solid #dee2e6',
                    padding: '1.5rem 0rem',
                    margin: '0px 20px',
                    alignItems: 'baseline',
                    marginLeft: '3%',
                    marginTop: '-30px',
                    width: '100%',
                  }}
                >
                  <span>{fileName}</span>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.openModal()}
                  >
                    <span
                      aria-hidden="true"
                      style={{ marginRight: '30px', color: '#1A1C1D' }}
                    >
                      &times;
                    </span>
                  </button>
                </div>
                <hr style={{ width: '85%' }} />
                <div
                  style={{
                    height: '470px',
                    width: '470px',
                  }}
                  ref={(el) => (this.componentRef = el)}
                  className="modal-body"
                >
                  {typeof file === 'string' && file ? (
                    fileName.includes('pdf') ? (
                      <iframe
                        title="iframe"
                        src={file}
                        style={{ height: 400, marginTop: '2%', width: '100%' }}
                      ></iframe>
                    ) : (
                      <img
                        style={{ height: '100%', width: '100%', borderRadius: '10px' }}
                        src={file}
                      />
                    )
                  ) : file && file.name ? (
                    file.name.includes('pdf') ? (
                      <div className="miniature-container">
                        <iframe
                          title="iframe"
                          src={URL.createObjectURL(file)}
                          style={{ height: 400, marginTop: '2%', width: '100%' }}
                        ></iframe>
                      </div>
                    ) : (
                      <div className="miniature-container">
                        <img
                          style={{ height: '100%', width: '100%', borderRadius: '10px' }}
                          src={URL.createObjectURL(file)}
                        />
                      </div>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <hr style={{ width: '95%' }} />
                <div
                  className="modal-footer create_prise_en_charge_1_footer"
                  style={{
                    borderBottom: '1px solid #dee2e6',
                    padding: '1.5rem 0rem',
                    margin: '0px 20px',
                  }}
                ></div>
              </>
            }
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PlanDeReeducation);
