import React, { Component } from 'react';
import moment from 'moment';
import actionsJs from '../../../js/actions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import actions from '../../../js/actions';
import { toast } from 'react-toastify';
import ToastAction from '../../../components/ToastAction';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const mapStateToProps = (state) => ({
  holidays: state.AppReducer.holidays,
  absences: state.AppReducer.absences,
  holidaysUser: state.AppReducer.holidaysUser,
  isLoadingAbsenses: state.AppReducer.isLoadingAbsenses,
  isLoadingActionAbsences: state.AppReducer.isLoadingActionAbsences,
  actionAbsences: state.AppReducer.actionAbsences,
});

const mapDispatchToProps = (dispatch) => ({
  getHolidayUser: (id) => dispatch(actionsJs.getHolidayUser(id)),
  getHoliday: () => dispatch(actionsJs.getHoliday()),
  getAbsenses: (id) => dispatch(actionsJs.getAbsenses(null, id)),
  addHolidayUser: async (values, id) => {
    await dispatch(actions.addHolidayUser(values));
    dispatch(actions.getHolidayUser(id));
  },
  submitAbsenceForm: async (values, id) => {
    await dispatch(actions.submitAbsenceForm(values));
    dispatch(actions.getAbsenses(null, id));
  },
});

class Holidays extends Component {
  constructor(props) {
    super(props);
    props.changeNamePage('Gestion des jours fériés');
    document.title = 'Gestion des jours fériés';
    this.state = {
      hasErrorDate: false,
      hasError: false,
      switchList: false,
      employee_selected: null,
      value: 0,
      practitioner_id: props.employeeManagement && props.employeeManagement[0],
      initialValues: {},
    };
    this.handleChange = this.handleChange.bind(this);
  }

  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  handleChange = (event, value) => {
    this.setState({
      value,
    });
  };

  handleSelectItem(id) {
    const { doctors } = this.props;
    const data = doctors.find((item) => item.id === id);
    this.setState({
      employee_selected: data,
    });
  }

  submitForm(initialValues, id) {
    if (
      !initialValues.date_from ||
      !initialValues.date_to ||
      !initialValues.hour_from ||
      !initialValues.hour_to ||
      !initialValues.note
    ) {
      this.setState({
        ...initialValues,
        hasError: true,
      });
    } else if (moment(initialValues.date_from).isAfter(moment(initialValues.date_to))) {
      this.setState({
        hasErrorDate: true,
      });
    } else {
      initialValues.practitioner_id = id;
      this.setState({
        hasErrorDate: false,
        hasError: false,
      });
      this.props.submitAbsenceForm(initialValues, id);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { actionAbsences } = this.props;
    if (actionAbsences !== prevProps.actionAbsences) {
      this.setState({
        switchList: false,
      });
    }
  }
  componentDidMount() {
    this.props.getHolidayUser(null);
  }

  render() {
    const {
      absences,
      holidays,
      isLoadingAbsenses,
      holidaysUser,
      doctors,
      isLoadingDoctors,
      firstDoctor,
      secretaries,
      assistants,
    } = this.props;
    const {
      switchList,
      hasErrorDate,
      hasError,
      initialValues,
      motif_selected,
      value,
      doctor,
      employee_selected,
    } = this.state;
    const allUsers = [...doctors, ...secretaries, ...assistants];
    if (isLoadingDoctors)
      return (
        <CircularProgress disableShrink style={{ marginLeft: '33%', marginTop: '20%' }} />
      );
    return (
      <div
        className="main_content"
        style={{
          overflow: 'hidden',
          width: '100%',
          height: '100%',
        }}
      >
        <svg
          onClick={async () => {
            this.props.goBackItem('showHoliday');
          }}
          width="46"
          height="46"
          viewBox="0 0 46 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ cursor: 'pointer',margin:5 }}
        >
          <rect
            x="0.5"
            y="45.5"
            width="45"
            height="45"
            rx="9.5"
            transform="rotate(-90 0.5 45.5)"
            fill="white"
            stroke="#ECEEF5"
          ></rect>
          <path
            d="M20.0117 23L24.5117 18.5L25.5664 19.5547L22.1211 23L25.5664 26.4453L24.5117 27.5L20.0117 23Z"
            fill="#8083A3"
          ></path>
        </svg>
        {doctors.length > 0 ? (
          <div
            className="general_info"
            style={{ display: 'contents', width: '87%', height: '100%' }}
          >
            <div className="diploma_info" style={{ overflow: 'auto', display: 'block' }}>
              <div className="contact_patients_header">
                <p style={{ display: 'black' }}>
                  Choisir un collaborateur pour gérer ses jours fériés
                </p>
                <div className="search_contact_patients_name">
                  <select
                    onChange={(e) => {
                      this.props.getHolidayUser(e.target.value);
                      this.setState({
                        employee_selected:
                          e.target.value === 'Choisir un collaborateur'
                            ? null
                            : e.target.value,
                        doctor: allUsers.find(
                          (item) => item.login_access.id === parseInt(e.target.value),
                        ),
                      });
                    }}
                  >
                    <option value={null}>Choisir un collaborateur</option>
                    {allUsers.map((doctor) => {
                      if (doctor.login_access.access_status === 'active')
                        return (
                          <option
                            value={doctor.login_access.id}
                          >{`${doctor.first_name} ${doctor.last_name}`}</option>
                        );
                    })}
                  </select>
                </div>
              </div>
              <br />
              <br />
              <Alert severity="info">
                Quand le bouton est en bleu, c'est à dire le collaborateur n'est pas
                disponible dans ce jour férié
              </Alert>
              <br />
              {isLoadingAbsenses ? (
                <CircularProgress
                  disableShrink
                  style={{ marginLeft: '33%', marginTop: '20%' }}
                />
              ) : firstDoctor ? (
                <>
                  <div className="contact_patients_body">
                    <span className="patients_consultations_record_label">
                      Jours fériés
                    </span>
                    {holidays && holidays.length > 0 ? (
                      holidays.map((item) => {
                        return (
                          <div
                            onClick={() => this.handleSelectItem(item.id)}
                            style={{ width: '90%' }}
                            className="notification_info_form_item"
                          >
                            <div
                              style={{ width: '600px' }}
                              className="options_container_item option1"
                            >
                              <div className="seance_number" style={{ width: '80%' }}>
                                <span>{item.name}</span>
                              </div>
                            </div>
                            <div style={{ width: '5%' }} className="seance-status">
                              <div
                                onClick={(e) => {
                                  if (doctor && doctor.id)
                                    this.props.addHolidayUser(
                                      {
                                        login_access_id:
                                          (doctor && doctor.login_access.id) ||
                                          firstDoctor.login_access.id,
                                        holiday_id: item.id,
                                        checked:
                                          holidaysUser.length > 0 &&
                                          holidaysUser.find(
                                            (holidayUser) =>
                                              holidayUser.holiday_id === item.id,
                                          )
                                            ? false
                                            : true,
                                      },
                                      (doctor && doctor.login_access.id) ||
                                        firstDoctor.login_access.id,
                                    );
                                  else
                                    toast(
                                      ({ closeToast }) => (
                                        <ToastAction
                                          type="warning"
                                          message=" Veuillez choisir un collaborateur pour ajouter un jours fériés"
                                        />
                                      ),
                                      { hideProgressBar: true },
                                    );
                                }}
                                style={{ cursor: 'pointer' }}
                                className={
                                  holidaysUser.length > 0 &&
                                  holidaysUser.find(
                                    (holidayUser) => holidayUser.holiday_id === item.id,
                                  )
                                    ? 'active_btn'
                                    : 'deactive_btn'
                                }
                              >
                                <div
                                  className={
                                    holidaysUser.length > 0 &&
                                    holidaysUser.find(
                                      (holidayUser) => holidayUser.holiday_id === item.id,
                                    )
                                      ? 'deactive_btn_circle active_btn_circle'
                                      : 'deactive_btn_circle'
                                  }
                                ></div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="consultation_detailed_info_section">
                        <div className="empty_consultation_result_container">
                          <div className="empty_consultation_result">
                            <img
                              src={require('../../../assets/img/undraw_design_team_af2y.svg')}
                            />
                            <span id="no_consultation">Pas de jours fériés !</span>
                            <span id="no_consultation_desc">
                              Essayez de créer des nouveaux jours fériés.
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                /* <TabPanel
                  value={(doctor && doctor.id) || firstDoctor.id}
                  index={(doctor && doctor.id) || firstDoctor.id}
                >

                </TabPanel>*/
                ''
              )}
            </div>
          </div>
        ) : (
          <div className="consultation_detailed_info_section">
            <div className="empty_consultation_result_container">
              <div className="empty_consultation_result">
                <img src={require('../../../assets/img/undraw_design_team_af2y.svg')} />
                <span id="no_consultation">Pas de collaborateurs !</span>
                <span id="no_consultation_desc">
                  Essayez de créer des nouveaux collaborateurs.
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Holidays);
