import React, { Component } from 'react';
import { SelectorBilanItemSection, SelectorColorInputCheckbox } from '../../../../utils';
import UploadFile from '../../upload_file';import { Slider, RangeSlider } from 'rsuite';

class Fonctionnel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEntretien: true,
      showDouleur: false,
      showBilanArticulaire: false,
      showScore: false,
    };
    this.handleChangeSubSectionBtn = this.handleChangeSubSectionBtn.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    SelectorColorInputCheckbox();
    SelectorBilanItemSection();
  }
  handleChangeSubSectionBtn(name) {
    this.setState({
      [name]: !this.state[name],
    });
  }
  handleClick(fileUploader) {
    this.refs[fileUploader].click();
  }
  render() {
    const { sandwich_menu_btn, initialValues } = this.props;
    return (
      <div className="actual_bilan_settings">
        <div className="actual_bilan_settings_item head_shaking_test" id="headShaking1">
          <div
            style={{ backgroundColor: '#6c89bc' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}> Fonctionnel</span>
          </div>
          <br />
          <div
            style={{ backgroundColor: '#035ef8' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}> Fonctions</span>
          </div>
          <br />
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Fonctions / Gestes impossibles
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Gontracture"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_1"
                              checked={initialValues.check_fonctionne_1}
                              value="S’habiller"
                            />
                            <label className="form-check-label" htmlFor={`Gontracture`}>
                              S’habiller
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_2"
                              checked={initialValues.check_fonctionne_2}
                              value="Manger seul"
                              id={`Amyotrophie`}
                            />
                            <label className="form-check-label" htmlFor={`Amyotrophie`}>
                              Manger seul
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_21"
                              checked={initialValues.check_fonctionne_21}
                              value="Se laver seul"
                              id={`Amyotrophie1`}
                            />
                            <label className="form-check-label" htmlFor={`Amyotrophie1`}>
                              Se laver seul
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_22"
                              checked={initialValues.check_fonctionne_22}
                              value="Ecrire"
                              id={`Ecrire`}
                            />
                            <label className="form-check-label" htmlFor={`Ecrire`}>
                              Ecrire
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_23"
                              checked={initialValues.check_fonctionne_23}
                              value="Ouvrir meuble haut"
                              id={`Ouvrir meuble haut`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`Ouvrir meuble haut`}
                            >
                              Ouvrir meuble haut
                            </label>
                          </div>
                        </div>

                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_24"
                              checked={initialValues.check_fonctionne_24}
                              value="Utiliser objets lourds"
                              id={`Utiliser objets lourds`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`Utiliser objets lourds`}
                            >
                              Utiliser objets lourds
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_25"
                              checked={initialValues.check_fonctionne_25}
                              value="Utiliser objets légers"
                              id={`Utiliser objets légers`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`Utiliser objets légers`}
                            >
                              Utiliser objets légers
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Coté dominant
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="check_fonctionne_11"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_12"
                              checked={
                                initialValues.check_fonctionne_12 === 'Droitier'
                                  ? true
                                  : false
                              }
                              value="Droitier"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_fonctionne_11`}
                            >
                              Droitier
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_12"
                              checked={
                                initialValues.check_fonctionne_12 === 'Goucher'
                                  ? true
                                  : false
                              }
                              value="Goucher"
                              id={`check_fonctionne_12`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_fonctionne_12`}
                            >
                              Goucher
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',

                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Remarques
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="additional_container_item_field">
                        <textarea
                          onChange={this.props.handleChangeInput}
                          maxLength="500"
                          style={{ width: '60%' }}
                          value={initialValues.notes10_11}
                          name="notes10_11"
                          placeholder="Remarques"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundColor: '#035ef8' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}>
              {' '}
              Scores - Indice Fonctionnel Uniformisé - IFU
            </span>
          </div>
          <br />
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Prise d’antalgique
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="Quotidienne"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_1112"
                              checked={
                                initialValues.check_fonctionne_1112 === 'Quotidienne'
                                  ? true
                                  : false
                              }
                              value="Quotidienne"
                            />
                            <label className="form-check-label" htmlFor={`Quotidienne`}>
                              Quotidienne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_1112"
                              checked={
                                initialValues.check_fonctionne_1112 === 'Irrégulière'
                                  ? true
                                  : false
                              }
                              value="Irrégulière"
                              id={`check_fonctionne_1012`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_fonctionne_1012`}
                            >
                              Irrégulière
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_1112"
                              checked={
                                initialValues.check_fonctionne_1112 ===
                                'Jamais ou très rarement'
                                  ? true
                                  : false
                              }
                              value="Jamais ou très rarement"
                              id={`check_fonctionne_1002`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`check_fonctionne_1002`}
                            >
                              Jamais ou très rarement
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Douleur au repos
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="Insupportable"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_11212"
                              checked={
                                initialValues.check_fonctionne_11212 === 'Insupportable'
                                  ? true
                                  : false
                              }
                              value="Insupportable"
                            />
                            <label className="form-check-label" htmlFor={`Insupportable`}>
                              Insupportable
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_11212"
                              checked={
                                initialValues.check_fonctionne_11212 === 'Supportable'
                                  ? true
                                  : false
                              }
                              value="Supportable"
                              id={`Supportable`}
                            />
                            <label className="form-check-label" htmlFor={`Supportable`}>
                              Supportable
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_11212"
                              checked={
                                initialValues.check_fonctionne_11212 === 'Absente'
                                  ? true
                                  : false
                              }
                              value="Absente"
                              id={`Absente`}
                            />
                            <label className="form-check-label" htmlFor={`Absente`}>
                              Absente
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Douleur pour les gestes usuels de la vie quotidienne
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="Insupportable1"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_112102"
                              checked={
                                initialValues.check_fonctionne_112102 === 'Insupportable'
                                  ? true
                                  : false
                              }
                              value="Insupportable"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`Insupportable1`}
                            >
                              Insupportable
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_112102"
                              checked={
                                initialValues.check_fonctionne_112102 === 'Supportable'
                                  ? true
                                  : false
                              }
                              value="Supportable"
                              id={`Supportable1`}
                            />
                            <label className="form-check-label" htmlFor={`Supportable1`}>
                              Supportable
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_112102"
                              checked={
                                initialValues.check_fonctionne_112102 === 'Absente'
                                  ? true
                                  : false
                              }
                              value="Absente"
                              id={`Absente1`}
                            />
                            <label className="form-check-label" htmlFor={`Absente1`}>
                              Absente
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Douleur nocturne
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="Insupportable12"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_10112"
                              checked={
                                initialValues.check_fonctionne_10112 === 'Insupportable12'
                                  ? true
                                  : false
                              }
                              value="Insupportable12"
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`Insupportable12`}
                            >
                              Insupportable, perturbant le sommeil
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_10112"
                              checked={
                                initialValues.check_fonctionne_10112 === 'Supportable12'
                                  ? true
                                  : false
                              }
                              value="Supportable12"
                              id={`Supportable12`}
                            />
                            <label className="form-check-label" htmlFor={`Supportable12`}>
                              Supportable, avec endormissement possible
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_10112"
                              checked={
                                initialValues.check_fonctionne_10112 === 'Absente'
                                  ? true
                                  : false
                              }
                              value="Absente"
                              id={`Absente11`}
                            />
                            <label className="form-check-label" htmlFor={`Absente11`}>
                              Absente
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Elévation latérale
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="10"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_102102"
                              checked={
                                initialValues.check_fonctionne_102102 === '0 à 60°'
                                  ? true
                                  : false
                              }
                              value="0 à 60°"
                            />
                            <label className="form-check-label" htmlFor={`10`}>
                              0 à 60°
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_102102"
                              checked={
                                initialValues.check_fonctionne_102102 === '61° à 80°'
                                  ? true
                                  : false
                              }
                              value="61° à 80°"
                              id={`11`}
                            />
                            <label className="form-check-label" htmlFor={`11`}>
                              61° à 80°
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_102102"
                              checked={
                                initialValues.check_fonctionne_102102 === '81° à 100°'
                                  ? true
                                  : false
                              }
                              value="81° à 100°"
                              id={`12`}
                            />
                            <label className="form-check-label" htmlFor={`12`}>
                              81° à 100°
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_102102"
                              checked={
                                initialValues.check_fonctionne_102102 === '101° à 120°'
                                  ? true
                                  : false
                              }
                              value="101° à 120°"
                              id={`13`}
                            />
                            <label className="form-check-label" htmlFor={`13`}>
                              101° à 120°
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_102102"
                              checked={
                                initialValues.check_fonctionne_102102 === '121° à 140°'
                                  ? true
                                  : false
                              }
                              value="121° à 140°"
                              id={`14`}
                            />
                            <label className="form-check-label" htmlFor={`14`}>
                              121° à 140°
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_102102"
                              checked={
                                initialValues.check_fonctionne_102102 === '141° à 165°'
                                  ? true
                                  : false
                              }
                              value="141° à 165°"
                              id={`15`}
                            />
                            <label className="form-check-label" htmlFor={`15`}>
                              141° à 165°
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Elévation antérieure
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="20"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_105102"
                              checked={
                                initialValues.check_fonctionne_105102 === '0 à 60°'
                                  ? true
                                  : false
                              }
                              value="0 à 60°"
                            />
                            <label className="form-check-label" htmlFor={`20`}>
                              0 à 60°
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_105102"
                              checked={
                                initialValues.check_fonctionne_105102 === '61° à 80°'
                                  ? true
                                  : false
                              }
                              value="61° à 80°"
                              id={`21`}
                            />
                            <label className="form-check-label" htmlFor={`21`}>
                              61° à 80°
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_105102"
                              checked={
                                initialValues.check_fonctionne_105102 === '81° à 100°'
                                  ? true
                                  : false
                              }
                              value="81° à 100°"
                              id={`22`}
                            />
                            <label className="form-check-label" htmlFor={`22`}>
                              81° à 100°
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_105102"
                              checked={
                                initialValues.check_fonctionne_105102 === '101° à 120°'
                                  ? true
                                  : false
                              }
                              value="101° à 120°"
                              id={`23`}
                            />
                            <label className="form-check-label" htmlFor={`23`}>
                              101° à 120°
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_105102"
                              checked={
                                initialValues.check_fonctionne_105102 === '121° à 140°'
                                  ? true
                                  : false
                              }
                              value="121° à 140°"
                              id={`24`}
                            />
                            <label className="form-check-label" htmlFor={`24`}>
                              121° à 140°
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_105102"
                              checked={
                                initialValues.check_fonctionne_105102 === '141° à 165°'
                                  ? true
                                  : false
                              }
                              value="141° à 165°"
                              id={`25`}
                            />
                            <label className="form-check-label" htmlFor={`25`}>
                              141° à 165°
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Antépulsion horizontale
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="00"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_152102"
                              checked={
                                initialValues.check_fonctionne_152102 === '0° à 45°'
                                  ? true
                                  : false
                              }
                              value="0° à 45°"
                            />
                            <label className="form-check-label" htmlFor={`00`}>
                              0° à 45°
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_152102"
                              checked={
                                initialValues.check_fonctionne_152102 === '46° à 90°'
                                  ? true
                                  : false
                              }
                              value="46° à 90°"
                              id={`16`}
                            />
                            <label className="form-check-label" htmlFor={`16`}>
                              46° à 90°
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_152102"
                              checked={
                                initialValues.check_fonctionne_152102 === '91° à 100°'
                                  ? true
                                  : false
                              }
                              value="91° à 100°"
                              id={`17`}
                            />
                            <label className="form-check-label" htmlFor={`17`}>
                              91° à 100°
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_152102"
                              checked={
                                initialValues.check_fonctionne_152102 === '101° à 120°'
                                  ? true
                                  : false
                              }
                              value="101° à 120°"
                              id={`18`}
                            />
                            <label className="form-check-label" htmlFor={`18`}>
                              101° à 120°
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Rotation externe à 90° d’abduction
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="01"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_165102"
                              checked={
                                initialValues.check_fonctionne_165102 === '0° à 30°'
                                  ? true
                                  : false
                              }
                              value="0° à 30°"
                            />
                            <label className="form-check-label" htmlFor={`01`}>
                              0° à 30°
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_165102"
                              checked={
                                initialValues.check_fonctionne_165102 === '31° à 45°'
                                  ? true
                                  : false
                              }
                              value="31° à 45°"
                              id={`02`}
                            />
                            <label className="form-check-label" htmlFor={`02`}>
                              31° à 45°
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_165102"
                              checked={
                                initialValues.check_fonctionne_165102 === '46° à 60°'
                                  ? true
                                  : false
                              }
                              value="46° à 60°"
                              id={`03`}
                            />
                            <label className="form-check-label" htmlFor={`03`}>
                              46° à 60°
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_165102"
                              checked={
                                initialValues.check_fonctionne_165102 === '61° à 90°'
                                  ? true
                                  : false
                              }
                              value="61° à 90°"
                              id={`04`}
                            />
                            <label className="form-check-label" htmlFor={`04`}>
                              61° à 90°
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Distance pouce-C7
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="06"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_172102"
                              checked={
                                initialValues.check_fonctionne_172102 ===
                                'Inférieure ou égale à 20 cm'
                                  ? true
                                  : false
                              }
                              value="Inférieure ou égale à 20 cm"
                            />
                            <label className="form-check-label" htmlFor={`06`}>
                              Inférieure ou égale à 20 cm
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_172102"
                              checked={
                                initialValues.check_fonctionne_172102 ===
                                'Entre 21 cm et 40 cm'
                                  ? true
                                  : false
                              }
                              value="Entre 21 cm et 40 cm"
                              id={`07`}
                            />
                            <label className="form-check-label" htmlFor={`07`}>
                              Entre 21 cm et 40 cm
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_172102"
                              checked={
                                initialValues.check_fonctionne_172102 ===
                                'Supérieure ou égale à 41 cm'
                                  ? true
                                  : false
                              }
                              value="Supérieure ou égale à 41 cm"
                              id={`08`}
                            />
                            <label className="form-check-label" htmlFor={`08`}>
                              Supérieure ou égale à 41 cm
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Ouvrir une fenêtre à hauteur des yeux
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="010"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_185102"
                              checked={
                                initialValues.check_fonctionne_185102 ===
                                'Possible sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Possible sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`010`}>
                              Possible sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_185102"
                              checked={
                                initialValues.check_fonctionne_185102 ===
                                'Possible avec difficulté'
                                  ? true
                                  : false
                              }
                              value="Possible avec difficulté"
                              id={`011`}
                            />
                            <label className="form-check-label" htmlFor={`011`}>
                              Possible avec difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_185102"
                              checked={
                                initialValues.check_fonctionne_185102 ===
                                'Impossible à réaliser'
                                  ? true
                                  : false
                              }
                              value="Impossible à réaliser"
                              id={`012`}
                            />
                            <label className="form-check-label" htmlFor={`012`}>
                              Impossible à réaliser
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Se coiffer complètement d’avant en arrière
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="013"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_192102"
                              checked={
                                initialValues.check_fonctionne_192102 ===
                                'Possible sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Possible sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`013`}>
                              Possible sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_192102"
                              checked={
                                initialValues.check_fonctionne_192102 ===
                                'Possible avec difficulté'
                                  ? true
                                  : false
                              }
                              value="Possible avec difficulté"
                              id={`014`}
                            />
                            <label className="form-check-label" htmlFor={`014`}>
                              Possible avec difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_192102"
                              checked={
                                initialValues.check_fonctionne_192102 ===
                                'Impossible à réaliser'
                                  ? true
                                  : false
                              }
                              value="Impossible à réaliser"
                              id={`015`}
                            />
                            <label className="form-check-label" htmlFor={`015`}>
                              Impossible à réaliser
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Changer une ampoule électrique au plafond
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="016"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_215102"
                              checked={
                                initialValues.check_fonctionne_215102 ===
                                'Possible sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Possible sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`016`}>
                              Possible sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_215102"
                              checked={
                                initialValues.check_fonctionne_215102 ===
                                'Possible avec difficulté'
                                  ? true
                                  : false
                              }
                              value="Possible avec difficulté"
                              id={`017`}
                            />
                            <label className="form-check-label" htmlFor={`017`}>
                              Possible avec difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_215102"
                              checked={
                                initialValues.check_fonctionne_215102 ===
                                'Impossible à réaliser'
                                  ? true
                                  : false
                              }
                              value="Impossible à réaliser"
                              id={`018`}
                            />
                            <label className="form-check-label" htmlFor={`018`}>
                              Impossible à réaliser
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Enfiler ou enlever un chandail par la tête
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="020"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_222102"
                              checked={
                                initialValues.check_fonctionne_222102 ===
                                'Possible sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Possible sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`020`}>
                              Possible sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_222102"
                              checked={
                                initialValues.check_fonctionne_222102 ===
                                'Possible avec difficulté'
                                  ? true
                                  : false
                              }
                              value="Possible avec difficulté"
                              id={`021`}
                            />
                            <label className="form-check-label" htmlFor={`021`}>
                              Possible avec difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_222102"
                              checked={
                                initialValues.check_fonctionne_222102 ===
                                'Impossible à réaliser'
                                  ? true
                                  : false
                              }
                              value="Impossible à réaliser"
                              id={`022`}
                            />
                            <label className="form-check-label" htmlFor={`022`}>
                              Impossible à réaliser
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Se laver l’omoplate du côté opposé en passant par devant
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="023"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_235102"
                              checked={
                                initialValues.check_fonctionne_235102 ===
                                'Possible sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Possible sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`023`}>
                              Possible sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_235102"
                              checked={
                                initialValues.check_fonctionne_235102 ===
                                'Possible avec difficulté'
                                  ? true
                                  : false
                              }
                              value="Possible avec difficulté"
                              id={`024`}
                            />
                            <label className="form-check-label" htmlFor={`024`}>
                              Possible avec difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_235102"
                              checked={
                                initialValues.check_fonctionne_235102 ===
                                'Impossible à réaliser'
                                  ? true
                                  : false
                              }
                              value="Impossible à réaliser"
                              id={`025`}
                            />
                            <label className="form-check-label" htmlFor={`025`}>
                              Impossible à réaliser
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Soulever une bouteille pleine d’eau (1 litre) bras tendu, et se
                      servir à boire
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="026"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_242102"
                              checked={
                                initialValues.check_fonctionne_242102 ===
                                'Possible sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Possible sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`026`}>
                              Possible sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_242102"
                              checked={
                                initialValues.check_fonctionne_242102 ===
                                'Possible avec difficulté'
                                  ? true
                                  : false
                              }
                              value="Possible avec difficulté"
                              id={`027`}
                            />
                            <label className="form-check-label" htmlFor={`027`}>
                              Possible avec difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_242102"
                              checked={
                                initialValues.check_fonctionne_242102 ===
                                'Impossible à réaliser'
                                  ? true
                                  : false
                              }
                              value="Impossible à réaliser"
                              id={`028`}
                            />
                            <label className="form-check-label" htmlFor={`028`}>
                              Impossible à réaliser
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Force élévation (bras tendu à 90° d’élev, antérieur) Nbre de kilos ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="additional_container_item_field">
                        <input
                          onChange={this.props.handleChangeInput}
                          maxLength="500"
                          style={{ width: '60%' }}
                          type="number"
                          name="text_seance_1_1"
                          placeholder=""
                          value={initialValues.text_seance_1_1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Type patient{' '}
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="029"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_252102"
                              checked={
                                initialValues.check_fonctionne_252102 ===
                                'Homme moins de 50 ans'
                                  ? true
                                  : false
                              }
                              value="Homme moins de 50 ans"
                            />
                            <label className="form-check-label" htmlFor={`029`}>
                              Homme moins de 50 ans
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_252102"
                              checked={
                                initialValues.check_fonctionne_252102 ===
                                'Homme entre 50 et 60 ans'
                                  ? true
                                  : false
                              }
                              value="Homme entre 50 et 60 ans"
                              id={`030`}
                            />
                            <label className="form-check-label" htmlFor={`030`}>
                              Homme entre 50 et 60 ans
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_252102"
                              checked={
                                initialValues.check_fonctionne_252102 ===
                                'Homme de plus de 60 ans'
                                  ? true
                                  : false
                              }
                              value="Homme de plus de 60 ans"
                              id={`031`}
                            />
                            <label className="form-check-label" htmlFor={`031`}>
                              Homme de plus de 60 ans
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_252102"
                              checked={
                                initialValues.check_fonctionne_252102 ===
                                'Femme moins de 50 ans'
                                  ? true
                                  : false
                              }
                              value="Femme moins de 50 ans"
                              id={`032`}
                            />
                            <label className="form-check-label" htmlFor={`032`}>
                              Femme moins de 50 ans
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_252102"
                              checked={
                                initialValues.check_fonctionne_252102 ===
                                'Femme entre 50 et 60 ans'
                                  ? true
                                  : false
                              }
                              value="Femme entre 50 et 60 ans"
                              id={`033`}
                            />
                            <label className="form-check-label" htmlFor={`033`}>
                              Femme entre 50 et 60 ans
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_252102"
                              checked={
                                initialValues.check_fonctionne_252102 ===
                                'Femme de plus de 60 ans'
                                  ? true
                                  : false
                              }
                              value="Femme de plus de 60 ans"
                              id={`034`}
                            />
                            <label className="form-check-label" htmlFor={`034`}>
                              Femme de plus de 60 ans
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundColor: '#035ef8' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}>
              {' '}
              Scores - Echelle de Cochin (main rhumatologique)
            </span>
          </div>
          <br />
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Pouvez-vous tenir un bol
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="035"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_262102"
                              checked={
                                initialValues.check_fonctionne_262102 ===
                                'Oui sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Oui sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`035`}>
                              Oui sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_262102"
                              checked={
                                initialValues.check_fonctionne_262102 ===
                                'Possible avec très peu de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec très peu de difficultés"
                              id={`036`}
                            />
                            <label className="form-check-label" htmlFor={`036`}>
                              Possible avec très peu de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_262102"
                              checked={
                                initialValues.check_fonctionne_262102 ===
                                'Possible avec quelques difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec quelques difficultés"
                              id={`037`}
                            />
                            <label className="form-check-label" htmlFor={`037`}>
                              Possible avec quelques difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_262102"
                              checked={
                                initialValues.check_fonctionne_262102 ===
                                'Possible avec beaucoup de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec beaucoup de difficultés"
                              id={`038`}
                            />
                            <label className="form-check-label" htmlFor={`038`}>
                              Possible avec beaucoup de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_262102"
                              checked={
                                initialValues.check_fonctionne_262102 ===
                                'Presque impossible'
                                  ? true
                                  : false
                              }
                              value="Presque impossible"
                              id={`039`}
                            />
                            <label className="form-check-label" htmlFor={`039`}>
                              Presque impossible
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_262102"
                              checked={
                                initialValues.check_fonctionne_262102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`040`}
                            />
                            <label className="form-check-label" htmlFor={`040`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Pouvez-vous saisir une bouteille pleine et la laver
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="041"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_272102"
                              checked={
                                initialValues.check_fonctionne_272102 ===
                                'Oui sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Oui sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`041`}>
                              Oui sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_272102"
                              checked={
                                initialValues.check_fonctionne_272102 ===
                                'Possible avec très peu de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec très peu de difficultés"
                              id={`042`}
                            />
                            <label className="form-check-label" htmlFor={`042`}>
                              Possible avec très peu de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_272102"
                              checked={
                                initialValues.check_fonctionne_272102 ===
                                'Possible avec quelques difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec quelques difficultés"
                              id={`043`}
                            />
                            <label className="form-check-label" htmlFor={`043`}>
                              Possible avec quelques difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_272102"
                              checked={
                                initialValues.check_fonctionne_272102 ===
                                'Possible avec beaucoup de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec beaucoup de difficultés"
                              id={`044`}
                            />
                            <label className="form-check-label" htmlFor={`044`}>
                              Possible avec beaucoup de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_272102"
                              checked={
                                initialValues.check_fonctionne_272102 ===
                                'Presque impossible'
                                  ? true
                                  : false
                              }
                              value="Presque impossible"
                              id={`045`}
                            />
                            <label className="form-check-label" htmlFor={`045`}>
                              Presque impossible
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_272102"
                              checked={
                                initialValues.check_fonctionne_272102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`046`}
                            />
                            <label className="form-check-label" htmlFor={`046`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Pouvez-vous verser le liquide de la bouteille dans un verre ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="047"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_292102"
                              checked={
                                initialValues.check_fonctionne_292102 ===
                                'Oui sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Oui sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`047`}>
                              Oui sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_292102"
                              checked={
                                initialValues.check_fonctionne_292102 ===
                                'Possible avec très peu de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec très peu de difficultés"
                              id={`048`}
                            />
                            <label className="form-check-label" htmlFor={`048`}>
                              Possible avec très peu de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_292102"
                              checked={
                                initialValues.check_fonctionne_292102 ===
                                'Possible avec quelques difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec quelques difficultés"
                              id={`049`}
                            />
                            <label className="form-check-label" htmlFor={`049`}>
                              Possible avec quelques difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_292102"
                              checked={
                                initialValues.check_fonctionne_292102 ===
                                'Possible avec beaucoup de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec beaucoup de difficultés"
                              id={`050`}
                            />
                            <label className="form-check-label" htmlFor={`050`}>
                              Possible avec beaucoup de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_292102"
                              checked={
                                initialValues.check_fonctionne_292102 ===
                                'Presque impossible'
                                  ? true
                                  : false
                              }
                              value="Presque impossible"
                              id={`051`}
                            />
                            <label className="form-check-label" htmlFor={`051`}>
                              Presque impossible
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_292102"
                              checked={
                                initialValues.check_fonctionne_292102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`052`}
                            />
                            <label className="form-check-label" htmlFor={`052`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Pouvez-vous dévisser le couvercle d’un pot déjà ouvert une fois ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="055"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_302102"
                              checked={
                                initialValues.check_fonctionne_302102 ===
                                'Oui sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Oui sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`055`}>
                              Oui sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_302102"
                              checked={
                                initialValues.check_fonctionne_302102 ===
                                'Possible avec très peu de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec très peu de difficultés"
                              id={`056`}
                            />
                            <label className="form-check-label" htmlFor={`056`}>
                              Possible avec très peu de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_302102"
                              checked={
                                initialValues.check_fonctionne_302102 ===
                                'Possible avec quelques difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec quelques difficultés"
                              id={`057`}
                            />
                            <label className="form-check-label" htmlFor={`057`}>
                              Possible avec quelques difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_302102"
                              checked={
                                initialValues.check_fonctionne_302102 ===
                                'Possible avec beaucoup de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec beaucoup de difficultés"
                              id={`058`}
                            />
                            <label className="form-check-label" htmlFor={`058`}>
                              Possible avec beaucoup de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_302102"
                              checked={
                                initialValues.check_fonctionne_302102 ===
                                'Presque impossible'
                                  ? true
                                  : false
                              }
                              value="Presque impossible"
                              id={`059`}
                            />
                            <label className="form-check-label" htmlFor={`059`}>
                              Presque impossible
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_302102"
                              checked={
                                initialValues.check_fonctionne_302102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`060`}
                            />
                            <label className="form-check-label" htmlFor={`060`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Pouvez-vous couper de la viande avec un couteau ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="061"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_312102"
                              checked={
                                initialValues.check_fonctionne_312102 ===
                                'Oui sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Oui sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`061`}>
                              Oui sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_312102"
                              checked={
                                initialValues.check_fonctionne_312102 ===
                                'Possible avec très peu de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec très peu de difficultés"
                              id={`062`}
                            />
                            <label className="form-check-label" htmlFor={`062`}>
                              Possible avec très peu de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_312102"
                              checked={
                                initialValues.check_fonctionne_312102 ===
                                'Possible avec quelques difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec quelques difficultés"
                              id={`063`}
                            />
                            <label className="form-check-label" htmlFor={`063`}>
                              Possible avec quelques difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_312102"
                              checked={
                                initialValues.check_fonctionne_312102 ===
                                'Possible avec beaucoup de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec beaucoup de difficultés"
                              id={`064`}
                            />
                            <label className="form-check-label" htmlFor={`064`}>
                              Possible avec beaucoup de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_312102"
                              checked={
                                initialValues.check_fonctionne_312102 ===
                                'Presque impossible'
                                  ? true
                                  : false
                              }
                              value="Presque impossible"
                              id={`065`}
                            />
                            <label className="form-check-label" htmlFor={`065`}>
                              Presque impossible
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_312102"
                              checked={
                                initialValues.check_fonctionne_312102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`066`}
                            />
                            <label className="form-check-label" htmlFor={`066`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Pouvez-vous piquer efficacement avec une fourchette ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="067"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_322102"
                              checked={
                                initialValues.check_fonctionne_322102 ===
                                'Oui sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Oui sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`067`}>
                              Oui sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_322102"
                              checked={
                                initialValues.check_fonctionne_322102 ===
                                'Possible avec très peu de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec très peu de difficultés"
                              id={`068`}
                            />
                            <label className="form-check-label" htmlFor={`068`}>
                              Possible avec très peu de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_322102"
                              checked={
                                initialValues.check_fonctionne_322102 ===
                                'Possible avec quelques difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec quelques difficultés"
                              id={`069`}
                            />
                            <label className="form-check-label" htmlFor={`069`}>
                              Possible avec quelques difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_322102"
                              checked={
                                initialValues.check_fonctionne_322102 ===
                                'Possible avec beaucoup de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec beaucoup de difficultés"
                              id={`070`}
                            />
                            <label className="form-check-label" htmlFor={`070`}>
                              Possible avec beaucoup de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_322102"
                              checked={
                                initialValues.check_fonctionne_322102 ===
                                'Presque impossible'
                                  ? true
                                  : false
                              }
                              value="Presque impossible"
                              id={`071`}
                            />
                            <label className="form-check-label" htmlFor={`071`}>
                              Presque impossible
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_322102"
                              checked={
                                initialValues.check_fonctionne_322102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`072`}
                            />
                            <label className="form-check-label" htmlFor={`072`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Pouvez-vous peler des fruits ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="073"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_332102"
                              checked={
                                initialValues.check_fonctionne_332102 ===
                                'Oui sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Oui sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`073`}>
                              Oui sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_332102"
                              checked={
                                initialValues.check_fonctionne_332102 ===
                                'Possible avec très peu de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec très peu de difficultés"
                              id={`074`}
                            />
                            <label className="form-check-label" htmlFor={`074`}>
                              Possible avec très peu de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_332102"
                              checked={
                                initialValues.check_fonctionne_332102 ===
                                'Possible avec quelques difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec quelques difficultés"
                              id={`075`}
                            />
                            <label className="form-check-label" htmlFor={`075`}>
                              Possible avec quelques difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_332102"
                              checked={
                                initialValues.check_fonctionne_332102 ===
                                'Possible avec beaucoup de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec beaucoup de difficultés"
                              id={`076`}
                            />
                            <label className="form-check-label" htmlFor={`076`}>
                              Possible avec beaucoup de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_332102"
                              checked={
                                initialValues.check_fonctionne_332102 ===
                                'Presque impossible'
                                  ? true
                                  : false
                              }
                              value="Presque impossible"
                              id={`077`}
                            />
                            <label className="form-check-label" htmlFor={`077`}>
                              Presque impossible
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_332102"
                              checked={
                                initialValues.check_fonctionne_332102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`078`}
                            />
                            <label className="form-check-label" htmlFor={`078`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Pouvez-vous boutonner votre chemise ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="079"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_342102"
                              checked={
                                initialValues.check_fonctionne_342102 ===
                                'Oui sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Oui sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`079`}>
                              Oui sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_342102"
                              checked={
                                initialValues.check_fonctionne_342102 ===
                                'Possible avec très peu de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec très peu de difficultés"
                              id={`080`}
                            />
                            <label className="form-check-label" htmlFor={`080`}>
                              Possible avec très peu de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_342102"
                              checked={
                                initialValues.check_fonctionne_342102 ===
                                'Possible avec quelques difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec quelques difficultés"
                              id={`081`}
                            />
                            <label className="form-check-label" htmlFor={`081`}>
                              Possible avec quelques difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_342102"
                              checked={
                                initialValues.check_fonctionne_342102 ===
                                'Possible avec beaucoup de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec beaucoup de difficultés"
                              id={`082`}
                            />
                            <label className="form-check-label" htmlFor={`082`}>
                              Possible avec beaucoup de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_342102"
                              checked={
                                initialValues.check_fonctionne_342102 ===
                                'Presque impossible'
                                  ? true
                                  : false
                              }
                              value="Presque impossible"
                              id={`083`}
                            />
                            <label className="form-check-label" htmlFor={`083`}>
                              Presque impossible
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_342102"
                              checked={
                                initialValues.check_fonctionne_342102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`084`}
                            />
                            <label className="form-check-label" htmlFor={`084`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Pouvez-vous ouvrir puis fermer les fermetures éclair ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="085"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_352102"
                              checked={
                                initialValues.check_fonctionne_352102 ===
                                'Oui sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Oui sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`085`}>
                              Oui sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_352102"
                              checked={
                                initialValues.check_fonctionne_352102 ===
                                'Possible avec très peu de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec très peu de difficultés"
                              id={`086`}
                            />
                            <label className="form-check-label" htmlFor={`086`}>
                              Possible avec très peu de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_352102"
                              checked={
                                initialValues.check_fonctionne_352102 ===
                                'Possible avec quelques difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec quelques difficultés"
                              id={`087`}
                            />
                            <label className="form-check-label" htmlFor={`087`}>
                              Possible avec quelques difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_352102"
                              checked={
                                initialValues.check_fonctionne_352102 ===
                                'Possible avec beaucoup de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec beaucoup de difficultés"
                              id={`088`}
                            />
                            <label className="form-check-label" htmlFor={`088`}>
                              Possible avec beaucoup de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_352102"
                              checked={
                                initialValues.check_fonctionne_352102 ===
                                'Presque impossible'
                                  ? true
                                  : false
                              }
                              value="Presque impossible"
                              id={`089`}
                            />
                            <label className="form-check-label" htmlFor={`089`}>
                              Presque impossible
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_352102"
                              checked={
                                initialValues.check_fonctionne_352102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`090`}
                            />
                            <label className="form-check-label" htmlFor={`090`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Pouvez-vous presser un tube de dentifrice plein ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="091"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_362102"
                              checked={
                                initialValues.check_fonctionne_362102 ===
                                'Oui sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Oui sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`091`}>
                              Oui sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_362102"
                              checked={
                                initialValues.check_fonctionne_362102 ===
                                'Possible avec très peu de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec très peu de difficultés"
                              id={`092`}
                            />
                            <label className="form-check-label" htmlFor={`092`}>
                              Possible avec très peu de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_362102"
                              checked={
                                initialValues.check_fonctionne_362102 ===
                                'Possible avec quelques difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec quelques difficultés"
                              id={`093`}
                            />
                            <label className="form-check-label" htmlFor={`093`}>
                              Possible avec quelques difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_362102"
                              checked={
                                initialValues.check_fonctionne_362102 ===
                                'Possible avec beaucoup de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec beaucoup de difficultés"
                              id={`094`}
                            />
                            <label className="form-check-label" htmlFor={`094`}>
                              Possible avec beaucoup de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_362102"
                              checked={
                                initialValues.check_fonctionne_362102 ===
                                'Presque impossible'
                                  ? true
                                  : false
                              }
                              value="Presque impossible"
                              id={`095`}
                            />
                            <label className="form-check-label" htmlFor={`095`}>
                              Presque impossible
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_362102"
                              checked={
                                initialValues.check_fonctionne_362102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`096`}
                            />
                            <label className="form-check-label" htmlFor={`096`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Pouvez-vous tenir votre brosse à dent efficacement ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="097"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_372102"
                              checked={
                                initialValues.check_fonctionne_372102 ===
                                'Oui sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Oui sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`097`}>
                              Oui sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_372102"
                              checked={
                                initialValues.check_fonctionne_372102 ===
                                'Possible avec très peu de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec très peu de difficultés"
                              id={`098`}
                            />
                            <label className="form-check-label" htmlFor={`098`}>
                              Possible avec très peu de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_372102"
                              checked={
                                initialValues.check_fonctionne_372102 ===
                                'Possible avec quelques difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec quelques difficultés"
                              id={`099`}
                            />
                            <label className="form-check-label" htmlFor={`099`}>
                              Possible avec quelques difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_372102"
                              checked={
                                initialValues.check_fonctionne_372102 ===
                                'Possible avec beaucoup de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec beaucoup de difficultés"
                              id={`0100`}
                            />
                            <label className="form-check-label" htmlFor={`0100`}>
                              Possible avec beaucoup de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_372102"
                              checked={
                                initialValues.check_fonctionne_372102 ===
                                'Presque impossible'
                                  ? true
                                  : false
                              }
                              value="Presque impossible"
                              id={`0101`}
                            />
                            <label className="form-check-label" htmlFor={`0101`}>
                              Presque impossible
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_372102"
                              checked={
                                initialValues.check_fonctionne_372102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0102`}
                            />
                            <label className="form-check-label" htmlFor={`0102`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Pouvez-vous écrire une phrase courte avec un crayon ou un stylo
                      ordinaire ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0104"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_382102"
                              checked={
                                initialValues.check_fonctionne_382102 ===
                                'Oui sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Oui sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0104`}>
                              Oui sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_382102"
                              checked={
                                initialValues.check_fonctionne_382102 ===
                                'Possible avec très peu de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec très peu de difficultés"
                              id={`0105`}
                            />
                            <label className="form-check-label" htmlFor={`0105`}>
                              Possible avec très peu de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_382102"
                              checked={
                                initialValues.check_fonctionne_382102 ===
                                'Possible avec quelques difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec quelques difficultés"
                              id={`0106`}
                            />
                            <label className="form-check-label" htmlFor={`0106`}>
                              Possible avec quelques difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_382102"
                              checked={
                                initialValues.check_fonctionne_382102 ===
                                'Possible avec beaucoup de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec beaucoup de difficultés"
                              id={`0107`}
                            />
                            <label className="form-check-label" htmlFor={`0107`}>
                              Possible avec beaucoup de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_382102"
                              checked={
                                initialValues.check_fonctionne_382102 ===
                                'Presque impossible'
                                  ? true
                                  : false
                              }
                              value="Presque impossible"
                              id={`0108`}
                            />
                            <label className="form-check-label" htmlFor={`0108`}>
                              Presque impossible
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_382102"
                              checked={
                                initialValues.check_fonctionne_382102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0109`}
                            />
                            <label className="form-check-label" htmlFor={`0109`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Pouvez-vous écrire une lettre avec un crayon ou un stylo ordinaire ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0110"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_392102"
                              checked={
                                initialValues.check_fonctionne_392102 ===
                                'Oui sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Oui sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0110`}>
                              Oui sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_392102"
                              checked={
                                initialValues.check_fonctionne_392102 ===
                                'Possible avec très peu de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec très peu de difficultés"
                              id={`0111`}
                            />
                            <label className="form-check-label" htmlFor={`0111`}>
                              Possible avec très peu de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_392102"
                              checked={
                                initialValues.check_fonctionne_392102 ===
                                'Possible avec quelques difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec quelques difficultés"
                              id={`0112`}
                            />
                            <label className="form-check-label" htmlFor={`0112`}>
                              Possible avec quelques difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_392102"
                              checked={
                                initialValues.check_fonctionne_392102 ===
                                'Possible avec beaucoup de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec beaucoup de difficultés"
                              id={`0113`}
                            />
                            <label className="form-check-label" htmlFor={`0113`}>
                              Possible avec beaucoup de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_392102"
                              checked={
                                initialValues.check_fonctionne_392102 ===
                                'Presque impossible'
                                  ? true
                                  : false
                              }
                              value="Presque impossible"
                              id={`0114`}
                            />
                            <label className="form-check-label" htmlFor={`0114`}>
                              Presque impossible
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_392102"
                              checked={
                                initialValues.check_fonctionne_392102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0115`}
                            />
                            <label className="form-check-label" htmlFor={`0115`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Pouvez-vous tourner une poignée de porte ronde ?{' '}
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0116"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_402102"
                              checked={
                                initialValues.check_fonctionne_402102 ===
                                'Oui sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Oui sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0116`}>
                              Oui sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_402102"
                              checked={
                                initialValues.check_fonctionne_402102 ===
                                'Possible avec très peu de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec très peu de difficultés"
                              id={`0117`}
                            />
                            <label className="form-check-label" htmlFor={`0117`}>
                              Possible avec très peu de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_402102"
                              checked={
                                initialValues.check_fonctionne_402102 ===
                                'Possible avec quelques difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec quelques difficultés"
                              id={`0118`}
                            />
                            <label className="form-check-label" htmlFor={`0118`}>
                              Possible avec quelques difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_402102"
                              checked={
                                initialValues.check_fonctionne_402102 ===
                                'Possible avec beaucoup de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec beaucoup de difficultés"
                              id={`0119`}
                            />
                            <label className="form-check-label" htmlFor={`0119`}>
                              Possible avec beaucoup de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_402102"
                              checked={
                                initialValues.check_fonctionne_402102 ===
                                'Presque impossible'
                                  ? true
                                  : false
                              }
                              value="Presque impossible"
                              id={`0120`}
                            />
                            <label className="form-check-label" htmlFor={`0120`}>
                              Presque impossible
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_402102"
                              checked={
                                initialValues.check_fonctionne_402102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0121`}
                            />
                            <label className="form-check-label" htmlFor={`0121`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Pouvez-vous utiliser des ciseaux pour couper un morceau de papier ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0122"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_412102"
                              checked={
                                initialValues.check_fonctionne_412102 ===
                                'Oui sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Oui sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0122`}>
                              Oui sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_412102"
                              checked={
                                initialValues.check_fonctionne_412102 ===
                                'Possible avec très peu de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec très peu de difficultés"
                              id={`0123`}
                            />
                            <label className="form-check-label" htmlFor={`0123`}>
                              Possible avec très peu de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_412102"
                              checked={
                                initialValues.check_fonctionne_412102 ===
                                'Possible avec quelques difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec quelques difficultés"
                              id={`0124`}
                            />
                            <label className="form-check-label" htmlFor={`0124`}>
                              Possible avec quelques difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_412102"
                              checked={
                                initialValues.check_fonctionne_412102 ===
                                'Possible avec beaucoup de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec beaucoup de difficultés"
                              id={`0125`}
                            />
                            <label className="form-check-label" htmlFor={`0125`}>
                              Possible avec beaucoup de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_412102"
                              checked={
                                initialValues.check_fonctionne_412102 ===
                                'Presque impossible'
                                  ? true
                                  : false
                              }
                              value="Presque impossible"
                              id={`0126`}
                            />
                            <label className="form-check-label" htmlFor={`0126`}>
                              Presque impossible
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_412102"
                              checked={
                                initialValues.check_fonctionne_412102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0127`}
                            />
                            <label className="form-check-label" htmlFor={`0127`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Pouvez-vous saisir les pièces de monnaie sur une table ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0128"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_422102"
                              checked={
                                initialValues.check_fonctionne_422102 ===
                                'Oui sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Oui sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0128`}>
                              Oui sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_422102"
                              checked={
                                initialValues.check_fonctionne_422102 ===
                                'Possible avec très peu de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec très peu de difficultés"
                              id={`0129`}
                            />
                            <label className="form-check-label" htmlFor={`0129`}>
                              Possible avec très peu de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_422102"
                              checked={
                                initialValues.check_fonctionne_422102 ===
                                'Possible avec quelques difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec quelques difficultés"
                              id={`01300`}
                            />
                            <label className="form-check-label" htmlFor={`01300`}>
                              Possible avec quelques difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_422102"
                              checked={
                                initialValues.check_fonctionne_422102 ===
                                'Possible avec beaucoup de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec beaucoup de difficultés"
                              id={`0130`}
                            />
                            <label className="form-check-label" htmlFor={`0130`}>
                              Possible avec beaucoup de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_422102"
                              checked={
                                initialValues.check_fonctionne_422102 ===
                                'Presque impossible'
                                  ? true
                                  : false
                              }
                              value="Presque impossible"
                              id={`0131`}
                            />
                            <label className="form-check-label" htmlFor={`0131`}>
                              Presque impossible
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_422102"
                              checked={
                                initialValues.check_fonctionne_422102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0132`}
                            />
                            <label className="form-check-label" htmlFor={`0132`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Pouvez-vous tourner une clé dans la serrure ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0133"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_432102"
                              checked={
                                initialValues.check_fonctionne_432102 ===
                                'Oui sans difficulté'
                                  ? true
                                  : false
                              }
                              value="Oui sans difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0133`}>
                              Oui sans difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_432102"
                              checked={
                                initialValues.check_fonctionne_432102 ===
                                'Possible avec très peu de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec très peu de difficultés"
                              id={`0134`}
                            />
                            <label className="form-check-label" htmlFor={`0134`}>
                              Possible avec très peu de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_432102"
                              checked={
                                initialValues.check_fonctionne_432102 ===
                                'Possible avec quelques difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec quelques difficultés"
                              id={`01340`}
                            />
                            <label className="form-check-label" htmlFor={`01340`}>
                              Possible avec quelques difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_432102"
                              checked={
                                initialValues.check_fonctionne_432102 ===
                                'Possible avec beaucoup de difficultés'
                                  ? true
                                  : false
                              }
                              value="Possible avec beaucoup de difficultés"
                              id={`0135`}
                            />
                            <label className="form-check-label" htmlFor={`0135`}>
                              Possible avec beaucoup de difficultés
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_432102"
                              checked={
                                initialValues.check_fonctionne_432102 ===
                                'Presque impossible'
                                  ? true
                                  : false
                              }
                              value="Presque impossible"
                              id={`0136`}
                            />
                            <label className="form-check-label" htmlFor={`0136`}>
                              Presque impossible
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_432102"
                              checked={
                                initialValues.check_fonctionne_432102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0137`}
                            />
                            <label className="form-check-label" htmlFor={`0137`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundColor: '#035ef8' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}> Scores - Quick Dash</span>
          </div>
          <br />
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Veuillez évaluer vos possibilités d’effectuer les activités
                      suivantes au cours des 7 derniers jours. Dévisser un couvercle serré
                      ou neuf
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0140"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_452102"
                              checked={
                                initialValues.check_fonctionne_452102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0140`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_452102"
                              checked={
                                initialValues.check_fonctionne_452102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0141`}
                            />
                            <label className="form-check-label" htmlFor={`0141`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_452102"
                              checked={
                                initialValues.check_fonctionne_452102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0142`}
                            />
                            <label className="form-check-label" htmlFor={`0142`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_452102"
                              checked={
                                initialValues.check_fonctionne_452102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0143`}
                            />
                            <label className="form-check-label" htmlFor={`0143`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_452102"
                              checked={
                                initialValues.check_fonctionne_452102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0144`}
                            />
                            <label className="form-check-label" htmlFor={`0144`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Effectuer des tâches ménagères lourdes (nettoyage des sols ou des
                      murs)
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0145"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_462102"
                              checked={
                                initialValues.check_fonctionne_462102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0145`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_462102"
                              checked={
                                initialValues.check_fonctionne_462102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0146`}
                            />
                            <label className="form-check-label" htmlFor={`0146`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_462102"
                              checked={
                                initialValues.check_fonctionne_462102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0147`}
                            />
                            <label className="form-check-label" htmlFor={`0147`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_462102"
                              checked={
                                initialValues.check_fonctionne_462102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0148`}
                            />
                            <label className="form-check-label" htmlFor={`0148`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_462102"
                              checked={
                                initialValues.check_fonctionne_462102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0149`}
                            />
                            <label className="form-check-label" htmlFor={`0149`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Porter des sacs de provisions ou une mallette
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0150"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_472102"
                              checked={
                                initialValues.check_fonctionne_472102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0150`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_472102"
                              checked={
                                initialValues.check_fonctionne_472102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0151`}
                            />
                            <label className="form-check-label" htmlFor={`0151`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_472102"
                              checked={
                                initialValues.check_fonctionne_472102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0152`}
                            />
                            <label className="form-check-label" htmlFor={`0152`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_472102"
                              checked={
                                initialValues.check_fonctionne_472102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0153`}
                            />
                            <label className="form-check-label" htmlFor={`0153`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_472102"
                              checked={
                                initialValues.check_fonctionne_472102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0154`}
                            />
                            <label className="form-check-label" htmlFor={`0154`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Se laver le dos
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0155"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_482102"
                              checked={
                                initialValues.check_fonctionne_482102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0155`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_482102"
                              checked={
                                initialValues.check_fonctionne_482102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0156`}
                            />
                            <label className="form-check-label" htmlFor={`0156`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_482102"
                              checked={
                                initialValues.check_fonctionne_482102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0157`}
                            />
                            <label className="form-check-label" htmlFor={`0157`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_482102"
                              checked={
                                initialValues.check_fonctionne_482102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0158`}
                            />
                            <label className="form-check-label" htmlFor={`0158`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_482102"
                              checked={
                                initialValues.check_fonctionne_482102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0159`}
                            />
                            <label className="form-check-label" htmlFor={`0159`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Couper la nourriture avec un couteau
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0160"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_502102"
                              checked={
                                initialValues.check_fonctionne_502102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0160`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_502102"
                              checked={
                                initialValues.check_fonctionne_502102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0161`}
                            />
                            <label className="form-check-label" htmlFor={`0161`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_502102"
                              checked={
                                initialValues.check_fonctionne_502102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0162`}
                            />
                            <label className="form-check-label" htmlFor={`0162`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_502102"
                              checked={
                                initialValues.check_fonctionne_502102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0163`}
                            />
                            <label className="form-check-label" htmlFor={`0163`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_502102"
                              checked={
                                initialValues.check_fonctionne_502102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0164`}
                            />
                            <label className="form-check-label" htmlFor={`0164`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Activités de loisir nécessitant une certaine force ou avec des chocs
                      au niveau de l’épaule, du bras ou de la main (bricolage, tennis,
                      golf, etc.)
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0165"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_512102"
                              checked={
                                initialValues.check_fonctionne_512102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0165`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_512102"
                              checked={
                                initialValues.check_fonctionne_512102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0166`}
                            />
                            <label className="form-check-label" htmlFor={`0166`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_512102"
                              checked={
                                initialValues.check_fonctionne_512102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0167`}
                            />
                            <label className="form-check-label" htmlFor={`0167`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_512102"
                              checked={
                                initialValues.check_fonctionne_512102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0168`}
                            />
                            <label className="form-check-label" htmlFor={`0168`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_512102"
                              checked={
                                initialValues.check_fonctionne_512102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0169`}
                            />
                            <label className="form-check-label" htmlFor={`0169`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Pendant les 7 derniers jours, à quel point votre épaule, votre bras
                      ou votre main vous a-t-elle gêné dans vos relations avec votre
                      famille, vos amis ou vos voisins ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0170"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_522102"
                              checked={
                                initialValues.check_fonctionne_522102 === 'Pas du tout'
                                  ? true
                                  : false
                              }
                              value="Pas du tout"
                            />
                            <label className="form-check-label" htmlFor={`0170`}>
                              Pas du tout
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_522102"
                              checked={
                                initialValues.check_fonctionne_522102 === 'Légèrement'
                                  ? true
                                  : false
                              }
                              value="Légèrement"
                              id={`0171`}
                            />
                            <label className="form-check-label" htmlFor={`0171`}>
                              Légèrement
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_522102"
                              checked={
                                initialValues.check_fonctionne_522102 === 'Moyennement'
                                  ? true
                                  : false
                              }
                              value="Moyennement"
                              id={`0172`}
                            />
                            <label className="form-check-label" htmlFor={`0172`}>
                              Moyennement
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_522102"
                              checked={
                                initialValues.check_fonctionne_522102 === 'Beaucoup'
                                  ? true
                                  : false
                              }
                              value="Beaucoup"
                              id={`0173`}
                            />
                            <label className="form-check-label" htmlFor={`0173`}>
                              Beaucoup
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_522102"
                              checked={
                                initialValues.check_fonctionne_522102 === 'Extrêmement'
                                  ? true
                                  : false
                              }
                              value="Extrêmement"
                              id={`0174`}
                            />
                            <label className="form-check-label" htmlFor={`0174`}>
                              Extrêmement
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Avez-vous été limité dans votre travail ou une de vos activités
                      quotidiennes habituelles en raison de problèmes à votre épaule,
                      votre bras ou votre main ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0175"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_532102"
                              checked={
                                initialValues.check_fonctionne_532102 === 'Pas du tout'
                                  ? true
                                  : false
                              }
                              value="Pas du tout"
                            />
                            <label className="form-check-label" htmlFor={`0175`}>
                              Pas du tout
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_532102"
                              checked={
                                initialValues.check_fonctionne_532102 === 'Légèrement'
                                  ? true
                                  : false
                              }
                              value="Légèrement"
                              id={`0176`}
                            />
                            <label className="form-check-label" htmlFor={`0176`}>
                              Légèrement
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_532102"
                              checked={
                                initialValues.check_fonctionne_532102 === 'Moyennement'
                                  ? true
                                  : false
                              }
                              value="Moyennement"
                              id={`0177`}
                            />
                            <label className="form-check-label" htmlFor={`0177`}>
                              Moyennement
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_532102"
                              checked={
                                initialValues.check_fonctionne_532102 === 'Beaucoup'
                                  ? true
                                  : false
                              }
                              value="Beaucoup"
                              id={`0178`}
                            />
                            <label className="form-check-label" htmlFor={`0178`}>
                              Beaucoup
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_532102"
                              checked={
                                initialValues.check_fonctionne_532102 === 'Extrêmement'
                                  ? true
                                  : false
                              }
                              value="Extrêmement"
                              id={`0179`}
                            />
                            <label className="form-check-label" htmlFor={`0179`}>
                              Extrêmement
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Veuillez évaluer la sévérité des symptômes suivants durant les 7
                      derniers jours. Douleur de l’épaule, du bras ou de la main ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0180"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_542102"
                              checked={
                                initialValues.check_fonctionne_542102 === 'Pas du tout'
                                  ? true
                                  : false
                              }
                              value="Pas du tout"
                            />
                            <label className="form-check-label" htmlFor={`0180`}>
                              Pas du tout
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_542102"
                              checked={
                                initialValues.check_fonctionne_542102 === 'Légèrement'
                                  ? true
                                  : false
                              }
                              value="Légèrement"
                              id={`0181`}
                            />
                            <label className="form-check-label" htmlFor={`0181`}>
                              Légèrement
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_542102"
                              checked={
                                initialValues.check_fonctionne_542102 === 'Moyennement'
                                  ? true
                                  : false
                              }
                              value="Moyennement"
                              id={`0182`}
                            />
                            <label className="form-check-label" htmlFor={`0182`}>
                              Moyennement
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_542102"
                              checked={
                                initialValues.check_fonctionne_542102 === 'Beaucoup'
                                  ? true
                                  : false
                              }
                              value="Beaucoup"
                              id={`0183`}
                            />
                            <label className="form-check-label" htmlFor={`0183`}>
                              Beaucoup
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_542102"
                              checked={
                                initialValues.check_fonctionne_542102 === 'Extrêmement'
                                  ? true
                                  : false
                              }
                              value="Extrêmement"
                              id={`0184`}
                            />
                            <label className="form-check-label" htmlFor={`0184`}>
                              Extrêmement
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Picotements ou fourmillements douloureux de l’épaule, du bras, ou de
                      la main ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0185"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_552102"
                              checked={
                                initialValues.check_fonctionne_552102 === 'Pas du tout'
                                  ? true
                                  : false
                              }
                              value="Pas du tout"
                            />
                            <label className="form-check-label" htmlFor={`0185`}>
                              Pas du tout
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_552102"
                              checked={
                                initialValues.check_fonctionne_552102 === 'Légèrement'
                                  ? true
                                  : false
                              }
                              value="Légèrement"
                              id={`0186`}
                            />
                            <label className="form-check-label" htmlFor={`0186`}>
                              Légèrement
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_552102"
                              checked={
                                initialValues.check_fonctionne_552102 === 'Moyennement'
                                  ? true
                                  : false
                              }
                              value="Moyennement"
                              id={`0187`}
                            />
                            <label className="form-check-label" htmlFor={`0187`}>
                              Moyennement
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_552102"
                              checked={
                                initialValues.check_fonctionne_552102 === 'Beaucoup'
                                  ? true
                                  : false
                              }
                              value="Beaucoup"
                              id={`0188`}
                            />
                            <label className="form-check-label" htmlFor={`0188`}>
                              Beaucoup
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_552102"
                              checked={
                                initialValues.check_fonctionne_552102 === 'Extrêmement'
                                  ? true
                                  : false
                              }
                              value="Extrêmement"
                              id={`0189`}
                            />
                            <label className="form-check-label" htmlFor={`0189`}>
                              Extrêmement
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Pendant les 7 derniers jours, votre sommeil a-t-il été perturbé par
                      une douleur de votre épaule, de votre bras ou de votre main ?{' '}
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0190"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_562102"
                              checked={
                                initialValues.check_fonctionne_562102 ===
                                'Pas de tout perturbé'
                                  ? true
                                  : false
                              }
                              value="Pas de tout perturbé"
                            />
                            <label className="form-check-label" htmlFor={`0190`}>
                              Pas de tout perturbé
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_562102"
                              checked={
                                initialValues.check_fonctionne_562102 ===
                                'Un peu perturbé'
                                  ? true
                                  : false
                              }
                              value="Un peu perturbé"
                              id={`0191`}
                            />
                            <label className="form-check-label" htmlFor={`0191`}>
                              Un peu perturbé
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_562102"
                              checked={
                                initialValues.check_fonctionne_562102 ===
                                'Moyennement perturbé'
                                  ? true
                                  : false
                              }
                              value="Moyennement perturbé"
                              id={`0192`}
                            />
                            <label className="form-check-label" htmlFor={`0192`}>
                              Moyennement perturbé
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_562102"
                              checked={
                                initialValues.check_fonctionne_562102 === 'Très perturbé'
                                  ? true
                                  : false
                              }
                              value="Très perturbé"
                              id={`0193`}
                            />
                            <label className="form-check-label" htmlFor={`0193`}>
                              Très perturbé
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_562102"
                              checked={
                                initialValues.check_fonctionne_562102 ===
                                'Tellement perturbé que je ne peux pas dormir'
                                  ? true
                                  : false
                              }
                              value="Tellement perturbé que je ne peux pas dormir"
                              id={`0194`}
                            />
                            <label className="form-check-label" htmlFor={`0194`}>
                              Tellement perturbé que je ne peux pas dormir
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundColor: '#035ef8' }}
            onClick={() => this.handleChangeSubSectionBtn('showEntretien')}
            className="actual_bilan_settings_item_header sub_section_btn"
          >
            <span style={{ color: '#ffffff' }}> Scores - Dash</span>
          </div>
          <br />
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Au cours des 7 derniers jours, évaluez votre capacité à dévisser un
                      couvercle serré ou neuf
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0195"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_562102"
                              checked={
                                initialValues.check_fonctionne_562102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0195`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_562102"
                              checked={
                                initialValues.check_fonctionne_562102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0196`}
                            />
                            <label className="form-check-label" htmlFor={`0196`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_562102"
                              checked={
                                initialValues.check_fonctionne_562102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0197`}
                            />
                            <label className="form-check-label" htmlFor={`0197`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_562102"
                              checked={
                                initialValues.check_fonctionne_562102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0198`}
                            />
                            <label className="form-check-label" htmlFor={`0198`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_562102"
                              checked={
                                initialValues.check_fonctionne_562102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0199`}
                            />
                            <label className="form-check-label" htmlFor={`0199`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Au cours des 7 derniers jours, évaluez votre capacité à écrire
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0200"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_572102"
                              checked={
                                initialValues.check_fonctionne_572102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0200`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_572102"
                              checked={
                                initialValues.check_fonctionne_572102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0201`}
                            />
                            <label className="form-check-label" htmlFor={`0201`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_572102"
                              checked={
                                initialValues.check_fonctionne_572102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0202`}
                            />
                            <label className="form-check-label" htmlFor={`0202`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_572102"
                              checked={
                                initialValues.check_fonctionne_572102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0203`}
                            />
                            <label className="form-check-label" htmlFor={`0203`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_572102"
                              checked={
                                initialValues.check_fonctionne_572102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0204`}
                            />
                            <label className="form-check-label" htmlFor={`0204`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Au cours des 7 derniers jours, évaluez votre capacité à tourner une
                      clé dans une serrure
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0205"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_582102"
                              checked={
                                initialValues.check_fonctionne_582102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0205`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_582102"
                              checked={
                                initialValues.check_fonctionne_582102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0206`}
                            />
                            <label className="form-check-label" htmlFor={`0206`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_582102"
                              checked={
                                initialValues.check_fonctionne_582102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0207`}
                            />
                            <label className="form-check-label" htmlFor={`0207`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_582102"
                              checked={
                                initialValues.check_fonctionne_582102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0208`}
                            />
                            <label className="form-check-label" htmlFor={`0208`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_582102"
                              checked={
                                initialValues.check_fonctionne_582102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0209`}
                            />
                            <label className="form-check-label" htmlFor={`0209`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Au cours des 7 derniers jours, évaluez votre capacité à préparer un
                      repas
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0210"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_592102"
                              checked={
                                initialValues.check_fonctionne_592102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0210`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_592102"
                              checked={
                                initialValues.check_fonctionne_592102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0211`}
                            />
                            <label className="form-check-label" htmlFor={`0211`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_592102"
                              checked={
                                initialValues.check_fonctionne_592102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0212`}
                            />
                            <label className="form-check-label" htmlFor={`0212`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_592102"
                              checked={
                                initialValues.check_fonctionne_592102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0213`}
                            />
                            <label className="form-check-label" htmlFor={`0213`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_592102"
                              checked={
                                initialValues.check_fonctionne_592102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0214`}
                            />
                            <label className="form-check-label" htmlFor={`0214`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Au cours des 7 derniers jours, évaluez votre capacité à ouvrir un
                      portail ou une lourde porte en la poussant{' '}
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0212"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_602102"
                              checked={
                                initialValues.check_fonctionne_602102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0216`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_602102"
                              checked={
                                initialValues.check_fonctionne_602102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0217`}
                            />
                            <label className="form-check-label" htmlFor={`0217`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_602102"
                              checked={
                                initialValues.check_fonctionne_602102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0218`}
                            />
                            <label className="form-check-label" htmlFor={`0218`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_602102"
                              checked={
                                initialValues.check_fonctionne_602102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0219`}
                            />
                            <label className="form-check-label" htmlFor={`0219`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_602102"
                              checked={
                                initialValues.check_fonctionne_602102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0220`}
                            />
                            <label className="form-check-label" htmlFor={`0220`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Au cours des 7 derniers jours, évaluez votre capacité à placer un
                      objet sur une étagère au-dessus de votre tête
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0221"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_612102"
                              checked={
                                initialValues.check_fonctionne_612102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0221`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_612102"
                              checked={
                                initialValues.check_fonctionne_612102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0222`}
                            />
                            <label className="form-check-label" htmlFor={`0222`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_612102"
                              checked={
                                initialValues.check_fonctionne_612102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0223`}
                            />
                            <label className="form-check-label" htmlFor={`0223`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_612102"
                              checked={
                                initialValues.check_fonctionne_612102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0224`}
                            />
                            <label className="form-check-label" htmlFor={`0224`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_612102"
                              checked={
                                initialValues.check_fonctionne_612102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0225`}
                            />
                            <label className="form-check-label" htmlFor={`0225`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Au cours des 7 derniers jours, évaluez votre capacité à effectuer
                      des tâches ménagères lourdes
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0226"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_622102"
                              checked={
                                initialValues.check_fonctionne_622102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0226`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_622102"
                              checked={
                                initialValues.check_fonctionne_622102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0227`}
                            />
                            <label className="form-check-label" htmlFor={`0227`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_622102"
                              checked={
                                initialValues.check_fonctionne_622102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0228`}
                            />
                            <label className="form-check-label" htmlFor={`0228`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_622102"
                              checked={
                                initialValues.check_fonctionne_622102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0229`}
                            />
                            <label className="form-check-label" htmlFor={`0229`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_622102"
                              checked={
                                initialValues.check_fonctionne_622102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0230`}
                            />
                            <label className="form-check-label" htmlFor={`0230`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Au cours des 7 derniers jours, évaluez votre capacité à jardiner,
                      s’occuper des plantes
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0231"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_632102"
                              checked={
                                initialValues.check_fonctionne_632102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0231`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_632102"
                              checked={
                                initialValues.check_fonctionne_632102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0232`}
                            />
                            <label className="form-check-label" htmlFor={`0232`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_632102"
                              checked={
                                initialValues.check_fonctionne_632102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0233`}
                            />
                            <label className="form-check-label" htmlFor={`0233`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_632102"
                              checked={
                                initialValues.check_fonctionne_632102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0234`}
                            />
                            <label className="form-check-label" htmlFor={`0234`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_632102"
                              checked={
                                initialValues.check_fonctionne_632102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0235`}
                            />
                            <label className="form-check-label" htmlFor={`0235`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Au cours des 7 derniers jours, évaluez votre capacité à faire un lit
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0236"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_642102"
                              checked={
                                initialValues.check_fonctionne_642102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0236`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_642102"
                              checked={
                                initialValues.check_fonctionne_642102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0237`}
                            />
                            <label className="form-check-label" htmlFor={`0237`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_642102"
                              checked={
                                initialValues.check_fonctionne_642102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0238`}
                            />
                            <label className="form-check-label" htmlFor={`0238`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_642102"
                              checked={
                                initialValues.check_fonctionne_642102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0239`}
                            />
                            <label className="form-check-label" htmlFor={`0239`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_642102"
                              checked={
                                initialValues.check_fonctionne_642102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0240`}
                            />
                            <label className="form-check-label" htmlFor={`0240`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Au cours des 7 derniers jours, évaluez votre capacité à porter des
                      sacs de provisions ou une mallette
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0241"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_652102"
                              checked={
                                initialValues.check_fonctionne_652102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0241`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_652102"
                              checked={
                                initialValues.check_fonctionne_652102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0242`}
                            />
                            <label className="form-check-label" htmlFor={`0242`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_652102"
                              checked={
                                initialValues.check_fonctionne_652102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0243`}
                            />
                            <label className="form-check-label" htmlFor={`0243`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_652102"
                              checked={
                                initialValues.check_fonctionne_652102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0244`}
                            />
                            <label className="form-check-label" htmlFor={`0244`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_652102"
                              checked={
                                initialValues.check_fonctionne_652102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0245`}
                            />
                            <label className="form-check-label" htmlFor={`0245`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Au cours des 7 derniers jours, évaluez votre capacité à porter un
                      objet lourd (supérieur à 5 kg)
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0246"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_662102"
                              checked={
                                initialValues.check_fonctionne_662102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0246`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_662102"
                              checked={
                                initialValues.check_fonctionne_662102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0247`}
                            />
                            <label className="form-check-label" htmlFor={`0247`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_662102"
                              checked={
                                initialValues.check_fonctionne_662102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0248`}
                            />
                            <label className="form-check-label" htmlFor={`0248`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_662102"
                              checked={
                                initialValues.check_fonctionne_662102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0249`}
                            />
                            <label className="form-check-label" htmlFor={`0249`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_662102"
                              checked={
                                initialValues.check_fonctionne_662102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0250`}
                            />
                            <label className="form-check-label" htmlFor={`0250`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Au cours des 7 derniers jours, évaluez votre capacité à changer une
                      ampoule en hauteur
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0251"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_672102"
                              checked={
                                initialValues.check_fonctionne_672102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0251`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_672102"
                              checked={
                                initialValues.check_fonctionne_672102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0252`}
                            />
                            <label className="form-check-label" htmlFor={`0252`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_672102"
                              checked={
                                initialValues.check_fonctionne_672102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0253`}
                            />
                            <label className="form-check-label" htmlFor={`0253`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_672102"
                              checked={
                                initialValues.check_fonctionne_672102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0254`}
                            />
                            <label className="form-check-label" htmlFor={`0254`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_672102"
                              checked={
                                initialValues.check_fonctionne_672102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0255`}
                            />
                            <label className="form-check-label" htmlFor={`0255`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Au cours des 7 derniers jours, évaluez votre capacité à se laver ou
                      se sécher les cheveux
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0256"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_682102"
                              checked={
                                initialValues.check_fonctionne_682102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0256`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_682102"
                              checked={
                                initialValues.check_fonctionne_682102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0257`}
                            />
                            <label className="form-check-label" htmlFor={`0257`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_682102"
                              checked={
                                initialValues.check_fonctionne_682102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0258`}
                            />
                            <label className="form-check-label" htmlFor={`0258`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_682102"
                              checked={
                                initialValues.check_fonctionne_682102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0259`}
                            />
                            <label className="form-check-label" htmlFor={`0259`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_682102"
                              checked={
                                initialValues.check_fonctionne_682102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0260`}
                            />
                            <label className="form-check-label" htmlFor={`0260`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Au cours des 7 derniers jours, évaluez votre capacité à se laver le
                      dos
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0261"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_692102"
                              checked={
                                initialValues.check_fonctionne_692102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0261`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_692102"
                              checked={
                                initialValues.check_fonctionne_692102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0262`}
                            />
                            <label className="form-check-label" htmlFor={`0262`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_692102"
                              checked={
                                initialValues.check_fonctionne_692102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0263`}
                            />
                            <label className="form-check-label" htmlFor={`0263`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_692102"
                              checked={
                                initialValues.check_fonctionne_692102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0264`}
                            />
                            <label className="form-check-label" htmlFor={`0264`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_692102"
                              checked={
                                initialValues.check_fonctionne_692102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0265`}
                            />
                            <label className="form-check-label" htmlFor={`0265`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Au cours des 7 derniers jours, évaluez votre capacité à enfiler un
                      pull-over
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0266"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_702102"
                              checked={
                                initialValues.check_fonctionne_702102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0266`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_702102"
                              checked={
                                initialValues.check_fonctionne_702102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0267`}
                            />
                            <label className="form-check-label" htmlFor={`0267`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_702102"
                              checked={
                                initialValues.check_fonctionne_702102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0268`}
                            />
                            <label className="form-check-label" htmlFor={`0268`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_702102"
                              checked={
                                initialValues.check_fonctionne_702102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0269`}
                            />
                            <label className="form-check-label" htmlFor={`0269`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_702102"
                              checked={
                                initialValues.check_fonctionne_702102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0270`}
                            />
                            <label className="form-check-label" htmlFor={`0270`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Au cours des 7 derniers jours, évaluez votre capacité à couper la
                      nourriture avec un couteau
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0271"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_712102"
                              checked={
                                initialValues.check_fonctionne_712102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0271`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_712102"
                              checked={
                                initialValues.check_fonctionne_712102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0272`}
                            />
                            <label className="form-check-label" htmlFor={`0272`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_712102"
                              checked={
                                initialValues.check_fonctionne_712102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0273`}
                            />
                            <label className="form-check-label" htmlFor={`0273`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_712102"
                              checked={
                                initialValues.check_fonctionne_712102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0274`}
                            />
                            <label className="form-check-label" htmlFor={`0274`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_712102"
                              checked={
                                initialValues.check_fonctionne_712102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0275`}
                            />
                            <label className="form-check-label" htmlFor={`0275`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Activités de loisirs sans gros effort (jouer aux cartes, tricoter
                      etc.)
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0276"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_722102"
                              checked={
                                initialValues.check_fonctionne_722102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0276`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_722102"
                              checked={
                                initialValues.check_fonctionne_722102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0277`}
                            />
                            <label className="form-check-label" htmlFor={`0277`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_722102"
                              checked={
                                initialValues.check_fonctionne_722102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0278`}
                            />
                            <label className="form-check-label" htmlFor={`0278`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_722102"
                              checked={
                                initialValues.check_fonctionne_722102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0279`}
                            />
                            <label className="form-check-label" htmlFor={`0279`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_722102"
                              checked={
                                initialValues.check_fonctionne_722102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0280`}
                            />
                            <label className="form-check-label" htmlFor={`0280`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Activités de loisirs nécessitant une certaine force ou avec des
                      chocs au niveau de l’épaule du bras ou de la main (bricolage,
                      tennis, golf, etc.)
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0281"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_732102"
                              checked={
                                initialValues.check_fonctionne_732102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0281`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_732102"
                              checked={
                                initialValues.check_fonctionne_732102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0282`}
                            />
                            <label className="form-check-label" htmlFor={`0282`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_732102"
                              checked={
                                initialValues.check_fonctionne_732102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0283`}
                            />
                            <label className="form-check-label" htmlFor={`0283`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_732102"
                              checked={
                                initialValues.check_fonctionne_732102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0284`}
                            />
                            <label className="form-check-label" htmlFor={`0284`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_732102"
                              checked={
                                initialValues.check_fonctionne_732102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0285`}
                            />
                            <label className="form-check-label" htmlFor={`0285`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Activités de loisirs nécessitant toute liberté de mouvement
                      (badminton, lancer de balle, pêche, Frisbee, etc.)
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0286"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_742102"
                              checked={
                                initialValues.check_fonctionne_742102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0286`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_742102"
                              checked={
                                initialValues.check_fonctionne_742102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0287`}
                            />
                            <label className="form-check-label" htmlFor={`0287`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_742102"
                              checked={
                                initialValues.check_fonctionne_742102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0288`}
                            />
                            <label className="form-check-label" htmlFor={`0288`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_742102"
                              checked={
                                initialValues.check_fonctionne_742102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0289`}
                            />
                            <label className="form-check-label" htmlFor={`0289`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_742102"
                              checked={
                                initialValues.check_fonctionne_742102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0290`}
                            />
                            <label className="form-check-label" htmlFor={`0290`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Déplacements (transports)
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0291"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_752102"
                              checked={
                                initialValues.check_fonctionne_752102 ===
                                'Aucune difficulté'
                                  ? true
                                  : false
                              }
                              value="Aucune difficulté"
                            />
                            <label className="form-check-label" htmlFor={`0291`}>
                              Aucune difficulté
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_752102"
                              checked={
                                initialValues.check_fonctionne_752102 ===
                                'Difficulté légère'
                                  ? true
                                  : false
                              }
                              value="Difficulté légère"
                              id={`0292`}
                            />
                            <label className="form-check-label" htmlFor={`0292`}>
                              Difficulté légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_752102"
                              checked={
                                initialValues.check_fonctionne_752102 ===
                                'Difficulté moyenne'
                                  ? true
                                  : false
                              }
                              value="Difficulté moyenne"
                              id={`0293`}
                            />
                            <label className="form-check-label" htmlFor={`0293`}>
                              Difficulté moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_752102"
                              checked={
                                initialValues.check_fonctionne_752102 ===
                                'Difficulté importante'
                                  ? true
                                  : false
                              }
                              value="Difficulté importante"
                              id={`0294`}
                            />
                            <label className="form-check-label" htmlFor={`0294`}>
                              Difficulté importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_752102"
                              checked={
                                initialValues.check_fonctionne_752102 === 'Impossible'
                                  ? true
                                  : false
                              }
                              value="Impossible"
                              id={`0295`}
                            />
                            <label className="form-check-label" htmlFor={`0295`}>
                              Impossible
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Pendant les 7 derniers jours, à quel point votre épaule, votre bras
                      ou votre main a-t-elle gêné vos relations avec votre famille, vos
                      amis ou vos voisins ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0296"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_762102"
                              checked={
                                initialValues.check_fonctionne_762102 === 'Pas du tout'
                                  ? true
                                  : false
                              }
                              value="Pas du tout"
                            />
                            <label className="form-check-label" htmlFor={`0296`}>
                              Pas du tout
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_762102"
                              checked={
                                initialValues.check_fonctionne_762102 === 'Légèrement'
                                  ? true
                                  : false
                              }
                              value="Légèrement"
                              id={`0297`}
                            />
                            <label className="form-check-label" htmlFor={`0297`}>
                              Légèrement
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_762102"
                              checked={
                                initialValues.check_fonctionne_762102 === 'Moyennement'
                                  ? true
                                  : false
                              }
                              value="Moyennement"
                              id={`0298`}
                            />
                            <label className="form-check-label" htmlFor={`0298`}>
                              Moyennement
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_762102"
                              checked={
                                initialValues.check_fonctionne_762102 === 'Beaucoup'
                                  ? true
                                  : false
                              }
                              value="Beaucoup"
                              id={`0299`}
                            />
                            <label className="form-check-label" htmlFor={`0299`}>
                              Beaucoup
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_762102"
                              checked={
                                initialValues.check_fonctionne_762102 === 'Extrêmement'
                                  ? true
                                  : false
                              }
                              value="Extrêmement"
                              id={`0300`}
                            />
                            <label className="form-check-label" htmlFor={`0300`}>
                              Extrêmement
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Avez-vous été limité dans votre travail ou une de vos activités
                      quotidiennes habituelles du fait (en raison, par) de problèmes à
                      votre épaule, votre bras ou votre main ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0301"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_772102"
                              checked={
                                initialValues.check_fonctionne_772102 === 'Pas du tout'
                                  ? true
                                  : false
                              }
                              value="Pas du tout"
                            />
                            <label className="form-check-label" htmlFor={`0301`}>
                              Pas du tout
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_772102"
                              checked={
                                initialValues.check_fonctionne_772102 === 'Légèrement'
                                  ? true
                                  : false
                              }
                              value="Légèrement"
                              id={`0302`}
                            />
                            <label className="form-check-label" htmlFor={`0302`}>
                              Légèrement
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_772102"
                              checked={
                                initialValues.check_fonctionne_772102 === 'Moyennement'
                                  ? true
                                  : false
                              }
                              value="Moyennement"
                              id={`0303`}
                            />
                            <label className="form-check-label" htmlFor={`0303`}>
                              Moyennement
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_772102"
                              checked={
                                initialValues.check_fonctionne_772102 === 'Beaucoup'
                                  ? true
                                  : false
                              }
                              value="Beaucoup"
                              id={`0304`}
                            />
                            <label className="form-check-label" htmlFor={`0304`}>
                              Beaucoup
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_772102"
                              checked={
                                initialValues.check_fonctionne_772102 === 'Extrêmement'
                                  ? true
                                  : false
                              }
                              value="Extrêmement"
                              id={`0305`}
                            />
                            <label className="form-check-label" htmlFor={`0305`}>
                              Extrêmement
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Durant les 7 derniers jours, comment sentez-vous la douleur au
                      niveau de l’épaule, du bras ou de la main au repos ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0306"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_782102"
                              checked={
                                initialValues.check_fonctionne_782102 === 'Aucune'
                                  ? true
                                  : false
                              }
                              value="Aucune"
                            />
                            <label className="form-check-label" htmlFor={`0306`}>
                              Aucune
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_782102"
                              checked={
                                initialValues.check_fonctionne_782102 === 'Légère'
                                  ? true
                                  : false
                              }
                              value="Légère"
                              id={`0307`}
                            />
                            <label className="form-check-label" htmlFor={`0307`}>
                              Légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_782102"
                              checked={
                                initialValues.check_fonctionne_782102 === 'Moyenne'
                                  ? true
                                  : false
                              }
                              value="Moyenne"
                              id={`0308`}
                            />
                            <label className="form-check-label" htmlFor={`0308`}>
                              Moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_782102"
                              checked={
                                initialValues.check_fonctionne_782102 === 'Importante'
                                  ? true
                                  : false
                              }
                              value="Importante"
                              id={`0309`}
                            />
                            <label className="form-check-label" htmlFor={`0309`}>
                              Importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_782102"
                              checked={
                                initialValues.check_fonctionne_782102 === 'Extrême'
                                  ? true
                                  : false
                              }
                              value="Extrême"
                              id={`0310`}
                            />
                            <label className="form-check-label" htmlFor={`0310`}>
                              Extrême
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Durant les 7 derniers jours, comment sentez-vous la douleur au
                      niveau de l’épaule, du bras ou de la main en pratiquant une activité
                      particulière ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0311"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_792102"
                              checked={
                                initialValues.check_fonctionne_792102 === 'Aucune'
                                  ? true
                                  : false
                              }
                              value="Aucune"
                            />
                            <label className="form-check-label" htmlFor={`0311`}>
                              Aucune
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_792102"
                              checked={
                                initialValues.check_fonctionne_792102 === 'Légère'
                                  ? true
                                  : false
                              }
                              value="Légère"
                              id={`0312`}
                            />
                            <label className="form-check-label" htmlFor={`0312`}>
                              Légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_792102"
                              checked={
                                initialValues.check_fonctionne_792102 === 'Moyenne'
                                  ? true
                                  : false
                              }
                              value="Moyenne"
                              id={`0313`}
                            />
                            <label className="form-check-label" htmlFor={`0313`}>
                              Moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_792102"
                              checked={
                                initialValues.check_fonctionne_792102 === 'Importante'
                                  ? true
                                  : false
                              }
                              value="Importante"
                              id={`0314`}
                            />
                            <label className="form-check-label" htmlFor={`0314`}>
                              Importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_792102"
                              checked={
                                initialValues.check_fonctionne_792102 === 'Extrême'
                                  ? true
                                  : false
                              }
                              value="Extrême"
                              id={`0315`}
                            />
                            <label className="form-check-label" htmlFor={`0315`}>
                              Extrême
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Durant les 7 derniers jours, avez-vous des picotements ou
                      fourmillements douloureux de l’épaule, du bras ou de la main ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0316"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_802102"
                              checked={
                                initialValues.check_fonctionne_802102 === 'Aucune'
                                  ? true
                                  : false
                              }
                              value="Aucune"
                            />
                            <label className="form-check-label" htmlFor={`0316`}>
                              Aucune
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_802102"
                              checked={
                                initialValues.check_fonctionne_802102 === 'Légère'
                                  ? true
                                  : false
                              }
                              value="Légère"
                              id={`0317`}
                            />
                            <label className="form-check-label" htmlFor={`0317`}>
                              Légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_802102"
                              checked={
                                initialValues.check_fonctionne_802102 === 'Moyenne'
                                  ? true
                                  : false
                              }
                              value="Moyenne"
                              id={`0318`}
                            />
                            <label className="form-check-label" htmlFor={`0318`}>
                              Moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_802102"
                              checked={
                                initialValues.check_fonctionne_802102 === 'Importante'
                                  ? true
                                  : false
                              }
                              value="Importante"
                              id={`0319`}
                            />
                            <label className="form-check-label" htmlFor={`0319`}>
                              Importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_802102"
                              checked={
                                initialValues.check_fonctionne_802102 === 'Extrême'
                                  ? true
                                  : false
                              }
                              value="Extrême"
                              id={`0320`}
                            />
                            <label className="form-check-label" htmlFor={`0320`}>
                              Extrême
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Durant les 7 derniers jours, sentez-vous une faiblesse du bras, de
                      l’épaule ou de la main ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0321"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_812102"
                              checked={
                                initialValues.check_fonctionne_812102 === 'Aucune'
                                  ? true
                                  : false
                              }
                              value="Aucune"
                            />
                            <label className="form-check-label" htmlFor={`0321`}>
                              Aucune
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_812102"
                              checked={
                                initialValues.check_fonctionne_812102 === 'Légère'
                                  ? true
                                  : false
                              }
                              value="Légère"
                              id={`0322`}
                            />
                            <label className="form-check-label" htmlFor={`0322`}>
                              Légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_812102"
                              checked={
                                initialValues.check_fonctionne_812102 === 'Moyenne'
                                  ? true
                                  : false
                              }
                              value="Moyenne"
                              id={`0323`}
                            />
                            <label className="form-check-label" htmlFor={`0323`}>
                              Moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_812102"
                              checked={
                                initialValues.check_fonctionne_812102 === 'Importante'
                                  ? true
                                  : false
                              }
                              value="Importante"
                              id={`0324`}
                            />
                            <label className="form-check-label" htmlFor={`0324`}>
                              Importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_812102"
                              checked={
                                initialValues.check_fonctionne_812102 === 'Extrême'
                                  ? true
                                  : false
                              }
                              value="Extrême"
                              id={`0325`}
                            />
                            <label className="form-check-label" htmlFor={`0325`}>
                              Extrême
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Durant les 7 derniers jours, sentez-vous une raideur du bras, de
                      l’épaule ou de la main ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0326"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_822102"
                              checked={
                                initialValues.check_fonctionne_822102 === 'Aucune'
                                  ? true
                                  : false
                              }
                              value="Aucune"
                            />
                            <label className="form-check-label" htmlFor={`0326`}>
                              Aucune
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_822102"
                              checked={
                                initialValues.check_fonctionne_822102 === 'Légère'
                                  ? true
                                  : false
                              }
                              value="Légère"
                              id={`0327`}
                            />
                            <label className="form-check-label" htmlFor={`0327`}>
                              Légère
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_822102"
                              checked={
                                initialValues.check_fonctionne_822102 === 'Moyenne'
                                  ? true
                                  : false
                              }
                              value="Moyenne"
                              id={`0328`}
                            />
                            <label className="form-check-label" htmlFor={`0328`}>
                              Moyenne
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_822102"
                              checked={
                                initialValues.check_fonctionne_822102 === 'Importante'
                                  ? true
                                  : false
                              }
                              value="Importante"
                              id={`0329`}
                            />
                            <label className="form-check-label" htmlFor={`0329`}>
                              Importante
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_822102"
                              checked={
                                initialValues.check_fonctionne_822102 === 'Extrême'
                                  ? true
                                  : false
                              }
                              value="Extrême"
                              id={`0330`}
                            />
                            <label className="form-check-label" htmlFor={`0330`}>
                              Extrême
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      Durant les 7 derniers jours, votre sommeil a-t-il été perturbé par
                      une douleur de votre épaule, de votre bras ou de votre main ?
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0331"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_832102"
                              checked={
                                initialValues.check_fonctionne_832102 === 'Pas du tout'
                                  ? true
                                  : false
                              }
                              value="Pas du tout"
                            />
                            <label className="form-check-label" htmlFor={`0331`}>
                              Pas du tout
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_832102"
                              checked={
                                initialValues.check_fonctionne_832102 === 'Un peu'
                                  ? true
                                  : false
                              }
                              value="Un peu"
                              id={`0332`}
                            />
                            <label className="form-check-label" htmlFor={`0332`}>
                              Un peu
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_832102"
                              checked={
                                initialValues.check_fonctionne_832102 === 'Moyennement'
                                  ? true
                                  : false
                              }
                              value="Moyennement"
                              id={`0333`}
                            />
                            <label className="form-check-label" htmlFor={`0333`}>
                              Moyennement
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_832102"
                              checked={
                                initialValues.check_fonctionne_832102 === 'Très perturbé'
                                  ? true
                                  : false
                              }
                              value="Très perturbé"
                              id={`0334`}
                            />
                            <label className="form-check-label" htmlFor={`0334`}>
                              Très perturbé
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_832102"
                              checked={
                                initialValues.check_fonctionne_832102 ===
                                'Insomnie complète'
                                  ? true
                                  : false
                              }
                              value="Insomnie complète"
                              id={`0335`}
                            />
                            <label className="form-check-label" htmlFor={`0335`}>
                              Insomnie complète
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="actual_bilan_settings_item_body "
            id="headShaking1_sub_section"
            style={{ display: 'flex', width: '100%' }}
          >
            <div
              className="bilan_item"
              style={{
                width: '50%',
                backgroundColor: '#f3f4f9',
              }}
            >
              <div className="bilan_handler">
                <div className="bilan_handler_info">
                  <div
                    style={{
                      alignItems: 'start',
                    }}
                    className="bilan_contact_info"
                  >
                    <span
                      style={{
                        marginLeft: '3%',
                      }}
                      id="name"
                    >
                      “Je me sens moins capable, moins confiant ou moins utile à cause du
                      problème de mon épaule, de mon bras ou de ma main”
                    </span>
                  </div>
                  <div style={{ padding: '2%' }}>
                    <div className="field_sub_section_container_item">
                      <div className="checkbox_options">
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="0336"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_842102"
                              checked={
                                initialValues.check_fonctionne_842102 === 'Pas du tout d’accord'
                                  ? true
                                  : false
                              }
                              value="Pas du tout d’accord"
                            />
                            <label className="form-check-label" htmlFor={`0336`}>
                              Pas du tout d’accord
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_842102"
                              checked={
                                initialValues.check_fonctionne_842102 === 'Pas d’accord'
                                  ? true
                                  : false
                              }
                              value="Pas d’accord"
                              id={`0337`}
                            />
                            <label className="form-check-label" htmlFor={`0337`}>
                              Pas d’accord
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_842102"
                              checked={
                                initialValues.check_fonctionne_842102 === 'Ni d’accord, ni pas d’accord'
                                  ? true
                                  : false
                              }
                              value="Ni d’accord, ni pas d’accord"
                              id={`0338`}
                            />
                            <label className="form-check-label" htmlFor={`0338`}>
                              Ni d’accord, ni pas d’accord
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_842102"
                              checked={
                                initialValues.check_fonctionne_842102 === 'D’accord'
                                  ? true
                                  : false
                              }
                              value="D’accord"
                              id={`0339`}
                            />
                            <label className="form-check-label" htmlFor={`0339`}>
                              D’accord
                            </label>
                          </div>
                        </div>
                        <div className="checkbox_options_item">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              onChange={this.props.handleChangeInput}
                              name="check_fonctionne_842102"
                              checked={
                                initialValues.check_fonctionne_842102 ===
                                'Tout à fait d’accord'
                                  ? true
                                  : false
                              }
                              value="Tout à fait d’accord"
                              id={`0340`}
                            />
                            <label className="form-check-label" htmlFor={`0340`}>
                              Tout à fait d’accord
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Fonctionnel;
