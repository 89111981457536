/*
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { protocolList } from '../../utils/constants';
import moment from 'moment';
import { isHasPermission } from '../../utils/helpres';

class Protocol extends Component {
  render() {
    const { consultationID, patient, user, protocoleConsultation } = this.props;
    return (
      <div style={{ marginTop: '15px' }} className="add_bilan_container">
        <div className="add_bilan_container_header">
          <div className="left">
            <span>Protocole de rééducation</span>
          </div>
          {isHasPermission(user.login_access.permissions, 'CREATE_NEW_PROTOCOLE') &&
          !patient ? (
            <Link to={`/form-plan-reeducation/${consultationID}`}>
              <div className="right">
                <svg
                  width="12"
                  height="11"
                  viewBox="0 0 12 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.2381 6.23828H6.73805V10.7383H5.26149V6.23828H0.76149V4.76172H5.26149V0.261719H6.73805V4.76172H11.2381V6.23828Z"
                    fill="#5446DB"
                  />
                </svg>
                <span>Ajouter Protocole</span>
              </div>
            </Link>
          ) : (
            <div onClick={this.props.showProtocole} className="right">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.238 13.7383V5.51172H1.76147V13.7383H12.238ZM9.98804 0.238281H11.4998V1.75H12.238C12.6365 1.75 12.988 1.90234 13.2927 2.20703C13.5974 2.51172 13.7498 2.86328 13.7498 3.26172V13.7383C13.7498 14.1367 13.5974 14.4883 13.2927 14.793C12.988 15.0977 12.6365 15.25 12.238 15.25H1.76147C1.3396 15.25 0.976318 15.0977 0.671631 14.793C0.390381 14.4883 0.249756 14.1367 0.249756 13.7383V3.26172C0.249756 2.86328 0.390381 2.51172 0.671631 2.20703C0.976318 1.90234 1.3396 1.75 1.76147 1.75H2.49976V0.238281H4.01147V1.75H9.98804V0.238281ZM10.7615 8.5V12.2617H6.99976V8.5H10.7615Z"
                  fill="#5446DB"
                />
              </svg>

              <span>Voir tous</span>
            </div>
          )}
        </div>
        <div className="add_document_container_body">
          {protocoleConsultation &&
            protocoleConsultation.length > 0 &&
            protocoleConsultation.map((protocol) => {
              return (
                <div className="add_document_content_preview">
                  <div className="add_document_content_preview_item">
                    <div
                      style={{ width: '80%' }}
                      className="bilan_preview_sub_item"
                      id="patient_consulation_timing"
                    >
                      {protocol.icon}
                      <div className="prise_en_charge_text">
                        <span id="prise_en_charge_text_title">{protocol.name}</span>
                        <span id="prise_en_charge_text_desc">
                          {moment(protocol.created_at).format('DD/MM/YYYY')} -{' '}
                          {moment(protocol.created_at).format('HH:mm')}
                        </span>
                      </div>
                    </div>
                    <Link
                      to={`/form-plan-reeducation/${protocol.id}/${
                        consultationID ? consultationID : protocol.consultations[0].id
                      }`}
                    >
                      <div
                        /*onClick={() => {
                                                this.getItemDocument(file.id);
                                                this.openModal();
                                              }}*/
                        style={{ marginRight: '1%' }}
                        className="more_icon_container"
                      >
                        <i
                          style={{
                            padding: '0',
                            border: '',
                            color: '',
                            borderRadius: '',
                            backgroundColor: 'white',
                          }}
                          className="fas fa-eye"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </Link>
                    {/*<div
                      className="more_icon_container"
                    >
                      <i
                        style={{
                          padding: '0',
                          border: '',
                          color: '',
                          borderRadius: '',
                          backgroundColor: 'white',
                        }}
                        className="fas fa-download"
                        aria-hidden="true"
                      ></i>
                    </div>*/}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
export default Protocol;
