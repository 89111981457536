import React, { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../store/actions';
import './index.css';
import {
  SelectorBilanItemSection,
  SelectorColorInputCheckbox,
  SelectorInputAndIcon,
} from '../../../utils';
import update from 'immutability-helper';
import CircularProgress from '@material-ui/core/CircularProgress';
import ANoter from './sections/a-noter';
import actionsJs from '../../../js/actions';
import ToastAction from '../../../../src/components/ToastAction';
import { toast } from 'react-toastify';

const orderOptions = (values) => {
  return values.filter((v) => v.isFixed).concat(values.filter((v) => !v.isFixed));
};
const mapStateToProps = (state) => ({
  addBilan: state.BilanReducer.addBilan,
  section_bilan: state.BilanReducer.section_bilan,
  loadingSectionBilan: state.BilanReducer.loadingSectionBilan,
  loadingAddBilan: state.BilanReducer.loadingAddBilan,
  medecins: state.AppReducer.medecins,
  user: state.AppReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  getMedecins: () => dispatch(actionsJs.getMedecins()),
  initialState: () => dispatch(actions.initialState()),
  submitFormBilan: (values) => dispatch(actions.submitFormBilan(values)),
  getSectionBilanFile: (bilan_id, consultation_id) =>
    dispatch(actions.getSectionBilanFile(bilan_id, consultation_id)),
});

class Epaule extends Component {
  constructor(props) {
    super(props);
    if (!props.location.state)
      props.getSectionBilanFile(
        props.match.params.bilan_id,
        props.match.params.consultation_id,
      );
    props.getMedecins();
    props.initialState();
    props.changeNamePage('Bilan Epaule');
    props.changeActiveItemSideBar(2);
    document.title = 'Bilan Epaule';
    this.state = {
      item: 0,
      id: null,
      page: null,
      section_1: true,
      section_2: false,
      section_3: false,
      section_4: false,
      section_5: false,
      section_6: false,
      section_7: false,
      section_8: false,
      section_9: false,
      section_10: false,
      section_11: false,
      section_12: false,
      isLoading: false,
      consultation_id: this.props.match.params.consultation_id,
      bilan_id: this.props.match.params.bilan_id,
      initialValues: {},
    };
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleAddNewItem = this.handleAddNewItem.bind(this);
    this.handleChangeFile = this.handleChangeFile.bind(this);

    this.handleChangeDeleteFile = this.handleChangeDeleteFile.bind(this);
    this.handleChangeInputItem = this.handleChangeInputItem.bind(this);
    this.handleDeleteItemExercice = this.handleDeleteItemExercice.bind(this);
    this.handleChangeFile = this.handleChangeFile.bind(this);
  }
  handleChangeFile(event) {
    const { initialValues } = this.state;
    const target = event.target;
    this.setState({
      initialValues: {
        ...initialValues,
        file: target.files[0],
        file_name: target.files[0].name,
      },
    });
  }
  handleChangeDeleteFile() {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        file: '',
        file_name: '',
      },
    });
  }
  handleDeleteItemExercice(id) {
    const { initialValues } = this.state;
    this.setState((prevState) => {
      let data = [...prevState.initialValues.repetitions_series];
      if (data.length > 1) {
        data = data.filter((item) => item.id !== id);
      }
      this.setState({
        initialValues: {
          ...initialValues,
          repetitions_series: data,
        },
      });
    });
  }
  onChangeSelect(value, { action, removedValue }, name, options) {
    const { initialValues } = this.state;
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        value = options.filter((v) => v.isFixed);
        break;
    }
    value = orderOptions(value || []);
    this.setState({
      initialValues: {
        ...initialValues,
        [name]: value,
      },
    });
  }
  handleChangeFiles = (event, name) => {
    const { initialValues } = this.state;
    const fileUploaded = event.target.files[0];
    this.setState({
      initialValues: {
        ...initialValues,
        [name]: fileUploaded.name,
      },
    });
  };
  handleChangeAnyInput = (value, name) => {
    const { initialValues } = this.state;
    this.setState({
      initialValues: {
        ...initialValues,
        [name]: value,
      },
    });
  };
  handleChangeInputItem(event, id, element) {
    const { initialValues } = this.state;
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (target.files) value = target.files[0];
    this.setState((prevState) => {
      const data = [...prevState.initialValues[element]];

      var commentIndex = data.findIndex(function (c) {
        return c.id === id;
      });
      const updatedComment = update(data[commentIndex], {
        [name]: {
          $set: name === 'series' || name === 'repetitions' ? parseInt(value) : value,
        },
      });
      data[commentIndex] = updatedComment;
      this.setState({
        initialValues: {
          ...initialValues,
          [element]: data,
        },
      });
    });
  }
  handleAddNewItem(element) {
    const { initialValues } = this.state;
    if (initialValues[element] && initialValues[element].length > 0) {
      this.setState((prevState) => {
        const items = [...prevState.initialValues[element]];
        items.push({
          id: initialValues[element][initialValues[element].length - 1].id + 1,
          [`frequence_item_1_${
            initialValues[element][initialValues[element].length - 1].id + 1
          }`]: '',
          [`consignes_${
            initialValues[element][initialValues[element].length - 1].id + 1
          }`]: '',
          [`Consignes_file_${
            initialValues[element][initialValues[element].length - 1].id + 1
          }`]: '',
          [`file_${initialValues[element][initialValues[element].length - 1].id + 1}`]:
            '',
        });
        this.setState({
          initialValues: {
            ...initialValues,
            [element]: items,
          },
        });
      });
    }
  }
  componentDidMount() {
    SelectorColorInputCheckbox();
    SelectorBilanItemSection();
    const { initialValues } = this.state;
    SelectorInputAndIcon();
    if (initialValues.notes && initialValues.notes.length === 0) {
      this.setState((prevState) => {
        const notes = [...prevState.initialValues.notes];
        notes.push({
          id: 1,
          notes: '',
        });
        this.setState({
          initialValues: {
            ...initialValues,
            notes: notes,
          },
        });
      });
    }
  }

  HandelChangeSection(item) {
    const { initialValues } = this.state;
    if (item != 11) {
      toast(
        ({ closeToast }) => (
          <ToastAction
            type="info"
            message="Cette section est en cours de construction. Elle sera bientôt disponible"
          />
        ),
        { hideProgressBar: true },
      );
    }

    /*     switch (item) {
      case 0:
        this.setState({
          item: item,
          section_1: true,
          section_2: false,
          section_3: false,
          section_4: false,
          section_5: false,
          section_6: false,
          section_7: false,
          section_8: false,
          section_9: false,
          section_10: false,
          section_11: false,
          section_12: false,
        });
        break;
      case 1:
        this.setState({
          item: item,
          section_1: false,
          section_2: true,
          section_3: false,
          section_4: false,
          section_5: false,
          section_6: false,
          section_7: false,
          section_8: false,
          section_9: false,
          section_10: false,
          section_11: false,
          section_12: false,
        });
        break;
      case 2:
        this.setState({
          item: item,
          section_1: false,
          section_2: false,
          section_3: true,
          section_4: false,
          section_5: false,
          section_6: false,
          section_7: false,
          section_8: false,
          section_9: false,
          section_10: false,
          section_11: false,
          section_12: false,
        });
        break;
      case 3:
        this.setState({
          item: item,
          section_1: false,
          section_2: false,
          section_3: false,
          section_4: true,
          section_5: false,
          section_6: false,
          section_7: false,
          section_8: false,
          section_9: false,
          section_10: false,
          section_11: false,
          section_12: false,
        });
        break;
      case 4:
        this.setState({
          item: item,
          section_1: false,
          section_2: false,
          section_3: false,
          section_4: false,
          section_5: true,
          section_6: false,
          section_7: false,
          section_8: false,
          section_9: false,
          section_10: false,
          section_11: false,
          section_12: false,
        });
        break;
      case 5:
        this.setState({
          item: item,
          section_1: false,
          section_2: false,
          section_3: false,
          section_4: false,
          section_5: false,
          section_6: true,
          section_7: false,
          section_8: false,
          section_9: false,
          section_10: false,
          section_11: false,
          section_12: false,
        });
        break;
      case 6:
        this.setState({
          item: item,
          section_1: false,
          section_2: false,
          section_3: false,
          section_4: false,
          section_5: false,
          section_6: false,
          section_7: true,
          section_8: false,
          section_9: false,
          section_10: false,
          section_11: false,
          section_12: false,
        });
        break;
      case 7:
        this.setState({
          item: item,
          section_1: false,
          section_2: false,
          section_3: false,
          section_4: false,
          section_5: false,
          section_6: false,
          section_7: false,
          section_8: true,
          section_9: false,
          section_10: false,
          section_11: false,
          section_12: false,
        });
        break;
      case 8:
        this.setState({
          item: item,
          section_1: false,
          section_2: false,
          section_3: false,
          section_4: false,
          section_5: false,
          section_6: false,
          section_7: false,
          section_8: false,
          section_9: true,
          section_10: false,
          section_11: false,
          section_12: false,
        });
        break;
      case 9:
        this.setState({
          item: item,
          section_1: false,
          section_2: false,
          section_3: false,
          section_4: false,
          section_5: false,
          section_6: false,
          section_7: false,
          section_8: false,
          section_9: false,
          section_10: true,
          section_11: false,
          section_12: false,
        });
        break;
      case 10:
        this.setState({
          item: item,
          section_1: false,
          section_2: false,
          section_3: false,
          section_4: false,
          section_5: false,
          section_6: false,
          section_7: false,
          section_8: false,
          section_9: false,
          section_10: false,
          section_11: true,
          section_12: false,
        });
        break;
      case 11:
        this.setState({
          item: item,
          section_1: false,
          section_2: false,
          section_3: false,
          section_4: false,
          section_5: false,
          section_6: false,
          section_7: false,
          section_8: false,
          section_9: false,
          section_10: false,
          section_11: false,
          section_12: true,
        });
        break;
      default:
        this.setState({
          item: 0,
          page: <div className="background_img"></div>,
          initialValues: {
            ...initialValues,
          },
        });
    } */
  }
  componentWillReceiveProps(nextProps, nextContext) {
    const { section_bilan, addBilan } = nextProps;

    if (addBilan) {
      nextProps.history.push('/consultations', {
        consultation_id: this.props.match.params.consultation_id,
      });
    }
    if (!this.props.location.state && section_bilan && section_bilan.bilan_id) {
      this.setState({
        id: section_bilan.id,
        consultation_id: section_bilan.consultation_id,
        bilan_id: section_bilan.bilan_id,
        initialValues: {
          ...section_bilan.path,
          id: section_bilan.id,
          bilan_date: section_bilan.createdAt,
        },
        hidden: false,
      });
    }
  }
  handleChangeInput(event) {
    const { initialValues } = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      initialValues: {
        ...initialValues,
        [name]: value,
      },
    });
  }

  render() {
    const {
      section_1,
      section_2,
      section_3,
      section_4,
      section_5,
      section_6,
      section_7,
      section_8,
      section_9,
      section_10,
      isLoading,
      section_12,
      item,
      initialValues,
    } = this.state;
    const { loadingSectionBilan, user, medecins, loadingAddBilan } = this.props;
    return (
      <>
        <div className="main_content">
          <div className="bilan_details">
            <div
              onClick={() =>
                this.props.history.push({
                  pathname: '/consultations',
                  state: { consultation_id: this.props.match.params.consultation_id },
                })
              }
              className="bilan_details_header"
            >
              <div className="back_icon">
                <img src={require('../../../assets/img/arrow1.png')} alt="" />
              </div>
              <div>
                <button
                  style={{
                    backgroundColor: '#5446db',
                    color: 'white',
                    fontSize: '20px',
                    fontWeight: '300',
                    borderRadius: '8px',
                    padding: '8px 20px',
                  }}
                  onClick={() => {
                    this.props.submitFormBilan(this.state);
                  }}
                >
                  Valider le bilan
                </button>
              </div>
            </div>
            <div className="bilan_details_container">
              <div
                onClick={() => this.HandelChangeSection(11)}
                className="bilan_details_item active_details_nav"
              >
                <i className="fas fa-angle-right" style={{ color: 'black' }}></i>
                <span id="disease_history">Notes libres</span>
              </div>
              <hr />
              <div
                onClick={() => this.HandelChangeSection(0)}
                className="bilan_details_item"
              >
                <i
                  className="fas fa-angle-right"
                  style={{ color: item > 0 ? 'black' : '' }}
                ></i>
                <span id="disease_history">Administartif</span>
              </div>
              <hr />
              <div
                onClick={() => this.HandelChangeSection(1)}
                className="bilan_details_item"
              >
                <i
                  className="fas fa-angle-right"
                  style={{
                    color: 'black',
                  }}
                ></i>
                <span id="disease_history">Contexte et Anamnèse</span>
              </div>
              <hr />
              {/*<div
                onClick={() => this.HandelChangeSection(2)}
                className="bilan_details_item"
              >
                <i
                  className="fas fa-angle-right"
                  style={{ color: item === 0 || item === 1 || item === 3 ? 'black' : '' }}
                ></i>
                <span id="disease_history">Evaluation synthétique</span>
              </div>
              <hr />*/}
              <div
                onClick={() => this.HandelChangeSection(2)}
                className="bilan_details_item"
              >
                <i className="fas fa-angle-right" style={{ color: 'black' }}></i>
                <span id="disease_history">Douleur</span>
              </div>
              <hr />
              <div
                onClick={() => this.HandelChangeSection(3)}
                className="bilan_details_item"
              >
                <i className="fas fa-angle-right" style={{ color: 'black' }}></i>
                <span id="disease_history">Trophicité</span>
              </div>
              <hr />
              <div
                onClick={() => this.HandelChangeSection(4)}
                className="bilan_details_item"
              >
                <i className="fas fa-angle-right" style={{ color: 'black' }}></i>
                <span id="disease_history">Mobilités synthétiques</span>
              </div>
              <hr />
              <div
                onClick={() => this.HandelChangeSection(5)}
                className="bilan_details_item"
              >
                <i className="fas fa-angle-right" style={{ color: 'black' }}></i>
                <span id="disease_history">Mobilités</span>
              </div>
              <hr />
              <div
                onClick={() => this.HandelChangeSection(6)}
                className="bilan_details_item"
              >
                <i className="fas fa-angle-right" style={{ color: 'black' }}></i>
                <span id="disease_history">Musculaire</span>
              </div>
              <hr />
              <div
                onClick={() => this.HandelChangeSection(7)}
                className="bilan_details_item"
              >
                <i className="fas fa-angle-right" style={{ color: 'black' }}></i>
                <span id="disease_history">Fonctionnel</span>
              </div>
              <hr />
              <div
                onClick={() => this.HandelChangeSection(8)}
                className="bilan_details_item"
              >
                <i className="fas fa-angle-right" style={{ color: 'black' }}></i>
                <span id="disease_history">Objectifs</span>
              </div>
              <hr />
              <div
                onClick={() => this.HandelChangeSection(9)}
                className="bilan_details_item"
              >
                <i className="fas fa-angle-right" style={{ color: 'black' }}></i>
                <span id="disease_history">Traitements</span>
              </div>
              <hr />

              {/*<div
                onClick={() => this.HandelChangeSection(10)}
                className="bilan_details_item"
              >
                <i className="fas fa-angle-right" style={{ color: 'black' }}></i>
                <span id="disease_history">Tout afficher</span>
              </div>
              <hr />*/}
              <div className="validate_bilan_btn">
                <button
                  disabled={isLoading}
                  onClick={() => {
                    this.props.submitFormBilan(this.state);
                  }}
                >
                  {loadingAddBilan ? (
                    <CircularProgress
                      disableShrink
                      style={{
                        marginRight: '50%',
                        height: '1',
                        width: '26px',
                        color: 'white',
                      }}
                    />
                  ) : (
                    'Valider le bilan'
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="bilan_settings">
            {
              !this.props.location.state && loadingSectionBilan ? (
                <CircularProgress
                  disableShrink
                  style={{ marginLeft: '47%', marginTop: '30%' }}
                />
              ) : (
                <ANoter
                  handleChangeDeleteFile={this.handleChangeDeleteFile}
                  handleChangeFile={this.handleChangeFile}
                  handleChangeFiles={this.handleChangeFiles}
                  handleChangeInput={this.handleChangeInput}
                  handleAddNewItem={this.handleAddNewItem}
                  handleChangeInputItem={this.handleChangeInputItem}
                  initialValues={initialValues}
                />
              )

              /* section_1 ? (
              <Administartif
                medecins={medecins}
                handleChangeInput={this.handleChangeInput}
                initialValues={initialValues}
              />
            ) : section_2 ? (
              <ContexteAnamnese
                handleChangeAnyInput={this.handleChangeAnyInput}
                handleChangeInput={this.handleChangeInput}
                handleAddNewItem={this.handleAddNewItem}
                handleChangeInputItem={this.handleChangeInputItem}
                initialValues={initialValues}
              />
            ) : section_4 ? (
              <Trophicite
                handleChangeAnyInput={this.handleChangeAnyInput}
                handleChangeFiles={this.handleChangeFiles}
                handleChangeInput={this.handleChangeInput}
                handleAddNewItem={this.handleAddNewItem}
                handleChangeInputItem={this.handleChangeInputItem}
  handleChangeFile={this.handleChangeFile}
                initialValues={initialValues}
              />
            ) : section_3 ? (
              <Douleur
                handleChangeAnyInput={this.handleChangeAnyInput}
                handleChangeFiles={this.handleChangeFiles}
                handleChangeInput={this.handleChangeInput}
                handleAddNewItem={this.handleAddNewItem}
                handleChangeInputItem={this.handleChangeInputItem}
  handleChangeFile={this.handleChangeFile}
                initialValues={initialValues}
              />
            ) : section_5 ? (
              <MobilitySynthetique
                handleChangeAnyInput={this.handleChangeAnyInput}
                handleChangeFiles={this.handleChangeFiles}
                handleChangeInput={this.handleChangeInput}
                handleAddNewItem={this.handleAddNewItem}
                handleChangeInputItem={this.handleChangeInputItem}
  handleChangeFile={this.handleChangeFile}
                initialValues={initialValues}
              />
            ) : section_6 ? (
              <Mobility
                handleChangeAnyInput={this.handleChangeAnyInput}
                handleChangeFiles={this.handleChangeFiles}
                handleChangeInput={this.handleChangeInput}
                handleAddNewItem={this.handleAddNewItem}
                handleChangeInputItem={this.handleChangeInputItem}
  handleChangeFile={this.handleChangeFile}
                initialValues={initialValues}
              />
            ) : section_7 ? (
              <Musculaire
                handleChangeAnyInput={this.handleChangeAnyInput}
                handleChangeInput={this.handleChangeInput}
                handleAddNewItem={this.handleAddNewItem}
                handleChangeInputItem={this.handleChangeInputItem}
                initialValues={initialValues}
              />
            ) : section_8 ? (
              <Fonctionnel
                handleChangeFiles={this.handleChangeFiles}
                handleChangeInput={this.handleChangeInput}
                handleAddNewItem={this.handleAddNewItem}
                handleChangeInputItem={this.handleChangeInputItem}
  handleChangeFile={this.handleChangeFile}
                initialValues={initialValues}
              />
            ) : section_9 ? (
              <Objectif
                handleChangeFiles={this.handleChangeFiles}
                handleChangeInput={this.handleChangeInput}
                handleAddNewItem={this.handleAddNewItem}
                handleChangeInputItem={this.handleChangeInputItem}
  handleChangeFile={this.handleChangeFile}
                initialValues={initialValues}
              />
            ) : section_10 ? (
              <Traitement
                handleChangeFiles={this.handleChangeFiles}
                handleChangeInput={this.handleChangeInput}
                handleAddNewItem={this.handleAddNewItem}
                handleChangeInputItem={this.handleChangeInputItem}
  handleChangeFile={this.handleChangeFile}
                initialValues={initialValues}
              />
            ) : section_12 ? (
              <ANoter
                handleChangeFiles={this.handleChangeFiles}
                handleChangeInput={this.handleChangeInput}
                handleAddNewItem={this.handleAddNewItem}
                handleChangeInputItem={this.handleChangeInputItem}
  handleChangeFile={this.handleChangeFile}
                initialValues={initialValues}
              />
            ) : (
              ''
            ) */
            }
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Epaule);
